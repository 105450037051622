import { sygnet } from './sygnet';
import { logo } from './logo';
import { logoNegative } from './logo-negative';

import { cilApplicationsSettings, cilSun, cilMoon } from '@coreui/icons/js/free';

import {
    cisChalkboardTeacher,
    cisSpeedometer,
    cisSchool,
    cisPeople,
    cisArchive,
    cisLocationPin,
    cisFolderAlt,
    cisMenu,
    cisLockLocked,
    cisAccountLogout,
    cisExternalLink,
    cisArrowCircleBottom,
    cisArrowCircleRight,
    cisBook,
    cisQueue,
} from '@coreui/icons-pro';

export const icons = Object.assign(
    {},
    {
        sygnet,
        logo,
        logoNegative,
    },
    {
        cilApplicationsSettings,
        cisSpeedometer,
        cisPeople,
        cilSun,
        cilMoon,
        cisChalkboardTeacher,
        cisSchool,
        cisArchive,
        cisLocationPin,
        cisFolderAlt,
        cisMenu,
        cisLockLocked,
        cisAccountLogout,
        cisExternalLink,
        cisArrowCircleBottom,
        cisArrowCircleRight,
        cisQueue,
        cisBook,
    },
);
