/* eslint-disable @typescript-eslint/no-explicit-any */
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import * as serviceWorker from './serviceWorker';

import { icons } from './assets/icons';

import { Provider } from 'react-redux';
import store from './store';

import { ApolloClient, InMemoryCache, createHttpLink, ApolloProvider, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { setContext } from '@apollo/client/link/context';

import { ReactKeycloakProvider } from '@react-keycloak/web';

import keycloak from './keycloak';

const eventLogger = (event: unknown, error: unknown) => {
    if (error) {
        console.log('onKeycloakEvent', event, error);
    }
};

const tokenLogger = (tokens: any) => {
    if (tokens !== null && tokens.idToken) {
        store.dispatch({ type: 'set', token: tokens.token });
    } else {
        store.dispatch({ type: 'set', token: null });
    }
};
const { graphQlUrl } = store.getState();
const httpLink = createHttpLink({
    uri: process.env.NODE_ENV == 'production' ? graphQlUrl : 'http://localhost:5002/graphql',
});

const authLink = setContext((_, { headers }) => {
    const { token } = store.getState();
    // console.log(`Token ${token} used`);
    if (token) {
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : '',
            },
        };
    }
    return {
        headers: {
            ...headers,
        },
    };
});

const cache = new InMemoryCache({
    dataIdFromObject: (object: any) => object.nodeId || null,
    typePolicies: {
        Query: {
            fields: {
                courseGroup: {
                    read(_, { args, toReference }) {
                        return toReference({ __typename: 'CourseGroup', id: args?.nodeId });
                    },
                },
            },
        },
    },
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) => {
            if (process.env.NODE_ENV != 'production') {
                console.error(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, ${JSON.stringify(
                        locations,
                    )} Path: ${path} Operation: ${JSON.stringify(operation)}`,
                );
            }
        });

    if (networkError) console.error(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
    link: from([errorLink, authLink.concat(httpLink)]),
    cache: cache,
    connectToDevTools: process.env.NODE_ENV != 'production',
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'cache-and-network',
        },
        query: {
            fetchPolicy: 'network-only',
        },
    },
});

React.icons = icons;

ReactDOM.render(
    <React.StrictMode>
        <ReactKeycloakProvider
            authClient={keycloak}
            // initOptions={{
            //   onLoad: "login-required",
            // }}
            onEvent={eventLogger}
            onTokens={tokenLogger}
        >
            <ApolloProvider client={client}>
                <Provider store={store}>
                    <App />
                </Provider>
            </ApolloProvider>
        </ReactKeycloakProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
