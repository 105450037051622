/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** A floating point number that requires more precision than IEEE 754 binary 64 */
    BigFloat: any;
    /**
     * A signed eight-byte integer. The upper big integer values are greater than the
     * max value for a JavaScript number. Therefore all big integers will be output as
     * strings and not numbers.
     */
    BigInt: any;
    /** A location in a connection that can be used for resuming pagination. */
    Cursor: any;
    /** The day, does not include a time. */
    Date: any;
    /**
     * A point in time as described by the [ISO
     * 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone.
     */
    Datetime: any;
    /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    JSON: any;
    /** A universally unique identifier as defined by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
    UUID: any;
};

/** A filter to be used against BigFloat fields. All fields are combined with a logical ‘and.’ */
export type BigFloatFilter = {
    /** Is null (if `true` is specified) or is not null (if `false` is specified). */
    isNull?: Maybe<Scalars['Boolean']>;
    /** Equal to the specified value. */
    equalTo?: Maybe<Scalars['BigFloat']>;
    /** Not equal to the specified value. */
    notEqualTo?: Maybe<Scalars['BigFloat']>;
    /** Not equal to the specified value, treating null like an ordinary value. */
    distinctFrom?: Maybe<Scalars['BigFloat']>;
    /** Equal to the specified value, treating null like an ordinary value. */
    notDistinctFrom?: Maybe<Scalars['BigFloat']>;
    /** Included in the specified list. */
    in?: Maybe<Array<Scalars['BigFloat']>>;
    /** Not included in the specified list. */
    notIn?: Maybe<Array<Scalars['BigFloat']>>;
    /** Less than the specified value. */
    lessThan?: Maybe<Scalars['BigFloat']>;
    /** Less than or equal to the specified value. */
    lessThanOrEqualTo?: Maybe<Scalars['BigFloat']>;
    /** Greater than the specified value. */
    greaterThan?: Maybe<Scalars['BigFloat']>;
    /** Greater than or equal to the specified value. */
    greaterThanOrEqualTo?: Maybe<Scalars['BigFloat']>;
};

/** A filter to be used against BigInt fields. All fields are combined with a logical ‘and.’ */
export type BigIntFilter = {
    /** Is null (if `true` is specified) or is not null (if `false` is specified). */
    isNull?: Maybe<Scalars['Boolean']>;
    /** Equal to the specified value. */
    equalTo?: Maybe<Scalars['BigInt']>;
    /** Not equal to the specified value. */
    notEqualTo?: Maybe<Scalars['BigInt']>;
    /** Not equal to the specified value, treating null like an ordinary value. */
    distinctFrom?: Maybe<Scalars['BigInt']>;
    /** Equal to the specified value, treating null like an ordinary value. */
    notDistinctFrom?: Maybe<Scalars['BigInt']>;
    /** Included in the specified list. */
    in?: Maybe<Array<Scalars['BigInt']>>;
    /** Not included in the specified list. */
    notIn?: Maybe<Array<Scalars['BigInt']>>;
    /** Less than the specified value. */
    lessThan?: Maybe<Scalars['BigInt']>;
    /** Less than or equal to the specified value. */
    lessThanOrEqualTo?: Maybe<Scalars['BigInt']>;
    /** Greater than the specified value. */
    greaterThan?: Maybe<Scalars['BigInt']>;
    /** Greater than or equal to the specified value. */
    greaterThanOrEqualTo?: Maybe<Scalars['BigInt']>;
};

export type Booking = Node & {
    __typename?: 'Booking';
    /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
    nodeId: Scalars['ID'];
    id: Scalars['Int'];
    courseScheduleId?: Maybe<Scalars['Int']>;
    participantId?: Maybe<Scalars['Int']>;
    registrationRegistered?: Maybe<Scalars['Boolean']>;
    registrationDate?: Maybe<Scalars['Date']>;
    registrationNote?: Maybe<Scalars['String']>;
    passed?: Maybe<Scalars['Boolean']>;
    missingHours: Scalars['Int'];
    paymentReceived?: Maybe<Scalars['Boolean']>;
    paymentDate?: Maybe<Scalars['Date']>;
    paymentMethod: BookingPaymentMethod;
    invoiceNumber?: Maybe<Scalars['BigInt']>;
    waitingPosition?: Maybe<Scalars['BigInt']>;
    documentsSend?: Maybe<Scalars['Boolean']>;
    note?: Maybe<Scalars['String']>;
    amount: Scalars['Float'];
    createdAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    registrationConfirmation?: Maybe<Scalars['Boolean']>;
    invoiceCompanyName?: Maybe<Scalars['String']>;
    invoiceFamilyName?: Maybe<Scalars['String']>;
    invoiceSalutation?: Maybe<Scalars['String']>;
    invoiceTitle?: Maybe<Scalars['String']>;
    invoiceGivenName?: Maybe<Scalars['String']>;
    invoiceStreet?: Maybe<Scalars['String']>;
    invoicePostcode?: Maybe<Scalars['String']>;
    invoiceCity?: Maybe<Scalars['String']>;
    invoiceCountry?: Maybe<Scalars['String']>;
    invoiceBankOwner?: Maybe<Scalars['String']>;
    invoiceBankName?: Maybe<Scalars['String']>;
    invoiceBankSwift?: Maybe<Scalars['String']>;
    invoiceBankIban?: Maybe<Scalars['String']>;
    invoiceToEmployer?: Maybe<Scalars['Boolean']>;
    state: BookingState;
    invoiceContactPhone?: Maybe<Scalars['String']>;
    invoiceContactEmail?: Maybe<Scalars['String']>;
    invoiceContactUrl?: Maybe<Scalars['String']>;
    accept?: Maybe<Scalars['Boolean']>;
    acceptPayment?: Maybe<Scalars['Boolean']>;
    invoiceDate?: Maybe<Scalars['Date']>;
    code?: Maybe<Scalars['UUID']>;
    useForCertification: Scalars['Boolean'];
    /** Reads a single `CourseSchedule` that is related to this `Booking`. */
    courseSchedule?: Maybe<CourseSchedule>;
    /** Reads a single `Participant` that is related to this `Booking`. */
    participant?: Maybe<Participant>;
    infos?: Maybe<Scalars['JSON']>;
    infosInternal?: Maybe<Scalars['JSON']>;
    infosSearchable?: Maybe<Scalars['String']>;
    invoiceAddress?: Maybe<Scalars['String']>;
    invoiceContactInformation?: Maybe<Scalars['String']>;
    registrationDateFormatted?: Maybe<Scalars['String']>;
};

export type BookingInfosInternalArgs = {
    dropEmptyKeys?: Maybe<Scalars['Boolean']>;
};

export type BookingInvoiceAddressArgs = {
    delimiter?: Maybe<Scalars['String']>;
};

export type BookingInvoiceContactInformationArgs = {
    delimiter?: Maybe<Scalars['String']>;
};

export type BookingAggregates = {
    __typename?: 'BookingAggregates';
    keys?: Maybe<Array<Scalars['String']>>;
    /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    sum?: Maybe<BookingSumAggregates>;
    /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
    distinctCount?: Maybe<BookingDistinctCountAggregates>;
    /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    min?: Maybe<BookingMinAggregates>;
    /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    max?: Maybe<BookingMaxAggregates>;
    /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
    average?: Maybe<BookingAverageAggregates>;
    /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
    stddevSample?: Maybe<BookingStddevSampleAggregates>;
    /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
    stddevPopulation?: Maybe<BookingStddevPopulationAggregates>;
    /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
    varianceSample?: Maybe<BookingVarianceSampleAggregates>;
    /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
    variancePopulation?: Maybe<BookingVariancePopulationAggregates>;
};

export type BookingAverageAggregates = {
    __typename?: 'BookingAverageAggregates';
    /** Mean average of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Mean average of courseScheduleId across the matching connection */
    courseScheduleId?: Maybe<Scalars['BigFloat']>;
    /** Mean average of participantId across the matching connection */
    participantId?: Maybe<Scalars['BigFloat']>;
    /** Mean average of missingHours across the matching connection */
    missingHours?: Maybe<Scalars['BigFloat']>;
    /** Mean average of invoiceNumber across the matching connection */
    invoiceNumber?: Maybe<Scalars['BigFloat']>;
    /** Mean average of waitingPosition across the matching connection */
    waitingPosition?: Maybe<Scalars['BigFloat']>;
    /** Mean average of amount across the matching connection */
    amount?: Maybe<Scalars['Float']>;
};

/** A condition to be used against `Booking` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type BookingCondition = {
    /** Checks for equality with the object’s `id` field. */
    id?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `courseScheduleId` field. */
    courseScheduleId?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `participantId` field. */
    participantId?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `registrationRegistered` field. */
    registrationRegistered?: Maybe<Scalars['Boolean']>;
    /** Checks for equality with the object’s `registrationDate` field. */
    registrationDate?: Maybe<Scalars['Date']>;
    /** Checks for equality with the object’s `registrationNote` field. */
    registrationNote?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `passed` field. */
    passed?: Maybe<Scalars['Boolean']>;
    /** Checks for equality with the object’s `missingHours` field. */
    missingHours?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `paymentReceived` field. */
    paymentReceived?: Maybe<Scalars['Boolean']>;
    /** Checks for equality with the object’s `paymentDate` field. */
    paymentDate?: Maybe<Scalars['Date']>;
    /** Checks for equality with the object’s `paymentMethod` field. */
    paymentMethod?: Maybe<BookingPaymentMethod>;
    /** Checks for equality with the object’s `invoiceNumber` field. */
    invoiceNumber?: Maybe<Scalars['BigInt']>;
    /** Checks for equality with the object’s `waitingPosition` field. */
    waitingPosition?: Maybe<Scalars['BigInt']>;
    /** Checks for equality with the object’s `documentsSend` field. */
    documentsSend?: Maybe<Scalars['Boolean']>;
    /** Checks for equality with the object’s `note` field. */
    note?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `amount` field. */
    amount?: Maybe<Scalars['Float']>;
    /** Checks for equality with the object’s `createdAt` field. */
    createdAt?: Maybe<Scalars['Datetime']>;
    /** Checks for equality with the object’s `updatedAt` field. */
    updatedAt?: Maybe<Scalars['Datetime']>;
    /** Checks for equality with the object’s `registrationConfirmation` field. */
    registrationConfirmation?: Maybe<Scalars['Boolean']>;
    /** Checks for equality with the object’s `invoiceCompanyName` field. */
    invoiceCompanyName?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `invoiceFamilyName` field. */
    invoiceFamilyName?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `invoiceSalutation` field. */
    invoiceSalutation?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `invoiceTitle` field. */
    invoiceTitle?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `invoiceGivenName` field. */
    invoiceGivenName?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `invoiceStreet` field. */
    invoiceStreet?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `invoicePostcode` field. */
    invoicePostcode?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `invoiceCity` field. */
    invoiceCity?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `invoiceCountry` field. */
    invoiceCountry?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `invoiceBankOwner` field. */
    invoiceBankOwner?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `invoiceBankName` field. */
    invoiceBankName?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `invoiceBankSwift` field. */
    invoiceBankSwift?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `invoiceBankIban` field. */
    invoiceBankIban?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `invoiceToEmployer` field. */
    invoiceToEmployer?: Maybe<Scalars['Boolean']>;
    /** Checks for equality with the object’s `state` field. */
    state?: Maybe<BookingState>;
    /** Checks for equality with the object’s `invoiceContactPhone` field. */
    invoiceContactPhone?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `invoiceContactEmail` field. */
    invoiceContactEmail?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `invoiceContactUrl` field. */
    invoiceContactUrl?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `accept` field. */
    accept?: Maybe<Scalars['Boolean']>;
    /** Checks for equality with the object’s `acceptPayment` field. */
    acceptPayment?: Maybe<Scalars['Boolean']>;
    /** Checks for equality with the object’s `invoiceDate` field. */
    invoiceDate?: Maybe<Scalars['Date']>;
    /** Checks for equality with the object’s `code` field. */
    code?: Maybe<Scalars['UUID']>;
    /** Checks for equality with the object’s `useForCertification` field. */
    useForCertification?: Maybe<Scalars['Boolean']>;
};

export type BookingDistinctCountAggregates = {
    __typename?: 'BookingDistinctCountAggregates';
    /** Distinct count of id across the matching connection */
    id?: Maybe<Scalars['BigInt']>;
    /** Distinct count of courseScheduleId across the matching connection */
    courseScheduleId?: Maybe<Scalars['BigInt']>;
    /** Distinct count of participantId across the matching connection */
    participantId?: Maybe<Scalars['BigInt']>;
    /** Distinct count of registrationRegistered across the matching connection */
    registrationRegistered?: Maybe<Scalars['BigInt']>;
    /** Distinct count of registrationDate across the matching connection */
    registrationDate?: Maybe<Scalars['BigInt']>;
    /** Distinct count of registrationNote across the matching connection */
    registrationNote?: Maybe<Scalars['BigInt']>;
    /** Distinct count of passed across the matching connection */
    passed?: Maybe<Scalars['BigInt']>;
    /** Distinct count of missingHours across the matching connection */
    missingHours?: Maybe<Scalars['BigInt']>;
    /** Distinct count of paymentReceived across the matching connection */
    paymentReceived?: Maybe<Scalars['BigInt']>;
    /** Distinct count of paymentDate across the matching connection */
    paymentDate?: Maybe<Scalars['BigInt']>;
    /** Distinct count of paymentMethod across the matching connection */
    paymentMethod?: Maybe<Scalars['BigInt']>;
    /** Distinct count of invoiceNumber across the matching connection */
    invoiceNumber?: Maybe<Scalars['BigInt']>;
    /** Distinct count of waitingPosition across the matching connection */
    waitingPosition?: Maybe<Scalars['BigInt']>;
    /** Distinct count of documentsSend across the matching connection */
    documentsSend?: Maybe<Scalars['BigInt']>;
    /** Distinct count of note across the matching connection */
    note?: Maybe<Scalars['BigInt']>;
    /** Distinct count of amount across the matching connection */
    amount?: Maybe<Scalars['BigInt']>;
    /** Distinct count of createdAt across the matching connection */
    createdAt?: Maybe<Scalars['BigInt']>;
    /** Distinct count of updatedAt across the matching connection */
    updatedAt?: Maybe<Scalars['BigInt']>;
    /** Distinct count of registrationConfirmation across the matching connection */
    registrationConfirmation?: Maybe<Scalars['BigInt']>;
    /** Distinct count of invoiceCompanyName across the matching connection */
    invoiceCompanyName?: Maybe<Scalars['BigInt']>;
    /** Distinct count of invoiceFamilyName across the matching connection */
    invoiceFamilyName?: Maybe<Scalars['BigInt']>;
    /** Distinct count of invoiceSalutation across the matching connection */
    invoiceSalutation?: Maybe<Scalars['BigInt']>;
    /** Distinct count of invoiceTitle across the matching connection */
    invoiceTitle?: Maybe<Scalars['BigInt']>;
    /** Distinct count of invoiceGivenName across the matching connection */
    invoiceGivenName?: Maybe<Scalars['BigInt']>;
    /** Distinct count of invoiceStreet across the matching connection */
    invoiceStreet?: Maybe<Scalars['BigInt']>;
    /** Distinct count of invoicePostcode across the matching connection */
    invoicePostcode?: Maybe<Scalars['BigInt']>;
    /** Distinct count of invoiceCity across the matching connection */
    invoiceCity?: Maybe<Scalars['BigInt']>;
    /** Distinct count of invoiceCountry across the matching connection */
    invoiceCountry?: Maybe<Scalars['BigInt']>;
    /** Distinct count of invoiceBankOwner across the matching connection */
    invoiceBankOwner?: Maybe<Scalars['BigInt']>;
    /** Distinct count of invoiceBankName across the matching connection */
    invoiceBankName?: Maybe<Scalars['BigInt']>;
    /** Distinct count of invoiceBankSwift across the matching connection */
    invoiceBankSwift?: Maybe<Scalars['BigInt']>;
    /** Distinct count of invoiceBankIban across the matching connection */
    invoiceBankIban?: Maybe<Scalars['BigInt']>;
    /** Distinct count of invoiceToEmployer across the matching connection */
    invoiceToEmployer?: Maybe<Scalars['BigInt']>;
    /** Distinct count of state across the matching connection */
    state?: Maybe<Scalars['BigInt']>;
    /** Distinct count of invoiceContactPhone across the matching connection */
    invoiceContactPhone?: Maybe<Scalars['BigInt']>;
    /** Distinct count of invoiceContactEmail across the matching connection */
    invoiceContactEmail?: Maybe<Scalars['BigInt']>;
    /** Distinct count of invoiceContactUrl across the matching connection */
    invoiceContactUrl?: Maybe<Scalars['BigInt']>;
    /** Distinct count of accept across the matching connection */
    accept?: Maybe<Scalars['BigInt']>;
    /** Distinct count of acceptPayment across the matching connection */
    acceptPayment?: Maybe<Scalars['BigInt']>;
    /** Distinct count of invoiceDate across the matching connection */
    invoiceDate?: Maybe<Scalars['BigInt']>;
    /** Distinct count of code across the matching connection */
    code?: Maybe<Scalars['BigInt']>;
    /** Distinct count of useForCertification across the matching connection */
    useForCertification?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    infos?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    infosInternal?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    infosSearchable?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    invoiceAddress?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    invoiceContactInformation?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    registrationDateFormatted?: Maybe<Scalars['BigInt']>;
};

export type BookingDistinctCountAggregatesInfosInternalArgs = {
    dropEmptyKeys?: Maybe<Scalars['Boolean']>;
};

export type BookingDistinctCountAggregatesInvoiceAddressArgs = {
    delimiter?: Maybe<Scalars['String']>;
};

export type BookingDistinctCountAggregatesInvoiceContactInformationArgs = {
    delimiter?: Maybe<Scalars['String']>;
};

/** A filter to be used against `Booking` object types. All fields are combined with a logical ‘and.’ */
export type BookingFilter = {
    /** Filter by the object’s `id` field. */
    id?: Maybe<IntFilter>;
    /** Filter by the object’s `courseScheduleId` field. */
    courseScheduleId?: Maybe<IntFilter>;
    /** Filter by the object’s `participantId` field. */
    participantId?: Maybe<IntFilter>;
    /** Filter by the object’s `registrationRegistered` field. */
    registrationRegistered?: Maybe<BooleanFilter>;
    /** Filter by the object’s `registrationDate` field. */
    registrationDate?: Maybe<DateFilter>;
    /** Filter by the object’s `registrationNote` field. */
    registrationNote?: Maybe<StringFilter>;
    /** Filter by the object’s `passed` field. */
    passed?: Maybe<BooleanFilter>;
    /** Filter by the object’s `missingHours` field. */
    missingHours?: Maybe<IntFilter>;
    /** Filter by the object’s `paymentReceived` field. */
    paymentReceived?: Maybe<BooleanFilter>;
    /** Filter by the object’s `paymentDate` field. */
    paymentDate?: Maybe<DateFilter>;
    /** Filter by the object’s `paymentMethod` field. */
    paymentMethod?: Maybe<BookingPaymentMethodFilter>;
    /** Filter by the object’s `invoiceNumber` field. */
    invoiceNumber?: Maybe<BigIntFilter>;
    /** Filter by the object’s `waitingPosition` field. */
    waitingPosition?: Maybe<BigIntFilter>;
    /** Filter by the object’s `documentsSend` field. */
    documentsSend?: Maybe<BooleanFilter>;
    /** Filter by the object’s `note` field. */
    note?: Maybe<StringFilter>;
    /** Filter by the object’s `amount` field. */
    amount?: Maybe<FloatFilter>;
    /** Filter by the object’s `createdAt` field. */
    createdAt?: Maybe<DatetimeFilter>;
    /** Filter by the object’s `updatedAt` field. */
    updatedAt?: Maybe<DatetimeFilter>;
    /** Filter by the object’s `registrationConfirmation` field. */
    registrationConfirmation?: Maybe<BooleanFilter>;
    /** Filter by the object’s `invoiceCompanyName` field. */
    invoiceCompanyName?: Maybe<StringFilter>;
    /** Filter by the object’s `invoiceFamilyName` field. */
    invoiceFamilyName?: Maybe<StringFilter>;
    /** Filter by the object’s `invoiceSalutation` field. */
    invoiceSalutation?: Maybe<StringFilter>;
    /** Filter by the object’s `invoiceTitle` field. */
    invoiceTitle?: Maybe<StringFilter>;
    /** Filter by the object’s `invoiceGivenName` field. */
    invoiceGivenName?: Maybe<StringFilter>;
    /** Filter by the object’s `invoiceStreet` field. */
    invoiceStreet?: Maybe<StringFilter>;
    /** Filter by the object’s `invoicePostcode` field. */
    invoicePostcode?: Maybe<StringFilter>;
    /** Filter by the object’s `invoiceCity` field. */
    invoiceCity?: Maybe<StringFilter>;
    /** Filter by the object’s `invoiceCountry` field. */
    invoiceCountry?: Maybe<StringFilter>;
    /** Filter by the object’s `invoiceBankOwner` field. */
    invoiceBankOwner?: Maybe<StringFilter>;
    /** Filter by the object’s `invoiceBankName` field. */
    invoiceBankName?: Maybe<StringFilter>;
    /** Filter by the object’s `invoiceBankSwift` field. */
    invoiceBankSwift?: Maybe<StringFilter>;
    /** Filter by the object’s `invoiceBankIban` field. */
    invoiceBankIban?: Maybe<StringFilter>;
    /** Filter by the object’s `invoiceToEmployer` field. */
    invoiceToEmployer?: Maybe<BooleanFilter>;
    /** Filter by the object’s `state` field. */
    state?: Maybe<BookingStateFilter>;
    /** Filter by the object’s `invoiceContactPhone` field. */
    invoiceContactPhone?: Maybe<StringFilter>;
    /** Filter by the object’s `invoiceContactEmail` field. */
    invoiceContactEmail?: Maybe<StringFilter>;
    /** Filter by the object’s `invoiceContactUrl` field. */
    invoiceContactUrl?: Maybe<StringFilter>;
    /** Filter by the object’s `accept` field. */
    accept?: Maybe<BooleanFilter>;
    /** Filter by the object’s `acceptPayment` field. */
    acceptPayment?: Maybe<BooleanFilter>;
    /** Filter by the object’s `invoiceDate` field. */
    invoiceDate?: Maybe<DateFilter>;
    /** Filter by the object’s `code` field. */
    code?: Maybe<UuidFilter>;
    /** Filter by the object’s `useForCertification` field. */
    useForCertification?: Maybe<BooleanFilter>;
    /** Filter by the object’s `infos` field. */
    infos?: Maybe<JsonFilter>;
    /** Filter by the object’s `infosInternal` field. */
    infosInternal?: Maybe<JsonFilter>;
    /** Filter by the object’s `infosSearchable` field. */
    infosSearchable?: Maybe<StringFilter>;
    /** Filter by the object’s `invoiceAddress` field. */
    invoiceAddress?: Maybe<StringFilter>;
    /** Filter by the object’s `invoiceContactInformation` field. */
    invoiceContactInformation?: Maybe<StringFilter>;
    /** Filter by the object’s `registrationDateFormatted` field. */
    registrationDateFormatted?: Maybe<StringFilter>;
    /** Checks for all expressions in this list. */
    and?: Maybe<Array<BookingFilter>>;
    /** Checks for any expressions in this list. */
    or?: Maybe<Array<BookingFilter>>;
    /** Negates the expression. */
    not?: Maybe<BookingFilter>;
};

/** Grouping methods for `Booking` for usage during aggregation. */
export enum BookingGroupBy {
    CourseScheduleId = 'COURSE_SCHEDULE_ID',
    ParticipantId = 'PARTICIPANT_ID',
    RegistrationRegistered = 'REGISTRATION_REGISTERED',
    RegistrationDate = 'REGISTRATION_DATE',
    RegistrationNote = 'REGISTRATION_NOTE',
    Passed = 'PASSED',
    MissingHours = 'MISSING_HOURS',
    PaymentReceived = 'PAYMENT_RECEIVED',
    PaymentDate = 'PAYMENT_DATE',
    PaymentMethod = 'PAYMENT_METHOD',
    InvoiceNumber = 'INVOICE_NUMBER',
    WaitingPosition = 'WAITING_POSITION',
    DocumentsSend = 'DOCUMENTS_SEND',
    Note = 'NOTE',
    Amount = 'AMOUNT',
    CreatedAt = 'CREATED_AT',
    CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
    CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
    UpdatedAt = 'UPDATED_AT',
    UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
    UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
    RegistrationConfirmation = 'REGISTRATION_CONFIRMATION',
    InvoiceCompanyName = 'INVOICE_COMPANY_NAME',
    InvoiceFamilyName = 'INVOICE_FAMILY_NAME',
    InvoiceSalutation = 'INVOICE_SALUTATION',
    InvoiceTitle = 'INVOICE_TITLE',
    InvoiceGivenName = 'INVOICE_GIVEN_NAME',
    InvoiceStreet = 'INVOICE_STREET',
    InvoicePostcode = 'INVOICE_POSTCODE',
    InvoiceCity = 'INVOICE_CITY',
    InvoiceCountry = 'INVOICE_COUNTRY',
    InvoiceBankOwner = 'INVOICE_BANK_OWNER',
    InvoiceBankName = 'INVOICE_BANK_NAME',
    InvoiceBankSwift = 'INVOICE_BANK_SWIFT',
    InvoiceBankIban = 'INVOICE_BANK_IBAN',
    InvoiceToEmployer = 'INVOICE_TO_EMPLOYER',
    State = 'STATE',
    InvoiceContactPhone = 'INVOICE_CONTACT_PHONE',
    InvoiceContactEmail = 'INVOICE_CONTACT_EMAIL',
    InvoiceContactUrl = 'INVOICE_CONTACT_URL',
    Accept = 'ACCEPT',
    AcceptPayment = 'ACCEPT_PAYMENT',
    InvoiceDate = 'INVOICE_DATE',
    UseForCertification = 'USE_FOR_CERTIFICATION',
}

export type BookingHavingAverageInput = {
    id?: Maybe<HavingIntFilter>;
    courseScheduleId?: Maybe<HavingIntFilter>;
    participantId?: Maybe<HavingIntFilter>;
    registrationDate?: Maybe<HavingDatetimeFilter>;
    missingHours?: Maybe<HavingIntFilter>;
    paymentDate?: Maybe<HavingDatetimeFilter>;
    invoiceNumber?: Maybe<HavingBigintFilter>;
    waitingPosition?: Maybe<HavingBigintFilter>;
    amount?: Maybe<HavingFloatFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
    invoiceDate?: Maybe<HavingDatetimeFilter>;
};

export type BookingHavingDistinctCountInput = {
    id?: Maybe<HavingIntFilter>;
    courseScheduleId?: Maybe<HavingIntFilter>;
    participantId?: Maybe<HavingIntFilter>;
    registrationDate?: Maybe<HavingDatetimeFilter>;
    missingHours?: Maybe<HavingIntFilter>;
    paymentDate?: Maybe<HavingDatetimeFilter>;
    invoiceNumber?: Maybe<HavingBigintFilter>;
    waitingPosition?: Maybe<HavingBigintFilter>;
    amount?: Maybe<HavingFloatFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
    invoiceDate?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `Booking` aggregates. */
export type BookingHavingInput = {
    AND?: Maybe<Array<BookingHavingInput>>;
    OR?: Maybe<Array<BookingHavingInput>>;
    sum?: Maybe<BookingHavingSumInput>;
    distinctCount?: Maybe<BookingHavingDistinctCountInput>;
    min?: Maybe<BookingHavingMinInput>;
    max?: Maybe<BookingHavingMaxInput>;
    average?: Maybe<BookingHavingAverageInput>;
    stddevSample?: Maybe<BookingHavingStddevSampleInput>;
    stddevPopulation?: Maybe<BookingHavingStddevPopulationInput>;
    varianceSample?: Maybe<BookingHavingVarianceSampleInput>;
    variancePopulation?: Maybe<BookingHavingVariancePopulationInput>;
};

export type BookingHavingMaxInput = {
    id?: Maybe<HavingIntFilter>;
    courseScheduleId?: Maybe<HavingIntFilter>;
    participantId?: Maybe<HavingIntFilter>;
    registrationDate?: Maybe<HavingDatetimeFilter>;
    missingHours?: Maybe<HavingIntFilter>;
    paymentDate?: Maybe<HavingDatetimeFilter>;
    invoiceNumber?: Maybe<HavingBigintFilter>;
    waitingPosition?: Maybe<HavingBigintFilter>;
    amount?: Maybe<HavingFloatFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
    invoiceDate?: Maybe<HavingDatetimeFilter>;
};

export type BookingHavingMinInput = {
    id?: Maybe<HavingIntFilter>;
    courseScheduleId?: Maybe<HavingIntFilter>;
    participantId?: Maybe<HavingIntFilter>;
    registrationDate?: Maybe<HavingDatetimeFilter>;
    missingHours?: Maybe<HavingIntFilter>;
    paymentDate?: Maybe<HavingDatetimeFilter>;
    invoiceNumber?: Maybe<HavingBigintFilter>;
    waitingPosition?: Maybe<HavingBigintFilter>;
    amount?: Maybe<HavingFloatFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
    invoiceDate?: Maybe<HavingDatetimeFilter>;
};

export type BookingHavingStddevPopulationInput = {
    id?: Maybe<HavingIntFilter>;
    courseScheduleId?: Maybe<HavingIntFilter>;
    participantId?: Maybe<HavingIntFilter>;
    registrationDate?: Maybe<HavingDatetimeFilter>;
    missingHours?: Maybe<HavingIntFilter>;
    paymentDate?: Maybe<HavingDatetimeFilter>;
    invoiceNumber?: Maybe<HavingBigintFilter>;
    waitingPosition?: Maybe<HavingBigintFilter>;
    amount?: Maybe<HavingFloatFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
    invoiceDate?: Maybe<HavingDatetimeFilter>;
};

export type BookingHavingStddevSampleInput = {
    id?: Maybe<HavingIntFilter>;
    courseScheduleId?: Maybe<HavingIntFilter>;
    participantId?: Maybe<HavingIntFilter>;
    registrationDate?: Maybe<HavingDatetimeFilter>;
    missingHours?: Maybe<HavingIntFilter>;
    paymentDate?: Maybe<HavingDatetimeFilter>;
    invoiceNumber?: Maybe<HavingBigintFilter>;
    waitingPosition?: Maybe<HavingBigintFilter>;
    amount?: Maybe<HavingFloatFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
    invoiceDate?: Maybe<HavingDatetimeFilter>;
};

export type BookingHavingSumInput = {
    id?: Maybe<HavingIntFilter>;
    courseScheduleId?: Maybe<HavingIntFilter>;
    participantId?: Maybe<HavingIntFilter>;
    registrationDate?: Maybe<HavingDatetimeFilter>;
    missingHours?: Maybe<HavingIntFilter>;
    paymentDate?: Maybe<HavingDatetimeFilter>;
    invoiceNumber?: Maybe<HavingBigintFilter>;
    waitingPosition?: Maybe<HavingBigintFilter>;
    amount?: Maybe<HavingFloatFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
    invoiceDate?: Maybe<HavingDatetimeFilter>;
};

export type BookingHavingVariancePopulationInput = {
    id?: Maybe<HavingIntFilter>;
    courseScheduleId?: Maybe<HavingIntFilter>;
    participantId?: Maybe<HavingIntFilter>;
    registrationDate?: Maybe<HavingDatetimeFilter>;
    missingHours?: Maybe<HavingIntFilter>;
    paymentDate?: Maybe<HavingDatetimeFilter>;
    invoiceNumber?: Maybe<HavingBigintFilter>;
    waitingPosition?: Maybe<HavingBigintFilter>;
    amount?: Maybe<HavingFloatFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
    invoiceDate?: Maybe<HavingDatetimeFilter>;
};

export type BookingHavingVarianceSampleInput = {
    id?: Maybe<HavingIntFilter>;
    courseScheduleId?: Maybe<HavingIntFilter>;
    participantId?: Maybe<HavingIntFilter>;
    registrationDate?: Maybe<HavingDatetimeFilter>;
    missingHours?: Maybe<HavingIntFilter>;
    paymentDate?: Maybe<HavingDatetimeFilter>;
    invoiceNumber?: Maybe<HavingBigintFilter>;
    waitingPosition?: Maybe<HavingBigintFilter>;
    amount?: Maybe<HavingFloatFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
    invoiceDate?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `Booking` */
export type BookingInput = {
    id?: Maybe<Scalars['Int']>;
    courseScheduleId?: Maybe<Scalars['Int']>;
    participantId?: Maybe<Scalars['Int']>;
    registrationRegistered?: Maybe<Scalars['Boolean']>;
    registrationDate?: Maybe<Scalars['Date']>;
    registrationNote?: Maybe<Scalars['String']>;
    passed?: Maybe<Scalars['Boolean']>;
    missingHours?: Maybe<Scalars['Int']>;
    paymentReceived?: Maybe<Scalars['Boolean']>;
    paymentDate?: Maybe<Scalars['Date']>;
    paymentMethod?: Maybe<BookingPaymentMethod>;
    invoiceNumber?: Maybe<Scalars['BigInt']>;
    waitingPosition?: Maybe<Scalars['BigInt']>;
    documentsSend?: Maybe<Scalars['Boolean']>;
    note?: Maybe<Scalars['String']>;
    amount?: Maybe<Scalars['Float']>;
    createdAt?: Maybe<Scalars['Datetime']>;
    updatedAt?: Maybe<Scalars['Datetime']>;
    registrationConfirmation?: Maybe<Scalars['Boolean']>;
    invoiceCompanyName?: Maybe<Scalars['String']>;
    invoiceFamilyName?: Maybe<Scalars['String']>;
    invoiceSalutation?: Maybe<Scalars['String']>;
    invoiceTitle?: Maybe<Scalars['String']>;
    invoiceGivenName?: Maybe<Scalars['String']>;
    invoiceStreet?: Maybe<Scalars['String']>;
    invoicePostcode?: Maybe<Scalars['String']>;
    invoiceCity?: Maybe<Scalars['String']>;
    invoiceCountry?: Maybe<Scalars['String']>;
    invoiceBankOwner?: Maybe<Scalars['String']>;
    invoiceBankName?: Maybe<Scalars['String']>;
    invoiceBankSwift?: Maybe<Scalars['String']>;
    invoiceBankIban?: Maybe<Scalars['String']>;
    invoiceToEmployer?: Maybe<Scalars['Boolean']>;
    state?: Maybe<BookingState>;
    invoiceContactPhone?: Maybe<Scalars['String']>;
    invoiceContactEmail?: Maybe<Scalars['String']>;
    invoiceContactUrl?: Maybe<Scalars['String']>;
    accept?: Maybe<Scalars['Boolean']>;
    acceptPayment?: Maybe<Scalars['Boolean']>;
    invoiceDate?: Maybe<Scalars['Date']>;
    code?: Maybe<Scalars['UUID']>;
    useForCertification?: Maybe<Scalars['Boolean']>;
};

export type BookingMaxAggregates = {
    __typename?: 'BookingMaxAggregates';
    /** Maximum of id across the matching connection */
    id?: Maybe<Scalars['Int']>;
    /** Maximum of courseScheduleId across the matching connection */
    courseScheduleId?: Maybe<Scalars['Int']>;
    /** Maximum of participantId across the matching connection */
    participantId?: Maybe<Scalars['Int']>;
    /** Maximum of missingHours across the matching connection */
    missingHours?: Maybe<Scalars['Int']>;
    /** Maximum of invoiceNumber across the matching connection */
    invoiceNumber?: Maybe<Scalars['BigInt']>;
    /** Maximum of waitingPosition across the matching connection */
    waitingPosition?: Maybe<Scalars['BigInt']>;
    /** Maximum of amount across the matching connection */
    amount?: Maybe<Scalars['Float']>;
};

export type BookingMinAggregates = {
    __typename?: 'BookingMinAggregates';
    /** Minimum of id across the matching connection */
    id?: Maybe<Scalars['Int']>;
    /** Minimum of courseScheduleId across the matching connection */
    courseScheduleId?: Maybe<Scalars['Int']>;
    /** Minimum of participantId across the matching connection */
    participantId?: Maybe<Scalars['Int']>;
    /** Minimum of missingHours across the matching connection */
    missingHours?: Maybe<Scalars['Int']>;
    /** Minimum of invoiceNumber across the matching connection */
    invoiceNumber?: Maybe<Scalars['BigInt']>;
    /** Minimum of waitingPosition across the matching connection */
    waitingPosition?: Maybe<Scalars['BigInt']>;
    /** Minimum of amount across the matching connection */
    amount?: Maybe<Scalars['Float']>;
};

/** All input for the `bookingPassedDocument` mutation. */
export type BookingPassedDocumentInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    b: BookingInput;
};

/** The output of our `bookingPassedDocument` mutation. */
export type BookingPassedDocumentPayload = {
    __typename?: 'BookingPassedDocumentPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    json?: Maybe<Scalars['JSON']>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
};

/** Represents an update to a `Booking`. Fields that are set will be updated. */
export type BookingPatch = {
    id?: Maybe<Scalars['Int']>;
    courseScheduleId?: Maybe<Scalars['Int']>;
    participantId?: Maybe<Scalars['Int']>;
    registrationRegistered?: Maybe<Scalars['Boolean']>;
    registrationDate?: Maybe<Scalars['Date']>;
    registrationNote?: Maybe<Scalars['String']>;
    passed?: Maybe<Scalars['Boolean']>;
    missingHours?: Maybe<Scalars['Int']>;
    paymentReceived?: Maybe<Scalars['Boolean']>;
    paymentDate?: Maybe<Scalars['Date']>;
    paymentMethod?: Maybe<BookingPaymentMethod>;
    invoiceNumber?: Maybe<Scalars['BigInt']>;
    waitingPosition?: Maybe<Scalars['BigInt']>;
    documentsSend?: Maybe<Scalars['Boolean']>;
    note?: Maybe<Scalars['String']>;
    amount?: Maybe<Scalars['Float']>;
    createdAt?: Maybe<Scalars['Datetime']>;
    updatedAt?: Maybe<Scalars['Datetime']>;
    registrationConfirmation?: Maybe<Scalars['Boolean']>;
    invoiceCompanyName?: Maybe<Scalars['String']>;
    invoiceFamilyName?: Maybe<Scalars['String']>;
    invoiceSalutation?: Maybe<Scalars['String']>;
    invoiceTitle?: Maybe<Scalars['String']>;
    invoiceGivenName?: Maybe<Scalars['String']>;
    invoiceStreet?: Maybe<Scalars['String']>;
    invoicePostcode?: Maybe<Scalars['String']>;
    invoiceCity?: Maybe<Scalars['String']>;
    invoiceCountry?: Maybe<Scalars['String']>;
    invoiceBankOwner?: Maybe<Scalars['String']>;
    invoiceBankName?: Maybe<Scalars['String']>;
    invoiceBankSwift?: Maybe<Scalars['String']>;
    invoiceBankIban?: Maybe<Scalars['String']>;
    invoiceToEmployer?: Maybe<Scalars['Boolean']>;
    state?: Maybe<BookingState>;
    invoiceContactPhone?: Maybe<Scalars['String']>;
    invoiceContactEmail?: Maybe<Scalars['String']>;
    invoiceContactUrl?: Maybe<Scalars['String']>;
    accept?: Maybe<Scalars['Boolean']>;
    acceptPayment?: Maybe<Scalars['Boolean']>;
    invoiceDate?: Maybe<Scalars['Date']>;
    code?: Maybe<Scalars['UUID']>;
    useForCertification?: Maybe<Scalars['Boolean']>;
};

export enum BookingPaymentMethod {
    Cash = 'CASH',
    WireTransfer = 'WIRE_TRANSFER',
    DirectDebit = 'DIRECT_DEBIT',
}

/** A filter to be used against BookingPaymentMethod fields. All fields are combined with a logical ‘and.’ */
export type BookingPaymentMethodFilter = {
    /** Is null (if `true` is specified) or is not null (if `false` is specified). */
    isNull?: Maybe<Scalars['Boolean']>;
    /** Equal to the specified value. */
    equalTo?: Maybe<BookingPaymentMethod>;
    /** Not equal to the specified value. */
    notEqualTo?: Maybe<BookingPaymentMethod>;
    /** Not equal to the specified value, treating null like an ordinary value. */
    distinctFrom?: Maybe<BookingPaymentMethod>;
    /** Equal to the specified value, treating null like an ordinary value. */
    notDistinctFrom?: Maybe<BookingPaymentMethod>;
    /** Included in the specified list. */
    in?: Maybe<Array<BookingPaymentMethod>>;
    /** Not included in the specified list. */
    notIn?: Maybe<Array<BookingPaymentMethod>>;
    /** Less than the specified value. */
    lessThan?: Maybe<BookingPaymentMethod>;
    /** Less than or equal to the specified value. */
    lessThanOrEqualTo?: Maybe<BookingPaymentMethod>;
    /** Greater than the specified value. */
    greaterThan?: Maybe<BookingPaymentMethod>;
    /** Greater than or equal to the specified value. */
    greaterThanOrEqualTo?: Maybe<BookingPaymentMethod>;
};

export enum BookingState {
    Unread = 'UNREAD',
    Read = 'READ',
    Archived = 'ARCHIVED',
    Cancelled = 'CANCELLED',
}

/** A filter to be used against BookingState fields. All fields are combined with a logical ‘and.’ */
export type BookingStateFilter = {
    /** Is null (if `true` is specified) or is not null (if `false` is specified). */
    isNull?: Maybe<Scalars['Boolean']>;
    /** Equal to the specified value. */
    equalTo?: Maybe<BookingState>;
    /** Not equal to the specified value. */
    notEqualTo?: Maybe<BookingState>;
    /** Not equal to the specified value, treating null like an ordinary value. */
    distinctFrom?: Maybe<BookingState>;
    /** Equal to the specified value, treating null like an ordinary value. */
    notDistinctFrom?: Maybe<BookingState>;
    /** Included in the specified list. */
    in?: Maybe<Array<BookingState>>;
    /** Not included in the specified list. */
    notIn?: Maybe<Array<BookingState>>;
    /** Less than the specified value. */
    lessThan?: Maybe<BookingState>;
    /** Less than or equal to the specified value. */
    lessThanOrEqualTo?: Maybe<BookingState>;
    /** Greater than the specified value. */
    greaterThan?: Maybe<BookingState>;
    /** Greater than or equal to the specified value. */
    greaterThanOrEqualTo?: Maybe<BookingState>;
};

export type BookingStddevPopulationAggregates = {
    __typename?: 'BookingStddevPopulationAggregates';
    /** Population standard deviation of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Population standard deviation of courseScheduleId across the matching connection */
    courseScheduleId?: Maybe<Scalars['BigFloat']>;
    /** Population standard deviation of participantId across the matching connection */
    participantId?: Maybe<Scalars['BigFloat']>;
    /** Population standard deviation of missingHours across the matching connection */
    missingHours?: Maybe<Scalars['BigFloat']>;
    /** Population standard deviation of invoiceNumber across the matching connection */
    invoiceNumber?: Maybe<Scalars['BigFloat']>;
    /** Population standard deviation of waitingPosition across the matching connection */
    waitingPosition?: Maybe<Scalars['BigFloat']>;
    /** Population standard deviation of amount across the matching connection */
    amount?: Maybe<Scalars['Float']>;
};

export type BookingStddevSampleAggregates = {
    __typename?: 'BookingStddevSampleAggregates';
    /** Sample standard deviation of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Sample standard deviation of courseScheduleId across the matching connection */
    courseScheduleId?: Maybe<Scalars['BigFloat']>;
    /** Sample standard deviation of participantId across the matching connection */
    participantId?: Maybe<Scalars['BigFloat']>;
    /** Sample standard deviation of missingHours across the matching connection */
    missingHours?: Maybe<Scalars['BigFloat']>;
    /** Sample standard deviation of invoiceNumber across the matching connection */
    invoiceNumber?: Maybe<Scalars['BigFloat']>;
    /** Sample standard deviation of waitingPosition across the matching connection */
    waitingPosition?: Maybe<Scalars['BigFloat']>;
    /** Sample standard deviation of amount across the matching connection */
    amount?: Maybe<Scalars['Float']>;
};

export type BookingSumAggregates = {
    __typename?: 'BookingSumAggregates';
    /** Sum of id across the matching connection */
    id: Scalars['BigInt'];
    /** Sum of courseScheduleId across the matching connection */
    courseScheduleId: Scalars['BigInt'];
    /** Sum of participantId across the matching connection */
    participantId: Scalars['BigInt'];
    /** Sum of missingHours across the matching connection */
    missingHours: Scalars['BigInt'];
    /** Sum of invoiceNumber across the matching connection */
    invoiceNumber: Scalars['BigFloat'];
    /** Sum of waitingPosition across the matching connection */
    waitingPosition: Scalars['BigFloat'];
    /** Sum of amount across the matching connection */
    amount: Scalars['Float'];
};

export type BookingVariancePopulationAggregates = {
    __typename?: 'BookingVariancePopulationAggregates';
    /** Population variance of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Population variance of courseScheduleId across the matching connection */
    courseScheduleId?: Maybe<Scalars['BigFloat']>;
    /** Population variance of participantId across the matching connection */
    participantId?: Maybe<Scalars['BigFloat']>;
    /** Population variance of missingHours across the matching connection */
    missingHours?: Maybe<Scalars['BigFloat']>;
    /** Population variance of invoiceNumber across the matching connection */
    invoiceNumber?: Maybe<Scalars['BigFloat']>;
    /** Population variance of waitingPosition across the matching connection */
    waitingPosition?: Maybe<Scalars['BigFloat']>;
    /** Population variance of amount across the matching connection */
    amount?: Maybe<Scalars['Float']>;
};

export type BookingVarianceSampleAggregates = {
    __typename?: 'BookingVarianceSampleAggregates';
    /** Sample variance of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Sample variance of courseScheduleId across the matching connection */
    courseScheduleId?: Maybe<Scalars['BigFloat']>;
    /** Sample variance of participantId across the matching connection */
    participantId?: Maybe<Scalars['BigFloat']>;
    /** Sample variance of missingHours across the matching connection */
    missingHours?: Maybe<Scalars['BigFloat']>;
    /** Sample variance of invoiceNumber across the matching connection */
    invoiceNumber?: Maybe<Scalars['BigFloat']>;
    /** Sample variance of waitingPosition across the matching connection */
    waitingPosition?: Maybe<Scalars['BigFloat']>;
    /** Sample variance of amount across the matching connection */
    amount?: Maybe<Scalars['Float']>;
};

/** A connection to a list of `Booking` values. */
export type BookingsConnection = {
    __typename?: 'BookingsConnection';
    /** A list of `Booking` objects. */
    nodes: Array<Booking>;
    /** A list of edges which contains the `Booking` and cursor to aid in pagination. */
    edges: Array<BookingsEdge>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `Booking` you could get from the connection. */
    totalCount: Scalars['Int'];
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<BookingAggregates>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<BookingAggregates>>;
};

/** A connection to a list of `Booking` values. */
export type BookingsConnectionGroupedAggregatesArgs = {
    groupBy: Array<BookingGroupBy>;
    having?: Maybe<BookingHavingInput>;
};

/** A `Booking` edge in the connection. */
export type BookingsEdge = {
    __typename?: 'BookingsEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `Booking` at the end of the edge. */
    node: Booking;
};

/** Methods to use when ordering `Booking`. */
export enum BookingsOrderBy {
    Natural = 'NATURAL',
    IdAsc = 'ID_ASC',
    IdDesc = 'ID_DESC',
    CourseScheduleIdAsc = 'COURSE_SCHEDULE_ID_ASC',
    CourseScheduleIdDesc = 'COURSE_SCHEDULE_ID_DESC',
    ParticipantIdAsc = 'PARTICIPANT_ID_ASC',
    ParticipantIdDesc = 'PARTICIPANT_ID_DESC',
    RegistrationRegisteredAsc = 'REGISTRATION_REGISTERED_ASC',
    RegistrationRegisteredDesc = 'REGISTRATION_REGISTERED_DESC',
    RegistrationDateAsc = 'REGISTRATION_DATE_ASC',
    RegistrationDateDesc = 'REGISTRATION_DATE_DESC',
    RegistrationNoteAsc = 'REGISTRATION_NOTE_ASC',
    RegistrationNoteDesc = 'REGISTRATION_NOTE_DESC',
    PassedAsc = 'PASSED_ASC',
    PassedDesc = 'PASSED_DESC',
    MissingHoursAsc = 'MISSING_HOURS_ASC',
    MissingHoursDesc = 'MISSING_HOURS_DESC',
    PaymentReceivedAsc = 'PAYMENT_RECEIVED_ASC',
    PaymentReceivedDesc = 'PAYMENT_RECEIVED_DESC',
    PaymentDateAsc = 'PAYMENT_DATE_ASC',
    PaymentDateDesc = 'PAYMENT_DATE_DESC',
    PaymentMethodAsc = 'PAYMENT_METHOD_ASC',
    PaymentMethodDesc = 'PAYMENT_METHOD_DESC',
    InvoiceNumberAsc = 'INVOICE_NUMBER_ASC',
    InvoiceNumberDesc = 'INVOICE_NUMBER_DESC',
    WaitingPositionAsc = 'WAITING_POSITION_ASC',
    WaitingPositionDesc = 'WAITING_POSITION_DESC',
    DocumentsSendAsc = 'DOCUMENTS_SEND_ASC',
    DocumentsSendDesc = 'DOCUMENTS_SEND_DESC',
    NoteAsc = 'NOTE_ASC',
    NoteDesc = 'NOTE_DESC',
    AmountAsc = 'AMOUNT_ASC',
    AmountDesc = 'AMOUNT_DESC',
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
    UpdatedAtAsc = 'UPDATED_AT_ASC',
    UpdatedAtDesc = 'UPDATED_AT_DESC',
    RegistrationConfirmationAsc = 'REGISTRATION_CONFIRMATION_ASC',
    RegistrationConfirmationDesc = 'REGISTRATION_CONFIRMATION_DESC',
    InvoiceCompanyNameAsc = 'INVOICE_COMPANY_NAME_ASC',
    InvoiceCompanyNameDesc = 'INVOICE_COMPANY_NAME_DESC',
    InvoiceFamilyNameAsc = 'INVOICE_FAMILY_NAME_ASC',
    InvoiceFamilyNameDesc = 'INVOICE_FAMILY_NAME_DESC',
    InvoiceSalutationAsc = 'INVOICE_SALUTATION_ASC',
    InvoiceSalutationDesc = 'INVOICE_SALUTATION_DESC',
    InvoiceTitleAsc = 'INVOICE_TITLE_ASC',
    InvoiceTitleDesc = 'INVOICE_TITLE_DESC',
    InvoiceGivenNameAsc = 'INVOICE_GIVEN_NAME_ASC',
    InvoiceGivenNameDesc = 'INVOICE_GIVEN_NAME_DESC',
    InvoiceStreetAsc = 'INVOICE_STREET_ASC',
    InvoiceStreetDesc = 'INVOICE_STREET_DESC',
    InvoicePostcodeAsc = 'INVOICE_POSTCODE_ASC',
    InvoicePostcodeDesc = 'INVOICE_POSTCODE_DESC',
    InvoiceCityAsc = 'INVOICE_CITY_ASC',
    InvoiceCityDesc = 'INVOICE_CITY_DESC',
    InvoiceCountryAsc = 'INVOICE_COUNTRY_ASC',
    InvoiceCountryDesc = 'INVOICE_COUNTRY_DESC',
    InvoiceBankOwnerAsc = 'INVOICE_BANK_OWNER_ASC',
    InvoiceBankOwnerDesc = 'INVOICE_BANK_OWNER_DESC',
    InvoiceBankNameAsc = 'INVOICE_BANK_NAME_ASC',
    InvoiceBankNameDesc = 'INVOICE_BANK_NAME_DESC',
    InvoiceBankSwiftAsc = 'INVOICE_BANK_SWIFT_ASC',
    InvoiceBankSwiftDesc = 'INVOICE_BANK_SWIFT_DESC',
    InvoiceBankIbanAsc = 'INVOICE_BANK_IBAN_ASC',
    InvoiceBankIbanDesc = 'INVOICE_BANK_IBAN_DESC',
    InvoiceToEmployerAsc = 'INVOICE_TO_EMPLOYER_ASC',
    InvoiceToEmployerDesc = 'INVOICE_TO_EMPLOYER_DESC',
    StateAsc = 'STATE_ASC',
    StateDesc = 'STATE_DESC',
    InvoiceContactPhoneAsc = 'INVOICE_CONTACT_PHONE_ASC',
    InvoiceContactPhoneDesc = 'INVOICE_CONTACT_PHONE_DESC',
    InvoiceContactEmailAsc = 'INVOICE_CONTACT_EMAIL_ASC',
    InvoiceContactEmailDesc = 'INVOICE_CONTACT_EMAIL_DESC',
    InvoiceContactUrlAsc = 'INVOICE_CONTACT_URL_ASC',
    InvoiceContactUrlDesc = 'INVOICE_CONTACT_URL_DESC',
    AcceptAsc = 'ACCEPT_ASC',
    AcceptDesc = 'ACCEPT_DESC',
    AcceptPaymentAsc = 'ACCEPT_PAYMENT_ASC',
    AcceptPaymentDesc = 'ACCEPT_PAYMENT_DESC',
    InvoiceDateAsc = 'INVOICE_DATE_ASC',
    InvoiceDateDesc = 'INVOICE_DATE_DESC',
    CodeAsc = 'CODE_ASC',
    CodeDesc = 'CODE_DESC',
    UseForCertificationAsc = 'USE_FOR_CERTIFICATION_ASC',
    UseForCertificationDesc = 'USE_FOR_CERTIFICATION_DESC',
    PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
    PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

/** A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’ */
export type BooleanFilter = {
    /** Is null (if `true` is specified) or is not null (if `false` is specified). */
    isNull?: Maybe<Scalars['Boolean']>;
    /** Equal to the specified value. */
    equalTo?: Maybe<Scalars['Boolean']>;
    /** Not equal to the specified value. */
    notEqualTo?: Maybe<Scalars['Boolean']>;
    /** Not equal to the specified value, treating null like an ordinary value. */
    distinctFrom?: Maybe<Scalars['Boolean']>;
    /** Equal to the specified value, treating null like an ordinary value. */
    notDistinctFrom?: Maybe<Scalars['Boolean']>;
    /** Included in the specified list. */
    in?: Maybe<Array<Scalars['Boolean']>>;
    /** Not included in the specified list. */
    notIn?: Maybe<Array<Scalars['Boolean']>>;
    /** Less than the specified value. */
    lessThan?: Maybe<Scalars['Boolean']>;
    /** Less than or equal to the specified value. */
    lessThanOrEqualTo?: Maybe<Scalars['Boolean']>;
    /** Greater than the specified value. */
    greaterThan?: Maybe<Scalars['Boolean']>;
    /** Greater than or equal to the specified value. */
    greaterThanOrEqualTo?: Maybe<Scalars['Boolean']>;
};

/** All input for the `checkDatePattern` mutation. */
export type CheckDatePatternInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    startDate?: Maybe<Scalars['Date']>;
    endDate?: Maybe<Scalars['Date']>;
};

/** The output of our `checkDatePattern` mutation. */
export type CheckDatePatternPayload = {
    __typename?: 'CheckDatePatternPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    string?: Maybe<Scalars['String']>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
};

/** All input for the `combineParticipants` mutation. */
export type CombineParticipantsInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    oldParticipantId: Scalars['Int'];
    newParticipantId: Scalars['Int'];
};

/** The output of our `combineParticipants` mutation. */
export type CombineParticipantsPayload = {
    __typename?: 'CombineParticipantsPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    integer?: Maybe<Scalars['Int']>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
};

export type Course = Node & {
    __typename?: 'Course';
    /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
    nodeId: Scalars['ID'];
    id: Scalars['Int'];
    name?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    comment?: Maybe<Scalars['String']>;
    details?: Maybe<Scalars['String']>;
    archived?: Maybe<Scalars['Boolean']>;
    defaultLocationId?: Maybe<Scalars['Int']>;
    defaultTimetable?: Maybe<Scalars['String']>;
    courseGroupId?: Maybe<Scalars['Int']>;
    createdAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    defaultSeats?: Maybe<Scalars['Int']>;
    defaultDuration?: Maybe<Scalars['Int']>;
    defaultUnits?: Maybe<Scalars['Int']>;
    defaultPrice?: Maybe<Scalars['Float']>;
    certSetup?: Maybe<Scalars['JSON']>;
    confirmationContent?: Maybe<Scalars['String']>;
    /** Reads a single `Location` that is related to this `Course`. */
    defaultLocation?: Maybe<Location>;
    /** Reads a single `CourseGroup` that is related to this `Course`. */
    courseGroup?: Maybe<CourseGroup>;
    /** Reads and enables pagination through a set of `CourseSchedule`. */
    courseSchedules: CourseSchedulesConnection;
    /** Reads and enables pagination through a set of `CourseDefaultSpeaker`. */
    courseDefaultSpeakers: CourseDefaultSpeakersConnection;
    fullName?: Maybe<Scalars['String']>;
    fullNameNewline?: Maybe<Scalars['String']>;
    fullNameWithMenu?: Maybe<Scalars['String']>;
    lastDate?: Maybe<Scalars['Date']>;
    lastSchedule?: Maybe<CourseSchedule>;
    nextDate?: Maybe<Scalars['Date']>;
    nextSchedule?: Maybe<CourseSchedule>;
    plannedScheduleCount?: Maybe<Scalars['Int']>;
};

export type CourseCourseSchedulesArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['Cursor']>;
    after?: Maybe<Scalars['Cursor']>;
    orderBy?: Maybe<Array<CourseSchedulesOrderBy>>;
    condition?: Maybe<CourseScheduleCondition>;
    filter?: Maybe<CourseScheduleFilter>;
};

export type CourseCourseDefaultSpeakersArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['Cursor']>;
    after?: Maybe<Scalars['Cursor']>;
    orderBy?: Maybe<Array<CourseDefaultSpeakersOrderBy>>;
    condition?: Maybe<CourseDefaultSpeakerCondition>;
    filter?: Maybe<CourseDefaultSpeakerFilter>;
};

export type CourseAggregates = {
    __typename?: 'CourseAggregates';
    keys?: Maybe<Array<Scalars['String']>>;
    /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    sum?: Maybe<CourseSumAggregates>;
    /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
    distinctCount?: Maybe<CourseDistinctCountAggregates>;
    /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    min?: Maybe<CourseMinAggregates>;
    /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    max?: Maybe<CourseMaxAggregates>;
    /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
    average?: Maybe<CourseAverageAggregates>;
    /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
    stddevSample?: Maybe<CourseStddevSampleAggregates>;
    /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
    stddevPopulation?: Maybe<CourseStddevPopulationAggregates>;
    /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
    varianceSample?: Maybe<CourseVarianceSampleAggregates>;
    /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
    variancePopulation?: Maybe<CourseVariancePopulationAggregates>;
};

export type CourseAverageAggregates = {
    __typename?: 'CourseAverageAggregates';
    /** Mean average of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Mean average of defaultLocationId across the matching connection */
    defaultLocationId?: Maybe<Scalars['BigFloat']>;
    /** Mean average of courseGroupId across the matching connection */
    courseGroupId?: Maybe<Scalars['BigFloat']>;
    /** Mean average of defaultSeats across the matching connection */
    defaultSeats?: Maybe<Scalars['BigFloat']>;
    /** Mean average of defaultDuration across the matching connection */
    defaultDuration?: Maybe<Scalars['BigFloat']>;
    /** Mean average of defaultUnits across the matching connection */
    defaultUnits?: Maybe<Scalars['BigFloat']>;
    /** Mean average of defaultPrice across the matching connection */
    defaultPrice?: Maybe<Scalars['Float']>;
    /** Mean average of this field across the matching connection. */
    plannedScheduleCount?: Maybe<Scalars['BigFloat']>;
};

/** A condition to be used against `Course` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type CourseCondition = {
    /** Checks for equality with the object’s `id` field. */
    id?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `name` field. */
    name?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `description` field. */
    description?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `comment` field. */
    comment?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `details` field. */
    details?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `archived` field. */
    archived?: Maybe<Scalars['Boolean']>;
    /** Checks for equality with the object’s `defaultLocationId` field. */
    defaultLocationId?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `defaultTimetable` field. */
    defaultTimetable?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `courseGroupId` field. */
    courseGroupId?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `createdAt` field. */
    createdAt?: Maybe<Scalars['Datetime']>;
    /** Checks for equality with the object’s `updatedAt` field. */
    updatedAt?: Maybe<Scalars['Datetime']>;
    /** Checks for equality with the object’s `defaultSeats` field. */
    defaultSeats?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `defaultDuration` field. */
    defaultDuration?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `defaultUnits` field. */
    defaultUnits?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `defaultPrice` field. */
    defaultPrice?: Maybe<Scalars['Float']>;
    /** Checks for equality with the object’s `certSetup` field. */
    certSetup?: Maybe<Scalars['JSON']>;
    /** Checks for equality with the object’s `confirmationContent` field. */
    confirmationContent?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `fullName` field. */
    fullName?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `lastDate` field. */
    lastDate?: Maybe<Scalars['Date']>;
    /** Checks for equality with the object’s `nextDate` field. */
    nextDate?: Maybe<Scalars['Date']>;
    /** Checks for equality with the object’s `plannedScheduleCount` field. */
    plannedScheduleCount?: Maybe<Scalars['Int']>;
};

export type CourseDefaultSpeaker = Node & {
    __typename?: 'CourseDefaultSpeaker';
    /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
    nodeId: Scalars['ID'];
    speakerId: Scalars['Int'];
    courseId: Scalars['Int'];
    createdAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    /** Reads a single `Speaker` that is related to this `CourseDefaultSpeaker`. */
    speaker?: Maybe<Speaker>;
    /** Reads a single `Course` that is related to this `CourseDefaultSpeaker`. */
    course?: Maybe<Course>;
};

export type CourseDefaultSpeakerAggregates = {
    __typename?: 'CourseDefaultSpeakerAggregates';
    keys?: Maybe<Array<Scalars['String']>>;
    /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    sum?: Maybe<CourseDefaultSpeakerSumAggregates>;
    /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
    distinctCount?: Maybe<CourseDefaultSpeakerDistinctCountAggregates>;
    /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    min?: Maybe<CourseDefaultSpeakerMinAggregates>;
    /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    max?: Maybe<CourseDefaultSpeakerMaxAggregates>;
    /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
    average?: Maybe<CourseDefaultSpeakerAverageAggregates>;
    /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
    stddevSample?: Maybe<CourseDefaultSpeakerStddevSampleAggregates>;
    /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
    stddevPopulation?: Maybe<CourseDefaultSpeakerStddevPopulationAggregates>;
    /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
    varianceSample?: Maybe<CourseDefaultSpeakerVarianceSampleAggregates>;
    /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
    variancePopulation?: Maybe<CourseDefaultSpeakerVariancePopulationAggregates>;
};

export type CourseDefaultSpeakerAverageAggregates = {
    __typename?: 'CourseDefaultSpeakerAverageAggregates';
    /** Mean average of speakerId across the matching connection */
    speakerId?: Maybe<Scalars['BigFloat']>;
    /** Mean average of courseId across the matching connection */
    courseId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `CourseDefaultSpeaker` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type CourseDefaultSpeakerCondition = {
    /** Checks for equality with the object’s `speakerId` field. */
    speakerId?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `courseId` field. */
    courseId?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `createdAt` field. */
    createdAt?: Maybe<Scalars['Datetime']>;
    /** Checks for equality with the object’s `updatedAt` field. */
    updatedAt?: Maybe<Scalars['Datetime']>;
};

export type CourseDefaultSpeakerDistinctCountAggregates = {
    __typename?: 'CourseDefaultSpeakerDistinctCountAggregates';
    /** Distinct count of speakerId across the matching connection */
    speakerId?: Maybe<Scalars['BigInt']>;
    /** Distinct count of courseId across the matching connection */
    courseId?: Maybe<Scalars['BigInt']>;
    /** Distinct count of createdAt across the matching connection */
    createdAt?: Maybe<Scalars['BigInt']>;
    /** Distinct count of updatedAt across the matching connection */
    updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `CourseDefaultSpeaker` object types. All fields are combined with a logical ‘and.’ */
export type CourseDefaultSpeakerFilter = {
    /** Filter by the object’s `speakerId` field. */
    speakerId?: Maybe<IntFilter>;
    /** Filter by the object’s `courseId` field. */
    courseId?: Maybe<IntFilter>;
    /** Filter by the object’s `createdAt` field. */
    createdAt?: Maybe<DatetimeFilter>;
    /** Filter by the object’s `updatedAt` field. */
    updatedAt?: Maybe<DatetimeFilter>;
    /** Checks for all expressions in this list. */
    and?: Maybe<Array<CourseDefaultSpeakerFilter>>;
    /** Checks for any expressions in this list. */
    or?: Maybe<Array<CourseDefaultSpeakerFilter>>;
    /** Negates the expression. */
    not?: Maybe<CourseDefaultSpeakerFilter>;
};

/** Grouping methods for `CourseDefaultSpeaker` for usage during aggregation. */
export enum CourseDefaultSpeakerGroupBy {
    SpeakerId = 'SPEAKER_ID',
    CourseId = 'COURSE_ID',
    CreatedAt = 'CREATED_AT',
    CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
    CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
    UpdatedAt = 'UPDATED_AT',
    UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
    UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
}

export type CourseDefaultSpeakerHavingAverageInput = {
    speakerId?: Maybe<HavingIntFilter>;
    courseId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CourseDefaultSpeakerHavingDistinctCountInput = {
    speakerId?: Maybe<HavingIntFilter>;
    courseId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `CourseDefaultSpeaker` aggregates. */
export type CourseDefaultSpeakerHavingInput = {
    AND?: Maybe<Array<CourseDefaultSpeakerHavingInput>>;
    OR?: Maybe<Array<CourseDefaultSpeakerHavingInput>>;
    sum?: Maybe<CourseDefaultSpeakerHavingSumInput>;
    distinctCount?: Maybe<CourseDefaultSpeakerHavingDistinctCountInput>;
    min?: Maybe<CourseDefaultSpeakerHavingMinInput>;
    max?: Maybe<CourseDefaultSpeakerHavingMaxInput>;
    average?: Maybe<CourseDefaultSpeakerHavingAverageInput>;
    stddevSample?: Maybe<CourseDefaultSpeakerHavingStddevSampleInput>;
    stddevPopulation?: Maybe<CourseDefaultSpeakerHavingStddevPopulationInput>;
    varianceSample?: Maybe<CourseDefaultSpeakerHavingVarianceSampleInput>;
    variancePopulation?: Maybe<CourseDefaultSpeakerHavingVariancePopulationInput>;
};

export type CourseDefaultSpeakerHavingMaxInput = {
    speakerId?: Maybe<HavingIntFilter>;
    courseId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CourseDefaultSpeakerHavingMinInput = {
    speakerId?: Maybe<HavingIntFilter>;
    courseId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CourseDefaultSpeakerHavingStddevPopulationInput = {
    speakerId?: Maybe<HavingIntFilter>;
    courseId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CourseDefaultSpeakerHavingStddevSampleInput = {
    speakerId?: Maybe<HavingIntFilter>;
    courseId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CourseDefaultSpeakerHavingSumInput = {
    speakerId?: Maybe<HavingIntFilter>;
    courseId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CourseDefaultSpeakerHavingVariancePopulationInput = {
    speakerId?: Maybe<HavingIntFilter>;
    courseId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CourseDefaultSpeakerHavingVarianceSampleInput = {
    speakerId?: Maybe<HavingIntFilter>;
    courseId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `CourseDefaultSpeaker` */
export type CourseDefaultSpeakerInput = {
    speakerId: Scalars['Int'];
    courseId: Scalars['Int'];
    createdAt?: Maybe<Scalars['Datetime']>;
    updatedAt?: Maybe<Scalars['Datetime']>;
};

export type CourseDefaultSpeakerMaxAggregates = {
    __typename?: 'CourseDefaultSpeakerMaxAggregates';
    /** Maximum of speakerId across the matching connection */
    speakerId?: Maybe<Scalars['Int']>;
    /** Maximum of courseId across the matching connection */
    courseId?: Maybe<Scalars['Int']>;
};

export type CourseDefaultSpeakerMinAggregates = {
    __typename?: 'CourseDefaultSpeakerMinAggregates';
    /** Minimum of speakerId across the matching connection */
    speakerId?: Maybe<Scalars['Int']>;
    /** Minimum of courseId across the matching connection */
    courseId?: Maybe<Scalars['Int']>;
};

/** Represents an update to a `CourseDefaultSpeaker`. Fields that are set will be updated. */
export type CourseDefaultSpeakerPatch = {
    speakerId?: Maybe<Scalars['Int']>;
    courseId?: Maybe<Scalars['Int']>;
    createdAt?: Maybe<Scalars['Datetime']>;
    updatedAt?: Maybe<Scalars['Datetime']>;
};

export type CourseDefaultSpeakerStddevPopulationAggregates = {
    __typename?: 'CourseDefaultSpeakerStddevPopulationAggregates';
    /** Population standard deviation of speakerId across the matching connection */
    speakerId?: Maybe<Scalars['BigFloat']>;
    /** Population standard deviation of courseId across the matching connection */
    courseId?: Maybe<Scalars['BigFloat']>;
};

export type CourseDefaultSpeakerStddevSampleAggregates = {
    __typename?: 'CourseDefaultSpeakerStddevSampleAggregates';
    /** Sample standard deviation of speakerId across the matching connection */
    speakerId?: Maybe<Scalars['BigFloat']>;
    /** Sample standard deviation of courseId across the matching connection */
    courseId?: Maybe<Scalars['BigFloat']>;
};

export type CourseDefaultSpeakerSumAggregates = {
    __typename?: 'CourseDefaultSpeakerSumAggregates';
    /** Sum of speakerId across the matching connection */
    speakerId: Scalars['BigInt'];
    /** Sum of courseId across the matching connection */
    courseId: Scalars['BigInt'];
};

export type CourseDefaultSpeakerVariancePopulationAggregates = {
    __typename?: 'CourseDefaultSpeakerVariancePopulationAggregates';
    /** Population variance of speakerId across the matching connection */
    speakerId?: Maybe<Scalars['BigFloat']>;
    /** Population variance of courseId across the matching connection */
    courseId?: Maybe<Scalars['BigFloat']>;
};

export type CourseDefaultSpeakerVarianceSampleAggregates = {
    __typename?: 'CourseDefaultSpeakerVarianceSampleAggregates';
    /** Sample variance of speakerId across the matching connection */
    speakerId?: Maybe<Scalars['BigFloat']>;
    /** Sample variance of courseId across the matching connection */
    courseId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `CourseDefaultSpeaker` values. */
export type CourseDefaultSpeakersConnection = {
    __typename?: 'CourseDefaultSpeakersConnection';
    /** A list of `CourseDefaultSpeaker` objects. */
    nodes: Array<CourseDefaultSpeaker>;
    /** A list of edges which contains the `CourseDefaultSpeaker` and cursor to aid in pagination. */
    edges: Array<CourseDefaultSpeakersEdge>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `CourseDefaultSpeaker` you could get from the connection. */
    totalCount: Scalars['Int'];
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<CourseDefaultSpeakerAggregates>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<CourseDefaultSpeakerAggregates>>;
};

/** A connection to a list of `CourseDefaultSpeaker` values. */
export type CourseDefaultSpeakersConnectionGroupedAggregatesArgs = {
    groupBy: Array<CourseDefaultSpeakerGroupBy>;
    having?: Maybe<CourseDefaultSpeakerHavingInput>;
};

/** A `CourseDefaultSpeaker` edge in the connection. */
export type CourseDefaultSpeakersEdge = {
    __typename?: 'CourseDefaultSpeakersEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `CourseDefaultSpeaker` at the end of the edge. */
    node: CourseDefaultSpeaker;
};

/** Methods to use when ordering `CourseDefaultSpeaker`. */
export enum CourseDefaultSpeakersOrderBy {
    Natural = 'NATURAL',
    SpeakerIdAsc = 'SPEAKER_ID_ASC',
    SpeakerIdDesc = 'SPEAKER_ID_DESC',
    CourseIdAsc = 'COURSE_ID_ASC',
    CourseIdDesc = 'COURSE_ID_DESC',
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
    UpdatedAtAsc = 'UPDATED_AT_ASC',
    UpdatedAtDesc = 'UPDATED_AT_DESC',
    PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
    PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

export type CourseDistinctCountAggregates = {
    __typename?: 'CourseDistinctCountAggregates';
    /** Distinct count of id across the matching connection */
    id?: Maybe<Scalars['BigInt']>;
    /** Distinct count of name across the matching connection */
    name?: Maybe<Scalars['BigInt']>;
    /** Distinct count of description across the matching connection */
    description?: Maybe<Scalars['BigInt']>;
    /** Distinct count of comment across the matching connection */
    comment?: Maybe<Scalars['BigInt']>;
    /** Distinct count of details across the matching connection */
    details?: Maybe<Scalars['BigInt']>;
    /** Distinct count of archived across the matching connection */
    archived?: Maybe<Scalars['BigInt']>;
    /** Distinct count of defaultLocationId across the matching connection */
    defaultLocationId?: Maybe<Scalars['BigInt']>;
    /** Distinct count of defaultTimetable across the matching connection */
    defaultTimetable?: Maybe<Scalars['BigInt']>;
    /** Distinct count of courseGroupId across the matching connection */
    courseGroupId?: Maybe<Scalars['BigInt']>;
    /** Distinct count of createdAt across the matching connection */
    createdAt?: Maybe<Scalars['BigInt']>;
    /** Distinct count of updatedAt across the matching connection */
    updatedAt?: Maybe<Scalars['BigInt']>;
    /** Distinct count of defaultSeats across the matching connection */
    defaultSeats?: Maybe<Scalars['BigInt']>;
    /** Distinct count of defaultDuration across the matching connection */
    defaultDuration?: Maybe<Scalars['BigInt']>;
    /** Distinct count of defaultUnits across the matching connection */
    defaultUnits?: Maybe<Scalars['BigInt']>;
    /** Distinct count of defaultPrice across the matching connection */
    defaultPrice?: Maybe<Scalars['BigInt']>;
    /** Distinct count of certSetup across the matching connection */
    certSetup?: Maybe<Scalars['BigInt']>;
    /** Distinct count of confirmationContent across the matching connection */
    confirmationContent?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    fullName?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    fullNameNewline?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    fullNameWithMenu?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    lastDate?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    lastSchedule?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    nextDate?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    nextSchedule?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    plannedScheduleCount?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `Course` object types. All fields are combined with a logical ‘and.’ */
export type CourseFilter = {
    /** Filter by the object’s `id` field. */
    id?: Maybe<IntFilter>;
    /** Filter by the object’s `name` field. */
    name?: Maybe<StringFilter>;
    /** Filter by the object’s `description` field. */
    description?: Maybe<StringFilter>;
    /** Filter by the object’s `comment` field. */
    comment?: Maybe<StringFilter>;
    /** Filter by the object’s `details` field. */
    details?: Maybe<StringFilter>;
    /** Filter by the object’s `archived` field. */
    archived?: Maybe<BooleanFilter>;
    /** Filter by the object’s `defaultLocationId` field. */
    defaultLocationId?: Maybe<IntFilter>;
    /** Filter by the object’s `defaultTimetable` field. */
    defaultTimetable?: Maybe<StringFilter>;
    /** Filter by the object’s `courseGroupId` field. */
    courseGroupId?: Maybe<IntFilter>;
    /** Filter by the object’s `createdAt` field. */
    createdAt?: Maybe<DatetimeFilter>;
    /** Filter by the object’s `updatedAt` field. */
    updatedAt?: Maybe<DatetimeFilter>;
    /** Filter by the object’s `defaultSeats` field. */
    defaultSeats?: Maybe<IntFilter>;
    /** Filter by the object’s `defaultDuration` field. */
    defaultDuration?: Maybe<IntFilter>;
    /** Filter by the object’s `defaultUnits` field. */
    defaultUnits?: Maybe<IntFilter>;
    /** Filter by the object’s `defaultPrice` field. */
    defaultPrice?: Maybe<FloatFilter>;
    /** Filter by the object’s `certSetup` field. */
    certSetup?: Maybe<JsonFilter>;
    /** Filter by the object’s `confirmationContent` field. */
    confirmationContent?: Maybe<StringFilter>;
    /** Filter by the object’s `fullName` field. */
    fullName?: Maybe<StringFilter>;
    /** Filter by the object’s `fullNameNewline` field. */
    fullNameNewline?: Maybe<StringFilter>;
    /** Filter by the object’s `fullNameWithMenu` field. */
    fullNameWithMenu?: Maybe<StringFilter>;
    /** Filter by the object’s `lastDate` field. */
    lastDate?: Maybe<DateFilter>;
    /** Filter by the object’s `nextDate` field. */
    nextDate?: Maybe<DateFilter>;
    /** Filter by the object’s `plannedScheduleCount` field. */
    plannedScheduleCount?: Maybe<IntFilter>;
    /** Checks for all expressions in this list. */
    and?: Maybe<Array<CourseFilter>>;
    /** Checks for any expressions in this list. */
    or?: Maybe<Array<CourseFilter>>;
    /** Negates the expression. */
    not?: Maybe<CourseFilter>;
};

export type CourseGroup = Node & {
    __typename?: 'CourseGroup';
    /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
    nodeId: Scalars['ID'];
    id: Scalars['Int'];
    title?: Maybe<Scalars['String']>;
    archived?: Maybe<Scalars['Boolean']>;
    description?: Maybe<Scalars['String']>;
    topic?: Maybe<Scalars['String']>;
    content?: Maybe<Scalars['String']>;
    courseGroupMenuId?: Maybe<Scalars['Int']>;
    externalUrl?: Maybe<Scalars['String']>;
    createdAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    notes?: Maybe<Scalars['String']>;
    /** Reads a single `CourseGroupMenu` that is related to this `CourseGroup`. */
    courseGroupMenu?: Maybe<CourseGroupMenu>;
    /** Reads and enables pagination through a set of `Course`. */
    courses: CoursesConnection;
};

export type CourseGroupCoursesArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['Cursor']>;
    after?: Maybe<Scalars['Cursor']>;
    orderBy?: Maybe<Array<CoursesOrderBy>>;
    condition?: Maybe<CourseCondition>;
    filter?: Maybe<CourseFilter>;
};

export type CourseGroupAggregates = {
    __typename?: 'CourseGroupAggregates';
    keys?: Maybe<Array<Scalars['String']>>;
    /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    sum?: Maybe<CourseGroupSumAggregates>;
    /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
    distinctCount?: Maybe<CourseGroupDistinctCountAggregates>;
    /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    min?: Maybe<CourseGroupMinAggregates>;
    /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    max?: Maybe<CourseGroupMaxAggregates>;
    /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
    average?: Maybe<CourseGroupAverageAggregates>;
    /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
    stddevSample?: Maybe<CourseGroupStddevSampleAggregates>;
    /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
    stddevPopulation?: Maybe<CourseGroupStddevPopulationAggregates>;
    /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
    varianceSample?: Maybe<CourseGroupVarianceSampleAggregates>;
    /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
    variancePopulation?: Maybe<CourseGroupVariancePopulationAggregates>;
};

export type CourseGroupAverageAggregates = {
    __typename?: 'CourseGroupAverageAggregates';
    /** Mean average of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Mean average of courseGroupMenuId across the matching connection */
    courseGroupMenuId?: Maybe<Scalars['BigFloat']>;
};

/** Grouping methods for `Course` for usage during aggregation. */
export enum CourseGroupBy {
    Name = 'NAME',
    Description = 'DESCRIPTION',
    Comment = 'COMMENT',
    Details = 'DETAILS',
    Archived = 'ARCHIVED',
    DefaultLocationId = 'DEFAULT_LOCATION_ID',
    DefaultTimetable = 'DEFAULT_TIMETABLE',
    CourseGroupId = 'COURSE_GROUP_ID',
    CreatedAt = 'CREATED_AT',
    CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
    CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
    UpdatedAt = 'UPDATED_AT',
    UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
    UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
    DefaultSeats = 'DEFAULT_SEATS',
    DefaultDuration = 'DEFAULT_DURATION',
    DefaultUnits = 'DEFAULT_UNITS',
    DefaultPrice = 'DEFAULT_PRICE',
    CertSetup = 'CERT_SETUP',
    ConfirmationContent = 'CONFIRMATION_CONTENT',
}

/**
 * A condition to be used against `CourseGroup` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type CourseGroupCondition = {
    /** Checks for equality with the object’s `id` field. */
    id?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `title` field. */
    title?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `archived` field. */
    archived?: Maybe<Scalars['Boolean']>;
    /** Checks for equality with the object’s `description` field. */
    description?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `topic` field. */
    topic?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `content` field. */
    content?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `courseGroupMenuId` field. */
    courseGroupMenuId?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `externalUrl` field. */
    externalUrl?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `createdAt` field. */
    createdAt?: Maybe<Scalars['Datetime']>;
    /** Checks for equality with the object’s `updatedAt` field. */
    updatedAt?: Maybe<Scalars['Datetime']>;
    /** Checks for equality with the object’s `notes` field. */
    notes?: Maybe<Scalars['String']>;
};

export type CourseGroupDistinctCountAggregates = {
    __typename?: 'CourseGroupDistinctCountAggregates';
    /** Distinct count of id across the matching connection */
    id?: Maybe<Scalars['BigInt']>;
    /** Distinct count of title across the matching connection */
    title?: Maybe<Scalars['BigInt']>;
    /** Distinct count of archived across the matching connection */
    archived?: Maybe<Scalars['BigInt']>;
    /** Distinct count of description across the matching connection */
    description?: Maybe<Scalars['BigInt']>;
    /** Distinct count of topic across the matching connection */
    topic?: Maybe<Scalars['BigInt']>;
    /** Distinct count of content across the matching connection */
    content?: Maybe<Scalars['BigInt']>;
    /** Distinct count of courseGroupMenuId across the matching connection */
    courseGroupMenuId?: Maybe<Scalars['BigInt']>;
    /** Distinct count of externalUrl across the matching connection */
    externalUrl?: Maybe<Scalars['BigInt']>;
    /** Distinct count of createdAt across the matching connection */
    createdAt?: Maybe<Scalars['BigInt']>;
    /** Distinct count of updatedAt across the matching connection */
    updatedAt?: Maybe<Scalars['BigInt']>;
    /** Distinct count of notes across the matching connection */
    notes?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `CourseGroup` object types. All fields are combined with a logical ‘and.’ */
export type CourseGroupFilter = {
    /** Filter by the object’s `id` field. */
    id?: Maybe<IntFilter>;
    /** Filter by the object’s `title` field. */
    title?: Maybe<StringFilter>;
    /** Filter by the object’s `archived` field. */
    archived?: Maybe<BooleanFilter>;
    /** Filter by the object’s `description` field. */
    description?: Maybe<StringFilter>;
    /** Filter by the object’s `topic` field. */
    topic?: Maybe<StringFilter>;
    /** Filter by the object’s `content` field. */
    content?: Maybe<StringFilter>;
    /** Filter by the object’s `courseGroupMenuId` field. */
    courseGroupMenuId?: Maybe<IntFilter>;
    /** Filter by the object’s `externalUrl` field. */
    externalUrl?: Maybe<StringFilter>;
    /** Filter by the object’s `createdAt` field. */
    createdAt?: Maybe<DatetimeFilter>;
    /** Filter by the object’s `updatedAt` field. */
    updatedAt?: Maybe<DatetimeFilter>;
    /** Filter by the object’s `notes` field. */
    notes?: Maybe<StringFilter>;
    /** Checks for all expressions in this list. */
    and?: Maybe<Array<CourseGroupFilter>>;
    /** Checks for any expressions in this list. */
    or?: Maybe<Array<CourseGroupFilter>>;
    /** Negates the expression. */
    not?: Maybe<CourseGroupFilter>;
};

/** Grouping methods for `CourseGroup` for usage during aggregation. */
export enum CourseGroupGroupBy {
    Title = 'TITLE',
    Archived = 'ARCHIVED',
    Description = 'DESCRIPTION',
    Topic = 'TOPIC',
    Content = 'CONTENT',
    CourseGroupMenuId = 'COURSE_GROUP_MENU_ID',
    ExternalUrl = 'EXTERNAL_URL',
    CreatedAt = 'CREATED_AT',
    CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
    CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
    UpdatedAt = 'UPDATED_AT',
    UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
    UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
    Notes = 'NOTES',
}

export type CourseGroupHavingAverageInput = {
    id?: Maybe<HavingIntFilter>;
    courseGroupMenuId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CourseGroupHavingDistinctCountInput = {
    id?: Maybe<HavingIntFilter>;
    courseGroupMenuId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `CourseGroup` aggregates. */
export type CourseGroupHavingInput = {
    AND?: Maybe<Array<CourseGroupHavingInput>>;
    OR?: Maybe<Array<CourseGroupHavingInput>>;
    sum?: Maybe<CourseGroupHavingSumInput>;
    distinctCount?: Maybe<CourseGroupHavingDistinctCountInput>;
    min?: Maybe<CourseGroupHavingMinInput>;
    max?: Maybe<CourseGroupHavingMaxInput>;
    average?: Maybe<CourseGroupHavingAverageInput>;
    stddevSample?: Maybe<CourseGroupHavingStddevSampleInput>;
    stddevPopulation?: Maybe<CourseGroupHavingStddevPopulationInput>;
    varianceSample?: Maybe<CourseGroupHavingVarianceSampleInput>;
    variancePopulation?: Maybe<CourseGroupHavingVariancePopulationInput>;
};

export type CourseGroupHavingMaxInput = {
    id?: Maybe<HavingIntFilter>;
    courseGroupMenuId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CourseGroupHavingMinInput = {
    id?: Maybe<HavingIntFilter>;
    courseGroupMenuId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CourseGroupHavingStddevPopulationInput = {
    id?: Maybe<HavingIntFilter>;
    courseGroupMenuId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CourseGroupHavingStddevSampleInput = {
    id?: Maybe<HavingIntFilter>;
    courseGroupMenuId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CourseGroupHavingSumInput = {
    id?: Maybe<HavingIntFilter>;
    courseGroupMenuId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CourseGroupHavingVariancePopulationInput = {
    id?: Maybe<HavingIntFilter>;
    courseGroupMenuId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CourseGroupHavingVarianceSampleInput = {
    id?: Maybe<HavingIntFilter>;
    courseGroupMenuId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `CourseGroup` */
export type CourseGroupInput = {
    id?: Maybe<Scalars['Int']>;
    title?: Maybe<Scalars['String']>;
    archived?: Maybe<Scalars['Boolean']>;
    description?: Maybe<Scalars['String']>;
    topic?: Maybe<Scalars['String']>;
    content?: Maybe<Scalars['String']>;
    courseGroupMenuId?: Maybe<Scalars['Int']>;
    externalUrl?: Maybe<Scalars['String']>;
    createdAt?: Maybe<Scalars['Datetime']>;
    updatedAt?: Maybe<Scalars['Datetime']>;
    notes?: Maybe<Scalars['String']>;
};

export type CourseGroupMaxAggregates = {
    __typename?: 'CourseGroupMaxAggregates';
    /** Maximum of id across the matching connection */
    id?: Maybe<Scalars['Int']>;
    /** Maximum of courseGroupMenuId across the matching connection */
    courseGroupMenuId?: Maybe<Scalars['Int']>;
};

export type CourseGroupMenu = Node & {
    __typename?: 'CourseGroupMenu';
    /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
    nodeId: Scalars['ID'];
    id: Scalars['Int'];
    shortcut?: Maybe<Scalars['String']>;
    titleOverview?: Maybe<Scalars['String']>;
    descriptionOverview?: Maybe<Scalars['String']>;
    imageurlOverview?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    imageurl?: Maybe<Scalars['String']>;
    archived?: Maybe<Scalars['Boolean']>;
    createdAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    /** Reads and enables pagination through a set of `CourseGroup`. */
    courseGroups: CourseGroupsConnection;
};

export type CourseGroupMenuCourseGroupsArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['Cursor']>;
    after?: Maybe<Scalars['Cursor']>;
    orderBy?: Maybe<Array<CourseGroupsOrderBy>>;
    condition?: Maybe<CourseGroupCondition>;
    filter?: Maybe<CourseGroupFilter>;
};

export type CourseGroupMenuAggregates = {
    __typename?: 'CourseGroupMenuAggregates';
    keys?: Maybe<Array<Scalars['String']>>;
    /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    sum?: Maybe<CourseGroupMenuSumAggregates>;
    /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
    distinctCount?: Maybe<CourseGroupMenuDistinctCountAggregates>;
    /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    min?: Maybe<CourseGroupMenuMinAggregates>;
    /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    max?: Maybe<CourseGroupMenuMaxAggregates>;
    /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
    average?: Maybe<CourseGroupMenuAverageAggregates>;
    /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
    stddevSample?: Maybe<CourseGroupMenuStddevSampleAggregates>;
    /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
    stddevPopulation?: Maybe<CourseGroupMenuStddevPopulationAggregates>;
    /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
    varianceSample?: Maybe<CourseGroupMenuVarianceSampleAggregates>;
    /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
    variancePopulation?: Maybe<CourseGroupMenuVariancePopulationAggregates>;
};

export type CourseGroupMenuAverageAggregates = {
    __typename?: 'CourseGroupMenuAverageAggregates';
    /** Mean average of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `CourseGroupMenu` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CourseGroupMenuCondition = {
    /** Checks for equality with the object’s `id` field. */
    id?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `shortcut` field. */
    shortcut?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `titleOverview` field. */
    titleOverview?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `descriptionOverview` field. */
    descriptionOverview?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `imageurlOverview` field. */
    imageurlOverview?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `title` field. */
    title?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `description` field. */
    description?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `imageurl` field. */
    imageurl?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `archived` field. */
    archived?: Maybe<Scalars['Boolean']>;
    /** Checks for equality with the object’s `createdAt` field. */
    createdAt?: Maybe<Scalars['Datetime']>;
    /** Checks for equality with the object’s `updatedAt` field. */
    updatedAt?: Maybe<Scalars['Datetime']>;
};

export type CourseGroupMenuDistinctCountAggregates = {
    __typename?: 'CourseGroupMenuDistinctCountAggregates';
    /** Distinct count of id across the matching connection */
    id?: Maybe<Scalars['BigInt']>;
    /** Distinct count of shortcut across the matching connection */
    shortcut?: Maybe<Scalars['BigInt']>;
    /** Distinct count of titleOverview across the matching connection */
    titleOverview?: Maybe<Scalars['BigInt']>;
    /** Distinct count of descriptionOverview across the matching connection */
    descriptionOverview?: Maybe<Scalars['BigInt']>;
    /** Distinct count of imageurlOverview across the matching connection */
    imageurlOverview?: Maybe<Scalars['BigInt']>;
    /** Distinct count of title across the matching connection */
    title?: Maybe<Scalars['BigInt']>;
    /** Distinct count of description across the matching connection */
    description?: Maybe<Scalars['BigInt']>;
    /** Distinct count of imageurl across the matching connection */
    imageurl?: Maybe<Scalars['BigInt']>;
    /** Distinct count of archived across the matching connection */
    archived?: Maybe<Scalars['BigInt']>;
    /** Distinct count of createdAt across the matching connection */
    createdAt?: Maybe<Scalars['BigInt']>;
    /** Distinct count of updatedAt across the matching connection */
    updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `CourseGroupMenu` object types. All fields are combined with a logical ‘and.’ */
export type CourseGroupMenuFilter = {
    /** Filter by the object’s `id` field. */
    id?: Maybe<IntFilter>;
    /** Filter by the object’s `shortcut` field. */
    shortcut?: Maybe<StringFilter>;
    /** Filter by the object’s `titleOverview` field. */
    titleOverview?: Maybe<StringFilter>;
    /** Filter by the object’s `descriptionOverview` field. */
    descriptionOverview?: Maybe<StringFilter>;
    /** Filter by the object’s `imageurlOverview` field. */
    imageurlOverview?: Maybe<StringFilter>;
    /** Filter by the object’s `title` field. */
    title?: Maybe<StringFilter>;
    /** Filter by the object’s `description` field. */
    description?: Maybe<StringFilter>;
    /** Filter by the object’s `imageurl` field. */
    imageurl?: Maybe<StringFilter>;
    /** Filter by the object’s `archived` field. */
    archived?: Maybe<BooleanFilter>;
    /** Filter by the object’s `createdAt` field. */
    createdAt?: Maybe<DatetimeFilter>;
    /** Filter by the object’s `updatedAt` field. */
    updatedAt?: Maybe<DatetimeFilter>;
    /** Checks for all expressions in this list. */
    and?: Maybe<Array<CourseGroupMenuFilter>>;
    /** Checks for any expressions in this list. */
    or?: Maybe<Array<CourseGroupMenuFilter>>;
    /** Negates the expression. */
    not?: Maybe<CourseGroupMenuFilter>;
};

/** Grouping methods for `CourseGroupMenu` for usage during aggregation. */
export enum CourseGroupMenuGroupBy {
    Shortcut = 'SHORTCUT',
    TitleOverview = 'TITLE_OVERVIEW',
    DescriptionOverview = 'DESCRIPTION_OVERVIEW',
    ImageurlOverview = 'IMAGEURL_OVERVIEW',
    Title = 'TITLE',
    Description = 'DESCRIPTION',
    Imageurl = 'IMAGEURL',
    Archived = 'ARCHIVED',
    CreatedAt = 'CREATED_AT',
    CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
    CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
    UpdatedAt = 'UPDATED_AT',
    UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
    UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
}

export type CourseGroupMenuHavingAverageInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CourseGroupMenuHavingDistinctCountInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `CourseGroupMenu` aggregates. */
export type CourseGroupMenuHavingInput = {
    AND?: Maybe<Array<CourseGroupMenuHavingInput>>;
    OR?: Maybe<Array<CourseGroupMenuHavingInput>>;
    sum?: Maybe<CourseGroupMenuHavingSumInput>;
    distinctCount?: Maybe<CourseGroupMenuHavingDistinctCountInput>;
    min?: Maybe<CourseGroupMenuHavingMinInput>;
    max?: Maybe<CourseGroupMenuHavingMaxInput>;
    average?: Maybe<CourseGroupMenuHavingAverageInput>;
    stddevSample?: Maybe<CourseGroupMenuHavingStddevSampleInput>;
    stddevPopulation?: Maybe<CourseGroupMenuHavingStddevPopulationInput>;
    varianceSample?: Maybe<CourseGroupMenuHavingVarianceSampleInput>;
    variancePopulation?: Maybe<CourseGroupMenuHavingVariancePopulationInput>;
};

export type CourseGroupMenuHavingMaxInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CourseGroupMenuHavingMinInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CourseGroupMenuHavingStddevPopulationInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CourseGroupMenuHavingStddevSampleInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CourseGroupMenuHavingSumInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CourseGroupMenuHavingVariancePopulationInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CourseGroupMenuHavingVarianceSampleInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `CourseGroupMenu` */
export type CourseGroupMenuInput = {
    id?: Maybe<Scalars['Int']>;
    shortcut?: Maybe<Scalars['String']>;
    titleOverview?: Maybe<Scalars['String']>;
    descriptionOverview?: Maybe<Scalars['String']>;
    imageurlOverview?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    imageurl?: Maybe<Scalars['String']>;
    archived?: Maybe<Scalars['Boolean']>;
    createdAt?: Maybe<Scalars['Datetime']>;
    updatedAt?: Maybe<Scalars['Datetime']>;
};

export type CourseGroupMenuMaxAggregates = {
    __typename?: 'CourseGroupMenuMaxAggregates';
    /** Maximum of id across the matching connection */
    id?: Maybe<Scalars['Int']>;
};

export type CourseGroupMenuMinAggregates = {
    __typename?: 'CourseGroupMenuMinAggregates';
    /** Minimum of id across the matching connection */
    id?: Maybe<Scalars['Int']>;
};

/** Represents an update to a `CourseGroupMenu`. Fields that are set will be updated. */
export type CourseGroupMenuPatch = {
    id?: Maybe<Scalars['Int']>;
    shortcut?: Maybe<Scalars['String']>;
    titleOverview?: Maybe<Scalars['String']>;
    descriptionOverview?: Maybe<Scalars['String']>;
    imageurlOverview?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    imageurl?: Maybe<Scalars['String']>;
    archived?: Maybe<Scalars['Boolean']>;
    createdAt?: Maybe<Scalars['Datetime']>;
    updatedAt?: Maybe<Scalars['Datetime']>;
};

export type CourseGroupMenuStddevPopulationAggregates = {
    __typename?: 'CourseGroupMenuStddevPopulationAggregates';
    /** Population standard deviation of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
};

export type CourseGroupMenuStddevSampleAggregates = {
    __typename?: 'CourseGroupMenuStddevSampleAggregates';
    /** Sample standard deviation of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
};

export type CourseGroupMenuSumAggregates = {
    __typename?: 'CourseGroupMenuSumAggregates';
    /** Sum of id across the matching connection */
    id: Scalars['BigInt'];
};

export type CourseGroupMenuVariancePopulationAggregates = {
    __typename?: 'CourseGroupMenuVariancePopulationAggregates';
    /** Population variance of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
};

export type CourseGroupMenuVarianceSampleAggregates = {
    __typename?: 'CourseGroupMenuVarianceSampleAggregates';
    /** Sample variance of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `CourseGroupMenu` values. */
export type CourseGroupMenusConnection = {
    __typename?: 'CourseGroupMenusConnection';
    /** A list of `CourseGroupMenu` objects. */
    nodes: Array<CourseGroupMenu>;
    /** A list of edges which contains the `CourseGroupMenu` and cursor to aid in pagination. */
    edges: Array<CourseGroupMenusEdge>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `CourseGroupMenu` you could get from the connection. */
    totalCount: Scalars['Int'];
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<CourseGroupMenuAggregates>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<CourseGroupMenuAggregates>>;
};

/** A connection to a list of `CourseGroupMenu` values. */
export type CourseGroupMenusConnectionGroupedAggregatesArgs = {
    groupBy: Array<CourseGroupMenuGroupBy>;
    having?: Maybe<CourseGroupMenuHavingInput>;
};

/** A `CourseGroupMenu` edge in the connection. */
export type CourseGroupMenusEdge = {
    __typename?: 'CourseGroupMenusEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `CourseGroupMenu` at the end of the edge. */
    node: CourseGroupMenu;
};

/** Methods to use when ordering `CourseGroupMenu`. */
export enum CourseGroupMenusOrderBy {
    Natural = 'NATURAL',
    IdAsc = 'ID_ASC',
    IdDesc = 'ID_DESC',
    ShortcutAsc = 'SHORTCUT_ASC',
    ShortcutDesc = 'SHORTCUT_DESC',
    TitleOverviewAsc = 'TITLE_OVERVIEW_ASC',
    TitleOverviewDesc = 'TITLE_OVERVIEW_DESC',
    DescriptionOverviewAsc = 'DESCRIPTION_OVERVIEW_ASC',
    DescriptionOverviewDesc = 'DESCRIPTION_OVERVIEW_DESC',
    ImageurlOverviewAsc = 'IMAGEURL_OVERVIEW_ASC',
    ImageurlOverviewDesc = 'IMAGEURL_OVERVIEW_DESC',
    TitleAsc = 'TITLE_ASC',
    TitleDesc = 'TITLE_DESC',
    DescriptionAsc = 'DESCRIPTION_ASC',
    DescriptionDesc = 'DESCRIPTION_DESC',
    ImageurlAsc = 'IMAGEURL_ASC',
    ImageurlDesc = 'IMAGEURL_DESC',
    ArchivedAsc = 'ARCHIVED_ASC',
    ArchivedDesc = 'ARCHIVED_DESC',
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
    UpdatedAtAsc = 'UPDATED_AT_ASC',
    UpdatedAtDesc = 'UPDATED_AT_DESC',
    PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
    PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
    CourseGroupsCountAsc = 'COURSE_GROUPS_COUNT_ASC',
    CourseGroupsCountDesc = 'COURSE_GROUPS_COUNT_DESC',
    CourseGroupsSumIdAsc = 'COURSE_GROUPS_SUM_ID_ASC',
    CourseGroupsSumIdDesc = 'COURSE_GROUPS_SUM_ID_DESC',
    CourseGroupsSumTitleAsc = 'COURSE_GROUPS_SUM_TITLE_ASC',
    CourseGroupsSumTitleDesc = 'COURSE_GROUPS_SUM_TITLE_DESC',
    CourseGroupsSumArchivedAsc = 'COURSE_GROUPS_SUM_ARCHIVED_ASC',
    CourseGroupsSumArchivedDesc = 'COURSE_GROUPS_SUM_ARCHIVED_DESC',
    CourseGroupsSumDescriptionAsc = 'COURSE_GROUPS_SUM_DESCRIPTION_ASC',
    CourseGroupsSumDescriptionDesc = 'COURSE_GROUPS_SUM_DESCRIPTION_DESC',
    CourseGroupsSumTopicAsc = 'COURSE_GROUPS_SUM_TOPIC_ASC',
    CourseGroupsSumTopicDesc = 'COURSE_GROUPS_SUM_TOPIC_DESC',
    CourseGroupsSumContentAsc = 'COURSE_GROUPS_SUM_CONTENT_ASC',
    CourseGroupsSumContentDesc = 'COURSE_GROUPS_SUM_CONTENT_DESC',
    CourseGroupsSumCourseGroupMenuIdAsc = 'COURSE_GROUPS_SUM_COURSE_GROUP_MENU_ID_ASC',
    CourseGroupsSumCourseGroupMenuIdDesc = 'COURSE_GROUPS_SUM_COURSE_GROUP_MENU_ID_DESC',
    CourseGroupsSumExternalUrlAsc = 'COURSE_GROUPS_SUM_EXTERNAL_URL_ASC',
    CourseGroupsSumExternalUrlDesc = 'COURSE_GROUPS_SUM_EXTERNAL_URL_DESC',
    CourseGroupsSumCreatedAtAsc = 'COURSE_GROUPS_SUM_CREATED_AT_ASC',
    CourseGroupsSumCreatedAtDesc = 'COURSE_GROUPS_SUM_CREATED_AT_DESC',
    CourseGroupsSumUpdatedAtAsc = 'COURSE_GROUPS_SUM_UPDATED_AT_ASC',
    CourseGroupsSumUpdatedAtDesc = 'COURSE_GROUPS_SUM_UPDATED_AT_DESC',
    CourseGroupsSumNotesAsc = 'COURSE_GROUPS_SUM_NOTES_ASC',
    CourseGroupsSumNotesDesc = 'COURSE_GROUPS_SUM_NOTES_DESC',
    CourseGroupsDistinctCountIdAsc = 'COURSE_GROUPS_DISTINCT_COUNT_ID_ASC',
    CourseGroupsDistinctCountIdDesc = 'COURSE_GROUPS_DISTINCT_COUNT_ID_DESC',
    CourseGroupsDistinctCountTitleAsc = 'COURSE_GROUPS_DISTINCT_COUNT_TITLE_ASC',
    CourseGroupsDistinctCountTitleDesc = 'COURSE_GROUPS_DISTINCT_COUNT_TITLE_DESC',
    CourseGroupsDistinctCountArchivedAsc = 'COURSE_GROUPS_DISTINCT_COUNT_ARCHIVED_ASC',
    CourseGroupsDistinctCountArchivedDesc = 'COURSE_GROUPS_DISTINCT_COUNT_ARCHIVED_DESC',
    CourseGroupsDistinctCountDescriptionAsc = 'COURSE_GROUPS_DISTINCT_COUNT_DESCRIPTION_ASC',
    CourseGroupsDistinctCountDescriptionDesc = 'COURSE_GROUPS_DISTINCT_COUNT_DESCRIPTION_DESC',
    CourseGroupsDistinctCountTopicAsc = 'COURSE_GROUPS_DISTINCT_COUNT_TOPIC_ASC',
    CourseGroupsDistinctCountTopicDesc = 'COURSE_GROUPS_DISTINCT_COUNT_TOPIC_DESC',
    CourseGroupsDistinctCountContentAsc = 'COURSE_GROUPS_DISTINCT_COUNT_CONTENT_ASC',
    CourseGroupsDistinctCountContentDesc = 'COURSE_GROUPS_DISTINCT_COUNT_CONTENT_DESC',
    CourseGroupsDistinctCountCourseGroupMenuIdAsc = 'COURSE_GROUPS_DISTINCT_COUNT_COURSE_GROUP_MENU_ID_ASC',
    CourseGroupsDistinctCountCourseGroupMenuIdDesc = 'COURSE_GROUPS_DISTINCT_COUNT_COURSE_GROUP_MENU_ID_DESC',
    CourseGroupsDistinctCountExternalUrlAsc = 'COURSE_GROUPS_DISTINCT_COUNT_EXTERNAL_URL_ASC',
    CourseGroupsDistinctCountExternalUrlDesc = 'COURSE_GROUPS_DISTINCT_COUNT_EXTERNAL_URL_DESC',
    CourseGroupsDistinctCountCreatedAtAsc = 'COURSE_GROUPS_DISTINCT_COUNT_CREATED_AT_ASC',
    CourseGroupsDistinctCountCreatedAtDesc = 'COURSE_GROUPS_DISTINCT_COUNT_CREATED_AT_DESC',
    CourseGroupsDistinctCountUpdatedAtAsc = 'COURSE_GROUPS_DISTINCT_COUNT_UPDATED_AT_ASC',
    CourseGroupsDistinctCountUpdatedAtDesc = 'COURSE_GROUPS_DISTINCT_COUNT_UPDATED_AT_DESC',
    CourseGroupsDistinctCountNotesAsc = 'COURSE_GROUPS_DISTINCT_COUNT_NOTES_ASC',
    CourseGroupsDistinctCountNotesDesc = 'COURSE_GROUPS_DISTINCT_COUNT_NOTES_DESC',
    CourseGroupsMinIdAsc = 'COURSE_GROUPS_MIN_ID_ASC',
    CourseGroupsMinIdDesc = 'COURSE_GROUPS_MIN_ID_DESC',
    CourseGroupsMinTitleAsc = 'COURSE_GROUPS_MIN_TITLE_ASC',
    CourseGroupsMinTitleDesc = 'COURSE_GROUPS_MIN_TITLE_DESC',
    CourseGroupsMinArchivedAsc = 'COURSE_GROUPS_MIN_ARCHIVED_ASC',
    CourseGroupsMinArchivedDesc = 'COURSE_GROUPS_MIN_ARCHIVED_DESC',
    CourseGroupsMinDescriptionAsc = 'COURSE_GROUPS_MIN_DESCRIPTION_ASC',
    CourseGroupsMinDescriptionDesc = 'COURSE_GROUPS_MIN_DESCRIPTION_DESC',
    CourseGroupsMinTopicAsc = 'COURSE_GROUPS_MIN_TOPIC_ASC',
    CourseGroupsMinTopicDesc = 'COURSE_GROUPS_MIN_TOPIC_DESC',
    CourseGroupsMinContentAsc = 'COURSE_GROUPS_MIN_CONTENT_ASC',
    CourseGroupsMinContentDesc = 'COURSE_GROUPS_MIN_CONTENT_DESC',
    CourseGroupsMinCourseGroupMenuIdAsc = 'COURSE_GROUPS_MIN_COURSE_GROUP_MENU_ID_ASC',
    CourseGroupsMinCourseGroupMenuIdDesc = 'COURSE_GROUPS_MIN_COURSE_GROUP_MENU_ID_DESC',
    CourseGroupsMinExternalUrlAsc = 'COURSE_GROUPS_MIN_EXTERNAL_URL_ASC',
    CourseGroupsMinExternalUrlDesc = 'COURSE_GROUPS_MIN_EXTERNAL_URL_DESC',
    CourseGroupsMinCreatedAtAsc = 'COURSE_GROUPS_MIN_CREATED_AT_ASC',
    CourseGroupsMinCreatedAtDesc = 'COURSE_GROUPS_MIN_CREATED_AT_DESC',
    CourseGroupsMinUpdatedAtAsc = 'COURSE_GROUPS_MIN_UPDATED_AT_ASC',
    CourseGroupsMinUpdatedAtDesc = 'COURSE_GROUPS_MIN_UPDATED_AT_DESC',
    CourseGroupsMinNotesAsc = 'COURSE_GROUPS_MIN_NOTES_ASC',
    CourseGroupsMinNotesDesc = 'COURSE_GROUPS_MIN_NOTES_DESC',
    CourseGroupsMaxIdAsc = 'COURSE_GROUPS_MAX_ID_ASC',
    CourseGroupsMaxIdDesc = 'COURSE_GROUPS_MAX_ID_DESC',
    CourseGroupsMaxTitleAsc = 'COURSE_GROUPS_MAX_TITLE_ASC',
    CourseGroupsMaxTitleDesc = 'COURSE_GROUPS_MAX_TITLE_DESC',
    CourseGroupsMaxArchivedAsc = 'COURSE_GROUPS_MAX_ARCHIVED_ASC',
    CourseGroupsMaxArchivedDesc = 'COURSE_GROUPS_MAX_ARCHIVED_DESC',
    CourseGroupsMaxDescriptionAsc = 'COURSE_GROUPS_MAX_DESCRIPTION_ASC',
    CourseGroupsMaxDescriptionDesc = 'COURSE_GROUPS_MAX_DESCRIPTION_DESC',
    CourseGroupsMaxTopicAsc = 'COURSE_GROUPS_MAX_TOPIC_ASC',
    CourseGroupsMaxTopicDesc = 'COURSE_GROUPS_MAX_TOPIC_DESC',
    CourseGroupsMaxContentAsc = 'COURSE_GROUPS_MAX_CONTENT_ASC',
    CourseGroupsMaxContentDesc = 'COURSE_GROUPS_MAX_CONTENT_DESC',
    CourseGroupsMaxCourseGroupMenuIdAsc = 'COURSE_GROUPS_MAX_COURSE_GROUP_MENU_ID_ASC',
    CourseGroupsMaxCourseGroupMenuIdDesc = 'COURSE_GROUPS_MAX_COURSE_GROUP_MENU_ID_DESC',
    CourseGroupsMaxExternalUrlAsc = 'COURSE_GROUPS_MAX_EXTERNAL_URL_ASC',
    CourseGroupsMaxExternalUrlDesc = 'COURSE_GROUPS_MAX_EXTERNAL_URL_DESC',
    CourseGroupsMaxCreatedAtAsc = 'COURSE_GROUPS_MAX_CREATED_AT_ASC',
    CourseGroupsMaxCreatedAtDesc = 'COURSE_GROUPS_MAX_CREATED_AT_DESC',
    CourseGroupsMaxUpdatedAtAsc = 'COURSE_GROUPS_MAX_UPDATED_AT_ASC',
    CourseGroupsMaxUpdatedAtDesc = 'COURSE_GROUPS_MAX_UPDATED_AT_DESC',
    CourseGroupsMaxNotesAsc = 'COURSE_GROUPS_MAX_NOTES_ASC',
    CourseGroupsMaxNotesDesc = 'COURSE_GROUPS_MAX_NOTES_DESC',
    CourseGroupsAverageIdAsc = 'COURSE_GROUPS_AVERAGE_ID_ASC',
    CourseGroupsAverageIdDesc = 'COURSE_GROUPS_AVERAGE_ID_DESC',
    CourseGroupsAverageTitleAsc = 'COURSE_GROUPS_AVERAGE_TITLE_ASC',
    CourseGroupsAverageTitleDesc = 'COURSE_GROUPS_AVERAGE_TITLE_DESC',
    CourseGroupsAverageArchivedAsc = 'COURSE_GROUPS_AVERAGE_ARCHIVED_ASC',
    CourseGroupsAverageArchivedDesc = 'COURSE_GROUPS_AVERAGE_ARCHIVED_DESC',
    CourseGroupsAverageDescriptionAsc = 'COURSE_GROUPS_AVERAGE_DESCRIPTION_ASC',
    CourseGroupsAverageDescriptionDesc = 'COURSE_GROUPS_AVERAGE_DESCRIPTION_DESC',
    CourseGroupsAverageTopicAsc = 'COURSE_GROUPS_AVERAGE_TOPIC_ASC',
    CourseGroupsAverageTopicDesc = 'COURSE_GROUPS_AVERAGE_TOPIC_DESC',
    CourseGroupsAverageContentAsc = 'COURSE_GROUPS_AVERAGE_CONTENT_ASC',
    CourseGroupsAverageContentDesc = 'COURSE_GROUPS_AVERAGE_CONTENT_DESC',
    CourseGroupsAverageCourseGroupMenuIdAsc = 'COURSE_GROUPS_AVERAGE_COURSE_GROUP_MENU_ID_ASC',
    CourseGroupsAverageCourseGroupMenuIdDesc = 'COURSE_GROUPS_AVERAGE_COURSE_GROUP_MENU_ID_DESC',
    CourseGroupsAverageExternalUrlAsc = 'COURSE_GROUPS_AVERAGE_EXTERNAL_URL_ASC',
    CourseGroupsAverageExternalUrlDesc = 'COURSE_GROUPS_AVERAGE_EXTERNAL_URL_DESC',
    CourseGroupsAverageCreatedAtAsc = 'COURSE_GROUPS_AVERAGE_CREATED_AT_ASC',
    CourseGroupsAverageCreatedAtDesc = 'COURSE_GROUPS_AVERAGE_CREATED_AT_DESC',
    CourseGroupsAverageUpdatedAtAsc = 'COURSE_GROUPS_AVERAGE_UPDATED_AT_ASC',
    CourseGroupsAverageUpdatedAtDesc = 'COURSE_GROUPS_AVERAGE_UPDATED_AT_DESC',
    CourseGroupsAverageNotesAsc = 'COURSE_GROUPS_AVERAGE_NOTES_ASC',
    CourseGroupsAverageNotesDesc = 'COURSE_GROUPS_AVERAGE_NOTES_DESC',
    CourseGroupsStddevSampleIdAsc = 'COURSE_GROUPS_STDDEV_SAMPLE_ID_ASC',
    CourseGroupsStddevSampleIdDesc = 'COURSE_GROUPS_STDDEV_SAMPLE_ID_DESC',
    CourseGroupsStddevSampleTitleAsc = 'COURSE_GROUPS_STDDEV_SAMPLE_TITLE_ASC',
    CourseGroupsStddevSampleTitleDesc = 'COURSE_GROUPS_STDDEV_SAMPLE_TITLE_DESC',
    CourseGroupsStddevSampleArchivedAsc = 'COURSE_GROUPS_STDDEV_SAMPLE_ARCHIVED_ASC',
    CourseGroupsStddevSampleArchivedDesc = 'COURSE_GROUPS_STDDEV_SAMPLE_ARCHIVED_DESC',
    CourseGroupsStddevSampleDescriptionAsc = 'COURSE_GROUPS_STDDEV_SAMPLE_DESCRIPTION_ASC',
    CourseGroupsStddevSampleDescriptionDesc = 'COURSE_GROUPS_STDDEV_SAMPLE_DESCRIPTION_DESC',
    CourseGroupsStddevSampleTopicAsc = 'COURSE_GROUPS_STDDEV_SAMPLE_TOPIC_ASC',
    CourseGroupsStddevSampleTopicDesc = 'COURSE_GROUPS_STDDEV_SAMPLE_TOPIC_DESC',
    CourseGroupsStddevSampleContentAsc = 'COURSE_GROUPS_STDDEV_SAMPLE_CONTENT_ASC',
    CourseGroupsStddevSampleContentDesc = 'COURSE_GROUPS_STDDEV_SAMPLE_CONTENT_DESC',
    CourseGroupsStddevSampleCourseGroupMenuIdAsc = 'COURSE_GROUPS_STDDEV_SAMPLE_COURSE_GROUP_MENU_ID_ASC',
    CourseGroupsStddevSampleCourseGroupMenuIdDesc = 'COURSE_GROUPS_STDDEV_SAMPLE_COURSE_GROUP_MENU_ID_DESC',
    CourseGroupsStddevSampleExternalUrlAsc = 'COURSE_GROUPS_STDDEV_SAMPLE_EXTERNAL_URL_ASC',
    CourseGroupsStddevSampleExternalUrlDesc = 'COURSE_GROUPS_STDDEV_SAMPLE_EXTERNAL_URL_DESC',
    CourseGroupsStddevSampleCreatedAtAsc = 'COURSE_GROUPS_STDDEV_SAMPLE_CREATED_AT_ASC',
    CourseGroupsStddevSampleCreatedAtDesc = 'COURSE_GROUPS_STDDEV_SAMPLE_CREATED_AT_DESC',
    CourseGroupsStddevSampleUpdatedAtAsc = 'COURSE_GROUPS_STDDEV_SAMPLE_UPDATED_AT_ASC',
    CourseGroupsStddevSampleUpdatedAtDesc = 'COURSE_GROUPS_STDDEV_SAMPLE_UPDATED_AT_DESC',
    CourseGroupsStddevSampleNotesAsc = 'COURSE_GROUPS_STDDEV_SAMPLE_NOTES_ASC',
    CourseGroupsStddevSampleNotesDesc = 'COURSE_GROUPS_STDDEV_SAMPLE_NOTES_DESC',
    CourseGroupsStddevPopulationIdAsc = 'COURSE_GROUPS_STDDEV_POPULATION_ID_ASC',
    CourseGroupsStddevPopulationIdDesc = 'COURSE_GROUPS_STDDEV_POPULATION_ID_DESC',
    CourseGroupsStddevPopulationTitleAsc = 'COURSE_GROUPS_STDDEV_POPULATION_TITLE_ASC',
    CourseGroupsStddevPopulationTitleDesc = 'COURSE_GROUPS_STDDEV_POPULATION_TITLE_DESC',
    CourseGroupsStddevPopulationArchivedAsc = 'COURSE_GROUPS_STDDEV_POPULATION_ARCHIVED_ASC',
    CourseGroupsStddevPopulationArchivedDesc = 'COURSE_GROUPS_STDDEV_POPULATION_ARCHIVED_DESC',
    CourseGroupsStddevPopulationDescriptionAsc = 'COURSE_GROUPS_STDDEV_POPULATION_DESCRIPTION_ASC',
    CourseGroupsStddevPopulationDescriptionDesc = 'COURSE_GROUPS_STDDEV_POPULATION_DESCRIPTION_DESC',
    CourseGroupsStddevPopulationTopicAsc = 'COURSE_GROUPS_STDDEV_POPULATION_TOPIC_ASC',
    CourseGroupsStddevPopulationTopicDesc = 'COURSE_GROUPS_STDDEV_POPULATION_TOPIC_DESC',
    CourseGroupsStddevPopulationContentAsc = 'COURSE_GROUPS_STDDEV_POPULATION_CONTENT_ASC',
    CourseGroupsStddevPopulationContentDesc = 'COURSE_GROUPS_STDDEV_POPULATION_CONTENT_DESC',
    CourseGroupsStddevPopulationCourseGroupMenuIdAsc = 'COURSE_GROUPS_STDDEV_POPULATION_COURSE_GROUP_MENU_ID_ASC',
    CourseGroupsStddevPopulationCourseGroupMenuIdDesc = 'COURSE_GROUPS_STDDEV_POPULATION_COURSE_GROUP_MENU_ID_DESC',
    CourseGroupsStddevPopulationExternalUrlAsc = 'COURSE_GROUPS_STDDEV_POPULATION_EXTERNAL_URL_ASC',
    CourseGroupsStddevPopulationExternalUrlDesc = 'COURSE_GROUPS_STDDEV_POPULATION_EXTERNAL_URL_DESC',
    CourseGroupsStddevPopulationCreatedAtAsc = 'COURSE_GROUPS_STDDEV_POPULATION_CREATED_AT_ASC',
    CourseGroupsStddevPopulationCreatedAtDesc = 'COURSE_GROUPS_STDDEV_POPULATION_CREATED_AT_DESC',
    CourseGroupsStddevPopulationUpdatedAtAsc = 'COURSE_GROUPS_STDDEV_POPULATION_UPDATED_AT_ASC',
    CourseGroupsStddevPopulationUpdatedAtDesc = 'COURSE_GROUPS_STDDEV_POPULATION_UPDATED_AT_DESC',
    CourseGroupsStddevPopulationNotesAsc = 'COURSE_GROUPS_STDDEV_POPULATION_NOTES_ASC',
    CourseGroupsStddevPopulationNotesDesc = 'COURSE_GROUPS_STDDEV_POPULATION_NOTES_DESC',
    CourseGroupsVarianceSampleIdAsc = 'COURSE_GROUPS_VARIANCE_SAMPLE_ID_ASC',
    CourseGroupsVarianceSampleIdDesc = 'COURSE_GROUPS_VARIANCE_SAMPLE_ID_DESC',
    CourseGroupsVarianceSampleTitleAsc = 'COURSE_GROUPS_VARIANCE_SAMPLE_TITLE_ASC',
    CourseGroupsVarianceSampleTitleDesc = 'COURSE_GROUPS_VARIANCE_SAMPLE_TITLE_DESC',
    CourseGroupsVarianceSampleArchivedAsc = 'COURSE_GROUPS_VARIANCE_SAMPLE_ARCHIVED_ASC',
    CourseGroupsVarianceSampleArchivedDesc = 'COURSE_GROUPS_VARIANCE_SAMPLE_ARCHIVED_DESC',
    CourseGroupsVarianceSampleDescriptionAsc = 'COURSE_GROUPS_VARIANCE_SAMPLE_DESCRIPTION_ASC',
    CourseGroupsVarianceSampleDescriptionDesc = 'COURSE_GROUPS_VARIANCE_SAMPLE_DESCRIPTION_DESC',
    CourseGroupsVarianceSampleTopicAsc = 'COURSE_GROUPS_VARIANCE_SAMPLE_TOPIC_ASC',
    CourseGroupsVarianceSampleTopicDesc = 'COURSE_GROUPS_VARIANCE_SAMPLE_TOPIC_DESC',
    CourseGroupsVarianceSampleContentAsc = 'COURSE_GROUPS_VARIANCE_SAMPLE_CONTENT_ASC',
    CourseGroupsVarianceSampleContentDesc = 'COURSE_GROUPS_VARIANCE_SAMPLE_CONTENT_DESC',
    CourseGroupsVarianceSampleCourseGroupMenuIdAsc = 'COURSE_GROUPS_VARIANCE_SAMPLE_COURSE_GROUP_MENU_ID_ASC',
    CourseGroupsVarianceSampleCourseGroupMenuIdDesc = 'COURSE_GROUPS_VARIANCE_SAMPLE_COURSE_GROUP_MENU_ID_DESC',
    CourseGroupsVarianceSampleExternalUrlAsc = 'COURSE_GROUPS_VARIANCE_SAMPLE_EXTERNAL_URL_ASC',
    CourseGroupsVarianceSampleExternalUrlDesc = 'COURSE_GROUPS_VARIANCE_SAMPLE_EXTERNAL_URL_DESC',
    CourseGroupsVarianceSampleCreatedAtAsc = 'COURSE_GROUPS_VARIANCE_SAMPLE_CREATED_AT_ASC',
    CourseGroupsVarianceSampleCreatedAtDesc = 'COURSE_GROUPS_VARIANCE_SAMPLE_CREATED_AT_DESC',
    CourseGroupsVarianceSampleUpdatedAtAsc = 'COURSE_GROUPS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
    CourseGroupsVarianceSampleUpdatedAtDesc = 'COURSE_GROUPS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
    CourseGroupsVarianceSampleNotesAsc = 'COURSE_GROUPS_VARIANCE_SAMPLE_NOTES_ASC',
    CourseGroupsVarianceSampleNotesDesc = 'COURSE_GROUPS_VARIANCE_SAMPLE_NOTES_DESC',
    CourseGroupsVariancePopulationIdAsc = 'COURSE_GROUPS_VARIANCE_POPULATION_ID_ASC',
    CourseGroupsVariancePopulationIdDesc = 'COURSE_GROUPS_VARIANCE_POPULATION_ID_DESC',
    CourseGroupsVariancePopulationTitleAsc = 'COURSE_GROUPS_VARIANCE_POPULATION_TITLE_ASC',
    CourseGroupsVariancePopulationTitleDesc = 'COURSE_GROUPS_VARIANCE_POPULATION_TITLE_DESC',
    CourseGroupsVariancePopulationArchivedAsc = 'COURSE_GROUPS_VARIANCE_POPULATION_ARCHIVED_ASC',
    CourseGroupsVariancePopulationArchivedDesc = 'COURSE_GROUPS_VARIANCE_POPULATION_ARCHIVED_DESC',
    CourseGroupsVariancePopulationDescriptionAsc = 'COURSE_GROUPS_VARIANCE_POPULATION_DESCRIPTION_ASC',
    CourseGroupsVariancePopulationDescriptionDesc = 'COURSE_GROUPS_VARIANCE_POPULATION_DESCRIPTION_DESC',
    CourseGroupsVariancePopulationTopicAsc = 'COURSE_GROUPS_VARIANCE_POPULATION_TOPIC_ASC',
    CourseGroupsVariancePopulationTopicDesc = 'COURSE_GROUPS_VARIANCE_POPULATION_TOPIC_DESC',
    CourseGroupsVariancePopulationContentAsc = 'COURSE_GROUPS_VARIANCE_POPULATION_CONTENT_ASC',
    CourseGroupsVariancePopulationContentDesc = 'COURSE_GROUPS_VARIANCE_POPULATION_CONTENT_DESC',
    CourseGroupsVariancePopulationCourseGroupMenuIdAsc = 'COURSE_GROUPS_VARIANCE_POPULATION_COURSE_GROUP_MENU_ID_ASC',
    CourseGroupsVariancePopulationCourseGroupMenuIdDesc = 'COURSE_GROUPS_VARIANCE_POPULATION_COURSE_GROUP_MENU_ID_DESC',
    CourseGroupsVariancePopulationExternalUrlAsc = 'COURSE_GROUPS_VARIANCE_POPULATION_EXTERNAL_URL_ASC',
    CourseGroupsVariancePopulationExternalUrlDesc = 'COURSE_GROUPS_VARIANCE_POPULATION_EXTERNAL_URL_DESC',
    CourseGroupsVariancePopulationCreatedAtAsc = 'COURSE_GROUPS_VARIANCE_POPULATION_CREATED_AT_ASC',
    CourseGroupsVariancePopulationCreatedAtDesc = 'COURSE_GROUPS_VARIANCE_POPULATION_CREATED_AT_DESC',
    CourseGroupsVariancePopulationUpdatedAtAsc = 'COURSE_GROUPS_VARIANCE_POPULATION_UPDATED_AT_ASC',
    CourseGroupsVariancePopulationUpdatedAtDesc = 'COURSE_GROUPS_VARIANCE_POPULATION_UPDATED_AT_DESC',
    CourseGroupsVariancePopulationNotesAsc = 'COURSE_GROUPS_VARIANCE_POPULATION_NOTES_ASC',
    CourseGroupsVariancePopulationNotesDesc = 'COURSE_GROUPS_VARIANCE_POPULATION_NOTES_DESC',
}

export type CourseGroupMinAggregates = {
    __typename?: 'CourseGroupMinAggregates';
    /** Minimum of id across the matching connection */
    id?: Maybe<Scalars['Int']>;
    /** Minimum of courseGroupMenuId across the matching connection */
    courseGroupMenuId?: Maybe<Scalars['Int']>;
};

/** Represents an update to a `CourseGroup`. Fields that are set will be updated. */
export type CourseGroupPatch = {
    id?: Maybe<Scalars['Int']>;
    title?: Maybe<Scalars['String']>;
    archived?: Maybe<Scalars['Boolean']>;
    description?: Maybe<Scalars['String']>;
    topic?: Maybe<Scalars['String']>;
    content?: Maybe<Scalars['String']>;
    courseGroupMenuId?: Maybe<Scalars['Int']>;
    externalUrl?: Maybe<Scalars['String']>;
    createdAt?: Maybe<Scalars['Datetime']>;
    updatedAt?: Maybe<Scalars['Datetime']>;
    notes?: Maybe<Scalars['String']>;
};

export type CourseGroupStddevPopulationAggregates = {
    __typename?: 'CourseGroupStddevPopulationAggregates';
    /** Population standard deviation of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Population standard deviation of courseGroupMenuId across the matching connection */
    courseGroupMenuId?: Maybe<Scalars['BigFloat']>;
};

export type CourseGroupStddevSampleAggregates = {
    __typename?: 'CourseGroupStddevSampleAggregates';
    /** Sample standard deviation of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Sample standard deviation of courseGroupMenuId across the matching connection */
    courseGroupMenuId?: Maybe<Scalars['BigFloat']>;
};

export type CourseGroupSumAggregates = {
    __typename?: 'CourseGroupSumAggregates';
    /** Sum of id across the matching connection */
    id: Scalars['BigInt'];
    /** Sum of courseGroupMenuId across the matching connection */
    courseGroupMenuId: Scalars['BigInt'];
};

export type CourseGroupVariancePopulationAggregates = {
    __typename?: 'CourseGroupVariancePopulationAggregates';
    /** Population variance of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Population variance of courseGroupMenuId across the matching connection */
    courseGroupMenuId?: Maybe<Scalars['BigFloat']>;
};

export type CourseGroupVarianceSampleAggregates = {
    __typename?: 'CourseGroupVarianceSampleAggregates';
    /** Sample variance of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Sample variance of courseGroupMenuId across the matching connection */
    courseGroupMenuId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `CourseGroup` values. */
export type CourseGroupsConnection = {
    __typename?: 'CourseGroupsConnection';
    /** A list of `CourseGroup` objects. */
    nodes: Array<CourseGroup>;
    /** A list of edges which contains the `CourseGroup` and cursor to aid in pagination. */
    edges: Array<CourseGroupsEdge>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `CourseGroup` you could get from the connection. */
    totalCount: Scalars['Int'];
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<CourseGroupAggregates>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<CourseGroupAggregates>>;
};

/** A connection to a list of `CourseGroup` values. */
export type CourseGroupsConnectionGroupedAggregatesArgs = {
    groupBy: Array<CourseGroupGroupBy>;
    having?: Maybe<CourseGroupHavingInput>;
};

/** A `CourseGroup` edge in the connection. */
export type CourseGroupsEdge = {
    __typename?: 'CourseGroupsEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `CourseGroup` at the end of the edge. */
    node: CourseGroup;
};

/** Methods to use when ordering `CourseGroup`. */
export enum CourseGroupsOrderBy {
    Natural = 'NATURAL',
    IdAsc = 'ID_ASC',
    IdDesc = 'ID_DESC',
    TitleAsc = 'TITLE_ASC',
    TitleDesc = 'TITLE_DESC',
    ArchivedAsc = 'ARCHIVED_ASC',
    ArchivedDesc = 'ARCHIVED_DESC',
    DescriptionAsc = 'DESCRIPTION_ASC',
    DescriptionDesc = 'DESCRIPTION_DESC',
    TopicAsc = 'TOPIC_ASC',
    TopicDesc = 'TOPIC_DESC',
    ContentAsc = 'CONTENT_ASC',
    ContentDesc = 'CONTENT_DESC',
    CourseGroupMenuIdAsc = 'COURSE_GROUP_MENU_ID_ASC',
    CourseGroupMenuIdDesc = 'COURSE_GROUP_MENU_ID_DESC',
    ExternalUrlAsc = 'EXTERNAL_URL_ASC',
    ExternalUrlDesc = 'EXTERNAL_URL_DESC',
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
    UpdatedAtAsc = 'UPDATED_AT_ASC',
    UpdatedAtDesc = 'UPDATED_AT_DESC',
    NotesAsc = 'NOTES_ASC',
    NotesDesc = 'NOTES_DESC',
    PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
    PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
    CoursesCountAsc = 'COURSES_COUNT_ASC',
    CoursesCountDesc = 'COURSES_COUNT_DESC',
    CoursesSumIdAsc = 'COURSES_SUM_ID_ASC',
    CoursesSumIdDesc = 'COURSES_SUM_ID_DESC',
    CoursesSumNameAsc = 'COURSES_SUM_NAME_ASC',
    CoursesSumNameDesc = 'COURSES_SUM_NAME_DESC',
    CoursesSumDescriptionAsc = 'COURSES_SUM_DESCRIPTION_ASC',
    CoursesSumDescriptionDesc = 'COURSES_SUM_DESCRIPTION_DESC',
    CoursesSumCommentAsc = 'COURSES_SUM_COMMENT_ASC',
    CoursesSumCommentDesc = 'COURSES_SUM_COMMENT_DESC',
    CoursesSumDetailsAsc = 'COURSES_SUM_DETAILS_ASC',
    CoursesSumDetailsDesc = 'COURSES_SUM_DETAILS_DESC',
    CoursesSumArchivedAsc = 'COURSES_SUM_ARCHIVED_ASC',
    CoursesSumArchivedDesc = 'COURSES_SUM_ARCHIVED_DESC',
    CoursesSumDefaultLocationIdAsc = 'COURSES_SUM_DEFAULT_LOCATION_ID_ASC',
    CoursesSumDefaultLocationIdDesc = 'COURSES_SUM_DEFAULT_LOCATION_ID_DESC',
    CoursesSumDefaultTimetableAsc = 'COURSES_SUM_DEFAULT_TIMETABLE_ASC',
    CoursesSumDefaultTimetableDesc = 'COURSES_SUM_DEFAULT_TIMETABLE_DESC',
    CoursesSumCourseGroupIdAsc = 'COURSES_SUM_COURSE_GROUP_ID_ASC',
    CoursesSumCourseGroupIdDesc = 'COURSES_SUM_COURSE_GROUP_ID_DESC',
    CoursesSumCreatedAtAsc = 'COURSES_SUM_CREATED_AT_ASC',
    CoursesSumCreatedAtDesc = 'COURSES_SUM_CREATED_AT_DESC',
    CoursesSumUpdatedAtAsc = 'COURSES_SUM_UPDATED_AT_ASC',
    CoursesSumUpdatedAtDesc = 'COURSES_SUM_UPDATED_AT_DESC',
    CoursesSumDefaultSeatsAsc = 'COURSES_SUM_DEFAULT_SEATS_ASC',
    CoursesSumDefaultSeatsDesc = 'COURSES_SUM_DEFAULT_SEATS_DESC',
    CoursesSumDefaultDurationAsc = 'COURSES_SUM_DEFAULT_DURATION_ASC',
    CoursesSumDefaultDurationDesc = 'COURSES_SUM_DEFAULT_DURATION_DESC',
    CoursesSumDefaultUnitsAsc = 'COURSES_SUM_DEFAULT_UNITS_ASC',
    CoursesSumDefaultUnitsDesc = 'COURSES_SUM_DEFAULT_UNITS_DESC',
    CoursesSumDefaultPriceAsc = 'COURSES_SUM_DEFAULT_PRICE_ASC',
    CoursesSumDefaultPriceDesc = 'COURSES_SUM_DEFAULT_PRICE_DESC',
    CoursesSumCertSetupAsc = 'COURSES_SUM_CERT_SETUP_ASC',
    CoursesSumCertSetupDesc = 'COURSES_SUM_CERT_SETUP_DESC',
    CoursesSumConfirmationContentAsc = 'COURSES_SUM_CONFIRMATION_CONTENT_ASC',
    CoursesSumConfirmationContentDesc = 'COURSES_SUM_CONFIRMATION_CONTENT_DESC',
    CoursesDistinctCountIdAsc = 'COURSES_DISTINCT_COUNT_ID_ASC',
    CoursesDistinctCountIdDesc = 'COURSES_DISTINCT_COUNT_ID_DESC',
    CoursesDistinctCountNameAsc = 'COURSES_DISTINCT_COUNT_NAME_ASC',
    CoursesDistinctCountNameDesc = 'COURSES_DISTINCT_COUNT_NAME_DESC',
    CoursesDistinctCountDescriptionAsc = 'COURSES_DISTINCT_COUNT_DESCRIPTION_ASC',
    CoursesDistinctCountDescriptionDesc = 'COURSES_DISTINCT_COUNT_DESCRIPTION_DESC',
    CoursesDistinctCountCommentAsc = 'COURSES_DISTINCT_COUNT_COMMENT_ASC',
    CoursesDistinctCountCommentDesc = 'COURSES_DISTINCT_COUNT_COMMENT_DESC',
    CoursesDistinctCountDetailsAsc = 'COURSES_DISTINCT_COUNT_DETAILS_ASC',
    CoursesDistinctCountDetailsDesc = 'COURSES_DISTINCT_COUNT_DETAILS_DESC',
    CoursesDistinctCountArchivedAsc = 'COURSES_DISTINCT_COUNT_ARCHIVED_ASC',
    CoursesDistinctCountArchivedDesc = 'COURSES_DISTINCT_COUNT_ARCHIVED_DESC',
    CoursesDistinctCountDefaultLocationIdAsc = 'COURSES_DISTINCT_COUNT_DEFAULT_LOCATION_ID_ASC',
    CoursesDistinctCountDefaultLocationIdDesc = 'COURSES_DISTINCT_COUNT_DEFAULT_LOCATION_ID_DESC',
    CoursesDistinctCountDefaultTimetableAsc = 'COURSES_DISTINCT_COUNT_DEFAULT_TIMETABLE_ASC',
    CoursesDistinctCountDefaultTimetableDesc = 'COURSES_DISTINCT_COUNT_DEFAULT_TIMETABLE_DESC',
    CoursesDistinctCountCourseGroupIdAsc = 'COURSES_DISTINCT_COUNT_COURSE_GROUP_ID_ASC',
    CoursesDistinctCountCourseGroupIdDesc = 'COURSES_DISTINCT_COUNT_COURSE_GROUP_ID_DESC',
    CoursesDistinctCountCreatedAtAsc = 'COURSES_DISTINCT_COUNT_CREATED_AT_ASC',
    CoursesDistinctCountCreatedAtDesc = 'COURSES_DISTINCT_COUNT_CREATED_AT_DESC',
    CoursesDistinctCountUpdatedAtAsc = 'COURSES_DISTINCT_COUNT_UPDATED_AT_ASC',
    CoursesDistinctCountUpdatedAtDesc = 'COURSES_DISTINCT_COUNT_UPDATED_AT_DESC',
    CoursesDistinctCountDefaultSeatsAsc = 'COURSES_DISTINCT_COUNT_DEFAULT_SEATS_ASC',
    CoursesDistinctCountDefaultSeatsDesc = 'COURSES_DISTINCT_COUNT_DEFAULT_SEATS_DESC',
    CoursesDistinctCountDefaultDurationAsc = 'COURSES_DISTINCT_COUNT_DEFAULT_DURATION_ASC',
    CoursesDistinctCountDefaultDurationDesc = 'COURSES_DISTINCT_COUNT_DEFAULT_DURATION_DESC',
    CoursesDistinctCountDefaultUnitsAsc = 'COURSES_DISTINCT_COUNT_DEFAULT_UNITS_ASC',
    CoursesDistinctCountDefaultUnitsDesc = 'COURSES_DISTINCT_COUNT_DEFAULT_UNITS_DESC',
    CoursesDistinctCountDefaultPriceAsc = 'COURSES_DISTINCT_COUNT_DEFAULT_PRICE_ASC',
    CoursesDistinctCountDefaultPriceDesc = 'COURSES_DISTINCT_COUNT_DEFAULT_PRICE_DESC',
    CoursesDistinctCountCertSetupAsc = 'COURSES_DISTINCT_COUNT_CERT_SETUP_ASC',
    CoursesDistinctCountCertSetupDesc = 'COURSES_DISTINCT_COUNT_CERT_SETUP_DESC',
    CoursesDistinctCountConfirmationContentAsc = 'COURSES_DISTINCT_COUNT_CONFIRMATION_CONTENT_ASC',
    CoursesDistinctCountConfirmationContentDesc = 'COURSES_DISTINCT_COUNT_CONFIRMATION_CONTENT_DESC',
    CoursesMinIdAsc = 'COURSES_MIN_ID_ASC',
    CoursesMinIdDesc = 'COURSES_MIN_ID_DESC',
    CoursesMinNameAsc = 'COURSES_MIN_NAME_ASC',
    CoursesMinNameDesc = 'COURSES_MIN_NAME_DESC',
    CoursesMinDescriptionAsc = 'COURSES_MIN_DESCRIPTION_ASC',
    CoursesMinDescriptionDesc = 'COURSES_MIN_DESCRIPTION_DESC',
    CoursesMinCommentAsc = 'COURSES_MIN_COMMENT_ASC',
    CoursesMinCommentDesc = 'COURSES_MIN_COMMENT_DESC',
    CoursesMinDetailsAsc = 'COURSES_MIN_DETAILS_ASC',
    CoursesMinDetailsDesc = 'COURSES_MIN_DETAILS_DESC',
    CoursesMinArchivedAsc = 'COURSES_MIN_ARCHIVED_ASC',
    CoursesMinArchivedDesc = 'COURSES_MIN_ARCHIVED_DESC',
    CoursesMinDefaultLocationIdAsc = 'COURSES_MIN_DEFAULT_LOCATION_ID_ASC',
    CoursesMinDefaultLocationIdDesc = 'COURSES_MIN_DEFAULT_LOCATION_ID_DESC',
    CoursesMinDefaultTimetableAsc = 'COURSES_MIN_DEFAULT_TIMETABLE_ASC',
    CoursesMinDefaultTimetableDesc = 'COURSES_MIN_DEFAULT_TIMETABLE_DESC',
    CoursesMinCourseGroupIdAsc = 'COURSES_MIN_COURSE_GROUP_ID_ASC',
    CoursesMinCourseGroupIdDesc = 'COURSES_MIN_COURSE_GROUP_ID_DESC',
    CoursesMinCreatedAtAsc = 'COURSES_MIN_CREATED_AT_ASC',
    CoursesMinCreatedAtDesc = 'COURSES_MIN_CREATED_AT_DESC',
    CoursesMinUpdatedAtAsc = 'COURSES_MIN_UPDATED_AT_ASC',
    CoursesMinUpdatedAtDesc = 'COURSES_MIN_UPDATED_AT_DESC',
    CoursesMinDefaultSeatsAsc = 'COURSES_MIN_DEFAULT_SEATS_ASC',
    CoursesMinDefaultSeatsDesc = 'COURSES_MIN_DEFAULT_SEATS_DESC',
    CoursesMinDefaultDurationAsc = 'COURSES_MIN_DEFAULT_DURATION_ASC',
    CoursesMinDefaultDurationDesc = 'COURSES_MIN_DEFAULT_DURATION_DESC',
    CoursesMinDefaultUnitsAsc = 'COURSES_MIN_DEFAULT_UNITS_ASC',
    CoursesMinDefaultUnitsDesc = 'COURSES_MIN_DEFAULT_UNITS_DESC',
    CoursesMinDefaultPriceAsc = 'COURSES_MIN_DEFAULT_PRICE_ASC',
    CoursesMinDefaultPriceDesc = 'COURSES_MIN_DEFAULT_PRICE_DESC',
    CoursesMinCertSetupAsc = 'COURSES_MIN_CERT_SETUP_ASC',
    CoursesMinCertSetupDesc = 'COURSES_MIN_CERT_SETUP_DESC',
    CoursesMinConfirmationContentAsc = 'COURSES_MIN_CONFIRMATION_CONTENT_ASC',
    CoursesMinConfirmationContentDesc = 'COURSES_MIN_CONFIRMATION_CONTENT_DESC',
    CoursesMaxIdAsc = 'COURSES_MAX_ID_ASC',
    CoursesMaxIdDesc = 'COURSES_MAX_ID_DESC',
    CoursesMaxNameAsc = 'COURSES_MAX_NAME_ASC',
    CoursesMaxNameDesc = 'COURSES_MAX_NAME_DESC',
    CoursesMaxDescriptionAsc = 'COURSES_MAX_DESCRIPTION_ASC',
    CoursesMaxDescriptionDesc = 'COURSES_MAX_DESCRIPTION_DESC',
    CoursesMaxCommentAsc = 'COURSES_MAX_COMMENT_ASC',
    CoursesMaxCommentDesc = 'COURSES_MAX_COMMENT_DESC',
    CoursesMaxDetailsAsc = 'COURSES_MAX_DETAILS_ASC',
    CoursesMaxDetailsDesc = 'COURSES_MAX_DETAILS_DESC',
    CoursesMaxArchivedAsc = 'COURSES_MAX_ARCHIVED_ASC',
    CoursesMaxArchivedDesc = 'COURSES_MAX_ARCHIVED_DESC',
    CoursesMaxDefaultLocationIdAsc = 'COURSES_MAX_DEFAULT_LOCATION_ID_ASC',
    CoursesMaxDefaultLocationIdDesc = 'COURSES_MAX_DEFAULT_LOCATION_ID_DESC',
    CoursesMaxDefaultTimetableAsc = 'COURSES_MAX_DEFAULT_TIMETABLE_ASC',
    CoursesMaxDefaultTimetableDesc = 'COURSES_MAX_DEFAULT_TIMETABLE_DESC',
    CoursesMaxCourseGroupIdAsc = 'COURSES_MAX_COURSE_GROUP_ID_ASC',
    CoursesMaxCourseGroupIdDesc = 'COURSES_MAX_COURSE_GROUP_ID_DESC',
    CoursesMaxCreatedAtAsc = 'COURSES_MAX_CREATED_AT_ASC',
    CoursesMaxCreatedAtDesc = 'COURSES_MAX_CREATED_AT_DESC',
    CoursesMaxUpdatedAtAsc = 'COURSES_MAX_UPDATED_AT_ASC',
    CoursesMaxUpdatedAtDesc = 'COURSES_MAX_UPDATED_AT_DESC',
    CoursesMaxDefaultSeatsAsc = 'COURSES_MAX_DEFAULT_SEATS_ASC',
    CoursesMaxDefaultSeatsDesc = 'COURSES_MAX_DEFAULT_SEATS_DESC',
    CoursesMaxDefaultDurationAsc = 'COURSES_MAX_DEFAULT_DURATION_ASC',
    CoursesMaxDefaultDurationDesc = 'COURSES_MAX_DEFAULT_DURATION_DESC',
    CoursesMaxDefaultUnitsAsc = 'COURSES_MAX_DEFAULT_UNITS_ASC',
    CoursesMaxDefaultUnitsDesc = 'COURSES_MAX_DEFAULT_UNITS_DESC',
    CoursesMaxDefaultPriceAsc = 'COURSES_MAX_DEFAULT_PRICE_ASC',
    CoursesMaxDefaultPriceDesc = 'COURSES_MAX_DEFAULT_PRICE_DESC',
    CoursesMaxCertSetupAsc = 'COURSES_MAX_CERT_SETUP_ASC',
    CoursesMaxCertSetupDesc = 'COURSES_MAX_CERT_SETUP_DESC',
    CoursesMaxConfirmationContentAsc = 'COURSES_MAX_CONFIRMATION_CONTENT_ASC',
    CoursesMaxConfirmationContentDesc = 'COURSES_MAX_CONFIRMATION_CONTENT_DESC',
    CoursesAverageIdAsc = 'COURSES_AVERAGE_ID_ASC',
    CoursesAverageIdDesc = 'COURSES_AVERAGE_ID_DESC',
    CoursesAverageNameAsc = 'COURSES_AVERAGE_NAME_ASC',
    CoursesAverageNameDesc = 'COURSES_AVERAGE_NAME_DESC',
    CoursesAverageDescriptionAsc = 'COURSES_AVERAGE_DESCRIPTION_ASC',
    CoursesAverageDescriptionDesc = 'COURSES_AVERAGE_DESCRIPTION_DESC',
    CoursesAverageCommentAsc = 'COURSES_AVERAGE_COMMENT_ASC',
    CoursesAverageCommentDesc = 'COURSES_AVERAGE_COMMENT_DESC',
    CoursesAverageDetailsAsc = 'COURSES_AVERAGE_DETAILS_ASC',
    CoursesAverageDetailsDesc = 'COURSES_AVERAGE_DETAILS_DESC',
    CoursesAverageArchivedAsc = 'COURSES_AVERAGE_ARCHIVED_ASC',
    CoursesAverageArchivedDesc = 'COURSES_AVERAGE_ARCHIVED_DESC',
    CoursesAverageDefaultLocationIdAsc = 'COURSES_AVERAGE_DEFAULT_LOCATION_ID_ASC',
    CoursesAverageDefaultLocationIdDesc = 'COURSES_AVERAGE_DEFAULT_LOCATION_ID_DESC',
    CoursesAverageDefaultTimetableAsc = 'COURSES_AVERAGE_DEFAULT_TIMETABLE_ASC',
    CoursesAverageDefaultTimetableDesc = 'COURSES_AVERAGE_DEFAULT_TIMETABLE_DESC',
    CoursesAverageCourseGroupIdAsc = 'COURSES_AVERAGE_COURSE_GROUP_ID_ASC',
    CoursesAverageCourseGroupIdDesc = 'COURSES_AVERAGE_COURSE_GROUP_ID_DESC',
    CoursesAverageCreatedAtAsc = 'COURSES_AVERAGE_CREATED_AT_ASC',
    CoursesAverageCreatedAtDesc = 'COURSES_AVERAGE_CREATED_AT_DESC',
    CoursesAverageUpdatedAtAsc = 'COURSES_AVERAGE_UPDATED_AT_ASC',
    CoursesAverageUpdatedAtDesc = 'COURSES_AVERAGE_UPDATED_AT_DESC',
    CoursesAverageDefaultSeatsAsc = 'COURSES_AVERAGE_DEFAULT_SEATS_ASC',
    CoursesAverageDefaultSeatsDesc = 'COURSES_AVERAGE_DEFAULT_SEATS_DESC',
    CoursesAverageDefaultDurationAsc = 'COURSES_AVERAGE_DEFAULT_DURATION_ASC',
    CoursesAverageDefaultDurationDesc = 'COURSES_AVERAGE_DEFAULT_DURATION_DESC',
    CoursesAverageDefaultUnitsAsc = 'COURSES_AVERAGE_DEFAULT_UNITS_ASC',
    CoursesAverageDefaultUnitsDesc = 'COURSES_AVERAGE_DEFAULT_UNITS_DESC',
    CoursesAverageDefaultPriceAsc = 'COURSES_AVERAGE_DEFAULT_PRICE_ASC',
    CoursesAverageDefaultPriceDesc = 'COURSES_AVERAGE_DEFAULT_PRICE_DESC',
    CoursesAverageCertSetupAsc = 'COURSES_AVERAGE_CERT_SETUP_ASC',
    CoursesAverageCertSetupDesc = 'COURSES_AVERAGE_CERT_SETUP_DESC',
    CoursesAverageConfirmationContentAsc = 'COURSES_AVERAGE_CONFIRMATION_CONTENT_ASC',
    CoursesAverageConfirmationContentDesc = 'COURSES_AVERAGE_CONFIRMATION_CONTENT_DESC',
    CoursesStddevSampleIdAsc = 'COURSES_STDDEV_SAMPLE_ID_ASC',
    CoursesStddevSampleIdDesc = 'COURSES_STDDEV_SAMPLE_ID_DESC',
    CoursesStddevSampleNameAsc = 'COURSES_STDDEV_SAMPLE_NAME_ASC',
    CoursesStddevSampleNameDesc = 'COURSES_STDDEV_SAMPLE_NAME_DESC',
    CoursesStddevSampleDescriptionAsc = 'COURSES_STDDEV_SAMPLE_DESCRIPTION_ASC',
    CoursesStddevSampleDescriptionDesc = 'COURSES_STDDEV_SAMPLE_DESCRIPTION_DESC',
    CoursesStddevSampleCommentAsc = 'COURSES_STDDEV_SAMPLE_COMMENT_ASC',
    CoursesStddevSampleCommentDesc = 'COURSES_STDDEV_SAMPLE_COMMENT_DESC',
    CoursesStddevSampleDetailsAsc = 'COURSES_STDDEV_SAMPLE_DETAILS_ASC',
    CoursesStddevSampleDetailsDesc = 'COURSES_STDDEV_SAMPLE_DETAILS_DESC',
    CoursesStddevSampleArchivedAsc = 'COURSES_STDDEV_SAMPLE_ARCHIVED_ASC',
    CoursesStddevSampleArchivedDesc = 'COURSES_STDDEV_SAMPLE_ARCHIVED_DESC',
    CoursesStddevSampleDefaultLocationIdAsc = 'COURSES_STDDEV_SAMPLE_DEFAULT_LOCATION_ID_ASC',
    CoursesStddevSampleDefaultLocationIdDesc = 'COURSES_STDDEV_SAMPLE_DEFAULT_LOCATION_ID_DESC',
    CoursesStddevSampleDefaultTimetableAsc = 'COURSES_STDDEV_SAMPLE_DEFAULT_TIMETABLE_ASC',
    CoursesStddevSampleDefaultTimetableDesc = 'COURSES_STDDEV_SAMPLE_DEFAULT_TIMETABLE_DESC',
    CoursesStddevSampleCourseGroupIdAsc = 'COURSES_STDDEV_SAMPLE_COURSE_GROUP_ID_ASC',
    CoursesStddevSampleCourseGroupIdDesc = 'COURSES_STDDEV_SAMPLE_COURSE_GROUP_ID_DESC',
    CoursesStddevSampleCreatedAtAsc = 'COURSES_STDDEV_SAMPLE_CREATED_AT_ASC',
    CoursesStddevSampleCreatedAtDesc = 'COURSES_STDDEV_SAMPLE_CREATED_AT_DESC',
    CoursesStddevSampleUpdatedAtAsc = 'COURSES_STDDEV_SAMPLE_UPDATED_AT_ASC',
    CoursesStddevSampleUpdatedAtDesc = 'COURSES_STDDEV_SAMPLE_UPDATED_AT_DESC',
    CoursesStddevSampleDefaultSeatsAsc = 'COURSES_STDDEV_SAMPLE_DEFAULT_SEATS_ASC',
    CoursesStddevSampleDefaultSeatsDesc = 'COURSES_STDDEV_SAMPLE_DEFAULT_SEATS_DESC',
    CoursesStddevSampleDefaultDurationAsc = 'COURSES_STDDEV_SAMPLE_DEFAULT_DURATION_ASC',
    CoursesStddevSampleDefaultDurationDesc = 'COURSES_STDDEV_SAMPLE_DEFAULT_DURATION_DESC',
    CoursesStddevSampleDefaultUnitsAsc = 'COURSES_STDDEV_SAMPLE_DEFAULT_UNITS_ASC',
    CoursesStddevSampleDefaultUnitsDesc = 'COURSES_STDDEV_SAMPLE_DEFAULT_UNITS_DESC',
    CoursesStddevSampleDefaultPriceAsc = 'COURSES_STDDEV_SAMPLE_DEFAULT_PRICE_ASC',
    CoursesStddevSampleDefaultPriceDesc = 'COURSES_STDDEV_SAMPLE_DEFAULT_PRICE_DESC',
    CoursesStddevSampleCertSetupAsc = 'COURSES_STDDEV_SAMPLE_CERT_SETUP_ASC',
    CoursesStddevSampleCertSetupDesc = 'COURSES_STDDEV_SAMPLE_CERT_SETUP_DESC',
    CoursesStddevSampleConfirmationContentAsc = 'COURSES_STDDEV_SAMPLE_CONFIRMATION_CONTENT_ASC',
    CoursesStddevSampleConfirmationContentDesc = 'COURSES_STDDEV_SAMPLE_CONFIRMATION_CONTENT_DESC',
    CoursesStddevPopulationIdAsc = 'COURSES_STDDEV_POPULATION_ID_ASC',
    CoursesStddevPopulationIdDesc = 'COURSES_STDDEV_POPULATION_ID_DESC',
    CoursesStddevPopulationNameAsc = 'COURSES_STDDEV_POPULATION_NAME_ASC',
    CoursesStddevPopulationNameDesc = 'COURSES_STDDEV_POPULATION_NAME_DESC',
    CoursesStddevPopulationDescriptionAsc = 'COURSES_STDDEV_POPULATION_DESCRIPTION_ASC',
    CoursesStddevPopulationDescriptionDesc = 'COURSES_STDDEV_POPULATION_DESCRIPTION_DESC',
    CoursesStddevPopulationCommentAsc = 'COURSES_STDDEV_POPULATION_COMMENT_ASC',
    CoursesStddevPopulationCommentDesc = 'COURSES_STDDEV_POPULATION_COMMENT_DESC',
    CoursesStddevPopulationDetailsAsc = 'COURSES_STDDEV_POPULATION_DETAILS_ASC',
    CoursesStddevPopulationDetailsDesc = 'COURSES_STDDEV_POPULATION_DETAILS_DESC',
    CoursesStddevPopulationArchivedAsc = 'COURSES_STDDEV_POPULATION_ARCHIVED_ASC',
    CoursesStddevPopulationArchivedDesc = 'COURSES_STDDEV_POPULATION_ARCHIVED_DESC',
    CoursesStddevPopulationDefaultLocationIdAsc = 'COURSES_STDDEV_POPULATION_DEFAULT_LOCATION_ID_ASC',
    CoursesStddevPopulationDefaultLocationIdDesc = 'COURSES_STDDEV_POPULATION_DEFAULT_LOCATION_ID_DESC',
    CoursesStddevPopulationDefaultTimetableAsc = 'COURSES_STDDEV_POPULATION_DEFAULT_TIMETABLE_ASC',
    CoursesStddevPopulationDefaultTimetableDesc = 'COURSES_STDDEV_POPULATION_DEFAULT_TIMETABLE_DESC',
    CoursesStddevPopulationCourseGroupIdAsc = 'COURSES_STDDEV_POPULATION_COURSE_GROUP_ID_ASC',
    CoursesStddevPopulationCourseGroupIdDesc = 'COURSES_STDDEV_POPULATION_COURSE_GROUP_ID_DESC',
    CoursesStddevPopulationCreatedAtAsc = 'COURSES_STDDEV_POPULATION_CREATED_AT_ASC',
    CoursesStddevPopulationCreatedAtDesc = 'COURSES_STDDEV_POPULATION_CREATED_AT_DESC',
    CoursesStddevPopulationUpdatedAtAsc = 'COURSES_STDDEV_POPULATION_UPDATED_AT_ASC',
    CoursesStddevPopulationUpdatedAtDesc = 'COURSES_STDDEV_POPULATION_UPDATED_AT_DESC',
    CoursesStddevPopulationDefaultSeatsAsc = 'COURSES_STDDEV_POPULATION_DEFAULT_SEATS_ASC',
    CoursesStddevPopulationDefaultSeatsDesc = 'COURSES_STDDEV_POPULATION_DEFAULT_SEATS_DESC',
    CoursesStddevPopulationDefaultDurationAsc = 'COURSES_STDDEV_POPULATION_DEFAULT_DURATION_ASC',
    CoursesStddevPopulationDefaultDurationDesc = 'COURSES_STDDEV_POPULATION_DEFAULT_DURATION_DESC',
    CoursesStddevPopulationDefaultUnitsAsc = 'COURSES_STDDEV_POPULATION_DEFAULT_UNITS_ASC',
    CoursesStddevPopulationDefaultUnitsDesc = 'COURSES_STDDEV_POPULATION_DEFAULT_UNITS_DESC',
    CoursesStddevPopulationDefaultPriceAsc = 'COURSES_STDDEV_POPULATION_DEFAULT_PRICE_ASC',
    CoursesStddevPopulationDefaultPriceDesc = 'COURSES_STDDEV_POPULATION_DEFAULT_PRICE_DESC',
    CoursesStddevPopulationCertSetupAsc = 'COURSES_STDDEV_POPULATION_CERT_SETUP_ASC',
    CoursesStddevPopulationCertSetupDesc = 'COURSES_STDDEV_POPULATION_CERT_SETUP_DESC',
    CoursesStddevPopulationConfirmationContentAsc = 'COURSES_STDDEV_POPULATION_CONFIRMATION_CONTENT_ASC',
    CoursesStddevPopulationConfirmationContentDesc = 'COURSES_STDDEV_POPULATION_CONFIRMATION_CONTENT_DESC',
    CoursesVarianceSampleIdAsc = 'COURSES_VARIANCE_SAMPLE_ID_ASC',
    CoursesVarianceSampleIdDesc = 'COURSES_VARIANCE_SAMPLE_ID_DESC',
    CoursesVarianceSampleNameAsc = 'COURSES_VARIANCE_SAMPLE_NAME_ASC',
    CoursesVarianceSampleNameDesc = 'COURSES_VARIANCE_SAMPLE_NAME_DESC',
    CoursesVarianceSampleDescriptionAsc = 'COURSES_VARIANCE_SAMPLE_DESCRIPTION_ASC',
    CoursesVarianceSampleDescriptionDesc = 'COURSES_VARIANCE_SAMPLE_DESCRIPTION_DESC',
    CoursesVarianceSampleCommentAsc = 'COURSES_VARIANCE_SAMPLE_COMMENT_ASC',
    CoursesVarianceSampleCommentDesc = 'COURSES_VARIANCE_SAMPLE_COMMENT_DESC',
    CoursesVarianceSampleDetailsAsc = 'COURSES_VARIANCE_SAMPLE_DETAILS_ASC',
    CoursesVarianceSampleDetailsDesc = 'COURSES_VARIANCE_SAMPLE_DETAILS_DESC',
    CoursesVarianceSampleArchivedAsc = 'COURSES_VARIANCE_SAMPLE_ARCHIVED_ASC',
    CoursesVarianceSampleArchivedDesc = 'COURSES_VARIANCE_SAMPLE_ARCHIVED_DESC',
    CoursesVarianceSampleDefaultLocationIdAsc = 'COURSES_VARIANCE_SAMPLE_DEFAULT_LOCATION_ID_ASC',
    CoursesVarianceSampleDefaultLocationIdDesc = 'COURSES_VARIANCE_SAMPLE_DEFAULT_LOCATION_ID_DESC',
    CoursesVarianceSampleDefaultTimetableAsc = 'COURSES_VARIANCE_SAMPLE_DEFAULT_TIMETABLE_ASC',
    CoursesVarianceSampleDefaultTimetableDesc = 'COURSES_VARIANCE_SAMPLE_DEFAULT_TIMETABLE_DESC',
    CoursesVarianceSampleCourseGroupIdAsc = 'COURSES_VARIANCE_SAMPLE_COURSE_GROUP_ID_ASC',
    CoursesVarianceSampleCourseGroupIdDesc = 'COURSES_VARIANCE_SAMPLE_COURSE_GROUP_ID_DESC',
    CoursesVarianceSampleCreatedAtAsc = 'COURSES_VARIANCE_SAMPLE_CREATED_AT_ASC',
    CoursesVarianceSampleCreatedAtDesc = 'COURSES_VARIANCE_SAMPLE_CREATED_AT_DESC',
    CoursesVarianceSampleUpdatedAtAsc = 'COURSES_VARIANCE_SAMPLE_UPDATED_AT_ASC',
    CoursesVarianceSampleUpdatedAtDesc = 'COURSES_VARIANCE_SAMPLE_UPDATED_AT_DESC',
    CoursesVarianceSampleDefaultSeatsAsc = 'COURSES_VARIANCE_SAMPLE_DEFAULT_SEATS_ASC',
    CoursesVarianceSampleDefaultSeatsDesc = 'COURSES_VARIANCE_SAMPLE_DEFAULT_SEATS_DESC',
    CoursesVarianceSampleDefaultDurationAsc = 'COURSES_VARIANCE_SAMPLE_DEFAULT_DURATION_ASC',
    CoursesVarianceSampleDefaultDurationDesc = 'COURSES_VARIANCE_SAMPLE_DEFAULT_DURATION_DESC',
    CoursesVarianceSampleDefaultUnitsAsc = 'COURSES_VARIANCE_SAMPLE_DEFAULT_UNITS_ASC',
    CoursesVarianceSampleDefaultUnitsDesc = 'COURSES_VARIANCE_SAMPLE_DEFAULT_UNITS_DESC',
    CoursesVarianceSampleDefaultPriceAsc = 'COURSES_VARIANCE_SAMPLE_DEFAULT_PRICE_ASC',
    CoursesVarianceSampleDefaultPriceDesc = 'COURSES_VARIANCE_SAMPLE_DEFAULT_PRICE_DESC',
    CoursesVarianceSampleCertSetupAsc = 'COURSES_VARIANCE_SAMPLE_CERT_SETUP_ASC',
    CoursesVarianceSampleCertSetupDesc = 'COURSES_VARIANCE_SAMPLE_CERT_SETUP_DESC',
    CoursesVarianceSampleConfirmationContentAsc = 'COURSES_VARIANCE_SAMPLE_CONFIRMATION_CONTENT_ASC',
    CoursesVarianceSampleConfirmationContentDesc = 'COURSES_VARIANCE_SAMPLE_CONFIRMATION_CONTENT_DESC',
    CoursesVariancePopulationIdAsc = 'COURSES_VARIANCE_POPULATION_ID_ASC',
    CoursesVariancePopulationIdDesc = 'COURSES_VARIANCE_POPULATION_ID_DESC',
    CoursesVariancePopulationNameAsc = 'COURSES_VARIANCE_POPULATION_NAME_ASC',
    CoursesVariancePopulationNameDesc = 'COURSES_VARIANCE_POPULATION_NAME_DESC',
    CoursesVariancePopulationDescriptionAsc = 'COURSES_VARIANCE_POPULATION_DESCRIPTION_ASC',
    CoursesVariancePopulationDescriptionDesc = 'COURSES_VARIANCE_POPULATION_DESCRIPTION_DESC',
    CoursesVariancePopulationCommentAsc = 'COURSES_VARIANCE_POPULATION_COMMENT_ASC',
    CoursesVariancePopulationCommentDesc = 'COURSES_VARIANCE_POPULATION_COMMENT_DESC',
    CoursesVariancePopulationDetailsAsc = 'COURSES_VARIANCE_POPULATION_DETAILS_ASC',
    CoursesVariancePopulationDetailsDesc = 'COURSES_VARIANCE_POPULATION_DETAILS_DESC',
    CoursesVariancePopulationArchivedAsc = 'COURSES_VARIANCE_POPULATION_ARCHIVED_ASC',
    CoursesVariancePopulationArchivedDesc = 'COURSES_VARIANCE_POPULATION_ARCHIVED_DESC',
    CoursesVariancePopulationDefaultLocationIdAsc = 'COURSES_VARIANCE_POPULATION_DEFAULT_LOCATION_ID_ASC',
    CoursesVariancePopulationDefaultLocationIdDesc = 'COURSES_VARIANCE_POPULATION_DEFAULT_LOCATION_ID_DESC',
    CoursesVariancePopulationDefaultTimetableAsc = 'COURSES_VARIANCE_POPULATION_DEFAULT_TIMETABLE_ASC',
    CoursesVariancePopulationDefaultTimetableDesc = 'COURSES_VARIANCE_POPULATION_DEFAULT_TIMETABLE_DESC',
    CoursesVariancePopulationCourseGroupIdAsc = 'COURSES_VARIANCE_POPULATION_COURSE_GROUP_ID_ASC',
    CoursesVariancePopulationCourseGroupIdDesc = 'COURSES_VARIANCE_POPULATION_COURSE_GROUP_ID_DESC',
    CoursesVariancePopulationCreatedAtAsc = 'COURSES_VARIANCE_POPULATION_CREATED_AT_ASC',
    CoursesVariancePopulationCreatedAtDesc = 'COURSES_VARIANCE_POPULATION_CREATED_AT_DESC',
    CoursesVariancePopulationUpdatedAtAsc = 'COURSES_VARIANCE_POPULATION_UPDATED_AT_ASC',
    CoursesVariancePopulationUpdatedAtDesc = 'COURSES_VARIANCE_POPULATION_UPDATED_AT_DESC',
    CoursesVariancePopulationDefaultSeatsAsc = 'COURSES_VARIANCE_POPULATION_DEFAULT_SEATS_ASC',
    CoursesVariancePopulationDefaultSeatsDesc = 'COURSES_VARIANCE_POPULATION_DEFAULT_SEATS_DESC',
    CoursesVariancePopulationDefaultDurationAsc = 'COURSES_VARIANCE_POPULATION_DEFAULT_DURATION_ASC',
    CoursesVariancePopulationDefaultDurationDesc = 'COURSES_VARIANCE_POPULATION_DEFAULT_DURATION_DESC',
    CoursesVariancePopulationDefaultUnitsAsc = 'COURSES_VARIANCE_POPULATION_DEFAULT_UNITS_ASC',
    CoursesVariancePopulationDefaultUnitsDesc = 'COURSES_VARIANCE_POPULATION_DEFAULT_UNITS_DESC',
    CoursesVariancePopulationDefaultPriceAsc = 'COURSES_VARIANCE_POPULATION_DEFAULT_PRICE_ASC',
    CoursesVariancePopulationDefaultPriceDesc = 'COURSES_VARIANCE_POPULATION_DEFAULT_PRICE_DESC',
    CoursesVariancePopulationCertSetupAsc = 'COURSES_VARIANCE_POPULATION_CERT_SETUP_ASC',
    CoursesVariancePopulationCertSetupDesc = 'COURSES_VARIANCE_POPULATION_CERT_SETUP_DESC',
    CoursesVariancePopulationConfirmationContentAsc = 'COURSES_VARIANCE_POPULATION_CONFIRMATION_CONTENT_ASC',
    CoursesVariancePopulationConfirmationContentDesc = 'COURSES_VARIANCE_POPULATION_CONFIRMATION_CONTENT_DESC',
}

export type CourseHavingAverageCoursePlannedScheduleCountInput = {
    filter?: Maybe<HavingIntFilter>;
};

export type CourseHavingAverageInput = {
    id?: Maybe<HavingIntFilter>;
    defaultLocationId?: Maybe<HavingIntFilter>;
    courseGroupId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
    defaultSeats?: Maybe<HavingIntFilter>;
    defaultDuration?: Maybe<HavingIntFilter>;
    defaultUnits?: Maybe<HavingIntFilter>;
    defaultPrice?: Maybe<HavingFloatFilter>;
    plannedScheduleCount?: Maybe<CourseHavingAverageCoursePlannedScheduleCountInput>;
};

export type CourseHavingDistinctCountCourseLastDateInput = {
    filter?: Maybe<HavingDatetimeFilter>;
};

export type CourseHavingDistinctCountCourseNextDateInput = {
    filter?: Maybe<HavingDatetimeFilter>;
};

export type CourseHavingDistinctCountCoursePlannedScheduleCountInput = {
    filter?: Maybe<HavingIntFilter>;
};

export type CourseHavingDistinctCountInput = {
    id?: Maybe<HavingIntFilter>;
    defaultLocationId?: Maybe<HavingIntFilter>;
    courseGroupId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
    defaultSeats?: Maybe<HavingIntFilter>;
    defaultDuration?: Maybe<HavingIntFilter>;
    defaultUnits?: Maybe<HavingIntFilter>;
    defaultPrice?: Maybe<HavingFloatFilter>;
    lastDate?: Maybe<CourseHavingDistinctCountCourseLastDateInput>;
    nextDate?: Maybe<CourseHavingDistinctCountCourseNextDateInput>;
    plannedScheduleCount?: Maybe<CourseHavingDistinctCountCoursePlannedScheduleCountInput>;
};

/** Conditions for `Course` aggregates. */
export type CourseHavingInput = {
    AND?: Maybe<Array<CourseHavingInput>>;
    OR?: Maybe<Array<CourseHavingInput>>;
    sum?: Maybe<CourseHavingSumInput>;
    distinctCount?: Maybe<CourseHavingDistinctCountInput>;
    min?: Maybe<CourseHavingMinInput>;
    max?: Maybe<CourseHavingMaxInput>;
    average?: Maybe<CourseHavingAverageInput>;
    stddevSample?: Maybe<CourseHavingStddevSampleInput>;
    stddevPopulation?: Maybe<CourseHavingStddevPopulationInput>;
    varianceSample?: Maybe<CourseHavingVarianceSampleInput>;
    variancePopulation?: Maybe<CourseHavingVariancePopulationInput>;
};

export type CourseHavingMaxCoursePlannedScheduleCountInput = {
    filter?: Maybe<HavingIntFilter>;
};

export type CourseHavingMaxInput = {
    id?: Maybe<HavingIntFilter>;
    defaultLocationId?: Maybe<HavingIntFilter>;
    courseGroupId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
    defaultSeats?: Maybe<HavingIntFilter>;
    defaultDuration?: Maybe<HavingIntFilter>;
    defaultUnits?: Maybe<HavingIntFilter>;
    defaultPrice?: Maybe<HavingFloatFilter>;
    plannedScheduleCount?: Maybe<CourseHavingMaxCoursePlannedScheduleCountInput>;
};

export type CourseHavingMinCoursePlannedScheduleCountInput = {
    filter?: Maybe<HavingIntFilter>;
};

export type CourseHavingMinInput = {
    id?: Maybe<HavingIntFilter>;
    defaultLocationId?: Maybe<HavingIntFilter>;
    courseGroupId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
    defaultSeats?: Maybe<HavingIntFilter>;
    defaultDuration?: Maybe<HavingIntFilter>;
    defaultUnits?: Maybe<HavingIntFilter>;
    defaultPrice?: Maybe<HavingFloatFilter>;
    plannedScheduleCount?: Maybe<CourseHavingMinCoursePlannedScheduleCountInput>;
};

export type CourseHavingStddevPopulationCoursePlannedScheduleCountInput = {
    filter?: Maybe<HavingIntFilter>;
};

export type CourseHavingStddevPopulationInput = {
    id?: Maybe<HavingIntFilter>;
    defaultLocationId?: Maybe<HavingIntFilter>;
    courseGroupId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
    defaultSeats?: Maybe<HavingIntFilter>;
    defaultDuration?: Maybe<HavingIntFilter>;
    defaultUnits?: Maybe<HavingIntFilter>;
    defaultPrice?: Maybe<HavingFloatFilter>;
    plannedScheduleCount?: Maybe<CourseHavingStddevPopulationCoursePlannedScheduleCountInput>;
};

export type CourseHavingStddevSampleCoursePlannedScheduleCountInput = {
    filter?: Maybe<HavingIntFilter>;
};

export type CourseHavingStddevSampleInput = {
    id?: Maybe<HavingIntFilter>;
    defaultLocationId?: Maybe<HavingIntFilter>;
    courseGroupId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
    defaultSeats?: Maybe<HavingIntFilter>;
    defaultDuration?: Maybe<HavingIntFilter>;
    defaultUnits?: Maybe<HavingIntFilter>;
    defaultPrice?: Maybe<HavingFloatFilter>;
    plannedScheduleCount?: Maybe<CourseHavingStddevSampleCoursePlannedScheduleCountInput>;
};

export type CourseHavingSumCoursePlannedScheduleCountInput = {
    filter?: Maybe<HavingIntFilter>;
};

export type CourseHavingSumInput = {
    id?: Maybe<HavingIntFilter>;
    defaultLocationId?: Maybe<HavingIntFilter>;
    courseGroupId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
    defaultSeats?: Maybe<HavingIntFilter>;
    defaultDuration?: Maybe<HavingIntFilter>;
    defaultUnits?: Maybe<HavingIntFilter>;
    defaultPrice?: Maybe<HavingFloatFilter>;
    plannedScheduleCount?: Maybe<CourseHavingSumCoursePlannedScheduleCountInput>;
};

export type CourseHavingVariancePopulationCoursePlannedScheduleCountInput = {
    filter?: Maybe<HavingIntFilter>;
};

export type CourseHavingVariancePopulationInput = {
    id?: Maybe<HavingIntFilter>;
    defaultLocationId?: Maybe<HavingIntFilter>;
    courseGroupId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
    defaultSeats?: Maybe<HavingIntFilter>;
    defaultDuration?: Maybe<HavingIntFilter>;
    defaultUnits?: Maybe<HavingIntFilter>;
    defaultPrice?: Maybe<HavingFloatFilter>;
    plannedScheduleCount?: Maybe<CourseHavingVariancePopulationCoursePlannedScheduleCountInput>;
};

export type CourseHavingVarianceSampleCoursePlannedScheduleCountInput = {
    filter?: Maybe<HavingIntFilter>;
};

export type CourseHavingVarianceSampleInput = {
    id?: Maybe<HavingIntFilter>;
    defaultLocationId?: Maybe<HavingIntFilter>;
    courseGroupId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
    defaultSeats?: Maybe<HavingIntFilter>;
    defaultDuration?: Maybe<HavingIntFilter>;
    defaultUnits?: Maybe<HavingIntFilter>;
    defaultPrice?: Maybe<HavingFloatFilter>;
    plannedScheduleCount?: Maybe<CourseHavingVarianceSampleCoursePlannedScheduleCountInput>;
};

/** An input for mutations affecting `Course` */
export type CourseInput = {
    id?: Maybe<Scalars['Int']>;
    name?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    comment?: Maybe<Scalars['String']>;
    details?: Maybe<Scalars['String']>;
    archived?: Maybe<Scalars['Boolean']>;
    defaultLocationId?: Maybe<Scalars['Int']>;
    defaultTimetable?: Maybe<Scalars['String']>;
    courseGroupId?: Maybe<Scalars['Int']>;
    createdAt?: Maybe<Scalars['Datetime']>;
    updatedAt?: Maybe<Scalars['Datetime']>;
    defaultSeats?: Maybe<Scalars['Int']>;
    defaultDuration?: Maybe<Scalars['Int']>;
    defaultUnits?: Maybe<Scalars['Int']>;
    defaultPrice?: Maybe<Scalars['Float']>;
    certSetup?: Maybe<Scalars['JSON']>;
    confirmationContent?: Maybe<Scalars['String']>;
};

export type CourseMaxAggregates = {
    __typename?: 'CourseMaxAggregates';
    /** Maximum of id across the matching connection */
    id?: Maybe<Scalars['Int']>;
    /** Maximum of defaultLocationId across the matching connection */
    defaultLocationId?: Maybe<Scalars['Int']>;
    /** Maximum of courseGroupId across the matching connection */
    courseGroupId?: Maybe<Scalars['Int']>;
    /** Maximum of defaultSeats across the matching connection */
    defaultSeats?: Maybe<Scalars['Int']>;
    /** Maximum of defaultDuration across the matching connection */
    defaultDuration?: Maybe<Scalars['Int']>;
    /** Maximum of defaultUnits across the matching connection */
    defaultUnits?: Maybe<Scalars['Int']>;
    /** Maximum of defaultPrice across the matching connection */
    defaultPrice?: Maybe<Scalars['Float']>;
    /** Maximum of this field across the matching connection. */
    plannedScheduleCount?: Maybe<Scalars['Int']>;
};

export type CourseMinAggregates = {
    __typename?: 'CourseMinAggregates';
    /** Minimum of id across the matching connection */
    id?: Maybe<Scalars['Int']>;
    /** Minimum of defaultLocationId across the matching connection */
    defaultLocationId?: Maybe<Scalars['Int']>;
    /** Minimum of courseGroupId across the matching connection */
    courseGroupId?: Maybe<Scalars['Int']>;
    /** Minimum of defaultSeats across the matching connection */
    defaultSeats?: Maybe<Scalars['Int']>;
    /** Minimum of defaultDuration across the matching connection */
    defaultDuration?: Maybe<Scalars['Int']>;
    /** Minimum of defaultUnits across the matching connection */
    defaultUnits?: Maybe<Scalars['Int']>;
    /** Minimum of defaultPrice across the matching connection */
    defaultPrice?: Maybe<Scalars['Float']>;
    /** Minimum of this field across the matching connection. */
    plannedScheduleCount?: Maybe<Scalars['Int']>;
};

/** Represents an update to a `Course`. Fields that are set will be updated. */
export type CoursePatch = {
    id?: Maybe<Scalars['Int']>;
    name?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    comment?: Maybe<Scalars['String']>;
    details?: Maybe<Scalars['String']>;
    archived?: Maybe<Scalars['Boolean']>;
    defaultLocationId?: Maybe<Scalars['Int']>;
    defaultTimetable?: Maybe<Scalars['String']>;
    courseGroupId?: Maybe<Scalars['Int']>;
    createdAt?: Maybe<Scalars['Datetime']>;
    updatedAt?: Maybe<Scalars['Datetime']>;
    defaultSeats?: Maybe<Scalars['Int']>;
    defaultDuration?: Maybe<Scalars['Int']>;
    defaultUnits?: Maybe<Scalars['Int']>;
    defaultPrice?: Maybe<Scalars['Float']>;
    certSetup?: Maybe<Scalars['JSON']>;
    confirmationContent?: Maybe<Scalars['String']>;
};

export type CourseSchedule = Node & {
    __typename?: 'CourseSchedule';
    /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
    nodeId: Scalars['ID'];
    id: Scalars['Int'];
    courseId?: Maybe<Scalars['Int']>;
    startDate: Scalars['Date'];
    endDate: Scalars['Date'];
    seats?: Maybe<Scalars['Int']>;
    duration?: Maybe<Scalars['Int']>;
    units?: Maybe<Scalars['Int']>;
    price?: Maybe<Scalars['Float']>;
    timetable?: Maybe<Scalars['String']>;
    cancelled?: Maybe<Scalars['Boolean']>;
    comment?: Maybe<Scalars['String']>;
    locationId?: Maybe<Scalars['Int']>;
    createdAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    reminderSentAt?: Maybe<Scalars['Datetime']>;
    /** Reads a single `Course` that is related to this `CourseSchedule`. */
    course?: Maybe<Course>;
    /** Reads a single `Location` that is related to this `CourseSchedule`. */
    location?: Maybe<Location>;
    /** Reads and enables pagination through a set of `Booking`. */
    bookings: BookingsConnection;
    /** Reads and enables pagination through a set of `CourseScheduleSpeaker`. */
    courseScheduleSpeakers: CourseScheduleSpeakersConnection;
    courseName?: Maybe<Scalars['String']>;
    dateSchedule?: Maybe<Scalars['String']>;
    dateScheduleSortable?: Maybe<Scalars['String']>;
    daysAndUnits?: Maybe<Scalars['String']>;
    emailList?: Maybe<Scalars['String']>;
    emptyLines?: Maybe<Scalars['Int']>;
    freeSeats?: Maybe<Scalars['BigInt']>;
    priceFormatted?: Maybe<Scalars['String']>;
    resolvedComment?: Maybe<Scalars['String']>;
    resolvedLocation?: Maybe<Location>;
    /** Reads and enables pagination through a set of `Speaker`. */
    resolvedSpeakers: SpeakersConnection;
    resolvedTimetable?: Maybe<Scalars['String']>;
    /** Reads and enables pagination through a set of `Speaker`. */
    speakersByCourseScheduleId: SpeakersConnection;
    status?: Maybe<CourseScheduleStatus>;
};

export type CourseScheduleBookingsArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['Cursor']>;
    after?: Maybe<Scalars['Cursor']>;
    orderBy?: Maybe<Array<BookingsOrderBy>>;
    condition?: Maybe<BookingCondition>;
    filter?: Maybe<BookingFilter>;
};

export type CourseScheduleCourseScheduleSpeakersArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['Cursor']>;
    after?: Maybe<Scalars['Cursor']>;
    orderBy?: Maybe<Array<CourseScheduleSpeakersOrderBy>>;
    condition?: Maybe<CourseScheduleSpeakerCondition>;
    filter?: Maybe<CourseScheduleSpeakerFilter>;
};

export type CourseScheduleResolvedSpeakersArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['Cursor']>;
    after?: Maybe<Scalars['Cursor']>;
    filter?: Maybe<SpeakerFilter>;
};

export type CourseScheduleSpeakersByCourseScheduleIdArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['Cursor']>;
    after?: Maybe<Scalars['Cursor']>;
    filter?: Maybe<SpeakerFilter>;
};

export type CourseScheduleAggregates = {
    __typename?: 'CourseScheduleAggregates';
    keys?: Maybe<Array<Scalars['String']>>;
    /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    sum?: Maybe<CourseScheduleSumAggregates>;
    /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
    distinctCount?: Maybe<CourseScheduleDistinctCountAggregates>;
    /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    min?: Maybe<CourseScheduleMinAggregates>;
    /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    max?: Maybe<CourseScheduleMaxAggregates>;
    /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
    average?: Maybe<CourseScheduleAverageAggregates>;
    /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
    stddevSample?: Maybe<CourseScheduleStddevSampleAggregates>;
    /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
    stddevPopulation?: Maybe<CourseScheduleStddevPopulationAggregates>;
    /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
    varianceSample?: Maybe<CourseScheduleVarianceSampleAggregates>;
    /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
    variancePopulation?: Maybe<CourseScheduleVariancePopulationAggregates>;
};

export type CourseScheduleAverageAggregates = {
    __typename?: 'CourseScheduleAverageAggregates';
    /** Mean average of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Mean average of courseId across the matching connection */
    courseId?: Maybe<Scalars['BigFloat']>;
    /** Mean average of seats across the matching connection */
    seats?: Maybe<Scalars['BigFloat']>;
    /** Mean average of duration across the matching connection */
    duration?: Maybe<Scalars['BigFloat']>;
    /** Mean average of units across the matching connection */
    units?: Maybe<Scalars['BigFloat']>;
    /** Mean average of price across the matching connection */
    price?: Maybe<Scalars['Float']>;
    /** Mean average of locationId across the matching connection */
    locationId?: Maybe<Scalars['BigFloat']>;
    /** Mean average of this field across the matching connection. */
    emptyLines?: Maybe<Scalars['BigFloat']>;
    /** Mean average of this field across the matching connection. */
    freeSeats?: Maybe<Scalars['BigFloat']>;
};

/** All input for the `courseScheduleBookable` mutation. */
export type CourseScheduleBookableInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    recipient: Scalars['String'];
    pCourseScheduleId: Scalars['Int'];
};

/** The output of our `courseScheduleBookable` mutation. */
export type CourseScheduleBookablePayload = {
    __typename?: 'CourseScheduleBookablePayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    boolean?: Maybe<Scalars['Boolean']>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
};

/**
 * A condition to be used against `CourseSchedule` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CourseScheduleCondition = {
    /** Checks for equality with the object’s `id` field. */
    id?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `courseId` field. */
    courseId?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `startDate` field. */
    startDate?: Maybe<Scalars['Date']>;
    /** Checks for equality with the object’s `endDate` field. */
    endDate?: Maybe<Scalars['Date']>;
    /** Checks for equality with the object’s `seats` field. */
    seats?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `duration` field. */
    duration?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `units` field. */
    units?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `price` field. */
    price?: Maybe<Scalars['Float']>;
    /** Checks for equality with the object’s `timetable` field. */
    timetable?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `cancelled` field. */
    cancelled?: Maybe<Scalars['Boolean']>;
    /** Checks for equality with the object’s `comment` field. */
    comment?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `locationId` field. */
    locationId?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `createdAt` field. */
    createdAt?: Maybe<Scalars['Datetime']>;
    /** Checks for equality with the object’s `updatedAt` field. */
    updatedAt?: Maybe<Scalars['Datetime']>;
    /** Checks for equality with the object’s `reminderSentAt` field. */
    reminderSentAt?: Maybe<Scalars['Datetime']>;
    /** Checks for equality with the object’s `dateScheduleSortable` field. */
    dateScheduleSortable?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `status` field. */
    status?: Maybe<CourseScheduleStatus>;
};

export type CourseScheduleDistinctCountAggregates = {
    __typename?: 'CourseScheduleDistinctCountAggregates';
    /** Distinct count of id across the matching connection */
    id?: Maybe<Scalars['BigInt']>;
    /** Distinct count of courseId across the matching connection */
    courseId?: Maybe<Scalars['BigInt']>;
    /** Distinct count of startDate across the matching connection */
    startDate?: Maybe<Scalars['BigInt']>;
    /** Distinct count of endDate across the matching connection */
    endDate?: Maybe<Scalars['BigInt']>;
    /** Distinct count of seats across the matching connection */
    seats?: Maybe<Scalars['BigInt']>;
    /** Distinct count of duration across the matching connection */
    duration?: Maybe<Scalars['BigInt']>;
    /** Distinct count of units across the matching connection */
    units?: Maybe<Scalars['BigInt']>;
    /** Distinct count of price across the matching connection */
    price?: Maybe<Scalars['BigInt']>;
    /** Distinct count of timetable across the matching connection */
    timetable?: Maybe<Scalars['BigInt']>;
    /** Distinct count of cancelled across the matching connection */
    cancelled?: Maybe<Scalars['BigInt']>;
    /** Distinct count of comment across the matching connection */
    comment?: Maybe<Scalars['BigInt']>;
    /** Distinct count of locationId across the matching connection */
    locationId?: Maybe<Scalars['BigInt']>;
    /** Distinct count of createdAt across the matching connection */
    createdAt?: Maybe<Scalars['BigInt']>;
    /** Distinct count of updatedAt across the matching connection */
    updatedAt?: Maybe<Scalars['BigInt']>;
    /** Distinct count of reminderSentAt across the matching connection */
    reminderSentAt?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    courseName?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    dateSchedule?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    dateScheduleSortable?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    daysAndUnits?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    emailList?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    emptyLines?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    freeSeats?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    priceFormatted?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    resolvedComment?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    resolvedLocation?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    resolvedTimetable?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    status?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `CourseSchedule` object types. All fields are combined with a logical ‘and.’ */
export type CourseScheduleFilter = {
    /** Filter by the object’s `id` field. */
    id?: Maybe<IntFilter>;
    /** Filter by the object’s `courseId` field. */
    courseId?: Maybe<IntFilter>;
    /** Filter by the object’s `startDate` field. */
    startDate?: Maybe<DateFilter>;
    /** Filter by the object’s `endDate` field. */
    endDate?: Maybe<DateFilter>;
    /** Filter by the object’s `seats` field. */
    seats?: Maybe<IntFilter>;
    /** Filter by the object’s `duration` field. */
    duration?: Maybe<IntFilter>;
    /** Filter by the object’s `units` field. */
    units?: Maybe<IntFilter>;
    /** Filter by the object’s `price` field. */
    price?: Maybe<FloatFilter>;
    /** Filter by the object’s `timetable` field. */
    timetable?: Maybe<StringFilter>;
    /** Filter by the object’s `cancelled` field. */
    cancelled?: Maybe<BooleanFilter>;
    /** Filter by the object’s `comment` field. */
    comment?: Maybe<StringFilter>;
    /** Filter by the object’s `locationId` field. */
    locationId?: Maybe<IntFilter>;
    /** Filter by the object’s `createdAt` field. */
    createdAt?: Maybe<DatetimeFilter>;
    /** Filter by the object’s `updatedAt` field. */
    updatedAt?: Maybe<DatetimeFilter>;
    /** Filter by the object’s `reminderSentAt` field. */
    reminderSentAt?: Maybe<DatetimeFilter>;
    /** Filter by the object’s `courseName` field. */
    courseName?: Maybe<StringFilter>;
    /** Filter by the object’s `dateSchedule` field. */
    dateSchedule?: Maybe<StringFilter>;
    /** Filter by the object’s `dateScheduleSortable` field. */
    dateScheduleSortable?: Maybe<StringFilter>;
    /** Filter by the object’s `daysAndUnits` field. */
    daysAndUnits?: Maybe<StringFilter>;
    /** Filter by the object’s `emailList` field. */
    emailList?: Maybe<StringFilter>;
    /** Filter by the object’s `emptyLines` field. */
    emptyLines?: Maybe<IntFilter>;
    /** Filter by the object’s `freeSeats` field. */
    freeSeats?: Maybe<BigIntFilter>;
    /** Filter by the object’s `priceFormatted` field. */
    priceFormatted?: Maybe<StringFilter>;
    /** Filter by the object’s `resolvedComment` field. */
    resolvedComment?: Maybe<StringFilter>;
    /** Filter by the object’s `resolvedTimetable` field. */
    resolvedTimetable?: Maybe<StringFilter>;
    /** Filter by the object’s `status` field. */
    status?: Maybe<CourseScheduleStatusFilter>;
    /** Checks for all expressions in this list. */
    and?: Maybe<Array<CourseScheduleFilter>>;
    /** Checks for any expressions in this list. */
    or?: Maybe<Array<CourseScheduleFilter>>;
    /** Negates the expression. */
    not?: Maybe<CourseScheduleFilter>;
};

/** Grouping methods for `CourseSchedule` for usage during aggregation. */
export enum CourseScheduleGroupBy {
    CourseId = 'COURSE_ID',
    StartDate = 'START_DATE',
    EndDate = 'END_DATE',
    Seats = 'SEATS',
    Duration = 'DURATION',
    Units = 'UNITS',
    Price = 'PRICE',
    Timetable = 'TIMETABLE',
    Cancelled = 'CANCELLED',
    Comment = 'COMMENT',
    LocationId = 'LOCATION_ID',
    CreatedAt = 'CREATED_AT',
    CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
    CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
    UpdatedAt = 'UPDATED_AT',
    UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
    UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
    ReminderSentAt = 'REMINDER_SENT_AT',
    ReminderSentAtTruncatedToHour = 'REMINDER_SENT_AT_TRUNCATED_TO_HOUR',
    ReminderSentAtTruncatedToDay = 'REMINDER_SENT_AT_TRUNCATED_TO_DAY',
}

export type CourseScheduleHavingAverageCourseScheduleEmptyLinesInput = {
    filter?: Maybe<HavingIntFilter>;
};

export type CourseScheduleHavingAverageCourseScheduleFreeSeatsInput = {
    filter?: Maybe<HavingBigintFilter>;
};

export type CourseScheduleHavingAverageInput = {
    id?: Maybe<HavingIntFilter>;
    courseId?: Maybe<HavingIntFilter>;
    startDate?: Maybe<HavingDatetimeFilter>;
    endDate?: Maybe<HavingDatetimeFilter>;
    seats?: Maybe<HavingIntFilter>;
    duration?: Maybe<HavingIntFilter>;
    units?: Maybe<HavingIntFilter>;
    price?: Maybe<HavingFloatFilter>;
    locationId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
    reminderSentAt?: Maybe<HavingDatetimeFilter>;
    emptyLines?: Maybe<CourseScheduleHavingAverageCourseScheduleEmptyLinesInput>;
    freeSeats?: Maybe<CourseScheduleHavingAverageCourseScheduleFreeSeatsInput>;
};

export type CourseScheduleHavingDistinctCountCourseScheduleEmptyLinesInput = {
    filter?: Maybe<HavingIntFilter>;
};

export type CourseScheduleHavingDistinctCountCourseScheduleFreeSeatsInput = {
    filter?: Maybe<HavingBigintFilter>;
};

export type CourseScheduleHavingDistinctCountInput = {
    id?: Maybe<HavingIntFilter>;
    courseId?: Maybe<HavingIntFilter>;
    startDate?: Maybe<HavingDatetimeFilter>;
    endDate?: Maybe<HavingDatetimeFilter>;
    seats?: Maybe<HavingIntFilter>;
    duration?: Maybe<HavingIntFilter>;
    units?: Maybe<HavingIntFilter>;
    price?: Maybe<HavingFloatFilter>;
    locationId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
    reminderSentAt?: Maybe<HavingDatetimeFilter>;
    emptyLines?: Maybe<CourseScheduleHavingDistinctCountCourseScheduleEmptyLinesInput>;
    freeSeats?: Maybe<CourseScheduleHavingDistinctCountCourseScheduleFreeSeatsInput>;
};

/** Conditions for `CourseSchedule` aggregates. */
export type CourseScheduleHavingInput = {
    AND?: Maybe<Array<CourseScheduleHavingInput>>;
    OR?: Maybe<Array<CourseScheduleHavingInput>>;
    sum?: Maybe<CourseScheduleHavingSumInput>;
    distinctCount?: Maybe<CourseScheduleHavingDistinctCountInput>;
    min?: Maybe<CourseScheduleHavingMinInput>;
    max?: Maybe<CourseScheduleHavingMaxInput>;
    average?: Maybe<CourseScheduleHavingAverageInput>;
    stddevSample?: Maybe<CourseScheduleHavingStddevSampleInput>;
    stddevPopulation?: Maybe<CourseScheduleHavingStddevPopulationInput>;
    varianceSample?: Maybe<CourseScheduleHavingVarianceSampleInput>;
    variancePopulation?: Maybe<CourseScheduleHavingVariancePopulationInput>;
};

export type CourseScheduleHavingMaxCourseScheduleEmptyLinesInput = {
    filter?: Maybe<HavingIntFilter>;
};

export type CourseScheduleHavingMaxCourseScheduleFreeSeatsInput = {
    filter?: Maybe<HavingBigintFilter>;
};

export type CourseScheduleHavingMaxInput = {
    id?: Maybe<HavingIntFilter>;
    courseId?: Maybe<HavingIntFilter>;
    startDate?: Maybe<HavingDatetimeFilter>;
    endDate?: Maybe<HavingDatetimeFilter>;
    seats?: Maybe<HavingIntFilter>;
    duration?: Maybe<HavingIntFilter>;
    units?: Maybe<HavingIntFilter>;
    price?: Maybe<HavingFloatFilter>;
    locationId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
    reminderSentAt?: Maybe<HavingDatetimeFilter>;
    emptyLines?: Maybe<CourseScheduleHavingMaxCourseScheduleEmptyLinesInput>;
    freeSeats?: Maybe<CourseScheduleHavingMaxCourseScheduleFreeSeatsInput>;
};

export type CourseScheduleHavingMinCourseScheduleEmptyLinesInput = {
    filter?: Maybe<HavingIntFilter>;
};

export type CourseScheduleHavingMinCourseScheduleFreeSeatsInput = {
    filter?: Maybe<HavingBigintFilter>;
};

export type CourseScheduleHavingMinInput = {
    id?: Maybe<HavingIntFilter>;
    courseId?: Maybe<HavingIntFilter>;
    startDate?: Maybe<HavingDatetimeFilter>;
    endDate?: Maybe<HavingDatetimeFilter>;
    seats?: Maybe<HavingIntFilter>;
    duration?: Maybe<HavingIntFilter>;
    units?: Maybe<HavingIntFilter>;
    price?: Maybe<HavingFloatFilter>;
    locationId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
    reminderSentAt?: Maybe<HavingDatetimeFilter>;
    emptyLines?: Maybe<CourseScheduleHavingMinCourseScheduleEmptyLinesInput>;
    freeSeats?: Maybe<CourseScheduleHavingMinCourseScheduleFreeSeatsInput>;
};

export type CourseScheduleHavingStddevPopulationCourseScheduleEmptyLinesInput = {
    filter?: Maybe<HavingIntFilter>;
};

export type CourseScheduleHavingStddevPopulationCourseScheduleFreeSeatsInput = {
    filter?: Maybe<HavingBigintFilter>;
};

export type CourseScheduleHavingStddevPopulationInput = {
    id?: Maybe<HavingIntFilter>;
    courseId?: Maybe<HavingIntFilter>;
    startDate?: Maybe<HavingDatetimeFilter>;
    endDate?: Maybe<HavingDatetimeFilter>;
    seats?: Maybe<HavingIntFilter>;
    duration?: Maybe<HavingIntFilter>;
    units?: Maybe<HavingIntFilter>;
    price?: Maybe<HavingFloatFilter>;
    locationId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
    reminderSentAt?: Maybe<HavingDatetimeFilter>;
    emptyLines?: Maybe<CourseScheduleHavingStddevPopulationCourseScheduleEmptyLinesInput>;
    freeSeats?: Maybe<CourseScheduleHavingStddevPopulationCourseScheduleFreeSeatsInput>;
};

export type CourseScheduleHavingStddevSampleCourseScheduleEmptyLinesInput = {
    filter?: Maybe<HavingIntFilter>;
};

export type CourseScheduleHavingStddevSampleCourseScheduleFreeSeatsInput = {
    filter?: Maybe<HavingBigintFilter>;
};

export type CourseScheduleHavingStddevSampleInput = {
    id?: Maybe<HavingIntFilter>;
    courseId?: Maybe<HavingIntFilter>;
    startDate?: Maybe<HavingDatetimeFilter>;
    endDate?: Maybe<HavingDatetimeFilter>;
    seats?: Maybe<HavingIntFilter>;
    duration?: Maybe<HavingIntFilter>;
    units?: Maybe<HavingIntFilter>;
    price?: Maybe<HavingFloatFilter>;
    locationId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
    reminderSentAt?: Maybe<HavingDatetimeFilter>;
    emptyLines?: Maybe<CourseScheduleHavingStddevSampleCourseScheduleEmptyLinesInput>;
    freeSeats?: Maybe<CourseScheduleHavingStddevSampleCourseScheduleFreeSeatsInput>;
};

export type CourseScheduleHavingSumCourseScheduleEmptyLinesInput = {
    filter?: Maybe<HavingIntFilter>;
};

export type CourseScheduleHavingSumCourseScheduleFreeSeatsInput = {
    filter?: Maybe<HavingBigintFilter>;
};

export type CourseScheduleHavingSumInput = {
    id?: Maybe<HavingIntFilter>;
    courseId?: Maybe<HavingIntFilter>;
    startDate?: Maybe<HavingDatetimeFilter>;
    endDate?: Maybe<HavingDatetimeFilter>;
    seats?: Maybe<HavingIntFilter>;
    duration?: Maybe<HavingIntFilter>;
    units?: Maybe<HavingIntFilter>;
    price?: Maybe<HavingFloatFilter>;
    locationId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
    reminderSentAt?: Maybe<HavingDatetimeFilter>;
    emptyLines?: Maybe<CourseScheduleHavingSumCourseScheduleEmptyLinesInput>;
    freeSeats?: Maybe<CourseScheduleHavingSumCourseScheduleFreeSeatsInput>;
};

export type CourseScheduleHavingVariancePopulationCourseScheduleEmptyLinesInput = {
    filter?: Maybe<HavingIntFilter>;
};

export type CourseScheduleHavingVariancePopulationCourseScheduleFreeSeatsInput = {
    filter?: Maybe<HavingBigintFilter>;
};

export type CourseScheduleHavingVariancePopulationInput = {
    id?: Maybe<HavingIntFilter>;
    courseId?: Maybe<HavingIntFilter>;
    startDate?: Maybe<HavingDatetimeFilter>;
    endDate?: Maybe<HavingDatetimeFilter>;
    seats?: Maybe<HavingIntFilter>;
    duration?: Maybe<HavingIntFilter>;
    units?: Maybe<HavingIntFilter>;
    price?: Maybe<HavingFloatFilter>;
    locationId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
    reminderSentAt?: Maybe<HavingDatetimeFilter>;
    emptyLines?: Maybe<CourseScheduleHavingVariancePopulationCourseScheduleEmptyLinesInput>;
    freeSeats?: Maybe<CourseScheduleHavingVariancePopulationCourseScheduleFreeSeatsInput>;
};

export type CourseScheduleHavingVarianceSampleCourseScheduleEmptyLinesInput = {
    filter?: Maybe<HavingIntFilter>;
};

export type CourseScheduleHavingVarianceSampleCourseScheduleFreeSeatsInput = {
    filter?: Maybe<HavingBigintFilter>;
};

export type CourseScheduleHavingVarianceSampleInput = {
    id?: Maybe<HavingIntFilter>;
    courseId?: Maybe<HavingIntFilter>;
    startDate?: Maybe<HavingDatetimeFilter>;
    endDate?: Maybe<HavingDatetimeFilter>;
    seats?: Maybe<HavingIntFilter>;
    duration?: Maybe<HavingIntFilter>;
    units?: Maybe<HavingIntFilter>;
    price?: Maybe<HavingFloatFilter>;
    locationId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
    reminderSentAt?: Maybe<HavingDatetimeFilter>;
    emptyLines?: Maybe<CourseScheduleHavingVarianceSampleCourseScheduleEmptyLinesInput>;
    freeSeats?: Maybe<CourseScheduleHavingVarianceSampleCourseScheduleFreeSeatsInput>;
};

/** An input for mutations affecting `CourseSchedule` */
export type CourseScheduleInput = {
    id?: Maybe<Scalars['Int']>;
    courseId?: Maybe<Scalars['Int']>;
    startDate: Scalars['Date'];
    endDate: Scalars['Date'];
    seats?: Maybe<Scalars['Int']>;
    duration?: Maybe<Scalars['Int']>;
    units?: Maybe<Scalars['Int']>;
    price?: Maybe<Scalars['Float']>;
    timetable?: Maybe<Scalars['String']>;
    cancelled?: Maybe<Scalars['Boolean']>;
    comment?: Maybe<Scalars['String']>;
    locationId?: Maybe<Scalars['Int']>;
    createdAt?: Maybe<Scalars['Datetime']>;
    updatedAt?: Maybe<Scalars['Datetime']>;
    reminderSentAt?: Maybe<Scalars['Datetime']>;
};

export type CourseScheduleMaxAggregates = {
    __typename?: 'CourseScheduleMaxAggregates';
    /** Maximum of id across the matching connection */
    id?: Maybe<Scalars['Int']>;
    /** Maximum of courseId across the matching connection */
    courseId?: Maybe<Scalars['Int']>;
    /** Maximum of seats across the matching connection */
    seats?: Maybe<Scalars['Int']>;
    /** Maximum of duration across the matching connection */
    duration?: Maybe<Scalars['Int']>;
    /** Maximum of units across the matching connection */
    units?: Maybe<Scalars['Int']>;
    /** Maximum of price across the matching connection */
    price?: Maybe<Scalars['Float']>;
    /** Maximum of locationId across the matching connection */
    locationId?: Maybe<Scalars['Int']>;
    /** Maximum of this field across the matching connection. */
    emptyLines?: Maybe<Scalars['Int']>;
    /** Maximum of this field across the matching connection. */
    freeSeats?: Maybe<Scalars['BigInt']>;
};

export type CourseScheduleMinAggregates = {
    __typename?: 'CourseScheduleMinAggregates';
    /** Minimum of id across the matching connection */
    id?: Maybe<Scalars['Int']>;
    /** Minimum of courseId across the matching connection */
    courseId?: Maybe<Scalars['Int']>;
    /** Minimum of seats across the matching connection */
    seats?: Maybe<Scalars['Int']>;
    /** Minimum of duration across the matching connection */
    duration?: Maybe<Scalars['Int']>;
    /** Minimum of units across the matching connection */
    units?: Maybe<Scalars['Int']>;
    /** Minimum of price across the matching connection */
    price?: Maybe<Scalars['Float']>;
    /** Minimum of locationId across the matching connection */
    locationId?: Maybe<Scalars['Int']>;
    /** Minimum of this field across the matching connection. */
    emptyLines?: Maybe<Scalars['Int']>;
    /** Minimum of this field across the matching connection. */
    freeSeats?: Maybe<Scalars['BigInt']>;
};

/** Represents an update to a `CourseSchedule`. Fields that are set will be updated. */
export type CourseSchedulePatch = {
    id?: Maybe<Scalars['Int']>;
    courseId?: Maybe<Scalars['Int']>;
    startDate?: Maybe<Scalars['Date']>;
    endDate?: Maybe<Scalars['Date']>;
    seats?: Maybe<Scalars['Int']>;
    duration?: Maybe<Scalars['Int']>;
    units?: Maybe<Scalars['Int']>;
    price?: Maybe<Scalars['Float']>;
    timetable?: Maybe<Scalars['String']>;
    cancelled?: Maybe<Scalars['Boolean']>;
    comment?: Maybe<Scalars['String']>;
    locationId?: Maybe<Scalars['Int']>;
    createdAt?: Maybe<Scalars['Datetime']>;
    updatedAt?: Maybe<Scalars['Datetime']>;
    reminderSentAt?: Maybe<Scalars['Datetime']>;
};

export type CourseScheduleSpeaker = Node & {
    __typename?: 'CourseScheduleSpeaker';
    /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
    nodeId: Scalars['ID'];
    speakerId: Scalars['Int'];
    courseScheduleId: Scalars['Int'];
    createdAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    /** Reads a single `Speaker` that is related to this `CourseScheduleSpeaker`. */
    speaker?: Maybe<Speaker>;
    /** Reads a single `CourseSchedule` that is related to this `CourseScheduleSpeaker`. */
    courseSchedule?: Maybe<CourseSchedule>;
};

export type CourseScheduleSpeakerAggregates = {
    __typename?: 'CourseScheduleSpeakerAggregates';
    keys?: Maybe<Array<Scalars['String']>>;
    /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    sum?: Maybe<CourseScheduleSpeakerSumAggregates>;
    /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
    distinctCount?: Maybe<CourseScheduleSpeakerDistinctCountAggregates>;
    /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    min?: Maybe<CourseScheduleSpeakerMinAggregates>;
    /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    max?: Maybe<CourseScheduleSpeakerMaxAggregates>;
    /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
    average?: Maybe<CourseScheduleSpeakerAverageAggregates>;
    /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
    stddevSample?: Maybe<CourseScheduleSpeakerStddevSampleAggregates>;
    /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
    stddevPopulation?: Maybe<CourseScheduleSpeakerStddevPopulationAggregates>;
    /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
    varianceSample?: Maybe<CourseScheduleSpeakerVarianceSampleAggregates>;
    /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
    variancePopulation?: Maybe<CourseScheduleSpeakerVariancePopulationAggregates>;
};

export type CourseScheduleSpeakerAverageAggregates = {
    __typename?: 'CourseScheduleSpeakerAverageAggregates';
    /** Mean average of speakerId across the matching connection */
    speakerId?: Maybe<Scalars['BigFloat']>;
    /** Mean average of courseScheduleId across the matching connection */
    courseScheduleId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `CourseScheduleSpeaker` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type CourseScheduleSpeakerCondition = {
    /** Checks for equality with the object’s `speakerId` field. */
    speakerId?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `courseScheduleId` field. */
    courseScheduleId?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `createdAt` field. */
    createdAt?: Maybe<Scalars['Datetime']>;
    /** Checks for equality with the object’s `updatedAt` field. */
    updatedAt?: Maybe<Scalars['Datetime']>;
};

export type CourseScheduleSpeakerDistinctCountAggregates = {
    __typename?: 'CourseScheduleSpeakerDistinctCountAggregates';
    /** Distinct count of speakerId across the matching connection */
    speakerId?: Maybe<Scalars['BigInt']>;
    /** Distinct count of courseScheduleId across the matching connection */
    courseScheduleId?: Maybe<Scalars['BigInt']>;
    /** Distinct count of createdAt across the matching connection */
    createdAt?: Maybe<Scalars['BigInt']>;
    /** Distinct count of updatedAt across the matching connection */
    updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `CourseScheduleSpeaker` object types. All fields are combined with a logical ‘and.’ */
export type CourseScheduleSpeakerFilter = {
    /** Filter by the object’s `speakerId` field. */
    speakerId?: Maybe<IntFilter>;
    /** Filter by the object’s `courseScheduleId` field. */
    courseScheduleId?: Maybe<IntFilter>;
    /** Filter by the object’s `createdAt` field. */
    createdAt?: Maybe<DatetimeFilter>;
    /** Filter by the object’s `updatedAt` field. */
    updatedAt?: Maybe<DatetimeFilter>;
    /** Checks for all expressions in this list. */
    and?: Maybe<Array<CourseScheduleSpeakerFilter>>;
    /** Checks for any expressions in this list. */
    or?: Maybe<Array<CourseScheduleSpeakerFilter>>;
    /** Negates the expression. */
    not?: Maybe<CourseScheduleSpeakerFilter>;
};

/** Grouping methods for `CourseScheduleSpeaker` for usage during aggregation. */
export enum CourseScheduleSpeakerGroupBy {
    SpeakerId = 'SPEAKER_ID',
    CourseScheduleId = 'COURSE_SCHEDULE_ID',
    CreatedAt = 'CREATED_AT',
    CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
    CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
    UpdatedAt = 'UPDATED_AT',
    UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
    UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
}

export type CourseScheduleSpeakerHavingAverageInput = {
    speakerId?: Maybe<HavingIntFilter>;
    courseScheduleId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CourseScheduleSpeakerHavingDistinctCountInput = {
    speakerId?: Maybe<HavingIntFilter>;
    courseScheduleId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `CourseScheduleSpeaker` aggregates. */
export type CourseScheduleSpeakerHavingInput = {
    AND?: Maybe<Array<CourseScheduleSpeakerHavingInput>>;
    OR?: Maybe<Array<CourseScheduleSpeakerHavingInput>>;
    sum?: Maybe<CourseScheduleSpeakerHavingSumInput>;
    distinctCount?: Maybe<CourseScheduleSpeakerHavingDistinctCountInput>;
    min?: Maybe<CourseScheduleSpeakerHavingMinInput>;
    max?: Maybe<CourseScheduleSpeakerHavingMaxInput>;
    average?: Maybe<CourseScheduleSpeakerHavingAverageInput>;
    stddevSample?: Maybe<CourseScheduleSpeakerHavingStddevSampleInput>;
    stddevPopulation?: Maybe<CourseScheduleSpeakerHavingStddevPopulationInput>;
    varianceSample?: Maybe<CourseScheduleSpeakerHavingVarianceSampleInput>;
    variancePopulation?: Maybe<CourseScheduleSpeakerHavingVariancePopulationInput>;
};

export type CourseScheduleSpeakerHavingMaxInput = {
    speakerId?: Maybe<HavingIntFilter>;
    courseScheduleId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CourseScheduleSpeakerHavingMinInput = {
    speakerId?: Maybe<HavingIntFilter>;
    courseScheduleId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CourseScheduleSpeakerHavingStddevPopulationInput = {
    speakerId?: Maybe<HavingIntFilter>;
    courseScheduleId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CourseScheduleSpeakerHavingStddevSampleInput = {
    speakerId?: Maybe<HavingIntFilter>;
    courseScheduleId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CourseScheduleSpeakerHavingSumInput = {
    speakerId?: Maybe<HavingIntFilter>;
    courseScheduleId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CourseScheduleSpeakerHavingVariancePopulationInput = {
    speakerId?: Maybe<HavingIntFilter>;
    courseScheduleId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type CourseScheduleSpeakerHavingVarianceSampleInput = {
    speakerId?: Maybe<HavingIntFilter>;
    courseScheduleId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `CourseScheduleSpeaker` */
export type CourseScheduleSpeakerInput = {
    speakerId: Scalars['Int'];
    courseScheduleId: Scalars['Int'];
    createdAt?: Maybe<Scalars['Datetime']>;
    updatedAt?: Maybe<Scalars['Datetime']>;
};

export type CourseScheduleSpeakerMaxAggregates = {
    __typename?: 'CourseScheduleSpeakerMaxAggregates';
    /** Maximum of speakerId across the matching connection */
    speakerId?: Maybe<Scalars['Int']>;
    /** Maximum of courseScheduleId across the matching connection */
    courseScheduleId?: Maybe<Scalars['Int']>;
};

export type CourseScheduleSpeakerMinAggregates = {
    __typename?: 'CourseScheduleSpeakerMinAggregates';
    /** Minimum of speakerId across the matching connection */
    speakerId?: Maybe<Scalars['Int']>;
    /** Minimum of courseScheduleId across the matching connection */
    courseScheduleId?: Maybe<Scalars['Int']>;
};

/** Represents an update to a `CourseScheduleSpeaker`. Fields that are set will be updated. */
export type CourseScheduleSpeakerPatch = {
    speakerId?: Maybe<Scalars['Int']>;
    courseScheduleId?: Maybe<Scalars['Int']>;
    createdAt?: Maybe<Scalars['Datetime']>;
    updatedAt?: Maybe<Scalars['Datetime']>;
};

export type CourseScheduleSpeakerStddevPopulationAggregates = {
    __typename?: 'CourseScheduleSpeakerStddevPopulationAggregates';
    /** Population standard deviation of speakerId across the matching connection */
    speakerId?: Maybe<Scalars['BigFloat']>;
    /** Population standard deviation of courseScheduleId across the matching connection */
    courseScheduleId?: Maybe<Scalars['BigFloat']>;
};

export type CourseScheduleSpeakerStddevSampleAggregates = {
    __typename?: 'CourseScheduleSpeakerStddevSampleAggregates';
    /** Sample standard deviation of speakerId across the matching connection */
    speakerId?: Maybe<Scalars['BigFloat']>;
    /** Sample standard deviation of courseScheduleId across the matching connection */
    courseScheduleId?: Maybe<Scalars['BigFloat']>;
};

export type CourseScheduleSpeakerSumAggregates = {
    __typename?: 'CourseScheduleSpeakerSumAggregates';
    /** Sum of speakerId across the matching connection */
    speakerId: Scalars['BigInt'];
    /** Sum of courseScheduleId across the matching connection */
    courseScheduleId: Scalars['BigInt'];
};

export type CourseScheduleSpeakerVariancePopulationAggregates = {
    __typename?: 'CourseScheduleSpeakerVariancePopulationAggregates';
    /** Population variance of speakerId across the matching connection */
    speakerId?: Maybe<Scalars['BigFloat']>;
    /** Population variance of courseScheduleId across the matching connection */
    courseScheduleId?: Maybe<Scalars['BigFloat']>;
};

export type CourseScheduleSpeakerVarianceSampleAggregates = {
    __typename?: 'CourseScheduleSpeakerVarianceSampleAggregates';
    /** Sample variance of speakerId across the matching connection */
    speakerId?: Maybe<Scalars['BigFloat']>;
    /** Sample variance of courseScheduleId across the matching connection */
    courseScheduleId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `CourseScheduleSpeaker` values. */
export type CourseScheduleSpeakersConnection = {
    __typename?: 'CourseScheduleSpeakersConnection';
    /** A list of `CourseScheduleSpeaker` objects. */
    nodes: Array<CourseScheduleSpeaker>;
    /** A list of edges which contains the `CourseScheduleSpeaker` and cursor to aid in pagination. */
    edges: Array<CourseScheduleSpeakersEdge>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `CourseScheduleSpeaker` you could get from the connection. */
    totalCount: Scalars['Int'];
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<CourseScheduleSpeakerAggregates>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<CourseScheduleSpeakerAggregates>>;
};

/** A connection to a list of `CourseScheduleSpeaker` values. */
export type CourseScheduleSpeakersConnectionGroupedAggregatesArgs = {
    groupBy: Array<CourseScheduleSpeakerGroupBy>;
    having?: Maybe<CourseScheduleSpeakerHavingInput>;
};

/** A `CourseScheduleSpeaker` edge in the connection. */
export type CourseScheduleSpeakersEdge = {
    __typename?: 'CourseScheduleSpeakersEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `CourseScheduleSpeaker` at the end of the edge. */
    node: CourseScheduleSpeaker;
};

/** Methods to use when ordering `CourseScheduleSpeaker`. */
export enum CourseScheduleSpeakersOrderBy {
    Natural = 'NATURAL',
    SpeakerIdAsc = 'SPEAKER_ID_ASC',
    SpeakerIdDesc = 'SPEAKER_ID_DESC',
    CourseScheduleIdAsc = 'COURSE_SCHEDULE_ID_ASC',
    CourseScheduleIdDesc = 'COURSE_SCHEDULE_ID_DESC',
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
    UpdatedAtAsc = 'UPDATED_AT_ASC',
    UpdatedAtDesc = 'UPDATED_AT_DESC',
    PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
    PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

export enum CourseScheduleStatus {
    Active = 'ACTIVE',
    Planned = 'PLANNED',
    Cancelled = 'CANCELLED',
    Done = 'DONE',
}

/** A filter to be used against CourseScheduleStatus fields. All fields are combined with a logical ‘and.’ */
export type CourseScheduleStatusFilter = {
    /** Is null (if `true` is specified) or is not null (if `false` is specified). */
    isNull?: Maybe<Scalars['Boolean']>;
    /** Equal to the specified value. */
    equalTo?: Maybe<CourseScheduleStatus>;
    /** Not equal to the specified value. */
    notEqualTo?: Maybe<CourseScheduleStatus>;
    /** Not equal to the specified value, treating null like an ordinary value. */
    distinctFrom?: Maybe<CourseScheduleStatus>;
    /** Equal to the specified value, treating null like an ordinary value. */
    notDistinctFrom?: Maybe<CourseScheduleStatus>;
    /** Included in the specified list. */
    in?: Maybe<Array<CourseScheduleStatus>>;
    /** Not included in the specified list. */
    notIn?: Maybe<Array<CourseScheduleStatus>>;
    /** Less than the specified value. */
    lessThan?: Maybe<CourseScheduleStatus>;
    /** Less than or equal to the specified value. */
    lessThanOrEqualTo?: Maybe<CourseScheduleStatus>;
    /** Greater than the specified value. */
    greaterThan?: Maybe<CourseScheduleStatus>;
    /** Greater than or equal to the specified value. */
    greaterThanOrEqualTo?: Maybe<CourseScheduleStatus>;
};

export type CourseScheduleStddevPopulationAggregates = {
    __typename?: 'CourseScheduleStddevPopulationAggregates';
    /** Population standard deviation of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Population standard deviation of courseId across the matching connection */
    courseId?: Maybe<Scalars['BigFloat']>;
    /** Population standard deviation of seats across the matching connection */
    seats?: Maybe<Scalars['BigFloat']>;
    /** Population standard deviation of duration across the matching connection */
    duration?: Maybe<Scalars['BigFloat']>;
    /** Population standard deviation of units across the matching connection */
    units?: Maybe<Scalars['BigFloat']>;
    /** Population standard deviation of price across the matching connection */
    price?: Maybe<Scalars['Float']>;
    /** Population standard deviation of locationId across the matching connection */
    locationId?: Maybe<Scalars['BigFloat']>;
    /** Population standard deviation of this field across the matching connection. */
    emptyLines?: Maybe<Scalars['BigFloat']>;
    /** Population standard deviation of this field across the matching connection. */
    freeSeats?: Maybe<Scalars['BigFloat']>;
};

export type CourseScheduleStddevSampleAggregates = {
    __typename?: 'CourseScheduleStddevSampleAggregates';
    /** Sample standard deviation of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Sample standard deviation of courseId across the matching connection */
    courseId?: Maybe<Scalars['BigFloat']>;
    /** Sample standard deviation of seats across the matching connection */
    seats?: Maybe<Scalars['BigFloat']>;
    /** Sample standard deviation of duration across the matching connection */
    duration?: Maybe<Scalars['BigFloat']>;
    /** Sample standard deviation of units across the matching connection */
    units?: Maybe<Scalars['BigFloat']>;
    /** Sample standard deviation of price across the matching connection */
    price?: Maybe<Scalars['Float']>;
    /** Sample standard deviation of locationId across the matching connection */
    locationId?: Maybe<Scalars['BigFloat']>;
    /** Sample standard deviation of this field across the matching connection. */
    emptyLines?: Maybe<Scalars['BigFloat']>;
    /** Sample standard deviation of this field across the matching connection. */
    freeSeats?: Maybe<Scalars['BigFloat']>;
};

export type CourseScheduleSumAggregates = {
    __typename?: 'CourseScheduleSumAggregates';
    /** Sum of id across the matching connection */
    id: Scalars['BigInt'];
    /** Sum of courseId across the matching connection */
    courseId: Scalars['BigInt'];
    /** Sum of seats across the matching connection */
    seats: Scalars['BigInt'];
    /** Sum of duration across the matching connection */
    duration: Scalars['BigInt'];
    /** Sum of units across the matching connection */
    units: Scalars['BigInt'];
    /** Sum of price across the matching connection */
    price: Scalars['Float'];
    /** Sum of locationId across the matching connection */
    locationId: Scalars['BigInt'];
    /** Sum of this field across the matching connection. */
    emptyLines?: Maybe<Scalars['BigInt']>;
    /** Sum of this field across the matching connection. */
    freeSeats?: Maybe<Scalars['BigFloat']>;
};

export type CourseScheduleVariancePopulationAggregates = {
    __typename?: 'CourseScheduleVariancePopulationAggregates';
    /** Population variance of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Population variance of courseId across the matching connection */
    courseId?: Maybe<Scalars['BigFloat']>;
    /** Population variance of seats across the matching connection */
    seats?: Maybe<Scalars['BigFloat']>;
    /** Population variance of duration across the matching connection */
    duration?: Maybe<Scalars['BigFloat']>;
    /** Population variance of units across the matching connection */
    units?: Maybe<Scalars['BigFloat']>;
    /** Population variance of price across the matching connection */
    price?: Maybe<Scalars['Float']>;
    /** Population variance of locationId across the matching connection */
    locationId?: Maybe<Scalars['BigFloat']>;
    /** Population variance of this field across the matching connection. */
    emptyLines?: Maybe<Scalars['BigFloat']>;
    /** Population variance of this field across the matching connection. */
    freeSeats?: Maybe<Scalars['BigFloat']>;
};

export type CourseScheduleVarianceSampleAggregates = {
    __typename?: 'CourseScheduleVarianceSampleAggregates';
    /** Sample variance of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Sample variance of courseId across the matching connection */
    courseId?: Maybe<Scalars['BigFloat']>;
    /** Sample variance of seats across the matching connection */
    seats?: Maybe<Scalars['BigFloat']>;
    /** Sample variance of duration across the matching connection */
    duration?: Maybe<Scalars['BigFloat']>;
    /** Sample variance of units across the matching connection */
    units?: Maybe<Scalars['BigFloat']>;
    /** Sample variance of price across the matching connection */
    price?: Maybe<Scalars['Float']>;
    /** Sample variance of locationId across the matching connection */
    locationId?: Maybe<Scalars['BigFloat']>;
    /** Sample variance of this field across the matching connection. */
    emptyLines?: Maybe<Scalars['BigFloat']>;
    /** Sample variance of this field across the matching connection. */
    freeSeats?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `CourseSchedule` values. */
export type CourseSchedulesConnection = {
    __typename?: 'CourseSchedulesConnection';
    /** A list of `CourseSchedule` objects. */
    nodes: Array<CourseSchedule>;
    /** A list of edges which contains the `CourseSchedule` and cursor to aid in pagination. */
    edges: Array<CourseSchedulesEdge>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `CourseSchedule` you could get from the connection. */
    totalCount: Scalars['Int'];
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<CourseScheduleAggregates>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<CourseScheduleAggregates>>;
};

/** A connection to a list of `CourseSchedule` values. */
export type CourseSchedulesConnectionGroupedAggregatesArgs = {
    groupBy: Array<CourseScheduleGroupBy>;
    having?: Maybe<CourseScheduleHavingInput>;
};

/** A `CourseSchedule` edge in the connection. */
export type CourseSchedulesEdge = {
    __typename?: 'CourseSchedulesEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `CourseSchedule` at the end of the edge. */
    node: CourseSchedule;
};

/** Methods to use when ordering `CourseSchedule`. */
export enum CourseSchedulesOrderBy {
    Natural = 'NATURAL',
    IdAsc = 'ID_ASC',
    IdDesc = 'ID_DESC',
    CourseIdAsc = 'COURSE_ID_ASC',
    CourseIdDesc = 'COURSE_ID_DESC',
    StartDateAsc = 'START_DATE_ASC',
    StartDateDesc = 'START_DATE_DESC',
    EndDateAsc = 'END_DATE_ASC',
    EndDateDesc = 'END_DATE_DESC',
    SeatsAsc = 'SEATS_ASC',
    SeatsDesc = 'SEATS_DESC',
    DurationAsc = 'DURATION_ASC',
    DurationDesc = 'DURATION_DESC',
    UnitsAsc = 'UNITS_ASC',
    UnitsDesc = 'UNITS_DESC',
    PriceAsc = 'PRICE_ASC',
    PriceDesc = 'PRICE_DESC',
    TimetableAsc = 'TIMETABLE_ASC',
    TimetableDesc = 'TIMETABLE_DESC',
    CancelledAsc = 'CANCELLED_ASC',
    CancelledDesc = 'CANCELLED_DESC',
    CommentAsc = 'COMMENT_ASC',
    CommentDesc = 'COMMENT_DESC',
    LocationIdAsc = 'LOCATION_ID_ASC',
    LocationIdDesc = 'LOCATION_ID_DESC',
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
    UpdatedAtAsc = 'UPDATED_AT_ASC',
    UpdatedAtDesc = 'UPDATED_AT_DESC',
    ReminderSentAtAsc = 'REMINDER_SENT_AT_ASC',
    ReminderSentAtDesc = 'REMINDER_SENT_AT_DESC',
    DateScheduleSortableAsc = 'DATE_SCHEDULE_SORTABLE_ASC',
    DateScheduleSortableDesc = 'DATE_SCHEDULE_SORTABLE_DESC',
    StatusAsc = 'STATUS_ASC',
    StatusDesc = 'STATUS_DESC',
    PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
    PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
    BookingsCountAsc = 'BOOKINGS_COUNT_ASC',
    BookingsCountDesc = 'BOOKINGS_COUNT_DESC',
    BookingsSumIdAsc = 'BOOKINGS_SUM_ID_ASC',
    BookingsSumIdDesc = 'BOOKINGS_SUM_ID_DESC',
    BookingsSumCourseScheduleIdAsc = 'BOOKINGS_SUM_COURSE_SCHEDULE_ID_ASC',
    BookingsSumCourseScheduleIdDesc = 'BOOKINGS_SUM_COURSE_SCHEDULE_ID_DESC',
    BookingsSumParticipantIdAsc = 'BOOKINGS_SUM_PARTICIPANT_ID_ASC',
    BookingsSumParticipantIdDesc = 'BOOKINGS_SUM_PARTICIPANT_ID_DESC',
    BookingsSumRegistrationRegisteredAsc = 'BOOKINGS_SUM_REGISTRATION_REGISTERED_ASC',
    BookingsSumRegistrationRegisteredDesc = 'BOOKINGS_SUM_REGISTRATION_REGISTERED_DESC',
    BookingsSumRegistrationDateAsc = 'BOOKINGS_SUM_REGISTRATION_DATE_ASC',
    BookingsSumRegistrationDateDesc = 'BOOKINGS_SUM_REGISTRATION_DATE_DESC',
    BookingsSumRegistrationNoteAsc = 'BOOKINGS_SUM_REGISTRATION_NOTE_ASC',
    BookingsSumRegistrationNoteDesc = 'BOOKINGS_SUM_REGISTRATION_NOTE_DESC',
    BookingsSumPassedAsc = 'BOOKINGS_SUM_PASSED_ASC',
    BookingsSumPassedDesc = 'BOOKINGS_SUM_PASSED_DESC',
    BookingsSumMissingHoursAsc = 'BOOKINGS_SUM_MISSING_HOURS_ASC',
    BookingsSumMissingHoursDesc = 'BOOKINGS_SUM_MISSING_HOURS_DESC',
    BookingsSumPaymentReceivedAsc = 'BOOKINGS_SUM_PAYMENT_RECEIVED_ASC',
    BookingsSumPaymentReceivedDesc = 'BOOKINGS_SUM_PAYMENT_RECEIVED_DESC',
    BookingsSumPaymentDateAsc = 'BOOKINGS_SUM_PAYMENT_DATE_ASC',
    BookingsSumPaymentDateDesc = 'BOOKINGS_SUM_PAYMENT_DATE_DESC',
    BookingsSumPaymentMethodAsc = 'BOOKINGS_SUM_PAYMENT_METHOD_ASC',
    BookingsSumPaymentMethodDesc = 'BOOKINGS_SUM_PAYMENT_METHOD_DESC',
    BookingsSumInvoiceNumberAsc = 'BOOKINGS_SUM_INVOICE_NUMBER_ASC',
    BookingsSumInvoiceNumberDesc = 'BOOKINGS_SUM_INVOICE_NUMBER_DESC',
    BookingsSumWaitingPositionAsc = 'BOOKINGS_SUM_WAITING_POSITION_ASC',
    BookingsSumWaitingPositionDesc = 'BOOKINGS_SUM_WAITING_POSITION_DESC',
    BookingsSumDocumentsSendAsc = 'BOOKINGS_SUM_DOCUMENTS_SEND_ASC',
    BookingsSumDocumentsSendDesc = 'BOOKINGS_SUM_DOCUMENTS_SEND_DESC',
    BookingsSumNoteAsc = 'BOOKINGS_SUM_NOTE_ASC',
    BookingsSumNoteDesc = 'BOOKINGS_SUM_NOTE_DESC',
    BookingsSumAmountAsc = 'BOOKINGS_SUM_AMOUNT_ASC',
    BookingsSumAmountDesc = 'BOOKINGS_SUM_AMOUNT_DESC',
    BookingsSumCreatedAtAsc = 'BOOKINGS_SUM_CREATED_AT_ASC',
    BookingsSumCreatedAtDesc = 'BOOKINGS_SUM_CREATED_AT_DESC',
    BookingsSumUpdatedAtAsc = 'BOOKINGS_SUM_UPDATED_AT_ASC',
    BookingsSumUpdatedAtDesc = 'BOOKINGS_SUM_UPDATED_AT_DESC',
    BookingsSumRegistrationConfirmationAsc = 'BOOKINGS_SUM_REGISTRATION_CONFIRMATION_ASC',
    BookingsSumRegistrationConfirmationDesc = 'BOOKINGS_SUM_REGISTRATION_CONFIRMATION_DESC',
    BookingsSumInvoiceCompanyNameAsc = 'BOOKINGS_SUM_INVOICE_COMPANY_NAME_ASC',
    BookingsSumInvoiceCompanyNameDesc = 'BOOKINGS_SUM_INVOICE_COMPANY_NAME_DESC',
    BookingsSumInvoiceFamilyNameAsc = 'BOOKINGS_SUM_INVOICE_FAMILY_NAME_ASC',
    BookingsSumInvoiceFamilyNameDesc = 'BOOKINGS_SUM_INVOICE_FAMILY_NAME_DESC',
    BookingsSumInvoiceSalutationAsc = 'BOOKINGS_SUM_INVOICE_SALUTATION_ASC',
    BookingsSumInvoiceSalutationDesc = 'BOOKINGS_SUM_INVOICE_SALUTATION_DESC',
    BookingsSumInvoiceTitleAsc = 'BOOKINGS_SUM_INVOICE_TITLE_ASC',
    BookingsSumInvoiceTitleDesc = 'BOOKINGS_SUM_INVOICE_TITLE_DESC',
    BookingsSumInvoiceGivenNameAsc = 'BOOKINGS_SUM_INVOICE_GIVEN_NAME_ASC',
    BookingsSumInvoiceGivenNameDesc = 'BOOKINGS_SUM_INVOICE_GIVEN_NAME_DESC',
    BookingsSumInvoiceStreetAsc = 'BOOKINGS_SUM_INVOICE_STREET_ASC',
    BookingsSumInvoiceStreetDesc = 'BOOKINGS_SUM_INVOICE_STREET_DESC',
    BookingsSumInvoicePostcodeAsc = 'BOOKINGS_SUM_INVOICE_POSTCODE_ASC',
    BookingsSumInvoicePostcodeDesc = 'BOOKINGS_SUM_INVOICE_POSTCODE_DESC',
    BookingsSumInvoiceCityAsc = 'BOOKINGS_SUM_INVOICE_CITY_ASC',
    BookingsSumInvoiceCityDesc = 'BOOKINGS_SUM_INVOICE_CITY_DESC',
    BookingsSumInvoiceCountryAsc = 'BOOKINGS_SUM_INVOICE_COUNTRY_ASC',
    BookingsSumInvoiceCountryDesc = 'BOOKINGS_SUM_INVOICE_COUNTRY_DESC',
    BookingsSumInvoiceBankOwnerAsc = 'BOOKINGS_SUM_INVOICE_BANK_OWNER_ASC',
    BookingsSumInvoiceBankOwnerDesc = 'BOOKINGS_SUM_INVOICE_BANK_OWNER_DESC',
    BookingsSumInvoiceBankNameAsc = 'BOOKINGS_SUM_INVOICE_BANK_NAME_ASC',
    BookingsSumInvoiceBankNameDesc = 'BOOKINGS_SUM_INVOICE_BANK_NAME_DESC',
    BookingsSumInvoiceBankSwiftAsc = 'BOOKINGS_SUM_INVOICE_BANK_SWIFT_ASC',
    BookingsSumInvoiceBankSwiftDesc = 'BOOKINGS_SUM_INVOICE_BANK_SWIFT_DESC',
    BookingsSumInvoiceBankIbanAsc = 'BOOKINGS_SUM_INVOICE_BANK_IBAN_ASC',
    BookingsSumInvoiceBankIbanDesc = 'BOOKINGS_SUM_INVOICE_BANK_IBAN_DESC',
    BookingsSumInvoiceToEmployerAsc = 'BOOKINGS_SUM_INVOICE_TO_EMPLOYER_ASC',
    BookingsSumInvoiceToEmployerDesc = 'BOOKINGS_SUM_INVOICE_TO_EMPLOYER_DESC',
    BookingsSumStateAsc = 'BOOKINGS_SUM_STATE_ASC',
    BookingsSumStateDesc = 'BOOKINGS_SUM_STATE_DESC',
    BookingsSumInvoiceContactPhoneAsc = 'BOOKINGS_SUM_INVOICE_CONTACT_PHONE_ASC',
    BookingsSumInvoiceContactPhoneDesc = 'BOOKINGS_SUM_INVOICE_CONTACT_PHONE_DESC',
    BookingsSumInvoiceContactEmailAsc = 'BOOKINGS_SUM_INVOICE_CONTACT_EMAIL_ASC',
    BookingsSumInvoiceContactEmailDesc = 'BOOKINGS_SUM_INVOICE_CONTACT_EMAIL_DESC',
    BookingsSumInvoiceContactUrlAsc = 'BOOKINGS_SUM_INVOICE_CONTACT_URL_ASC',
    BookingsSumInvoiceContactUrlDesc = 'BOOKINGS_SUM_INVOICE_CONTACT_URL_DESC',
    BookingsSumAcceptAsc = 'BOOKINGS_SUM_ACCEPT_ASC',
    BookingsSumAcceptDesc = 'BOOKINGS_SUM_ACCEPT_DESC',
    BookingsSumAcceptPaymentAsc = 'BOOKINGS_SUM_ACCEPT_PAYMENT_ASC',
    BookingsSumAcceptPaymentDesc = 'BOOKINGS_SUM_ACCEPT_PAYMENT_DESC',
    BookingsSumInvoiceDateAsc = 'BOOKINGS_SUM_INVOICE_DATE_ASC',
    BookingsSumInvoiceDateDesc = 'BOOKINGS_SUM_INVOICE_DATE_DESC',
    BookingsSumCodeAsc = 'BOOKINGS_SUM_CODE_ASC',
    BookingsSumCodeDesc = 'BOOKINGS_SUM_CODE_DESC',
    BookingsSumUseForCertificationAsc = 'BOOKINGS_SUM_USE_FOR_CERTIFICATION_ASC',
    BookingsSumUseForCertificationDesc = 'BOOKINGS_SUM_USE_FOR_CERTIFICATION_DESC',
    BookingsDistinctCountIdAsc = 'BOOKINGS_DISTINCT_COUNT_ID_ASC',
    BookingsDistinctCountIdDesc = 'BOOKINGS_DISTINCT_COUNT_ID_DESC',
    BookingsDistinctCountCourseScheduleIdAsc = 'BOOKINGS_DISTINCT_COUNT_COURSE_SCHEDULE_ID_ASC',
    BookingsDistinctCountCourseScheduleIdDesc = 'BOOKINGS_DISTINCT_COUNT_COURSE_SCHEDULE_ID_DESC',
    BookingsDistinctCountParticipantIdAsc = 'BOOKINGS_DISTINCT_COUNT_PARTICIPANT_ID_ASC',
    BookingsDistinctCountParticipantIdDesc = 'BOOKINGS_DISTINCT_COUNT_PARTICIPANT_ID_DESC',
    BookingsDistinctCountRegistrationRegisteredAsc = 'BOOKINGS_DISTINCT_COUNT_REGISTRATION_REGISTERED_ASC',
    BookingsDistinctCountRegistrationRegisteredDesc = 'BOOKINGS_DISTINCT_COUNT_REGISTRATION_REGISTERED_DESC',
    BookingsDistinctCountRegistrationDateAsc = 'BOOKINGS_DISTINCT_COUNT_REGISTRATION_DATE_ASC',
    BookingsDistinctCountRegistrationDateDesc = 'BOOKINGS_DISTINCT_COUNT_REGISTRATION_DATE_DESC',
    BookingsDistinctCountRegistrationNoteAsc = 'BOOKINGS_DISTINCT_COUNT_REGISTRATION_NOTE_ASC',
    BookingsDistinctCountRegistrationNoteDesc = 'BOOKINGS_DISTINCT_COUNT_REGISTRATION_NOTE_DESC',
    BookingsDistinctCountPassedAsc = 'BOOKINGS_DISTINCT_COUNT_PASSED_ASC',
    BookingsDistinctCountPassedDesc = 'BOOKINGS_DISTINCT_COUNT_PASSED_DESC',
    BookingsDistinctCountMissingHoursAsc = 'BOOKINGS_DISTINCT_COUNT_MISSING_HOURS_ASC',
    BookingsDistinctCountMissingHoursDesc = 'BOOKINGS_DISTINCT_COUNT_MISSING_HOURS_DESC',
    BookingsDistinctCountPaymentReceivedAsc = 'BOOKINGS_DISTINCT_COUNT_PAYMENT_RECEIVED_ASC',
    BookingsDistinctCountPaymentReceivedDesc = 'BOOKINGS_DISTINCT_COUNT_PAYMENT_RECEIVED_DESC',
    BookingsDistinctCountPaymentDateAsc = 'BOOKINGS_DISTINCT_COUNT_PAYMENT_DATE_ASC',
    BookingsDistinctCountPaymentDateDesc = 'BOOKINGS_DISTINCT_COUNT_PAYMENT_DATE_DESC',
    BookingsDistinctCountPaymentMethodAsc = 'BOOKINGS_DISTINCT_COUNT_PAYMENT_METHOD_ASC',
    BookingsDistinctCountPaymentMethodDesc = 'BOOKINGS_DISTINCT_COUNT_PAYMENT_METHOD_DESC',
    BookingsDistinctCountInvoiceNumberAsc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_NUMBER_ASC',
    BookingsDistinctCountInvoiceNumberDesc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_NUMBER_DESC',
    BookingsDistinctCountWaitingPositionAsc = 'BOOKINGS_DISTINCT_COUNT_WAITING_POSITION_ASC',
    BookingsDistinctCountWaitingPositionDesc = 'BOOKINGS_DISTINCT_COUNT_WAITING_POSITION_DESC',
    BookingsDistinctCountDocumentsSendAsc = 'BOOKINGS_DISTINCT_COUNT_DOCUMENTS_SEND_ASC',
    BookingsDistinctCountDocumentsSendDesc = 'BOOKINGS_DISTINCT_COUNT_DOCUMENTS_SEND_DESC',
    BookingsDistinctCountNoteAsc = 'BOOKINGS_DISTINCT_COUNT_NOTE_ASC',
    BookingsDistinctCountNoteDesc = 'BOOKINGS_DISTINCT_COUNT_NOTE_DESC',
    BookingsDistinctCountAmountAsc = 'BOOKINGS_DISTINCT_COUNT_AMOUNT_ASC',
    BookingsDistinctCountAmountDesc = 'BOOKINGS_DISTINCT_COUNT_AMOUNT_DESC',
    BookingsDistinctCountCreatedAtAsc = 'BOOKINGS_DISTINCT_COUNT_CREATED_AT_ASC',
    BookingsDistinctCountCreatedAtDesc = 'BOOKINGS_DISTINCT_COUNT_CREATED_AT_DESC',
    BookingsDistinctCountUpdatedAtAsc = 'BOOKINGS_DISTINCT_COUNT_UPDATED_AT_ASC',
    BookingsDistinctCountUpdatedAtDesc = 'BOOKINGS_DISTINCT_COUNT_UPDATED_AT_DESC',
    BookingsDistinctCountRegistrationConfirmationAsc = 'BOOKINGS_DISTINCT_COUNT_REGISTRATION_CONFIRMATION_ASC',
    BookingsDistinctCountRegistrationConfirmationDesc = 'BOOKINGS_DISTINCT_COUNT_REGISTRATION_CONFIRMATION_DESC',
    BookingsDistinctCountInvoiceCompanyNameAsc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_COMPANY_NAME_ASC',
    BookingsDistinctCountInvoiceCompanyNameDesc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_COMPANY_NAME_DESC',
    BookingsDistinctCountInvoiceFamilyNameAsc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_FAMILY_NAME_ASC',
    BookingsDistinctCountInvoiceFamilyNameDesc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_FAMILY_NAME_DESC',
    BookingsDistinctCountInvoiceSalutationAsc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_SALUTATION_ASC',
    BookingsDistinctCountInvoiceSalutationDesc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_SALUTATION_DESC',
    BookingsDistinctCountInvoiceTitleAsc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_TITLE_ASC',
    BookingsDistinctCountInvoiceTitleDesc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_TITLE_DESC',
    BookingsDistinctCountInvoiceGivenNameAsc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_GIVEN_NAME_ASC',
    BookingsDistinctCountInvoiceGivenNameDesc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_GIVEN_NAME_DESC',
    BookingsDistinctCountInvoiceStreetAsc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_STREET_ASC',
    BookingsDistinctCountInvoiceStreetDesc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_STREET_DESC',
    BookingsDistinctCountInvoicePostcodeAsc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_POSTCODE_ASC',
    BookingsDistinctCountInvoicePostcodeDesc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_POSTCODE_DESC',
    BookingsDistinctCountInvoiceCityAsc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_CITY_ASC',
    BookingsDistinctCountInvoiceCityDesc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_CITY_DESC',
    BookingsDistinctCountInvoiceCountryAsc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_COUNTRY_ASC',
    BookingsDistinctCountInvoiceCountryDesc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_COUNTRY_DESC',
    BookingsDistinctCountInvoiceBankOwnerAsc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_BANK_OWNER_ASC',
    BookingsDistinctCountInvoiceBankOwnerDesc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_BANK_OWNER_DESC',
    BookingsDistinctCountInvoiceBankNameAsc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_BANK_NAME_ASC',
    BookingsDistinctCountInvoiceBankNameDesc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_BANK_NAME_DESC',
    BookingsDistinctCountInvoiceBankSwiftAsc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_BANK_SWIFT_ASC',
    BookingsDistinctCountInvoiceBankSwiftDesc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_BANK_SWIFT_DESC',
    BookingsDistinctCountInvoiceBankIbanAsc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_BANK_IBAN_ASC',
    BookingsDistinctCountInvoiceBankIbanDesc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_BANK_IBAN_DESC',
    BookingsDistinctCountInvoiceToEmployerAsc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_TO_EMPLOYER_ASC',
    BookingsDistinctCountInvoiceToEmployerDesc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_TO_EMPLOYER_DESC',
    BookingsDistinctCountStateAsc = 'BOOKINGS_DISTINCT_COUNT_STATE_ASC',
    BookingsDistinctCountStateDesc = 'BOOKINGS_DISTINCT_COUNT_STATE_DESC',
    BookingsDistinctCountInvoiceContactPhoneAsc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_CONTACT_PHONE_ASC',
    BookingsDistinctCountInvoiceContactPhoneDesc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_CONTACT_PHONE_DESC',
    BookingsDistinctCountInvoiceContactEmailAsc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_CONTACT_EMAIL_ASC',
    BookingsDistinctCountInvoiceContactEmailDesc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_CONTACT_EMAIL_DESC',
    BookingsDistinctCountInvoiceContactUrlAsc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_CONTACT_URL_ASC',
    BookingsDistinctCountInvoiceContactUrlDesc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_CONTACT_URL_DESC',
    BookingsDistinctCountAcceptAsc = 'BOOKINGS_DISTINCT_COUNT_ACCEPT_ASC',
    BookingsDistinctCountAcceptDesc = 'BOOKINGS_DISTINCT_COUNT_ACCEPT_DESC',
    BookingsDistinctCountAcceptPaymentAsc = 'BOOKINGS_DISTINCT_COUNT_ACCEPT_PAYMENT_ASC',
    BookingsDistinctCountAcceptPaymentDesc = 'BOOKINGS_DISTINCT_COUNT_ACCEPT_PAYMENT_DESC',
    BookingsDistinctCountInvoiceDateAsc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_DATE_ASC',
    BookingsDistinctCountInvoiceDateDesc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_DATE_DESC',
    BookingsDistinctCountCodeAsc = 'BOOKINGS_DISTINCT_COUNT_CODE_ASC',
    BookingsDistinctCountCodeDesc = 'BOOKINGS_DISTINCT_COUNT_CODE_DESC',
    BookingsDistinctCountUseForCertificationAsc = 'BOOKINGS_DISTINCT_COUNT_USE_FOR_CERTIFICATION_ASC',
    BookingsDistinctCountUseForCertificationDesc = 'BOOKINGS_DISTINCT_COUNT_USE_FOR_CERTIFICATION_DESC',
    BookingsMinIdAsc = 'BOOKINGS_MIN_ID_ASC',
    BookingsMinIdDesc = 'BOOKINGS_MIN_ID_DESC',
    BookingsMinCourseScheduleIdAsc = 'BOOKINGS_MIN_COURSE_SCHEDULE_ID_ASC',
    BookingsMinCourseScheduleIdDesc = 'BOOKINGS_MIN_COURSE_SCHEDULE_ID_DESC',
    BookingsMinParticipantIdAsc = 'BOOKINGS_MIN_PARTICIPANT_ID_ASC',
    BookingsMinParticipantIdDesc = 'BOOKINGS_MIN_PARTICIPANT_ID_DESC',
    BookingsMinRegistrationRegisteredAsc = 'BOOKINGS_MIN_REGISTRATION_REGISTERED_ASC',
    BookingsMinRegistrationRegisteredDesc = 'BOOKINGS_MIN_REGISTRATION_REGISTERED_DESC',
    BookingsMinRegistrationDateAsc = 'BOOKINGS_MIN_REGISTRATION_DATE_ASC',
    BookingsMinRegistrationDateDesc = 'BOOKINGS_MIN_REGISTRATION_DATE_DESC',
    BookingsMinRegistrationNoteAsc = 'BOOKINGS_MIN_REGISTRATION_NOTE_ASC',
    BookingsMinRegistrationNoteDesc = 'BOOKINGS_MIN_REGISTRATION_NOTE_DESC',
    BookingsMinPassedAsc = 'BOOKINGS_MIN_PASSED_ASC',
    BookingsMinPassedDesc = 'BOOKINGS_MIN_PASSED_DESC',
    BookingsMinMissingHoursAsc = 'BOOKINGS_MIN_MISSING_HOURS_ASC',
    BookingsMinMissingHoursDesc = 'BOOKINGS_MIN_MISSING_HOURS_DESC',
    BookingsMinPaymentReceivedAsc = 'BOOKINGS_MIN_PAYMENT_RECEIVED_ASC',
    BookingsMinPaymentReceivedDesc = 'BOOKINGS_MIN_PAYMENT_RECEIVED_DESC',
    BookingsMinPaymentDateAsc = 'BOOKINGS_MIN_PAYMENT_DATE_ASC',
    BookingsMinPaymentDateDesc = 'BOOKINGS_MIN_PAYMENT_DATE_DESC',
    BookingsMinPaymentMethodAsc = 'BOOKINGS_MIN_PAYMENT_METHOD_ASC',
    BookingsMinPaymentMethodDesc = 'BOOKINGS_MIN_PAYMENT_METHOD_DESC',
    BookingsMinInvoiceNumberAsc = 'BOOKINGS_MIN_INVOICE_NUMBER_ASC',
    BookingsMinInvoiceNumberDesc = 'BOOKINGS_MIN_INVOICE_NUMBER_DESC',
    BookingsMinWaitingPositionAsc = 'BOOKINGS_MIN_WAITING_POSITION_ASC',
    BookingsMinWaitingPositionDesc = 'BOOKINGS_MIN_WAITING_POSITION_DESC',
    BookingsMinDocumentsSendAsc = 'BOOKINGS_MIN_DOCUMENTS_SEND_ASC',
    BookingsMinDocumentsSendDesc = 'BOOKINGS_MIN_DOCUMENTS_SEND_DESC',
    BookingsMinNoteAsc = 'BOOKINGS_MIN_NOTE_ASC',
    BookingsMinNoteDesc = 'BOOKINGS_MIN_NOTE_DESC',
    BookingsMinAmountAsc = 'BOOKINGS_MIN_AMOUNT_ASC',
    BookingsMinAmountDesc = 'BOOKINGS_MIN_AMOUNT_DESC',
    BookingsMinCreatedAtAsc = 'BOOKINGS_MIN_CREATED_AT_ASC',
    BookingsMinCreatedAtDesc = 'BOOKINGS_MIN_CREATED_AT_DESC',
    BookingsMinUpdatedAtAsc = 'BOOKINGS_MIN_UPDATED_AT_ASC',
    BookingsMinUpdatedAtDesc = 'BOOKINGS_MIN_UPDATED_AT_DESC',
    BookingsMinRegistrationConfirmationAsc = 'BOOKINGS_MIN_REGISTRATION_CONFIRMATION_ASC',
    BookingsMinRegistrationConfirmationDesc = 'BOOKINGS_MIN_REGISTRATION_CONFIRMATION_DESC',
    BookingsMinInvoiceCompanyNameAsc = 'BOOKINGS_MIN_INVOICE_COMPANY_NAME_ASC',
    BookingsMinInvoiceCompanyNameDesc = 'BOOKINGS_MIN_INVOICE_COMPANY_NAME_DESC',
    BookingsMinInvoiceFamilyNameAsc = 'BOOKINGS_MIN_INVOICE_FAMILY_NAME_ASC',
    BookingsMinInvoiceFamilyNameDesc = 'BOOKINGS_MIN_INVOICE_FAMILY_NAME_DESC',
    BookingsMinInvoiceSalutationAsc = 'BOOKINGS_MIN_INVOICE_SALUTATION_ASC',
    BookingsMinInvoiceSalutationDesc = 'BOOKINGS_MIN_INVOICE_SALUTATION_DESC',
    BookingsMinInvoiceTitleAsc = 'BOOKINGS_MIN_INVOICE_TITLE_ASC',
    BookingsMinInvoiceTitleDesc = 'BOOKINGS_MIN_INVOICE_TITLE_DESC',
    BookingsMinInvoiceGivenNameAsc = 'BOOKINGS_MIN_INVOICE_GIVEN_NAME_ASC',
    BookingsMinInvoiceGivenNameDesc = 'BOOKINGS_MIN_INVOICE_GIVEN_NAME_DESC',
    BookingsMinInvoiceStreetAsc = 'BOOKINGS_MIN_INVOICE_STREET_ASC',
    BookingsMinInvoiceStreetDesc = 'BOOKINGS_MIN_INVOICE_STREET_DESC',
    BookingsMinInvoicePostcodeAsc = 'BOOKINGS_MIN_INVOICE_POSTCODE_ASC',
    BookingsMinInvoicePostcodeDesc = 'BOOKINGS_MIN_INVOICE_POSTCODE_DESC',
    BookingsMinInvoiceCityAsc = 'BOOKINGS_MIN_INVOICE_CITY_ASC',
    BookingsMinInvoiceCityDesc = 'BOOKINGS_MIN_INVOICE_CITY_DESC',
    BookingsMinInvoiceCountryAsc = 'BOOKINGS_MIN_INVOICE_COUNTRY_ASC',
    BookingsMinInvoiceCountryDesc = 'BOOKINGS_MIN_INVOICE_COUNTRY_DESC',
    BookingsMinInvoiceBankOwnerAsc = 'BOOKINGS_MIN_INVOICE_BANK_OWNER_ASC',
    BookingsMinInvoiceBankOwnerDesc = 'BOOKINGS_MIN_INVOICE_BANK_OWNER_DESC',
    BookingsMinInvoiceBankNameAsc = 'BOOKINGS_MIN_INVOICE_BANK_NAME_ASC',
    BookingsMinInvoiceBankNameDesc = 'BOOKINGS_MIN_INVOICE_BANK_NAME_DESC',
    BookingsMinInvoiceBankSwiftAsc = 'BOOKINGS_MIN_INVOICE_BANK_SWIFT_ASC',
    BookingsMinInvoiceBankSwiftDesc = 'BOOKINGS_MIN_INVOICE_BANK_SWIFT_DESC',
    BookingsMinInvoiceBankIbanAsc = 'BOOKINGS_MIN_INVOICE_BANK_IBAN_ASC',
    BookingsMinInvoiceBankIbanDesc = 'BOOKINGS_MIN_INVOICE_BANK_IBAN_DESC',
    BookingsMinInvoiceToEmployerAsc = 'BOOKINGS_MIN_INVOICE_TO_EMPLOYER_ASC',
    BookingsMinInvoiceToEmployerDesc = 'BOOKINGS_MIN_INVOICE_TO_EMPLOYER_DESC',
    BookingsMinStateAsc = 'BOOKINGS_MIN_STATE_ASC',
    BookingsMinStateDesc = 'BOOKINGS_MIN_STATE_DESC',
    BookingsMinInvoiceContactPhoneAsc = 'BOOKINGS_MIN_INVOICE_CONTACT_PHONE_ASC',
    BookingsMinInvoiceContactPhoneDesc = 'BOOKINGS_MIN_INVOICE_CONTACT_PHONE_DESC',
    BookingsMinInvoiceContactEmailAsc = 'BOOKINGS_MIN_INVOICE_CONTACT_EMAIL_ASC',
    BookingsMinInvoiceContactEmailDesc = 'BOOKINGS_MIN_INVOICE_CONTACT_EMAIL_DESC',
    BookingsMinInvoiceContactUrlAsc = 'BOOKINGS_MIN_INVOICE_CONTACT_URL_ASC',
    BookingsMinInvoiceContactUrlDesc = 'BOOKINGS_MIN_INVOICE_CONTACT_URL_DESC',
    BookingsMinAcceptAsc = 'BOOKINGS_MIN_ACCEPT_ASC',
    BookingsMinAcceptDesc = 'BOOKINGS_MIN_ACCEPT_DESC',
    BookingsMinAcceptPaymentAsc = 'BOOKINGS_MIN_ACCEPT_PAYMENT_ASC',
    BookingsMinAcceptPaymentDesc = 'BOOKINGS_MIN_ACCEPT_PAYMENT_DESC',
    BookingsMinInvoiceDateAsc = 'BOOKINGS_MIN_INVOICE_DATE_ASC',
    BookingsMinInvoiceDateDesc = 'BOOKINGS_MIN_INVOICE_DATE_DESC',
    BookingsMinCodeAsc = 'BOOKINGS_MIN_CODE_ASC',
    BookingsMinCodeDesc = 'BOOKINGS_MIN_CODE_DESC',
    BookingsMinUseForCertificationAsc = 'BOOKINGS_MIN_USE_FOR_CERTIFICATION_ASC',
    BookingsMinUseForCertificationDesc = 'BOOKINGS_MIN_USE_FOR_CERTIFICATION_DESC',
    BookingsMaxIdAsc = 'BOOKINGS_MAX_ID_ASC',
    BookingsMaxIdDesc = 'BOOKINGS_MAX_ID_DESC',
    BookingsMaxCourseScheduleIdAsc = 'BOOKINGS_MAX_COURSE_SCHEDULE_ID_ASC',
    BookingsMaxCourseScheduleIdDesc = 'BOOKINGS_MAX_COURSE_SCHEDULE_ID_DESC',
    BookingsMaxParticipantIdAsc = 'BOOKINGS_MAX_PARTICIPANT_ID_ASC',
    BookingsMaxParticipantIdDesc = 'BOOKINGS_MAX_PARTICIPANT_ID_DESC',
    BookingsMaxRegistrationRegisteredAsc = 'BOOKINGS_MAX_REGISTRATION_REGISTERED_ASC',
    BookingsMaxRegistrationRegisteredDesc = 'BOOKINGS_MAX_REGISTRATION_REGISTERED_DESC',
    BookingsMaxRegistrationDateAsc = 'BOOKINGS_MAX_REGISTRATION_DATE_ASC',
    BookingsMaxRegistrationDateDesc = 'BOOKINGS_MAX_REGISTRATION_DATE_DESC',
    BookingsMaxRegistrationNoteAsc = 'BOOKINGS_MAX_REGISTRATION_NOTE_ASC',
    BookingsMaxRegistrationNoteDesc = 'BOOKINGS_MAX_REGISTRATION_NOTE_DESC',
    BookingsMaxPassedAsc = 'BOOKINGS_MAX_PASSED_ASC',
    BookingsMaxPassedDesc = 'BOOKINGS_MAX_PASSED_DESC',
    BookingsMaxMissingHoursAsc = 'BOOKINGS_MAX_MISSING_HOURS_ASC',
    BookingsMaxMissingHoursDesc = 'BOOKINGS_MAX_MISSING_HOURS_DESC',
    BookingsMaxPaymentReceivedAsc = 'BOOKINGS_MAX_PAYMENT_RECEIVED_ASC',
    BookingsMaxPaymentReceivedDesc = 'BOOKINGS_MAX_PAYMENT_RECEIVED_DESC',
    BookingsMaxPaymentDateAsc = 'BOOKINGS_MAX_PAYMENT_DATE_ASC',
    BookingsMaxPaymentDateDesc = 'BOOKINGS_MAX_PAYMENT_DATE_DESC',
    BookingsMaxPaymentMethodAsc = 'BOOKINGS_MAX_PAYMENT_METHOD_ASC',
    BookingsMaxPaymentMethodDesc = 'BOOKINGS_MAX_PAYMENT_METHOD_DESC',
    BookingsMaxInvoiceNumberAsc = 'BOOKINGS_MAX_INVOICE_NUMBER_ASC',
    BookingsMaxInvoiceNumberDesc = 'BOOKINGS_MAX_INVOICE_NUMBER_DESC',
    BookingsMaxWaitingPositionAsc = 'BOOKINGS_MAX_WAITING_POSITION_ASC',
    BookingsMaxWaitingPositionDesc = 'BOOKINGS_MAX_WAITING_POSITION_DESC',
    BookingsMaxDocumentsSendAsc = 'BOOKINGS_MAX_DOCUMENTS_SEND_ASC',
    BookingsMaxDocumentsSendDesc = 'BOOKINGS_MAX_DOCUMENTS_SEND_DESC',
    BookingsMaxNoteAsc = 'BOOKINGS_MAX_NOTE_ASC',
    BookingsMaxNoteDesc = 'BOOKINGS_MAX_NOTE_DESC',
    BookingsMaxAmountAsc = 'BOOKINGS_MAX_AMOUNT_ASC',
    BookingsMaxAmountDesc = 'BOOKINGS_MAX_AMOUNT_DESC',
    BookingsMaxCreatedAtAsc = 'BOOKINGS_MAX_CREATED_AT_ASC',
    BookingsMaxCreatedAtDesc = 'BOOKINGS_MAX_CREATED_AT_DESC',
    BookingsMaxUpdatedAtAsc = 'BOOKINGS_MAX_UPDATED_AT_ASC',
    BookingsMaxUpdatedAtDesc = 'BOOKINGS_MAX_UPDATED_AT_DESC',
    BookingsMaxRegistrationConfirmationAsc = 'BOOKINGS_MAX_REGISTRATION_CONFIRMATION_ASC',
    BookingsMaxRegistrationConfirmationDesc = 'BOOKINGS_MAX_REGISTRATION_CONFIRMATION_DESC',
    BookingsMaxInvoiceCompanyNameAsc = 'BOOKINGS_MAX_INVOICE_COMPANY_NAME_ASC',
    BookingsMaxInvoiceCompanyNameDesc = 'BOOKINGS_MAX_INVOICE_COMPANY_NAME_DESC',
    BookingsMaxInvoiceFamilyNameAsc = 'BOOKINGS_MAX_INVOICE_FAMILY_NAME_ASC',
    BookingsMaxInvoiceFamilyNameDesc = 'BOOKINGS_MAX_INVOICE_FAMILY_NAME_DESC',
    BookingsMaxInvoiceSalutationAsc = 'BOOKINGS_MAX_INVOICE_SALUTATION_ASC',
    BookingsMaxInvoiceSalutationDesc = 'BOOKINGS_MAX_INVOICE_SALUTATION_DESC',
    BookingsMaxInvoiceTitleAsc = 'BOOKINGS_MAX_INVOICE_TITLE_ASC',
    BookingsMaxInvoiceTitleDesc = 'BOOKINGS_MAX_INVOICE_TITLE_DESC',
    BookingsMaxInvoiceGivenNameAsc = 'BOOKINGS_MAX_INVOICE_GIVEN_NAME_ASC',
    BookingsMaxInvoiceGivenNameDesc = 'BOOKINGS_MAX_INVOICE_GIVEN_NAME_DESC',
    BookingsMaxInvoiceStreetAsc = 'BOOKINGS_MAX_INVOICE_STREET_ASC',
    BookingsMaxInvoiceStreetDesc = 'BOOKINGS_MAX_INVOICE_STREET_DESC',
    BookingsMaxInvoicePostcodeAsc = 'BOOKINGS_MAX_INVOICE_POSTCODE_ASC',
    BookingsMaxInvoicePostcodeDesc = 'BOOKINGS_MAX_INVOICE_POSTCODE_DESC',
    BookingsMaxInvoiceCityAsc = 'BOOKINGS_MAX_INVOICE_CITY_ASC',
    BookingsMaxInvoiceCityDesc = 'BOOKINGS_MAX_INVOICE_CITY_DESC',
    BookingsMaxInvoiceCountryAsc = 'BOOKINGS_MAX_INVOICE_COUNTRY_ASC',
    BookingsMaxInvoiceCountryDesc = 'BOOKINGS_MAX_INVOICE_COUNTRY_DESC',
    BookingsMaxInvoiceBankOwnerAsc = 'BOOKINGS_MAX_INVOICE_BANK_OWNER_ASC',
    BookingsMaxInvoiceBankOwnerDesc = 'BOOKINGS_MAX_INVOICE_BANK_OWNER_DESC',
    BookingsMaxInvoiceBankNameAsc = 'BOOKINGS_MAX_INVOICE_BANK_NAME_ASC',
    BookingsMaxInvoiceBankNameDesc = 'BOOKINGS_MAX_INVOICE_BANK_NAME_DESC',
    BookingsMaxInvoiceBankSwiftAsc = 'BOOKINGS_MAX_INVOICE_BANK_SWIFT_ASC',
    BookingsMaxInvoiceBankSwiftDesc = 'BOOKINGS_MAX_INVOICE_BANK_SWIFT_DESC',
    BookingsMaxInvoiceBankIbanAsc = 'BOOKINGS_MAX_INVOICE_BANK_IBAN_ASC',
    BookingsMaxInvoiceBankIbanDesc = 'BOOKINGS_MAX_INVOICE_BANK_IBAN_DESC',
    BookingsMaxInvoiceToEmployerAsc = 'BOOKINGS_MAX_INVOICE_TO_EMPLOYER_ASC',
    BookingsMaxInvoiceToEmployerDesc = 'BOOKINGS_MAX_INVOICE_TO_EMPLOYER_DESC',
    BookingsMaxStateAsc = 'BOOKINGS_MAX_STATE_ASC',
    BookingsMaxStateDesc = 'BOOKINGS_MAX_STATE_DESC',
    BookingsMaxInvoiceContactPhoneAsc = 'BOOKINGS_MAX_INVOICE_CONTACT_PHONE_ASC',
    BookingsMaxInvoiceContactPhoneDesc = 'BOOKINGS_MAX_INVOICE_CONTACT_PHONE_DESC',
    BookingsMaxInvoiceContactEmailAsc = 'BOOKINGS_MAX_INVOICE_CONTACT_EMAIL_ASC',
    BookingsMaxInvoiceContactEmailDesc = 'BOOKINGS_MAX_INVOICE_CONTACT_EMAIL_DESC',
    BookingsMaxInvoiceContactUrlAsc = 'BOOKINGS_MAX_INVOICE_CONTACT_URL_ASC',
    BookingsMaxInvoiceContactUrlDesc = 'BOOKINGS_MAX_INVOICE_CONTACT_URL_DESC',
    BookingsMaxAcceptAsc = 'BOOKINGS_MAX_ACCEPT_ASC',
    BookingsMaxAcceptDesc = 'BOOKINGS_MAX_ACCEPT_DESC',
    BookingsMaxAcceptPaymentAsc = 'BOOKINGS_MAX_ACCEPT_PAYMENT_ASC',
    BookingsMaxAcceptPaymentDesc = 'BOOKINGS_MAX_ACCEPT_PAYMENT_DESC',
    BookingsMaxInvoiceDateAsc = 'BOOKINGS_MAX_INVOICE_DATE_ASC',
    BookingsMaxInvoiceDateDesc = 'BOOKINGS_MAX_INVOICE_DATE_DESC',
    BookingsMaxCodeAsc = 'BOOKINGS_MAX_CODE_ASC',
    BookingsMaxCodeDesc = 'BOOKINGS_MAX_CODE_DESC',
    BookingsMaxUseForCertificationAsc = 'BOOKINGS_MAX_USE_FOR_CERTIFICATION_ASC',
    BookingsMaxUseForCertificationDesc = 'BOOKINGS_MAX_USE_FOR_CERTIFICATION_DESC',
    BookingsAverageIdAsc = 'BOOKINGS_AVERAGE_ID_ASC',
    BookingsAverageIdDesc = 'BOOKINGS_AVERAGE_ID_DESC',
    BookingsAverageCourseScheduleIdAsc = 'BOOKINGS_AVERAGE_COURSE_SCHEDULE_ID_ASC',
    BookingsAverageCourseScheduleIdDesc = 'BOOKINGS_AVERAGE_COURSE_SCHEDULE_ID_DESC',
    BookingsAverageParticipantIdAsc = 'BOOKINGS_AVERAGE_PARTICIPANT_ID_ASC',
    BookingsAverageParticipantIdDesc = 'BOOKINGS_AVERAGE_PARTICIPANT_ID_DESC',
    BookingsAverageRegistrationRegisteredAsc = 'BOOKINGS_AVERAGE_REGISTRATION_REGISTERED_ASC',
    BookingsAverageRegistrationRegisteredDesc = 'BOOKINGS_AVERAGE_REGISTRATION_REGISTERED_DESC',
    BookingsAverageRegistrationDateAsc = 'BOOKINGS_AVERAGE_REGISTRATION_DATE_ASC',
    BookingsAverageRegistrationDateDesc = 'BOOKINGS_AVERAGE_REGISTRATION_DATE_DESC',
    BookingsAverageRegistrationNoteAsc = 'BOOKINGS_AVERAGE_REGISTRATION_NOTE_ASC',
    BookingsAverageRegistrationNoteDesc = 'BOOKINGS_AVERAGE_REGISTRATION_NOTE_DESC',
    BookingsAveragePassedAsc = 'BOOKINGS_AVERAGE_PASSED_ASC',
    BookingsAveragePassedDesc = 'BOOKINGS_AVERAGE_PASSED_DESC',
    BookingsAverageMissingHoursAsc = 'BOOKINGS_AVERAGE_MISSING_HOURS_ASC',
    BookingsAverageMissingHoursDesc = 'BOOKINGS_AVERAGE_MISSING_HOURS_DESC',
    BookingsAveragePaymentReceivedAsc = 'BOOKINGS_AVERAGE_PAYMENT_RECEIVED_ASC',
    BookingsAveragePaymentReceivedDesc = 'BOOKINGS_AVERAGE_PAYMENT_RECEIVED_DESC',
    BookingsAveragePaymentDateAsc = 'BOOKINGS_AVERAGE_PAYMENT_DATE_ASC',
    BookingsAveragePaymentDateDesc = 'BOOKINGS_AVERAGE_PAYMENT_DATE_DESC',
    BookingsAveragePaymentMethodAsc = 'BOOKINGS_AVERAGE_PAYMENT_METHOD_ASC',
    BookingsAveragePaymentMethodDesc = 'BOOKINGS_AVERAGE_PAYMENT_METHOD_DESC',
    BookingsAverageInvoiceNumberAsc = 'BOOKINGS_AVERAGE_INVOICE_NUMBER_ASC',
    BookingsAverageInvoiceNumberDesc = 'BOOKINGS_AVERAGE_INVOICE_NUMBER_DESC',
    BookingsAverageWaitingPositionAsc = 'BOOKINGS_AVERAGE_WAITING_POSITION_ASC',
    BookingsAverageWaitingPositionDesc = 'BOOKINGS_AVERAGE_WAITING_POSITION_DESC',
    BookingsAverageDocumentsSendAsc = 'BOOKINGS_AVERAGE_DOCUMENTS_SEND_ASC',
    BookingsAverageDocumentsSendDesc = 'BOOKINGS_AVERAGE_DOCUMENTS_SEND_DESC',
    BookingsAverageNoteAsc = 'BOOKINGS_AVERAGE_NOTE_ASC',
    BookingsAverageNoteDesc = 'BOOKINGS_AVERAGE_NOTE_DESC',
    BookingsAverageAmountAsc = 'BOOKINGS_AVERAGE_AMOUNT_ASC',
    BookingsAverageAmountDesc = 'BOOKINGS_AVERAGE_AMOUNT_DESC',
    BookingsAverageCreatedAtAsc = 'BOOKINGS_AVERAGE_CREATED_AT_ASC',
    BookingsAverageCreatedAtDesc = 'BOOKINGS_AVERAGE_CREATED_AT_DESC',
    BookingsAverageUpdatedAtAsc = 'BOOKINGS_AVERAGE_UPDATED_AT_ASC',
    BookingsAverageUpdatedAtDesc = 'BOOKINGS_AVERAGE_UPDATED_AT_DESC',
    BookingsAverageRegistrationConfirmationAsc = 'BOOKINGS_AVERAGE_REGISTRATION_CONFIRMATION_ASC',
    BookingsAverageRegistrationConfirmationDesc = 'BOOKINGS_AVERAGE_REGISTRATION_CONFIRMATION_DESC',
    BookingsAverageInvoiceCompanyNameAsc = 'BOOKINGS_AVERAGE_INVOICE_COMPANY_NAME_ASC',
    BookingsAverageInvoiceCompanyNameDesc = 'BOOKINGS_AVERAGE_INVOICE_COMPANY_NAME_DESC',
    BookingsAverageInvoiceFamilyNameAsc = 'BOOKINGS_AVERAGE_INVOICE_FAMILY_NAME_ASC',
    BookingsAverageInvoiceFamilyNameDesc = 'BOOKINGS_AVERAGE_INVOICE_FAMILY_NAME_DESC',
    BookingsAverageInvoiceSalutationAsc = 'BOOKINGS_AVERAGE_INVOICE_SALUTATION_ASC',
    BookingsAverageInvoiceSalutationDesc = 'BOOKINGS_AVERAGE_INVOICE_SALUTATION_DESC',
    BookingsAverageInvoiceTitleAsc = 'BOOKINGS_AVERAGE_INVOICE_TITLE_ASC',
    BookingsAverageInvoiceTitleDesc = 'BOOKINGS_AVERAGE_INVOICE_TITLE_DESC',
    BookingsAverageInvoiceGivenNameAsc = 'BOOKINGS_AVERAGE_INVOICE_GIVEN_NAME_ASC',
    BookingsAverageInvoiceGivenNameDesc = 'BOOKINGS_AVERAGE_INVOICE_GIVEN_NAME_DESC',
    BookingsAverageInvoiceStreetAsc = 'BOOKINGS_AVERAGE_INVOICE_STREET_ASC',
    BookingsAverageInvoiceStreetDesc = 'BOOKINGS_AVERAGE_INVOICE_STREET_DESC',
    BookingsAverageInvoicePostcodeAsc = 'BOOKINGS_AVERAGE_INVOICE_POSTCODE_ASC',
    BookingsAverageInvoicePostcodeDesc = 'BOOKINGS_AVERAGE_INVOICE_POSTCODE_DESC',
    BookingsAverageInvoiceCityAsc = 'BOOKINGS_AVERAGE_INVOICE_CITY_ASC',
    BookingsAverageInvoiceCityDesc = 'BOOKINGS_AVERAGE_INVOICE_CITY_DESC',
    BookingsAverageInvoiceCountryAsc = 'BOOKINGS_AVERAGE_INVOICE_COUNTRY_ASC',
    BookingsAverageInvoiceCountryDesc = 'BOOKINGS_AVERAGE_INVOICE_COUNTRY_DESC',
    BookingsAverageInvoiceBankOwnerAsc = 'BOOKINGS_AVERAGE_INVOICE_BANK_OWNER_ASC',
    BookingsAverageInvoiceBankOwnerDesc = 'BOOKINGS_AVERAGE_INVOICE_BANK_OWNER_DESC',
    BookingsAverageInvoiceBankNameAsc = 'BOOKINGS_AVERAGE_INVOICE_BANK_NAME_ASC',
    BookingsAverageInvoiceBankNameDesc = 'BOOKINGS_AVERAGE_INVOICE_BANK_NAME_DESC',
    BookingsAverageInvoiceBankSwiftAsc = 'BOOKINGS_AVERAGE_INVOICE_BANK_SWIFT_ASC',
    BookingsAverageInvoiceBankSwiftDesc = 'BOOKINGS_AVERAGE_INVOICE_BANK_SWIFT_DESC',
    BookingsAverageInvoiceBankIbanAsc = 'BOOKINGS_AVERAGE_INVOICE_BANK_IBAN_ASC',
    BookingsAverageInvoiceBankIbanDesc = 'BOOKINGS_AVERAGE_INVOICE_BANK_IBAN_DESC',
    BookingsAverageInvoiceToEmployerAsc = 'BOOKINGS_AVERAGE_INVOICE_TO_EMPLOYER_ASC',
    BookingsAverageInvoiceToEmployerDesc = 'BOOKINGS_AVERAGE_INVOICE_TO_EMPLOYER_DESC',
    BookingsAverageStateAsc = 'BOOKINGS_AVERAGE_STATE_ASC',
    BookingsAverageStateDesc = 'BOOKINGS_AVERAGE_STATE_DESC',
    BookingsAverageInvoiceContactPhoneAsc = 'BOOKINGS_AVERAGE_INVOICE_CONTACT_PHONE_ASC',
    BookingsAverageInvoiceContactPhoneDesc = 'BOOKINGS_AVERAGE_INVOICE_CONTACT_PHONE_DESC',
    BookingsAverageInvoiceContactEmailAsc = 'BOOKINGS_AVERAGE_INVOICE_CONTACT_EMAIL_ASC',
    BookingsAverageInvoiceContactEmailDesc = 'BOOKINGS_AVERAGE_INVOICE_CONTACT_EMAIL_DESC',
    BookingsAverageInvoiceContactUrlAsc = 'BOOKINGS_AVERAGE_INVOICE_CONTACT_URL_ASC',
    BookingsAverageInvoiceContactUrlDesc = 'BOOKINGS_AVERAGE_INVOICE_CONTACT_URL_DESC',
    BookingsAverageAcceptAsc = 'BOOKINGS_AVERAGE_ACCEPT_ASC',
    BookingsAverageAcceptDesc = 'BOOKINGS_AVERAGE_ACCEPT_DESC',
    BookingsAverageAcceptPaymentAsc = 'BOOKINGS_AVERAGE_ACCEPT_PAYMENT_ASC',
    BookingsAverageAcceptPaymentDesc = 'BOOKINGS_AVERAGE_ACCEPT_PAYMENT_DESC',
    BookingsAverageInvoiceDateAsc = 'BOOKINGS_AVERAGE_INVOICE_DATE_ASC',
    BookingsAverageInvoiceDateDesc = 'BOOKINGS_AVERAGE_INVOICE_DATE_DESC',
    BookingsAverageCodeAsc = 'BOOKINGS_AVERAGE_CODE_ASC',
    BookingsAverageCodeDesc = 'BOOKINGS_AVERAGE_CODE_DESC',
    BookingsAverageUseForCertificationAsc = 'BOOKINGS_AVERAGE_USE_FOR_CERTIFICATION_ASC',
    BookingsAverageUseForCertificationDesc = 'BOOKINGS_AVERAGE_USE_FOR_CERTIFICATION_DESC',
    BookingsStddevSampleIdAsc = 'BOOKINGS_STDDEV_SAMPLE_ID_ASC',
    BookingsStddevSampleIdDesc = 'BOOKINGS_STDDEV_SAMPLE_ID_DESC',
    BookingsStddevSampleCourseScheduleIdAsc = 'BOOKINGS_STDDEV_SAMPLE_COURSE_SCHEDULE_ID_ASC',
    BookingsStddevSampleCourseScheduleIdDesc = 'BOOKINGS_STDDEV_SAMPLE_COURSE_SCHEDULE_ID_DESC',
    BookingsStddevSampleParticipantIdAsc = 'BOOKINGS_STDDEV_SAMPLE_PARTICIPANT_ID_ASC',
    BookingsStddevSampleParticipantIdDesc = 'BOOKINGS_STDDEV_SAMPLE_PARTICIPANT_ID_DESC',
    BookingsStddevSampleRegistrationRegisteredAsc = 'BOOKINGS_STDDEV_SAMPLE_REGISTRATION_REGISTERED_ASC',
    BookingsStddevSampleRegistrationRegisteredDesc = 'BOOKINGS_STDDEV_SAMPLE_REGISTRATION_REGISTERED_DESC',
    BookingsStddevSampleRegistrationDateAsc = 'BOOKINGS_STDDEV_SAMPLE_REGISTRATION_DATE_ASC',
    BookingsStddevSampleRegistrationDateDesc = 'BOOKINGS_STDDEV_SAMPLE_REGISTRATION_DATE_DESC',
    BookingsStddevSampleRegistrationNoteAsc = 'BOOKINGS_STDDEV_SAMPLE_REGISTRATION_NOTE_ASC',
    BookingsStddevSampleRegistrationNoteDesc = 'BOOKINGS_STDDEV_SAMPLE_REGISTRATION_NOTE_DESC',
    BookingsStddevSamplePassedAsc = 'BOOKINGS_STDDEV_SAMPLE_PASSED_ASC',
    BookingsStddevSamplePassedDesc = 'BOOKINGS_STDDEV_SAMPLE_PASSED_DESC',
    BookingsStddevSampleMissingHoursAsc = 'BOOKINGS_STDDEV_SAMPLE_MISSING_HOURS_ASC',
    BookingsStddevSampleMissingHoursDesc = 'BOOKINGS_STDDEV_SAMPLE_MISSING_HOURS_DESC',
    BookingsStddevSamplePaymentReceivedAsc = 'BOOKINGS_STDDEV_SAMPLE_PAYMENT_RECEIVED_ASC',
    BookingsStddevSamplePaymentReceivedDesc = 'BOOKINGS_STDDEV_SAMPLE_PAYMENT_RECEIVED_DESC',
    BookingsStddevSamplePaymentDateAsc = 'BOOKINGS_STDDEV_SAMPLE_PAYMENT_DATE_ASC',
    BookingsStddevSamplePaymentDateDesc = 'BOOKINGS_STDDEV_SAMPLE_PAYMENT_DATE_DESC',
    BookingsStddevSamplePaymentMethodAsc = 'BOOKINGS_STDDEV_SAMPLE_PAYMENT_METHOD_ASC',
    BookingsStddevSamplePaymentMethodDesc = 'BOOKINGS_STDDEV_SAMPLE_PAYMENT_METHOD_DESC',
    BookingsStddevSampleInvoiceNumberAsc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_NUMBER_ASC',
    BookingsStddevSampleInvoiceNumberDesc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_NUMBER_DESC',
    BookingsStddevSampleWaitingPositionAsc = 'BOOKINGS_STDDEV_SAMPLE_WAITING_POSITION_ASC',
    BookingsStddevSampleWaitingPositionDesc = 'BOOKINGS_STDDEV_SAMPLE_WAITING_POSITION_DESC',
    BookingsStddevSampleDocumentsSendAsc = 'BOOKINGS_STDDEV_SAMPLE_DOCUMENTS_SEND_ASC',
    BookingsStddevSampleDocumentsSendDesc = 'BOOKINGS_STDDEV_SAMPLE_DOCUMENTS_SEND_DESC',
    BookingsStddevSampleNoteAsc = 'BOOKINGS_STDDEV_SAMPLE_NOTE_ASC',
    BookingsStddevSampleNoteDesc = 'BOOKINGS_STDDEV_SAMPLE_NOTE_DESC',
    BookingsStddevSampleAmountAsc = 'BOOKINGS_STDDEV_SAMPLE_AMOUNT_ASC',
    BookingsStddevSampleAmountDesc = 'BOOKINGS_STDDEV_SAMPLE_AMOUNT_DESC',
    BookingsStddevSampleCreatedAtAsc = 'BOOKINGS_STDDEV_SAMPLE_CREATED_AT_ASC',
    BookingsStddevSampleCreatedAtDesc = 'BOOKINGS_STDDEV_SAMPLE_CREATED_AT_DESC',
    BookingsStddevSampleUpdatedAtAsc = 'BOOKINGS_STDDEV_SAMPLE_UPDATED_AT_ASC',
    BookingsStddevSampleUpdatedAtDesc = 'BOOKINGS_STDDEV_SAMPLE_UPDATED_AT_DESC',
    BookingsStddevSampleRegistrationConfirmationAsc = 'BOOKINGS_STDDEV_SAMPLE_REGISTRATION_CONFIRMATION_ASC',
    BookingsStddevSampleRegistrationConfirmationDesc = 'BOOKINGS_STDDEV_SAMPLE_REGISTRATION_CONFIRMATION_DESC',
    BookingsStddevSampleInvoiceCompanyNameAsc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_COMPANY_NAME_ASC',
    BookingsStddevSampleInvoiceCompanyNameDesc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_COMPANY_NAME_DESC',
    BookingsStddevSampleInvoiceFamilyNameAsc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_FAMILY_NAME_ASC',
    BookingsStddevSampleInvoiceFamilyNameDesc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_FAMILY_NAME_DESC',
    BookingsStddevSampleInvoiceSalutationAsc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_SALUTATION_ASC',
    BookingsStddevSampleInvoiceSalutationDesc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_SALUTATION_DESC',
    BookingsStddevSampleInvoiceTitleAsc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_TITLE_ASC',
    BookingsStddevSampleInvoiceTitleDesc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_TITLE_DESC',
    BookingsStddevSampleInvoiceGivenNameAsc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_GIVEN_NAME_ASC',
    BookingsStddevSampleInvoiceGivenNameDesc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_GIVEN_NAME_DESC',
    BookingsStddevSampleInvoiceStreetAsc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_STREET_ASC',
    BookingsStddevSampleInvoiceStreetDesc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_STREET_DESC',
    BookingsStddevSampleInvoicePostcodeAsc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_POSTCODE_ASC',
    BookingsStddevSampleInvoicePostcodeDesc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_POSTCODE_DESC',
    BookingsStddevSampleInvoiceCityAsc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_CITY_ASC',
    BookingsStddevSampleInvoiceCityDesc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_CITY_DESC',
    BookingsStddevSampleInvoiceCountryAsc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_COUNTRY_ASC',
    BookingsStddevSampleInvoiceCountryDesc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_COUNTRY_DESC',
    BookingsStddevSampleInvoiceBankOwnerAsc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_BANK_OWNER_ASC',
    BookingsStddevSampleInvoiceBankOwnerDesc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_BANK_OWNER_DESC',
    BookingsStddevSampleInvoiceBankNameAsc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_BANK_NAME_ASC',
    BookingsStddevSampleInvoiceBankNameDesc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_BANK_NAME_DESC',
    BookingsStddevSampleInvoiceBankSwiftAsc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_BANK_SWIFT_ASC',
    BookingsStddevSampleInvoiceBankSwiftDesc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_BANK_SWIFT_DESC',
    BookingsStddevSampleInvoiceBankIbanAsc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_BANK_IBAN_ASC',
    BookingsStddevSampleInvoiceBankIbanDesc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_BANK_IBAN_DESC',
    BookingsStddevSampleInvoiceToEmployerAsc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_TO_EMPLOYER_ASC',
    BookingsStddevSampleInvoiceToEmployerDesc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_TO_EMPLOYER_DESC',
    BookingsStddevSampleStateAsc = 'BOOKINGS_STDDEV_SAMPLE_STATE_ASC',
    BookingsStddevSampleStateDesc = 'BOOKINGS_STDDEV_SAMPLE_STATE_DESC',
    BookingsStddevSampleInvoiceContactPhoneAsc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_CONTACT_PHONE_ASC',
    BookingsStddevSampleInvoiceContactPhoneDesc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_CONTACT_PHONE_DESC',
    BookingsStddevSampleInvoiceContactEmailAsc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_CONTACT_EMAIL_ASC',
    BookingsStddevSampleInvoiceContactEmailDesc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_CONTACT_EMAIL_DESC',
    BookingsStddevSampleInvoiceContactUrlAsc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_CONTACT_URL_ASC',
    BookingsStddevSampleInvoiceContactUrlDesc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_CONTACT_URL_DESC',
    BookingsStddevSampleAcceptAsc = 'BOOKINGS_STDDEV_SAMPLE_ACCEPT_ASC',
    BookingsStddevSampleAcceptDesc = 'BOOKINGS_STDDEV_SAMPLE_ACCEPT_DESC',
    BookingsStddevSampleAcceptPaymentAsc = 'BOOKINGS_STDDEV_SAMPLE_ACCEPT_PAYMENT_ASC',
    BookingsStddevSampleAcceptPaymentDesc = 'BOOKINGS_STDDEV_SAMPLE_ACCEPT_PAYMENT_DESC',
    BookingsStddevSampleInvoiceDateAsc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_DATE_ASC',
    BookingsStddevSampleInvoiceDateDesc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_DATE_DESC',
    BookingsStddevSampleCodeAsc = 'BOOKINGS_STDDEV_SAMPLE_CODE_ASC',
    BookingsStddevSampleCodeDesc = 'BOOKINGS_STDDEV_SAMPLE_CODE_DESC',
    BookingsStddevSampleUseForCertificationAsc = 'BOOKINGS_STDDEV_SAMPLE_USE_FOR_CERTIFICATION_ASC',
    BookingsStddevSampleUseForCertificationDesc = 'BOOKINGS_STDDEV_SAMPLE_USE_FOR_CERTIFICATION_DESC',
    BookingsStddevPopulationIdAsc = 'BOOKINGS_STDDEV_POPULATION_ID_ASC',
    BookingsStddevPopulationIdDesc = 'BOOKINGS_STDDEV_POPULATION_ID_DESC',
    BookingsStddevPopulationCourseScheduleIdAsc = 'BOOKINGS_STDDEV_POPULATION_COURSE_SCHEDULE_ID_ASC',
    BookingsStddevPopulationCourseScheduleIdDesc = 'BOOKINGS_STDDEV_POPULATION_COURSE_SCHEDULE_ID_DESC',
    BookingsStddevPopulationParticipantIdAsc = 'BOOKINGS_STDDEV_POPULATION_PARTICIPANT_ID_ASC',
    BookingsStddevPopulationParticipantIdDesc = 'BOOKINGS_STDDEV_POPULATION_PARTICIPANT_ID_DESC',
    BookingsStddevPopulationRegistrationRegisteredAsc = 'BOOKINGS_STDDEV_POPULATION_REGISTRATION_REGISTERED_ASC',
    BookingsStddevPopulationRegistrationRegisteredDesc = 'BOOKINGS_STDDEV_POPULATION_REGISTRATION_REGISTERED_DESC',
    BookingsStddevPopulationRegistrationDateAsc = 'BOOKINGS_STDDEV_POPULATION_REGISTRATION_DATE_ASC',
    BookingsStddevPopulationRegistrationDateDesc = 'BOOKINGS_STDDEV_POPULATION_REGISTRATION_DATE_DESC',
    BookingsStddevPopulationRegistrationNoteAsc = 'BOOKINGS_STDDEV_POPULATION_REGISTRATION_NOTE_ASC',
    BookingsStddevPopulationRegistrationNoteDesc = 'BOOKINGS_STDDEV_POPULATION_REGISTRATION_NOTE_DESC',
    BookingsStddevPopulationPassedAsc = 'BOOKINGS_STDDEV_POPULATION_PASSED_ASC',
    BookingsStddevPopulationPassedDesc = 'BOOKINGS_STDDEV_POPULATION_PASSED_DESC',
    BookingsStddevPopulationMissingHoursAsc = 'BOOKINGS_STDDEV_POPULATION_MISSING_HOURS_ASC',
    BookingsStddevPopulationMissingHoursDesc = 'BOOKINGS_STDDEV_POPULATION_MISSING_HOURS_DESC',
    BookingsStddevPopulationPaymentReceivedAsc = 'BOOKINGS_STDDEV_POPULATION_PAYMENT_RECEIVED_ASC',
    BookingsStddevPopulationPaymentReceivedDesc = 'BOOKINGS_STDDEV_POPULATION_PAYMENT_RECEIVED_DESC',
    BookingsStddevPopulationPaymentDateAsc = 'BOOKINGS_STDDEV_POPULATION_PAYMENT_DATE_ASC',
    BookingsStddevPopulationPaymentDateDesc = 'BOOKINGS_STDDEV_POPULATION_PAYMENT_DATE_DESC',
    BookingsStddevPopulationPaymentMethodAsc = 'BOOKINGS_STDDEV_POPULATION_PAYMENT_METHOD_ASC',
    BookingsStddevPopulationPaymentMethodDesc = 'BOOKINGS_STDDEV_POPULATION_PAYMENT_METHOD_DESC',
    BookingsStddevPopulationInvoiceNumberAsc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_NUMBER_ASC',
    BookingsStddevPopulationInvoiceNumberDesc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_NUMBER_DESC',
    BookingsStddevPopulationWaitingPositionAsc = 'BOOKINGS_STDDEV_POPULATION_WAITING_POSITION_ASC',
    BookingsStddevPopulationWaitingPositionDesc = 'BOOKINGS_STDDEV_POPULATION_WAITING_POSITION_DESC',
    BookingsStddevPopulationDocumentsSendAsc = 'BOOKINGS_STDDEV_POPULATION_DOCUMENTS_SEND_ASC',
    BookingsStddevPopulationDocumentsSendDesc = 'BOOKINGS_STDDEV_POPULATION_DOCUMENTS_SEND_DESC',
    BookingsStddevPopulationNoteAsc = 'BOOKINGS_STDDEV_POPULATION_NOTE_ASC',
    BookingsStddevPopulationNoteDesc = 'BOOKINGS_STDDEV_POPULATION_NOTE_DESC',
    BookingsStddevPopulationAmountAsc = 'BOOKINGS_STDDEV_POPULATION_AMOUNT_ASC',
    BookingsStddevPopulationAmountDesc = 'BOOKINGS_STDDEV_POPULATION_AMOUNT_DESC',
    BookingsStddevPopulationCreatedAtAsc = 'BOOKINGS_STDDEV_POPULATION_CREATED_AT_ASC',
    BookingsStddevPopulationCreatedAtDesc = 'BOOKINGS_STDDEV_POPULATION_CREATED_AT_DESC',
    BookingsStddevPopulationUpdatedAtAsc = 'BOOKINGS_STDDEV_POPULATION_UPDATED_AT_ASC',
    BookingsStddevPopulationUpdatedAtDesc = 'BOOKINGS_STDDEV_POPULATION_UPDATED_AT_DESC',
    BookingsStddevPopulationRegistrationConfirmationAsc = 'BOOKINGS_STDDEV_POPULATION_REGISTRATION_CONFIRMATION_ASC',
    BookingsStddevPopulationRegistrationConfirmationDesc = 'BOOKINGS_STDDEV_POPULATION_REGISTRATION_CONFIRMATION_DESC',
    BookingsStddevPopulationInvoiceCompanyNameAsc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_COMPANY_NAME_ASC',
    BookingsStddevPopulationInvoiceCompanyNameDesc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_COMPANY_NAME_DESC',
    BookingsStddevPopulationInvoiceFamilyNameAsc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_FAMILY_NAME_ASC',
    BookingsStddevPopulationInvoiceFamilyNameDesc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_FAMILY_NAME_DESC',
    BookingsStddevPopulationInvoiceSalutationAsc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_SALUTATION_ASC',
    BookingsStddevPopulationInvoiceSalutationDesc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_SALUTATION_DESC',
    BookingsStddevPopulationInvoiceTitleAsc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_TITLE_ASC',
    BookingsStddevPopulationInvoiceTitleDesc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_TITLE_DESC',
    BookingsStddevPopulationInvoiceGivenNameAsc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_GIVEN_NAME_ASC',
    BookingsStddevPopulationInvoiceGivenNameDesc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_GIVEN_NAME_DESC',
    BookingsStddevPopulationInvoiceStreetAsc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_STREET_ASC',
    BookingsStddevPopulationInvoiceStreetDesc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_STREET_DESC',
    BookingsStddevPopulationInvoicePostcodeAsc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_POSTCODE_ASC',
    BookingsStddevPopulationInvoicePostcodeDesc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_POSTCODE_DESC',
    BookingsStddevPopulationInvoiceCityAsc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_CITY_ASC',
    BookingsStddevPopulationInvoiceCityDesc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_CITY_DESC',
    BookingsStddevPopulationInvoiceCountryAsc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_COUNTRY_ASC',
    BookingsStddevPopulationInvoiceCountryDesc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_COUNTRY_DESC',
    BookingsStddevPopulationInvoiceBankOwnerAsc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_BANK_OWNER_ASC',
    BookingsStddevPopulationInvoiceBankOwnerDesc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_BANK_OWNER_DESC',
    BookingsStddevPopulationInvoiceBankNameAsc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_BANK_NAME_ASC',
    BookingsStddevPopulationInvoiceBankNameDesc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_BANK_NAME_DESC',
    BookingsStddevPopulationInvoiceBankSwiftAsc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_BANK_SWIFT_ASC',
    BookingsStddevPopulationInvoiceBankSwiftDesc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_BANK_SWIFT_DESC',
    BookingsStddevPopulationInvoiceBankIbanAsc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_BANK_IBAN_ASC',
    BookingsStddevPopulationInvoiceBankIbanDesc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_BANK_IBAN_DESC',
    BookingsStddevPopulationInvoiceToEmployerAsc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_TO_EMPLOYER_ASC',
    BookingsStddevPopulationInvoiceToEmployerDesc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_TO_EMPLOYER_DESC',
    BookingsStddevPopulationStateAsc = 'BOOKINGS_STDDEV_POPULATION_STATE_ASC',
    BookingsStddevPopulationStateDesc = 'BOOKINGS_STDDEV_POPULATION_STATE_DESC',
    BookingsStddevPopulationInvoiceContactPhoneAsc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_CONTACT_PHONE_ASC',
    BookingsStddevPopulationInvoiceContactPhoneDesc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_CONTACT_PHONE_DESC',
    BookingsStddevPopulationInvoiceContactEmailAsc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_CONTACT_EMAIL_ASC',
    BookingsStddevPopulationInvoiceContactEmailDesc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_CONTACT_EMAIL_DESC',
    BookingsStddevPopulationInvoiceContactUrlAsc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_CONTACT_URL_ASC',
    BookingsStddevPopulationInvoiceContactUrlDesc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_CONTACT_URL_DESC',
    BookingsStddevPopulationAcceptAsc = 'BOOKINGS_STDDEV_POPULATION_ACCEPT_ASC',
    BookingsStddevPopulationAcceptDesc = 'BOOKINGS_STDDEV_POPULATION_ACCEPT_DESC',
    BookingsStddevPopulationAcceptPaymentAsc = 'BOOKINGS_STDDEV_POPULATION_ACCEPT_PAYMENT_ASC',
    BookingsStddevPopulationAcceptPaymentDesc = 'BOOKINGS_STDDEV_POPULATION_ACCEPT_PAYMENT_DESC',
    BookingsStddevPopulationInvoiceDateAsc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_DATE_ASC',
    BookingsStddevPopulationInvoiceDateDesc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_DATE_DESC',
    BookingsStddevPopulationCodeAsc = 'BOOKINGS_STDDEV_POPULATION_CODE_ASC',
    BookingsStddevPopulationCodeDesc = 'BOOKINGS_STDDEV_POPULATION_CODE_DESC',
    BookingsStddevPopulationUseForCertificationAsc = 'BOOKINGS_STDDEV_POPULATION_USE_FOR_CERTIFICATION_ASC',
    BookingsStddevPopulationUseForCertificationDesc = 'BOOKINGS_STDDEV_POPULATION_USE_FOR_CERTIFICATION_DESC',
    BookingsVarianceSampleIdAsc = 'BOOKINGS_VARIANCE_SAMPLE_ID_ASC',
    BookingsVarianceSampleIdDesc = 'BOOKINGS_VARIANCE_SAMPLE_ID_DESC',
    BookingsVarianceSampleCourseScheduleIdAsc = 'BOOKINGS_VARIANCE_SAMPLE_COURSE_SCHEDULE_ID_ASC',
    BookingsVarianceSampleCourseScheduleIdDesc = 'BOOKINGS_VARIANCE_SAMPLE_COURSE_SCHEDULE_ID_DESC',
    BookingsVarianceSampleParticipantIdAsc = 'BOOKINGS_VARIANCE_SAMPLE_PARTICIPANT_ID_ASC',
    BookingsVarianceSampleParticipantIdDesc = 'BOOKINGS_VARIANCE_SAMPLE_PARTICIPANT_ID_DESC',
    BookingsVarianceSampleRegistrationRegisteredAsc = 'BOOKINGS_VARIANCE_SAMPLE_REGISTRATION_REGISTERED_ASC',
    BookingsVarianceSampleRegistrationRegisteredDesc = 'BOOKINGS_VARIANCE_SAMPLE_REGISTRATION_REGISTERED_DESC',
    BookingsVarianceSampleRegistrationDateAsc = 'BOOKINGS_VARIANCE_SAMPLE_REGISTRATION_DATE_ASC',
    BookingsVarianceSampleRegistrationDateDesc = 'BOOKINGS_VARIANCE_SAMPLE_REGISTRATION_DATE_DESC',
    BookingsVarianceSampleRegistrationNoteAsc = 'BOOKINGS_VARIANCE_SAMPLE_REGISTRATION_NOTE_ASC',
    BookingsVarianceSampleRegistrationNoteDesc = 'BOOKINGS_VARIANCE_SAMPLE_REGISTRATION_NOTE_DESC',
    BookingsVarianceSamplePassedAsc = 'BOOKINGS_VARIANCE_SAMPLE_PASSED_ASC',
    BookingsVarianceSamplePassedDesc = 'BOOKINGS_VARIANCE_SAMPLE_PASSED_DESC',
    BookingsVarianceSampleMissingHoursAsc = 'BOOKINGS_VARIANCE_SAMPLE_MISSING_HOURS_ASC',
    BookingsVarianceSampleMissingHoursDesc = 'BOOKINGS_VARIANCE_SAMPLE_MISSING_HOURS_DESC',
    BookingsVarianceSamplePaymentReceivedAsc = 'BOOKINGS_VARIANCE_SAMPLE_PAYMENT_RECEIVED_ASC',
    BookingsVarianceSamplePaymentReceivedDesc = 'BOOKINGS_VARIANCE_SAMPLE_PAYMENT_RECEIVED_DESC',
    BookingsVarianceSamplePaymentDateAsc = 'BOOKINGS_VARIANCE_SAMPLE_PAYMENT_DATE_ASC',
    BookingsVarianceSamplePaymentDateDesc = 'BOOKINGS_VARIANCE_SAMPLE_PAYMENT_DATE_DESC',
    BookingsVarianceSamplePaymentMethodAsc = 'BOOKINGS_VARIANCE_SAMPLE_PAYMENT_METHOD_ASC',
    BookingsVarianceSamplePaymentMethodDesc = 'BOOKINGS_VARIANCE_SAMPLE_PAYMENT_METHOD_DESC',
    BookingsVarianceSampleInvoiceNumberAsc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_NUMBER_ASC',
    BookingsVarianceSampleInvoiceNumberDesc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_NUMBER_DESC',
    BookingsVarianceSampleWaitingPositionAsc = 'BOOKINGS_VARIANCE_SAMPLE_WAITING_POSITION_ASC',
    BookingsVarianceSampleWaitingPositionDesc = 'BOOKINGS_VARIANCE_SAMPLE_WAITING_POSITION_DESC',
    BookingsVarianceSampleDocumentsSendAsc = 'BOOKINGS_VARIANCE_SAMPLE_DOCUMENTS_SEND_ASC',
    BookingsVarianceSampleDocumentsSendDesc = 'BOOKINGS_VARIANCE_SAMPLE_DOCUMENTS_SEND_DESC',
    BookingsVarianceSampleNoteAsc = 'BOOKINGS_VARIANCE_SAMPLE_NOTE_ASC',
    BookingsVarianceSampleNoteDesc = 'BOOKINGS_VARIANCE_SAMPLE_NOTE_DESC',
    BookingsVarianceSampleAmountAsc = 'BOOKINGS_VARIANCE_SAMPLE_AMOUNT_ASC',
    BookingsVarianceSampleAmountDesc = 'BOOKINGS_VARIANCE_SAMPLE_AMOUNT_DESC',
    BookingsVarianceSampleCreatedAtAsc = 'BOOKINGS_VARIANCE_SAMPLE_CREATED_AT_ASC',
    BookingsVarianceSampleCreatedAtDesc = 'BOOKINGS_VARIANCE_SAMPLE_CREATED_AT_DESC',
    BookingsVarianceSampleUpdatedAtAsc = 'BOOKINGS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
    BookingsVarianceSampleUpdatedAtDesc = 'BOOKINGS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
    BookingsVarianceSampleRegistrationConfirmationAsc = 'BOOKINGS_VARIANCE_SAMPLE_REGISTRATION_CONFIRMATION_ASC',
    BookingsVarianceSampleRegistrationConfirmationDesc = 'BOOKINGS_VARIANCE_SAMPLE_REGISTRATION_CONFIRMATION_DESC',
    BookingsVarianceSampleInvoiceCompanyNameAsc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_COMPANY_NAME_ASC',
    BookingsVarianceSampleInvoiceCompanyNameDesc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_COMPANY_NAME_DESC',
    BookingsVarianceSampleInvoiceFamilyNameAsc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_FAMILY_NAME_ASC',
    BookingsVarianceSampleInvoiceFamilyNameDesc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_FAMILY_NAME_DESC',
    BookingsVarianceSampleInvoiceSalutationAsc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_SALUTATION_ASC',
    BookingsVarianceSampleInvoiceSalutationDesc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_SALUTATION_DESC',
    BookingsVarianceSampleInvoiceTitleAsc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_TITLE_ASC',
    BookingsVarianceSampleInvoiceTitleDesc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_TITLE_DESC',
    BookingsVarianceSampleInvoiceGivenNameAsc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_GIVEN_NAME_ASC',
    BookingsVarianceSampleInvoiceGivenNameDesc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_GIVEN_NAME_DESC',
    BookingsVarianceSampleInvoiceStreetAsc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_STREET_ASC',
    BookingsVarianceSampleInvoiceStreetDesc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_STREET_DESC',
    BookingsVarianceSampleInvoicePostcodeAsc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_POSTCODE_ASC',
    BookingsVarianceSampleInvoicePostcodeDesc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_POSTCODE_DESC',
    BookingsVarianceSampleInvoiceCityAsc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_CITY_ASC',
    BookingsVarianceSampleInvoiceCityDesc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_CITY_DESC',
    BookingsVarianceSampleInvoiceCountryAsc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_COUNTRY_ASC',
    BookingsVarianceSampleInvoiceCountryDesc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_COUNTRY_DESC',
    BookingsVarianceSampleInvoiceBankOwnerAsc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_BANK_OWNER_ASC',
    BookingsVarianceSampleInvoiceBankOwnerDesc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_BANK_OWNER_DESC',
    BookingsVarianceSampleInvoiceBankNameAsc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_BANK_NAME_ASC',
    BookingsVarianceSampleInvoiceBankNameDesc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_BANK_NAME_DESC',
    BookingsVarianceSampleInvoiceBankSwiftAsc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_BANK_SWIFT_ASC',
    BookingsVarianceSampleInvoiceBankSwiftDesc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_BANK_SWIFT_DESC',
    BookingsVarianceSampleInvoiceBankIbanAsc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_BANK_IBAN_ASC',
    BookingsVarianceSampleInvoiceBankIbanDesc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_BANK_IBAN_DESC',
    BookingsVarianceSampleInvoiceToEmployerAsc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_TO_EMPLOYER_ASC',
    BookingsVarianceSampleInvoiceToEmployerDesc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_TO_EMPLOYER_DESC',
    BookingsVarianceSampleStateAsc = 'BOOKINGS_VARIANCE_SAMPLE_STATE_ASC',
    BookingsVarianceSampleStateDesc = 'BOOKINGS_VARIANCE_SAMPLE_STATE_DESC',
    BookingsVarianceSampleInvoiceContactPhoneAsc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_CONTACT_PHONE_ASC',
    BookingsVarianceSampleInvoiceContactPhoneDesc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_CONTACT_PHONE_DESC',
    BookingsVarianceSampleInvoiceContactEmailAsc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_CONTACT_EMAIL_ASC',
    BookingsVarianceSampleInvoiceContactEmailDesc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_CONTACT_EMAIL_DESC',
    BookingsVarianceSampleInvoiceContactUrlAsc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_CONTACT_URL_ASC',
    BookingsVarianceSampleInvoiceContactUrlDesc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_CONTACT_URL_DESC',
    BookingsVarianceSampleAcceptAsc = 'BOOKINGS_VARIANCE_SAMPLE_ACCEPT_ASC',
    BookingsVarianceSampleAcceptDesc = 'BOOKINGS_VARIANCE_SAMPLE_ACCEPT_DESC',
    BookingsVarianceSampleAcceptPaymentAsc = 'BOOKINGS_VARIANCE_SAMPLE_ACCEPT_PAYMENT_ASC',
    BookingsVarianceSampleAcceptPaymentDesc = 'BOOKINGS_VARIANCE_SAMPLE_ACCEPT_PAYMENT_DESC',
    BookingsVarianceSampleInvoiceDateAsc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_DATE_ASC',
    BookingsVarianceSampleInvoiceDateDesc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_DATE_DESC',
    BookingsVarianceSampleCodeAsc = 'BOOKINGS_VARIANCE_SAMPLE_CODE_ASC',
    BookingsVarianceSampleCodeDesc = 'BOOKINGS_VARIANCE_SAMPLE_CODE_DESC',
    BookingsVarianceSampleUseForCertificationAsc = 'BOOKINGS_VARIANCE_SAMPLE_USE_FOR_CERTIFICATION_ASC',
    BookingsVarianceSampleUseForCertificationDesc = 'BOOKINGS_VARIANCE_SAMPLE_USE_FOR_CERTIFICATION_DESC',
    BookingsVariancePopulationIdAsc = 'BOOKINGS_VARIANCE_POPULATION_ID_ASC',
    BookingsVariancePopulationIdDesc = 'BOOKINGS_VARIANCE_POPULATION_ID_DESC',
    BookingsVariancePopulationCourseScheduleIdAsc = 'BOOKINGS_VARIANCE_POPULATION_COURSE_SCHEDULE_ID_ASC',
    BookingsVariancePopulationCourseScheduleIdDesc = 'BOOKINGS_VARIANCE_POPULATION_COURSE_SCHEDULE_ID_DESC',
    BookingsVariancePopulationParticipantIdAsc = 'BOOKINGS_VARIANCE_POPULATION_PARTICIPANT_ID_ASC',
    BookingsVariancePopulationParticipantIdDesc = 'BOOKINGS_VARIANCE_POPULATION_PARTICIPANT_ID_DESC',
    BookingsVariancePopulationRegistrationRegisteredAsc = 'BOOKINGS_VARIANCE_POPULATION_REGISTRATION_REGISTERED_ASC',
    BookingsVariancePopulationRegistrationRegisteredDesc = 'BOOKINGS_VARIANCE_POPULATION_REGISTRATION_REGISTERED_DESC',
    BookingsVariancePopulationRegistrationDateAsc = 'BOOKINGS_VARIANCE_POPULATION_REGISTRATION_DATE_ASC',
    BookingsVariancePopulationRegistrationDateDesc = 'BOOKINGS_VARIANCE_POPULATION_REGISTRATION_DATE_DESC',
    BookingsVariancePopulationRegistrationNoteAsc = 'BOOKINGS_VARIANCE_POPULATION_REGISTRATION_NOTE_ASC',
    BookingsVariancePopulationRegistrationNoteDesc = 'BOOKINGS_VARIANCE_POPULATION_REGISTRATION_NOTE_DESC',
    BookingsVariancePopulationPassedAsc = 'BOOKINGS_VARIANCE_POPULATION_PASSED_ASC',
    BookingsVariancePopulationPassedDesc = 'BOOKINGS_VARIANCE_POPULATION_PASSED_DESC',
    BookingsVariancePopulationMissingHoursAsc = 'BOOKINGS_VARIANCE_POPULATION_MISSING_HOURS_ASC',
    BookingsVariancePopulationMissingHoursDesc = 'BOOKINGS_VARIANCE_POPULATION_MISSING_HOURS_DESC',
    BookingsVariancePopulationPaymentReceivedAsc = 'BOOKINGS_VARIANCE_POPULATION_PAYMENT_RECEIVED_ASC',
    BookingsVariancePopulationPaymentReceivedDesc = 'BOOKINGS_VARIANCE_POPULATION_PAYMENT_RECEIVED_DESC',
    BookingsVariancePopulationPaymentDateAsc = 'BOOKINGS_VARIANCE_POPULATION_PAYMENT_DATE_ASC',
    BookingsVariancePopulationPaymentDateDesc = 'BOOKINGS_VARIANCE_POPULATION_PAYMENT_DATE_DESC',
    BookingsVariancePopulationPaymentMethodAsc = 'BOOKINGS_VARIANCE_POPULATION_PAYMENT_METHOD_ASC',
    BookingsVariancePopulationPaymentMethodDesc = 'BOOKINGS_VARIANCE_POPULATION_PAYMENT_METHOD_DESC',
    BookingsVariancePopulationInvoiceNumberAsc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_NUMBER_ASC',
    BookingsVariancePopulationInvoiceNumberDesc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_NUMBER_DESC',
    BookingsVariancePopulationWaitingPositionAsc = 'BOOKINGS_VARIANCE_POPULATION_WAITING_POSITION_ASC',
    BookingsVariancePopulationWaitingPositionDesc = 'BOOKINGS_VARIANCE_POPULATION_WAITING_POSITION_DESC',
    BookingsVariancePopulationDocumentsSendAsc = 'BOOKINGS_VARIANCE_POPULATION_DOCUMENTS_SEND_ASC',
    BookingsVariancePopulationDocumentsSendDesc = 'BOOKINGS_VARIANCE_POPULATION_DOCUMENTS_SEND_DESC',
    BookingsVariancePopulationNoteAsc = 'BOOKINGS_VARIANCE_POPULATION_NOTE_ASC',
    BookingsVariancePopulationNoteDesc = 'BOOKINGS_VARIANCE_POPULATION_NOTE_DESC',
    BookingsVariancePopulationAmountAsc = 'BOOKINGS_VARIANCE_POPULATION_AMOUNT_ASC',
    BookingsVariancePopulationAmountDesc = 'BOOKINGS_VARIANCE_POPULATION_AMOUNT_DESC',
    BookingsVariancePopulationCreatedAtAsc = 'BOOKINGS_VARIANCE_POPULATION_CREATED_AT_ASC',
    BookingsVariancePopulationCreatedAtDesc = 'BOOKINGS_VARIANCE_POPULATION_CREATED_AT_DESC',
    BookingsVariancePopulationUpdatedAtAsc = 'BOOKINGS_VARIANCE_POPULATION_UPDATED_AT_ASC',
    BookingsVariancePopulationUpdatedAtDesc = 'BOOKINGS_VARIANCE_POPULATION_UPDATED_AT_DESC',
    BookingsVariancePopulationRegistrationConfirmationAsc = 'BOOKINGS_VARIANCE_POPULATION_REGISTRATION_CONFIRMATION_ASC',
    BookingsVariancePopulationRegistrationConfirmationDesc = 'BOOKINGS_VARIANCE_POPULATION_REGISTRATION_CONFIRMATION_DESC',
    BookingsVariancePopulationInvoiceCompanyNameAsc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_COMPANY_NAME_ASC',
    BookingsVariancePopulationInvoiceCompanyNameDesc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_COMPANY_NAME_DESC',
    BookingsVariancePopulationInvoiceFamilyNameAsc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_FAMILY_NAME_ASC',
    BookingsVariancePopulationInvoiceFamilyNameDesc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_FAMILY_NAME_DESC',
    BookingsVariancePopulationInvoiceSalutationAsc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_SALUTATION_ASC',
    BookingsVariancePopulationInvoiceSalutationDesc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_SALUTATION_DESC',
    BookingsVariancePopulationInvoiceTitleAsc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_TITLE_ASC',
    BookingsVariancePopulationInvoiceTitleDesc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_TITLE_DESC',
    BookingsVariancePopulationInvoiceGivenNameAsc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_GIVEN_NAME_ASC',
    BookingsVariancePopulationInvoiceGivenNameDesc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_GIVEN_NAME_DESC',
    BookingsVariancePopulationInvoiceStreetAsc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_STREET_ASC',
    BookingsVariancePopulationInvoiceStreetDesc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_STREET_DESC',
    BookingsVariancePopulationInvoicePostcodeAsc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_POSTCODE_ASC',
    BookingsVariancePopulationInvoicePostcodeDesc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_POSTCODE_DESC',
    BookingsVariancePopulationInvoiceCityAsc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_CITY_ASC',
    BookingsVariancePopulationInvoiceCityDesc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_CITY_DESC',
    BookingsVariancePopulationInvoiceCountryAsc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_COUNTRY_ASC',
    BookingsVariancePopulationInvoiceCountryDesc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_COUNTRY_DESC',
    BookingsVariancePopulationInvoiceBankOwnerAsc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_BANK_OWNER_ASC',
    BookingsVariancePopulationInvoiceBankOwnerDesc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_BANK_OWNER_DESC',
    BookingsVariancePopulationInvoiceBankNameAsc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_BANK_NAME_ASC',
    BookingsVariancePopulationInvoiceBankNameDesc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_BANK_NAME_DESC',
    BookingsVariancePopulationInvoiceBankSwiftAsc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_BANK_SWIFT_ASC',
    BookingsVariancePopulationInvoiceBankSwiftDesc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_BANK_SWIFT_DESC',
    BookingsVariancePopulationInvoiceBankIbanAsc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_BANK_IBAN_ASC',
    BookingsVariancePopulationInvoiceBankIbanDesc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_BANK_IBAN_DESC',
    BookingsVariancePopulationInvoiceToEmployerAsc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_TO_EMPLOYER_ASC',
    BookingsVariancePopulationInvoiceToEmployerDesc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_TO_EMPLOYER_DESC',
    BookingsVariancePopulationStateAsc = 'BOOKINGS_VARIANCE_POPULATION_STATE_ASC',
    BookingsVariancePopulationStateDesc = 'BOOKINGS_VARIANCE_POPULATION_STATE_DESC',
    BookingsVariancePopulationInvoiceContactPhoneAsc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_CONTACT_PHONE_ASC',
    BookingsVariancePopulationInvoiceContactPhoneDesc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_CONTACT_PHONE_DESC',
    BookingsVariancePopulationInvoiceContactEmailAsc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_CONTACT_EMAIL_ASC',
    BookingsVariancePopulationInvoiceContactEmailDesc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_CONTACT_EMAIL_DESC',
    BookingsVariancePopulationInvoiceContactUrlAsc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_CONTACT_URL_ASC',
    BookingsVariancePopulationInvoiceContactUrlDesc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_CONTACT_URL_DESC',
    BookingsVariancePopulationAcceptAsc = 'BOOKINGS_VARIANCE_POPULATION_ACCEPT_ASC',
    BookingsVariancePopulationAcceptDesc = 'BOOKINGS_VARIANCE_POPULATION_ACCEPT_DESC',
    BookingsVariancePopulationAcceptPaymentAsc = 'BOOKINGS_VARIANCE_POPULATION_ACCEPT_PAYMENT_ASC',
    BookingsVariancePopulationAcceptPaymentDesc = 'BOOKINGS_VARIANCE_POPULATION_ACCEPT_PAYMENT_DESC',
    BookingsVariancePopulationInvoiceDateAsc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_DATE_ASC',
    BookingsVariancePopulationInvoiceDateDesc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_DATE_DESC',
    BookingsVariancePopulationCodeAsc = 'BOOKINGS_VARIANCE_POPULATION_CODE_ASC',
    BookingsVariancePopulationCodeDesc = 'BOOKINGS_VARIANCE_POPULATION_CODE_DESC',
    BookingsVariancePopulationUseForCertificationAsc = 'BOOKINGS_VARIANCE_POPULATION_USE_FOR_CERTIFICATION_ASC',
    BookingsVariancePopulationUseForCertificationDesc = 'BOOKINGS_VARIANCE_POPULATION_USE_FOR_CERTIFICATION_DESC',
    CourseScheduleSpeakersCountAsc = 'COURSE_SCHEDULE_SPEAKERS_COUNT_ASC',
    CourseScheduleSpeakersCountDesc = 'COURSE_SCHEDULE_SPEAKERS_COUNT_DESC',
    CourseScheduleSpeakersSumSpeakerIdAsc = 'COURSE_SCHEDULE_SPEAKERS_SUM_SPEAKER_ID_ASC',
    CourseScheduleSpeakersSumSpeakerIdDesc = 'COURSE_SCHEDULE_SPEAKERS_SUM_SPEAKER_ID_DESC',
    CourseScheduleSpeakersSumCourseScheduleIdAsc = 'COURSE_SCHEDULE_SPEAKERS_SUM_COURSE_SCHEDULE_ID_ASC',
    CourseScheduleSpeakersSumCourseScheduleIdDesc = 'COURSE_SCHEDULE_SPEAKERS_SUM_COURSE_SCHEDULE_ID_DESC',
    CourseScheduleSpeakersSumCreatedAtAsc = 'COURSE_SCHEDULE_SPEAKERS_SUM_CREATED_AT_ASC',
    CourseScheduleSpeakersSumCreatedAtDesc = 'COURSE_SCHEDULE_SPEAKERS_SUM_CREATED_AT_DESC',
    CourseScheduleSpeakersSumUpdatedAtAsc = 'COURSE_SCHEDULE_SPEAKERS_SUM_UPDATED_AT_ASC',
    CourseScheduleSpeakersSumUpdatedAtDesc = 'COURSE_SCHEDULE_SPEAKERS_SUM_UPDATED_AT_DESC',
    CourseScheduleSpeakersDistinctCountSpeakerIdAsc = 'COURSE_SCHEDULE_SPEAKERS_DISTINCT_COUNT_SPEAKER_ID_ASC',
    CourseScheduleSpeakersDistinctCountSpeakerIdDesc = 'COURSE_SCHEDULE_SPEAKERS_DISTINCT_COUNT_SPEAKER_ID_DESC',
    CourseScheduleSpeakersDistinctCountCourseScheduleIdAsc = 'COURSE_SCHEDULE_SPEAKERS_DISTINCT_COUNT_COURSE_SCHEDULE_ID_ASC',
    CourseScheduleSpeakersDistinctCountCourseScheduleIdDesc = 'COURSE_SCHEDULE_SPEAKERS_DISTINCT_COUNT_COURSE_SCHEDULE_ID_DESC',
    CourseScheduleSpeakersDistinctCountCreatedAtAsc = 'COURSE_SCHEDULE_SPEAKERS_DISTINCT_COUNT_CREATED_AT_ASC',
    CourseScheduleSpeakersDistinctCountCreatedAtDesc = 'COURSE_SCHEDULE_SPEAKERS_DISTINCT_COUNT_CREATED_AT_DESC',
    CourseScheduleSpeakersDistinctCountUpdatedAtAsc = 'COURSE_SCHEDULE_SPEAKERS_DISTINCT_COUNT_UPDATED_AT_ASC',
    CourseScheduleSpeakersDistinctCountUpdatedAtDesc = 'COURSE_SCHEDULE_SPEAKERS_DISTINCT_COUNT_UPDATED_AT_DESC',
    CourseScheduleSpeakersMinSpeakerIdAsc = 'COURSE_SCHEDULE_SPEAKERS_MIN_SPEAKER_ID_ASC',
    CourseScheduleSpeakersMinSpeakerIdDesc = 'COURSE_SCHEDULE_SPEAKERS_MIN_SPEAKER_ID_DESC',
    CourseScheduleSpeakersMinCourseScheduleIdAsc = 'COURSE_SCHEDULE_SPEAKERS_MIN_COURSE_SCHEDULE_ID_ASC',
    CourseScheduleSpeakersMinCourseScheduleIdDesc = 'COURSE_SCHEDULE_SPEAKERS_MIN_COURSE_SCHEDULE_ID_DESC',
    CourseScheduleSpeakersMinCreatedAtAsc = 'COURSE_SCHEDULE_SPEAKERS_MIN_CREATED_AT_ASC',
    CourseScheduleSpeakersMinCreatedAtDesc = 'COURSE_SCHEDULE_SPEAKERS_MIN_CREATED_AT_DESC',
    CourseScheduleSpeakersMinUpdatedAtAsc = 'COURSE_SCHEDULE_SPEAKERS_MIN_UPDATED_AT_ASC',
    CourseScheduleSpeakersMinUpdatedAtDesc = 'COURSE_SCHEDULE_SPEAKERS_MIN_UPDATED_AT_DESC',
    CourseScheduleSpeakersMaxSpeakerIdAsc = 'COURSE_SCHEDULE_SPEAKERS_MAX_SPEAKER_ID_ASC',
    CourseScheduleSpeakersMaxSpeakerIdDesc = 'COURSE_SCHEDULE_SPEAKERS_MAX_SPEAKER_ID_DESC',
    CourseScheduleSpeakersMaxCourseScheduleIdAsc = 'COURSE_SCHEDULE_SPEAKERS_MAX_COURSE_SCHEDULE_ID_ASC',
    CourseScheduleSpeakersMaxCourseScheduleIdDesc = 'COURSE_SCHEDULE_SPEAKERS_MAX_COURSE_SCHEDULE_ID_DESC',
    CourseScheduleSpeakersMaxCreatedAtAsc = 'COURSE_SCHEDULE_SPEAKERS_MAX_CREATED_AT_ASC',
    CourseScheduleSpeakersMaxCreatedAtDesc = 'COURSE_SCHEDULE_SPEAKERS_MAX_CREATED_AT_DESC',
    CourseScheduleSpeakersMaxUpdatedAtAsc = 'COURSE_SCHEDULE_SPEAKERS_MAX_UPDATED_AT_ASC',
    CourseScheduleSpeakersMaxUpdatedAtDesc = 'COURSE_SCHEDULE_SPEAKERS_MAX_UPDATED_AT_DESC',
    CourseScheduleSpeakersAverageSpeakerIdAsc = 'COURSE_SCHEDULE_SPEAKERS_AVERAGE_SPEAKER_ID_ASC',
    CourseScheduleSpeakersAverageSpeakerIdDesc = 'COURSE_SCHEDULE_SPEAKERS_AVERAGE_SPEAKER_ID_DESC',
    CourseScheduleSpeakersAverageCourseScheduleIdAsc = 'COURSE_SCHEDULE_SPEAKERS_AVERAGE_COURSE_SCHEDULE_ID_ASC',
    CourseScheduleSpeakersAverageCourseScheduleIdDesc = 'COURSE_SCHEDULE_SPEAKERS_AVERAGE_COURSE_SCHEDULE_ID_DESC',
    CourseScheduleSpeakersAverageCreatedAtAsc = 'COURSE_SCHEDULE_SPEAKERS_AVERAGE_CREATED_AT_ASC',
    CourseScheduleSpeakersAverageCreatedAtDesc = 'COURSE_SCHEDULE_SPEAKERS_AVERAGE_CREATED_AT_DESC',
    CourseScheduleSpeakersAverageUpdatedAtAsc = 'COURSE_SCHEDULE_SPEAKERS_AVERAGE_UPDATED_AT_ASC',
    CourseScheduleSpeakersAverageUpdatedAtDesc = 'COURSE_SCHEDULE_SPEAKERS_AVERAGE_UPDATED_AT_DESC',
    CourseScheduleSpeakersStddevSampleSpeakerIdAsc = 'COURSE_SCHEDULE_SPEAKERS_STDDEV_SAMPLE_SPEAKER_ID_ASC',
    CourseScheduleSpeakersStddevSampleSpeakerIdDesc = 'COURSE_SCHEDULE_SPEAKERS_STDDEV_SAMPLE_SPEAKER_ID_DESC',
    CourseScheduleSpeakersStddevSampleCourseScheduleIdAsc = 'COURSE_SCHEDULE_SPEAKERS_STDDEV_SAMPLE_COURSE_SCHEDULE_ID_ASC',
    CourseScheduleSpeakersStddevSampleCourseScheduleIdDesc = 'COURSE_SCHEDULE_SPEAKERS_STDDEV_SAMPLE_COURSE_SCHEDULE_ID_DESC',
    CourseScheduleSpeakersStddevSampleCreatedAtAsc = 'COURSE_SCHEDULE_SPEAKERS_STDDEV_SAMPLE_CREATED_AT_ASC',
    CourseScheduleSpeakersStddevSampleCreatedAtDesc = 'COURSE_SCHEDULE_SPEAKERS_STDDEV_SAMPLE_CREATED_AT_DESC',
    CourseScheduleSpeakersStddevSampleUpdatedAtAsc = 'COURSE_SCHEDULE_SPEAKERS_STDDEV_SAMPLE_UPDATED_AT_ASC',
    CourseScheduleSpeakersStddevSampleUpdatedAtDesc = 'COURSE_SCHEDULE_SPEAKERS_STDDEV_SAMPLE_UPDATED_AT_DESC',
    CourseScheduleSpeakersStddevPopulationSpeakerIdAsc = 'COURSE_SCHEDULE_SPEAKERS_STDDEV_POPULATION_SPEAKER_ID_ASC',
    CourseScheduleSpeakersStddevPopulationSpeakerIdDesc = 'COURSE_SCHEDULE_SPEAKERS_STDDEV_POPULATION_SPEAKER_ID_DESC',
    CourseScheduleSpeakersStddevPopulationCourseScheduleIdAsc = 'COURSE_SCHEDULE_SPEAKERS_STDDEV_POPULATION_COURSE_SCHEDULE_ID_ASC',
    CourseScheduleSpeakersStddevPopulationCourseScheduleIdDesc = 'COURSE_SCHEDULE_SPEAKERS_STDDEV_POPULATION_COURSE_SCHEDULE_ID_DESC',
    CourseScheduleSpeakersStddevPopulationCreatedAtAsc = 'COURSE_SCHEDULE_SPEAKERS_STDDEV_POPULATION_CREATED_AT_ASC',
    CourseScheduleSpeakersStddevPopulationCreatedAtDesc = 'COURSE_SCHEDULE_SPEAKERS_STDDEV_POPULATION_CREATED_AT_DESC',
    CourseScheduleSpeakersStddevPopulationUpdatedAtAsc = 'COURSE_SCHEDULE_SPEAKERS_STDDEV_POPULATION_UPDATED_AT_ASC',
    CourseScheduleSpeakersStddevPopulationUpdatedAtDesc = 'COURSE_SCHEDULE_SPEAKERS_STDDEV_POPULATION_UPDATED_AT_DESC',
    CourseScheduleSpeakersVarianceSampleSpeakerIdAsc = 'COURSE_SCHEDULE_SPEAKERS_VARIANCE_SAMPLE_SPEAKER_ID_ASC',
    CourseScheduleSpeakersVarianceSampleSpeakerIdDesc = 'COURSE_SCHEDULE_SPEAKERS_VARIANCE_SAMPLE_SPEAKER_ID_DESC',
    CourseScheduleSpeakersVarianceSampleCourseScheduleIdAsc = 'COURSE_SCHEDULE_SPEAKERS_VARIANCE_SAMPLE_COURSE_SCHEDULE_ID_ASC',
    CourseScheduleSpeakersVarianceSampleCourseScheduleIdDesc = 'COURSE_SCHEDULE_SPEAKERS_VARIANCE_SAMPLE_COURSE_SCHEDULE_ID_DESC',
    CourseScheduleSpeakersVarianceSampleCreatedAtAsc = 'COURSE_SCHEDULE_SPEAKERS_VARIANCE_SAMPLE_CREATED_AT_ASC',
    CourseScheduleSpeakersVarianceSampleCreatedAtDesc = 'COURSE_SCHEDULE_SPEAKERS_VARIANCE_SAMPLE_CREATED_AT_DESC',
    CourseScheduleSpeakersVarianceSampleUpdatedAtAsc = 'COURSE_SCHEDULE_SPEAKERS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
    CourseScheduleSpeakersVarianceSampleUpdatedAtDesc = 'COURSE_SCHEDULE_SPEAKERS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
    CourseScheduleSpeakersVariancePopulationSpeakerIdAsc = 'COURSE_SCHEDULE_SPEAKERS_VARIANCE_POPULATION_SPEAKER_ID_ASC',
    CourseScheduleSpeakersVariancePopulationSpeakerIdDesc = 'COURSE_SCHEDULE_SPEAKERS_VARIANCE_POPULATION_SPEAKER_ID_DESC',
    CourseScheduleSpeakersVariancePopulationCourseScheduleIdAsc = 'COURSE_SCHEDULE_SPEAKERS_VARIANCE_POPULATION_COURSE_SCHEDULE_ID_ASC',
    CourseScheduleSpeakersVariancePopulationCourseScheduleIdDesc = 'COURSE_SCHEDULE_SPEAKERS_VARIANCE_POPULATION_COURSE_SCHEDULE_ID_DESC',
    CourseScheduleSpeakersVariancePopulationCreatedAtAsc = 'COURSE_SCHEDULE_SPEAKERS_VARIANCE_POPULATION_CREATED_AT_ASC',
    CourseScheduleSpeakersVariancePopulationCreatedAtDesc = 'COURSE_SCHEDULE_SPEAKERS_VARIANCE_POPULATION_CREATED_AT_DESC',
    CourseScheduleSpeakersVariancePopulationUpdatedAtAsc = 'COURSE_SCHEDULE_SPEAKERS_VARIANCE_POPULATION_UPDATED_AT_ASC',
    CourseScheduleSpeakersVariancePopulationUpdatedAtDesc = 'COURSE_SCHEDULE_SPEAKERS_VARIANCE_POPULATION_UPDATED_AT_DESC',
}

export type CourseStddevPopulationAggregates = {
    __typename?: 'CourseStddevPopulationAggregates';
    /** Population standard deviation of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Population standard deviation of defaultLocationId across the matching connection */
    defaultLocationId?: Maybe<Scalars['BigFloat']>;
    /** Population standard deviation of courseGroupId across the matching connection */
    courseGroupId?: Maybe<Scalars['BigFloat']>;
    /** Population standard deviation of defaultSeats across the matching connection */
    defaultSeats?: Maybe<Scalars['BigFloat']>;
    /** Population standard deviation of defaultDuration across the matching connection */
    defaultDuration?: Maybe<Scalars['BigFloat']>;
    /** Population standard deviation of defaultUnits across the matching connection */
    defaultUnits?: Maybe<Scalars['BigFloat']>;
    /** Population standard deviation of defaultPrice across the matching connection */
    defaultPrice?: Maybe<Scalars['Float']>;
    /** Population standard deviation of this field across the matching connection. */
    plannedScheduleCount?: Maybe<Scalars['BigFloat']>;
};

export type CourseStddevSampleAggregates = {
    __typename?: 'CourseStddevSampleAggregates';
    /** Sample standard deviation of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Sample standard deviation of defaultLocationId across the matching connection */
    defaultLocationId?: Maybe<Scalars['BigFloat']>;
    /** Sample standard deviation of courseGroupId across the matching connection */
    courseGroupId?: Maybe<Scalars['BigFloat']>;
    /** Sample standard deviation of defaultSeats across the matching connection */
    defaultSeats?: Maybe<Scalars['BigFloat']>;
    /** Sample standard deviation of defaultDuration across the matching connection */
    defaultDuration?: Maybe<Scalars['BigFloat']>;
    /** Sample standard deviation of defaultUnits across the matching connection */
    defaultUnits?: Maybe<Scalars['BigFloat']>;
    /** Sample standard deviation of defaultPrice across the matching connection */
    defaultPrice?: Maybe<Scalars['Float']>;
    /** Sample standard deviation of this field across the matching connection. */
    plannedScheduleCount?: Maybe<Scalars['BigFloat']>;
};

export type CourseSumAggregates = {
    __typename?: 'CourseSumAggregates';
    /** Sum of id across the matching connection */
    id: Scalars['BigInt'];
    /** Sum of defaultLocationId across the matching connection */
    defaultLocationId: Scalars['BigInt'];
    /** Sum of courseGroupId across the matching connection */
    courseGroupId: Scalars['BigInt'];
    /** Sum of defaultSeats across the matching connection */
    defaultSeats: Scalars['BigInt'];
    /** Sum of defaultDuration across the matching connection */
    defaultDuration: Scalars['BigInt'];
    /** Sum of defaultUnits across the matching connection */
    defaultUnits: Scalars['BigInt'];
    /** Sum of defaultPrice across the matching connection */
    defaultPrice: Scalars['Float'];
    /** Sum of this field across the matching connection. */
    plannedScheduleCount?: Maybe<Scalars['BigInt']>;
};

export type CourseVariancePopulationAggregates = {
    __typename?: 'CourseVariancePopulationAggregates';
    /** Population variance of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Population variance of defaultLocationId across the matching connection */
    defaultLocationId?: Maybe<Scalars['BigFloat']>;
    /** Population variance of courseGroupId across the matching connection */
    courseGroupId?: Maybe<Scalars['BigFloat']>;
    /** Population variance of defaultSeats across the matching connection */
    defaultSeats?: Maybe<Scalars['BigFloat']>;
    /** Population variance of defaultDuration across the matching connection */
    defaultDuration?: Maybe<Scalars['BigFloat']>;
    /** Population variance of defaultUnits across the matching connection */
    defaultUnits?: Maybe<Scalars['BigFloat']>;
    /** Population variance of defaultPrice across the matching connection */
    defaultPrice?: Maybe<Scalars['Float']>;
    /** Population variance of this field across the matching connection. */
    plannedScheduleCount?: Maybe<Scalars['BigFloat']>;
};

export type CourseVarianceSampleAggregates = {
    __typename?: 'CourseVarianceSampleAggregates';
    /** Sample variance of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Sample variance of defaultLocationId across the matching connection */
    defaultLocationId?: Maybe<Scalars['BigFloat']>;
    /** Sample variance of courseGroupId across the matching connection */
    courseGroupId?: Maybe<Scalars['BigFloat']>;
    /** Sample variance of defaultSeats across the matching connection */
    defaultSeats?: Maybe<Scalars['BigFloat']>;
    /** Sample variance of defaultDuration across the matching connection */
    defaultDuration?: Maybe<Scalars['BigFloat']>;
    /** Sample variance of defaultUnits across the matching connection */
    defaultUnits?: Maybe<Scalars['BigFloat']>;
    /** Sample variance of defaultPrice across the matching connection */
    defaultPrice?: Maybe<Scalars['Float']>;
    /** Sample variance of this field across the matching connection. */
    plannedScheduleCount?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Course` values. */
export type CoursesConnection = {
    __typename?: 'CoursesConnection';
    /** A list of `Course` objects. */
    nodes: Array<Course>;
    /** A list of edges which contains the `Course` and cursor to aid in pagination. */
    edges: Array<CoursesEdge>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `Course` you could get from the connection. */
    totalCount: Scalars['Int'];
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<CourseAggregates>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<CourseAggregates>>;
};

/** A connection to a list of `Course` values. */
export type CoursesConnectionGroupedAggregatesArgs = {
    groupBy: Array<CourseGroupBy>;
    having?: Maybe<CourseHavingInput>;
};

/** A `Course` edge in the connection. */
export type CoursesEdge = {
    __typename?: 'CoursesEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `Course` at the end of the edge. */
    node: Course;
};

/** Methods to use when ordering `Course`. */
export enum CoursesOrderBy {
    Natural = 'NATURAL',
    IdAsc = 'ID_ASC',
    IdDesc = 'ID_DESC',
    NameAsc = 'NAME_ASC',
    NameDesc = 'NAME_DESC',
    DescriptionAsc = 'DESCRIPTION_ASC',
    DescriptionDesc = 'DESCRIPTION_DESC',
    CommentAsc = 'COMMENT_ASC',
    CommentDesc = 'COMMENT_DESC',
    DetailsAsc = 'DETAILS_ASC',
    DetailsDesc = 'DETAILS_DESC',
    ArchivedAsc = 'ARCHIVED_ASC',
    ArchivedDesc = 'ARCHIVED_DESC',
    DefaultLocationIdAsc = 'DEFAULT_LOCATION_ID_ASC',
    DefaultLocationIdDesc = 'DEFAULT_LOCATION_ID_DESC',
    DefaultTimetableAsc = 'DEFAULT_TIMETABLE_ASC',
    DefaultTimetableDesc = 'DEFAULT_TIMETABLE_DESC',
    CourseGroupIdAsc = 'COURSE_GROUP_ID_ASC',
    CourseGroupIdDesc = 'COURSE_GROUP_ID_DESC',
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
    UpdatedAtAsc = 'UPDATED_AT_ASC',
    UpdatedAtDesc = 'UPDATED_AT_DESC',
    DefaultSeatsAsc = 'DEFAULT_SEATS_ASC',
    DefaultSeatsDesc = 'DEFAULT_SEATS_DESC',
    DefaultDurationAsc = 'DEFAULT_DURATION_ASC',
    DefaultDurationDesc = 'DEFAULT_DURATION_DESC',
    DefaultUnitsAsc = 'DEFAULT_UNITS_ASC',
    DefaultUnitsDesc = 'DEFAULT_UNITS_DESC',
    DefaultPriceAsc = 'DEFAULT_PRICE_ASC',
    DefaultPriceDesc = 'DEFAULT_PRICE_DESC',
    CertSetupAsc = 'CERT_SETUP_ASC',
    CertSetupDesc = 'CERT_SETUP_DESC',
    ConfirmationContentAsc = 'CONFIRMATION_CONTENT_ASC',
    ConfirmationContentDesc = 'CONFIRMATION_CONTENT_DESC',
    FullNameAsc = 'FULL_NAME_ASC',
    FullNameDesc = 'FULL_NAME_DESC',
    LastDateAsc = 'LAST_DATE_ASC',
    LastDateDesc = 'LAST_DATE_DESC',
    NextDateAsc = 'NEXT_DATE_ASC',
    NextDateDesc = 'NEXT_DATE_DESC',
    PlannedScheduleCountAsc = 'PLANNED_SCHEDULE_COUNT_ASC',
    PlannedScheduleCountDesc = 'PLANNED_SCHEDULE_COUNT_DESC',
    PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
    PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
    CourseSchedulesCountAsc = 'COURSE_SCHEDULES_COUNT_ASC',
    CourseSchedulesCountDesc = 'COURSE_SCHEDULES_COUNT_DESC',
    CourseSchedulesSumIdAsc = 'COURSE_SCHEDULES_SUM_ID_ASC',
    CourseSchedulesSumIdDesc = 'COURSE_SCHEDULES_SUM_ID_DESC',
    CourseSchedulesSumCourseIdAsc = 'COURSE_SCHEDULES_SUM_COURSE_ID_ASC',
    CourseSchedulesSumCourseIdDesc = 'COURSE_SCHEDULES_SUM_COURSE_ID_DESC',
    CourseSchedulesSumStartDateAsc = 'COURSE_SCHEDULES_SUM_START_DATE_ASC',
    CourseSchedulesSumStartDateDesc = 'COURSE_SCHEDULES_SUM_START_DATE_DESC',
    CourseSchedulesSumEndDateAsc = 'COURSE_SCHEDULES_SUM_END_DATE_ASC',
    CourseSchedulesSumEndDateDesc = 'COURSE_SCHEDULES_SUM_END_DATE_DESC',
    CourseSchedulesSumSeatsAsc = 'COURSE_SCHEDULES_SUM_SEATS_ASC',
    CourseSchedulesSumSeatsDesc = 'COURSE_SCHEDULES_SUM_SEATS_DESC',
    CourseSchedulesSumDurationAsc = 'COURSE_SCHEDULES_SUM_DURATION_ASC',
    CourseSchedulesSumDurationDesc = 'COURSE_SCHEDULES_SUM_DURATION_DESC',
    CourseSchedulesSumUnitsAsc = 'COURSE_SCHEDULES_SUM_UNITS_ASC',
    CourseSchedulesSumUnitsDesc = 'COURSE_SCHEDULES_SUM_UNITS_DESC',
    CourseSchedulesSumPriceAsc = 'COURSE_SCHEDULES_SUM_PRICE_ASC',
    CourseSchedulesSumPriceDesc = 'COURSE_SCHEDULES_SUM_PRICE_DESC',
    CourseSchedulesSumTimetableAsc = 'COURSE_SCHEDULES_SUM_TIMETABLE_ASC',
    CourseSchedulesSumTimetableDesc = 'COURSE_SCHEDULES_SUM_TIMETABLE_DESC',
    CourseSchedulesSumCancelledAsc = 'COURSE_SCHEDULES_SUM_CANCELLED_ASC',
    CourseSchedulesSumCancelledDesc = 'COURSE_SCHEDULES_SUM_CANCELLED_DESC',
    CourseSchedulesSumCommentAsc = 'COURSE_SCHEDULES_SUM_COMMENT_ASC',
    CourseSchedulesSumCommentDesc = 'COURSE_SCHEDULES_SUM_COMMENT_DESC',
    CourseSchedulesSumLocationIdAsc = 'COURSE_SCHEDULES_SUM_LOCATION_ID_ASC',
    CourseSchedulesSumLocationIdDesc = 'COURSE_SCHEDULES_SUM_LOCATION_ID_DESC',
    CourseSchedulesSumCreatedAtAsc = 'COURSE_SCHEDULES_SUM_CREATED_AT_ASC',
    CourseSchedulesSumCreatedAtDesc = 'COURSE_SCHEDULES_SUM_CREATED_AT_DESC',
    CourseSchedulesSumUpdatedAtAsc = 'COURSE_SCHEDULES_SUM_UPDATED_AT_ASC',
    CourseSchedulesSumUpdatedAtDesc = 'COURSE_SCHEDULES_SUM_UPDATED_AT_DESC',
    CourseSchedulesSumReminderSentAtAsc = 'COURSE_SCHEDULES_SUM_REMINDER_SENT_AT_ASC',
    CourseSchedulesSumReminderSentAtDesc = 'COURSE_SCHEDULES_SUM_REMINDER_SENT_AT_DESC',
    CourseSchedulesDistinctCountIdAsc = 'COURSE_SCHEDULES_DISTINCT_COUNT_ID_ASC',
    CourseSchedulesDistinctCountIdDesc = 'COURSE_SCHEDULES_DISTINCT_COUNT_ID_DESC',
    CourseSchedulesDistinctCountCourseIdAsc = 'COURSE_SCHEDULES_DISTINCT_COUNT_COURSE_ID_ASC',
    CourseSchedulesDistinctCountCourseIdDesc = 'COURSE_SCHEDULES_DISTINCT_COUNT_COURSE_ID_DESC',
    CourseSchedulesDistinctCountStartDateAsc = 'COURSE_SCHEDULES_DISTINCT_COUNT_START_DATE_ASC',
    CourseSchedulesDistinctCountStartDateDesc = 'COURSE_SCHEDULES_DISTINCT_COUNT_START_DATE_DESC',
    CourseSchedulesDistinctCountEndDateAsc = 'COURSE_SCHEDULES_DISTINCT_COUNT_END_DATE_ASC',
    CourseSchedulesDistinctCountEndDateDesc = 'COURSE_SCHEDULES_DISTINCT_COUNT_END_DATE_DESC',
    CourseSchedulesDistinctCountSeatsAsc = 'COURSE_SCHEDULES_DISTINCT_COUNT_SEATS_ASC',
    CourseSchedulesDistinctCountSeatsDesc = 'COURSE_SCHEDULES_DISTINCT_COUNT_SEATS_DESC',
    CourseSchedulesDistinctCountDurationAsc = 'COURSE_SCHEDULES_DISTINCT_COUNT_DURATION_ASC',
    CourseSchedulesDistinctCountDurationDesc = 'COURSE_SCHEDULES_DISTINCT_COUNT_DURATION_DESC',
    CourseSchedulesDistinctCountUnitsAsc = 'COURSE_SCHEDULES_DISTINCT_COUNT_UNITS_ASC',
    CourseSchedulesDistinctCountUnitsDesc = 'COURSE_SCHEDULES_DISTINCT_COUNT_UNITS_DESC',
    CourseSchedulesDistinctCountPriceAsc = 'COURSE_SCHEDULES_DISTINCT_COUNT_PRICE_ASC',
    CourseSchedulesDistinctCountPriceDesc = 'COURSE_SCHEDULES_DISTINCT_COUNT_PRICE_DESC',
    CourseSchedulesDistinctCountTimetableAsc = 'COURSE_SCHEDULES_DISTINCT_COUNT_TIMETABLE_ASC',
    CourseSchedulesDistinctCountTimetableDesc = 'COURSE_SCHEDULES_DISTINCT_COUNT_TIMETABLE_DESC',
    CourseSchedulesDistinctCountCancelledAsc = 'COURSE_SCHEDULES_DISTINCT_COUNT_CANCELLED_ASC',
    CourseSchedulesDistinctCountCancelledDesc = 'COURSE_SCHEDULES_DISTINCT_COUNT_CANCELLED_DESC',
    CourseSchedulesDistinctCountCommentAsc = 'COURSE_SCHEDULES_DISTINCT_COUNT_COMMENT_ASC',
    CourseSchedulesDistinctCountCommentDesc = 'COURSE_SCHEDULES_DISTINCT_COUNT_COMMENT_DESC',
    CourseSchedulesDistinctCountLocationIdAsc = 'COURSE_SCHEDULES_DISTINCT_COUNT_LOCATION_ID_ASC',
    CourseSchedulesDistinctCountLocationIdDesc = 'COURSE_SCHEDULES_DISTINCT_COUNT_LOCATION_ID_DESC',
    CourseSchedulesDistinctCountCreatedAtAsc = 'COURSE_SCHEDULES_DISTINCT_COUNT_CREATED_AT_ASC',
    CourseSchedulesDistinctCountCreatedAtDesc = 'COURSE_SCHEDULES_DISTINCT_COUNT_CREATED_AT_DESC',
    CourseSchedulesDistinctCountUpdatedAtAsc = 'COURSE_SCHEDULES_DISTINCT_COUNT_UPDATED_AT_ASC',
    CourseSchedulesDistinctCountUpdatedAtDesc = 'COURSE_SCHEDULES_DISTINCT_COUNT_UPDATED_AT_DESC',
    CourseSchedulesDistinctCountReminderSentAtAsc = 'COURSE_SCHEDULES_DISTINCT_COUNT_REMINDER_SENT_AT_ASC',
    CourseSchedulesDistinctCountReminderSentAtDesc = 'COURSE_SCHEDULES_DISTINCT_COUNT_REMINDER_SENT_AT_DESC',
    CourseSchedulesMinIdAsc = 'COURSE_SCHEDULES_MIN_ID_ASC',
    CourseSchedulesMinIdDesc = 'COURSE_SCHEDULES_MIN_ID_DESC',
    CourseSchedulesMinCourseIdAsc = 'COURSE_SCHEDULES_MIN_COURSE_ID_ASC',
    CourseSchedulesMinCourseIdDesc = 'COURSE_SCHEDULES_MIN_COURSE_ID_DESC',
    CourseSchedulesMinStartDateAsc = 'COURSE_SCHEDULES_MIN_START_DATE_ASC',
    CourseSchedulesMinStartDateDesc = 'COURSE_SCHEDULES_MIN_START_DATE_DESC',
    CourseSchedulesMinEndDateAsc = 'COURSE_SCHEDULES_MIN_END_DATE_ASC',
    CourseSchedulesMinEndDateDesc = 'COURSE_SCHEDULES_MIN_END_DATE_DESC',
    CourseSchedulesMinSeatsAsc = 'COURSE_SCHEDULES_MIN_SEATS_ASC',
    CourseSchedulesMinSeatsDesc = 'COURSE_SCHEDULES_MIN_SEATS_DESC',
    CourseSchedulesMinDurationAsc = 'COURSE_SCHEDULES_MIN_DURATION_ASC',
    CourseSchedulesMinDurationDesc = 'COURSE_SCHEDULES_MIN_DURATION_DESC',
    CourseSchedulesMinUnitsAsc = 'COURSE_SCHEDULES_MIN_UNITS_ASC',
    CourseSchedulesMinUnitsDesc = 'COURSE_SCHEDULES_MIN_UNITS_DESC',
    CourseSchedulesMinPriceAsc = 'COURSE_SCHEDULES_MIN_PRICE_ASC',
    CourseSchedulesMinPriceDesc = 'COURSE_SCHEDULES_MIN_PRICE_DESC',
    CourseSchedulesMinTimetableAsc = 'COURSE_SCHEDULES_MIN_TIMETABLE_ASC',
    CourseSchedulesMinTimetableDesc = 'COURSE_SCHEDULES_MIN_TIMETABLE_DESC',
    CourseSchedulesMinCancelledAsc = 'COURSE_SCHEDULES_MIN_CANCELLED_ASC',
    CourseSchedulesMinCancelledDesc = 'COURSE_SCHEDULES_MIN_CANCELLED_DESC',
    CourseSchedulesMinCommentAsc = 'COURSE_SCHEDULES_MIN_COMMENT_ASC',
    CourseSchedulesMinCommentDesc = 'COURSE_SCHEDULES_MIN_COMMENT_DESC',
    CourseSchedulesMinLocationIdAsc = 'COURSE_SCHEDULES_MIN_LOCATION_ID_ASC',
    CourseSchedulesMinLocationIdDesc = 'COURSE_SCHEDULES_MIN_LOCATION_ID_DESC',
    CourseSchedulesMinCreatedAtAsc = 'COURSE_SCHEDULES_MIN_CREATED_AT_ASC',
    CourseSchedulesMinCreatedAtDesc = 'COURSE_SCHEDULES_MIN_CREATED_AT_DESC',
    CourseSchedulesMinUpdatedAtAsc = 'COURSE_SCHEDULES_MIN_UPDATED_AT_ASC',
    CourseSchedulesMinUpdatedAtDesc = 'COURSE_SCHEDULES_MIN_UPDATED_AT_DESC',
    CourseSchedulesMinReminderSentAtAsc = 'COURSE_SCHEDULES_MIN_REMINDER_SENT_AT_ASC',
    CourseSchedulesMinReminderSentAtDesc = 'COURSE_SCHEDULES_MIN_REMINDER_SENT_AT_DESC',
    CourseSchedulesMaxIdAsc = 'COURSE_SCHEDULES_MAX_ID_ASC',
    CourseSchedulesMaxIdDesc = 'COURSE_SCHEDULES_MAX_ID_DESC',
    CourseSchedulesMaxCourseIdAsc = 'COURSE_SCHEDULES_MAX_COURSE_ID_ASC',
    CourseSchedulesMaxCourseIdDesc = 'COURSE_SCHEDULES_MAX_COURSE_ID_DESC',
    CourseSchedulesMaxStartDateAsc = 'COURSE_SCHEDULES_MAX_START_DATE_ASC',
    CourseSchedulesMaxStartDateDesc = 'COURSE_SCHEDULES_MAX_START_DATE_DESC',
    CourseSchedulesMaxEndDateAsc = 'COURSE_SCHEDULES_MAX_END_DATE_ASC',
    CourseSchedulesMaxEndDateDesc = 'COURSE_SCHEDULES_MAX_END_DATE_DESC',
    CourseSchedulesMaxSeatsAsc = 'COURSE_SCHEDULES_MAX_SEATS_ASC',
    CourseSchedulesMaxSeatsDesc = 'COURSE_SCHEDULES_MAX_SEATS_DESC',
    CourseSchedulesMaxDurationAsc = 'COURSE_SCHEDULES_MAX_DURATION_ASC',
    CourseSchedulesMaxDurationDesc = 'COURSE_SCHEDULES_MAX_DURATION_DESC',
    CourseSchedulesMaxUnitsAsc = 'COURSE_SCHEDULES_MAX_UNITS_ASC',
    CourseSchedulesMaxUnitsDesc = 'COURSE_SCHEDULES_MAX_UNITS_DESC',
    CourseSchedulesMaxPriceAsc = 'COURSE_SCHEDULES_MAX_PRICE_ASC',
    CourseSchedulesMaxPriceDesc = 'COURSE_SCHEDULES_MAX_PRICE_DESC',
    CourseSchedulesMaxTimetableAsc = 'COURSE_SCHEDULES_MAX_TIMETABLE_ASC',
    CourseSchedulesMaxTimetableDesc = 'COURSE_SCHEDULES_MAX_TIMETABLE_DESC',
    CourseSchedulesMaxCancelledAsc = 'COURSE_SCHEDULES_MAX_CANCELLED_ASC',
    CourseSchedulesMaxCancelledDesc = 'COURSE_SCHEDULES_MAX_CANCELLED_DESC',
    CourseSchedulesMaxCommentAsc = 'COURSE_SCHEDULES_MAX_COMMENT_ASC',
    CourseSchedulesMaxCommentDesc = 'COURSE_SCHEDULES_MAX_COMMENT_DESC',
    CourseSchedulesMaxLocationIdAsc = 'COURSE_SCHEDULES_MAX_LOCATION_ID_ASC',
    CourseSchedulesMaxLocationIdDesc = 'COURSE_SCHEDULES_MAX_LOCATION_ID_DESC',
    CourseSchedulesMaxCreatedAtAsc = 'COURSE_SCHEDULES_MAX_CREATED_AT_ASC',
    CourseSchedulesMaxCreatedAtDesc = 'COURSE_SCHEDULES_MAX_CREATED_AT_DESC',
    CourseSchedulesMaxUpdatedAtAsc = 'COURSE_SCHEDULES_MAX_UPDATED_AT_ASC',
    CourseSchedulesMaxUpdatedAtDesc = 'COURSE_SCHEDULES_MAX_UPDATED_AT_DESC',
    CourseSchedulesMaxReminderSentAtAsc = 'COURSE_SCHEDULES_MAX_REMINDER_SENT_AT_ASC',
    CourseSchedulesMaxReminderSentAtDesc = 'COURSE_SCHEDULES_MAX_REMINDER_SENT_AT_DESC',
    CourseSchedulesAverageIdAsc = 'COURSE_SCHEDULES_AVERAGE_ID_ASC',
    CourseSchedulesAverageIdDesc = 'COURSE_SCHEDULES_AVERAGE_ID_DESC',
    CourseSchedulesAverageCourseIdAsc = 'COURSE_SCHEDULES_AVERAGE_COURSE_ID_ASC',
    CourseSchedulesAverageCourseIdDesc = 'COURSE_SCHEDULES_AVERAGE_COURSE_ID_DESC',
    CourseSchedulesAverageStartDateAsc = 'COURSE_SCHEDULES_AVERAGE_START_DATE_ASC',
    CourseSchedulesAverageStartDateDesc = 'COURSE_SCHEDULES_AVERAGE_START_DATE_DESC',
    CourseSchedulesAverageEndDateAsc = 'COURSE_SCHEDULES_AVERAGE_END_DATE_ASC',
    CourseSchedulesAverageEndDateDesc = 'COURSE_SCHEDULES_AVERAGE_END_DATE_DESC',
    CourseSchedulesAverageSeatsAsc = 'COURSE_SCHEDULES_AVERAGE_SEATS_ASC',
    CourseSchedulesAverageSeatsDesc = 'COURSE_SCHEDULES_AVERAGE_SEATS_DESC',
    CourseSchedulesAverageDurationAsc = 'COURSE_SCHEDULES_AVERAGE_DURATION_ASC',
    CourseSchedulesAverageDurationDesc = 'COURSE_SCHEDULES_AVERAGE_DURATION_DESC',
    CourseSchedulesAverageUnitsAsc = 'COURSE_SCHEDULES_AVERAGE_UNITS_ASC',
    CourseSchedulesAverageUnitsDesc = 'COURSE_SCHEDULES_AVERAGE_UNITS_DESC',
    CourseSchedulesAveragePriceAsc = 'COURSE_SCHEDULES_AVERAGE_PRICE_ASC',
    CourseSchedulesAveragePriceDesc = 'COURSE_SCHEDULES_AVERAGE_PRICE_DESC',
    CourseSchedulesAverageTimetableAsc = 'COURSE_SCHEDULES_AVERAGE_TIMETABLE_ASC',
    CourseSchedulesAverageTimetableDesc = 'COURSE_SCHEDULES_AVERAGE_TIMETABLE_DESC',
    CourseSchedulesAverageCancelledAsc = 'COURSE_SCHEDULES_AVERAGE_CANCELLED_ASC',
    CourseSchedulesAverageCancelledDesc = 'COURSE_SCHEDULES_AVERAGE_CANCELLED_DESC',
    CourseSchedulesAverageCommentAsc = 'COURSE_SCHEDULES_AVERAGE_COMMENT_ASC',
    CourseSchedulesAverageCommentDesc = 'COURSE_SCHEDULES_AVERAGE_COMMENT_DESC',
    CourseSchedulesAverageLocationIdAsc = 'COURSE_SCHEDULES_AVERAGE_LOCATION_ID_ASC',
    CourseSchedulesAverageLocationIdDesc = 'COURSE_SCHEDULES_AVERAGE_LOCATION_ID_DESC',
    CourseSchedulesAverageCreatedAtAsc = 'COURSE_SCHEDULES_AVERAGE_CREATED_AT_ASC',
    CourseSchedulesAverageCreatedAtDesc = 'COURSE_SCHEDULES_AVERAGE_CREATED_AT_DESC',
    CourseSchedulesAverageUpdatedAtAsc = 'COURSE_SCHEDULES_AVERAGE_UPDATED_AT_ASC',
    CourseSchedulesAverageUpdatedAtDesc = 'COURSE_SCHEDULES_AVERAGE_UPDATED_AT_DESC',
    CourseSchedulesAverageReminderSentAtAsc = 'COURSE_SCHEDULES_AVERAGE_REMINDER_SENT_AT_ASC',
    CourseSchedulesAverageReminderSentAtDesc = 'COURSE_SCHEDULES_AVERAGE_REMINDER_SENT_AT_DESC',
    CourseSchedulesStddevSampleIdAsc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_ID_ASC',
    CourseSchedulesStddevSampleIdDesc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_ID_DESC',
    CourseSchedulesStddevSampleCourseIdAsc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_COURSE_ID_ASC',
    CourseSchedulesStddevSampleCourseIdDesc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_COURSE_ID_DESC',
    CourseSchedulesStddevSampleStartDateAsc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_START_DATE_ASC',
    CourseSchedulesStddevSampleStartDateDesc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_START_DATE_DESC',
    CourseSchedulesStddevSampleEndDateAsc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_END_DATE_ASC',
    CourseSchedulesStddevSampleEndDateDesc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_END_DATE_DESC',
    CourseSchedulesStddevSampleSeatsAsc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_SEATS_ASC',
    CourseSchedulesStddevSampleSeatsDesc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_SEATS_DESC',
    CourseSchedulesStddevSampleDurationAsc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_DURATION_ASC',
    CourseSchedulesStddevSampleDurationDesc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_DURATION_DESC',
    CourseSchedulesStddevSampleUnitsAsc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_UNITS_ASC',
    CourseSchedulesStddevSampleUnitsDesc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_UNITS_DESC',
    CourseSchedulesStddevSamplePriceAsc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_PRICE_ASC',
    CourseSchedulesStddevSamplePriceDesc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_PRICE_DESC',
    CourseSchedulesStddevSampleTimetableAsc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_TIMETABLE_ASC',
    CourseSchedulesStddevSampleTimetableDesc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_TIMETABLE_DESC',
    CourseSchedulesStddevSampleCancelledAsc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_CANCELLED_ASC',
    CourseSchedulesStddevSampleCancelledDesc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_CANCELLED_DESC',
    CourseSchedulesStddevSampleCommentAsc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_COMMENT_ASC',
    CourseSchedulesStddevSampleCommentDesc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_COMMENT_DESC',
    CourseSchedulesStddevSampleLocationIdAsc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_LOCATION_ID_ASC',
    CourseSchedulesStddevSampleLocationIdDesc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_LOCATION_ID_DESC',
    CourseSchedulesStddevSampleCreatedAtAsc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_CREATED_AT_ASC',
    CourseSchedulesStddevSampleCreatedAtDesc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_CREATED_AT_DESC',
    CourseSchedulesStddevSampleUpdatedAtAsc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_UPDATED_AT_ASC',
    CourseSchedulesStddevSampleUpdatedAtDesc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_UPDATED_AT_DESC',
    CourseSchedulesStddevSampleReminderSentAtAsc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_REMINDER_SENT_AT_ASC',
    CourseSchedulesStddevSampleReminderSentAtDesc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_REMINDER_SENT_AT_DESC',
    CourseSchedulesStddevPopulationIdAsc = 'COURSE_SCHEDULES_STDDEV_POPULATION_ID_ASC',
    CourseSchedulesStddevPopulationIdDesc = 'COURSE_SCHEDULES_STDDEV_POPULATION_ID_DESC',
    CourseSchedulesStddevPopulationCourseIdAsc = 'COURSE_SCHEDULES_STDDEV_POPULATION_COURSE_ID_ASC',
    CourseSchedulesStddevPopulationCourseIdDesc = 'COURSE_SCHEDULES_STDDEV_POPULATION_COURSE_ID_DESC',
    CourseSchedulesStddevPopulationStartDateAsc = 'COURSE_SCHEDULES_STDDEV_POPULATION_START_DATE_ASC',
    CourseSchedulesStddevPopulationStartDateDesc = 'COURSE_SCHEDULES_STDDEV_POPULATION_START_DATE_DESC',
    CourseSchedulesStddevPopulationEndDateAsc = 'COURSE_SCHEDULES_STDDEV_POPULATION_END_DATE_ASC',
    CourseSchedulesStddevPopulationEndDateDesc = 'COURSE_SCHEDULES_STDDEV_POPULATION_END_DATE_DESC',
    CourseSchedulesStddevPopulationSeatsAsc = 'COURSE_SCHEDULES_STDDEV_POPULATION_SEATS_ASC',
    CourseSchedulesStddevPopulationSeatsDesc = 'COURSE_SCHEDULES_STDDEV_POPULATION_SEATS_DESC',
    CourseSchedulesStddevPopulationDurationAsc = 'COURSE_SCHEDULES_STDDEV_POPULATION_DURATION_ASC',
    CourseSchedulesStddevPopulationDurationDesc = 'COURSE_SCHEDULES_STDDEV_POPULATION_DURATION_DESC',
    CourseSchedulesStddevPopulationUnitsAsc = 'COURSE_SCHEDULES_STDDEV_POPULATION_UNITS_ASC',
    CourseSchedulesStddevPopulationUnitsDesc = 'COURSE_SCHEDULES_STDDEV_POPULATION_UNITS_DESC',
    CourseSchedulesStddevPopulationPriceAsc = 'COURSE_SCHEDULES_STDDEV_POPULATION_PRICE_ASC',
    CourseSchedulesStddevPopulationPriceDesc = 'COURSE_SCHEDULES_STDDEV_POPULATION_PRICE_DESC',
    CourseSchedulesStddevPopulationTimetableAsc = 'COURSE_SCHEDULES_STDDEV_POPULATION_TIMETABLE_ASC',
    CourseSchedulesStddevPopulationTimetableDesc = 'COURSE_SCHEDULES_STDDEV_POPULATION_TIMETABLE_DESC',
    CourseSchedulesStddevPopulationCancelledAsc = 'COURSE_SCHEDULES_STDDEV_POPULATION_CANCELLED_ASC',
    CourseSchedulesStddevPopulationCancelledDesc = 'COURSE_SCHEDULES_STDDEV_POPULATION_CANCELLED_DESC',
    CourseSchedulesStddevPopulationCommentAsc = 'COURSE_SCHEDULES_STDDEV_POPULATION_COMMENT_ASC',
    CourseSchedulesStddevPopulationCommentDesc = 'COURSE_SCHEDULES_STDDEV_POPULATION_COMMENT_DESC',
    CourseSchedulesStddevPopulationLocationIdAsc = 'COURSE_SCHEDULES_STDDEV_POPULATION_LOCATION_ID_ASC',
    CourseSchedulesStddevPopulationLocationIdDesc = 'COURSE_SCHEDULES_STDDEV_POPULATION_LOCATION_ID_DESC',
    CourseSchedulesStddevPopulationCreatedAtAsc = 'COURSE_SCHEDULES_STDDEV_POPULATION_CREATED_AT_ASC',
    CourseSchedulesStddevPopulationCreatedAtDesc = 'COURSE_SCHEDULES_STDDEV_POPULATION_CREATED_AT_DESC',
    CourseSchedulesStddevPopulationUpdatedAtAsc = 'COURSE_SCHEDULES_STDDEV_POPULATION_UPDATED_AT_ASC',
    CourseSchedulesStddevPopulationUpdatedAtDesc = 'COURSE_SCHEDULES_STDDEV_POPULATION_UPDATED_AT_DESC',
    CourseSchedulesStddevPopulationReminderSentAtAsc = 'COURSE_SCHEDULES_STDDEV_POPULATION_REMINDER_SENT_AT_ASC',
    CourseSchedulesStddevPopulationReminderSentAtDesc = 'COURSE_SCHEDULES_STDDEV_POPULATION_REMINDER_SENT_AT_DESC',
    CourseSchedulesVarianceSampleIdAsc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_ID_ASC',
    CourseSchedulesVarianceSampleIdDesc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_ID_DESC',
    CourseSchedulesVarianceSampleCourseIdAsc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_COURSE_ID_ASC',
    CourseSchedulesVarianceSampleCourseIdDesc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_COURSE_ID_DESC',
    CourseSchedulesVarianceSampleStartDateAsc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_START_DATE_ASC',
    CourseSchedulesVarianceSampleStartDateDesc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_START_DATE_DESC',
    CourseSchedulesVarianceSampleEndDateAsc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_END_DATE_ASC',
    CourseSchedulesVarianceSampleEndDateDesc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_END_DATE_DESC',
    CourseSchedulesVarianceSampleSeatsAsc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_SEATS_ASC',
    CourseSchedulesVarianceSampleSeatsDesc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_SEATS_DESC',
    CourseSchedulesVarianceSampleDurationAsc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_DURATION_ASC',
    CourseSchedulesVarianceSampleDurationDesc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_DURATION_DESC',
    CourseSchedulesVarianceSampleUnitsAsc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_UNITS_ASC',
    CourseSchedulesVarianceSampleUnitsDesc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_UNITS_DESC',
    CourseSchedulesVarianceSamplePriceAsc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_PRICE_ASC',
    CourseSchedulesVarianceSamplePriceDesc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_PRICE_DESC',
    CourseSchedulesVarianceSampleTimetableAsc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_TIMETABLE_ASC',
    CourseSchedulesVarianceSampleTimetableDesc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_TIMETABLE_DESC',
    CourseSchedulesVarianceSampleCancelledAsc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_CANCELLED_ASC',
    CourseSchedulesVarianceSampleCancelledDesc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_CANCELLED_DESC',
    CourseSchedulesVarianceSampleCommentAsc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_COMMENT_ASC',
    CourseSchedulesVarianceSampleCommentDesc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_COMMENT_DESC',
    CourseSchedulesVarianceSampleLocationIdAsc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_LOCATION_ID_ASC',
    CourseSchedulesVarianceSampleLocationIdDesc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_LOCATION_ID_DESC',
    CourseSchedulesVarianceSampleCreatedAtAsc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_CREATED_AT_ASC',
    CourseSchedulesVarianceSampleCreatedAtDesc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_CREATED_AT_DESC',
    CourseSchedulesVarianceSampleUpdatedAtAsc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_UPDATED_AT_ASC',
    CourseSchedulesVarianceSampleUpdatedAtDesc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_UPDATED_AT_DESC',
    CourseSchedulesVarianceSampleReminderSentAtAsc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_REMINDER_SENT_AT_ASC',
    CourseSchedulesVarianceSampleReminderSentAtDesc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_REMINDER_SENT_AT_DESC',
    CourseSchedulesVariancePopulationIdAsc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_ID_ASC',
    CourseSchedulesVariancePopulationIdDesc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_ID_DESC',
    CourseSchedulesVariancePopulationCourseIdAsc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_COURSE_ID_ASC',
    CourseSchedulesVariancePopulationCourseIdDesc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_COURSE_ID_DESC',
    CourseSchedulesVariancePopulationStartDateAsc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_START_DATE_ASC',
    CourseSchedulesVariancePopulationStartDateDesc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_START_DATE_DESC',
    CourseSchedulesVariancePopulationEndDateAsc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_END_DATE_ASC',
    CourseSchedulesVariancePopulationEndDateDesc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_END_DATE_DESC',
    CourseSchedulesVariancePopulationSeatsAsc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_SEATS_ASC',
    CourseSchedulesVariancePopulationSeatsDesc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_SEATS_DESC',
    CourseSchedulesVariancePopulationDurationAsc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_DURATION_ASC',
    CourseSchedulesVariancePopulationDurationDesc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_DURATION_DESC',
    CourseSchedulesVariancePopulationUnitsAsc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_UNITS_ASC',
    CourseSchedulesVariancePopulationUnitsDesc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_UNITS_DESC',
    CourseSchedulesVariancePopulationPriceAsc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_PRICE_ASC',
    CourseSchedulesVariancePopulationPriceDesc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_PRICE_DESC',
    CourseSchedulesVariancePopulationTimetableAsc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_TIMETABLE_ASC',
    CourseSchedulesVariancePopulationTimetableDesc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_TIMETABLE_DESC',
    CourseSchedulesVariancePopulationCancelledAsc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_CANCELLED_ASC',
    CourseSchedulesVariancePopulationCancelledDesc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_CANCELLED_DESC',
    CourseSchedulesVariancePopulationCommentAsc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_COMMENT_ASC',
    CourseSchedulesVariancePopulationCommentDesc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_COMMENT_DESC',
    CourseSchedulesVariancePopulationLocationIdAsc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_LOCATION_ID_ASC',
    CourseSchedulesVariancePopulationLocationIdDesc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_LOCATION_ID_DESC',
    CourseSchedulesVariancePopulationCreatedAtAsc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_CREATED_AT_ASC',
    CourseSchedulesVariancePopulationCreatedAtDesc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_CREATED_AT_DESC',
    CourseSchedulesVariancePopulationUpdatedAtAsc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_UPDATED_AT_ASC',
    CourseSchedulesVariancePopulationUpdatedAtDesc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_UPDATED_AT_DESC',
    CourseSchedulesVariancePopulationReminderSentAtAsc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_REMINDER_SENT_AT_ASC',
    CourseSchedulesVariancePopulationReminderSentAtDesc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_REMINDER_SENT_AT_DESC',
    CourseDefaultSpeakersCountAsc = 'COURSE_DEFAULT_SPEAKERS_COUNT_ASC',
    CourseDefaultSpeakersCountDesc = 'COURSE_DEFAULT_SPEAKERS_COUNT_DESC',
    CourseDefaultSpeakersSumSpeakerIdAsc = 'COURSE_DEFAULT_SPEAKERS_SUM_SPEAKER_ID_ASC',
    CourseDefaultSpeakersSumSpeakerIdDesc = 'COURSE_DEFAULT_SPEAKERS_SUM_SPEAKER_ID_DESC',
    CourseDefaultSpeakersSumCourseIdAsc = 'COURSE_DEFAULT_SPEAKERS_SUM_COURSE_ID_ASC',
    CourseDefaultSpeakersSumCourseIdDesc = 'COURSE_DEFAULT_SPEAKERS_SUM_COURSE_ID_DESC',
    CourseDefaultSpeakersSumCreatedAtAsc = 'COURSE_DEFAULT_SPEAKERS_SUM_CREATED_AT_ASC',
    CourseDefaultSpeakersSumCreatedAtDesc = 'COURSE_DEFAULT_SPEAKERS_SUM_CREATED_AT_DESC',
    CourseDefaultSpeakersSumUpdatedAtAsc = 'COURSE_DEFAULT_SPEAKERS_SUM_UPDATED_AT_ASC',
    CourseDefaultSpeakersSumUpdatedAtDesc = 'COURSE_DEFAULT_SPEAKERS_SUM_UPDATED_AT_DESC',
    CourseDefaultSpeakersDistinctCountSpeakerIdAsc = 'COURSE_DEFAULT_SPEAKERS_DISTINCT_COUNT_SPEAKER_ID_ASC',
    CourseDefaultSpeakersDistinctCountSpeakerIdDesc = 'COURSE_DEFAULT_SPEAKERS_DISTINCT_COUNT_SPEAKER_ID_DESC',
    CourseDefaultSpeakersDistinctCountCourseIdAsc = 'COURSE_DEFAULT_SPEAKERS_DISTINCT_COUNT_COURSE_ID_ASC',
    CourseDefaultSpeakersDistinctCountCourseIdDesc = 'COURSE_DEFAULT_SPEAKERS_DISTINCT_COUNT_COURSE_ID_DESC',
    CourseDefaultSpeakersDistinctCountCreatedAtAsc = 'COURSE_DEFAULT_SPEAKERS_DISTINCT_COUNT_CREATED_AT_ASC',
    CourseDefaultSpeakersDistinctCountCreatedAtDesc = 'COURSE_DEFAULT_SPEAKERS_DISTINCT_COUNT_CREATED_AT_DESC',
    CourseDefaultSpeakersDistinctCountUpdatedAtAsc = 'COURSE_DEFAULT_SPEAKERS_DISTINCT_COUNT_UPDATED_AT_ASC',
    CourseDefaultSpeakersDistinctCountUpdatedAtDesc = 'COURSE_DEFAULT_SPEAKERS_DISTINCT_COUNT_UPDATED_AT_DESC',
    CourseDefaultSpeakersMinSpeakerIdAsc = 'COURSE_DEFAULT_SPEAKERS_MIN_SPEAKER_ID_ASC',
    CourseDefaultSpeakersMinSpeakerIdDesc = 'COURSE_DEFAULT_SPEAKERS_MIN_SPEAKER_ID_DESC',
    CourseDefaultSpeakersMinCourseIdAsc = 'COURSE_DEFAULT_SPEAKERS_MIN_COURSE_ID_ASC',
    CourseDefaultSpeakersMinCourseIdDesc = 'COURSE_DEFAULT_SPEAKERS_MIN_COURSE_ID_DESC',
    CourseDefaultSpeakersMinCreatedAtAsc = 'COURSE_DEFAULT_SPEAKERS_MIN_CREATED_AT_ASC',
    CourseDefaultSpeakersMinCreatedAtDesc = 'COURSE_DEFAULT_SPEAKERS_MIN_CREATED_AT_DESC',
    CourseDefaultSpeakersMinUpdatedAtAsc = 'COURSE_DEFAULT_SPEAKERS_MIN_UPDATED_AT_ASC',
    CourseDefaultSpeakersMinUpdatedAtDesc = 'COURSE_DEFAULT_SPEAKERS_MIN_UPDATED_AT_DESC',
    CourseDefaultSpeakersMaxSpeakerIdAsc = 'COURSE_DEFAULT_SPEAKERS_MAX_SPEAKER_ID_ASC',
    CourseDefaultSpeakersMaxSpeakerIdDesc = 'COURSE_DEFAULT_SPEAKERS_MAX_SPEAKER_ID_DESC',
    CourseDefaultSpeakersMaxCourseIdAsc = 'COURSE_DEFAULT_SPEAKERS_MAX_COURSE_ID_ASC',
    CourseDefaultSpeakersMaxCourseIdDesc = 'COURSE_DEFAULT_SPEAKERS_MAX_COURSE_ID_DESC',
    CourseDefaultSpeakersMaxCreatedAtAsc = 'COURSE_DEFAULT_SPEAKERS_MAX_CREATED_AT_ASC',
    CourseDefaultSpeakersMaxCreatedAtDesc = 'COURSE_DEFAULT_SPEAKERS_MAX_CREATED_AT_DESC',
    CourseDefaultSpeakersMaxUpdatedAtAsc = 'COURSE_DEFAULT_SPEAKERS_MAX_UPDATED_AT_ASC',
    CourseDefaultSpeakersMaxUpdatedAtDesc = 'COURSE_DEFAULT_SPEAKERS_MAX_UPDATED_AT_DESC',
    CourseDefaultSpeakersAverageSpeakerIdAsc = 'COURSE_DEFAULT_SPEAKERS_AVERAGE_SPEAKER_ID_ASC',
    CourseDefaultSpeakersAverageSpeakerIdDesc = 'COURSE_DEFAULT_SPEAKERS_AVERAGE_SPEAKER_ID_DESC',
    CourseDefaultSpeakersAverageCourseIdAsc = 'COURSE_DEFAULT_SPEAKERS_AVERAGE_COURSE_ID_ASC',
    CourseDefaultSpeakersAverageCourseIdDesc = 'COURSE_DEFAULT_SPEAKERS_AVERAGE_COURSE_ID_DESC',
    CourseDefaultSpeakersAverageCreatedAtAsc = 'COURSE_DEFAULT_SPEAKERS_AVERAGE_CREATED_AT_ASC',
    CourseDefaultSpeakersAverageCreatedAtDesc = 'COURSE_DEFAULT_SPEAKERS_AVERAGE_CREATED_AT_DESC',
    CourseDefaultSpeakersAverageUpdatedAtAsc = 'COURSE_DEFAULT_SPEAKERS_AVERAGE_UPDATED_AT_ASC',
    CourseDefaultSpeakersAverageUpdatedAtDesc = 'COURSE_DEFAULT_SPEAKERS_AVERAGE_UPDATED_AT_DESC',
    CourseDefaultSpeakersStddevSampleSpeakerIdAsc = 'COURSE_DEFAULT_SPEAKERS_STDDEV_SAMPLE_SPEAKER_ID_ASC',
    CourseDefaultSpeakersStddevSampleSpeakerIdDesc = 'COURSE_DEFAULT_SPEAKERS_STDDEV_SAMPLE_SPEAKER_ID_DESC',
    CourseDefaultSpeakersStddevSampleCourseIdAsc = 'COURSE_DEFAULT_SPEAKERS_STDDEV_SAMPLE_COURSE_ID_ASC',
    CourseDefaultSpeakersStddevSampleCourseIdDesc = 'COURSE_DEFAULT_SPEAKERS_STDDEV_SAMPLE_COURSE_ID_DESC',
    CourseDefaultSpeakersStddevSampleCreatedAtAsc = 'COURSE_DEFAULT_SPEAKERS_STDDEV_SAMPLE_CREATED_AT_ASC',
    CourseDefaultSpeakersStddevSampleCreatedAtDesc = 'COURSE_DEFAULT_SPEAKERS_STDDEV_SAMPLE_CREATED_AT_DESC',
    CourseDefaultSpeakersStddevSampleUpdatedAtAsc = 'COURSE_DEFAULT_SPEAKERS_STDDEV_SAMPLE_UPDATED_AT_ASC',
    CourseDefaultSpeakersStddevSampleUpdatedAtDesc = 'COURSE_DEFAULT_SPEAKERS_STDDEV_SAMPLE_UPDATED_AT_DESC',
    CourseDefaultSpeakersStddevPopulationSpeakerIdAsc = 'COURSE_DEFAULT_SPEAKERS_STDDEV_POPULATION_SPEAKER_ID_ASC',
    CourseDefaultSpeakersStddevPopulationSpeakerIdDesc = 'COURSE_DEFAULT_SPEAKERS_STDDEV_POPULATION_SPEAKER_ID_DESC',
    CourseDefaultSpeakersStddevPopulationCourseIdAsc = 'COURSE_DEFAULT_SPEAKERS_STDDEV_POPULATION_COURSE_ID_ASC',
    CourseDefaultSpeakersStddevPopulationCourseIdDesc = 'COURSE_DEFAULT_SPEAKERS_STDDEV_POPULATION_COURSE_ID_DESC',
    CourseDefaultSpeakersStddevPopulationCreatedAtAsc = 'COURSE_DEFAULT_SPEAKERS_STDDEV_POPULATION_CREATED_AT_ASC',
    CourseDefaultSpeakersStddevPopulationCreatedAtDesc = 'COURSE_DEFAULT_SPEAKERS_STDDEV_POPULATION_CREATED_AT_DESC',
    CourseDefaultSpeakersStddevPopulationUpdatedAtAsc = 'COURSE_DEFAULT_SPEAKERS_STDDEV_POPULATION_UPDATED_AT_ASC',
    CourseDefaultSpeakersStddevPopulationUpdatedAtDesc = 'COURSE_DEFAULT_SPEAKERS_STDDEV_POPULATION_UPDATED_AT_DESC',
    CourseDefaultSpeakersVarianceSampleSpeakerIdAsc = 'COURSE_DEFAULT_SPEAKERS_VARIANCE_SAMPLE_SPEAKER_ID_ASC',
    CourseDefaultSpeakersVarianceSampleSpeakerIdDesc = 'COURSE_DEFAULT_SPEAKERS_VARIANCE_SAMPLE_SPEAKER_ID_DESC',
    CourseDefaultSpeakersVarianceSampleCourseIdAsc = 'COURSE_DEFAULT_SPEAKERS_VARIANCE_SAMPLE_COURSE_ID_ASC',
    CourseDefaultSpeakersVarianceSampleCourseIdDesc = 'COURSE_DEFAULT_SPEAKERS_VARIANCE_SAMPLE_COURSE_ID_DESC',
    CourseDefaultSpeakersVarianceSampleCreatedAtAsc = 'COURSE_DEFAULT_SPEAKERS_VARIANCE_SAMPLE_CREATED_AT_ASC',
    CourseDefaultSpeakersVarianceSampleCreatedAtDesc = 'COURSE_DEFAULT_SPEAKERS_VARIANCE_SAMPLE_CREATED_AT_DESC',
    CourseDefaultSpeakersVarianceSampleUpdatedAtAsc = 'COURSE_DEFAULT_SPEAKERS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
    CourseDefaultSpeakersVarianceSampleUpdatedAtDesc = 'COURSE_DEFAULT_SPEAKERS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
    CourseDefaultSpeakersVariancePopulationSpeakerIdAsc = 'COURSE_DEFAULT_SPEAKERS_VARIANCE_POPULATION_SPEAKER_ID_ASC',
    CourseDefaultSpeakersVariancePopulationSpeakerIdDesc = 'COURSE_DEFAULT_SPEAKERS_VARIANCE_POPULATION_SPEAKER_ID_DESC',
    CourseDefaultSpeakersVariancePopulationCourseIdAsc = 'COURSE_DEFAULT_SPEAKERS_VARIANCE_POPULATION_COURSE_ID_ASC',
    CourseDefaultSpeakersVariancePopulationCourseIdDesc = 'COURSE_DEFAULT_SPEAKERS_VARIANCE_POPULATION_COURSE_ID_DESC',
    CourseDefaultSpeakersVariancePopulationCreatedAtAsc = 'COURSE_DEFAULT_SPEAKERS_VARIANCE_POPULATION_CREATED_AT_ASC',
    CourseDefaultSpeakersVariancePopulationCreatedAtDesc = 'COURSE_DEFAULT_SPEAKERS_VARIANCE_POPULATION_CREATED_AT_DESC',
    CourseDefaultSpeakersVariancePopulationUpdatedAtAsc = 'COURSE_DEFAULT_SPEAKERS_VARIANCE_POPULATION_UPDATED_AT_ASC',
    CourseDefaultSpeakersVariancePopulationUpdatedAtDesc = 'COURSE_DEFAULT_SPEAKERS_VARIANCE_POPULATION_UPDATED_AT_DESC',
}

/** All input for the `createBookingForParticipant` mutation. */
export type CreateBookingForParticipantInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    selectedCourseScheduleId: Scalars['Int'];
    selectParticipantId: Scalars['Int'];
};

/** The output of our `createBookingForParticipant` mutation. */
export type CreateBookingForParticipantPayload = {
    __typename?: 'CreateBookingForParticipantPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    integer?: Maybe<Scalars['Int']>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
};

/** All input for the create `Booking` mutation. */
export type CreateBookingInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `Booking` to be created by this mutation. */
    booking: BookingInput;
};

/** The output of our create `Booking` mutation. */
export type CreateBookingPayload = {
    __typename?: 'CreateBookingPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `Booking` that was created by this mutation. */
    booking?: Maybe<Booking>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** Reads a single `CourseSchedule` that is related to this `Booking`. */
    courseSchedule?: Maybe<CourseSchedule>;
    /** Reads a single `Participant` that is related to this `Booking`. */
    participant?: Maybe<Participant>;
    /** An edge for our `Booking`. May be used by Relay 1. */
    bookingEdge?: Maybe<BookingsEdge>;
};

/** The output of our create `Booking` mutation. */
export type CreateBookingPayloadBookingEdgeArgs = {
    orderBy?: Maybe<Array<BookingsOrderBy>>;
};

/** All input for the create `CourseDefaultSpeaker` mutation. */
export type CreateCourseDefaultSpeakerInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `CourseDefaultSpeaker` to be created by this mutation. */
    courseDefaultSpeaker: CourseDefaultSpeakerInput;
};

/** The output of our create `CourseDefaultSpeaker` mutation. */
export type CreateCourseDefaultSpeakerPayload = {
    __typename?: 'CreateCourseDefaultSpeakerPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `CourseDefaultSpeaker` that was created by this mutation. */
    courseDefaultSpeaker?: Maybe<CourseDefaultSpeaker>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** Reads a single `Speaker` that is related to this `CourseDefaultSpeaker`. */
    speaker?: Maybe<Speaker>;
    /** Reads a single `Course` that is related to this `CourseDefaultSpeaker`. */
    course?: Maybe<Course>;
    /** An edge for our `CourseDefaultSpeaker`. May be used by Relay 1. */
    courseDefaultSpeakerEdge?: Maybe<CourseDefaultSpeakersEdge>;
};

/** The output of our create `CourseDefaultSpeaker` mutation. */
export type CreateCourseDefaultSpeakerPayloadCourseDefaultSpeakerEdgeArgs = {
    orderBy?: Maybe<Array<CourseDefaultSpeakersOrderBy>>;
};

/** All input for the create `CourseGroup` mutation. */
export type CreateCourseGroupInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `CourseGroup` to be created by this mutation. */
    courseGroup: CourseGroupInput;
};

/** All input for the create `CourseGroupMenu` mutation. */
export type CreateCourseGroupMenuInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `CourseGroupMenu` to be created by this mutation. */
    courseGroupMenu: CourseGroupMenuInput;
};

/** The output of our create `CourseGroupMenu` mutation. */
export type CreateCourseGroupMenuPayload = {
    __typename?: 'CreateCourseGroupMenuPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `CourseGroupMenu` that was created by this mutation. */
    courseGroupMenu?: Maybe<CourseGroupMenu>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** An edge for our `CourseGroupMenu`. May be used by Relay 1. */
    courseGroupMenuEdge?: Maybe<CourseGroupMenusEdge>;
};

/** The output of our create `CourseGroupMenu` mutation. */
export type CreateCourseGroupMenuPayloadCourseGroupMenuEdgeArgs = {
    orderBy?: Maybe<Array<CourseGroupMenusOrderBy>>;
};

/** The output of our create `CourseGroup` mutation. */
export type CreateCourseGroupPayload = {
    __typename?: 'CreateCourseGroupPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `CourseGroup` that was created by this mutation. */
    courseGroup?: Maybe<CourseGroup>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** Reads a single `CourseGroupMenu` that is related to this `CourseGroup`. */
    courseGroupMenu?: Maybe<CourseGroupMenu>;
    /** An edge for our `CourseGroup`. May be used by Relay 1. */
    courseGroupEdge?: Maybe<CourseGroupsEdge>;
};

/** The output of our create `CourseGroup` mutation. */
export type CreateCourseGroupPayloadCourseGroupEdgeArgs = {
    orderBy?: Maybe<Array<CourseGroupsOrderBy>>;
};

/** All input for the create `Course` mutation. */
export type CreateCourseInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `Course` to be created by this mutation. */
    course: CourseInput;
};

/** The output of our create `Course` mutation. */
export type CreateCoursePayload = {
    __typename?: 'CreateCoursePayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `Course` that was created by this mutation. */
    course?: Maybe<Course>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** Reads a single `Location` that is related to this `Course`. */
    defaultLocation?: Maybe<Location>;
    /** Reads a single `CourseGroup` that is related to this `Course`. */
    courseGroup?: Maybe<CourseGroup>;
    /** An edge for our `Course`. May be used by Relay 1. */
    courseEdge?: Maybe<CoursesEdge>;
};

/** The output of our create `Course` mutation. */
export type CreateCoursePayloadCourseEdgeArgs = {
    orderBy?: Maybe<Array<CoursesOrderBy>>;
};

/** All input for the create `CourseSchedule` mutation. */
export type CreateCourseScheduleInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `CourseSchedule` to be created by this mutation. */
    courseSchedule: CourseScheduleInput;
};

/** The output of our create `CourseSchedule` mutation. */
export type CreateCourseSchedulePayload = {
    __typename?: 'CreateCourseSchedulePayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `CourseSchedule` that was created by this mutation. */
    courseSchedule?: Maybe<CourseSchedule>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** Reads a single `Course` that is related to this `CourseSchedule`. */
    course?: Maybe<Course>;
    /** Reads a single `Location` that is related to this `CourseSchedule`. */
    location?: Maybe<Location>;
    /** An edge for our `CourseSchedule`. May be used by Relay 1. */
    courseScheduleEdge?: Maybe<CourseSchedulesEdge>;
};

/** The output of our create `CourseSchedule` mutation. */
export type CreateCourseSchedulePayloadCourseScheduleEdgeArgs = {
    orderBy?: Maybe<Array<CourseSchedulesOrderBy>>;
};

/** All input for the create `CourseScheduleSpeaker` mutation. */
export type CreateCourseScheduleSpeakerInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `CourseScheduleSpeaker` to be created by this mutation. */
    courseScheduleSpeaker: CourseScheduleSpeakerInput;
};

/** The output of our create `CourseScheduleSpeaker` mutation. */
export type CreateCourseScheduleSpeakerPayload = {
    __typename?: 'CreateCourseScheduleSpeakerPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `CourseScheduleSpeaker` that was created by this mutation. */
    courseScheduleSpeaker?: Maybe<CourseScheduleSpeaker>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** Reads a single `Speaker` that is related to this `CourseScheduleSpeaker`. */
    speaker?: Maybe<Speaker>;
    /** Reads a single `CourseSchedule` that is related to this `CourseScheduleSpeaker`. */
    courseSchedule?: Maybe<CourseSchedule>;
    /** An edge for our `CourseScheduleSpeaker`. May be used by Relay 1. */
    courseScheduleSpeakerEdge?: Maybe<CourseScheduleSpeakersEdge>;
};

/** The output of our create `CourseScheduleSpeaker` mutation. */
export type CreateCourseScheduleSpeakerPayloadCourseScheduleSpeakerEdgeArgs = {
    orderBy?: Maybe<Array<CourseScheduleSpeakersOrderBy>>;
};

/** All input for the create `EducationClass` mutation. */
export type CreateEducationClassInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `EducationClass` to be created by this mutation. */
    educationClass: EducationClassInput;
};

/** The output of our create `EducationClass` mutation. */
export type CreateEducationClassPayload = {
    __typename?: 'CreateEducationClassPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `EducationClass` that was created by this mutation. */
    educationClass?: Maybe<EducationClass>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** Reads a single `Education` that is related to this `EducationClass`. */
    education?: Maybe<Education>;
    /** An edge for our `EducationClass`. May be used by Relay 1. */
    educationClassEdge?: Maybe<EducationClassesEdge>;
};

/** The output of our create `EducationClass` mutation. */
export type CreateEducationClassPayloadEducationClassEdgeArgs = {
    orderBy?: Maybe<Array<EducationClassesOrderBy>>;
};

/** All input for the create `EducationClassScheduleEntry` mutation. */
export type CreateEducationClassScheduleEntryInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `EducationClassScheduleEntry` to be created by this mutation. */
    educationClassScheduleEntry: EducationClassScheduleEntryInput;
};

/** The output of our create `EducationClassScheduleEntry` mutation. */
export type CreateEducationClassScheduleEntryPayload = {
    __typename?: 'CreateEducationClassScheduleEntryPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `EducationClassScheduleEntry` that was created by this mutation. */
    educationClassScheduleEntry?: Maybe<EducationClassScheduleEntry>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** Reads a single `EducationClassSchedule` that is related to this `EducationClassScheduleEntry`. */
    educationClassSchedule?: Maybe<EducationClassSchedule>;
    /** Reads a single `Speaker` that is related to this `EducationClassScheduleEntry`. */
    speaker?: Maybe<Speaker>;
    /** An edge for our `EducationClassScheduleEntry`. May be used by Relay 1. */
    educationClassScheduleEntryEdge?: Maybe<EducationClassScheduleEntriesEdge>;
};

/** The output of our create `EducationClassScheduleEntry` mutation. */
export type CreateEducationClassScheduleEntryPayloadEducationClassScheduleEntryEdgeArgs = {
    orderBy?: Maybe<Array<EducationClassScheduleEntriesOrderBy>>;
};

/** All input for the create `EducationClassSchedule` mutation. */
export type CreateEducationClassScheduleInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `EducationClassSchedule` to be created by this mutation. */
    educationClassSchedule: EducationClassScheduleInput;
};

/** The output of our create `EducationClassSchedule` mutation. */
export type CreateEducationClassSchedulePayload = {
    __typename?: 'CreateEducationClassSchedulePayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `EducationClassSchedule` that was created by this mutation. */
    educationClassSchedule?: Maybe<EducationClassSchedule>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** Reads a single `EducationClass` that is related to this `EducationClassSchedule`. */
    educationClass?: Maybe<EducationClass>;
    /** Reads a single `Location` that is related to this `EducationClassSchedule`. */
    location?: Maybe<Location>;
    /** An edge for our `EducationClassSchedule`. May be used by Relay 1. */
    educationClassScheduleEdge?: Maybe<EducationClassSchedulesEdge>;
};

/** The output of our create `EducationClassSchedule` mutation. */
export type CreateEducationClassSchedulePayloadEducationClassScheduleEdgeArgs = {
    orderBy?: Maybe<Array<EducationClassSchedulesOrderBy>>;
};

/** All input for the create `Education` mutation. */
export type CreateEducationInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `Education` to be created by this mutation. */
    education: EducationInput;
};

/** The output of our create `Education` mutation. */
export type CreateEducationPayload = {
    __typename?: 'CreateEducationPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `Education` that was created by this mutation. */
    education?: Maybe<Education>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** An edge for our `Education`. May be used by Relay 1. */
    educationEdge?: Maybe<EducationsEdge>;
};

/** The output of our create `Education` mutation. */
export type CreateEducationPayloadEducationEdgeArgs = {
    orderBy?: Maybe<Array<EducationsOrderBy>>;
};

/** All input for the create `EmailCode` mutation. */
export type CreateEmailCodeInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `EmailCode` to be created by this mutation. */
    emailCode: EmailCodeInput;
};

/** The output of our create `EmailCode` mutation. */
export type CreateEmailCodePayload = {
    __typename?: 'CreateEmailCodePayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `EmailCode` that was created by this mutation. */
    emailCode?: Maybe<EmailCode>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** Reads a single `Participant` that is related to this `EmailCode`. */
    participant?: Maybe<Participant>;
    /** An edge for our `EmailCode`. May be used by Relay 1. */
    emailCodeEdge?: Maybe<EmailCodesEdge>;
};

/** The output of our create `EmailCode` mutation. */
export type CreateEmailCodePayloadEmailCodeEdgeArgs = {
    orderBy?: Maybe<Array<EmailCodesOrderBy>>;
};

/** All input for the create `EmailTemplate` mutation. */
export type CreateEmailTemplateInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `EmailTemplate` to be created by this mutation. */
    emailTemplate: EmailTemplateInput;
};

/** The output of our create `EmailTemplate` mutation. */
export type CreateEmailTemplatePayload = {
    __typename?: 'CreateEmailTemplatePayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `EmailTemplate` that was created by this mutation. */
    emailTemplate?: Maybe<EmailTemplate>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** An edge for our `EmailTemplate`. May be used by Relay 1. */
    emailTemplateEdge?: Maybe<EmailTemplatesEdge>;
};

/** The output of our create `EmailTemplate` mutation. */
export type CreateEmailTemplatePayloadEmailTemplateEdgeArgs = {
    orderBy?: Maybe<Array<EmailTemplatesOrderBy>>;
};

/** All input for the create `Location` mutation. */
export type CreateLocationInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `Location` to be created by this mutation. */
    location: LocationInput;
};

/** The output of our create `Location` mutation. */
export type CreateLocationPayload = {
    __typename?: 'CreateLocationPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `Location` that was created by this mutation. */
    location?: Maybe<Location>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** An edge for our `Location`. May be used by Relay 1. */
    locationEdge?: Maybe<LocationsEdge>;
};

/** The output of our create `Location` mutation. */
export type CreateLocationPayloadLocationEdgeArgs = {
    orderBy?: Maybe<Array<LocationsOrderBy>>;
};

/** All input for the create `Participant` mutation. */
export type CreateParticipantInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `Participant` to be created by this mutation. */
    participant: ParticipantInput;
};

/** All input for the `createParticipantPassedDocuments` mutation. */
export type CreateParticipantPassedDocumentsInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    selectedCourseScheduleId: Scalars['Int'];
};

/** The output of our `createParticipantPassedDocuments` mutation. */
export type CreateParticipantPassedDocumentsPayload = {
    __typename?: 'CreateParticipantPassedDocumentsPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    string?: Maybe<Scalars['String']>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
};

/** The output of our create `Participant` mutation. */
export type CreateParticipantPayload = {
    __typename?: 'CreateParticipantPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `Participant` that was created by this mutation. */
    participant?: Maybe<Participant>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** An edge for our `Participant`. May be used by Relay 1. */
    participantEdge?: Maybe<ParticipantsEdge>;
};

/** The output of our create `Participant` mutation. */
export type CreateParticipantPayloadParticipantEdgeArgs = {
    orderBy?: Maybe<Array<ParticipantsOrderBy>>;
};

/** All input for the `createParticipantsList` mutation. */
export type CreateParticipantsListInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    selectedCourseScheduleId: Scalars['Int'];
    overrideDescription?: Maybe<Scalars['String']>;
    emptyLines?: Maybe<Scalars['Int']>;
};

/** The output of our `createParticipantsList` mutation. */
export type CreateParticipantsListPayload = {
    __typename?: 'CreateParticipantsListPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    string?: Maybe<Scalars['String']>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
};

/** All input for the `createParticipantsSepaXml` mutation. */
export type CreateParticipantsSepaXmlInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    selectedCourseScheduleId: Scalars['Int'];
};

/** The output of our `createParticipantsSepaXml` mutation. */
export type CreateParticipantsSepaXmlPayload = {
    __typename?: 'CreateParticipantsSepaXmlPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    string?: Maybe<Scalars['String']>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
};

/** All input for the create `Setting` mutation. */
export type CreateSettingInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `Setting` to be created by this mutation. */
    setting: SettingInput;
};

/** The output of our create `Setting` mutation. */
export type CreateSettingPayload = {
    __typename?: 'CreateSettingPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `Setting` that was created by this mutation. */
    setting?: Maybe<Setting>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** An edge for our `Setting`. May be used by Relay 1. */
    settingEdge?: Maybe<SettingsEdge>;
};

/** The output of our create `Setting` mutation. */
export type CreateSettingPayloadSettingEdgeArgs = {
    orderBy?: Maybe<Array<SettingsOrderBy>>;
};

/** All input for the `createSingleParticipantCertificateDocument` mutation. */
export type CreateSingleParticipantCertificateDocumentInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    singleParticipantId: Scalars['Int'];
};

/** The output of our `createSingleParticipantCertificateDocument` mutation. */
export type CreateSingleParticipantCertificateDocumentPayload = {
    __typename?: 'CreateSingleParticipantCertificateDocumentPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    string?: Maybe<Scalars['String']>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
};

/** All input for the `createSingleParticipantPassedDocument` mutation. */
export type CreateSingleParticipantPassedDocumentInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    bookingId: Scalars['Int'];
};

/** The output of our `createSingleParticipantPassedDocument` mutation. */
export type CreateSingleParticipantPassedDocumentPayload = {
    __typename?: 'CreateSingleParticipantPassedDocumentPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    string?: Maybe<Scalars['String']>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
};

/** All input for the create `Speaker` mutation. */
export type CreateSpeakerInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `Speaker` to be created by this mutation. */
    speaker: SpeakerInput;
};

/** The output of our create `Speaker` mutation. */
export type CreateSpeakerPayload = {
    __typename?: 'CreateSpeakerPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `Speaker` that was created by this mutation. */
    speaker?: Maybe<Speaker>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** An edge for our `Speaker`. May be used by Relay 1. */
    speakerEdge?: Maybe<SpeakersEdge>;
};

/** The output of our create `Speaker` mutation. */
export type CreateSpeakerPayloadSpeakerEdgeArgs = {
    orderBy?: Maybe<Array<SpeakersOrderBy>>;
};

/** All input for the `createWelcomeCode` mutation. */
export type CreateWelcomeCodeInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    pEmail: Scalars['String'];
    courseScheduleNodeId: Scalars['String'];
};

/** The output of our `createWelcomeCode` mutation. */
export type CreateWelcomeCodePayload = {
    __typename?: 'CreateWelcomeCodePayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    string?: Maybe<Scalars['String']>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
};

/** A filter to be used against Date fields. All fields are combined with a logical ‘and.’ */
export type DateFilter = {
    /** Is null (if `true` is specified) or is not null (if `false` is specified). */
    isNull?: Maybe<Scalars['Boolean']>;
    /** Equal to the specified value. */
    equalTo?: Maybe<Scalars['Date']>;
    /** Not equal to the specified value. */
    notEqualTo?: Maybe<Scalars['Date']>;
    /** Not equal to the specified value, treating null like an ordinary value. */
    distinctFrom?: Maybe<Scalars['Date']>;
    /** Equal to the specified value, treating null like an ordinary value. */
    notDistinctFrom?: Maybe<Scalars['Date']>;
    /** Included in the specified list. */
    in?: Maybe<Array<Scalars['Date']>>;
    /** Not included in the specified list. */
    notIn?: Maybe<Array<Scalars['Date']>>;
    /** Less than the specified value. */
    lessThan?: Maybe<Scalars['Date']>;
    /** Less than or equal to the specified value. */
    lessThanOrEqualTo?: Maybe<Scalars['Date']>;
    /** Greater than the specified value. */
    greaterThan?: Maybe<Scalars['Date']>;
    /** Greater than or equal to the specified value. */
    greaterThanOrEqualTo?: Maybe<Scalars['Date']>;
};

/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
    /** Is null (if `true` is specified) or is not null (if `false` is specified). */
    isNull?: Maybe<Scalars['Boolean']>;
    /** Equal to the specified value. */
    equalTo?: Maybe<Scalars['Datetime']>;
    /** Not equal to the specified value. */
    notEqualTo?: Maybe<Scalars['Datetime']>;
    /** Not equal to the specified value, treating null like an ordinary value. */
    distinctFrom?: Maybe<Scalars['Datetime']>;
    /** Equal to the specified value, treating null like an ordinary value. */
    notDistinctFrom?: Maybe<Scalars['Datetime']>;
    /** Included in the specified list. */
    in?: Maybe<Array<Scalars['Datetime']>>;
    /** Not included in the specified list. */
    notIn?: Maybe<Array<Scalars['Datetime']>>;
    /** Less than the specified value. */
    lessThan?: Maybe<Scalars['Datetime']>;
    /** Less than or equal to the specified value. */
    lessThanOrEqualTo?: Maybe<Scalars['Datetime']>;
    /** Greater than the specified value. */
    greaterThan?: Maybe<Scalars['Datetime']>;
    /** Greater than or equal to the specified value. */
    greaterThanOrEqualTo?: Maybe<Scalars['Datetime']>;
};

/** All input for the `deleteBookingByCode` mutation. */
export type DeleteBookingByCodeInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    code: Scalars['UUID'];
};

/** All input for the `deleteBookingByNodeId` mutation. */
export type DeleteBookingByNodeIdInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The globally unique `ID` which will identify a single `Booking` to be deleted. */
    nodeId: Scalars['ID'];
};

/** All input for the `deleteBooking` mutation. */
export type DeleteBookingInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    id: Scalars['Int'];
};

/** The output of our delete `Booking` mutation. */
export type DeleteBookingPayload = {
    __typename?: 'DeleteBookingPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `Booking` that was deleted by this mutation. */
    booking?: Maybe<Booking>;
    deletedBookingNodeId?: Maybe<Scalars['ID']>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** Reads a single `CourseSchedule` that is related to this `Booking`. */
    courseSchedule?: Maybe<CourseSchedule>;
    /** Reads a single `Participant` that is related to this `Booking`. */
    participant?: Maybe<Participant>;
    /** An edge for our `Booking`. May be used by Relay 1. */
    bookingEdge?: Maybe<BookingsEdge>;
};

/** The output of our delete `Booking` mutation. */
export type DeleteBookingPayloadBookingEdgeArgs = {
    orderBy?: Maybe<Array<BookingsOrderBy>>;
};

/** All input for the `deleteCourseByNodeId` mutation. */
export type DeleteCourseByNodeIdInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The globally unique `ID` which will identify a single `Course` to be deleted. */
    nodeId: Scalars['ID'];
};

/** All input for the `deleteCourseDefaultSpeakerByNodeId` mutation. */
export type DeleteCourseDefaultSpeakerByNodeIdInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The globally unique `ID` which will identify a single `CourseDefaultSpeaker` to be deleted. */
    nodeId: Scalars['ID'];
};

/** All input for the `deleteCourseDefaultSpeaker` mutation. */
export type DeleteCourseDefaultSpeakerInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    speakerId: Scalars['Int'];
    courseId: Scalars['Int'];
};

/** The output of our delete `CourseDefaultSpeaker` mutation. */
export type DeleteCourseDefaultSpeakerPayload = {
    __typename?: 'DeleteCourseDefaultSpeakerPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `CourseDefaultSpeaker` that was deleted by this mutation. */
    courseDefaultSpeaker?: Maybe<CourseDefaultSpeaker>;
    deletedCourseDefaultSpeakerNodeId?: Maybe<Scalars['ID']>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** Reads a single `Speaker` that is related to this `CourseDefaultSpeaker`. */
    speaker?: Maybe<Speaker>;
    /** Reads a single `Course` that is related to this `CourseDefaultSpeaker`. */
    course?: Maybe<Course>;
    /** An edge for our `CourseDefaultSpeaker`. May be used by Relay 1. */
    courseDefaultSpeakerEdge?: Maybe<CourseDefaultSpeakersEdge>;
};

/** The output of our delete `CourseDefaultSpeaker` mutation. */
export type DeleteCourseDefaultSpeakerPayloadCourseDefaultSpeakerEdgeArgs = {
    orderBy?: Maybe<Array<CourseDefaultSpeakersOrderBy>>;
};

/** All input for the `deleteCourseGroupByNodeId` mutation. */
export type DeleteCourseGroupByNodeIdInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The globally unique `ID` which will identify a single `CourseGroup` to be deleted. */
    nodeId: Scalars['ID'];
};

/** All input for the `deleteCourseGroup` mutation. */
export type DeleteCourseGroupInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    id: Scalars['Int'];
};

/** All input for the `deleteCourseGroupMenuByNodeId` mutation. */
export type DeleteCourseGroupMenuByNodeIdInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The globally unique `ID` which will identify a single `CourseGroupMenu` to be deleted. */
    nodeId: Scalars['ID'];
};

/** All input for the `deleteCourseGroupMenu` mutation. */
export type DeleteCourseGroupMenuInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    id: Scalars['Int'];
};

/** The output of our delete `CourseGroupMenu` mutation. */
export type DeleteCourseGroupMenuPayload = {
    __typename?: 'DeleteCourseGroupMenuPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `CourseGroupMenu` that was deleted by this mutation. */
    courseGroupMenu?: Maybe<CourseGroupMenu>;
    deletedCourseGroupMenuNodeId?: Maybe<Scalars['ID']>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** An edge for our `CourseGroupMenu`. May be used by Relay 1. */
    courseGroupMenuEdge?: Maybe<CourseGroupMenusEdge>;
};

/** The output of our delete `CourseGroupMenu` mutation. */
export type DeleteCourseGroupMenuPayloadCourseGroupMenuEdgeArgs = {
    orderBy?: Maybe<Array<CourseGroupMenusOrderBy>>;
};

/** The output of our delete `CourseGroup` mutation. */
export type DeleteCourseGroupPayload = {
    __typename?: 'DeleteCourseGroupPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `CourseGroup` that was deleted by this mutation. */
    courseGroup?: Maybe<CourseGroup>;
    deletedCourseGroupNodeId?: Maybe<Scalars['ID']>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** Reads a single `CourseGroupMenu` that is related to this `CourseGroup`. */
    courseGroupMenu?: Maybe<CourseGroupMenu>;
    /** An edge for our `CourseGroup`. May be used by Relay 1. */
    courseGroupEdge?: Maybe<CourseGroupsEdge>;
};

/** The output of our delete `CourseGroup` mutation. */
export type DeleteCourseGroupPayloadCourseGroupEdgeArgs = {
    orderBy?: Maybe<Array<CourseGroupsOrderBy>>;
};

/** All input for the `deleteCourse` mutation. */
export type DeleteCourseInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    id: Scalars['Int'];
};

/** The output of our delete `Course` mutation. */
export type DeleteCoursePayload = {
    __typename?: 'DeleteCoursePayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `Course` that was deleted by this mutation. */
    course?: Maybe<Course>;
    deletedCourseNodeId?: Maybe<Scalars['ID']>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** Reads a single `Location` that is related to this `Course`. */
    defaultLocation?: Maybe<Location>;
    /** Reads a single `CourseGroup` that is related to this `Course`. */
    courseGroup?: Maybe<CourseGroup>;
    /** An edge for our `Course`. May be used by Relay 1. */
    courseEdge?: Maybe<CoursesEdge>;
};

/** The output of our delete `Course` mutation. */
export type DeleteCoursePayloadCourseEdgeArgs = {
    orderBy?: Maybe<Array<CoursesOrderBy>>;
};

/** All input for the `deleteCourseScheduleByNodeId` mutation. */
export type DeleteCourseScheduleByNodeIdInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The globally unique `ID` which will identify a single `CourseSchedule` to be deleted. */
    nodeId: Scalars['ID'];
};

/** All input for the `deleteCourseSchedule` mutation. */
export type DeleteCourseScheduleInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    id: Scalars['Int'];
};

/** The output of our delete `CourseSchedule` mutation. */
export type DeleteCourseSchedulePayload = {
    __typename?: 'DeleteCourseSchedulePayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `CourseSchedule` that was deleted by this mutation. */
    courseSchedule?: Maybe<CourseSchedule>;
    deletedCourseScheduleNodeId?: Maybe<Scalars['ID']>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** Reads a single `Course` that is related to this `CourseSchedule`. */
    course?: Maybe<Course>;
    /** Reads a single `Location` that is related to this `CourseSchedule`. */
    location?: Maybe<Location>;
    /** An edge for our `CourseSchedule`. May be used by Relay 1. */
    courseScheduleEdge?: Maybe<CourseSchedulesEdge>;
};

/** The output of our delete `CourseSchedule` mutation. */
export type DeleteCourseSchedulePayloadCourseScheduleEdgeArgs = {
    orderBy?: Maybe<Array<CourseSchedulesOrderBy>>;
};

/** All input for the `deleteCourseScheduleSpeakerByNodeId` mutation. */
export type DeleteCourseScheduleSpeakerByNodeIdInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The globally unique `ID` which will identify a single `CourseScheduleSpeaker` to be deleted. */
    nodeId: Scalars['ID'];
};

/** All input for the `deleteCourseScheduleSpeaker` mutation. */
export type DeleteCourseScheduleSpeakerInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    speakerId: Scalars['Int'];
    courseScheduleId: Scalars['Int'];
};

/** The output of our delete `CourseScheduleSpeaker` mutation. */
export type DeleteCourseScheduleSpeakerPayload = {
    __typename?: 'DeleteCourseScheduleSpeakerPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `CourseScheduleSpeaker` that was deleted by this mutation. */
    courseScheduleSpeaker?: Maybe<CourseScheduleSpeaker>;
    deletedCourseScheduleSpeakerNodeId?: Maybe<Scalars['ID']>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** Reads a single `Speaker` that is related to this `CourseScheduleSpeaker`. */
    speaker?: Maybe<Speaker>;
    /** Reads a single `CourseSchedule` that is related to this `CourseScheduleSpeaker`. */
    courseSchedule?: Maybe<CourseSchedule>;
    /** An edge for our `CourseScheduleSpeaker`. May be used by Relay 1. */
    courseScheduleSpeakerEdge?: Maybe<CourseScheduleSpeakersEdge>;
};

/** The output of our delete `CourseScheduleSpeaker` mutation. */
export type DeleteCourseScheduleSpeakerPayloadCourseScheduleSpeakerEdgeArgs = {
    orderBy?: Maybe<Array<CourseScheduleSpeakersOrderBy>>;
};

/** All input for the `deleteEducationByNodeId` mutation. */
export type DeleteEducationByNodeIdInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The globally unique `ID` which will identify a single `Education` to be deleted. */
    nodeId: Scalars['ID'];
};

/** All input for the `deleteEducationClassByNodeId` mutation. */
export type DeleteEducationClassByNodeIdInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The globally unique `ID` which will identify a single `EducationClass` to be deleted. */
    nodeId: Scalars['ID'];
};

/** All input for the `deleteEducationClass` mutation. */
export type DeleteEducationClassInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    id: Scalars['Int'];
};

/** The output of our delete `EducationClass` mutation. */
export type DeleteEducationClassPayload = {
    __typename?: 'DeleteEducationClassPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `EducationClass` that was deleted by this mutation. */
    educationClass?: Maybe<EducationClass>;
    deletedEducationClassNodeId?: Maybe<Scalars['ID']>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** Reads a single `Education` that is related to this `EducationClass`. */
    education?: Maybe<Education>;
    /** An edge for our `EducationClass`. May be used by Relay 1. */
    educationClassEdge?: Maybe<EducationClassesEdge>;
};

/** The output of our delete `EducationClass` mutation. */
export type DeleteEducationClassPayloadEducationClassEdgeArgs = {
    orderBy?: Maybe<Array<EducationClassesOrderBy>>;
};

/** All input for the `deleteEducationClassScheduleByNodeId` mutation. */
export type DeleteEducationClassScheduleByNodeIdInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The globally unique `ID` which will identify a single `EducationClassSchedule` to be deleted. */
    nodeId: Scalars['ID'];
};

/** All input for the `deleteEducationClassScheduleEntryByNodeId` mutation. */
export type DeleteEducationClassScheduleEntryByNodeIdInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The globally unique `ID` which will identify a single `EducationClassScheduleEntry` to be deleted. */
    nodeId: Scalars['ID'];
};

/** All input for the `deleteEducationClassScheduleEntry` mutation. */
export type DeleteEducationClassScheduleEntryInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    id: Scalars['Int'];
};

/** The output of our delete `EducationClassScheduleEntry` mutation. */
export type DeleteEducationClassScheduleEntryPayload = {
    __typename?: 'DeleteEducationClassScheduleEntryPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `EducationClassScheduleEntry` that was deleted by this mutation. */
    educationClassScheduleEntry?: Maybe<EducationClassScheduleEntry>;
    deletedEducationClassScheduleEntryNodeId?: Maybe<Scalars['ID']>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** Reads a single `EducationClassSchedule` that is related to this `EducationClassScheduleEntry`. */
    educationClassSchedule?: Maybe<EducationClassSchedule>;
    /** Reads a single `Speaker` that is related to this `EducationClassScheduleEntry`. */
    speaker?: Maybe<Speaker>;
    /** An edge for our `EducationClassScheduleEntry`. May be used by Relay 1. */
    educationClassScheduleEntryEdge?: Maybe<EducationClassScheduleEntriesEdge>;
};

/** The output of our delete `EducationClassScheduleEntry` mutation. */
export type DeleteEducationClassScheduleEntryPayloadEducationClassScheduleEntryEdgeArgs = {
    orderBy?: Maybe<Array<EducationClassScheduleEntriesOrderBy>>;
};

/** All input for the `deleteEducationClassSchedule` mutation. */
export type DeleteEducationClassScheduleInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    id: Scalars['Int'];
};

/** The output of our delete `EducationClassSchedule` mutation. */
export type DeleteEducationClassSchedulePayload = {
    __typename?: 'DeleteEducationClassSchedulePayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `EducationClassSchedule` that was deleted by this mutation. */
    educationClassSchedule?: Maybe<EducationClassSchedule>;
    deletedEducationClassScheduleNodeId?: Maybe<Scalars['ID']>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** Reads a single `EducationClass` that is related to this `EducationClassSchedule`. */
    educationClass?: Maybe<EducationClass>;
    /** Reads a single `Location` that is related to this `EducationClassSchedule`. */
    location?: Maybe<Location>;
    /** An edge for our `EducationClassSchedule`. May be used by Relay 1. */
    educationClassScheduleEdge?: Maybe<EducationClassSchedulesEdge>;
};

/** The output of our delete `EducationClassSchedule` mutation. */
export type DeleteEducationClassSchedulePayloadEducationClassScheduleEdgeArgs = {
    orderBy?: Maybe<Array<EducationClassSchedulesOrderBy>>;
};

/** All input for the `deleteEducation` mutation. */
export type DeleteEducationInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    id: Scalars['Int'];
};

/** The output of our delete `Education` mutation. */
export type DeleteEducationPayload = {
    __typename?: 'DeleteEducationPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `Education` that was deleted by this mutation. */
    education?: Maybe<Education>;
    deletedEducationNodeId?: Maybe<Scalars['ID']>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** An edge for our `Education`. May be used by Relay 1. */
    educationEdge?: Maybe<EducationsEdge>;
};

/** The output of our delete `Education` mutation. */
export type DeleteEducationPayloadEducationEdgeArgs = {
    orderBy?: Maybe<Array<EducationsOrderBy>>;
};

/** All input for the `deleteEmailCodeByCode` mutation. */
export type DeleteEmailCodeByCodeInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    code: Scalars['UUID'];
};

/** All input for the `deleteEmailCodeByNodeId` mutation. */
export type DeleteEmailCodeByNodeIdInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The globally unique `ID` which will identify a single `EmailCode` to be deleted. */
    nodeId: Scalars['ID'];
};

/** All input for the `deleteEmailCode` mutation. */
export type DeleteEmailCodeInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    id: Scalars['Int'];
};

/** The output of our delete `EmailCode` mutation. */
export type DeleteEmailCodePayload = {
    __typename?: 'DeleteEmailCodePayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `EmailCode` that was deleted by this mutation. */
    emailCode?: Maybe<EmailCode>;
    deletedEmailCodeNodeId?: Maybe<Scalars['ID']>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** Reads a single `Participant` that is related to this `EmailCode`. */
    participant?: Maybe<Participant>;
    /** An edge for our `EmailCode`. May be used by Relay 1. */
    emailCodeEdge?: Maybe<EmailCodesEdge>;
};

/** The output of our delete `EmailCode` mutation. */
export type DeleteEmailCodePayloadEmailCodeEdgeArgs = {
    orderBy?: Maybe<Array<EmailCodesOrderBy>>;
};

/** All input for the `deleteEmailTemplateByNodeId` mutation. */
export type DeleteEmailTemplateByNodeIdInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The globally unique `ID` which will identify a single `EmailTemplate` to be deleted. */
    nodeId: Scalars['ID'];
};

/** All input for the `deleteEmailTemplate` mutation. */
export type DeleteEmailTemplateInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    id: Scalars['Int'];
};

/** The output of our delete `EmailTemplate` mutation. */
export type DeleteEmailTemplatePayload = {
    __typename?: 'DeleteEmailTemplatePayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `EmailTemplate` that was deleted by this mutation. */
    emailTemplate?: Maybe<EmailTemplate>;
    deletedEmailTemplateNodeId?: Maybe<Scalars['ID']>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** An edge for our `EmailTemplate`. May be used by Relay 1. */
    emailTemplateEdge?: Maybe<EmailTemplatesEdge>;
};

/** The output of our delete `EmailTemplate` mutation. */
export type DeleteEmailTemplatePayloadEmailTemplateEdgeArgs = {
    orderBy?: Maybe<Array<EmailTemplatesOrderBy>>;
};

/** All input for the `deleteLocationByNodeId` mutation. */
export type DeleteLocationByNodeIdInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The globally unique `ID` which will identify a single `Location` to be deleted. */
    nodeId: Scalars['ID'];
};

/** All input for the `deleteLocation` mutation. */
export type DeleteLocationInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    id: Scalars['Int'];
};

/** The output of our delete `Location` mutation. */
export type DeleteLocationPayload = {
    __typename?: 'DeleteLocationPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `Location` that was deleted by this mutation. */
    location?: Maybe<Location>;
    deletedLocationNodeId?: Maybe<Scalars['ID']>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** An edge for our `Location`. May be used by Relay 1. */
    locationEdge?: Maybe<LocationsEdge>;
};

/** The output of our delete `Location` mutation. */
export type DeleteLocationPayloadLocationEdgeArgs = {
    orderBy?: Maybe<Array<LocationsOrderBy>>;
};

/** All input for the `deleteParticipantByEmail` mutation. */
export type DeleteParticipantByEmailInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    email: Scalars['String'];
};

/** All input for the `deleteParticipantByNodeId` mutation. */
export type DeleteParticipantByNodeIdInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The globally unique `ID` which will identify a single `Participant` to be deleted. */
    nodeId: Scalars['ID'];
};

/** All input for the `deleteParticipant` mutation. */
export type DeleteParticipantInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    id: Scalars['Int'];
};

/** The output of our delete `Participant` mutation. */
export type DeleteParticipantPayload = {
    __typename?: 'DeleteParticipantPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `Participant` that was deleted by this mutation. */
    participant?: Maybe<Participant>;
    deletedParticipantNodeId?: Maybe<Scalars['ID']>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** An edge for our `Participant`. May be used by Relay 1. */
    participantEdge?: Maybe<ParticipantsEdge>;
};

/** The output of our delete `Participant` mutation. */
export type DeleteParticipantPayloadParticipantEdgeArgs = {
    orderBy?: Maybe<Array<ParticipantsOrderBy>>;
};

/** All input for the `deleteSettingByNodeId` mutation. */
export type DeleteSettingByNodeIdInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The globally unique `ID` which will identify a single `Setting` to be deleted. */
    nodeId: Scalars['ID'];
};

/** All input for the `deleteSetting` mutation. */
export type DeleteSettingInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    id: Scalars['Int'];
};

/** The output of our delete `Setting` mutation. */
export type DeleteSettingPayload = {
    __typename?: 'DeleteSettingPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `Setting` that was deleted by this mutation. */
    setting?: Maybe<Setting>;
    deletedSettingNodeId?: Maybe<Scalars['ID']>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** An edge for our `Setting`. May be used by Relay 1. */
    settingEdge?: Maybe<SettingsEdge>;
};

/** The output of our delete `Setting` mutation. */
export type DeleteSettingPayloadSettingEdgeArgs = {
    orderBy?: Maybe<Array<SettingsOrderBy>>;
};

/** All input for the `deleteSpeakerByNodeId` mutation. */
export type DeleteSpeakerByNodeIdInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The globally unique `ID` which will identify a single `Speaker` to be deleted. */
    nodeId: Scalars['ID'];
};

/** All input for the `deleteSpeaker` mutation. */
export type DeleteSpeakerInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    id: Scalars['Int'];
};

/** The output of our delete `Speaker` mutation. */
export type DeleteSpeakerPayload = {
    __typename?: 'DeleteSpeakerPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `Speaker` that was deleted by this mutation. */
    speaker?: Maybe<Speaker>;
    deletedSpeakerNodeId?: Maybe<Scalars['ID']>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** An edge for our `Speaker`. May be used by Relay 1. */
    speakerEdge?: Maybe<SpeakersEdge>;
};

/** The output of our delete `Speaker` mutation. */
export type DeleteSpeakerPayloadSpeakerEdgeArgs = {
    orderBy?: Maybe<Array<SpeakersOrderBy>>;
};

export type Education = Node & {
    __typename?: 'Education';
    /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
    nodeId: Scalars['ID'];
    id: Scalars['Int'];
    name?: Maybe<Scalars['String']>;
    createdAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    /** Reads and enables pagination through a set of `EducationClass`. */
    educationClasses: EducationClassesConnection;
};

export type EducationEducationClassesArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['Cursor']>;
    after?: Maybe<Scalars['Cursor']>;
    orderBy?: Maybe<Array<EducationClassesOrderBy>>;
    condition?: Maybe<EducationClassCondition>;
    filter?: Maybe<EducationClassFilter>;
};

export type EducationAggregates = {
    __typename?: 'EducationAggregates';
    keys?: Maybe<Array<Scalars['String']>>;
    /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    sum?: Maybe<EducationSumAggregates>;
    /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
    distinctCount?: Maybe<EducationDistinctCountAggregates>;
    /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    min?: Maybe<EducationMinAggregates>;
    /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    max?: Maybe<EducationMaxAggregates>;
    /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
    average?: Maybe<EducationAverageAggregates>;
    /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
    stddevSample?: Maybe<EducationStddevSampleAggregates>;
    /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
    stddevPopulation?: Maybe<EducationStddevPopulationAggregates>;
    /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
    varianceSample?: Maybe<EducationVarianceSampleAggregates>;
    /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
    variancePopulation?: Maybe<EducationVariancePopulationAggregates>;
};

export type EducationAverageAggregates = {
    __typename?: 'EducationAverageAggregates';
    /** Mean average of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
};

export type EducationClass = Node & {
    __typename?: 'EducationClass';
    /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
    nodeId: Scalars['ID'];
    id: Scalars['Int'];
    educationId?: Maybe<Scalars['Int']>;
    name: Scalars['String'];
    createdAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    /** Reads a single `Education` that is related to this `EducationClass`. */
    education?: Maybe<Education>;
    /** Reads and enables pagination through a set of `EducationClassSchedule`. */
    educationClassSchedules: EducationClassSchedulesConnection;
};

export type EducationClassEducationClassSchedulesArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['Cursor']>;
    after?: Maybe<Scalars['Cursor']>;
    orderBy?: Maybe<Array<EducationClassSchedulesOrderBy>>;
    condition?: Maybe<EducationClassScheduleCondition>;
    filter?: Maybe<EducationClassScheduleFilter>;
};

export type EducationClassAggregates = {
    __typename?: 'EducationClassAggregates';
    keys?: Maybe<Array<Scalars['String']>>;
    /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    sum?: Maybe<EducationClassSumAggregates>;
    /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
    distinctCount?: Maybe<EducationClassDistinctCountAggregates>;
    /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    min?: Maybe<EducationClassMinAggregates>;
    /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    max?: Maybe<EducationClassMaxAggregates>;
    /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
    average?: Maybe<EducationClassAverageAggregates>;
    /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
    stddevSample?: Maybe<EducationClassStddevSampleAggregates>;
    /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
    stddevPopulation?: Maybe<EducationClassStddevPopulationAggregates>;
    /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
    varianceSample?: Maybe<EducationClassVarianceSampleAggregates>;
    /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
    variancePopulation?: Maybe<EducationClassVariancePopulationAggregates>;
};

export type EducationClassAverageAggregates = {
    __typename?: 'EducationClassAverageAggregates';
    /** Mean average of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Mean average of educationId across the matching connection */
    educationId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `EducationClass` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type EducationClassCondition = {
    /** Checks for equality with the object’s `id` field. */
    id?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `educationId` field. */
    educationId?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `name` field. */
    name?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `createdAt` field. */
    createdAt?: Maybe<Scalars['Datetime']>;
    /** Checks for equality with the object’s `updatedAt` field. */
    updatedAt?: Maybe<Scalars['Datetime']>;
};

export type EducationClassDistinctCountAggregates = {
    __typename?: 'EducationClassDistinctCountAggregates';
    /** Distinct count of id across the matching connection */
    id?: Maybe<Scalars['BigInt']>;
    /** Distinct count of educationId across the matching connection */
    educationId?: Maybe<Scalars['BigInt']>;
    /** Distinct count of name across the matching connection */
    name?: Maybe<Scalars['BigInt']>;
    /** Distinct count of createdAt across the matching connection */
    createdAt?: Maybe<Scalars['BigInt']>;
    /** Distinct count of updatedAt across the matching connection */
    updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `EducationClass` object types. All fields are combined with a logical ‘and.’ */
export type EducationClassFilter = {
    /** Filter by the object’s `id` field. */
    id?: Maybe<IntFilter>;
    /** Filter by the object’s `educationId` field. */
    educationId?: Maybe<IntFilter>;
    /** Filter by the object’s `name` field. */
    name?: Maybe<StringFilter>;
    /** Filter by the object’s `createdAt` field. */
    createdAt?: Maybe<DatetimeFilter>;
    /** Filter by the object’s `updatedAt` field. */
    updatedAt?: Maybe<DatetimeFilter>;
    /** Checks for all expressions in this list. */
    and?: Maybe<Array<EducationClassFilter>>;
    /** Checks for any expressions in this list. */
    or?: Maybe<Array<EducationClassFilter>>;
    /** Negates the expression. */
    not?: Maybe<EducationClassFilter>;
};

/** Grouping methods for `EducationClass` for usage during aggregation. */
export enum EducationClassGroupBy {
    EducationId = 'EDUCATION_ID',
    Name = 'NAME',
    CreatedAt = 'CREATED_AT',
    CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
    CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
    UpdatedAt = 'UPDATED_AT',
    UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
    UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
}

export type EducationClassHavingAverageInput = {
    id?: Maybe<HavingIntFilter>;
    educationId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EducationClassHavingDistinctCountInput = {
    id?: Maybe<HavingIntFilter>;
    educationId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `EducationClass` aggregates. */
export type EducationClassHavingInput = {
    AND?: Maybe<Array<EducationClassHavingInput>>;
    OR?: Maybe<Array<EducationClassHavingInput>>;
    sum?: Maybe<EducationClassHavingSumInput>;
    distinctCount?: Maybe<EducationClassHavingDistinctCountInput>;
    min?: Maybe<EducationClassHavingMinInput>;
    max?: Maybe<EducationClassHavingMaxInput>;
    average?: Maybe<EducationClassHavingAverageInput>;
    stddevSample?: Maybe<EducationClassHavingStddevSampleInput>;
    stddevPopulation?: Maybe<EducationClassHavingStddevPopulationInput>;
    varianceSample?: Maybe<EducationClassHavingVarianceSampleInput>;
    variancePopulation?: Maybe<EducationClassHavingVariancePopulationInput>;
};

export type EducationClassHavingMaxInput = {
    id?: Maybe<HavingIntFilter>;
    educationId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EducationClassHavingMinInput = {
    id?: Maybe<HavingIntFilter>;
    educationId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EducationClassHavingStddevPopulationInput = {
    id?: Maybe<HavingIntFilter>;
    educationId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EducationClassHavingStddevSampleInput = {
    id?: Maybe<HavingIntFilter>;
    educationId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EducationClassHavingSumInput = {
    id?: Maybe<HavingIntFilter>;
    educationId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EducationClassHavingVariancePopulationInput = {
    id?: Maybe<HavingIntFilter>;
    educationId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EducationClassHavingVarianceSampleInput = {
    id?: Maybe<HavingIntFilter>;
    educationId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `EducationClass` */
export type EducationClassInput = {
    id?: Maybe<Scalars['Int']>;
    educationId?: Maybe<Scalars['Int']>;
    name: Scalars['String'];
    createdAt?: Maybe<Scalars['Datetime']>;
    updatedAt?: Maybe<Scalars['Datetime']>;
};

export type EducationClassMaxAggregates = {
    __typename?: 'EducationClassMaxAggregates';
    /** Maximum of id across the matching connection */
    id?: Maybe<Scalars['Int']>;
    /** Maximum of educationId across the matching connection */
    educationId?: Maybe<Scalars['Int']>;
};

export type EducationClassMinAggregates = {
    __typename?: 'EducationClassMinAggregates';
    /** Minimum of id across the matching connection */
    id?: Maybe<Scalars['Int']>;
    /** Minimum of educationId across the matching connection */
    educationId?: Maybe<Scalars['Int']>;
};

/** Represents an update to a `EducationClass`. Fields that are set will be updated. */
export type EducationClassPatch = {
    id?: Maybe<Scalars['Int']>;
    educationId?: Maybe<Scalars['Int']>;
    name?: Maybe<Scalars['String']>;
    createdAt?: Maybe<Scalars['Datetime']>;
    updatedAt?: Maybe<Scalars['Datetime']>;
};

export type EducationClassSchedule = Node & {
    __typename?: 'EducationClassSchedule';
    /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
    nodeId: Scalars['ID'];
    id: Scalars['Int'];
    educationClassId?: Maybe<Scalars['Int']>;
    startDate: Scalars['Date'];
    endDate: Scalars['Date'];
    timetable?: Maybe<Scalars['String']>;
    locationId?: Maybe<Scalars['Int']>;
    createdAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    courseYear: EducationClassYear;
    /** Reads a single `EducationClass` that is related to this `EducationClassSchedule`. */
    educationClass?: Maybe<EducationClass>;
    /** Reads a single `Location` that is related to this `EducationClassSchedule`. */
    location?: Maybe<Location>;
    /** Reads and enables pagination through a set of `EducationClassScheduleEntry`. */
    educationClassScheduleEntries: EducationClassScheduleEntriesConnection;
    courseYearSortable?: Maybe<Scalars['Int']>;
    dateSchedule?: Maybe<Scalars['String']>;
    scheduleSortable?: Maybe<Scalars['String']>;
};

export type EducationClassScheduleEducationClassScheduleEntriesArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['Cursor']>;
    after?: Maybe<Scalars['Cursor']>;
    orderBy?: Maybe<Array<EducationClassScheduleEntriesOrderBy>>;
    condition?: Maybe<EducationClassScheduleEntryCondition>;
    filter?: Maybe<EducationClassScheduleEntryFilter>;
};

export type EducationClassScheduleAggregates = {
    __typename?: 'EducationClassScheduleAggregates';
    keys?: Maybe<Array<Scalars['String']>>;
    /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    sum?: Maybe<EducationClassScheduleSumAggregates>;
    /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
    distinctCount?: Maybe<EducationClassScheduleDistinctCountAggregates>;
    /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    min?: Maybe<EducationClassScheduleMinAggregates>;
    /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    max?: Maybe<EducationClassScheduleMaxAggregates>;
    /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
    average?: Maybe<EducationClassScheduleAverageAggregates>;
    /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
    stddevSample?: Maybe<EducationClassScheduleStddevSampleAggregates>;
    /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
    stddevPopulation?: Maybe<EducationClassScheduleStddevPopulationAggregates>;
    /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
    varianceSample?: Maybe<EducationClassScheduleVarianceSampleAggregates>;
    /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
    variancePopulation?: Maybe<EducationClassScheduleVariancePopulationAggregates>;
};

export type EducationClassScheduleAverageAggregates = {
    __typename?: 'EducationClassScheduleAverageAggregates';
    /** Mean average of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Mean average of educationClassId across the matching connection */
    educationClassId?: Maybe<Scalars['BigFloat']>;
    /** Mean average of locationId across the matching connection */
    locationId?: Maybe<Scalars['BigFloat']>;
    /** Mean average of this field across the matching connection. */
    courseYearSortable?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `EducationClassSchedule` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type EducationClassScheduleCondition = {
    /** Checks for equality with the object’s `id` field. */
    id?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `educationClassId` field. */
    educationClassId?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `startDate` field. */
    startDate?: Maybe<Scalars['Date']>;
    /** Checks for equality with the object’s `endDate` field. */
    endDate?: Maybe<Scalars['Date']>;
    /** Checks for equality with the object’s `timetable` field. */
    timetable?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `locationId` field. */
    locationId?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `createdAt` field. */
    createdAt?: Maybe<Scalars['Datetime']>;
    /** Checks for equality with the object’s `updatedAt` field. */
    updatedAt?: Maybe<Scalars['Datetime']>;
    /** Checks for equality with the object’s `courseYear` field. */
    courseYear?: Maybe<EducationClassYear>;
    /** Checks for equality with the object’s `courseYearSortable` field. */
    courseYearSortable?: Maybe<Scalars['Int']>;
};

export type EducationClassScheduleDistinctCountAggregates = {
    __typename?: 'EducationClassScheduleDistinctCountAggregates';
    /** Distinct count of id across the matching connection */
    id?: Maybe<Scalars['BigInt']>;
    /** Distinct count of educationClassId across the matching connection */
    educationClassId?: Maybe<Scalars['BigInt']>;
    /** Distinct count of startDate across the matching connection */
    startDate?: Maybe<Scalars['BigInt']>;
    /** Distinct count of endDate across the matching connection */
    endDate?: Maybe<Scalars['BigInt']>;
    /** Distinct count of timetable across the matching connection */
    timetable?: Maybe<Scalars['BigInt']>;
    /** Distinct count of locationId across the matching connection */
    locationId?: Maybe<Scalars['BigInt']>;
    /** Distinct count of createdAt across the matching connection */
    createdAt?: Maybe<Scalars['BigInt']>;
    /** Distinct count of updatedAt across the matching connection */
    updatedAt?: Maybe<Scalars['BigInt']>;
    /** Distinct count of courseYear across the matching connection */
    courseYear?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    courseYearSortable?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    dateSchedule?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    scheduleSortable?: Maybe<Scalars['BigInt']>;
};

/** A connection to a list of `EducationClassScheduleEntry` values. */
export type EducationClassScheduleEntriesConnection = {
    __typename?: 'EducationClassScheduleEntriesConnection';
    /** A list of `EducationClassScheduleEntry` objects. */
    nodes: Array<EducationClassScheduleEntry>;
    /** A list of edges which contains the `EducationClassScheduleEntry` and cursor to aid in pagination. */
    edges: Array<EducationClassScheduleEntriesEdge>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `EducationClassScheduleEntry` you could get from the connection. */
    totalCount: Scalars['Int'];
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<EducationClassScheduleEntryAggregates>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<EducationClassScheduleEntryAggregates>>;
};

/** A connection to a list of `EducationClassScheduleEntry` values. */
export type EducationClassScheduleEntriesConnectionGroupedAggregatesArgs = {
    groupBy: Array<EducationClassScheduleEntryGroupBy>;
    having?: Maybe<EducationClassScheduleEntryHavingInput>;
};

/** A `EducationClassScheduleEntry` edge in the connection. */
export type EducationClassScheduleEntriesEdge = {
    __typename?: 'EducationClassScheduleEntriesEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `EducationClassScheduleEntry` at the end of the edge. */
    node: EducationClassScheduleEntry;
};

/** Methods to use when ordering `EducationClassScheduleEntry`. */
export enum EducationClassScheduleEntriesOrderBy {
    Natural = 'NATURAL',
    IdAsc = 'ID_ASC',
    IdDesc = 'ID_DESC',
    EducationClassScheduleIdAsc = 'EDUCATION_CLASS_SCHEDULE_ID_ASC',
    EducationClassScheduleIdDesc = 'EDUCATION_CLASS_SCHEDULE_ID_DESC',
    DateAsc = 'DATE_ASC',
    DateDesc = 'DATE_DESC',
    SpeakerIdAsc = 'SPEAKER_ID_ASC',
    SpeakerIdDesc = 'SPEAKER_ID_DESC',
    TopicAsc = 'TOPIC_ASC',
    TopicDesc = 'TOPIC_DESC',
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
    UpdatedAtAsc = 'UPDATED_AT_ASC',
    UpdatedAtDesc = 'UPDATED_AT_DESC',
    PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
    PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

export type EducationClassScheduleEntry = Node & {
    __typename?: 'EducationClassScheduleEntry';
    /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
    nodeId: Scalars['ID'];
    id: Scalars['Int'];
    educationClassScheduleId?: Maybe<Scalars['Int']>;
    date: Scalars['Date'];
    speakerId?: Maybe<Scalars['Int']>;
    topic?: Maybe<Scalars['String']>;
    createdAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    /** Reads a single `EducationClassSchedule` that is related to this `EducationClassScheduleEntry`. */
    educationClassSchedule?: Maybe<EducationClassSchedule>;
    /** Reads a single `Speaker` that is related to this `EducationClassScheduleEntry`. */
    speaker?: Maybe<Speaker>;
};

export type EducationClassScheduleEntryAggregates = {
    __typename?: 'EducationClassScheduleEntryAggregates';
    keys?: Maybe<Array<Scalars['String']>>;
    /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    sum?: Maybe<EducationClassScheduleEntrySumAggregates>;
    /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
    distinctCount?: Maybe<EducationClassScheduleEntryDistinctCountAggregates>;
    /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    min?: Maybe<EducationClassScheduleEntryMinAggregates>;
    /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    max?: Maybe<EducationClassScheduleEntryMaxAggregates>;
    /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
    average?: Maybe<EducationClassScheduleEntryAverageAggregates>;
    /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
    stddevSample?: Maybe<EducationClassScheduleEntryStddevSampleAggregates>;
    /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
    stddevPopulation?: Maybe<EducationClassScheduleEntryStddevPopulationAggregates>;
    /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
    varianceSample?: Maybe<EducationClassScheduleEntryVarianceSampleAggregates>;
    /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
    variancePopulation?: Maybe<EducationClassScheduleEntryVariancePopulationAggregates>;
};

export type EducationClassScheduleEntryAverageAggregates = {
    __typename?: 'EducationClassScheduleEntryAverageAggregates';
    /** Mean average of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Mean average of educationClassScheduleId across the matching connection */
    educationClassScheduleId?: Maybe<Scalars['BigFloat']>;
    /** Mean average of speakerId across the matching connection */
    speakerId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `EducationClassScheduleEntry` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type EducationClassScheduleEntryCondition = {
    /** Checks for equality with the object’s `id` field. */
    id?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `educationClassScheduleId` field. */
    educationClassScheduleId?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `date` field. */
    date?: Maybe<Scalars['Date']>;
    /** Checks for equality with the object’s `speakerId` field. */
    speakerId?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `topic` field. */
    topic?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `createdAt` field. */
    createdAt?: Maybe<Scalars['Datetime']>;
    /** Checks for equality with the object’s `updatedAt` field. */
    updatedAt?: Maybe<Scalars['Datetime']>;
};

export type EducationClassScheduleEntryDistinctCountAggregates = {
    __typename?: 'EducationClassScheduleEntryDistinctCountAggregates';
    /** Distinct count of id across the matching connection */
    id?: Maybe<Scalars['BigInt']>;
    /** Distinct count of educationClassScheduleId across the matching connection */
    educationClassScheduleId?: Maybe<Scalars['BigInt']>;
    /** Distinct count of date across the matching connection */
    date?: Maybe<Scalars['BigInt']>;
    /** Distinct count of speakerId across the matching connection */
    speakerId?: Maybe<Scalars['BigInt']>;
    /** Distinct count of topic across the matching connection */
    topic?: Maybe<Scalars['BigInt']>;
    /** Distinct count of createdAt across the matching connection */
    createdAt?: Maybe<Scalars['BigInt']>;
    /** Distinct count of updatedAt across the matching connection */
    updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `EducationClassScheduleEntry` object types. All fields are combined with a logical ‘and.’ */
export type EducationClassScheduleEntryFilter = {
    /** Filter by the object’s `id` field. */
    id?: Maybe<IntFilter>;
    /** Filter by the object’s `educationClassScheduleId` field. */
    educationClassScheduleId?: Maybe<IntFilter>;
    /** Filter by the object’s `date` field. */
    date?: Maybe<DateFilter>;
    /** Filter by the object’s `speakerId` field. */
    speakerId?: Maybe<IntFilter>;
    /** Filter by the object’s `topic` field. */
    topic?: Maybe<StringFilter>;
    /** Filter by the object’s `createdAt` field. */
    createdAt?: Maybe<DatetimeFilter>;
    /** Filter by the object’s `updatedAt` field. */
    updatedAt?: Maybe<DatetimeFilter>;
    /** Checks for all expressions in this list. */
    and?: Maybe<Array<EducationClassScheduleEntryFilter>>;
    /** Checks for any expressions in this list. */
    or?: Maybe<Array<EducationClassScheduleEntryFilter>>;
    /** Negates the expression. */
    not?: Maybe<EducationClassScheduleEntryFilter>;
};

/** Grouping methods for `EducationClassScheduleEntry` for usage during aggregation. */
export enum EducationClassScheduleEntryGroupBy {
    EducationClassScheduleId = 'EDUCATION_CLASS_SCHEDULE_ID',
    Date = 'DATE',
    SpeakerId = 'SPEAKER_ID',
    Topic = 'TOPIC',
    CreatedAt = 'CREATED_AT',
    CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
    CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
    UpdatedAt = 'UPDATED_AT',
    UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
    UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
}

export type EducationClassScheduleEntryHavingAverageInput = {
    id?: Maybe<HavingIntFilter>;
    educationClassScheduleId?: Maybe<HavingIntFilter>;
    date?: Maybe<HavingDatetimeFilter>;
    speakerId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EducationClassScheduleEntryHavingDistinctCountInput = {
    id?: Maybe<HavingIntFilter>;
    educationClassScheduleId?: Maybe<HavingIntFilter>;
    date?: Maybe<HavingDatetimeFilter>;
    speakerId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `EducationClassScheduleEntry` aggregates. */
export type EducationClassScheduleEntryHavingInput = {
    AND?: Maybe<Array<EducationClassScheduleEntryHavingInput>>;
    OR?: Maybe<Array<EducationClassScheduleEntryHavingInput>>;
    sum?: Maybe<EducationClassScheduleEntryHavingSumInput>;
    distinctCount?: Maybe<EducationClassScheduleEntryHavingDistinctCountInput>;
    min?: Maybe<EducationClassScheduleEntryHavingMinInput>;
    max?: Maybe<EducationClassScheduleEntryHavingMaxInput>;
    average?: Maybe<EducationClassScheduleEntryHavingAverageInput>;
    stddevSample?: Maybe<EducationClassScheduleEntryHavingStddevSampleInput>;
    stddevPopulation?: Maybe<EducationClassScheduleEntryHavingStddevPopulationInput>;
    varianceSample?: Maybe<EducationClassScheduleEntryHavingVarianceSampleInput>;
    variancePopulation?: Maybe<EducationClassScheduleEntryHavingVariancePopulationInput>;
};

export type EducationClassScheduleEntryHavingMaxInput = {
    id?: Maybe<HavingIntFilter>;
    educationClassScheduleId?: Maybe<HavingIntFilter>;
    date?: Maybe<HavingDatetimeFilter>;
    speakerId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EducationClassScheduleEntryHavingMinInput = {
    id?: Maybe<HavingIntFilter>;
    educationClassScheduleId?: Maybe<HavingIntFilter>;
    date?: Maybe<HavingDatetimeFilter>;
    speakerId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EducationClassScheduleEntryHavingStddevPopulationInput = {
    id?: Maybe<HavingIntFilter>;
    educationClassScheduleId?: Maybe<HavingIntFilter>;
    date?: Maybe<HavingDatetimeFilter>;
    speakerId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EducationClassScheduleEntryHavingStddevSampleInput = {
    id?: Maybe<HavingIntFilter>;
    educationClassScheduleId?: Maybe<HavingIntFilter>;
    date?: Maybe<HavingDatetimeFilter>;
    speakerId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EducationClassScheduleEntryHavingSumInput = {
    id?: Maybe<HavingIntFilter>;
    educationClassScheduleId?: Maybe<HavingIntFilter>;
    date?: Maybe<HavingDatetimeFilter>;
    speakerId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EducationClassScheduleEntryHavingVariancePopulationInput = {
    id?: Maybe<HavingIntFilter>;
    educationClassScheduleId?: Maybe<HavingIntFilter>;
    date?: Maybe<HavingDatetimeFilter>;
    speakerId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EducationClassScheduleEntryHavingVarianceSampleInput = {
    id?: Maybe<HavingIntFilter>;
    educationClassScheduleId?: Maybe<HavingIntFilter>;
    date?: Maybe<HavingDatetimeFilter>;
    speakerId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `EducationClassScheduleEntry` */
export type EducationClassScheduleEntryInput = {
    id?: Maybe<Scalars['Int']>;
    educationClassScheduleId?: Maybe<Scalars['Int']>;
    date: Scalars['Date'];
    speakerId?: Maybe<Scalars['Int']>;
    topic?: Maybe<Scalars['String']>;
    createdAt?: Maybe<Scalars['Datetime']>;
    updatedAt?: Maybe<Scalars['Datetime']>;
};

export type EducationClassScheduleEntryMaxAggregates = {
    __typename?: 'EducationClassScheduleEntryMaxAggregates';
    /** Maximum of id across the matching connection */
    id?: Maybe<Scalars['Int']>;
    /** Maximum of educationClassScheduleId across the matching connection */
    educationClassScheduleId?: Maybe<Scalars['Int']>;
    /** Maximum of speakerId across the matching connection */
    speakerId?: Maybe<Scalars['Int']>;
};

export type EducationClassScheduleEntryMinAggregates = {
    __typename?: 'EducationClassScheduleEntryMinAggregates';
    /** Minimum of id across the matching connection */
    id?: Maybe<Scalars['Int']>;
    /** Minimum of educationClassScheduleId across the matching connection */
    educationClassScheduleId?: Maybe<Scalars['Int']>;
    /** Minimum of speakerId across the matching connection */
    speakerId?: Maybe<Scalars['Int']>;
};

/** Represents an update to a `EducationClassScheduleEntry`. Fields that are set will be updated. */
export type EducationClassScheduleEntryPatch = {
    id?: Maybe<Scalars['Int']>;
    educationClassScheduleId?: Maybe<Scalars['Int']>;
    date?: Maybe<Scalars['Date']>;
    speakerId?: Maybe<Scalars['Int']>;
    topic?: Maybe<Scalars['String']>;
    createdAt?: Maybe<Scalars['Datetime']>;
    updatedAt?: Maybe<Scalars['Datetime']>;
};

export type EducationClassScheduleEntryStddevPopulationAggregates = {
    __typename?: 'EducationClassScheduleEntryStddevPopulationAggregates';
    /** Population standard deviation of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Population standard deviation of educationClassScheduleId across the matching connection */
    educationClassScheduleId?: Maybe<Scalars['BigFloat']>;
    /** Population standard deviation of speakerId across the matching connection */
    speakerId?: Maybe<Scalars['BigFloat']>;
};

export type EducationClassScheduleEntryStddevSampleAggregates = {
    __typename?: 'EducationClassScheduleEntryStddevSampleAggregates';
    /** Sample standard deviation of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Sample standard deviation of educationClassScheduleId across the matching connection */
    educationClassScheduleId?: Maybe<Scalars['BigFloat']>;
    /** Sample standard deviation of speakerId across the matching connection */
    speakerId?: Maybe<Scalars['BigFloat']>;
};

export type EducationClassScheduleEntrySumAggregates = {
    __typename?: 'EducationClassScheduleEntrySumAggregates';
    /** Sum of id across the matching connection */
    id: Scalars['BigInt'];
    /** Sum of educationClassScheduleId across the matching connection */
    educationClassScheduleId: Scalars['BigInt'];
    /** Sum of speakerId across the matching connection */
    speakerId: Scalars['BigInt'];
};

export type EducationClassScheduleEntryVariancePopulationAggregates = {
    __typename?: 'EducationClassScheduleEntryVariancePopulationAggregates';
    /** Population variance of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Population variance of educationClassScheduleId across the matching connection */
    educationClassScheduleId?: Maybe<Scalars['BigFloat']>;
    /** Population variance of speakerId across the matching connection */
    speakerId?: Maybe<Scalars['BigFloat']>;
};

export type EducationClassScheduleEntryVarianceSampleAggregates = {
    __typename?: 'EducationClassScheduleEntryVarianceSampleAggregates';
    /** Sample variance of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Sample variance of educationClassScheduleId across the matching connection */
    educationClassScheduleId?: Maybe<Scalars['BigFloat']>;
    /** Sample variance of speakerId across the matching connection */
    speakerId?: Maybe<Scalars['BigFloat']>;
};

/** A filter to be used against `EducationClassSchedule` object types. All fields are combined with a logical ‘and.’ */
export type EducationClassScheduleFilter = {
    /** Filter by the object’s `id` field. */
    id?: Maybe<IntFilter>;
    /** Filter by the object’s `educationClassId` field. */
    educationClassId?: Maybe<IntFilter>;
    /** Filter by the object’s `startDate` field. */
    startDate?: Maybe<DateFilter>;
    /** Filter by the object’s `endDate` field. */
    endDate?: Maybe<DateFilter>;
    /** Filter by the object’s `timetable` field. */
    timetable?: Maybe<StringFilter>;
    /** Filter by the object’s `locationId` field. */
    locationId?: Maybe<IntFilter>;
    /** Filter by the object’s `createdAt` field. */
    createdAt?: Maybe<DatetimeFilter>;
    /** Filter by the object’s `updatedAt` field. */
    updatedAt?: Maybe<DatetimeFilter>;
    /** Filter by the object’s `courseYear` field. */
    courseYear?: Maybe<EducationClassYearFilter>;
    /** Filter by the object’s `courseYearSortable` field. */
    courseYearSortable?: Maybe<IntFilter>;
    /** Filter by the object’s `dateSchedule` field. */
    dateSchedule?: Maybe<StringFilter>;
    /** Filter by the object’s `scheduleSortable` field. */
    scheduleSortable?: Maybe<StringFilter>;
    /** Checks for all expressions in this list. */
    and?: Maybe<Array<EducationClassScheduleFilter>>;
    /** Checks for any expressions in this list. */
    or?: Maybe<Array<EducationClassScheduleFilter>>;
    /** Negates the expression. */
    not?: Maybe<EducationClassScheduleFilter>;
};

/** Grouping methods for `EducationClassSchedule` for usage during aggregation. */
export enum EducationClassScheduleGroupBy {
    EducationClassId = 'EDUCATION_CLASS_ID',
    StartDate = 'START_DATE',
    EndDate = 'END_DATE',
    Timetable = 'TIMETABLE',
    LocationId = 'LOCATION_ID',
    CreatedAt = 'CREATED_AT',
    CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
    CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
    UpdatedAt = 'UPDATED_AT',
    UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
    UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
    CourseYear = 'COURSE_YEAR',
}

export type EducationClassScheduleHavingAverageEducationClassScheduleCourseYearSortableInput = {
    filter?: Maybe<HavingIntFilter>;
};

export type EducationClassScheduleHavingAverageInput = {
    id?: Maybe<HavingIntFilter>;
    educationClassId?: Maybe<HavingIntFilter>;
    startDate?: Maybe<HavingDatetimeFilter>;
    endDate?: Maybe<HavingDatetimeFilter>;
    locationId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
    courseYearSortable?: Maybe<EducationClassScheduleHavingAverageEducationClassScheduleCourseYearSortableInput>;
};

export type EducationClassScheduleHavingDistinctCountEducationClassScheduleCourseYearSortableInput = {
    filter?: Maybe<HavingIntFilter>;
};

export type EducationClassScheduleHavingDistinctCountInput = {
    id?: Maybe<HavingIntFilter>;
    educationClassId?: Maybe<HavingIntFilter>;
    startDate?: Maybe<HavingDatetimeFilter>;
    endDate?: Maybe<HavingDatetimeFilter>;
    locationId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
    courseYearSortable?: Maybe<EducationClassScheduleHavingDistinctCountEducationClassScheduleCourseYearSortableInput>;
};

/** Conditions for `EducationClassSchedule` aggregates. */
export type EducationClassScheduleHavingInput = {
    AND?: Maybe<Array<EducationClassScheduleHavingInput>>;
    OR?: Maybe<Array<EducationClassScheduleHavingInput>>;
    sum?: Maybe<EducationClassScheduleHavingSumInput>;
    distinctCount?: Maybe<EducationClassScheduleHavingDistinctCountInput>;
    min?: Maybe<EducationClassScheduleHavingMinInput>;
    max?: Maybe<EducationClassScheduleHavingMaxInput>;
    average?: Maybe<EducationClassScheduleHavingAverageInput>;
    stddevSample?: Maybe<EducationClassScheduleHavingStddevSampleInput>;
    stddevPopulation?: Maybe<EducationClassScheduleHavingStddevPopulationInput>;
    varianceSample?: Maybe<EducationClassScheduleHavingVarianceSampleInput>;
    variancePopulation?: Maybe<EducationClassScheduleHavingVariancePopulationInput>;
};

export type EducationClassScheduleHavingMaxEducationClassScheduleCourseYearSortableInput = {
    filter?: Maybe<HavingIntFilter>;
};

export type EducationClassScheduleHavingMaxInput = {
    id?: Maybe<HavingIntFilter>;
    educationClassId?: Maybe<HavingIntFilter>;
    startDate?: Maybe<HavingDatetimeFilter>;
    endDate?: Maybe<HavingDatetimeFilter>;
    locationId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
    courseYearSortable?: Maybe<EducationClassScheduleHavingMaxEducationClassScheduleCourseYearSortableInput>;
};

export type EducationClassScheduleHavingMinEducationClassScheduleCourseYearSortableInput = {
    filter?: Maybe<HavingIntFilter>;
};

export type EducationClassScheduleHavingMinInput = {
    id?: Maybe<HavingIntFilter>;
    educationClassId?: Maybe<HavingIntFilter>;
    startDate?: Maybe<HavingDatetimeFilter>;
    endDate?: Maybe<HavingDatetimeFilter>;
    locationId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
    courseYearSortable?: Maybe<EducationClassScheduleHavingMinEducationClassScheduleCourseYearSortableInput>;
};

export type EducationClassScheduleHavingStddevPopulationEducationClassScheduleCourseYearSortableInput = {
    filter?: Maybe<HavingIntFilter>;
};

export type EducationClassScheduleHavingStddevPopulationInput = {
    id?: Maybe<HavingIntFilter>;
    educationClassId?: Maybe<HavingIntFilter>;
    startDate?: Maybe<HavingDatetimeFilter>;
    endDate?: Maybe<HavingDatetimeFilter>;
    locationId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
    courseYearSortable?: Maybe<EducationClassScheduleHavingStddevPopulationEducationClassScheduleCourseYearSortableInput>;
};

export type EducationClassScheduleHavingStddevSampleEducationClassScheduleCourseYearSortableInput = {
    filter?: Maybe<HavingIntFilter>;
};

export type EducationClassScheduleHavingStddevSampleInput = {
    id?: Maybe<HavingIntFilter>;
    educationClassId?: Maybe<HavingIntFilter>;
    startDate?: Maybe<HavingDatetimeFilter>;
    endDate?: Maybe<HavingDatetimeFilter>;
    locationId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
    courseYearSortable?: Maybe<EducationClassScheduleHavingStddevSampleEducationClassScheduleCourseYearSortableInput>;
};

export type EducationClassScheduleHavingSumEducationClassScheduleCourseYearSortableInput = {
    filter?: Maybe<HavingIntFilter>;
};

export type EducationClassScheduleHavingSumInput = {
    id?: Maybe<HavingIntFilter>;
    educationClassId?: Maybe<HavingIntFilter>;
    startDate?: Maybe<HavingDatetimeFilter>;
    endDate?: Maybe<HavingDatetimeFilter>;
    locationId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
    courseYearSortable?: Maybe<EducationClassScheduleHavingSumEducationClassScheduleCourseYearSortableInput>;
};

export type EducationClassScheduleHavingVariancePopulationEducationClassScheduleCourseYearSortableInput = {
    filter?: Maybe<HavingIntFilter>;
};

export type EducationClassScheduleHavingVariancePopulationInput = {
    id?: Maybe<HavingIntFilter>;
    educationClassId?: Maybe<HavingIntFilter>;
    startDate?: Maybe<HavingDatetimeFilter>;
    endDate?: Maybe<HavingDatetimeFilter>;
    locationId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
    courseYearSortable?: Maybe<EducationClassScheduleHavingVariancePopulationEducationClassScheduleCourseYearSortableInput>;
};

export type EducationClassScheduleHavingVarianceSampleEducationClassScheduleCourseYearSortableInput = {
    filter?: Maybe<HavingIntFilter>;
};

export type EducationClassScheduleHavingVarianceSampleInput = {
    id?: Maybe<HavingIntFilter>;
    educationClassId?: Maybe<HavingIntFilter>;
    startDate?: Maybe<HavingDatetimeFilter>;
    endDate?: Maybe<HavingDatetimeFilter>;
    locationId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
    courseYearSortable?: Maybe<EducationClassScheduleHavingVarianceSampleEducationClassScheduleCourseYearSortableInput>;
};

/** An input for mutations affecting `EducationClassSchedule` */
export type EducationClassScheduleInput = {
    id?: Maybe<Scalars['Int']>;
    educationClassId?: Maybe<Scalars['Int']>;
    startDate: Scalars['Date'];
    endDate: Scalars['Date'];
    timetable?: Maybe<Scalars['String']>;
    locationId?: Maybe<Scalars['Int']>;
    createdAt?: Maybe<Scalars['Datetime']>;
    updatedAt?: Maybe<Scalars['Datetime']>;
    courseYear?: Maybe<EducationClassYear>;
};

export type EducationClassScheduleMaxAggregates = {
    __typename?: 'EducationClassScheduleMaxAggregates';
    /** Maximum of id across the matching connection */
    id?: Maybe<Scalars['Int']>;
    /** Maximum of educationClassId across the matching connection */
    educationClassId?: Maybe<Scalars['Int']>;
    /** Maximum of locationId across the matching connection */
    locationId?: Maybe<Scalars['Int']>;
    /** Maximum of this field across the matching connection. */
    courseYearSortable?: Maybe<Scalars['Int']>;
};

export type EducationClassScheduleMinAggregates = {
    __typename?: 'EducationClassScheduleMinAggregates';
    /** Minimum of id across the matching connection */
    id?: Maybe<Scalars['Int']>;
    /** Minimum of educationClassId across the matching connection */
    educationClassId?: Maybe<Scalars['Int']>;
    /** Minimum of locationId across the matching connection */
    locationId?: Maybe<Scalars['Int']>;
    /** Minimum of this field across the matching connection. */
    courseYearSortable?: Maybe<Scalars['Int']>;
};

/** Represents an update to a `EducationClassSchedule`. Fields that are set will be updated. */
export type EducationClassSchedulePatch = {
    id?: Maybe<Scalars['Int']>;
    educationClassId?: Maybe<Scalars['Int']>;
    startDate?: Maybe<Scalars['Date']>;
    endDate?: Maybe<Scalars['Date']>;
    timetable?: Maybe<Scalars['String']>;
    locationId?: Maybe<Scalars['Int']>;
    createdAt?: Maybe<Scalars['Datetime']>;
    updatedAt?: Maybe<Scalars['Datetime']>;
    courseYear?: Maybe<EducationClassYear>;
};

export type EducationClassScheduleStddevPopulationAggregates = {
    __typename?: 'EducationClassScheduleStddevPopulationAggregates';
    /** Population standard deviation of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Population standard deviation of educationClassId across the matching connection */
    educationClassId?: Maybe<Scalars['BigFloat']>;
    /** Population standard deviation of locationId across the matching connection */
    locationId?: Maybe<Scalars['BigFloat']>;
    /** Population standard deviation of this field across the matching connection. */
    courseYearSortable?: Maybe<Scalars['BigFloat']>;
};

export type EducationClassScheduleStddevSampleAggregates = {
    __typename?: 'EducationClassScheduleStddevSampleAggregates';
    /** Sample standard deviation of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Sample standard deviation of educationClassId across the matching connection */
    educationClassId?: Maybe<Scalars['BigFloat']>;
    /** Sample standard deviation of locationId across the matching connection */
    locationId?: Maybe<Scalars['BigFloat']>;
    /** Sample standard deviation of this field across the matching connection. */
    courseYearSortable?: Maybe<Scalars['BigFloat']>;
};

export type EducationClassScheduleSumAggregates = {
    __typename?: 'EducationClassScheduleSumAggregates';
    /** Sum of id across the matching connection */
    id: Scalars['BigInt'];
    /** Sum of educationClassId across the matching connection */
    educationClassId: Scalars['BigInt'];
    /** Sum of locationId across the matching connection */
    locationId: Scalars['BigInt'];
    /** Sum of this field across the matching connection. */
    courseYearSortable?: Maybe<Scalars['BigInt']>;
};

export type EducationClassScheduleVariancePopulationAggregates = {
    __typename?: 'EducationClassScheduleVariancePopulationAggregates';
    /** Population variance of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Population variance of educationClassId across the matching connection */
    educationClassId?: Maybe<Scalars['BigFloat']>;
    /** Population variance of locationId across the matching connection */
    locationId?: Maybe<Scalars['BigFloat']>;
    /** Population variance of this field across the matching connection. */
    courseYearSortable?: Maybe<Scalars['BigFloat']>;
};

export type EducationClassScheduleVarianceSampleAggregates = {
    __typename?: 'EducationClassScheduleVarianceSampleAggregates';
    /** Sample variance of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Sample variance of educationClassId across the matching connection */
    educationClassId?: Maybe<Scalars['BigFloat']>;
    /** Sample variance of locationId across the matching connection */
    locationId?: Maybe<Scalars['BigFloat']>;
    /** Sample variance of this field across the matching connection. */
    courseYearSortable?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `EducationClassSchedule` values. */
export type EducationClassSchedulesConnection = {
    __typename?: 'EducationClassSchedulesConnection';
    /** A list of `EducationClassSchedule` objects. */
    nodes: Array<EducationClassSchedule>;
    /** A list of edges which contains the `EducationClassSchedule` and cursor to aid in pagination. */
    edges: Array<EducationClassSchedulesEdge>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `EducationClassSchedule` you could get from the connection. */
    totalCount: Scalars['Int'];
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<EducationClassScheduleAggregates>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<EducationClassScheduleAggregates>>;
};

/** A connection to a list of `EducationClassSchedule` values. */
export type EducationClassSchedulesConnectionGroupedAggregatesArgs = {
    groupBy: Array<EducationClassScheduleGroupBy>;
    having?: Maybe<EducationClassScheduleHavingInput>;
};

/** A `EducationClassSchedule` edge in the connection. */
export type EducationClassSchedulesEdge = {
    __typename?: 'EducationClassSchedulesEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `EducationClassSchedule` at the end of the edge. */
    node: EducationClassSchedule;
};

/** Methods to use when ordering `EducationClassSchedule`. */
export enum EducationClassSchedulesOrderBy {
    Natural = 'NATURAL',
    IdAsc = 'ID_ASC',
    IdDesc = 'ID_DESC',
    EducationClassIdAsc = 'EDUCATION_CLASS_ID_ASC',
    EducationClassIdDesc = 'EDUCATION_CLASS_ID_DESC',
    StartDateAsc = 'START_DATE_ASC',
    StartDateDesc = 'START_DATE_DESC',
    EndDateAsc = 'END_DATE_ASC',
    EndDateDesc = 'END_DATE_DESC',
    TimetableAsc = 'TIMETABLE_ASC',
    TimetableDesc = 'TIMETABLE_DESC',
    LocationIdAsc = 'LOCATION_ID_ASC',
    LocationIdDesc = 'LOCATION_ID_DESC',
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
    UpdatedAtAsc = 'UPDATED_AT_ASC',
    UpdatedAtDesc = 'UPDATED_AT_DESC',
    CourseYearAsc = 'COURSE_YEAR_ASC',
    CourseYearDesc = 'COURSE_YEAR_DESC',
    CourseYearSortableAsc = 'COURSE_YEAR_SORTABLE_ASC',
    CourseYearSortableDesc = 'COURSE_YEAR_SORTABLE_DESC',
    ScheduleSortableAsc = 'SCHEDULE_SORTABLE_ASC',
    ScheduleSortableDesc = 'SCHEDULE_SORTABLE_DESC',
    PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
    PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
    EducationClassScheduleEntriesCountAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_COUNT_ASC',
    EducationClassScheduleEntriesCountDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_COUNT_DESC',
    EducationClassScheduleEntriesSumIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_SUM_ID_ASC',
    EducationClassScheduleEntriesSumIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_SUM_ID_DESC',
    EducationClassScheduleEntriesSumEducationClassScheduleIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_SUM_EDUCATION_CLASS_SCHEDULE_ID_ASC',
    EducationClassScheduleEntriesSumEducationClassScheduleIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_SUM_EDUCATION_CLASS_SCHEDULE_ID_DESC',
    EducationClassScheduleEntriesSumDateAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_SUM_DATE_ASC',
    EducationClassScheduleEntriesSumDateDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_SUM_DATE_DESC',
    EducationClassScheduleEntriesSumSpeakerIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_SUM_SPEAKER_ID_ASC',
    EducationClassScheduleEntriesSumSpeakerIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_SUM_SPEAKER_ID_DESC',
    EducationClassScheduleEntriesSumTopicAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_SUM_TOPIC_ASC',
    EducationClassScheduleEntriesSumTopicDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_SUM_TOPIC_DESC',
    EducationClassScheduleEntriesSumCreatedAtAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_SUM_CREATED_AT_ASC',
    EducationClassScheduleEntriesSumCreatedAtDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_SUM_CREATED_AT_DESC',
    EducationClassScheduleEntriesSumUpdatedAtAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_SUM_UPDATED_AT_ASC',
    EducationClassScheduleEntriesSumUpdatedAtDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_SUM_UPDATED_AT_DESC',
    EducationClassScheduleEntriesDistinctCountIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_DISTINCT_COUNT_ID_ASC',
    EducationClassScheduleEntriesDistinctCountIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_DISTINCT_COUNT_ID_DESC',
    EducationClassScheduleEntriesDistinctCountEducationClassScheduleIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_DISTINCT_COUNT_EDUCATION_CLASS_SCHEDULE_ID_ASC',
    EducationClassScheduleEntriesDistinctCountEducationClassScheduleIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_DISTINCT_COUNT_EDUCATION_CLASS_SCHEDULE_ID_DESC',
    EducationClassScheduleEntriesDistinctCountDateAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_DISTINCT_COUNT_DATE_ASC',
    EducationClassScheduleEntriesDistinctCountDateDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_DISTINCT_COUNT_DATE_DESC',
    EducationClassScheduleEntriesDistinctCountSpeakerIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_DISTINCT_COUNT_SPEAKER_ID_ASC',
    EducationClassScheduleEntriesDistinctCountSpeakerIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_DISTINCT_COUNT_SPEAKER_ID_DESC',
    EducationClassScheduleEntriesDistinctCountTopicAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_DISTINCT_COUNT_TOPIC_ASC',
    EducationClassScheduleEntriesDistinctCountTopicDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_DISTINCT_COUNT_TOPIC_DESC',
    EducationClassScheduleEntriesDistinctCountCreatedAtAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_DISTINCT_COUNT_CREATED_AT_ASC',
    EducationClassScheduleEntriesDistinctCountCreatedAtDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_DISTINCT_COUNT_CREATED_AT_DESC',
    EducationClassScheduleEntriesDistinctCountUpdatedAtAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_DISTINCT_COUNT_UPDATED_AT_ASC',
    EducationClassScheduleEntriesDistinctCountUpdatedAtDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_DISTINCT_COUNT_UPDATED_AT_DESC',
    EducationClassScheduleEntriesMinIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MIN_ID_ASC',
    EducationClassScheduleEntriesMinIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MIN_ID_DESC',
    EducationClassScheduleEntriesMinEducationClassScheduleIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MIN_EDUCATION_CLASS_SCHEDULE_ID_ASC',
    EducationClassScheduleEntriesMinEducationClassScheduleIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MIN_EDUCATION_CLASS_SCHEDULE_ID_DESC',
    EducationClassScheduleEntriesMinDateAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MIN_DATE_ASC',
    EducationClassScheduleEntriesMinDateDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MIN_DATE_DESC',
    EducationClassScheduleEntriesMinSpeakerIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MIN_SPEAKER_ID_ASC',
    EducationClassScheduleEntriesMinSpeakerIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MIN_SPEAKER_ID_DESC',
    EducationClassScheduleEntriesMinTopicAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MIN_TOPIC_ASC',
    EducationClassScheduleEntriesMinTopicDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MIN_TOPIC_DESC',
    EducationClassScheduleEntriesMinCreatedAtAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MIN_CREATED_AT_ASC',
    EducationClassScheduleEntriesMinCreatedAtDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MIN_CREATED_AT_DESC',
    EducationClassScheduleEntriesMinUpdatedAtAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MIN_UPDATED_AT_ASC',
    EducationClassScheduleEntriesMinUpdatedAtDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MIN_UPDATED_AT_DESC',
    EducationClassScheduleEntriesMaxIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MAX_ID_ASC',
    EducationClassScheduleEntriesMaxIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MAX_ID_DESC',
    EducationClassScheduleEntriesMaxEducationClassScheduleIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MAX_EDUCATION_CLASS_SCHEDULE_ID_ASC',
    EducationClassScheduleEntriesMaxEducationClassScheduleIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MAX_EDUCATION_CLASS_SCHEDULE_ID_DESC',
    EducationClassScheduleEntriesMaxDateAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MAX_DATE_ASC',
    EducationClassScheduleEntriesMaxDateDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MAX_DATE_DESC',
    EducationClassScheduleEntriesMaxSpeakerIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MAX_SPEAKER_ID_ASC',
    EducationClassScheduleEntriesMaxSpeakerIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MAX_SPEAKER_ID_DESC',
    EducationClassScheduleEntriesMaxTopicAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MAX_TOPIC_ASC',
    EducationClassScheduleEntriesMaxTopicDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MAX_TOPIC_DESC',
    EducationClassScheduleEntriesMaxCreatedAtAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MAX_CREATED_AT_ASC',
    EducationClassScheduleEntriesMaxCreatedAtDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MAX_CREATED_AT_DESC',
    EducationClassScheduleEntriesMaxUpdatedAtAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MAX_UPDATED_AT_ASC',
    EducationClassScheduleEntriesMaxUpdatedAtDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MAX_UPDATED_AT_DESC',
    EducationClassScheduleEntriesAverageIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_AVERAGE_ID_ASC',
    EducationClassScheduleEntriesAverageIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_AVERAGE_ID_DESC',
    EducationClassScheduleEntriesAverageEducationClassScheduleIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_AVERAGE_EDUCATION_CLASS_SCHEDULE_ID_ASC',
    EducationClassScheduleEntriesAverageEducationClassScheduleIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_AVERAGE_EDUCATION_CLASS_SCHEDULE_ID_DESC',
    EducationClassScheduleEntriesAverageDateAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_AVERAGE_DATE_ASC',
    EducationClassScheduleEntriesAverageDateDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_AVERAGE_DATE_DESC',
    EducationClassScheduleEntriesAverageSpeakerIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_AVERAGE_SPEAKER_ID_ASC',
    EducationClassScheduleEntriesAverageSpeakerIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_AVERAGE_SPEAKER_ID_DESC',
    EducationClassScheduleEntriesAverageTopicAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_AVERAGE_TOPIC_ASC',
    EducationClassScheduleEntriesAverageTopicDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_AVERAGE_TOPIC_DESC',
    EducationClassScheduleEntriesAverageCreatedAtAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_AVERAGE_CREATED_AT_ASC',
    EducationClassScheduleEntriesAverageCreatedAtDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_AVERAGE_CREATED_AT_DESC',
    EducationClassScheduleEntriesAverageUpdatedAtAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_AVERAGE_UPDATED_AT_ASC',
    EducationClassScheduleEntriesAverageUpdatedAtDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_AVERAGE_UPDATED_AT_DESC',
    EducationClassScheduleEntriesStddevSampleIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_SAMPLE_ID_ASC',
    EducationClassScheduleEntriesStddevSampleIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_SAMPLE_ID_DESC',
    EducationClassScheduleEntriesStddevSampleEducationClassScheduleIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_SAMPLE_EDUCATION_CLASS_SCHEDULE_ID_ASC',
    EducationClassScheduleEntriesStddevSampleEducationClassScheduleIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_SAMPLE_EDUCATION_CLASS_SCHEDULE_ID_DESC',
    EducationClassScheduleEntriesStddevSampleDateAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_SAMPLE_DATE_ASC',
    EducationClassScheduleEntriesStddevSampleDateDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_SAMPLE_DATE_DESC',
    EducationClassScheduleEntriesStddevSampleSpeakerIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_SAMPLE_SPEAKER_ID_ASC',
    EducationClassScheduleEntriesStddevSampleSpeakerIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_SAMPLE_SPEAKER_ID_DESC',
    EducationClassScheduleEntriesStddevSampleTopicAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_SAMPLE_TOPIC_ASC',
    EducationClassScheduleEntriesStddevSampleTopicDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_SAMPLE_TOPIC_DESC',
    EducationClassScheduleEntriesStddevSampleCreatedAtAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_SAMPLE_CREATED_AT_ASC',
    EducationClassScheduleEntriesStddevSampleCreatedAtDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_SAMPLE_CREATED_AT_DESC',
    EducationClassScheduleEntriesStddevSampleUpdatedAtAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_SAMPLE_UPDATED_AT_ASC',
    EducationClassScheduleEntriesStddevSampleUpdatedAtDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_SAMPLE_UPDATED_AT_DESC',
    EducationClassScheduleEntriesStddevPopulationIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_POPULATION_ID_ASC',
    EducationClassScheduleEntriesStddevPopulationIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_POPULATION_ID_DESC',
    EducationClassScheduleEntriesStddevPopulationEducationClassScheduleIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_POPULATION_EDUCATION_CLASS_SCHEDULE_ID_ASC',
    EducationClassScheduleEntriesStddevPopulationEducationClassScheduleIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_POPULATION_EDUCATION_CLASS_SCHEDULE_ID_DESC',
    EducationClassScheduleEntriesStddevPopulationDateAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_POPULATION_DATE_ASC',
    EducationClassScheduleEntriesStddevPopulationDateDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_POPULATION_DATE_DESC',
    EducationClassScheduleEntriesStddevPopulationSpeakerIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_POPULATION_SPEAKER_ID_ASC',
    EducationClassScheduleEntriesStddevPopulationSpeakerIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_POPULATION_SPEAKER_ID_DESC',
    EducationClassScheduleEntriesStddevPopulationTopicAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_POPULATION_TOPIC_ASC',
    EducationClassScheduleEntriesStddevPopulationTopicDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_POPULATION_TOPIC_DESC',
    EducationClassScheduleEntriesStddevPopulationCreatedAtAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_POPULATION_CREATED_AT_ASC',
    EducationClassScheduleEntriesStddevPopulationCreatedAtDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_POPULATION_CREATED_AT_DESC',
    EducationClassScheduleEntriesStddevPopulationUpdatedAtAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_POPULATION_UPDATED_AT_ASC',
    EducationClassScheduleEntriesStddevPopulationUpdatedAtDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_POPULATION_UPDATED_AT_DESC',
    EducationClassScheduleEntriesVarianceSampleIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_SAMPLE_ID_ASC',
    EducationClassScheduleEntriesVarianceSampleIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_SAMPLE_ID_DESC',
    EducationClassScheduleEntriesVarianceSampleEducationClassScheduleIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_SAMPLE_EDUCATION_CLASS_SCHEDULE_ID_ASC',
    EducationClassScheduleEntriesVarianceSampleEducationClassScheduleIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_SAMPLE_EDUCATION_CLASS_SCHEDULE_ID_DESC',
    EducationClassScheduleEntriesVarianceSampleDateAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_SAMPLE_DATE_ASC',
    EducationClassScheduleEntriesVarianceSampleDateDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_SAMPLE_DATE_DESC',
    EducationClassScheduleEntriesVarianceSampleSpeakerIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_SAMPLE_SPEAKER_ID_ASC',
    EducationClassScheduleEntriesVarianceSampleSpeakerIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_SAMPLE_SPEAKER_ID_DESC',
    EducationClassScheduleEntriesVarianceSampleTopicAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_SAMPLE_TOPIC_ASC',
    EducationClassScheduleEntriesVarianceSampleTopicDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_SAMPLE_TOPIC_DESC',
    EducationClassScheduleEntriesVarianceSampleCreatedAtAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_SAMPLE_CREATED_AT_ASC',
    EducationClassScheduleEntriesVarianceSampleCreatedAtDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_SAMPLE_CREATED_AT_DESC',
    EducationClassScheduleEntriesVarianceSampleUpdatedAtAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_SAMPLE_UPDATED_AT_ASC',
    EducationClassScheduleEntriesVarianceSampleUpdatedAtDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_SAMPLE_UPDATED_AT_DESC',
    EducationClassScheduleEntriesVariancePopulationIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_POPULATION_ID_ASC',
    EducationClassScheduleEntriesVariancePopulationIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_POPULATION_ID_DESC',
    EducationClassScheduleEntriesVariancePopulationEducationClassScheduleIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_POPULATION_EDUCATION_CLASS_SCHEDULE_ID_ASC',
    EducationClassScheduleEntriesVariancePopulationEducationClassScheduleIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_POPULATION_EDUCATION_CLASS_SCHEDULE_ID_DESC',
    EducationClassScheduleEntriesVariancePopulationDateAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_POPULATION_DATE_ASC',
    EducationClassScheduleEntriesVariancePopulationDateDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_POPULATION_DATE_DESC',
    EducationClassScheduleEntriesVariancePopulationSpeakerIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_POPULATION_SPEAKER_ID_ASC',
    EducationClassScheduleEntriesVariancePopulationSpeakerIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_POPULATION_SPEAKER_ID_DESC',
    EducationClassScheduleEntriesVariancePopulationTopicAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_POPULATION_TOPIC_ASC',
    EducationClassScheduleEntriesVariancePopulationTopicDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_POPULATION_TOPIC_DESC',
    EducationClassScheduleEntriesVariancePopulationCreatedAtAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_POPULATION_CREATED_AT_ASC',
    EducationClassScheduleEntriesVariancePopulationCreatedAtDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_POPULATION_CREATED_AT_DESC',
    EducationClassScheduleEntriesVariancePopulationUpdatedAtAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_POPULATION_UPDATED_AT_ASC',
    EducationClassScheduleEntriesVariancePopulationUpdatedAtDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_POPULATION_UPDATED_AT_DESC',
}

export type EducationClassStddevPopulationAggregates = {
    __typename?: 'EducationClassStddevPopulationAggregates';
    /** Population standard deviation of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Population standard deviation of educationId across the matching connection */
    educationId?: Maybe<Scalars['BigFloat']>;
};

export type EducationClassStddevSampleAggregates = {
    __typename?: 'EducationClassStddevSampleAggregates';
    /** Sample standard deviation of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Sample standard deviation of educationId across the matching connection */
    educationId?: Maybe<Scalars['BigFloat']>;
};

export type EducationClassSumAggregates = {
    __typename?: 'EducationClassSumAggregates';
    /** Sum of id across the matching connection */
    id: Scalars['BigInt'];
    /** Sum of educationId across the matching connection */
    educationId: Scalars['BigInt'];
};

export type EducationClassVariancePopulationAggregates = {
    __typename?: 'EducationClassVariancePopulationAggregates';
    /** Population variance of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Population variance of educationId across the matching connection */
    educationId?: Maybe<Scalars['BigFloat']>;
};

export type EducationClassVarianceSampleAggregates = {
    __typename?: 'EducationClassVarianceSampleAggregates';
    /** Sample variance of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Sample variance of educationId across the matching connection */
    educationId?: Maybe<Scalars['BigFloat']>;
};

export enum EducationClassYear {
    I = 'I',
    Ii = 'II',
    Iii = 'III',
    Iv = 'IV',
    Unbenutzt = 'UNBENUTZT',
}

/** A filter to be used against EducationClassYear fields. All fields are combined with a logical ‘and.’ */
export type EducationClassYearFilter = {
    /** Is null (if `true` is specified) or is not null (if `false` is specified). */
    isNull?: Maybe<Scalars['Boolean']>;
    /** Equal to the specified value. */
    equalTo?: Maybe<EducationClassYear>;
    /** Not equal to the specified value. */
    notEqualTo?: Maybe<EducationClassYear>;
    /** Not equal to the specified value, treating null like an ordinary value. */
    distinctFrom?: Maybe<EducationClassYear>;
    /** Equal to the specified value, treating null like an ordinary value. */
    notDistinctFrom?: Maybe<EducationClassYear>;
    /** Included in the specified list. */
    in?: Maybe<Array<EducationClassYear>>;
    /** Not included in the specified list. */
    notIn?: Maybe<Array<EducationClassYear>>;
    /** Less than the specified value. */
    lessThan?: Maybe<EducationClassYear>;
    /** Less than or equal to the specified value. */
    lessThanOrEqualTo?: Maybe<EducationClassYear>;
    /** Greater than the specified value. */
    greaterThan?: Maybe<EducationClassYear>;
    /** Greater than or equal to the specified value. */
    greaterThanOrEqualTo?: Maybe<EducationClassYear>;
};

/** A connection to a list of `EducationClass` values. */
export type EducationClassesConnection = {
    __typename?: 'EducationClassesConnection';
    /** A list of `EducationClass` objects. */
    nodes: Array<EducationClass>;
    /** A list of edges which contains the `EducationClass` and cursor to aid in pagination. */
    edges: Array<EducationClassesEdge>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `EducationClass` you could get from the connection. */
    totalCount: Scalars['Int'];
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<EducationClassAggregates>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<EducationClassAggregates>>;
};

/** A connection to a list of `EducationClass` values. */
export type EducationClassesConnectionGroupedAggregatesArgs = {
    groupBy: Array<EducationClassGroupBy>;
    having?: Maybe<EducationClassHavingInput>;
};

/** A `EducationClass` edge in the connection. */
export type EducationClassesEdge = {
    __typename?: 'EducationClassesEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `EducationClass` at the end of the edge. */
    node: EducationClass;
};

/** Methods to use when ordering `EducationClass`. */
export enum EducationClassesOrderBy {
    Natural = 'NATURAL',
    IdAsc = 'ID_ASC',
    IdDesc = 'ID_DESC',
    EducationIdAsc = 'EDUCATION_ID_ASC',
    EducationIdDesc = 'EDUCATION_ID_DESC',
    NameAsc = 'NAME_ASC',
    NameDesc = 'NAME_DESC',
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
    UpdatedAtAsc = 'UPDATED_AT_ASC',
    UpdatedAtDesc = 'UPDATED_AT_DESC',
    PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
    PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
    EducationClassSchedulesCountAsc = 'EDUCATION_CLASS_SCHEDULES_COUNT_ASC',
    EducationClassSchedulesCountDesc = 'EDUCATION_CLASS_SCHEDULES_COUNT_DESC',
    EducationClassSchedulesSumIdAsc = 'EDUCATION_CLASS_SCHEDULES_SUM_ID_ASC',
    EducationClassSchedulesSumIdDesc = 'EDUCATION_CLASS_SCHEDULES_SUM_ID_DESC',
    EducationClassSchedulesSumEducationClassIdAsc = 'EDUCATION_CLASS_SCHEDULES_SUM_EDUCATION_CLASS_ID_ASC',
    EducationClassSchedulesSumEducationClassIdDesc = 'EDUCATION_CLASS_SCHEDULES_SUM_EDUCATION_CLASS_ID_DESC',
    EducationClassSchedulesSumStartDateAsc = 'EDUCATION_CLASS_SCHEDULES_SUM_START_DATE_ASC',
    EducationClassSchedulesSumStartDateDesc = 'EDUCATION_CLASS_SCHEDULES_SUM_START_DATE_DESC',
    EducationClassSchedulesSumEndDateAsc = 'EDUCATION_CLASS_SCHEDULES_SUM_END_DATE_ASC',
    EducationClassSchedulesSumEndDateDesc = 'EDUCATION_CLASS_SCHEDULES_SUM_END_DATE_DESC',
    EducationClassSchedulesSumTimetableAsc = 'EDUCATION_CLASS_SCHEDULES_SUM_TIMETABLE_ASC',
    EducationClassSchedulesSumTimetableDesc = 'EDUCATION_CLASS_SCHEDULES_SUM_TIMETABLE_DESC',
    EducationClassSchedulesSumLocationIdAsc = 'EDUCATION_CLASS_SCHEDULES_SUM_LOCATION_ID_ASC',
    EducationClassSchedulesSumLocationIdDesc = 'EDUCATION_CLASS_SCHEDULES_SUM_LOCATION_ID_DESC',
    EducationClassSchedulesSumCreatedAtAsc = 'EDUCATION_CLASS_SCHEDULES_SUM_CREATED_AT_ASC',
    EducationClassSchedulesSumCreatedAtDesc = 'EDUCATION_CLASS_SCHEDULES_SUM_CREATED_AT_DESC',
    EducationClassSchedulesSumUpdatedAtAsc = 'EDUCATION_CLASS_SCHEDULES_SUM_UPDATED_AT_ASC',
    EducationClassSchedulesSumUpdatedAtDesc = 'EDUCATION_CLASS_SCHEDULES_SUM_UPDATED_AT_DESC',
    EducationClassSchedulesSumCourseYearAsc = 'EDUCATION_CLASS_SCHEDULES_SUM_COURSE_YEAR_ASC',
    EducationClassSchedulesSumCourseYearDesc = 'EDUCATION_CLASS_SCHEDULES_SUM_COURSE_YEAR_DESC',
    EducationClassSchedulesDistinctCountIdAsc = 'EDUCATION_CLASS_SCHEDULES_DISTINCT_COUNT_ID_ASC',
    EducationClassSchedulesDistinctCountIdDesc = 'EDUCATION_CLASS_SCHEDULES_DISTINCT_COUNT_ID_DESC',
    EducationClassSchedulesDistinctCountEducationClassIdAsc = 'EDUCATION_CLASS_SCHEDULES_DISTINCT_COUNT_EDUCATION_CLASS_ID_ASC',
    EducationClassSchedulesDistinctCountEducationClassIdDesc = 'EDUCATION_CLASS_SCHEDULES_DISTINCT_COUNT_EDUCATION_CLASS_ID_DESC',
    EducationClassSchedulesDistinctCountStartDateAsc = 'EDUCATION_CLASS_SCHEDULES_DISTINCT_COUNT_START_DATE_ASC',
    EducationClassSchedulesDistinctCountStartDateDesc = 'EDUCATION_CLASS_SCHEDULES_DISTINCT_COUNT_START_DATE_DESC',
    EducationClassSchedulesDistinctCountEndDateAsc = 'EDUCATION_CLASS_SCHEDULES_DISTINCT_COUNT_END_DATE_ASC',
    EducationClassSchedulesDistinctCountEndDateDesc = 'EDUCATION_CLASS_SCHEDULES_DISTINCT_COUNT_END_DATE_DESC',
    EducationClassSchedulesDistinctCountTimetableAsc = 'EDUCATION_CLASS_SCHEDULES_DISTINCT_COUNT_TIMETABLE_ASC',
    EducationClassSchedulesDistinctCountTimetableDesc = 'EDUCATION_CLASS_SCHEDULES_DISTINCT_COUNT_TIMETABLE_DESC',
    EducationClassSchedulesDistinctCountLocationIdAsc = 'EDUCATION_CLASS_SCHEDULES_DISTINCT_COUNT_LOCATION_ID_ASC',
    EducationClassSchedulesDistinctCountLocationIdDesc = 'EDUCATION_CLASS_SCHEDULES_DISTINCT_COUNT_LOCATION_ID_DESC',
    EducationClassSchedulesDistinctCountCreatedAtAsc = 'EDUCATION_CLASS_SCHEDULES_DISTINCT_COUNT_CREATED_AT_ASC',
    EducationClassSchedulesDistinctCountCreatedAtDesc = 'EDUCATION_CLASS_SCHEDULES_DISTINCT_COUNT_CREATED_AT_DESC',
    EducationClassSchedulesDistinctCountUpdatedAtAsc = 'EDUCATION_CLASS_SCHEDULES_DISTINCT_COUNT_UPDATED_AT_ASC',
    EducationClassSchedulesDistinctCountUpdatedAtDesc = 'EDUCATION_CLASS_SCHEDULES_DISTINCT_COUNT_UPDATED_AT_DESC',
    EducationClassSchedulesDistinctCountCourseYearAsc = 'EDUCATION_CLASS_SCHEDULES_DISTINCT_COUNT_COURSE_YEAR_ASC',
    EducationClassSchedulesDistinctCountCourseYearDesc = 'EDUCATION_CLASS_SCHEDULES_DISTINCT_COUNT_COURSE_YEAR_DESC',
    EducationClassSchedulesMinIdAsc = 'EDUCATION_CLASS_SCHEDULES_MIN_ID_ASC',
    EducationClassSchedulesMinIdDesc = 'EDUCATION_CLASS_SCHEDULES_MIN_ID_DESC',
    EducationClassSchedulesMinEducationClassIdAsc = 'EDUCATION_CLASS_SCHEDULES_MIN_EDUCATION_CLASS_ID_ASC',
    EducationClassSchedulesMinEducationClassIdDesc = 'EDUCATION_CLASS_SCHEDULES_MIN_EDUCATION_CLASS_ID_DESC',
    EducationClassSchedulesMinStartDateAsc = 'EDUCATION_CLASS_SCHEDULES_MIN_START_DATE_ASC',
    EducationClassSchedulesMinStartDateDesc = 'EDUCATION_CLASS_SCHEDULES_MIN_START_DATE_DESC',
    EducationClassSchedulesMinEndDateAsc = 'EDUCATION_CLASS_SCHEDULES_MIN_END_DATE_ASC',
    EducationClassSchedulesMinEndDateDesc = 'EDUCATION_CLASS_SCHEDULES_MIN_END_DATE_DESC',
    EducationClassSchedulesMinTimetableAsc = 'EDUCATION_CLASS_SCHEDULES_MIN_TIMETABLE_ASC',
    EducationClassSchedulesMinTimetableDesc = 'EDUCATION_CLASS_SCHEDULES_MIN_TIMETABLE_DESC',
    EducationClassSchedulesMinLocationIdAsc = 'EDUCATION_CLASS_SCHEDULES_MIN_LOCATION_ID_ASC',
    EducationClassSchedulesMinLocationIdDesc = 'EDUCATION_CLASS_SCHEDULES_MIN_LOCATION_ID_DESC',
    EducationClassSchedulesMinCreatedAtAsc = 'EDUCATION_CLASS_SCHEDULES_MIN_CREATED_AT_ASC',
    EducationClassSchedulesMinCreatedAtDesc = 'EDUCATION_CLASS_SCHEDULES_MIN_CREATED_AT_DESC',
    EducationClassSchedulesMinUpdatedAtAsc = 'EDUCATION_CLASS_SCHEDULES_MIN_UPDATED_AT_ASC',
    EducationClassSchedulesMinUpdatedAtDesc = 'EDUCATION_CLASS_SCHEDULES_MIN_UPDATED_AT_DESC',
    EducationClassSchedulesMinCourseYearAsc = 'EDUCATION_CLASS_SCHEDULES_MIN_COURSE_YEAR_ASC',
    EducationClassSchedulesMinCourseYearDesc = 'EDUCATION_CLASS_SCHEDULES_MIN_COURSE_YEAR_DESC',
    EducationClassSchedulesMaxIdAsc = 'EDUCATION_CLASS_SCHEDULES_MAX_ID_ASC',
    EducationClassSchedulesMaxIdDesc = 'EDUCATION_CLASS_SCHEDULES_MAX_ID_DESC',
    EducationClassSchedulesMaxEducationClassIdAsc = 'EDUCATION_CLASS_SCHEDULES_MAX_EDUCATION_CLASS_ID_ASC',
    EducationClassSchedulesMaxEducationClassIdDesc = 'EDUCATION_CLASS_SCHEDULES_MAX_EDUCATION_CLASS_ID_DESC',
    EducationClassSchedulesMaxStartDateAsc = 'EDUCATION_CLASS_SCHEDULES_MAX_START_DATE_ASC',
    EducationClassSchedulesMaxStartDateDesc = 'EDUCATION_CLASS_SCHEDULES_MAX_START_DATE_DESC',
    EducationClassSchedulesMaxEndDateAsc = 'EDUCATION_CLASS_SCHEDULES_MAX_END_DATE_ASC',
    EducationClassSchedulesMaxEndDateDesc = 'EDUCATION_CLASS_SCHEDULES_MAX_END_DATE_DESC',
    EducationClassSchedulesMaxTimetableAsc = 'EDUCATION_CLASS_SCHEDULES_MAX_TIMETABLE_ASC',
    EducationClassSchedulesMaxTimetableDesc = 'EDUCATION_CLASS_SCHEDULES_MAX_TIMETABLE_DESC',
    EducationClassSchedulesMaxLocationIdAsc = 'EDUCATION_CLASS_SCHEDULES_MAX_LOCATION_ID_ASC',
    EducationClassSchedulesMaxLocationIdDesc = 'EDUCATION_CLASS_SCHEDULES_MAX_LOCATION_ID_DESC',
    EducationClassSchedulesMaxCreatedAtAsc = 'EDUCATION_CLASS_SCHEDULES_MAX_CREATED_AT_ASC',
    EducationClassSchedulesMaxCreatedAtDesc = 'EDUCATION_CLASS_SCHEDULES_MAX_CREATED_AT_DESC',
    EducationClassSchedulesMaxUpdatedAtAsc = 'EDUCATION_CLASS_SCHEDULES_MAX_UPDATED_AT_ASC',
    EducationClassSchedulesMaxUpdatedAtDesc = 'EDUCATION_CLASS_SCHEDULES_MAX_UPDATED_AT_DESC',
    EducationClassSchedulesMaxCourseYearAsc = 'EDUCATION_CLASS_SCHEDULES_MAX_COURSE_YEAR_ASC',
    EducationClassSchedulesMaxCourseYearDesc = 'EDUCATION_CLASS_SCHEDULES_MAX_COURSE_YEAR_DESC',
    EducationClassSchedulesAverageIdAsc = 'EDUCATION_CLASS_SCHEDULES_AVERAGE_ID_ASC',
    EducationClassSchedulesAverageIdDesc = 'EDUCATION_CLASS_SCHEDULES_AVERAGE_ID_DESC',
    EducationClassSchedulesAverageEducationClassIdAsc = 'EDUCATION_CLASS_SCHEDULES_AVERAGE_EDUCATION_CLASS_ID_ASC',
    EducationClassSchedulesAverageEducationClassIdDesc = 'EDUCATION_CLASS_SCHEDULES_AVERAGE_EDUCATION_CLASS_ID_DESC',
    EducationClassSchedulesAverageStartDateAsc = 'EDUCATION_CLASS_SCHEDULES_AVERAGE_START_DATE_ASC',
    EducationClassSchedulesAverageStartDateDesc = 'EDUCATION_CLASS_SCHEDULES_AVERAGE_START_DATE_DESC',
    EducationClassSchedulesAverageEndDateAsc = 'EDUCATION_CLASS_SCHEDULES_AVERAGE_END_DATE_ASC',
    EducationClassSchedulesAverageEndDateDesc = 'EDUCATION_CLASS_SCHEDULES_AVERAGE_END_DATE_DESC',
    EducationClassSchedulesAverageTimetableAsc = 'EDUCATION_CLASS_SCHEDULES_AVERAGE_TIMETABLE_ASC',
    EducationClassSchedulesAverageTimetableDesc = 'EDUCATION_CLASS_SCHEDULES_AVERAGE_TIMETABLE_DESC',
    EducationClassSchedulesAverageLocationIdAsc = 'EDUCATION_CLASS_SCHEDULES_AVERAGE_LOCATION_ID_ASC',
    EducationClassSchedulesAverageLocationIdDesc = 'EDUCATION_CLASS_SCHEDULES_AVERAGE_LOCATION_ID_DESC',
    EducationClassSchedulesAverageCreatedAtAsc = 'EDUCATION_CLASS_SCHEDULES_AVERAGE_CREATED_AT_ASC',
    EducationClassSchedulesAverageCreatedAtDesc = 'EDUCATION_CLASS_SCHEDULES_AVERAGE_CREATED_AT_DESC',
    EducationClassSchedulesAverageUpdatedAtAsc = 'EDUCATION_CLASS_SCHEDULES_AVERAGE_UPDATED_AT_ASC',
    EducationClassSchedulesAverageUpdatedAtDesc = 'EDUCATION_CLASS_SCHEDULES_AVERAGE_UPDATED_AT_DESC',
    EducationClassSchedulesAverageCourseYearAsc = 'EDUCATION_CLASS_SCHEDULES_AVERAGE_COURSE_YEAR_ASC',
    EducationClassSchedulesAverageCourseYearDesc = 'EDUCATION_CLASS_SCHEDULES_AVERAGE_COURSE_YEAR_DESC',
    EducationClassSchedulesStddevSampleIdAsc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_SAMPLE_ID_ASC',
    EducationClassSchedulesStddevSampleIdDesc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_SAMPLE_ID_DESC',
    EducationClassSchedulesStddevSampleEducationClassIdAsc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_SAMPLE_EDUCATION_CLASS_ID_ASC',
    EducationClassSchedulesStddevSampleEducationClassIdDesc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_SAMPLE_EDUCATION_CLASS_ID_DESC',
    EducationClassSchedulesStddevSampleStartDateAsc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_SAMPLE_START_DATE_ASC',
    EducationClassSchedulesStddevSampleStartDateDesc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_SAMPLE_START_DATE_DESC',
    EducationClassSchedulesStddevSampleEndDateAsc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_SAMPLE_END_DATE_ASC',
    EducationClassSchedulesStddevSampleEndDateDesc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_SAMPLE_END_DATE_DESC',
    EducationClassSchedulesStddevSampleTimetableAsc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_SAMPLE_TIMETABLE_ASC',
    EducationClassSchedulesStddevSampleTimetableDesc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_SAMPLE_TIMETABLE_DESC',
    EducationClassSchedulesStddevSampleLocationIdAsc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_SAMPLE_LOCATION_ID_ASC',
    EducationClassSchedulesStddevSampleLocationIdDesc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_SAMPLE_LOCATION_ID_DESC',
    EducationClassSchedulesStddevSampleCreatedAtAsc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_SAMPLE_CREATED_AT_ASC',
    EducationClassSchedulesStddevSampleCreatedAtDesc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_SAMPLE_CREATED_AT_DESC',
    EducationClassSchedulesStddevSampleUpdatedAtAsc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_SAMPLE_UPDATED_AT_ASC',
    EducationClassSchedulesStddevSampleUpdatedAtDesc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_SAMPLE_UPDATED_AT_DESC',
    EducationClassSchedulesStddevSampleCourseYearAsc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_SAMPLE_COURSE_YEAR_ASC',
    EducationClassSchedulesStddevSampleCourseYearDesc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_SAMPLE_COURSE_YEAR_DESC',
    EducationClassSchedulesStddevPopulationIdAsc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_POPULATION_ID_ASC',
    EducationClassSchedulesStddevPopulationIdDesc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_POPULATION_ID_DESC',
    EducationClassSchedulesStddevPopulationEducationClassIdAsc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_POPULATION_EDUCATION_CLASS_ID_ASC',
    EducationClassSchedulesStddevPopulationEducationClassIdDesc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_POPULATION_EDUCATION_CLASS_ID_DESC',
    EducationClassSchedulesStddevPopulationStartDateAsc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_POPULATION_START_DATE_ASC',
    EducationClassSchedulesStddevPopulationStartDateDesc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_POPULATION_START_DATE_DESC',
    EducationClassSchedulesStddevPopulationEndDateAsc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_POPULATION_END_DATE_ASC',
    EducationClassSchedulesStddevPopulationEndDateDesc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_POPULATION_END_DATE_DESC',
    EducationClassSchedulesStddevPopulationTimetableAsc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_POPULATION_TIMETABLE_ASC',
    EducationClassSchedulesStddevPopulationTimetableDesc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_POPULATION_TIMETABLE_DESC',
    EducationClassSchedulesStddevPopulationLocationIdAsc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_POPULATION_LOCATION_ID_ASC',
    EducationClassSchedulesStddevPopulationLocationIdDesc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_POPULATION_LOCATION_ID_DESC',
    EducationClassSchedulesStddevPopulationCreatedAtAsc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_POPULATION_CREATED_AT_ASC',
    EducationClassSchedulesStddevPopulationCreatedAtDesc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_POPULATION_CREATED_AT_DESC',
    EducationClassSchedulesStddevPopulationUpdatedAtAsc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_POPULATION_UPDATED_AT_ASC',
    EducationClassSchedulesStddevPopulationUpdatedAtDesc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_POPULATION_UPDATED_AT_DESC',
    EducationClassSchedulesStddevPopulationCourseYearAsc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_POPULATION_COURSE_YEAR_ASC',
    EducationClassSchedulesStddevPopulationCourseYearDesc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_POPULATION_COURSE_YEAR_DESC',
    EducationClassSchedulesVarianceSampleIdAsc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_SAMPLE_ID_ASC',
    EducationClassSchedulesVarianceSampleIdDesc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_SAMPLE_ID_DESC',
    EducationClassSchedulesVarianceSampleEducationClassIdAsc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_SAMPLE_EDUCATION_CLASS_ID_ASC',
    EducationClassSchedulesVarianceSampleEducationClassIdDesc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_SAMPLE_EDUCATION_CLASS_ID_DESC',
    EducationClassSchedulesVarianceSampleStartDateAsc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_SAMPLE_START_DATE_ASC',
    EducationClassSchedulesVarianceSampleStartDateDesc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_SAMPLE_START_DATE_DESC',
    EducationClassSchedulesVarianceSampleEndDateAsc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_SAMPLE_END_DATE_ASC',
    EducationClassSchedulesVarianceSampleEndDateDesc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_SAMPLE_END_DATE_DESC',
    EducationClassSchedulesVarianceSampleTimetableAsc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_SAMPLE_TIMETABLE_ASC',
    EducationClassSchedulesVarianceSampleTimetableDesc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_SAMPLE_TIMETABLE_DESC',
    EducationClassSchedulesVarianceSampleLocationIdAsc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_SAMPLE_LOCATION_ID_ASC',
    EducationClassSchedulesVarianceSampleLocationIdDesc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_SAMPLE_LOCATION_ID_DESC',
    EducationClassSchedulesVarianceSampleCreatedAtAsc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_SAMPLE_CREATED_AT_ASC',
    EducationClassSchedulesVarianceSampleCreatedAtDesc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_SAMPLE_CREATED_AT_DESC',
    EducationClassSchedulesVarianceSampleUpdatedAtAsc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_SAMPLE_UPDATED_AT_ASC',
    EducationClassSchedulesVarianceSampleUpdatedAtDesc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_SAMPLE_UPDATED_AT_DESC',
    EducationClassSchedulesVarianceSampleCourseYearAsc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_SAMPLE_COURSE_YEAR_ASC',
    EducationClassSchedulesVarianceSampleCourseYearDesc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_SAMPLE_COURSE_YEAR_DESC',
    EducationClassSchedulesVariancePopulationIdAsc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_POPULATION_ID_ASC',
    EducationClassSchedulesVariancePopulationIdDesc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_POPULATION_ID_DESC',
    EducationClassSchedulesVariancePopulationEducationClassIdAsc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_POPULATION_EDUCATION_CLASS_ID_ASC',
    EducationClassSchedulesVariancePopulationEducationClassIdDesc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_POPULATION_EDUCATION_CLASS_ID_DESC',
    EducationClassSchedulesVariancePopulationStartDateAsc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_POPULATION_START_DATE_ASC',
    EducationClassSchedulesVariancePopulationStartDateDesc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_POPULATION_START_DATE_DESC',
    EducationClassSchedulesVariancePopulationEndDateAsc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_POPULATION_END_DATE_ASC',
    EducationClassSchedulesVariancePopulationEndDateDesc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_POPULATION_END_DATE_DESC',
    EducationClassSchedulesVariancePopulationTimetableAsc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_POPULATION_TIMETABLE_ASC',
    EducationClassSchedulesVariancePopulationTimetableDesc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_POPULATION_TIMETABLE_DESC',
    EducationClassSchedulesVariancePopulationLocationIdAsc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_POPULATION_LOCATION_ID_ASC',
    EducationClassSchedulesVariancePopulationLocationIdDesc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_POPULATION_LOCATION_ID_DESC',
    EducationClassSchedulesVariancePopulationCreatedAtAsc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_POPULATION_CREATED_AT_ASC',
    EducationClassSchedulesVariancePopulationCreatedAtDesc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_POPULATION_CREATED_AT_DESC',
    EducationClassSchedulesVariancePopulationUpdatedAtAsc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_POPULATION_UPDATED_AT_ASC',
    EducationClassSchedulesVariancePopulationUpdatedAtDesc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_POPULATION_UPDATED_AT_DESC',
    EducationClassSchedulesVariancePopulationCourseYearAsc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_POPULATION_COURSE_YEAR_ASC',
    EducationClassSchedulesVariancePopulationCourseYearDesc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_POPULATION_COURSE_YEAR_DESC',
}

/**
 * A condition to be used against `Education` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type EducationCondition = {
    /** Checks for equality with the object’s `id` field. */
    id?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `name` field. */
    name?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `createdAt` field. */
    createdAt?: Maybe<Scalars['Datetime']>;
    /** Checks for equality with the object’s `updatedAt` field. */
    updatedAt?: Maybe<Scalars['Datetime']>;
};

export type EducationDistinctCountAggregates = {
    __typename?: 'EducationDistinctCountAggregates';
    /** Distinct count of id across the matching connection */
    id?: Maybe<Scalars['BigInt']>;
    /** Distinct count of name across the matching connection */
    name?: Maybe<Scalars['BigInt']>;
    /** Distinct count of createdAt across the matching connection */
    createdAt?: Maybe<Scalars['BigInt']>;
    /** Distinct count of updatedAt across the matching connection */
    updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `Education` object types. All fields are combined with a logical ‘and.’ */
export type EducationFilter = {
    /** Filter by the object’s `id` field. */
    id?: Maybe<IntFilter>;
    /** Filter by the object’s `name` field. */
    name?: Maybe<StringFilter>;
    /** Filter by the object’s `createdAt` field. */
    createdAt?: Maybe<DatetimeFilter>;
    /** Filter by the object’s `updatedAt` field. */
    updatedAt?: Maybe<DatetimeFilter>;
    /** Checks for all expressions in this list. */
    and?: Maybe<Array<EducationFilter>>;
    /** Checks for any expressions in this list. */
    or?: Maybe<Array<EducationFilter>>;
    /** Negates the expression. */
    not?: Maybe<EducationFilter>;
};

/** Grouping methods for `Education` for usage during aggregation. */
export enum EducationGroupBy {
    Name = 'NAME',
    CreatedAt = 'CREATED_AT',
    CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
    CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
    UpdatedAt = 'UPDATED_AT',
    UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
    UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
}

export type EducationHavingAverageInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EducationHavingDistinctCountInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `Education` aggregates. */
export type EducationHavingInput = {
    AND?: Maybe<Array<EducationHavingInput>>;
    OR?: Maybe<Array<EducationHavingInput>>;
    sum?: Maybe<EducationHavingSumInput>;
    distinctCount?: Maybe<EducationHavingDistinctCountInput>;
    min?: Maybe<EducationHavingMinInput>;
    max?: Maybe<EducationHavingMaxInput>;
    average?: Maybe<EducationHavingAverageInput>;
    stddevSample?: Maybe<EducationHavingStddevSampleInput>;
    stddevPopulation?: Maybe<EducationHavingStddevPopulationInput>;
    varianceSample?: Maybe<EducationHavingVarianceSampleInput>;
    variancePopulation?: Maybe<EducationHavingVariancePopulationInput>;
};

export type EducationHavingMaxInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EducationHavingMinInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EducationHavingStddevPopulationInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EducationHavingStddevSampleInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EducationHavingSumInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EducationHavingVariancePopulationInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EducationHavingVarianceSampleInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `Education` */
export type EducationInput = {
    id?: Maybe<Scalars['Int']>;
    name?: Maybe<Scalars['String']>;
    createdAt?: Maybe<Scalars['Datetime']>;
    updatedAt?: Maybe<Scalars['Datetime']>;
};

export type EducationMaxAggregates = {
    __typename?: 'EducationMaxAggregates';
    /** Maximum of id across the matching connection */
    id?: Maybe<Scalars['Int']>;
};

export type EducationMinAggregates = {
    __typename?: 'EducationMinAggregates';
    /** Minimum of id across the matching connection */
    id?: Maybe<Scalars['Int']>;
};

/** Represents an update to a `Education`. Fields that are set will be updated. */
export type EducationPatch = {
    id?: Maybe<Scalars['Int']>;
    name?: Maybe<Scalars['String']>;
    createdAt?: Maybe<Scalars['Datetime']>;
    updatedAt?: Maybe<Scalars['Datetime']>;
};

export type EducationStddevPopulationAggregates = {
    __typename?: 'EducationStddevPopulationAggregates';
    /** Population standard deviation of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
};

export type EducationStddevSampleAggregates = {
    __typename?: 'EducationStddevSampleAggregates';
    /** Sample standard deviation of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
};

export type EducationSumAggregates = {
    __typename?: 'EducationSumAggregates';
    /** Sum of id across the matching connection */
    id: Scalars['BigInt'];
};

export type EducationVariancePopulationAggregates = {
    __typename?: 'EducationVariancePopulationAggregates';
    /** Population variance of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
};

export type EducationVarianceSampleAggregates = {
    __typename?: 'EducationVarianceSampleAggregates';
    /** Sample variance of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Education` values. */
export type EducationsConnection = {
    __typename?: 'EducationsConnection';
    /** A list of `Education` objects. */
    nodes: Array<Education>;
    /** A list of edges which contains the `Education` and cursor to aid in pagination. */
    edges: Array<EducationsEdge>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `Education` you could get from the connection. */
    totalCount: Scalars['Int'];
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<EducationAggregates>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<EducationAggregates>>;
};

/** A connection to a list of `Education` values. */
export type EducationsConnectionGroupedAggregatesArgs = {
    groupBy: Array<EducationGroupBy>;
    having?: Maybe<EducationHavingInput>;
};

/** A `Education` edge in the connection. */
export type EducationsEdge = {
    __typename?: 'EducationsEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `Education` at the end of the edge. */
    node: Education;
};

/** Methods to use when ordering `Education`. */
export enum EducationsOrderBy {
    Natural = 'NATURAL',
    IdAsc = 'ID_ASC',
    IdDesc = 'ID_DESC',
    NameAsc = 'NAME_ASC',
    NameDesc = 'NAME_DESC',
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
    UpdatedAtAsc = 'UPDATED_AT_ASC',
    UpdatedAtDesc = 'UPDATED_AT_DESC',
    PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
    PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
    EducationClassesCountAsc = 'EDUCATION_CLASSES_COUNT_ASC',
    EducationClassesCountDesc = 'EDUCATION_CLASSES_COUNT_DESC',
    EducationClassesSumIdAsc = 'EDUCATION_CLASSES_SUM_ID_ASC',
    EducationClassesSumIdDesc = 'EDUCATION_CLASSES_SUM_ID_DESC',
    EducationClassesSumEducationIdAsc = 'EDUCATION_CLASSES_SUM_EDUCATION_ID_ASC',
    EducationClassesSumEducationIdDesc = 'EDUCATION_CLASSES_SUM_EDUCATION_ID_DESC',
    EducationClassesSumNameAsc = 'EDUCATION_CLASSES_SUM_NAME_ASC',
    EducationClassesSumNameDesc = 'EDUCATION_CLASSES_SUM_NAME_DESC',
    EducationClassesSumCreatedAtAsc = 'EDUCATION_CLASSES_SUM_CREATED_AT_ASC',
    EducationClassesSumCreatedAtDesc = 'EDUCATION_CLASSES_SUM_CREATED_AT_DESC',
    EducationClassesSumUpdatedAtAsc = 'EDUCATION_CLASSES_SUM_UPDATED_AT_ASC',
    EducationClassesSumUpdatedAtDesc = 'EDUCATION_CLASSES_SUM_UPDATED_AT_DESC',
    EducationClassesDistinctCountIdAsc = 'EDUCATION_CLASSES_DISTINCT_COUNT_ID_ASC',
    EducationClassesDistinctCountIdDesc = 'EDUCATION_CLASSES_DISTINCT_COUNT_ID_DESC',
    EducationClassesDistinctCountEducationIdAsc = 'EDUCATION_CLASSES_DISTINCT_COUNT_EDUCATION_ID_ASC',
    EducationClassesDistinctCountEducationIdDesc = 'EDUCATION_CLASSES_DISTINCT_COUNT_EDUCATION_ID_DESC',
    EducationClassesDistinctCountNameAsc = 'EDUCATION_CLASSES_DISTINCT_COUNT_NAME_ASC',
    EducationClassesDistinctCountNameDesc = 'EDUCATION_CLASSES_DISTINCT_COUNT_NAME_DESC',
    EducationClassesDistinctCountCreatedAtAsc = 'EDUCATION_CLASSES_DISTINCT_COUNT_CREATED_AT_ASC',
    EducationClassesDistinctCountCreatedAtDesc = 'EDUCATION_CLASSES_DISTINCT_COUNT_CREATED_AT_DESC',
    EducationClassesDistinctCountUpdatedAtAsc = 'EDUCATION_CLASSES_DISTINCT_COUNT_UPDATED_AT_ASC',
    EducationClassesDistinctCountUpdatedAtDesc = 'EDUCATION_CLASSES_DISTINCT_COUNT_UPDATED_AT_DESC',
    EducationClassesMinIdAsc = 'EDUCATION_CLASSES_MIN_ID_ASC',
    EducationClassesMinIdDesc = 'EDUCATION_CLASSES_MIN_ID_DESC',
    EducationClassesMinEducationIdAsc = 'EDUCATION_CLASSES_MIN_EDUCATION_ID_ASC',
    EducationClassesMinEducationIdDesc = 'EDUCATION_CLASSES_MIN_EDUCATION_ID_DESC',
    EducationClassesMinNameAsc = 'EDUCATION_CLASSES_MIN_NAME_ASC',
    EducationClassesMinNameDesc = 'EDUCATION_CLASSES_MIN_NAME_DESC',
    EducationClassesMinCreatedAtAsc = 'EDUCATION_CLASSES_MIN_CREATED_AT_ASC',
    EducationClassesMinCreatedAtDesc = 'EDUCATION_CLASSES_MIN_CREATED_AT_DESC',
    EducationClassesMinUpdatedAtAsc = 'EDUCATION_CLASSES_MIN_UPDATED_AT_ASC',
    EducationClassesMinUpdatedAtDesc = 'EDUCATION_CLASSES_MIN_UPDATED_AT_DESC',
    EducationClassesMaxIdAsc = 'EDUCATION_CLASSES_MAX_ID_ASC',
    EducationClassesMaxIdDesc = 'EDUCATION_CLASSES_MAX_ID_DESC',
    EducationClassesMaxEducationIdAsc = 'EDUCATION_CLASSES_MAX_EDUCATION_ID_ASC',
    EducationClassesMaxEducationIdDesc = 'EDUCATION_CLASSES_MAX_EDUCATION_ID_DESC',
    EducationClassesMaxNameAsc = 'EDUCATION_CLASSES_MAX_NAME_ASC',
    EducationClassesMaxNameDesc = 'EDUCATION_CLASSES_MAX_NAME_DESC',
    EducationClassesMaxCreatedAtAsc = 'EDUCATION_CLASSES_MAX_CREATED_AT_ASC',
    EducationClassesMaxCreatedAtDesc = 'EDUCATION_CLASSES_MAX_CREATED_AT_DESC',
    EducationClassesMaxUpdatedAtAsc = 'EDUCATION_CLASSES_MAX_UPDATED_AT_ASC',
    EducationClassesMaxUpdatedAtDesc = 'EDUCATION_CLASSES_MAX_UPDATED_AT_DESC',
    EducationClassesAverageIdAsc = 'EDUCATION_CLASSES_AVERAGE_ID_ASC',
    EducationClassesAverageIdDesc = 'EDUCATION_CLASSES_AVERAGE_ID_DESC',
    EducationClassesAverageEducationIdAsc = 'EDUCATION_CLASSES_AVERAGE_EDUCATION_ID_ASC',
    EducationClassesAverageEducationIdDesc = 'EDUCATION_CLASSES_AVERAGE_EDUCATION_ID_DESC',
    EducationClassesAverageNameAsc = 'EDUCATION_CLASSES_AVERAGE_NAME_ASC',
    EducationClassesAverageNameDesc = 'EDUCATION_CLASSES_AVERAGE_NAME_DESC',
    EducationClassesAverageCreatedAtAsc = 'EDUCATION_CLASSES_AVERAGE_CREATED_AT_ASC',
    EducationClassesAverageCreatedAtDesc = 'EDUCATION_CLASSES_AVERAGE_CREATED_AT_DESC',
    EducationClassesAverageUpdatedAtAsc = 'EDUCATION_CLASSES_AVERAGE_UPDATED_AT_ASC',
    EducationClassesAverageUpdatedAtDesc = 'EDUCATION_CLASSES_AVERAGE_UPDATED_AT_DESC',
    EducationClassesStddevSampleIdAsc = 'EDUCATION_CLASSES_STDDEV_SAMPLE_ID_ASC',
    EducationClassesStddevSampleIdDesc = 'EDUCATION_CLASSES_STDDEV_SAMPLE_ID_DESC',
    EducationClassesStddevSampleEducationIdAsc = 'EDUCATION_CLASSES_STDDEV_SAMPLE_EDUCATION_ID_ASC',
    EducationClassesStddevSampleEducationIdDesc = 'EDUCATION_CLASSES_STDDEV_SAMPLE_EDUCATION_ID_DESC',
    EducationClassesStddevSampleNameAsc = 'EDUCATION_CLASSES_STDDEV_SAMPLE_NAME_ASC',
    EducationClassesStddevSampleNameDesc = 'EDUCATION_CLASSES_STDDEV_SAMPLE_NAME_DESC',
    EducationClassesStddevSampleCreatedAtAsc = 'EDUCATION_CLASSES_STDDEV_SAMPLE_CREATED_AT_ASC',
    EducationClassesStddevSampleCreatedAtDesc = 'EDUCATION_CLASSES_STDDEV_SAMPLE_CREATED_AT_DESC',
    EducationClassesStddevSampleUpdatedAtAsc = 'EDUCATION_CLASSES_STDDEV_SAMPLE_UPDATED_AT_ASC',
    EducationClassesStddevSampleUpdatedAtDesc = 'EDUCATION_CLASSES_STDDEV_SAMPLE_UPDATED_AT_DESC',
    EducationClassesStddevPopulationIdAsc = 'EDUCATION_CLASSES_STDDEV_POPULATION_ID_ASC',
    EducationClassesStddevPopulationIdDesc = 'EDUCATION_CLASSES_STDDEV_POPULATION_ID_DESC',
    EducationClassesStddevPopulationEducationIdAsc = 'EDUCATION_CLASSES_STDDEV_POPULATION_EDUCATION_ID_ASC',
    EducationClassesStddevPopulationEducationIdDesc = 'EDUCATION_CLASSES_STDDEV_POPULATION_EDUCATION_ID_DESC',
    EducationClassesStddevPopulationNameAsc = 'EDUCATION_CLASSES_STDDEV_POPULATION_NAME_ASC',
    EducationClassesStddevPopulationNameDesc = 'EDUCATION_CLASSES_STDDEV_POPULATION_NAME_DESC',
    EducationClassesStddevPopulationCreatedAtAsc = 'EDUCATION_CLASSES_STDDEV_POPULATION_CREATED_AT_ASC',
    EducationClassesStddevPopulationCreatedAtDesc = 'EDUCATION_CLASSES_STDDEV_POPULATION_CREATED_AT_DESC',
    EducationClassesStddevPopulationUpdatedAtAsc = 'EDUCATION_CLASSES_STDDEV_POPULATION_UPDATED_AT_ASC',
    EducationClassesStddevPopulationUpdatedAtDesc = 'EDUCATION_CLASSES_STDDEV_POPULATION_UPDATED_AT_DESC',
    EducationClassesVarianceSampleIdAsc = 'EDUCATION_CLASSES_VARIANCE_SAMPLE_ID_ASC',
    EducationClassesVarianceSampleIdDesc = 'EDUCATION_CLASSES_VARIANCE_SAMPLE_ID_DESC',
    EducationClassesVarianceSampleEducationIdAsc = 'EDUCATION_CLASSES_VARIANCE_SAMPLE_EDUCATION_ID_ASC',
    EducationClassesVarianceSampleEducationIdDesc = 'EDUCATION_CLASSES_VARIANCE_SAMPLE_EDUCATION_ID_DESC',
    EducationClassesVarianceSampleNameAsc = 'EDUCATION_CLASSES_VARIANCE_SAMPLE_NAME_ASC',
    EducationClassesVarianceSampleNameDesc = 'EDUCATION_CLASSES_VARIANCE_SAMPLE_NAME_DESC',
    EducationClassesVarianceSampleCreatedAtAsc = 'EDUCATION_CLASSES_VARIANCE_SAMPLE_CREATED_AT_ASC',
    EducationClassesVarianceSampleCreatedAtDesc = 'EDUCATION_CLASSES_VARIANCE_SAMPLE_CREATED_AT_DESC',
    EducationClassesVarianceSampleUpdatedAtAsc = 'EDUCATION_CLASSES_VARIANCE_SAMPLE_UPDATED_AT_ASC',
    EducationClassesVarianceSampleUpdatedAtDesc = 'EDUCATION_CLASSES_VARIANCE_SAMPLE_UPDATED_AT_DESC',
    EducationClassesVariancePopulationIdAsc = 'EDUCATION_CLASSES_VARIANCE_POPULATION_ID_ASC',
    EducationClassesVariancePopulationIdDesc = 'EDUCATION_CLASSES_VARIANCE_POPULATION_ID_DESC',
    EducationClassesVariancePopulationEducationIdAsc = 'EDUCATION_CLASSES_VARIANCE_POPULATION_EDUCATION_ID_ASC',
    EducationClassesVariancePopulationEducationIdDesc = 'EDUCATION_CLASSES_VARIANCE_POPULATION_EDUCATION_ID_DESC',
    EducationClassesVariancePopulationNameAsc = 'EDUCATION_CLASSES_VARIANCE_POPULATION_NAME_ASC',
    EducationClassesVariancePopulationNameDesc = 'EDUCATION_CLASSES_VARIANCE_POPULATION_NAME_DESC',
    EducationClassesVariancePopulationCreatedAtAsc = 'EDUCATION_CLASSES_VARIANCE_POPULATION_CREATED_AT_ASC',
    EducationClassesVariancePopulationCreatedAtDesc = 'EDUCATION_CLASSES_VARIANCE_POPULATION_CREATED_AT_DESC',
    EducationClassesVariancePopulationUpdatedAtAsc = 'EDUCATION_CLASSES_VARIANCE_POPULATION_UPDATED_AT_ASC',
    EducationClassesVariancePopulationUpdatedAtDesc = 'EDUCATION_CLASSES_VARIANCE_POPULATION_UPDATED_AT_DESC',
}

export type EmailCode = Node & {
    __typename?: 'EmailCode';
    /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
    nodeId: Scalars['ID'];
    id: Scalars['Int'];
    email: Scalars['String'];
    code: Scalars['UUID'];
    courseScheduleNodeId: Scalars['String'];
    participantId?: Maybe<Scalars['Int']>;
    createdAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    /** Reads a single `Participant` that is related to this `EmailCode`. */
    participant?: Maybe<Participant>;
};

export type EmailCodeAggregates = {
    __typename?: 'EmailCodeAggregates';
    keys?: Maybe<Array<Scalars['String']>>;
    /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    sum?: Maybe<EmailCodeSumAggregates>;
    /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
    distinctCount?: Maybe<EmailCodeDistinctCountAggregates>;
    /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    min?: Maybe<EmailCodeMinAggregates>;
    /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    max?: Maybe<EmailCodeMaxAggregates>;
    /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
    average?: Maybe<EmailCodeAverageAggregates>;
    /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
    stddevSample?: Maybe<EmailCodeStddevSampleAggregates>;
    /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
    stddevPopulation?: Maybe<EmailCodeStddevPopulationAggregates>;
    /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
    varianceSample?: Maybe<EmailCodeVarianceSampleAggregates>;
    /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
    variancePopulation?: Maybe<EmailCodeVariancePopulationAggregates>;
};

export type EmailCodeAverageAggregates = {
    __typename?: 'EmailCodeAverageAggregates';
    /** Mean average of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Mean average of participantId across the matching connection */
    participantId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `EmailCode` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type EmailCodeCondition = {
    /** Checks for equality with the object’s `id` field. */
    id?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `email` field. */
    email?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `code` field. */
    code?: Maybe<Scalars['UUID']>;
    /** Checks for equality with the object’s `courseScheduleNodeId` field. */
    courseScheduleNodeId?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `participantId` field. */
    participantId?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `createdAt` field. */
    createdAt?: Maybe<Scalars['Datetime']>;
    /** Checks for equality with the object’s `updatedAt` field. */
    updatedAt?: Maybe<Scalars['Datetime']>;
};

export type EmailCodeDistinctCountAggregates = {
    __typename?: 'EmailCodeDistinctCountAggregates';
    /** Distinct count of id across the matching connection */
    id?: Maybe<Scalars['BigInt']>;
    /** Distinct count of email across the matching connection */
    email?: Maybe<Scalars['BigInt']>;
    /** Distinct count of code across the matching connection */
    code?: Maybe<Scalars['BigInt']>;
    /** Distinct count of courseScheduleNodeId across the matching connection */
    courseScheduleNodeId?: Maybe<Scalars['BigInt']>;
    /** Distinct count of participantId across the matching connection */
    participantId?: Maybe<Scalars['BigInt']>;
    /** Distinct count of createdAt across the matching connection */
    createdAt?: Maybe<Scalars['BigInt']>;
    /** Distinct count of updatedAt across the matching connection */
    updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `EmailCode` object types. All fields are combined with a logical ‘and.’ */
export type EmailCodeFilter = {
    /** Filter by the object’s `id` field. */
    id?: Maybe<IntFilter>;
    /** Filter by the object’s `email` field. */
    email?: Maybe<StringFilter>;
    /** Filter by the object’s `code` field. */
    code?: Maybe<UuidFilter>;
    /** Filter by the object’s `courseScheduleNodeId` field. */
    courseScheduleNodeId?: Maybe<StringFilter>;
    /** Filter by the object’s `participantId` field. */
    participantId?: Maybe<IntFilter>;
    /** Filter by the object’s `createdAt` field. */
    createdAt?: Maybe<DatetimeFilter>;
    /** Filter by the object’s `updatedAt` field. */
    updatedAt?: Maybe<DatetimeFilter>;
    /** Checks for all expressions in this list. */
    and?: Maybe<Array<EmailCodeFilter>>;
    /** Checks for any expressions in this list. */
    or?: Maybe<Array<EmailCodeFilter>>;
    /** Negates the expression. */
    not?: Maybe<EmailCodeFilter>;
};

/** Grouping methods for `EmailCode` for usage during aggregation. */
export enum EmailCodeGroupBy {
    Email = 'EMAIL',
    CourseScheduleNodeId = 'COURSE_SCHEDULE_NODE_ID',
    ParticipantId = 'PARTICIPANT_ID',
    CreatedAt = 'CREATED_AT',
    CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
    CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
    UpdatedAt = 'UPDATED_AT',
    UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
    UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
}

export type EmailCodeHavingAverageInput = {
    id?: Maybe<HavingIntFilter>;
    participantId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EmailCodeHavingDistinctCountInput = {
    id?: Maybe<HavingIntFilter>;
    participantId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `EmailCode` aggregates. */
export type EmailCodeHavingInput = {
    AND?: Maybe<Array<EmailCodeHavingInput>>;
    OR?: Maybe<Array<EmailCodeHavingInput>>;
    sum?: Maybe<EmailCodeHavingSumInput>;
    distinctCount?: Maybe<EmailCodeHavingDistinctCountInput>;
    min?: Maybe<EmailCodeHavingMinInput>;
    max?: Maybe<EmailCodeHavingMaxInput>;
    average?: Maybe<EmailCodeHavingAverageInput>;
    stddevSample?: Maybe<EmailCodeHavingStddevSampleInput>;
    stddevPopulation?: Maybe<EmailCodeHavingStddevPopulationInput>;
    varianceSample?: Maybe<EmailCodeHavingVarianceSampleInput>;
    variancePopulation?: Maybe<EmailCodeHavingVariancePopulationInput>;
};

export type EmailCodeHavingMaxInput = {
    id?: Maybe<HavingIntFilter>;
    participantId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EmailCodeHavingMinInput = {
    id?: Maybe<HavingIntFilter>;
    participantId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EmailCodeHavingStddevPopulationInput = {
    id?: Maybe<HavingIntFilter>;
    participantId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EmailCodeHavingStddevSampleInput = {
    id?: Maybe<HavingIntFilter>;
    participantId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EmailCodeHavingSumInput = {
    id?: Maybe<HavingIntFilter>;
    participantId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EmailCodeHavingVariancePopulationInput = {
    id?: Maybe<HavingIntFilter>;
    participantId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EmailCodeHavingVarianceSampleInput = {
    id?: Maybe<HavingIntFilter>;
    participantId?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `EmailCode` */
export type EmailCodeInput = {
    id?: Maybe<Scalars['Int']>;
    email: Scalars['String'];
    code: Scalars['UUID'];
    courseScheduleNodeId: Scalars['String'];
    participantId?: Maybe<Scalars['Int']>;
    createdAt?: Maybe<Scalars['Datetime']>;
    updatedAt?: Maybe<Scalars['Datetime']>;
};

export type EmailCodeMaxAggregates = {
    __typename?: 'EmailCodeMaxAggregates';
    /** Maximum of id across the matching connection */
    id?: Maybe<Scalars['Int']>;
    /** Maximum of participantId across the matching connection */
    participantId?: Maybe<Scalars['Int']>;
};

export type EmailCodeMinAggregates = {
    __typename?: 'EmailCodeMinAggregates';
    /** Minimum of id across the matching connection */
    id?: Maybe<Scalars['Int']>;
    /** Minimum of participantId across the matching connection */
    participantId?: Maybe<Scalars['Int']>;
};

/** Represents an update to a `EmailCode`. Fields that are set will be updated. */
export type EmailCodePatch = {
    id?: Maybe<Scalars['Int']>;
    email?: Maybe<Scalars['String']>;
    code?: Maybe<Scalars['UUID']>;
    courseScheduleNodeId?: Maybe<Scalars['String']>;
    participantId?: Maybe<Scalars['Int']>;
    createdAt?: Maybe<Scalars['Datetime']>;
    updatedAt?: Maybe<Scalars['Datetime']>;
};

export type EmailCodeStddevPopulationAggregates = {
    __typename?: 'EmailCodeStddevPopulationAggregates';
    /** Population standard deviation of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Population standard deviation of participantId across the matching connection */
    participantId?: Maybe<Scalars['BigFloat']>;
};

export type EmailCodeStddevSampleAggregates = {
    __typename?: 'EmailCodeStddevSampleAggregates';
    /** Sample standard deviation of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Sample standard deviation of participantId across the matching connection */
    participantId?: Maybe<Scalars['BigFloat']>;
};

export type EmailCodeSumAggregates = {
    __typename?: 'EmailCodeSumAggregates';
    /** Sum of id across the matching connection */
    id: Scalars['BigInt'];
    /** Sum of participantId across the matching connection */
    participantId: Scalars['BigInt'];
};

export type EmailCodeVariancePopulationAggregates = {
    __typename?: 'EmailCodeVariancePopulationAggregates';
    /** Population variance of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Population variance of participantId across the matching connection */
    participantId?: Maybe<Scalars['BigFloat']>;
};

export type EmailCodeVarianceSampleAggregates = {
    __typename?: 'EmailCodeVarianceSampleAggregates';
    /** Sample variance of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Sample variance of participantId across the matching connection */
    participantId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `EmailCode` values. */
export type EmailCodesConnection = {
    __typename?: 'EmailCodesConnection';
    /** A list of `EmailCode` objects. */
    nodes: Array<EmailCode>;
    /** A list of edges which contains the `EmailCode` and cursor to aid in pagination. */
    edges: Array<EmailCodesEdge>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `EmailCode` you could get from the connection. */
    totalCount: Scalars['Int'];
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<EmailCodeAggregates>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<EmailCodeAggregates>>;
};

/** A connection to a list of `EmailCode` values. */
export type EmailCodesConnectionGroupedAggregatesArgs = {
    groupBy: Array<EmailCodeGroupBy>;
    having?: Maybe<EmailCodeHavingInput>;
};

/** A `EmailCode` edge in the connection. */
export type EmailCodesEdge = {
    __typename?: 'EmailCodesEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `EmailCode` at the end of the edge. */
    node: EmailCode;
};

/** Methods to use when ordering `EmailCode`. */
export enum EmailCodesOrderBy {
    Natural = 'NATURAL',
    IdAsc = 'ID_ASC',
    IdDesc = 'ID_DESC',
    EmailAsc = 'EMAIL_ASC',
    EmailDesc = 'EMAIL_DESC',
    CodeAsc = 'CODE_ASC',
    CodeDesc = 'CODE_DESC',
    CourseScheduleNodeIdAsc = 'COURSE_SCHEDULE_NODE_ID_ASC',
    CourseScheduleNodeIdDesc = 'COURSE_SCHEDULE_NODE_ID_DESC',
    ParticipantIdAsc = 'PARTICIPANT_ID_ASC',
    ParticipantIdDesc = 'PARTICIPANT_ID_DESC',
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
    UpdatedAtAsc = 'UPDATED_AT_ASC',
    UpdatedAtDesc = 'UPDATED_AT_DESC',
    PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
    PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

export type EmailTemplate = Node & {
    __typename?: 'EmailTemplate';
    /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
    nodeId: Scalars['ID'];
    id: Scalars['Int'];
    shortcut: Scalars['String'];
    subject: Scalars['String'];
    htmltemplate: Scalars['String'];
    texttemplate: Scalars['String'];
    createdAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
};

export type EmailTemplateAggregates = {
    __typename?: 'EmailTemplateAggregates';
    keys?: Maybe<Array<Scalars['String']>>;
    /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    sum?: Maybe<EmailTemplateSumAggregates>;
    /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
    distinctCount?: Maybe<EmailTemplateDistinctCountAggregates>;
    /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    min?: Maybe<EmailTemplateMinAggregates>;
    /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    max?: Maybe<EmailTemplateMaxAggregates>;
    /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
    average?: Maybe<EmailTemplateAverageAggregates>;
    /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
    stddevSample?: Maybe<EmailTemplateStddevSampleAggregates>;
    /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
    stddevPopulation?: Maybe<EmailTemplateStddevPopulationAggregates>;
    /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
    varianceSample?: Maybe<EmailTemplateVarianceSampleAggregates>;
    /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
    variancePopulation?: Maybe<EmailTemplateVariancePopulationAggregates>;
};

export type EmailTemplateAverageAggregates = {
    __typename?: 'EmailTemplateAverageAggregates';
    /** Mean average of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `EmailTemplate` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type EmailTemplateCondition = {
    /** Checks for equality with the object’s `id` field. */
    id?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `shortcut` field. */
    shortcut?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `subject` field. */
    subject?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `htmltemplate` field. */
    htmltemplate?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `texttemplate` field. */
    texttemplate?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `createdAt` field. */
    createdAt?: Maybe<Scalars['Datetime']>;
    /** Checks for equality with the object’s `updatedAt` field. */
    updatedAt?: Maybe<Scalars['Datetime']>;
};

export type EmailTemplateDistinctCountAggregates = {
    __typename?: 'EmailTemplateDistinctCountAggregates';
    /** Distinct count of id across the matching connection */
    id?: Maybe<Scalars['BigInt']>;
    /** Distinct count of shortcut across the matching connection */
    shortcut?: Maybe<Scalars['BigInt']>;
    /** Distinct count of subject across the matching connection */
    subject?: Maybe<Scalars['BigInt']>;
    /** Distinct count of htmltemplate across the matching connection */
    htmltemplate?: Maybe<Scalars['BigInt']>;
    /** Distinct count of texttemplate across the matching connection */
    texttemplate?: Maybe<Scalars['BigInt']>;
    /** Distinct count of createdAt across the matching connection */
    createdAt?: Maybe<Scalars['BigInt']>;
    /** Distinct count of updatedAt across the matching connection */
    updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `EmailTemplate` object types. All fields are combined with a logical ‘and.’ */
export type EmailTemplateFilter = {
    /** Filter by the object’s `id` field. */
    id?: Maybe<IntFilter>;
    /** Filter by the object’s `shortcut` field. */
    shortcut?: Maybe<StringFilter>;
    /** Filter by the object’s `subject` field. */
    subject?: Maybe<StringFilter>;
    /** Filter by the object’s `htmltemplate` field. */
    htmltemplate?: Maybe<StringFilter>;
    /** Filter by the object’s `texttemplate` field. */
    texttemplate?: Maybe<StringFilter>;
    /** Filter by the object’s `createdAt` field. */
    createdAt?: Maybe<DatetimeFilter>;
    /** Filter by the object’s `updatedAt` field. */
    updatedAt?: Maybe<DatetimeFilter>;
    /** Checks for all expressions in this list. */
    and?: Maybe<Array<EmailTemplateFilter>>;
    /** Checks for any expressions in this list. */
    or?: Maybe<Array<EmailTemplateFilter>>;
    /** Negates the expression. */
    not?: Maybe<EmailTemplateFilter>;
};

/** An input for mutations affecting `EmailTemplate` */
export type EmailTemplateInput = {
    id?: Maybe<Scalars['Int']>;
    shortcut: Scalars['String'];
    subject: Scalars['String'];
    htmltemplate: Scalars['String'];
    texttemplate: Scalars['String'];
    createdAt?: Maybe<Scalars['Datetime']>;
    updatedAt?: Maybe<Scalars['Datetime']>;
};

export type EmailTemplateMaxAggregates = {
    __typename?: 'EmailTemplateMaxAggregates';
    /** Maximum of id across the matching connection */
    id?: Maybe<Scalars['Int']>;
};

export type EmailTemplateMinAggregates = {
    __typename?: 'EmailTemplateMinAggregates';
    /** Minimum of id across the matching connection */
    id?: Maybe<Scalars['Int']>;
};

/** Represents an update to a `EmailTemplate`. Fields that are set will be updated. */
export type EmailTemplatePatch = {
    id?: Maybe<Scalars['Int']>;
    shortcut?: Maybe<Scalars['String']>;
    subject?: Maybe<Scalars['String']>;
    htmltemplate?: Maybe<Scalars['String']>;
    texttemplate?: Maybe<Scalars['String']>;
    createdAt?: Maybe<Scalars['Datetime']>;
    updatedAt?: Maybe<Scalars['Datetime']>;
};

export type EmailTemplateStddevPopulationAggregates = {
    __typename?: 'EmailTemplateStddevPopulationAggregates';
    /** Population standard deviation of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
};

export type EmailTemplateStddevSampleAggregates = {
    __typename?: 'EmailTemplateStddevSampleAggregates';
    /** Sample standard deviation of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
};

export type EmailTemplateSumAggregates = {
    __typename?: 'EmailTemplateSumAggregates';
    /** Sum of id across the matching connection */
    id: Scalars['BigInt'];
};

export type EmailTemplateVariancePopulationAggregates = {
    __typename?: 'EmailTemplateVariancePopulationAggregates';
    /** Population variance of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
};

export type EmailTemplateVarianceSampleAggregates = {
    __typename?: 'EmailTemplateVarianceSampleAggregates';
    /** Sample variance of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `EmailTemplate` values. */
export type EmailTemplatesConnection = {
    __typename?: 'EmailTemplatesConnection';
    /** A list of `EmailTemplate` objects. */
    nodes: Array<EmailTemplate>;
    /** A list of edges which contains the `EmailTemplate` and cursor to aid in pagination. */
    edges: Array<EmailTemplatesEdge>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `EmailTemplate` you could get from the connection. */
    totalCount: Scalars['Int'];
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<EmailTemplateAggregates>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<EmailTemplateAggregates>>;
};

/** A connection to a list of `EmailTemplate` values. */
export type EmailTemplatesConnectionGroupedAggregatesArgs = {
    groupBy: Array<EmailTemplatesGroupBy>;
    having?: Maybe<EmailTemplatesHavingInput>;
};

/** A `EmailTemplate` edge in the connection. */
export type EmailTemplatesEdge = {
    __typename?: 'EmailTemplatesEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `EmailTemplate` at the end of the edge. */
    node: EmailTemplate;
};

/** Grouping methods for `EmailTemplate` for usage during aggregation. */
export enum EmailTemplatesGroupBy {
    Shortcut = 'SHORTCUT',
    Subject = 'SUBJECT',
    Htmltemplate = 'HTMLTEMPLATE',
    Texttemplate = 'TEXTTEMPLATE',
    CreatedAt = 'CREATED_AT',
    CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
    CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
    UpdatedAt = 'UPDATED_AT',
    UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
    UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
}

export type EmailTemplatesHavingAverageInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EmailTemplatesHavingDistinctCountInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `EmailTemplate` aggregates. */
export type EmailTemplatesHavingInput = {
    AND?: Maybe<Array<EmailTemplatesHavingInput>>;
    OR?: Maybe<Array<EmailTemplatesHavingInput>>;
    sum?: Maybe<EmailTemplatesHavingSumInput>;
    distinctCount?: Maybe<EmailTemplatesHavingDistinctCountInput>;
    min?: Maybe<EmailTemplatesHavingMinInput>;
    max?: Maybe<EmailTemplatesHavingMaxInput>;
    average?: Maybe<EmailTemplatesHavingAverageInput>;
    stddevSample?: Maybe<EmailTemplatesHavingStddevSampleInput>;
    stddevPopulation?: Maybe<EmailTemplatesHavingStddevPopulationInput>;
    varianceSample?: Maybe<EmailTemplatesHavingVarianceSampleInput>;
    variancePopulation?: Maybe<EmailTemplatesHavingVariancePopulationInput>;
};

export type EmailTemplatesHavingMaxInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EmailTemplatesHavingMinInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EmailTemplatesHavingStddevPopulationInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EmailTemplatesHavingStddevSampleInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EmailTemplatesHavingSumInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EmailTemplatesHavingVariancePopulationInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type EmailTemplatesHavingVarianceSampleInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `EmailTemplate`. */
export enum EmailTemplatesOrderBy {
    Natural = 'NATURAL',
    IdAsc = 'ID_ASC',
    IdDesc = 'ID_DESC',
    ShortcutAsc = 'SHORTCUT_ASC',
    ShortcutDesc = 'SHORTCUT_DESC',
    SubjectAsc = 'SUBJECT_ASC',
    SubjectDesc = 'SUBJECT_DESC',
    HtmltemplateAsc = 'HTMLTEMPLATE_ASC',
    HtmltemplateDesc = 'HTMLTEMPLATE_DESC',
    TexttemplateAsc = 'TEXTTEMPLATE_ASC',
    TexttemplateDesc = 'TEXTTEMPLATE_DESC',
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
    UpdatedAtAsc = 'UPDATED_AT_ASC',
    UpdatedAtDesc = 'UPDATED_AT_DESC',
    PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
    PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

/** A filter to be used against Float fields. All fields are combined with a logical ‘and.’ */
export type FloatFilter = {
    /** Is null (if `true` is specified) or is not null (if `false` is specified). */
    isNull?: Maybe<Scalars['Boolean']>;
    /** Equal to the specified value. */
    equalTo?: Maybe<Scalars['Float']>;
    /** Not equal to the specified value. */
    notEqualTo?: Maybe<Scalars['Float']>;
    /** Not equal to the specified value, treating null like an ordinary value. */
    distinctFrom?: Maybe<Scalars['Float']>;
    /** Equal to the specified value, treating null like an ordinary value. */
    notDistinctFrom?: Maybe<Scalars['Float']>;
    /** Included in the specified list. */
    in?: Maybe<Array<Scalars['Float']>>;
    /** Not included in the specified list. */
    notIn?: Maybe<Array<Scalars['Float']>>;
    /** Less than the specified value. */
    lessThan?: Maybe<Scalars['Float']>;
    /** Less than or equal to the specified value. */
    lessThanOrEqualTo?: Maybe<Scalars['Float']>;
    /** Greater than the specified value. */
    greaterThan?: Maybe<Scalars['Float']>;
    /** Greater than or equal to the specified value. */
    greaterThanOrEqualTo?: Maybe<Scalars['Float']>;
};

export type HavingBigfloatFilter = {
    equalTo?: Maybe<Scalars['BigFloat']>;
    notEqualTo?: Maybe<Scalars['BigFloat']>;
    greaterThan?: Maybe<Scalars['BigFloat']>;
    greaterThanOrEqualTo?: Maybe<Scalars['BigFloat']>;
    lessThan?: Maybe<Scalars['BigFloat']>;
    lessThanOrEqualTo?: Maybe<Scalars['BigFloat']>;
};

export type HavingBigintFilter = {
    equalTo?: Maybe<Scalars['BigInt']>;
    notEqualTo?: Maybe<Scalars['BigInt']>;
    greaterThan?: Maybe<Scalars['BigInt']>;
    greaterThanOrEqualTo?: Maybe<Scalars['BigInt']>;
    lessThan?: Maybe<Scalars['BigInt']>;
    lessThanOrEqualTo?: Maybe<Scalars['BigInt']>;
};

export type HavingDatetimeFilter = {
    equalTo?: Maybe<Scalars['Datetime']>;
    notEqualTo?: Maybe<Scalars['Datetime']>;
    greaterThan?: Maybe<Scalars['Datetime']>;
    greaterThanOrEqualTo?: Maybe<Scalars['Datetime']>;
    lessThan?: Maybe<Scalars['Datetime']>;
    lessThanOrEqualTo?: Maybe<Scalars['Datetime']>;
};

export type HavingFloatFilter = {
    equalTo?: Maybe<Scalars['Float']>;
    notEqualTo?: Maybe<Scalars['Float']>;
    greaterThan?: Maybe<Scalars['Float']>;
    greaterThanOrEqualTo?: Maybe<Scalars['Float']>;
    lessThan?: Maybe<Scalars['Float']>;
    lessThanOrEqualTo?: Maybe<Scalars['Float']>;
};

export type HavingIntFilter = {
    equalTo?: Maybe<Scalars['Int']>;
    notEqualTo?: Maybe<Scalars['Int']>;
    greaterThan?: Maybe<Scalars['Int']>;
    greaterThanOrEqualTo?: Maybe<Scalars['Int']>;
    lessThan?: Maybe<Scalars['Int']>;
    lessThanOrEqualTo?: Maybe<Scalars['Int']>;
};

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
    /** Is null (if `true` is specified) or is not null (if `false` is specified). */
    isNull?: Maybe<Scalars['Boolean']>;
    /** Equal to the specified value. */
    equalTo?: Maybe<Scalars['Int']>;
    /** Not equal to the specified value. */
    notEqualTo?: Maybe<Scalars['Int']>;
    /** Not equal to the specified value, treating null like an ordinary value. */
    distinctFrom?: Maybe<Scalars['Int']>;
    /** Equal to the specified value, treating null like an ordinary value. */
    notDistinctFrom?: Maybe<Scalars['Int']>;
    /** Included in the specified list. */
    in?: Maybe<Array<Scalars['Int']>>;
    /** Not included in the specified list. */
    notIn?: Maybe<Array<Scalars['Int']>>;
    /** Less than the specified value. */
    lessThan?: Maybe<Scalars['Int']>;
    /** Less than or equal to the specified value. */
    lessThanOrEqualTo?: Maybe<Scalars['Int']>;
    /** Greater than the specified value. */
    greaterThan?: Maybe<Scalars['Int']>;
    /** Greater than or equal to the specified value. */
    greaterThanOrEqualTo?: Maybe<Scalars['Int']>;
};

/** A filter to be used against JSON fields. All fields are combined with a logical ‘and.’ */
export type JsonFilter = {
    /** Is null (if `true` is specified) or is not null (if `false` is specified). */
    isNull?: Maybe<Scalars['Boolean']>;
    /** Equal to the specified value. */
    equalTo?: Maybe<Scalars['JSON']>;
    /** Not equal to the specified value. */
    notEqualTo?: Maybe<Scalars['JSON']>;
    /** Not equal to the specified value, treating null like an ordinary value. */
    distinctFrom?: Maybe<Scalars['JSON']>;
    /** Equal to the specified value, treating null like an ordinary value. */
    notDistinctFrom?: Maybe<Scalars['JSON']>;
    /** Included in the specified list. */
    in?: Maybe<Array<Scalars['JSON']>>;
    /** Not included in the specified list. */
    notIn?: Maybe<Array<Scalars['JSON']>>;
    /** Less than the specified value. */
    lessThan?: Maybe<Scalars['JSON']>;
    /** Less than or equal to the specified value. */
    lessThanOrEqualTo?: Maybe<Scalars['JSON']>;
    /** Greater than the specified value. */
    greaterThan?: Maybe<Scalars['JSON']>;
    /** Greater than or equal to the specified value. */
    greaterThanOrEqualTo?: Maybe<Scalars['JSON']>;
    /** Contains the specified JSON. */
    contains?: Maybe<Scalars['JSON']>;
    /** Contains the specified key. */
    containsKey?: Maybe<Scalars['String']>;
    /** Contains all of the specified keys. */
    containsAllKeys?: Maybe<Array<Scalars['String']>>;
    /** Contains any of the specified keys. */
    containsAnyKeys?: Maybe<Array<Scalars['String']>>;
    /** Contained by the specified JSON. */
    containedBy?: Maybe<Scalars['JSON']>;
};

export type Location = Node & {
    __typename?: 'Location';
    /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
    nodeId: Scalars['ID'];
    id: Scalars['Int'];
    title?: Maybe<Scalars['String']>;
    street?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    postcode?: Maybe<Scalars['String']>;
    country: Scalars['String'];
    remarks?: Maybe<Scalars['String']>;
    archived?: Maybe<Scalars['Boolean']>;
    createdAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    /** Reads and enables pagination through a set of `Course`. */
    coursesByDefaultLocationId: CoursesConnection;
    /** Reads and enables pagination through a set of `CourseSchedule`. */
    courseSchedules: CourseSchedulesConnection;
    /** Reads and enables pagination through a set of `EducationClassSchedule`. */
    educationClassSchedules: EducationClassSchedulesConnection;
    address?: Maybe<Scalars['String']>;
    addressComplete?: Maybe<Scalars['String']>;
};

export type LocationCoursesByDefaultLocationIdArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['Cursor']>;
    after?: Maybe<Scalars['Cursor']>;
    orderBy?: Maybe<Array<CoursesOrderBy>>;
    condition?: Maybe<CourseCondition>;
    filter?: Maybe<CourseFilter>;
};

export type LocationCourseSchedulesArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['Cursor']>;
    after?: Maybe<Scalars['Cursor']>;
    orderBy?: Maybe<Array<CourseSchedulesOrderBy>>;
    condition?: Maybe<CourseScheduleCondition>;
    filter?: Maybe<CourseScheduleFilter>;
};

export type LocationEducationClassSchedulesArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['Cursor']>;
    after?: Maybe<Scalars['Cursor']>;
    orderBy?: Maybe<Array<EducationClassSchedulesOrderBy>>;
    condition?: Maybe<EducationClassScheduleCondition>;
    filter?: Maybe<EducationClassScheduleFilter>;
};

export type LocationAddressCompleteArgs = {
    delimiter?: Maybe<Scalars['String']>;
};

export type LocationAggregates = {
    __typename?: 'LocationAggregates';
    keys?: Maybe<Array<Scalars['String']>>;
    /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    sum?: Maybe<LocationSumAggregates>;
    /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
    distinctCount?: Maybe<LocationDistinctCountAggregates>;
    /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    min?: Maybe<LocationMinAggregates>;
    /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    max?: Maybe<LocationMaxAggregates>;
    /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
    average?: Maybe<LocationAverageAggregates>;
    /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
    stddevSample?: Maybe<LocationStddevSampleAggregates>;
    /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
    stddevPopulation?: Maybe<LocationStddevPopulationAggregates>;
    /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
    varianceSample?: Maybe<LocationVarianceSampleAggregates>;
    /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
    variancePopulation?: Maybe<LocationVariancePopulationAggregates>;
};

export type LocationAverageAggregates = {
    __typename?: 'LocationAverageAggregates';
    /** Mean average of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `Location` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type LocationCondition = {
    /** Checks for equality with the object’s `id` field. */
    id?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `title` field. */
    title?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `street` field. */
    street?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `city` field. */
    city?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `postcode` field. */
    postcode?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `country` field. */
    country?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `remarks` field. */
    remarks?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `archived` field. */
    archived?: Maybe<Scalars['Boolean']>;
    /** Checks for equality with the object’s `createdAt` field. */
    createdAt?: Maybe<Scalars['Datetime']>;
    /** Checks for equality with the object’s `updatedAt` field. */
    updatedAt?: Maybe<Scalars['Datetime']>;
};

export type LocationDistinctCountAggregates = {
    __typename?: 'LocationDistinctCountAggregates';
    /** Distinct count of id across the matching connection */
    id?: Maybe<Scalars['BigInt']>;
    /** Distinct count of title across the matching connection */
    title?: Maybe<Scalars['BigInt']>;
    /** Distinct count of street across the matching connection */
    street?: Maybe<Scalars['BigInt']>;
    /** Distinct count of city across the matching connection */
    city?: Maybe<Scalars['BigInt']>;
    /** Distinct count of postcode across the matching connection */
    postcode?: Maybe<Scalars['BigInt']>;
    /** Distinct count of country across the matching connection */
    country?: Maybe<Scalars['BigInt']>;
    /** Distinct count of remarks across the matching connection */
    remarks?: Maybe<Scalars['BigInt']>;
    /** Distinct count of archived across the matching connection */
    archived?: Maybe<Scalars['BigInt']>;
    /** Distinct count of createdAt across the matching connection */
    createdAt?: Maybe<Scalars['BigInt']>;
    /** Distinct count of updatedAt across the matching connection */
    updatedAt?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    address?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    addressComplete?: Maybe<Scalars['BigInt']>;
};

export type LocationDistinctCountAggregatesAddressCompleteArgs = {
    delimiter?: Maybe<Scalars['String']>;
};

/** A filter to be used against `Location` object types. All fields are combined with a logical ‘and.’ */
export type LocationFilter = {
    /** Filter by the object’s `id` field. */
    id?: Maybe<IntFilter>;
    /** Filter by the object’s `title` field. */
    title?: Maybe<StringFilter>;
    /** Filter by the object’s `street` field. */
    street?: Maybe<StringFilter>;
    /** Filter by the object’s `city` field. */
    city?: Maybe<StringFilter>;
    /** Filter by the object’s `postcode` field. */
    postcode?: Maybe<StringFilter>;
    /** Filter by the object’s `country` field. */
    country?: Maybe<StringFilter>;
    /** Filter by the object’s `remarks` field. */
    remarks?: Maybe<StringFilter>;
    /** Filter by the object’s `archived` field. */
    archived?: Maybe<BooleanFilter>;
    /** Filter by the object’s `createdAt` field. */
    createdAt?: Maybe<DatetimeFilter>;
    /** Filter by the object’s `updatedAt` field. */
    updatedAt?: Maybe<DatetimeFilter>;
    /** Filter by the object’s `address` field. */
    address?: Maybe<StringFilter>;
    /** Filter by the object’s `addressComplete` field. */
    addressComplete?: Maybe<StringFilter>;
    /** Checks for all expressions in this list. */
    and?: Maybe<Array<LocationFilter>>;
    /** Checks for any expressions in this list. */
    or?: Maybe<Array<LocationFilter>>;
    /** Negates the expression. */
    not?: Maybe<LocationFilter>;
};

/** Grouping methods for `Location` for usage during aggregation. */
export enum LocationGroupBy {
    Title = 'TITLE',
    Street = 'STREET',
    City = 'CITY',
    Postcode = 'POSTCODE',
    Country = 'COUNTRY',
    Remarks = 'REMARKS',
    Archived = 'ARCHIVED',
    CreatedAt = 'CREATED_AT',
    CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
    CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
    UpdatedAt = 'UPDATED_AT',
    UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
    UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
}

export type LocationHavingAverageInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LocationHavingDistinctCountInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `Location` aggregates. */
export type LocationHavingInput = {
    AND?: Maybe<Array<LocationHavingInput>>;
    OR?: Maybe<Array<LocationHavingInput>>;
    sum?: Maybe<LocationHavingSumInput>;
    distinctCount?: Maybe<LocationHavingDistinctCountInput>;
    min?: Maybe<LocationHavingMinInput>;
    max?: Maybe<LocationHavingMaxInput>;
    average?: Maybe<LocationHavingAverageInput>;
    stddevSample?: Maybe<LocationHavingStddevSampleInput>;
    stddevPopulation?: Maybe<LocationHavingStddevPopulationInput>;
    varianceSample?: Maybe<LocationHavingVarianceSampleInput>;
    variancePopulation?: Maybe<LocationHavingVariancePopulationInput>;
};

export type LocationHavingMaxInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LocationHavingMinInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LocationHavingStddevPopulationInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LocationHavingStddevSampleInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LocationHavingSumInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LocationHavingVariancePopulationInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type LocationHavingVarianceSampleInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `Location` */
export type LocationInput = {
    id?: Maybe<Scalars['Int']>;
    title?: Maybe<Scalars['String']>;
    street?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    postcode?: Maybe<Scalars['String']>;
    country: Scalars['String'];
    remarks?: Maybe<Scalars['String']>;
    archived?: Maybe<Scalars['Boolean']>;
    createdAt?: Maybe<Scalars['Datetime']>;
    updatedAt?: Maybe<Scalars['Datetime']>;
};

export type LocationMaxAggregates = {
    __typename?: 'LocationMaxAggregates';
    /** Maximum of id across the matching connection */
    id?: Maybe<Scalars['Int']>;
};

export type LocationMinAggregates = {
    __typename?: 'LocationMinAggregates';
    /** Minimum of id across the matching connection */
    id?: Maybe<Scalars['Int']>;
};

/** Represents an update to a `Location`. Fields that are set will be updated. */
export type LocationPatch = {
    id?: Maybe<Scalars['Int']>;
    title?: Maybe<Scalars['String']>;
    street?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    postcode?: Maybe<Scalars['String']>;
    country?: Maybe<Scalars['String']>;
    remarks?: Maybe<Scalars['String']>;
    archived?: Maybe<Scalars['Boolean']>;
    createdAt?: Maybe<Scalars['Datetime']>;
    updatedAt?: Maybe<Scalars['Datetime']>;
};

export type LocationStddevPopulationAggregates = {
    __typename?: 'LocationStddevPopulationAggregates';
    /** Population standard deviation of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
};

export type LocationStddevSampleAggregates = {
    __typename?: 'LocationStddevSampleAggregates';
    /** Sample standard deviation of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
};

export type LocationSumAggregates = {
    __typename?: 'LocationSumAggregates';
    /** Sum of id across the matching connection */
    id: Scalars['BigInt'];
};

export type LocationVariancePopulationAggregates = {
    __typename?: 'LocationVariancePopulationAggregates';
    /** Population variance of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
};

export type LocationVarianceSampleAggregates = {
    __typename?: 'LocationVarianceSampleAggregates';
    /** Sample variance of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Location` values. */
export type LocationsConnection = {
    __typename?: 'LocationsConnection';
    /** A list of `Location` objects. */
    nodes: Array<Location>;
    /** A list of edges which contains the `Location` and cursor to aid in pagination. */
    edges: Array<LocationsEdge>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `Location` you could get from the connection. */
    totalCount: Scalars['Int'];
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<LocationAggregates>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<LocationAggregates>>;
};

/** A connection to a list of `Location` values. */
export type LocationsConnectionGroupedAggregatesArgs = {
    groupBy: Array<LocationGroupBy>;
    having?: Maybe<LocationHavingInput>;
};

/** A `Location` edge in the connection. */
export type LocationsEdge = {
    __typename?: 'LocationsEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `Location` at the end of the edge. */
    node: Location;
};

/** Methods to use when ordering `Location`. */
export enum LocationsOrderBy {
    Natural = 'NATURAL',
    IdAsc = 'ID_ASC',
    IdDesc = 'ID_DESC',
    TitleAsc = 'TITLE_ASC',
    TitleDesc = 'TITLE_DESC',
    StreetAsc = 'STREET_ASC',
    StreetDesc = 'STREET_DESC',
    CityAsc = 'CITY_ASC',
    CityDesc = 'CITY_DESC',
    PostcodeAsc = 'POSTCODE_ASC',
    PostcodeDesc = 'POSTCODE_DESC',
    CountryAsc = 'COUNTRY_ASC',
    CountryDesc = 'COUNTRY_DESC',
    RemarksAsc = 'REMARKS_ASC',
    RemarksDesc = 'REMARKS_DESC',
    ArchivedAsc = 'ARCHIVED_ASC',
    ArchivedDesc = 'ARCHIVED_DESC',
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
    UpdatedAtAsc = 'UPDATED_AT_ASC',
    UpdatedAtDesc = 'UPDATED_AT_DESC',
    PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
    PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
    CoursesByDefaultLocationIdCountAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_COUNT_ASC',
    CoursesByDefaultLocationIdCountDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_COUNT_DESC',
    CoursesByDefaultLocationIdSumIdAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_SUM_ID_ASC',
    CoursesByDefaultLocationIdSumIdDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_SUM_ID_DESC',
    CoursesByDefaultLocationIdSumNameAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_SUM_NAME_ASC',
    CoursesByDefaultLocationIdSumNameDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_SUM_NAME_DESC',
    CoursesByDefaultLocationIdSumDescriptionAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_SUM_DESCRIPTION_ASC',
    CoursesByDefaultLocationIdSumDescriptionDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_SUM_DESCRIPTION_DESC',
    CoursesByDefaultLocationIdSumCommentAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_SUM_COMMENT_ASC',
    CoursesByDefaultLocationIdSumCommentDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_SUM_COMMENT_DESC',
    CoursesByDefaultLocationIdSumDetailsAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_SUM_DETAILS_ASC',
    CoursesByDefaultLocationIdSumDetailsDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_SUM_DETAILS_DESC',
    CoursesByDefaultLocationIdSumArchivedAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_SUM_ARCHIVED_ASC',
    CoursesByDefaultLocationIdSumArchivedDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_SUM_ARCHIVED_DESC',
    CoursesByDefaultLocationIdSumDefaultLocationIdAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_SUM_DEFAULT_LOCATION_ID_ASC',
    CoursesByDefaultLocationIdSumDefaultLocationIdDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_SUM_DEFAULT_LOCATION_ID_DESC',
    CoursesByDefaultLocationIdSumDefaultTimetableAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_SUM_DEFAULT_TIMETABLE_ASC',
    CoursesByDefaultLocationIdSumDefaultTimetableDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_SUM_DEFAULT_TIMETABLE_DESC',
    CoursesByDefaultLocationIdSumCourseGroupIdAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_SUM_COURSE_GROUP_ID_ASC',
    CoursesByDefaultLocationIdSumCourseGroupIdDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_SUM_COURSE_GROUP_ID_DESC',
    CoursesByDefaultLocationIdSumCreatedAtAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_SUM_CREATED_AT_ASC',
    CoursesByDefaultLocationIdSumCreatedAtDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_SUM_CREATED_AT_DESC',
    CoursesByDefaultLocationIdSumUpdatedAtAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_SUM_UPDATED_AT_ASC',
    CoursesByDefaultLocationIdSumUpdatedAtDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_SUM_UPDATED_AT_DESC',
    CoursesByDefaultLocationIdSumDefaultSeatsAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_SUM_DEFAULT_SEATS_ASC',
    CoursesByDefaultLocationIdSumDefaultSeatsDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_SUM_DEFAULT_SEATS_DESC',
    CoursesByDefaultLocationIdSumDefaultDurationAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_SUM_DEFAULT_DURATION_ASC',
    CoursesByDefaultLocationIdSumDefaultDurationDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_SUM_DEFAULT_DURATION_DESC',
    CoursesByDefaultLocationIdSumDefaultUnitsAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_SUM_DEFAULT_UNITS_ASC',
    CoursesByDefaultLocationIdSumDefaultUnitsDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_SUM_DEFAULT_UNITS_DESC',
    CoursesByDefaultLocationIdSumDefaultPriceAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_SUM_DEFAULT_PRICE_ASC',
    CoursesByDefaultLocationIdSumDefaultPriceDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_SUM_DEFAULT_PRICE_DESC',
    CoursesByDefaultLocationIdSumCertSetupAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_SUM_CERT_SETUP_ASC',
    CoursesByDefaultLocationIdSumCertSetupDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_SUM_CERT_SETUP_DESC',
    CoursesByDefaultLocationIdSumConfirmationContentAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_SUM_CONFIRMATION_CONTENT_ASC',
    CoursesByDefaultLocationIdSumConfirmationContentDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_SUM_CONFIRMATION_CONTENT_DESC',
    CoursesByDefaultLocationIdDistinctCountIdAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_DISTINCT_COUNT_ID_ASC',
    CoursesByDefaultLocationIdDistinctCountIdDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_DISTINCT_COUNT_ID_DESC',
    CoursesByDefaultLocationIdDistinctCountNameAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_DISTINCT_COUNT_NAME_ASC',
    CoursesByDefaultLocationIdDistinctCountNameDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_DISTINCT_COUNT_NAME_DESC',
    CoursesByDefaultLocationIdDistinctCountDescriptionAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_DISTINCT_COUNT_DESCRIPTION_ASC',
    CoursesByDefaultLocationIdDistinctCountDescriptionDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_DISTINCT_COUNT_DESCRIPTION_DESC',
    CoursesByDefaultLocationIdDistinctCountCommentAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_DISTINCT_COUNT_COMMENT_ASC',
    CoursesByDefaultLocationIdDistinctCountCommentDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_DISTINCT_COUNT_COMMENT_DESC',
    CoursesByDefaultLocationIdDistinctCountDetailsAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_DISTINCT_COUNT_DETAILS_ASC',
    CoursesByDefaultLocationIdDistinctCountDetailsDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_DISTINCT_COUNT_DETAILS_DESC',
    CoursesByDefaultLocationIdDistinctCountArchivedAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_DISTINCT_COUNT_ARCHIVED_ASC',
    CoursesByDefaultLocationIdDistinctCountArchivedDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_DISTINCT_COUNT_ARCHIVED_DESC',
    CoursesByDefaultLocationIdDistinctCountDefaultLocationIdAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_DISTINCT_COUNT_DEFAULT_LOCATION_ID_ASC',
    CoursesByDefaultLocationIdDistinctCountDefaultLocationIdDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_DISTINCT_COUNT_DEFAULT_LOCATION_ID_DESC',
    CoursesByDefaultLocationIdDistinctCountDefaultTimetableAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_DISTINCT_COUNT_DEFAULT_TIMETABLE_ASC',
    CoursesByDefaultLocationIdDistinctCountDefaultTimetableDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_DISTINCT_COUNT_DEFAULT_TIMETABLE_DESC',
    CoursesByDefaultLocationIdDistinctCountCourseGroupIdAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_DISTINCT_COUNT_COURSE_GROUP_ID_ASC',
    CoursesByDefaultLocationIdDistinctCountCourseGroupIdDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_DISTINCT_COUNT_COURSE_GROUP_ID_DESC',
    CoursesByDefaultLocationIdDistinctCountCreatedAtAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_DISTINCT_COUNT_CREATED_AT_ASC',
    CoursesByDefaultLocationIdDistinctCountCreatedAtDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_DISTINCT_COUNT_CREATED_AT_DESC',
    CoursesByDefaultLocationIdDistinctCountUpdatedAtAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_DISTINCT_COUNT_UPDATED_AT_ASC',
    CoursesByDefaultLocationIdDistinctCountUpdatedAtDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_DISTINCT_COUNT_UPDATED_AT_DESC',
    CoursesByDefaultLocationIdDistinctCountDefaultSeatsAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_DISTINCT_COUNT_DEFAULT_SEATS_ASC',
    CoursesByDefaultLocationIdDistinctCountDefaultSeatsDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_DISTINCT_COUNT_DEFAULT_SEATS_DESC',
    CoursesByDefaultLocationIdDistinctCountDefaultDurationAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_DISTINCT_COUNT_DEFAULT_DURATION_ASC',
    CoursesByDefaultLocationIdDistinctCountDefaultDurationDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_DISTINCT_COUNT_DEFAULT_DURATION_DESC',
    CoursesByDefaultLocationIdDistinctCountDefaultUnitsAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_DISTINCT_COUNT_DEFAULT_UNITS_ASC',
    CoursesByDefaultLocationIdDistinctCountDefaultUnitsDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_DISTINCT_COUNT_DEFAULT_UNITS_DESC',
    CoursesByDefaultLocationIdDistinctCountDefaultPriceAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_DISTINCT_COUNT_DEFAULT_PRICE_ASC',
    CoursesByDefaultLocationIdDistinctCountDefaultPriceDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_DISTINCT_COUNT_DEFAULT_PRICE_DESC',
    CoursesByDefaultLocationIdDistinctCountCertSetupAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_DISTINCT_COUNT_CERT_SETUP_ASC',
    CoursesByDefaultLocationIdDistinctCountCertSetupDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_DISTINCT_COUNT_CERT_SETUP_DESC',
    CoursesByDefaultLocationIdDistinctCountConfirmationContentAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_DISTINCT_COUNT_CONFIRMATION_CONTENT_ASC',
    CoursesByDefaultLocationIdDistinctCountConfirmationContentDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_DISTINCT_COUNT_CONFIRMATION_CONTENT_DESC',
    CoursesByDefaultLocationIdMinIdAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_MIN_ID_ASC',
    CoursesByDefaultLocationIdMinIdDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_MIN_ID_DESC',
    CoursesByDefaultLocationIdMinNameAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_MIN_NAME_ASC',
    CoursesByDefaultLocationIdMinNameDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_MIN_NAME_DESC',
    CoursesByDefaultLocationIdMinDescriptionAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_MIN_DESCRIPTION_ASC',
    CoursesByDefaultLocationIdMinDescriptionDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_MIN_DESCRIPTION_DESC',
    CoursesByDefaultLocationIdMinCommentAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_MIN_COMMENT_ASC',
    CoursesByDefaultLocationIdMinCommentDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_MIN_COMMENT_DESC',
    CoursesByDefaultLocationIdMinDetailsAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_MIN_DETAILS_ASC',
    CoursesByDefaultLocationIdMinDetailsDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_MIN_DETAILS_DESC',
    CoursesByDefaultLocationIdMinArchivedAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_MIN_ARCHIVED_ASC',
    CoursesByDefaultLocationIdMinArchivedDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_MIN_ARCHIVED_DESC',
    CoursesByDefaultLocationIdMinDefaultLocationIdAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_MIN_DEFAULT_LOCATION_ID_ASC',
    CoursesByDefaultLocationIdMinDefaultLocationIdDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_MIN_DEFAULT_LOCATION_ID_DESC',
    CoursesByDefaultLocationIdMinDefaultTimetableAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_MIN_DEFAULT_TIMETABLE_ASC',
    CoursesByDefaultLocationIdMinDefaultTimetableDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_MIN_DEFAULT_TIMETABLE_DESC',
    CoursesByDefaultLocationIdMinCourseGroupIdAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_MIN_COURSE_GROUP_ID_ASC',
    CoursesByDefaultLocationIdMinCourseGroupIdDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_MIN_COURSE_GROUP_ID_DESC',
    CoursesByDefaultLocationIdMinCreatedAtAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_MIN_CREATED_AT_ASC',
    CoursesByDefaultLocationIdMinCreatedAtDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_MIN_CREATED_AT_DESC',
    CoursesByDefaultLocationIdMinUpdatedAtAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_MIN_UPDATED_AT_ASC',
    CoursesByDefaultLocationIdMinUpdatedAtDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_MIN_UPDATED_AT_DESC',
    CoursesByDefaultLocationIdMinDefaultSeatsAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_MIN_DEFAULT_SEATS_ASC',
    CoursesByDefaultLocationIdMinDefaultSeatsDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_MIN_DEFAULT_SEATS_DESC',
    CoursesByDefaultLocationIdMinDefaultDurationAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_MIN_DEFAULT_DURATION_ASC',
    CoursesByDefaultLocationIdMinDefaultDurationDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_MIN_DEFAULT_DURATION_DESC',
    CoursesByDefaultLocationIdMinDefaultUnitsAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_MIN_DEFAULT_UNITS_ASC',
    CoursesByDefaultLocationIdMinDefaultUnitsDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_MIN_DEFAULT_UNITS_DESC',
    CoursesByDefaultLocationIdMinDefaultPriceAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_MIN_DEFAULT_PRICE_ASC',
    CoursesByDefaultLocationIdMinDefaultPriceDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_MIN_DEFAULT_PRICE_DESC',
    CoursesByDefaultLocationIdMinCertSetupAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_MIN_CERT_SETUP_ASC',
    CoursesByDefaultLocationIdMinCertSetupDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_MIN_CERT_SETUP_DESC',
    CoursesByDefaultLocationIdMinConfirmationContentAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_MIN_CONFIRMATION_CONTENT_ASC',
    CoursesByDefaultLocationIdMinConfirmationContentDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_MIN_CONFIRMATION_CONTENT_DESC',
    CoursesByDefaultLocationIdMaxIdAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_MAX_ID_ASC',
    CoursesByDefaultLocationIdMaxIdDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_MAX_ID_DESC',
    CoursesByDefaultLocationIdMaxNameAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_MAX_NAME_ASC',
    CoursesByDefaultLocationIdMaxNameDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_MAX_NAME_DESC',
    CoursesByDefaultLocationIdMaxDescriptionAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_MAX_DESCRIPTION_ASC',
    CoursesByDefaultLocationIdMaxDescriptionDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_MAX_DESCRIPTION_DESC',
    CoursesByDefaultLocationIdMaxCommentAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_MAX_COMMENT_ASC',
    CoursesByDefaultLocationIdMaxCommentDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_MAX_COMMENT_DESC',
    CoursesByDefaultLocationIdMaxDetailsAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_MAX_DETAILS_ASC',
    CoursesByDefaultLocationIdMaxDetailsDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_MAX_DETAILS_DESC',
    CoursesByDefaultLocationIdMaxArchivedAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_MAX_ARCHIVED_ASC',
    CoursesByDefaultLocationIdMaxArchivedDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_MAX_ARCHIVED_DESC',
    CoursesByDefaultLocationIdMaxDefaultLocationIdAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_MAX_DEFAULT_LOCATION_ID_ASC',
    CoursesByDefaultLocationIdMaxDefaultLocationIdDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_MAX_DEFAULT_LOCATION_ID_DESC',
    CoursesByDefaultLocationIdMaxDefaultTimetableAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_MAX_DEFAULT_TIMETABLE_ASC',
    CoursesByDefaultLocationIdMaxDefaultTimetableDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_MAX_DEFAULT_TIMETABLE_DESC',
    CoursesByDefaultLocationIdMaxCourseGroupIdAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_MAX_COURSE_GROUP_ID_ASC',
    CoursesByDefaultLocationIdMaxCourseGroupIdDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_MAX_COURSE_GROUP_ID_DESC',
    CoursesByDefaultLocationIdMaxCreatedAtAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_MAX_CREATED_AT_ASC',
    CoursesByDefaultLocationIdMaxCreatedAtDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_MAX_CREATED_AT_DESC',
    CoursesByDefaultLocationIdMaxUpdatedAtAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_MAX_UPDATED_AT_ASC',
    CoursesByDefaultLocationIdMaxUpdatedAtDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_MAX_UPDATED_AT_DESC',
    CoursesByDefaultLocationIdMaxDefaultSeatsAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_MAX_DEFAULT_SEATS_ASC',
    CoursesByDefaultLocationIdMaxDefaultSeatsDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_MAX_DEFAULT_SEATS_DESC',
    CoursesByDefaultLocationIdMaxDefaultDurationAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_MAX_DEFAULT_DURATION_ASC',
    CoursesByDefaultLocationIdMaxDefaultDurationDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_MAX_DEFAULT_DURATION_DESC',
    CoursesByDefaultLocationIdMaxDefaultUnitsAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_MAX_DEFAULT_UNITS_ASC',
    CoursesByDefaultLocationIdMaxDefaultUnitsDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_MAX_DEFAULT_UNITS_DESC',
    CoursesByDefaultLocationIdMaxDefaultPriceAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_MAX_DEFAULT_PRICE_ASC',
    CoursesByDefaultLocationIdMaxDefaultPriceDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_MAX_DEFAULT_PRICE_DESC',
    CoursesByDefaultLocationIdMaxCertSetupAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_MAX_CERT_SETUP_ASC',
    CoursesByDefaultLocationIdMaxCertSetupDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_MAX_CERT_SETUP_DESC',
    CoursesByDefaultLocationIdMaxConfirmationContentAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_MAX_CONFIRMATION_CONTENT_ASC',
    CoursesByDefaultLocationIdMaxConfirmationContentDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_MAX_CONFIRMATION_CONTENT_DESC',
    CoursesByDefaultLocationIdAverageIdAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_AVERAGE_ID_ASC',
    CoursesByDefaultLocationIdAverageIdDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_AVERAGE_ID_DESC',
    CoursesByDefaultLocationIdAverageNameAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_AVERAGE_NAME_ASC',
    CoursesByDefaultLocationIdAverageNameDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_AVERAGE_NAME_DESC',
    CoursesByDefaultLocationIdAverageDescriptionAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_AVERAGE_DESCRIPTION_ASC',
    CoursesByDefaultLocationIdAverageDescriptionDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_AVERAGE_DESCRIPTION_DESC',
    CoursesByDefaultLocationIdAverageCommentAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_AVERAGE_COMMENT_ASC',
    CoursesByDefaultLocationIdAverageCommentDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_AVERAGE_COMMENT_DESC',
    CoursesByDefaultLocationIdAverageDetailsAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_AVERAGE_DETAILS_ASC',
    CoursesByDefaultLocationIdAverageDetailsDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_AVERAGE_DETAILS_DESC',
    CoursesByDefaultLocationIdAverageArchivedAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_AVERAGE_ARCHIVED_ASC',
    CoursesByDefaultLocationIdAverageArchivedDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_AVERAGE_ARCHIVED_DESC',
    CoursesByDefaultLocationIdAverageDefaultLocationIdAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_AVERAGE_DEFAULT_LOCATION_ID_ASC',
    CoursesByDefaultLocationIdAverageDefaultLocationIdDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_AVERAGE_DEFAULT_LOCATION_ID_DESC',
    CoursesByDefaultLocationIdAverageDefaultTimetableAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_AVERAGE_DEFAULT_TIMETABLE_ASC',
    CoursesByDefaultLocationIdAverageDefaultTimetableDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_AVERAGE_DEFAULT_TIMETABLE_DESC',
    CoursesByDefaultLocationIdAverageCourseGroupIdAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_AVERAGE_COURSE_GROUP_ID_ASC',
    CoursesByDefaultLocationIdAverageCourseGroupIdDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_AVERAGE_COURSE_GROUP_ID_DESC',
    CoursesByDefaultLocationIdAverageCreatedAtAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_AVERAGE_CREATED_AT_ASC',
    CoursesByDefaultLocationIdAverageCreatedAtDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_AVERAGE_CREATED_AT_DESC',
    CoursesByDefaultLocationIdAverageUpdatedAtAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_AVERAGE_UPDATED_AT_ASC',
    CoursesByDefaultLocationIdAverageUpdatedAtDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_AVERAGE_UPDATED_AT_DESC',
    CoursesByDefaultLocationIdAverageDefaultSeatsAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_AVERAGE_DEFAULT_SEATS_ASC',
    CoursesByDefaultLocationIdAverageDefaultSeatsDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_AVERAGE_DEFAULT_SEATS_DESC',
    CoursesByDefaultLocationIdAverageDefaultDurationAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_AVERAGE_DEFAULT_DURATION_ASC',
    CoursesByDefaultLocationIdAverageDefaultDurationDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_AVERAGE_DEFAULT_DURATION_DESC',
    CoursesByDefaultLocationIdAverageDefaultUnitsAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_AVERAGE_DEFAULT_UNITS_ASC',
    CoursesByDefaultLocationIdAverageDefaultUnitsDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_AVERAGE_DEFAULT_UNITS_DESC',
    CoursesByDefaultLocationIdAverageDefaultPriceAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_AVERAGE_DEFAULT_PRICE_ASC',
    CoursesByDefaultLocationIdAverageDefaultPriceDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_AVERAGE_DEFAULT_PRICE_DESC',
    CoursesByDefaultLocationIdAverageCertSetupAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_AVERAGE_CERT_SETUP_ASC',
    CoursesByDefaultLocationIdAverageCertSetupDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_AVERAGE_CERT_SETUP_DESC',
    CoursesByDefaultLocationIdAverageConfirmationContentAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_AVERAGE_CONFIRMATION_CONTENT_ASC',
    CoursesByDefaultLocationIdAverageConfirmationContentDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_AVERAGE_CONFIRMATION_CONTENT_DESC',
    CoursesByDefaultLocationIdStddevSampleIdAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_SAMPLE_ID_ASC',
    CoursesByDefaultLocationIdStddevSampleIdDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_SAMPLE_ID_DESC',
    CoursesByDefaultLocationIdStddevSampleNameAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_SAMPLE_NAME_ASC',
    CoursesByDefaultLocationIdStddevSampleNameDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_SAMPLE_NAME_DESC',
    CoursesByDefaultLocationIdStddevSampleDescriptionAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_SAMPLE_DESCRIPTION_ASC',
    CoursesByDefaultLocationIdStddevSampleDescriptionDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_SAMPLE_DESCRIPTION_DESC',
    CoursesByDefaultLocationIdStddevSampleCommentAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_SAMPLE_COMMENT_ASC',
    CoursesByDefaultLocationIdStddevSampleCommentDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_SAMPLE_COMMENT_DESC',
    CoursesByDefaultLocationIdStddevSampleDetailsAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_SAMPLE_DETAILS_ASC',
    CoursesByDefaultLocationIdStddevSampleDetailsDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_SAMPLE_DETAILS_DESC',
    CoursesByDefaultLocationIdStddevSampleArchivedAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_SAMPLE_ARCHIVED_ASC',
    CoursesByDefaultLocationIdStddevSampleArchivedDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_SAMPLE_ARCHIVED_DESC',
    CoursesByDefaultLocationIdStddevSampleDefaultLocationIdAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_SAMPLE_DEFAULT_LOCATION_ID_ASC',
    CoursesByDefaultLocationIdStddevSampleDefaultLocationIdDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_SAMPLE_DEFAULT_LOCATION_ID_DESC',
    CoursesByDefaultLocationIdStddevSampleDefaultTimetableAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_SAMPLE_DEFAULT_TIMETABLE_ASC',
    CoursesByDefaultLocationIdStddevSampleDefaultTimetableDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_SAMPLE_DEFAULT_TIMETABLE_DESC',
    CoursesByDefaultLocationIdStddevSampleCourseGroupIdAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_SAMPLE_COURSE_GROUP_ID_ASC',
    CoursesByDefaultLocationIdStddevSampleCourseGroupIdDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_SAMPLE_COURSE_GROUP_ID_DESC',
    CoursesByDefaultLocationIdStddevSampleCreatedAtAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_SAMPLE_CREATED_AT_ASC',
    CoursesByDefaultLocationIdStddevSampleCreatedAtDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_SAMPLE_CREATED_AT_DESC',
    CoursesByDefaultLocationIdStddevSampleUpdatedAtAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_SAMPLE_UPDATED_AT_ASC',
    CoursesByDefaultLocationIdStddevSampleUpdatedAtDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_SAMPLE_UPDATED_AT_DESC',
    CoursesByDefaultLocationIdStddevSampleDefaultSeatsAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_SAMPLE_DEFAULT_SEATS_ASC',
    CoursesByDefaultLocationIdStddevSampleDefaultSeatsDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_SAMPLE_DEFAULT_SEATS_DESC',
    CoursesByDefaultLocationIdStddevSampleDefaultDurationAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_SAMPLE_DEFAULT_DURATION_ASC',
    CoursesByDefaultLocationIdStddevSampleDefaultDurationDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_SAMPLE_DEFAULT_DURATION_DESC',
    CoursesByDefaultLocationIdStddevSampleDefaultUnitsAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_SAMPLE_DEFAULT_UNITS_ASC',
    CoursesByDefaultLocationIdStddevSampleDefaultUnitsDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_SAMPLE_DEFAULT_UNITS_DESC',
    CoursesByDefaultLocationIdStddevSampleDefaultPriceAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_SAMPLE_DEFAULT_PRICE_ASC',
    CoursesByDefaultLocationIdStddevSampleDefaultPriceDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_SAMPLE_DEFAULT_PRICE_DESC',
    CoursesByDefaultLocationIdStddevSampleCertSetupAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_SAMPLE_CERT_SETUP_ASC',
    CoursesByDefaultLocationIdStddevSampleCertSetupDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_SAMPLE_CERT_SETUP_DESC',
    CoursesByDefaultLocationIdStddevSampleConfirmationContentAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_SAMPLE_CONFIRMATION_CONTENT_ASC',
    CoursesByDefaultLocationIdStddevSampleConfirmationContentDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_SAMPLE_CONFIRMATION_CONTENT_DESC',
    CoursesByDefaultLocationIdStddevPopulationIdAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_POPULATION_ID_ASC',
    CoursesByDefaultLocationIdStddevPopulationIdDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_POPULATION_ID_DESC',
    CoursesByDefaultLocationIdStddevPopulationNameAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_POPULATION_NAME_ASC',
    CoursesByDefaultLocationIdStddevPopulationNameDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_POPULATION_NAME_DESC',
    CoursesByDefaultLocationIdStddevPopulationDescriptionAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_POPULATION_DESCRIPTION_ASC',
    CoursesByDefaultLocationIdStddevPopulationDescriptionDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_POPULATION_DESCRIPTION_DESC',
    CoursesByDefaultLocationIdStddevPopulationCommentAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_POPULATION_COMMENT_ASC',
    CoursesByDefaultLocationIdStddevPopulationCommentDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_POPULATION_COMMENT_DESC',
    CoursesByDefaultLocationIdStddevPopulationDetailsAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_POPULATION_DETAILS_ASC',
    CoursesByDefaultLocationIdStddevPopulationDetailsDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_POPULATION_DETAILS_DESC',
    CoursesByDefaultLocationIdStddevPopulationArchivedAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_POPULATION_ARCHIVED_ASC',
    CoursesByDefaultLocationIdStddevPopulationArchivedDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_POPULATION_ARCHIVED_DESC',
    CoursesByDefaultLocationIdStddevPopulationDefaultLocationIdAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_POPULATION_DEFAULT_LOCATION_ID_ASC',
    CoursesByDefaultLocationIdStddevPopulationDefaultLocationIdDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_POPULATION_DEFAULT_LOCATION_ID_DESC',
    CoursesByDefaultLocationIdStddevPopulationDefaultTimetableAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_POPULATION_DEFAULT_TIMETABLE_ASC',
    CoursesByDefaultLocationIdStddevPopulationDefaultTimetableDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_POPULATION_DEFAULT_TIMETABLE_DESC',
    CoursesByDefaultLocationIdStddevPopulationCourseGroupIdAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_POPULATION_COURSE_GROUP_ID_ASC',
    CoursesByDefaultLocationIdStddevPopulationCourseGroupIdDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_POPULATION_COURSE_GROUP_ID_DESC',
    CoursesByDefaultLocationIdStddevPopulationCreatedAtAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_POPULATION_CREATED_AT_ASC',
    CoursesByDefaultLocationIdStddevPopulationCreatedAtDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_POPULATION_CREATED_AT_DESC',
    CoursesByDefaultLocationIdStddevPopulationUpdatedAtAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_POPULATION_UPDATED_AT_ASC',
    CoursesByDefaultLocationIdStddevPopulationUpdatedAtDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_POPULATION_UPDATED_AT_DESC',
    CoursesByDefaultLocationIdStddevPopulationDefaultSeatsAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_POPULATION_DEFAULT_SEATS_ASC',
    CoursesByDefaultLocationIdStddevPopulationDefaultSeatsDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_POPULATION_DEFAULT_SEATS_DESC',
    CoursesByDefaultLocationIdStddevPopulationDefaultDurationAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_POPULATION_DEFAULT_DURATION_ASC',
    CoursesByDefaultLocationIdStddevPopulationDefaultDurationDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_POPULATION_DEFAULT_DURATION_DESC',
    CoursesByDefaultLocationIdStddevPopulationDefaultUnitsAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_POPULATION_DEFAULT_UNITS_ASC',
    CoursesByDefaultLocationIdStddevPopulationDefaultUnitsDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_POPULATION_DEFAULT_UNITS_DESC',
    CoursesByDefaultLocationIdStddevPopulationDefaultPriceAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_POPULATION_DEFAULT_PRICE_ASC',
    CoursesByDefaultLocationIdStddevPopulationDefaultPriceDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_POPULATION_DEFAULT_PRICE_DESC',
    CoursesByDefaultLocationIdStddevPopulationCertSetupAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_POPULATION_CERT_SETUP_ASC',
    CoursesByDefaultLocationIdStddevPopulationCertSetupDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_POPULATION_CERT_SETUP_DESC',
    CoursesByDefaultLocationIdStddevPopulationConfirmationContentAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_POPULATION_CONFIRMATION_CONTENT_ASC',
    CoursesByDefaultLocationIdStddevPopulationConfirmationContentDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_STDDEV_POPULATION_CONFIRMATION_CONTENT_DESC',
    CoursesByDefaultLocationIdVarianceSampleIdAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_SAMPLE_ID_ASC',
    CoursesByDefaultLocationIdVarianceSampleIdDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_SAMPLE_ID_DESC',
    CoursesByDefaultLocationIdVarianceSampleNameAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_SAMPLE_NAME_ASC',
    CoursesByDefaultLocationIdVarianceSampleNameDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_SAMPLE_NAME_DESC',
    CoursesByDefaultLocationIdVarianceSampleDescriptionAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_SAMPLE_DESCRIPTION_ASC',
    CoursesByDefaultLocationIdVarianceSampleDescriptionDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_SAMPLE_DESCRIPTION_DESC',
    CoursesByDefaultLocationIdVarianceSampleCommentAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_SAMPLE_COMMENT_ASC',
    CoursesByDefaultLocationIdVarianceSampleCommentDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_SAMPLE_COMMENT_DESC',
    CoursesByDefaultLocationIdVarianceSampleDetailsAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_SAMPLE_DETAILS_ASC',
    CoursesByDefaultLocationIdVarianceSampleDetailsDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_SAMPLE_DETAILS_DESC',
    CoursesByDefaultLocationIdVarianceSampleArchivedAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_SAMPLE_ARCHIVED_ASC',
    CoursesByDefaultLocationIdVarianceSampleArchivedDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_SAMPLE_ARCHIVED_DESC',
    CoursesByDefaultLocationIdVarianceSampleDefaultLocationIdAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_SAMPLE_DEFAULT_LOCATION_ID_ASC',
    CoursesByDefaultLocationIdVarianceSampleDefaultLocationIdDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_SAMPLE_DEFAULT_LOCATION_ID_DESC',
    CoursesByDefaultLocationIdVarianceSampleDefaultTimetableAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_SAMPLE_DEFAULT_TIMETABLE_ASC',
    CoursesByDefaultLocationIdVarianceSampleDefaultTimetableDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_SAMPLE_DEFAULT_TIMETABLE_DESC',
    CoursesByDefaultLocationIdVarianceSampleCourseGroupIdAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_SAMPLE_COURSE_GROUP_ID_ASC',
    CoursesByDefaultLocationIdVarianceSampleCourseGroupIdDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_SAMPLE_COURSE_GROUP_ID_DESC',
    CoursesByDefaultLocationIdVarianceSampleCreatedAtAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_SAMPLE_CREATED_AT_ASC',
    CoursesByDefaultLocationIdVarianceSampleCreatedAtDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_SAMPLE_CREATED_AT_DESC',
    CoursesByDefaultLocationIdVarianceSampleUpdatedAtAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC',
    CoursesByDefaultLocationIdVarianceSampleUpdatedAtDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC',
    CoursesByDefaultLocationIdVarianceSampleDefaultSeatsAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_SAMPLE_DEFAULT_SEATS_ASC',
    CoursesByDefaultLocationIdVarianceSampleDefaultSeatsDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_SAMPLE_DEFAULT_SEATS_DESC',
    CoursesByDefaultLocationIdVarianceSampleDefaultDurationAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_SAMPLE_DEFAULT_DURATION_ASC',
    CoursesByDefaultLocationIdVarianceSampleDefaultDurationDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_SAMPLE_DEFAULT_DURATION_DESC',
    CoursesByDefaultLocationIdVarianceSampleDefaultUnitsAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_SAMPLE_DEFAULT_UNITS_ASC',
    CoursesByDefaultLocationIdVarianceSampleDefaultUnitsDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_SAMPLE_DEFAULT_UNITS_DESC',
    CoursesByDefaultLocationIdVarianceSampleDefaultPriceAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_SAMPLE_DEFAULT_PRICE_ASC',
    CoursesByDefaultLocationIdVarianceSampleDefaultPriceDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_SAMPLE_DEFAULT_PRICE_DESC',
    CoursesByDefaultLocationIdVarianceSampleCertSetupAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_SAMPLE_CERT_SETUP_ASC',
    CoursesByDefaultLocationIdVarianceSampleCertSetupDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_SAMPLE_CERT_SETUP_DESC',
    CoursesByDefaultLocationIdVarianceSampleConfirmationContentAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_SAMPLE_CONFIRMATION_CONTENT_ASC',
    CoursesByDefaultLocationIdVarianceSampleConfirmationContentDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_SAMPLE_CONFIRMATION_CONTENT_DESC',
    CoursesByDefaultLocationIdVariancePopulationIdAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_POPULATION_ID_ASC',
    CoursesByDefaultLocationIdVariancePopulationIdDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_POPULATION_ID_DESC',
    CoursesByDefaultLocationIdVariancePopulationNameAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_POPULATION_NAME_ASC',
    CoursesByDefaultLocationIdVariancePopulationNameDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_POPULATION_NAME_DESC',
    CoursesByDefaultLocationIdVariancePopulationDescriptionAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_POPULATION_DESCRIPTION_ASC',
    CoursesByDefaultLocationIdVariancePopulationDescriptionDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_POPULATION_DESCRIPTION_DESC',
    CoursesByDefaultLocationIdVariancePopulationCommentAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_POPULATION_COMMENT_ASC',
    CoursesByDefaultLocationIdVariancePopulationCommentDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_POPULATION_COMMENT_DESC',
    CoursesByDefaultLocationIdVariancePopulationDetailsAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_POPULATION_DETAILS_ASC',
    CoursesByDefaultLocationIdVariancePopulationDetailsDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_POPULATION_DETAILS_DESC',
    CoursesByDefaultLocationIdVariancePopulationArchivedAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_POPULATION_ARCHIVED_ASC',
    CoursesByDefaultLocationIdVariancePopulationArchivedDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_POPULATION_ARCHIVED_DESC',
    CoursesByDefaultLocationIdVariancePopulationDefaultLocationIdAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_POPULATION_DEFAULT_LOCATION_ID_ASC',
    CoursesByDefaultLocationIdVariancePopulationDefaultLocationIdDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_POPULATION_DEFAULT_LOCATION_ID_DESC',
    CoursesByDefaultLocationIdVariancePopulationDefaultTimetableAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_POPULATION_DEFAULT_TIMETABLE_ASC',
    CoursesByDefaultLocationIdVariancePopulationDefaultTimetableDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_POPULATION_DEFAULT_TIMETABLE_DESC',
    CoursesByDefaultLocationIdVariancePopulationCourseGroupIdAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_POPULATION_COURSE_GROUP_ID_ASC',
    CoursesByDefaultLocationIdVariancePopulationCourseGroupIdDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_POPULATION_COURSE_GROUP_ID_DESC',
    CoursesByDefaultLocationIdVariancePopulationCreatedAtAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_POPULATION_CREATED_AT_ASC',
    CoursesByDefaultLocationIdVariancePopulationCreatedAtDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_POPULATION_CREATED_AT_DESC',
    CoursesByDefaultLocationIdVariancePopulationUpdatedAtAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_POPULATION_UPDATED_AT_ASC',
    CoursesByDefaultLocationIdVariancePopulationUpdatedAtDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_POPULATION_UPDATED_AT_DESC',
    CoursesByDefaultLocationIdVariancePopulationDefaultSeatsAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_POPULATION_DEFAULT_SEATS_ASC',
    CoursesByDefaultLocationIdVariancePopulationDefaultSeatsDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_POPULATION_DEFAULT_SEATS_DESC',
    CoursesByDefaultLocationIdVariancePopulationDefaultDurationAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_POPULATION_DEFAULT_DURATION_ASC',
    CoursesByDefaultLocationIdVariancePopulationDefaultDurationDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_POPULATION_DEFAULT_DURATION_DESC',
    CoursesByDefaultLocationIdVariancePopulationDefaultUnitsAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_POPULATION_DEFAULT_UNITS_ASC',
    CoursesByDefaultLocationIdVariancePopulationDefaultUnitsDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_POPULATION_DEFAULT_UNITS_DESC',
    CoursesByDefaultLocationIdVariancePopulationDefaultPriceAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_POPULATION_DEFAULT_PRICE_ASC',
    CoursesByDefaultLocationIdVariancePopulationDefaultPriceDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_POPULATION_DEFAULT_PRICE_DESC',
    CoursesByDefaultLocationIdVariancePopulationCertSetupAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_POPULATION_CERT_SETUP_ASC',
    CoursesByDefaultLocationIdVariancePopulationCertSetupDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_POPULATION_CERT_SETUP_DESC',
    CoursesByDefaultLocationIdVariancePopulationConfirmationContentAsc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_POPULATION_CONFIRMATION_CONTENT_ASC',
    CoursesByDefaultLocationIdVariancePopulationConfirmationContentDesc = 'COURSES_BY_DEFAULT_LOCATION_ID_VARIANCE_POPULATION_CONFIRMATION_CONTENT_DESC',
    CourseSchedulesCountAsc = 'COURSE_SCHEDULES_COUNT_ASC',
    CourseSchedulesCountDesc = 'COURSE_SCHEDULES_COUNT_DESC',
    CourseSchedulesSumIdAsc = 'COURSE_SCHEDULES_SUM_ID_ASC',
    CourseSchedulesSumIdDesc = 'COURSE_SCHEDULES_SUM_ID_DESC',
    CourseSchedulesSumCourseIdAsc = 'COURSE_SCHEDULES_SUM_COURSE_ID_ASC',
    CourseSchedulesSumCourseIdDesc = 'COURSE_SCHEDULES_SUM_COURSE_ID_DESC',
    CourseSchedulesSumStartDateAsc = 'COURSE_SCHEDULES_SUM_START_DATE_ASC',
    CourseSchedulesSumStartDateDesc = 'COURSE_SCHEDULES_SUM_START_DATE_DESC',
    CourseSchedulesSumEndDateAsc = 'COURSE_SCHEDULES_SUM_END_DATE_ASC',
    CourseSchedulesSumEndDateDesc = 'COURSE_SCHEDULES_SUM_END_DATE_DESC',
    CourseSchedulesSumSeatsAsc = 'COURSE_SCHEDULES_SUM_SEATS_ASC',
    CourseSchedulesSumSeatsDesc = 'COURSE_SCHEDULES_SUM_SEATS_DESC',
    CourseSchedulesSumDurationAsc = 'COURSE_SCHEDULES_SUM_DURATION_ASC',
    CourseSchedulesSumDurationDesc = 'COURSE_SCHEDULES_SUM_DURATION_DESC',
    CourseSchedulesSumUnitsAsc = 'COURSE_SCHEDULES_SUM_UNITS_ASC',
    CourseSchedulesSumUnitsDesc = 'COURSE_SCHEDULES_SUM_UNITS_DESC',
    CourseSchedulesSumPriceAsc = 'COURSE_SCHEDULES_SUM_PRICE_ASC',
    CourseSchedulesSumPriceDesc = 'COURSE_SCHEDULES_SUM_PRICE_DESC',
    CourseSchedulesSumTimetableAsc = 'COURSE_SCHEDULES_SUM_TIMETABLE_ASC',
    CourseSchedulesSumTimetableDesc = 'COURSE_SCHEDULES_SUM_TIMETABLE_DESC',
    CourseSchedulesSumCancelledAsc = 'COURSE_SCHEDULES_SUM_CANCELLED_ASC',
    CourseSchedulesSumCancelledDesc = 'COURSE_SCHEDULES_SUM_CANCELLED_DESC',
    CourseSchedulesSumCommentAsc = 'COURSE_SCHEDULES_SUM_COMMENT_ASC',
    CourseSchedulesSumCommentDesc = 'COURSE_SCHEDULES_SUM_COMMENT_DESC',
    CourseSchedulesSumLocationIdAsc = 'COURSE_SCHEDULES_SUM_LOCATION_ID_ASC',
    CourseSchedulesSumLocationIdDesc = 'COURSE_SCHEDULES_SUM_LOCATION_ID_DESC',
    CourseSchedulesSumCreatedAtAsc = 'COURSE_SCHEDULES_SUM_CREATED_AT_ASC',
    CourseSchedulesSumCreatedAtDesc = 'COURSE_SCHEDULES_SUM_CREATED_AT_DESC',
    CourseSchedulesSumUpdatedAtAsc = 'COURSE_SCHEDULES_SUM_UPDATED_AT_ASC',
    CourseSchedulesSumUpdatedAtDesc = 'COURSE_SCHEDULES_SUM_UPDATED_AT_DESC',
    CourseSchedulesSumReminderSentAtAsc = 'COURSE_SCHEDULES_SUM_REMINDER_SENT_AT_ASC',
    CourseSchedulesSumReminderSentAtDesc = 'COURSE_SCHEDULES_SUM_REMINDER_SENT_AT_DESC',
    CourseSchedulesDistinctCountIdAsc = 'COURSE_SCHEDULES_DISTINCT_COUNT_ID_ASC',
    CourseSchedulesDistinctCountIdDesc = 'COURSE_SCHEDULES_DISTINCT_COUNT_ID_DESC',
    CourseSchedulesDistinctCountCourseIdAsc = 'COURSE_SCHEDULES_DISTINCT_COUNT_COURSE_ID_ASC',
    CourseSchedulesDistinctCountCourseIdDesc = 'COURSE_SCHEDULES_DISTINCT_COUNT_COURSE_ID_DESC',
    CourseSchedulesDistinctCountStartDateAsc = 'COURSE_SCHEDULES_DISTINCT_COUNT_START_DATE_ASC',
    CourseSchedulesDistinctCountStartDateDesc = 'COURSE_SCHEDULES_DISTINCT_COUNT_START_DATE_DESC',
    CourseSchedulesDistinctCountEndDateAsc = 'COURSE_SCHEDULES_DISTINCT_COUNT_END_DATE_ASC',
    CourseSchedulesDistinctCountEndDateDesc = 'COURSE_SCHEDULES_DISTINCT_COUNT_END_DATE_DESC',
    CourseSchedulesDistinctCountSeatsAsc = 'COURSE_SCHEDULES_DISTINCT_COUNT_SEATS_ASC',
    CourseSchedulesDistinctCountSeatsDesc = 'COURSE_SCHEDULES_DISTINCT_COUNT_SEATS_DESC',
    CourseSchedulesDistinctCountDurationAsc = 'COURSE_SCHEDULES_DISTINCT_COUNT_DURATION_ASC',
    CourseSchedulesDistinctCountDurationDesc = 'COURSE_SCHEDULES_DISTINCT_COUNT_DURATION_DESC',
    CourseSchedulesDistinctCountUnitsAsc = 'COURSE_SCHEDULES_DISTINCT_COUNT_UNITS_ASC',
    CourseSchedulesDistinctCountUnitsDesc = 'COURSE_SCHEDULES_DISTINCT_COUNT_UNITS_DESC',
    CourseSchedulesDistinctCountPriceAsc = 'COURSE_SCHEDULES_DISTINCT_COUNT_PRICE_ASC',
    CourseSchedulesDistinctCountPriceDesc = 'COURSE_SCHEDULES_DISTINCT_COUNT_PRICE_DESC',
    CourseSchedulesDistinctCountTimetableAsc = 'COURSE_SCHEDULES_DISTINCT_COUNT_TIMETABLE_ASC',
    CourseSchedulesDistinctCountTimetableDesc = 'COURSE_SCHEDULES_DISTINCT_COUNT_TIMETABLE_DESC',
    CourseSchedulesDistinctCountCancelledAsc = 'COURSE_SCHEDULES_DISTINCT_COUNT_CANCELLED_ASC',
    CourseSchedulesDistinctCountCancelledDesc = 'COURSE_SCHEDULES_DISTINCT_COUNT_CANCELLED_DESC',
    CourseSchedulesDistinctCountCommentAsc = 'COURSE_SCHEDULES_DISTINCT_COUNT_COMMENT_ASC',
    CourseSchedulesDistinctCountCommentDesc = 'COURSE_SCHEDULES_DISTINCT_COUNT_COMMENT_DESC',
    CourseSchedulesDistinctCountLocationIdAsc = 'COURSE_SCHEDULES_DISTINCT_COUNT_LOCATION_ID_ASC',
    CourseSchedulesDistinctCountLocationIdDesc = 'COURSE_SCHEDULES_DISTINCT_COUNT_LOCATION_ID_DESC',
    CourseSchedulesDistinctCountCreatedAtAsc = 'COURSE_SCHEDULES_DISTINCT_COUNT_CREATED_AT_ASC',
    CourseSchedulesDistinctCountCreatedAtDesc = 'COURSE_SCHEDULES_DISTINCT_COUNT_CREATED_AT_DESC',
    CourseSchedulesDistinctCountUpdatedAtAsc = 'COURSE_SCHEDULES_DISTINCT_COUNT_UPDATED_AT_ASC',
    CourseSchedulesDistinctCountUpdatedAtDesc = 'COURSE_SCHEDULES_DISTINCT_COUNT_UPDATED_AT_DESC',
    CourseSchedulesDistinctCountReminderSentAtAsc = 'COURSE_SCHEDULES_DISTINCT_COUNT_REMINDER_SENT_AT_ASC',
    CourseSchedulesDistinctCountReminderSentAtDesc = 'COURSE_SCHEDULES_DISTINCT_COUNT_REMINDER_SENT_AT_DESC',
    CourseSchedulesMinIdAsc = 'COURSE_SCHEDULES_MIN_ID_ASC',
    CourseSchedulesMinIdDesc = 'COURSE_SCHEDULES_MIN_ID_DESC',
    CourseSchedulesMinCourseIdAsc = 'COURSE_SCHEDULES_MIN_COURSE_ID_ASC',
    CourseSchedulesMinCourseIdDesc = 'COURSE_SCHEDULES_MIN_COURSE_ID_DESC',
    CourseSchedulesMinStartDateAsc = 'COURSE_SCHEDULES_MIN_START_DATE_ASC',
    CourseSchedulesMinStartDateDesc = 'COURSE_SCHEDULES_MIN_START_DATE_DESC',
    CourseSchedulesMinEndDateAsc = 'COURSE_SCHEDULES_MIN_END_DATE_ASC',
    CourseSchedulesMinEndDateDesc = 'COURSE_SCHEDULES_MIN_END_DATE_DESC',
    CourseSchedulesMinSeatsAsc = 'COURSE_SCHEDULES_MIN_SEATS_ASC',
    CourseSchedulesMinSeatsDesc = 'COURSE_SCHEDULES_MIN_SEATS_DESC',
    CourseSchedulesMinDurationAsc = 'COURSE_SCHEDULES_MIN_DURATION_ASC',
    CourseSchedulesMinDurationDesc = 'COURSE_SCHEDULES_MIN_DURATION_DESC',
    CourseSchedulesMinUnitsAsc = 'COURSE_SCHEDULES_MIN_UNITS_ASC',
    CourseSchedulesMinUnitsDesc = 'COURSE_SCHEDULES_MIN_UNITS_DESC',
    CourseSchedulesMinPriceAsc = 'COURSE_SCHEDULES_MIN_PRICE_ASC',
    CourseSchedulesMinPriceDesc = 'COURSE_SCHEDULES_MIN_PRICE_DESC',
    CourseSchedulesMinTimetableAsc = 'COURSE_SCHEDULES_MIN_TIMETABLE_ASC',
    CourseSchedulesMinTimetableDesc = 'COURSE_SCHEDULES_MIN_TIMETABLE_DESC',
    CourseSchedulesMinCancelledAsc = 'COURSE_SCHEDULES_MIN_CANCELLED_ASC',
    CourseSchedulesMinCancelledDesc = 'COURSE_SCHEDULES_MIN_CANCELLED_DESC',
    CourseSchedulesMinCommentAsc = 'COURSE_SCHEDULES_MIN_COMMENT_ASC',
    CourseSchedulesMinCommentDesc = 'COURSE_SCHEDULES_MIN_COMMENT_DESC',
    CourseSchedulesMinLocationIdAsc = 'COURSE_SCHEDULES_MIN_LOCATION_ID_ASC',
    CourseSchedulesMinLocationIdDesc = 'COURSE_SCHEDULES_MIN_LOCATION_ID_DESC',
    CourseSchedulesMinCreatedAtAsc = 'COURSE_SCHEDULES_MIN_CREATED_AT_ASC',
    CourseSchedulesMinCreatedAtDesc = 'COURSE_SCHEDULES_MIN_CREATED_AT_DESC',
    CourseSchedulesMinUpdatedAtAsc = 'COURSE_SCHEDULES_MIN_UPDATED_AT_ASC',
    CourseSchedulesMinUpdatedAtDesc = 'COURSE_SCHEDULES_MIN_UPDATED_AT_DESC',
    CourseSchedulesMinReminderSentAtAsc = 'COURSE_SCHEDULES_MIN_REMINDER_SENT_AT_ASC',
    CourseSchedulesMinReminderSentAtDesc = 'COURSE_SCHEDULES_MIN_REMINDER_SENT_AT_DESC',
    CourseSchedulesMaxIdAsc = 'COURSE_SCHEDULES_MAX_ID_ASC',
    CourseSchedulesMaxIdDesc = 'COURSE_SCHEDULES_MAX_ID_DESC',
    CourseSchedulesMaxCourseIdAsc = 'COURSE_SCHEDULES_MAX_COURSE_ID_ASC',
    CourseSchedulesMaxCourseIdDesc = 'COURSE_SCHEDULES_MAX_COURSE_ID_DESC',
    CourseSchedulesMaxStartDateAsc = 'COURSE_SCHEDULES_MAX_START_DATE_ASC',
    CourseSchedulesMaxStartDateDesc = 'COURSE_SCHEDULES_MAX_START_DATE_DESC',
    CourseSchedulesMaxEndDateAsc = 'COURSE_SCHEDULES_MAX_END_DATE_ASC',
    CourseSchedulesMaxEndDateDesc = 'COURSE_SCHEDULES_MAX_END_DATE_DESC',
    CourseSchedulesMaxSeatsAsc = 'COURSE_SCHEDULES_MAX_SEATS_ASC',
    CourseSchedulesMaxSeatsDesc = 'COURSE_SCHEDULES_MAX_SEATS_DESC',
    CourseSchedulesMaxDurationAsc = 'COURSE_SCHEDULES_MAX_DURATION_ASC',
    CourseSchedulesMaxDurationDesc = 'COURSE_SCHEDULES_MAX_DURATION_DESC',
    CourseSchedulesMaxUnitsAsc = 'COURSE_SCHEDULES_MAX_UNITS_ASC',
    CourseSchedulesMaxUnitsDesc = 'COURSE_SCHEDULES_MAX_UNITS_DESC',
    CourseSchedulesMaxPriceAsc = 'COURSE_SCHEDULES_MAX_PRICE_ASC',
    CourseSchedulesMaxPriceDesc = 'COURSE_SCHEDULES_MAX_PRICE_DESC',
    CourseSchedulesMaxTimetableAsc = 'COURSE_SCHEDULES_MAX_TIMETABLE_ASC',
    CourseSchedulesMaxTimetableDesc = 'COURSE_SCHEDULES_MAX_TIMETABLE_DESC',
    CourseSchedulesMaxCancelledAsc = 'COURSE_SCHEDULES_MAX_CANCELLED_ASC',
    CourseSchedulesMaxCancelledDesc = 'COURSE_SCHEDULES_MAX_CANCELLED_DESC',
    CourseSchedulesMaxCommentAsc = 'COURSE_SCHEDULES_MAX_COMMENT_ASC',
    CourseSchedulesMaxCommentDesc = 'COURSE_SCHEDULES_MAX_COMMENT_DESC',
    CourseSchedulesMaxLocationIdAsc = 'COURSE_SCHEDULES_MAX_LOCATION_ID_ASC',
    CourseSchedulesMaxLocationIdDesc = 'COURSE_SCHEDULES_MAX_LOCATION_ID_DESC',
    CourseSchedulesMaxCreatedAtAsc = 'COURSE_SCHEDULES_MAX_CREATED_AT_ASC',
    CourseSchedulesMaxCreatedAtDesc = 'COURSE_SCHEDULES_MAX_CREATED_AT_DESC',
    CourseSchedulesMaxUpdatedAtAsc = 'COURSE_SCHEDULES_MAX_UPDATED_AT_ASC',
    CourseSchedulesMaxUpdatedAtDesc = 'COURSE_SCHEDULES_MAX_UPDATED_AT_DESC',
    CourseSchedulesMaxReminderSentAtAsc = 'COURSE_SCHEDULES_MAX_REMINDER_SENT_AT_ASC',
    CourseSchedulesMaxReminderSentAtDesc = 'COURSE_SCHEDULES_MAX_REMINDER_SENT_AT_DESC',
    CourseSchedulesAverageIdAsc = 'COURSE_SCHEDULES_AVERAGE_ID_ASC',
    CourseSchedulesAverageIdDesc = 'COURSE_SCHEDULES_AVERAGE_ID_DESC',
    CourseSchedulesAverageCourseIdAsc = 'COURSE_SCHEDULES_AVERAGE_COURSE_ID_ASC',
    CourseSchedulesAverageCourseIdDesc = 'COURSE_SCHEDULES_AVERAGE_COURSE_ID_DESC',
    CourseSchedulesAverageStartDateAsc = 'COURSE_SCHEDULES_AVERAGE_START_DATE_ASC',
    CourseSchedulesAverageStartDateDesc = 'COURSE_SCHEDULES_AVERAGE_START_DATE_DESC',
    CourseSchedulesAverageEndDateAsc = 'COURSE_SCHEDULES_AVERAGE_END_DATE_ASC',
    CourseSchedulesAverageEndDateDesc = 'COURSE_SCHEDULES_AVERAGE_END_DATE_DESC',
    CourseSchedulesAverageSeatsAsc = 'COURSE_SCHEDULES_AVERAGE_SEATS_ASC',
    CourseSchedulesAverageSeatsDesc = 'COURSE_SCHEDULES_AVERAGE_SEATS_DESC',
    CourseSchedulesAverageDurationAsc = 'COURSE_SCHEDULES_AVERAGE_DURATION_ASC',
    CourseSchedulesAverageDurationDesc = 'COURSE_SCHEDULES_AVERAGE_DURATION_DESC',
    CourseSchedulesAverageUnitsAsc = 'COURSE_SCHEDULES_AVERAGE_UNITS_ASC',
    CourseSchedulesAverageUnitsDesc = 'COURSE_SCHEDULES_AVERAGE_UNITS_DESC',
    CourseSchedulesAveragePriceAsc = 'COURSE_SCHEDULES_AVERAGE_PRICE_ASC',
    CourseSchedulesAveragePriceDesc = 'COURSE_SCHEDULES_AVERAGE_PRICE_DESC',
    CourseSchedulesAverageTimetableAsc = 'COURSE_SCHEDULES_AVERAGE_TIMETABLE_ASC',
    CourseSchedulesAverageTimetableDesc = 'COURSE_SCHEDULES_AVERAGE_TIMETABLE_DESC',
    CourseSchedulesAverageCancelledAsc = 'COURSE_SCHEDULES_AVERAGE_CANCELLED_ASC',
    CourseSchedulesAverageCancelledDesc = 'COURSE_SCHEDULES_AVERAGE_CANCELLED_DESC',
    CourseSchedulesAverageCommentAsc = 'COURSE_SCHEDULES_AVERAGE_COMMENT_ASC',
    CourseSchedulesAverageCommentDesc = 'COURSE_SCHEDULES_AVERAGE_COMMENT_DESC',
    CourseSchedulesAverageLocationIdAsc = 'COURSE_SCHEDULES_AVERAGE_LOCATION_ID_ASC',
    CourseSchedulesAverageLocationIdDesc = 'COURSE_SCHEDULES_AVERAGE_LOCATION_ID_DESC',
    CourseSchedulesAverageCreatedAtAsc = 'COURSE_SCHEDULES_AVERAGE_CREATED_AT_ASC',
    CourseSchedulesAverageCreatedAtDesc = 'COURSE_SCHEDULES_AVERAGE_CREATED_AT_DESC',
    CourseSchedulesAverageUpdatedAtAsc = 'COURSE_SCHEDULES_AVERAGE_UPDATED_AT_ASC',
    CourseSchedulesAverageUpdatedAtDesc = 'COURSE_SCHEDULES_AVERAGE_UPDATED_AT_DESC',
    CourseSchedulesAverageReminderSentAtAsc = 'COURSE_SCHEDULES_AVERAGE_REMINDER_SENT_AT_ASC',
    CourseSchedulesAverageReminderSentAtDesc = 'COURSE_SCHEDULES_AVERAGE_REMINDER_SENT_AT_DESC',
    CourseSchedulesStddevSampleIdAsc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_ID_ASC',
    CourseSchedulesStddevSampleIdDesc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_ID_DESC',
    CourseSchedulesStddevSampleCourseIdAsc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_COURSE_ID_ASC',
    CourseSchedulesStddevSampleCourseIdDesc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_COURSE_ID_DESC',
    CourseSchedulesStddevSampleStartDateAsc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_START_DATE_ASC',
    CourseSchedulesStddevSampleStartDateDesc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_START_DATE_DESC',
    CourseSchedulesStddevSampleEndDateAsc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_END_DATE_ASC',
    CourseSchedulesStddevSampleEndDateDesc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_END_DATE_DESC',
    CourseSchedulesStddevSampleSeatsAsc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_SEATS_ASC',
    CourseSchedulesStddevSampleSeatsDesc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_SEATS_DESC',
    CourseSchedulesStddevSampleDurationAsc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_DURATION_ASC',
    CourseSchedulesStddevSampleDurationDesc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_DURATION_DESC',
    CourseSchedulesStddevSampleUnitsAsc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_UNITS_ASC',
    CourseSchedulesStddevSampleUnitsDesc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_UNITS_DESC',
    CourseSchedulesStddevSamplePriceAsc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_PRICE_ASC',
    CourseSchedulesStddevSamplePriceDesc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_PRICE_DESC',
    CourseSchedulesStddevSampleTimetableAsc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_TIMETABLE_ASC',
    CourseSchedulesStddevSampleTimetableDesc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_TIMETABLE_DESC',
    CourseSchedulesStddevSampleCancelledAsc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_CANCELLED_ASC',
    CourseSchedulesStddevSampleCancelledDesc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_CANCELLED_DESC',
    CourseSchedulesStddevSampleCommentAsc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_COMMENT_ASC',
    CourseSchedulesStddevSampleCommentDesc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_COMMENT_DESC',
    CourseSchedulesStddevSampleLocationIdAsc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_LOCATION_ID_ASC',
    CourseSchedulesStddevSampleLocationIdDesc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_LOCATION_ID_DESC',
    CourseSchedulesStddevSampleCreatedAtAsc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_CREATED_AT_ASC',
    CourseSchedulesStddevSampleCreatedAtDesc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_CREATED_AT_DESC',
    CourseSchedulesStddevSampleUpdatedAtAsc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_UPDATED_AT_ASC',
    CourseSchedulesStddevSampleUpdatedAtDesc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_UPDATED_AT_DESC',
    CourseSchedulesStddevSampleReminderSentAtAsc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_REMINDER_SENT_AT_ASC',
    CourseSchedulesStddevSampleReminderSentAtDesc = 'COURSE_SCHEDULES_STDDEV_SAMPLE_REMINDER_SENT_AT_DESC',
    CourseSchedulesStddevPopulationIdAsc = 'COURSE_SCHEDULES_STDDEV_POPULATION_ID_ASC',
    CourseSchedulesStddevPopulationIdDesc = 'COURSE_SCHEDULES_STDDEV_POPULATION_ID_DESC',
    CourseSchedulesStddevPopulationCourseIdAsc = 'COURSE_SCHEDULES_STDDEV_POPULATION_COURSE_ID_ASC',
    CourseSchedulesStddevPopulationCourseIdDesc = 'COURSE_SCHEDULES_STDDEV_POPULATION_COURSE_ID_DESC',
    CourseSchedulesStddevPopulationStartDateAsc = 'COURSE_SCHEDULES_STDDEV_POPULATION_START_DATE_ASC',
    CourseSchedulesStddevPopulationStartDateDesc = 'COURSE_SCHEDULES_STDDEV_POPULATION_START_DATE_DESC',
    CourseSchedulesStddevPopulationEndDateAsc = 'COURSE_SCHEDULES_STDDEV_POPULATION_END_DATE_ASC',
    CourseSchedulesStddevPopulationEndDateDesc = 'COURSE_SCHEDULES_STDDEV_POPULATION_END_DATE_DESC',
    CourseSchedulesStddevPopulationSeatsAsc = 'COURSE_SCHEDULES_STDDEV_POPULATION_SEATS_ASC',
    CourseSchedulesStddevPopulationSeatsDesc = 'COURSE_SCHEDULES_STDDEV_POPULATION_SEATS_DESC',
    CourseSchedulesStddevPopulationDurationAsc = 'COURSE_SCHEDULES_STDDEV_POPULATION_DURATION_ASC',
    CourseSchedulesStddevPopulationDurationDesc = 'COURSE_SCHEDULES_STDDEV_POPULATION_DURATION_DESC',
    CourseSchedulesStddevPopulationUnitsAsc = 'COURSE_SCHEDULES_STDDEV_POPULATION_UNITS_ASC',
    CourseSchedulesStddevPopulationUnitsDesc = 'COURSE_SCHEDULES_STDDEV_POPULATION_UNITS_DESC',
    CourseSchedulesStddevPopulationPriceAsc = 'COURSE_SCHEDULES_STDDEV_POPULATION_PRICE_ASC',
    CourseSchedulesStddevPopulationPriceDesc = 'COURSE_SCHEDULES_STDDEV_POPULATION_PRICE_DESC',
    CourseSchedulesStddevPopulationTimetableAsc = 'COURSE_SCHEDULES_STDDEV_POPULATION_TIMETABLE_ASC',
    CourseSchedulesStddevPopulationTimetableDesc = 'COURSE_SCHEDULES_STDDEV_POPULATION_TIMETABLE_DESC',
    CourseSchedulesStddevPopulationCancelledAsc = 'COURSE_SCHEDULES_STDDEV_POPULATION_CANCELLED_ASC',
    CourseSchedulesStddevPopulationCancelledDesc = 'COURSE_SCHEDULES_STDDEV_POPULATION_CANCELLED_DESC',
    CourseSchedulesStddevPopulationCommentAsc = 'COURSE_SCHEDULES_STDDEV_POPULATION_COMMENT_ASC',
    CourseSchedulesStddevPopulationCommentDesc = 'COURSE_SCHEDULES_STDDEV_POPULATION_COMMENT_DESC',
    CourseSchedulesStddevPopulationLocationIdAsc = 'COURSE_SCHEDULES_STDDEV_POPULATION_LOCATION_ID_ASC',
    CourseSchedulesStddevPopulationLocationIdDesc = 'COURSE_SCHEDULES_STDDEV_POPULATION_LOCATION_ID_DESC',
    CourseSchedulesStddevPopulationCreatedAtAsc = 'COURSE_SCHEDULES_STDDEV_POPULATION_CREATED_AT_ASC',
    CourseSchedulesStddevPopulationCreatedAtDesc = 'COURSE_SCHEDULES_STDDEV_POPULATION_CREATED_AT_DESC',
    CourseSchedulesStddevPopulationUpdatedAtAsc = 'COURSE_SCHEDULES_STDDEV_POPULATION_UPDATED_AT_ASC',
    CourseSchedulesStddevPopulationUpdatedAtDesc = 'COURSE_SCHEDULES_STDDEV_POPULATION_UPDATED_AT_DESC',
    CourseSchedulesStddevPopulationReminderSentAtAsc = 'COURSE_SCHEDULES_STDDEV_POPULATION_REMINDER_SENT_AT_ASC',
    CourseSchedulesStddevPopulationReminderSentAtDesc = 'COURSE_SCHEDULES_STDDEV_POPULATION_REMINDER_SENT_AT_DESC',
    CourseSchedulesVarianceSampleIdAsc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_ID_ASC',
    CourseSchedulesVarianceSampleIdDesc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_ID_DESC',
    CourseSchedulesVarianceSampleCourseIdAsc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_COURSE_ID_ASC',
    CourseSchedulesVarianceSampleCourseIdDesc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_COURSE_ID_DESC',
    CourseSchedulesVarianceSampleStartDateAsc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_START_DATE_ASC',
    CourseSchedulesVarianceSampleStartDateDesc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_START_DATE_DESC',
    CourseSchedulesVarianceSampleEndDateAsc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_END_DATE_ASC',
    CourseSchedulesVarianceSampleEndDateDesc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_END_DATE_DESC',
    CourseSchedulesVarianceSampleSeatsAsc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_SEATS_ASC',
    CourseSchedulesVarianceSampleSeatsDesc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_SEATS_DESC',
    CourseSchedulesVarianceSampleDurationAsc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_DURATION_ASC',
    CourseSchedulesVarianceSampleDurationDesc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_DURATION_DESC',
    CourseSchedulesVarianceSampleUnitsAsc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_UNITS_ASC',
    CourseSchedulesVarianceSampleUnitsDesc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_UNITS_DESC',
    CourseSchedulesVarianceSamplePriceAsc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_PRICE_ASC',
    CourseSchedulesVarianceSamplePriceDesc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_PRICE_DESC',
    CourseSchedulesVarianceSampleTimetableAsc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_TIMETABLE_ASC',
    CourseSchedulesVarianceSampleTimetableDesc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_TIMETABLE_DESC',
    CourseSchedulesVarianceSampleCancelledAsc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_CANCELLED_ASC',
    CourseSchedulesVarianceSampleCancelledDesc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_CANCELLED_DESC',
    CourseSchedulesVarianceSampleCommentAsc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_COMMENT_ASC',
    CourseSchedulesVarianceSampleCommentDesc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_COMMENT_DESC',
    CourseSchedulesVarianceSampleLocationIdAsc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_LOCATION_ID_ASC',
    CourseSchedulesVarianceSampleLocationIdDesc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_LOCATION_ID_DESC',
    CourseSchedulesVarianceSampleCreatedAtAsc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_CREATED_AT_ASC',
    CourseSchedulesVarianceSampleCreatedAtDesc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_CREATED_AT_DESC',
    CourseSchedulesVarianceSampleUpdatedAtAsc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_UPDATED_AT_ASC',
    CourseSchedulesVarianceSampleUpdatedAtDesc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_UPDATED_AT_DESC',
    CourseSchedulesVarianceSampleReminderSentAtAsc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_REMINDER_SENT_AT_ASC',
    CourseSchedulesVarianceSampleReminderSentAtDesc = 'COURSE_SCHEDULES_VARIANCE_SAMPLE_REMINDER_SENT_AT_DESC',
    CourseSchedulesVariancePopulationIdAsc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_ID_ASC',
    CourseSchedulesVariancePopulationIdDesc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_ID_DESC',
    CourseSchedulesVariancePopulationCourseIdAsc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_COURSE_ID_ASC',
    CourseSchedulesVariancePopulationCourseIdDesc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_COURSE_ID_DESC',
    CourseSchedulesVariancePopulationStartDateAsc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_START_DATE_ASC',
    CourseSchedulesVariancePopulationStartDateDesc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_START_DATE_DESC',
    CourseSchedulesVariancePopulationEndDateAsc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_END_DATE_ASC',
    CourseSchedulesVariancePopulationEndDateDesc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_END_DATE_DESC',
    CourseSchedulesVariancePopulationSeatsAsc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_SEATS_ASC',
    CourseSchedulesVariancePopulationSeatsDesc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_SEATS_DESC',
    CourseSchedulesVariancePopulationDurationAsc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_DURATION_ASC',
    CourseSchedulesVariancePopulationDurationDesc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_DURATION_DESC',
    CourseSchedulesVariancePopulationUnitsAsc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_UNITS_ASC',
    CourseSchedulesVariancePopulationUnitsDesc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_UNITS_DESC',
    CourseSchedulesVariancePopulationPriceAsc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_PRICE_ASC',
    CourseSchedulesVariancePopulationPriceDesc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_PRICE_DESC',
    CourseSchedulesVariancePopulationTimetableAsc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_TIMETABLE_ASC',
    CourseSchedulesVariancePopulationTimetableDesc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_TIMETABLE_DESC',
    CourseSchedulesVariancePopulationCancelledAsc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_CANCELLED_ASC',
    CourseSchedulesVariancePopulationCancelledDesc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_CANCELLED_DESC',
    CourseSchedulesVariancePopulationCommentAsc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_COMMENT_ASC',
    CourseSchedulesVariancePopulationCommentDesc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_COMMENT_DESC',
    CourseSchedulesVariancePopulationLocationIdAsc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_LOCATION_ID_ASC',
    CourseSchedulesVariancePopulationLocationIdDesc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_LOCATION_ID_DESC',
    CourseSchedulesVariancePopulationCreatedAtAsc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_CREATED_AT_ASC',
    CourseSchedulesVariancePopulationCreatedAtDesc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_CREATED_AT_DESC',
    CourseSchedulesVariancePopulationUpdatedAtAsc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_UPDATED_AT_ASC',
    CourseSchedulesVariancePopulationUpdatedAtDesc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_UPDATED_AT_DESC',
    CourseSchedulesVariancePopulationReminderSentAtAsc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_REMINDER_SENT_AT_ASC',
    CourseSchedulesVariancePopulationReminderSentAtDesc = 'COURSE_SCHEDULES_VARIANCE_POPULATION_REMINDER_SENT_AT_DESC',
    EducationClassSchedulesCountAsc = 'EDUCATION_CLASS_SCHEDULES_COUNT_ASC',
    EducationClassSchedulesCountDesc = 'EDUCATION_CLASS_SCHEDULES_COUNT_DESC',
    EducationClassSchedulesSumIdAsc = 'EDUCATION_CLASS_SCHEDULES_SUM_ID_ASC',
    EducationClassSchedulesSumIdDesc = 'EDUCATION_CLASS_SCHEDULES_SUM_ID_DESC',
    EducationClassSchedulesSumEducationClassIdAsc = 'EDUCATION_CLASS_SCHEDULES_SUM_EDUCATION_CLASS_ID_ASC',
    EducationClassSchedulesSumEducationClassIdDesc = 'EDUCATION_CLASS_SCHEDULES_SUM_EDUCATION_CLASS_ID_DESC',
    EducationClassSchedulesSumStartDateAsc = 'EDUCATION_CLASS_SCHEDULES_SUM_START_DATE_ASC',
    EducationClassSchedulesSumStartDateDesc = 'EDUCATION_CLASS_SCHEDULES_SUM_START_DATE_DESC',
    EducationClassSchedulesSumEndDateAsc = 'EDUCATION_CLASS_SCHEDULES_SUM_END_DATE_ASC',
    EducationClassSchedulesSumEndDateDesc = 'EDUCATION_CLASS_SCHEDULES_SUM_END_DATE_DESC',
    EducationClassSchedulesSumTimetableAsc = 'EDUCATION_CLASS_SCHEDULES_SUM_TIMETABLE_ASC',
    EducationClassSchedulesSumTimetableDesc = 'EDUCATION_CLASS_SCHEDULES_SUM_TIMETABLE_DESC',
    EducationClassSchedulesSumLocationIdAsc = 'EDUCATION_CLASS_SCHEDULES_SUM_LOCATION_ID_ASC',
    EducationClassSchedulesSumLocationIdDesc = 'EDUCATION_CLASS_SCHEDULES_SUM_LOCATION_ID_DESC',
    EducationClassSchedulesSumCreatedAtAsc = 'EDUCATION_CLASS_SCHEDULES_SUM_CREATED_AT_ASC',
    EducationClassSchedulesSumCreatedAtDesc = 'EDUCATION_CLASS_SCHEDULES_SUM_CREATED_AT_DESC',
    EducationClassSchedulesSumUpdatedAtAsc = 'EDUCATION_CLASS_SCHEDULES_SUM_UPDATED_AT_ASC',
    EducationClassSchedulesSumUpdatedAtDesc = 'EDUCATION_CLASS_SCHEDULES_SUM_UPDATED_AT_DESC',
    EducationClassSchedulesSumCourseYearAsc = 'EDUCATION_CLASS_SCHEDULES_SUM_COURSE_YEAR_ASC',
    EducationClassSchedulesSumCourseYearDesc = 'EDUCATION_CLASS_SCHEDULES_SUM_COURSE_YEAR_DESC',
    EducationClassSchedulesDistinctCountIdAsc = 'EDUCATION_CLASS_SCHEDULES_DISTINCT_COUNT_ID_ASC',
    EducationClassSchedulesDistinctCountIdDesc = 'EDUCATION_CLASS_SCHEDULES_DISTINCT_COUNT_ID_DESC',
    EducationClassSchedulesDistinctCountEducationClassIdAsc = 'EDUCATION_CLASS_SCHEDULES_DISTINCT_COUNT_EDUCATION_CLASS_ID_ASC',
    EducationClassSchedulesDistinctCountEducationClassIdDesc = 'EDUCATION_CLASS_SCHEDULES_DISTINCT_COUNT_EDUCATION_CLASS_ID_DESC',
    EducationClassSchedulesDistinctCountStartDateAsc = 'EDUCATION_CLASS_SCHEDULES_DISTINCT_COUNT_START_DATE_ASC',
    EducationClassSchedulesDistinctCountStartDateDesc = 'EDUCATION_CLASS_SCHEDULES_DISTINCT_COUNT_START_DATE_DESC',
    EducationClassSchedulesDistinctCountEndDateAsc = 'EDUCATION_CLASS_SCHEDULES_DISTINCT_COUNT_END_DATE_ASC',
    EducationClassSchedulesDistinctCountEndDateDesc = 'EDUCATION_CLASS_SCHEDULES_DISTINCT_COUNT_END_DATE_DESC',
    EducationClassSchedulesDistinctCountTimetableAsc = 'EDUCATION_CLASS_SCHEDULES_DISTINCT_COUNT_TIMETABLE_ASC',
    EducationClassSchedulesDistinctCountTimetableDesc = 'EDUCATION_CLASS_SCHEDULES_DISTINCT_COUNT_TIMETABLE_DESC',
    EducationClassSchedulesDistinctCountLocationIdAsc = 'EDUCATION_CLASS_SCHEDULES_DISTINCT_COUNT_LOCATION_ID_ASC',
    EducationClassSchedulesDistinctCountLocationIdDesc = 'EDUCATION_CLASS_SCHEDULES_DISTINCT_COUNT_LOCATION_ID_DESC',
    EducationClassSchedulesDistinctCountCreatedAtAsc = 'EDUCATION_CLASS_SCHEDULES_DISTINCT_COUNT_CREATED_AT_ASC',
    EducationClassSchedulesDistinctCountCreatedAtDesc = 'EDUCATION_CLASS_SCHEDULES_DISTINCT_COUNT_CREATED_AT_DESC',
    EducationClassSchedulesDistinctCountUpdatedAtAsc = 'EDUCATION_CLASS_SCHEDULES_DISTINCT_COUNT_UPDATED_AT_ASC',
    EducationClassSchedulesDistinctCountUpdatedAtDesc = 'EDUCATION_CLASS_SCHEDULES_DISTINCT_COUNT_UPDATED_AT_DESC',
    EducationClassSchedulesDistinctCountCourseYearAsc = 'EDUCATION_CLASS_SCHEDULES_DISTINCT_COUNT_COURSE_YEAR_ASC',
    EducationClassSchedulesDistinctCountCourseYearDesc = 'EDUCATION_CLASS_SCHEDULES_DISTINCT_COUNT_COURSE_YEAR_DESC',
    EducationClassSchedulesMinIdAsc = 'EDUCATION_CLASS_SCHEDULES_MIN_ID_ASC',
    EducationClassSchedulesMinIdDesc = 'EDUCATION_CLASS_SCHEDULES_MIN_ID_DESC',
    EducationClassSchedulesMinEducationClassIdAsc = 'EDUCATION_CLASS_SCHEDULES_MIN_EDUCATION_CLASS_ID_ASC',
    EducationClassSchedulesMinEducationClassIdDesc = 'EDUCATION_CLASS_SCHEDULES_MIN_EDUCATION_CLASS_ID_DESC',
    EducationClassSchedulesMinStartDateAsc = 'EDUCATION_CLASS_SCHEDULES_MIN_START_DATE_ASC',
    EducationClassSchedulesMinStartDateDesc = 'EDUCATION_CLASS_SCHEDULES_MIN_START_DATE_DESC',
    EducationClassSchedulesMinEndDateAsc = 'EDUCATION_CLASS_SCHEDULES_MIN_END_DATE_ASC',
    EducationClassSchedulesMinEndDateDesc = 'EDUCATION_CLASS_SCHEDULES_MIN_END_DATE_DESC',
    EducationClassSchedulesMinTimetableAsc = 'EDUCATION_CLASS_SCHEDULES_MIN_TIMETABLE_ASC',
    EducationClassSchedulesMinTimetableDesc = 'EDUCATION_CLASS_SCHEDULES_MIN_TIMETABLE_DESC',
    EducationClassSchedulesMinLocationIdAsc = 'EDUCATION_CLASS_SCHEDULES_MIN_LOCATION_ID_ASC',
    EducationClassSchedulesMinLocationIdDesc = 'EDUCATION_CLASS_SCHEDULES_MIN_LOCATION_ID_DESC',
    EducationClassSchedulesMinCreatedAtAsc = 'EDUCATION_CLASS_SCHEDULES_MIN_CREATED_AT_ASC',
    EducationClassSchedulesMinCreatedAtDesc = 'EDUCATION_CLASS_SCHEDULES_MIN_CREATED_AT_DESC',
    EducationClassSchedulesMinUpdatedAtAsc = 'EDUCATION_CLASS_SCHEDULES_MIN_UPDATED_AT_ASC',
    EducationClassSchedulesMinUpdatedAtDesc = 'EDUCATION_CLASS_SCHEDULES_MIN_UPDATED_AT_DESC',
    EducationClassSchedulesMinCourseYearAsc = 'EDUCATION_CLASS_SCHEDULES_MIN_COURSE_YEAR_ASC',
    EducationClassSchedulesMinCourseYearDesc = 'EDUCATION_CLASS_SCHEDULES_MIN_COURSE_YEAR_DESC',
    EducationClassSchedulesMaxIdAsc = 'EDUCATION_CLASS_SCHEDULES_MAX_ID_ASC',
    EducationClassSchedulesMaxIdDesc = 'EDUCATION_CLASS_SCHEDULES_MAX_ID_DESC',
    EducationClassSchedulesMaxEducationClassIdAsc = 'EDUCATION_CLASS_SCHEDULES_MAX_EDUCATION_CLASS_ID_ASC',
    EducationClassSchedulesMaxEducationClassIdDesc = 'EDUCATION_CLASS_SCHEDULES_MAX_EDUCATION_CLASS_ID_DESC',
    EducationClassSchedulesMaxStartDateAsc = 'EDUCATION_CLASS_SCHEDULES_MAX_START_DATE_ASC',
    EducationClassSchedulesMaxStartDateDesc = 'EDUCATION_CLASS_SCHEDULES_MAX_START_DATE_DESC',
    EducationClassSchedulesMaxEndDateAsc = 'EDUCATION_CLASS_SCHEDULES_MAX_END_DATE_ASC',
    EducationClassSchedulesMaxEndDateDesc = 'EDUCATION_CLASS_SCHEDULES_MAX_END_DATE_DESC',
    EducationClassSchedulesMaxTimetableAsc = 'EDUCATION_CLASS_SCHEDULES_MAX_TIMETABLE_ASC',
    EducationClassSchedulesMaxTimetableDesc = 'EDUCATION_CLASS_SCHEDULES_MAX_TIMETABLE_DESC',
    EducationClassSchedulesMaxLocationIdAsc = 'EDUCATION_CLASS_SCHEDULES_MAX_LOCATION_ID_ASC',
    EducationClassSchedulesMaxLocationIdDesc = 'EDUCATION_CLASS_SCHEDULES_MAX_LOCATION_ID_DESC',
    EducationClassSchedulesMaxCreatedAtAsc = 'EDUCATION_CLASS_SCHEDULES_MAX_CREATED_AT_ASC',
    EducationClassSchedulesMaxCreatedAtDesc = 'EDUCATION_CLASS_SCHEDULES_MAX_CREATED_AT_DESC',
    EducationClassSchedulesMaxUpdatedAtAsc = 'EDUCATION_CLASS_SCHEDULES_MAX_UPDATED_AT_ASC',
    EducationClassSchedulesMaxUpdatedAtDesc = 'EDUCATION_CLASS_SCHEDULES_MAX_UPDATED_AT_DESC',
    EducationClassSchedulesMaxCourseYearAsc = 'EDUCATION_CLASS_SCHEDULES_MAX_COURSE_YEAR_ASC',
    EducationClassSchedulesMaxCourseYearDesc = 'EDUCATION_CLASS_SCHEDULES_MAX_COURSE_YEAR_DESC',
    EducationClassSchedulesAverageIdAsc = 'EDUCATION_CLASS_SCHEDULES_AVERAGE_ID_ASC',
    EducationClassSchedulesAverageIdDesc = 'EDUCATION_CLASS_SCHEDULES_AVERAGE_ID_DESC',
    EducationClassSchedulesAverageEducationClassIdAsc = 'EDUCATION_CLASS_SCHEDULES_AVERAGE_EDUCATION_CLASS_ID_ASC',
    EducationClassSchedulesAverageEducationClassIdDesc = 'EDUCATION_CLASS_SCHEDULES_AVERAGE_EDUCATION_CLASS_ID_DESC',
    EducationClassSchedulesAverageStartDateAsc = 'EDUCATION_CLASS_SCHEDULES_AVERAGE_START_DATE_ASC',
    EducationClassSchedulesAverageStartDateDesc = 'EDUCATION_CLASS_SCHEDULES_AVERAGE_START_DATE_DESC',
    EducationClassSchedulesAverageEndDateAsc = 'EDUCATION_CLASS_SCHEDULES_AVERAGE_END_DATE_ASC',
    EducationClassSchedulesAverageEndDateDesc = 'EDUCATION_CLASS_SCHEDULES_AVERAGE_END_DATE_DESC',
    EducationClassSchedulesAverageTimetableAsc = 'EDUCATION_CLASS_SCHEDULES_AVERAGE_TIMETABLE_ASC',
    EducationClassSchedulesAverageTimetableDesc = 'EDUCATION_CLASS_SCHEDULES_AVERAGE_TIMETABLE_DESC',
    EducationClassSchedulesAverageLocationIdAsc = 'EDUCATION_CLASS_SCHEDULES_AVERAGE_LOCATION_ID_ASC',
    EducationClassSchedulesAverageLocationIdDesc = 'EDUCATION_CLASS_SCHEDULES_AVERAGE_LOCATION_ID_DESC',
    EducationClassSchedulesAverageCreatedAtAsc = 'EDUCATION_CLASS_SCHEDULES_AVERAGE_CREATED_AT_ASC',
    EducationClassSchedulesAverageCreatedAtDesc = 'EDUCATION_CLASS_SCHEDULES_AVERAGE_CREATED_AT_DESC',
    EducationClassSchedulesAverageUpdatedAtAsc = 'EDUCATION_CLASS_SCHEDULES_AVERAGE_UPDATED_AT_ASC',
    EducationClassSchedulesAverageUpdatedAtDesc = 'EDUCATION_CLASS_SCHEDULES_AVERAGE_UPDATED_AT_DESC',
    EducationClassSchedulesAverageCourseYearAsc = 'EDUCATION_CLASS_SCHEDULES_AVERAGE_COURSE_YEAR_ASC',
    EducationClassSchedulesAverageCourseYearDesc = 'EDUCATION_CLASS_SCHEDULES_AVERAGE_COURSE_YEAR_DESC',
    EducationClassSchedulesStddevSampleIdAsc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_SAMPLE_ID_ASC',
    EducationClassSchedulesStddevSampleIdDesc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_SAMPLE_ID_DESC',
    EducationClassSchedulesStddevSampleEducationClassIdAsc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_SAMPLE_EDUCATION_CLASS_ID_ASC',
    EducationClassSchedulesStddevSampleEducationClassIdDesc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_SAMPLE_EDUCATION_CLASS_ID_DESC',
    EducationClassSchedulesStddevSampleStartDateAsc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_SAMPLE_START_DATE_ASC',
    EducationClassSchedulesStddevSampleStartDateDesc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_SAMPLE_START_DATE_DESC',
    EducationClassSchedulesStddevSampleEndDateAsc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_SAMPLE_END_DATE_ASC',
    EducationClassSchedulesStddevSampleEndDateDesc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_SAMPLE_END_DATE_DESC',
    EducationClassSchedulesStddevSampleTimetableAsc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_SAMPLE_TIMETABLE_ASC',
    EducationClassSchedulesStddevSampleTimetableDesc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_SAMPLE_TIMETABLE_DESC',
    EducationClassSchedulesStddevSampleLocationIdAsc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_SAMPLE_LOCATION_ID_ASC',
    EducationClassSchedulesStddevSampleLocationIdDesc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_SAMPLE_LOCATION_ID_DESC',
    EducationClassSchedulesStddevSampleCreatedAtAsc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_SAMPLE_CREATED_AT_ASC',
    EducationClassSchedulesStddevSampleCreatedAtDesc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_SAMPLE_CREATED_AT_DESC',
    EducationClassSchedulesStddevSampleUpdatedAtAsc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_SAMPLE_UPDATED_AT_ASC',
    EducationClassSchedulesStddevSampleUpdatedAtDesc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_SAMPLE_UPDATED_AT_DESC',
    EducationClassSchedulesStddevSampleCourseYearAsc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_SAMPLE_COURSE_YEAR_ASC',
    EducationClassSchedulesStddevSampleCourseYearDesc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_SAMPLE_COURSE_YEAR_DESC',
    EducationClassSchedulesStddevPopulationIdAsc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_POPULATION_ID_ASC',
    EducationClassSchedulesStddevPopulationIdDesc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_POPULATION_ID_DESC',
    EducationClassSchedulesStddevPopulationEducationClassIdAsc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_POPULATION_EDUCATION_CLASS_ID_ASC',
    EducationClassSchedulesStddevPopulationEducationClassIdDesc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_POPULATION_EDUCATION_CLASS_ID_DESC',
    EducationClassSchedulesStddevPopulationStartDateAsc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_POPULATION_START_DATE_ASC',
    EducationClassSchedulesStddevPopulationStartDateDesc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_POPULATION_START_DATE_DESC',
    EducationClassSchedulesStddevPopulationEndDateAsc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_POPULATION_END_DATE_ASC',
    EducationClassSchedulesStddevPopulationEndDateDesc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_POPULATION_END_DATE_DESC',
    EducationClassSchedulesStddevPopulationTimetableAsc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_POPULATION_TIMETABLE_ASC',
    EducationClassSchedulesStddevPopulationTimetableDesc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_POPULATION_TIMETABLE_DESC',
    EducationClassSchedulesStddevPopulationLocationIdAsc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_POPULATION_LOCATION_ID_ASC',
    EducationClassSchedulesStddevPopulationLocationIdDesc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_POPULATION_LOCATION_ID_DESC',
    EducationClassSchedulesStddevPopulationCreatedAtAsc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_POPULATION_CREATED_AT_ASC',
    EducationClassSchedulesStddevPopulationCreatedAtDesc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_POPULATION_CREATED_AT_DESC',
    EducationClassSchedulesStddevPopulationUpdatedAtAsc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_POPULATION_UPDATED_AT_ASC',
    EducationClassSchedulesStddevPopulationUpdatedAtDesc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_POPULATION_UPDATED_AT_DESC',
    EducationClassSchedulesStddevPopulationCourseYearAsc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_POPULATION_COURSE_YEAR_ASC',
    EducationClassSchedulesStddevPopulationCourseYearDesc = 'EDUCATION_CLASS_SCHEDULES_STDDEV_POPULATION_COURSE_YEAR_DESC',
    EducationClassSchedulesVarianceSampleIdAsc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_SAMPLE_ID_ASC',
    EducationClassSchedulesVarianceSampleIdDesc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_SAMPLE_ID_DESC',
    EducationClassSchedulesVarianceSampleEducationClassIdAsc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_SAMPLE_EDUCATION_CLASS_ID_ASC',
    EducationClassSchedulesVarianceSampleEducationClassIdDesc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_SAMPLE_EDUCATION_CLASS_ID_DESC',
    EducationClassSchedulesVarianceSampleStartDateAsc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_SAMPLE_START_DATE_ASC',
    EducationClassSchedulesVarianceSampleStartDateDesc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_SAMPLE_START_DATE_DESC',
    EducationClassSchedulesVarianceSampleEndDateAsc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_SAMPLE_END_DATE_ASC',
    EducationClassSchedulesVarianceSampleEndDateDesc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_SAMPLE_END_DATE_DESC',
    EducationClassSchedulesVarianceSampleTimetableAsc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_SAMPLE_TIMETABLE_ASC',
    EducationClassSchedulesVarianceSampleTimetableDesc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_SAMPLE_TIMETABLE_DESC',
    EducationClassSchedulesVarianceSampleLocationIdAsc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_SAMPLE_LOCATION_ID_ASC',
    EducationClassSchedulesVarianceSampleLocationIdDesc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_SAMPLE_LOCATION_ID_DESC',
    EducationClassSchedulesVarianceSampleCreatedAtAsc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_SAMPLE_CREATED_AT_ASC',
    EducationClassSchedulesVarianceSampleCreatedAtDesc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_SAMPLE_CREATED_AT_DESC',
    EducationClassSchedulesVarianceSampleUpdatedAtAsc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_SAMPLE_UPDATED_AT_ASC',
    EducationClassSchedulesVarianceSampleUpdatedAtDesc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_SAMPLE_UPDATED_AT_DESC',
    EducationClassSchedulesVarianceSampleCourseYearAsc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_SAMPLE_COURSE_YEAR_ASC',
    EducationClassSchedulesVarianceSampleCourseYearDesc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_SAMPLE_COURSE_YEAR_DESC',
    EducationClassSchedulesVariancePopulationIdAsc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_POPULATION_ID_ASC',
    EducationClassSchedulesVariancePopulationIdDesc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_POPULATION_ID_DESC',
    EducationClassSchedulesVariancePopulationEducationClassIdAsc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_POPULATION_EDUCATION_CLASS_ID_ASC',
    EducationClassSchedulesVariancePopulationEducationClassIdDesc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_POPULATION_EDUCATION_CLASS_ID_DESC',
    EducationClassSchedulesVariancePopulationStartDateAsc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_POPULATION_START_DATE_ASC',
    EducationClassSchedulesVariancePopulationStartDateDesc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_POPULATION_START_DATE_DESC',
    EducationClassSchedulesVariancePopulationEndDateAsc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_POPULATION_END_DATE_ASC',
    EducationClassSchedulesVariancePopulationEndDateDesc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_POPULATION_END_DATE_DESC',
    EducationClassSchedulesVariancePopulationTimetableAsc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_POPULATION_TIMETABLE_ASC',
    EducationClassSchedulesVariancePopulationTimetableDesc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_POPULATION_TIMETABLE_DESC',
    EducationClassSchedulesVariancePopulationLocationIdAsc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_POPULATION_LOCATION_ID_ASC',
    EducationClassSchedulesVariancePopulationLocationIdDesc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_POPULATION_LOCATION_ID_DESC',
    EducationClassSchedulesVariancePopulationCreatedAtAsc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_POPULATION_CREATED_AT_ASC',
    EducationClassSchedulesVariancePopulationCreatedAtDesc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_POPULATION_CREATED_AT_DESC',
    EducationClassSchedulesVariancePopulationUpdatedAtAsc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_POPULATION_UPDATED_AT_ASC',
    EducationClassSchedulesVariancePopulationUpdatedAtDesc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_POPULATION_UPDATED_AT_DESC',
    EducationClassSchedulesVariancePopulationCourseYearAsc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_POPULATION_COURSE_YEAR_ASC',
    EducationClassSchedulesVariancePopulationCourseYearDesc = 'EDUCATION_CLASS_SCHEDULES_VARIANCE_POPULATION_COURSE_YEAR_DESC',
}

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
    __typename?: 'Mutation';
    /** Creates a single `Booking`. */
    createBooking?: Maybe<CreateBookingPayload>;
    /** Creates a single `Course`. */
    createCourse?: Maybe<CreateCoursePayload>;
    /** Creates a single `CourseDefaultSpeaker`. */
    createCourseDefaultSpeaker?: Maybe<CreateCourseDefaultSpeakerPayload>;
    /** Creates a single `CourseGroup`. */
    createCourseGroup?: Maybe<CreateCourseGroupPayload>;
    /** Creates a single `CourseGroupMenu`. */
    createCourseGroupMenu?: Maybe<CreateCourseGroupMenuPayload>;
    /** Creates a single `CourseSchedule`. */
    createCourseSchedule?: Maybe<CreateCourseSchedulePayload>;
    /** Creates a single `CourseScheduleSpeaker`. */
    createCourseScheduleSpeaker?: Maybe<CreateCourseScheduleSpeakerPayload>;
    /** Creates a single `Education`. */
    createEducation?: Maybe<CreateEducationPayload>;
    /** Creates a single `EducationClass`. */
    createEducationClass?: Maybe<CreateEducationClassPayload>;
    /** Creates a single `EducationClassSchedule`. */
    createEducationClassSchedule?: Maybe<CreateEducationClassSchedulePayload>;
    /** Creates a single `EducationClassScheduleEntry`. */
    createEducationClassScheduleEntry?: Maybe<CreateEducationClassScheduleEntryPayload>;
    /** Creates a single `EmailCode`. */
    createEmailCode?: Maybe<CreateEmailCodePayload>;
    /** Creates a single `EmailTemplate`. */
    createEmailTemplate?: Maybe<CreateEmailTemplatePayload>;
    /** Creates a single `Location`. */
    createLocation?: Maybe<CreateLocationPayload>;
    /** Creates a single `Participant`. */
    createParticipant?: Maybe<CreateParticipantPayload>;
    /** Creates a single `Setting`. */
    createSetting?: Maybe<CreateSettingPayload>;
    /** Creates a single `Speaker`. */
    createSpeaker?: Maybe<CreateSpeakerPayload>;
    /** Updates a single `Booking` using its globally unique id and a patch. */
    updateBookingByNodeId?: Maybe<UpdateBookingPayload>;
    /** Updates a single `Booking` using a unique key and a patch. */
    updateBooking?: Maybe<UpdateBookingPayload>;
    /** Updates a single `Booking` using a unique key and a patch. */
    updateBookingByCode?: Maybe<UpdateBookingPayload>;
    /** Updates a single `Course` using its globally unique id and a patch. */
    updateCourseByNodeId?: Maybe<UpdateCoursePayload>;
    /** Updates a single `Course` using a unique key and a patch. */
    updateCourse?: Maybe<UpdateCoursePayload>;
    /** Updates a single `CourseDefaultSpeaker` using its globally unique id and a patch. */
    updateCourseDefaultSpeakerByNodeId?: Maybe<UpdateCourseDefaultSpeakerPayload>;
    /** Updates a single `CourseDefaultSpeaker` using a unique key and a patch. */
    updateCourseDefaultSpeaker?: Maybe<UpdateCourseDefaultSpeakerPayload>;
    /** Updates a single `CourseGroup` using its globally unique id and a patch. */
    updateCourseGroupByNodeId?: Maybe<UpdateCourseGroupPayload>;
    /** Updates a single `CourseGroup` using a unique key and a patch. */
    updateCourseGroup?: Maybe<UpdateCourseGroupPayload>;
    /** Updates a single `CourseGroupMenu` using its globally unique id and a patch. */
    updateCourseGroupMenuByNodeId?: Maybe<UpdateCourseGroupMenuPayload>;
    /** Updates a single `CourseGroupMenu` using a unique key and a patch. */
    updateCourseGroupMenu?: Maybe<UpdateCourseGroupMenuPayload>;
    /** Updates a single `CourseSchedule` using its globally unique id and a patch. */
    updateCourseScheduleByNodeId?: Maybe<UpdateCourseSchedulePayload>;
    /** Updates a single `CourseSchedule` using a unique key and a patch. */
    updateCourseSchedule?: Maybe<UpdateCourseSchedulePayload>;
    /** Updates a single `CourseScheduleSpeaker` using its globally unique id and a patch. */
    updateCourseScheduleSpeakerByNodeId?: Maybe<UpdateCourseScheduleSpeakerPayload>;
    /** Updates a single `CourseScheduleSpeaker` using a unique key and a patch. */
    updateCourseScheduleSpeaker?: Maybe<UpdateCourseScheduleSpeakerPayload>;
    /** Updates a single `Education` using its globally unique id and a patch. */
    updateEducationByNodeId?: Maybe<UpdateEducationPayload>;
    /** Updates a single `Education` using a unique key and a patch. */
    updateEducation?: Maybe<UpdateEducationPayload>;
    /** Updates a single `EducationClass` using its globally unique id and a patch. */
    updateEducationClassByNodeId?: Maybe<UpdateEducationClassPayload>;
    /** Updates a single `EducationClass` using a unique key and a patch. */
    updateEducationClass?: Maybe<UpdateEducationClassPayload>;
    /** Updates a single `EducationClassSchedule` using its globally unique id and a patch. */
    updateEducationClassScheduleByNodeId?: Maybe<UpdateEducationClassSchedulePayload>;
    /** Updates a single `EducationClassSchedule` using a unique key and a patch. */
    updateEducationClassSchedule?: Maybe<UpdateEducationClassSchedulePayload>;
    /** Updates a single `EducationClassScheduleEntry` using its globally unique id and a patch. */
    updateEducationClassScheduleEntryByNodeId?: Maybe<UpdateEducationClassScheduleEntryPayload>;
    /** Updates a single `EducationClassScheduleEntry` using a unique key and a patch. */
    updateEducationClassScheduleEntry?: Maybe<UpdateEducationClassScheduleEntryPayload>;
    /** Updates a single `EmailCode` using its globally unique id and a patch. */
    updateEmailCodeByNodeId?: Maybe<UpdateEmailCodePayload>;
    /** Updates a single `EmailCode` using a unique key and a patch. */
    updateEmailCode?: Maybe<UpdateEmailCodePayload>;
    /** Updates a single `EmailCode` using a unique key and a patch. */
    updateEmailCodeByCode?: Maybe<UpdateEmailCodePayload>;
    /** Updates a single `EmailTemplate` using its globally unique id and a patch. */
    updateEmailTemplateByNodeId?: Maybe<UpdateEmailTemplatePayload>;
    /** Updates a single `EmailTemplate` using a unique key and a patch. */
    updateEmailTemplate?: Maybe<UpdateEmailTemplatePayload>;
    /** Updates a single `Location` using its globally unique id and a patch. */
    updateLocationByNodeId?: Maybe<UpdateLocationPayload>;
    /** Updates a single `Location` using a unique key and a patch. */
    updateLocation?: Maybe<UpdateLocationPayload>;
    /** Updates a single `Participant` using its globally unique id and a patch. */
    updateParticipantByNodeId?: Maybe<UpdateParticipantPayload>;
    /** Updates a single `Participant` using a unique key and a patch. */
    updateParticipant?: Maybe<UpdateParticipantPayload>;
    /** Updates a single `Participant` using a unique key and a patch. */
    updateParticipantByEmail?: Maybe<UpdateParticipantPayload>;
    /** Updates a single `Setting` using its globally unique id and a patch. */
    updateSettingByNodeId?: Maybe<UpdateSettingPayload>;
    /** Updates a single `Setting` using a unique key and a patch. */
    updateSetting?: Maybe<UpdateSettingPayload>;
    /** Updates a single `Speaker` using its globally unique id and a patch. */
    updateSpeakerByNodeId?: Maybe<UpdateSpeakerPayload>;
    /** Updates a single `Speaker` using a unique key and a patch. */
    updateSpeaker?: Maybe<UpdateSpeakerPayload>;
    /** Deletes a single `Booking` using its globally unique id. */
    deleteBookingByNodeId?: Maybe<DeleteBookingPayload>;
    /** Deletes a single `Booking` using a unique key. */
    deleteBooking?: Maybe<DeleteBookingPayload>;
    /** Deletes a single `Booking` using a unique key. */
    deleteBookingByCode?: Maybe<DeleteBookingPayload>;
    /** Deletes a single `Course` using its globally unique id. */
    deleteCourseByNodeId?: Maybe<DeleteCoursePayload>;
    /** Deletes a single `Course` using a unique key. */
    deleteCourse?: Maybe<DeleteCoursePayload>;
    /** Deletes a single `CourseDefaultSpeaker` using its globally unique id. */
    deleteCourseDefaultSpeakerByNodeId?: Maybe<DeleteCourseDefaultSpeakerPayload>;
    /** Deletes a single `CourseDefaultSpeaker` using a unique key. */
    deleteCourseDefaultSpeaker?: Maybe<DeleteCourseDefaultSpeakerPayload>;
    /** Deletes a single `CourseGroup` using its globally unique id. */
    deleteCourseGroupByNodeId?: Maybe<DeleteCourseGroupPayload>;
    /** Deletes a single `CourseGroup` using a unique key. */
    deleteCourseGroup?: Maybe<DeleteCourseGroupPayload>;
    /** Deletes a single `CourseGroupMenu` using its globally unique id. */
    deleteCourseGroupMenuByNodeId?: Maybe<DeleteCourseGroupMenuPayload>;
    /** Deletes a single `CourseGroupMenu` using a unique key. */
    deleteCourseGroupMenu?: Maybe<DeleteCourseGroupMenuPayload>;
    /** Deletes a single `CourseSchedule` using its globally unique id. */
    deleteCourseScheduleByNodeId?: Maybe<DeleteCourseSchedulePayload>;
    /** Deletes a single `CourseSchedule` using a unique key. */
    deleteCourseSchedule?: Maybe<DeleteCourseSchedulePayload>;
    /** Deletes a single `CourseScheduleSpeaker` using its globally unique id. */
    deleteCourseScheduleSpeakerByNodeId?: Maybe<DeleteCourseScheduleSpeakerPayload>;
    /** Deletes a single `CourseScheduleSpeaker` using a unique key. */
    deleteCourseScheduleSpeaker?: Maybe<DeleteCourseScheduleSpeakerPayload>;
    /** Deletes a single `Education` using its globally unique id. */
    deleteEducationByNodeId?: Maybe<DeleteEducationPayload>;
    /** Deletes a single `Education` using a unique key. */
    deleteEducation?: Maybe<DeleteEducationPayload>;
    /** Deletes a single `EducationClass` using its globally unique id. */
    deleteEducationClassByNodeId?: Maybe<DeleteEducationClassPayload>;
    /** Deletes a single `EducationClass` using a unique key. */
    deleteEducationClass?: Maybe<DeleteEducationClassPayload>;
    /** Deletes a single `EducationClassSchedule` using its globally unique id. */
    deleteEducationClassScheduleByNodeId?: Maybe<DeleteEducationClassSchedulePayload>;
    /** Deletes a single `EducationClassSchedule` using a unique key. */
    deleteEducationClassSchedule?: Maybe<DeleteEducationClassSchedulePayload>;
    /** Deletes a single `EducationClassScheduleEntry` using its globally unique id. */
    deleteEducationClassScheduleEntryByNodeId?: Maybe<DeleteEducationClassScheduleEntryPayload>;
    /** Deletes a single `EducationClassScheduleEntry` using a unique key. */
    deleteEducationClassScheduleEntry?: Maybe<DeleteEducationClassScheduleEntryPayload>;
    /** Deletes a single `EmailCode` using its globally unique id. */
    deleteEmailCodeByNodeId?: Maybe<DeleteEmailCodePayload>;
    /** Deletes a single `EmailCode` using a unique key. */
    deleteEmailCode?: Maybe<DeleteEmailCodePayload>;
    /** Deletes a single `EmailCode` using a unique key. */
    deleteEmailCodeByCode?: Maybe<DeleteEmailCodePayload>;
    /** Deletes a single `EmailTemplate` using its globally unique id. */
    deleteEmailTemplateByNodeId?: Maybe<DeleteEmailTemplatePayload>;
    /** Deletes a single `EmailTemplate` using a unique key. */
    deleteEmailTemplate?: Maybe<DeleteEmailTemplatePayload>;
    /** Deletes a single `Location` using its globally unique id. */
    deleteLocationByNodeId?: Maybe<DeleteLocationPayload>;
    /** Deletes a single `Location` using a unique key. */
    deleteLocation?: Maybe<DeleteLocationPayload>;
    /** Deletes a single `Participant` using its globally unique id. */
    deleteParticipantByNodeId?: Maybe<DeleteParticipantPayload>;
    /** Deletes a single `Participant` using a unique key. */
    deleteParticipant?: Maybe<DeleteParticipantPayload>;
    /** Deletes a single `Participant` using a unique key. */
    deleteParticipantByEmail?: Maybe<DeleteParticipantPayload>;
    /** Deletes a single `Setting` using its globally unique id. */
    deleteSettingByNodeId?: Maybe<DeleteSettingPayload>;
    /** Deletes a single `Setting` using a unique key. */
    deleteSetting?: Maybe<DeleteSettingPayload>;
    /** Deletes a single `Speaker` using its globally unique id. */
    deleteSpeakerByNodeId?: Maybe<DeleteSpeakerPayload>;
    /** Deletes a single `Speaker` using a unique key. */
    deleteSpeaker?: Maybe<DeleteSpeakerPayload>;
    bookingPassedDocument?: Maybe<BookingPassedDocumentPayload>;
    checkDatePattern?: Maybe<CheckDatePatternPayload>;
    combineParticipants?: Maybe<CombineParticipantsPayload>;
    courseScheduleBookable?: Maybe<CourseScheduleBookablePayload>;
    createBookingForParticipant?: Maybe<CreateBookingForParticipantPayload>;
    createParticipantPassedDocuments?: Maybe<CreateParticipantPassedDocumentsPayload>;
    createParticipantsList?: Maybe<CreateParticipantsListPayload>;
    createParticipantsSepaXml?: Maybe<CreateParticipantsSepaXmlPayload>;
    createSingleParticipantCertificateDocument?: Maybe<CreateSingleParticipantCertificateDocumentPayload>;
    createSingleParticipantPassedDocument?: Maybe<CreateSingleParticipantPassedDocumentPayload>;
    createWelcomeCode?: Maybe<CreateWelcomeCodePayload>;
    participantDetailsForCertificateCourseList?: Maybe<ParticipantDetailsForCertificateCourseListPayload>;
    pySendEmail?: Maybe<PySendEmailPayload>;
    pySendEmailWelcome?: Maybe<PySendEmailWelcomePayload>;
    sendEmailSignupConfirmation?: Maybe<SendEmailSignupConfirmationPayload>;
    sendEmailWelcome?: Maybe<SendEmailWelcomePayload>;
    updateAddAllPassedParticipantsToCertificate?: Maybe<UpdateAddAllPassedParticipantsToCertificatePayload>;
    updateBookingsBankData?: Maybe<UpdateBookingsBankDataPayload>;
    updateCourseScheduleSpeakers?: Maybe<UpdateCourseScheduleSpeakersPayload>;
    updatePassedAllParticipants?: Maybe<UpdatePassedAllParticipantsPayload>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateBookingArgs = {
    input: CreateBookingInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCourseArgs = {
    input: CreateCourseInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCourseDefaultSpeakerArgs = {
    input: CreateCourseDefaultSpeakerInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCourseGroupArgs = {
    input: CreateCourseGroupInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCourseGroupMenuArgs = {
    input: CreateCourseGroupMenuInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCourseScheduleArgs = {
    input: CreateCourseScheduleInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCourseScheduleSpeakerArgs = {
    input: CreateCourseScheduleSpeakerInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateEducationArgs = {
    input: CreateEducationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateEducationClassArgs = {
    input: CreateEducationClassInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateEducationClassScheduleArgs = {
    input: CreateEducationClassScheduleInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateEducationClassScheduleEntryArgs = {
    input: CreateEducationClassScheduleEntryInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateEmailCodeArgs = {
    input: CreateEmailCodeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateEmailTemplateArgs = {
    input: CreateEmailTemplateInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLocationArgs = {
    input: CreateLocationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateParticipantArgs = {
    input: CreateParticipantInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSettingArgs = {
    input: CreateSettingInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSpeakerArgs = {
    input: CreateSpeakerInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBookingByNodeIdArgs = {
    input: UpdateBookingByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBookingArgs = {
    input: UpdateBookingInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBookingByCodeArgs = {
    input: UpdateBookingByCodeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCourseByNodeIdArgs = {
    input: UpdateCourseByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCourseArgs = {
    input: UpdateCourseInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCourseDefaultSpeakerByNodeIdArgs = {
    input: UpdateCourseDefaultSpeakerByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCourseDefaultSpeakerArgs = {
    input: UpdateCourseDefaultSpeakerInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCourseGroupByNodeIdArgs = {
    input: UpdateCourseGroupByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCourseGroupArgs = {
    input: UpdateCourseGroupInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCourseGroupMenuByNodeIdArgs = {
    input: UpdateCourseGroupMenuByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCourseGroupMenuArgs = {
    input: UpdateCourseGroupMenuInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCourseScheduleByNodeIdArgs = {
    input: UpdateCourseScheduleByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCourseScheduleArgs = {
    input: UpdateCourseScheduleInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCourseScheduleSpeakerByNodeIdArgs = {
    input: UpdateCourseScheduleSpeakerByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCourseScheduleSpeakerArgs = {
    input: UpdateCourseScheduleSpeakerInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEducationByNodeIdArgs = {
    input: UpdateEducationByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEducationArgs = {
    input: UpdateEducationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEducationClassByNodeIdArgs = {
    input: UpdateEducationClassByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEducationClassArgs = {
    input: UpdateEducationClassInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEducationClassScheduleByNodeIdArgs = {
    input: UpdateEducationClassScheduleByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEducationClassScheduleArgs = {
    input: UpdateEducationClassScheduleInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEducationClassScheduleEntryByNodeIdArgs = {
    input: UpdateEducationClassScheduleEntryByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEducationClassScheduleEntryArgs = {
    input: UpdateEducationClassScheduleEntryInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEmailCodeByNodeIdArgs = {
    input: UpdateEmailCodeByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEmailCodeArgs = {
    input: UpdateEmailCodeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEmailCodeByCodeArgs = {
    input: UpdateEmailCodeByCodeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEmailTemplateByNodeIdArgs = {
    input: UpdateEmailTemplateByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEmailTemplateArgs = {
    input: UpdateEmailTemplateInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLocationByNodeIdArgs = {
    input: UpdateLocationByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLocationArgs = {
    input: UpdateLocationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateParticipantByNodeIdArgs = {
    input: UpdateParticipantByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateParticipantArgs = {
    input: UpdateParticipantInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateParticipantByEmailArgs = {
    input: UpdateParticipantByEmailInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSettingByNodeIdArgs = {
    input: UpdateSettingByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSettingArgs = {
    input: UpdateSettingInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSpeakerByNodeIdArgs = {
    input: UpdateSpeakerByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSpeakerArgs = {
    input: UpdateSpeakerInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteBookingByNodeIdArgs = {
    input: DeleteBookingByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteBookingArgs = {
    input: DeleteBookingInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteBookingByCodeArgs = {
    input: DeleteBookingByCodeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCourseByNodeIdArgs = {
    input: DeleteCourseByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCourseArgs = {
    input: DeleteCourseInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCourseDefaultSpeakerByNodeIdArgs = {
    input: DeleteCourseDefaultSpeakerByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCourseDefaultSpeakerArgs = {
    input: DeleteCourseDefaultSpeakerInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCourseGroupByNodeIdArgs = {
    input: DeleteCourseGroupByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCourseGroupArgs = {
    input: DeleteCourseGroupInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCourseGroupMenuByNodeIdArgs = {
    input: DeleteCourseGroupMenuByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCourseGroupMenuArgs = {
    input: DeleteCourseGroupMenuInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCourseScheduleByNodeIdArgs = {
    input: DeleteCourseScheduleByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCourseScheduleArgs = {
    input: DeleteCourseScheduleInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCourseScheduleSpeakerByNodeIdArgs = {
    input: DeleteCourseScheduleSpeakerByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCourseScheduleSpeakerArgs = {
    input: DeleteCourseScheduleSpeakerInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEducationByNodeIdArgs = {
    input: DeleteEducationByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEducationArgs = {
    input: DeleteEducationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEducationClassByNodeIdArgs = {
    input: DeleteEducationClassByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEducationClassArgs = {
    input: DeleteEducationClassInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEducationClassScheduleByNodeIdArgs = {
    input: DeleteEducationClassScheduleByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEducationClassScheduleArgs = {
    input: DeleteEducationClassScheduleInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEducationClassScheduleEntryByNodeIdArgs = {
    input: DeleteEducationClassScheduleEntryByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEducationClassScheduleEntryArgs = {
    input: DeleteEducationClassScheduleEntryInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEmailCodeByNodeIdArgs = {
    input: DeleteEmailCodeByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEmailCodeArgs = {
    input: DeleteEmailCodeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEmailCodeByCodeArgs = {
    input: DeleteEmailCodeByCodeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEmailTemplateByNodeIdArgs = {
    input: DeleteEmailTemplateByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEmailTemplateArgs = {
    input: DeleteEmailTemplateInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLocationByNodeIdArgs = {
    input: DeleteLocationByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLocationArgs = {
    input: DeleteLocationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteParticipantByNodeIdArgs = {
    input: DeleteParticipantByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteParticipantArgs = {
    input: DeleteParticipantInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteParticipantByEmailArgs = {
    input: DeleteParticipantByEmailInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSettingByNodeIdArgs = {
    input: DeleteSettingByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSettingArgs = {
    input: DeleteSettingInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSpeakerByNodeIdArgs = {
    input: DeleteSpeakerByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSpeakerArgs = {
    input: DeleteSpeakerInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationBookingPassedDocumentArgs = {
    input: BookingPassedDocumentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCheckDatePatternArgs = {
    input: CheckDatePatternInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCombineParticipantsArgs = {
    input: CombineParticipantsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCourseScheduleBookableArgs = {
    input: CourseScheduleBookableInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateBookingForParticipantArgs = {
    input: CreateBookingForParticipantInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateParticipantPassedDocumentsArgs = {
    input: CreateParticipantPassedDocumentsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateParticipantsListArgs = {
    input: CreateParticipantsListInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateParticipantsSepaXmlArgs = {
    input: CreateParticipantsSepaXmlInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSingleParticipantCertificateDocumentArgs = {
    input: CreateSingleParticipantCertificateDocumentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSingleParticipantPassedDocumentArgs = {
    input: CreateSingleParticipantPassedDocumentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateWelcomeCodeArgs = {
    input: CreateWelcomeCodeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationParticipantDetailsForCertificateCourseListArgs = {
    input: ParticipantDetailsForCertificateCourseListInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationPySendEmailArgs = {
    input: PySendEmailInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationPySendEmailWelcomeArgs = {
    input: PySendEmailWelcomeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSendEmailSignupConfirmationArgs = {
    input: SendEmailSignupConfirmationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationSendEmailWelcomeArgs = {
    input: SendEmailWelcomeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAddAllPassedParticipantsToCertificateArgs = {
    input: UpdateAddAllPassedParticipantsToCertificateInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBookingsBankDataArgs = {
    input: UpdateBookingsBankDataInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCourseScheduleSpeakersArgs = {
    input: UpdateCourseScheduleSpeakersInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePassedAllParticipantsArgs = {
    input: UpdatePassedAllParticipantsInput;
};

/** An object with a globally unique `ID`. */
export type Node = {
    /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
    nodeId: Scalars['ID'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
    __typename?: 'PageInfo';
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean'];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['Cursor']>;
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['Cursor']>;
};

export type Participant = Node & {
    __typename?: 'Participant';
    /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
    nodeId: Scalars['ID'];
    id: Scalars['Int'];
    title?: Maybe<Scalars['String']>;
    familyName: Scalars['String'];
    givenName: Scalars['String'];
    jobTitle?: Maybe<Scalars['String']>;
    street?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    postcode?: Maybe<Scalars['String']>;
    country: Scalars['String'];
    phone?: Maybe<Scalars['String']>;
    mobile?: Maybe<Scalars['String']>;
    fax?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    birthday?: Maybe<Scalars['Date']>;
    note?: Maybe<Scalars['String']>;
    invoiceFamilyName?: Maybe<Scalars['String']>;
    invoiceSalutation?: Maybe<Scalars['String']>;
    invoiceTitle?: Maybe<Scalars['String']>;
    invoiceGivenName?: Maybe<Scalars['String']>;
    invoiceStreet?: Maybe<Scalars['String']>;
    invoicePostcode?: Maybe<Scalars['String']>;
    invoiceCity?: Maybe<Scalars['String']>;
    invoiceCountry?: Maybe<Scalars['String']>;
    invoiceBankAccountNumber?: Maybe<Scalars['String']>;
    invoiceBankOwner?: Maybe<Scalars['String']>;
    invoiceBankOldCode?: Maybe<Scalars['String']>;
    invoiceBankName?: Maybe<Scalars['String']>;
    invoiceBankSwift?: Maybe<Scalars['String']>;
    invoiceBankIban?: Maybe<Scalars['String']>;
    invoiceCredit: Scalars['BigFloat'];
    highlighted?: Maybe<Scalars['Boolean']>;
    createdAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    gender?: Maybe<ParticipantGender>;
    newsletter?: Maybe<Scalars['Boolean']>;
    files?: Maybe<Scalars['String']>;
    /** Reads and enables pagination through a set of `Booking`. */
    bookings: BookingsConnection;
    /** Reads and enables pagination through a set of `EmailCode`. */
    emailCodes: EmailCodesConnection;
    address?: Maybe<Scalars['String']>;
    addressLetter?: Maybe<Scalars['String']>;
    birthdayFormatted?: Maybe<Scalars['String']>;
    cleanMobileNumber?: Maybe<Scalars['String']>;
    cleanPhoneNumber?: Maybe<Scalars['String']>;
    fullName?: Maybe<Scalars['String']>;
    fullNameWithSalutation?: Maybe<Scalars['String']>;
    fullNameWithSalutationLetter?: Maybe<Scalars['String']>;
    fullnameAndAdress?: Maybe<Scalars['String']>;
    mobileOrPhone?: Maybe<Scalars['String']>;
    salutation?: Maybe<Scalars['String']>;
    salutationEmail?: Maybe<Scalars['String']>;
    salutationLetter?: Maybe<Scalars['String']>;
    sepaMandateName?: Maybe<Scalars['String']>;
    sepaName?: Maybe<Scalars['String']>;
    sortableName?: Maybe<Scalars['String']>;
};

export type ParticipantBookingsArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['Cursor']>;
    after?: Maybe<Scalars['Cursor']>;
    orderBy?: Maybe<Array<BookingsOrderBy>>;
    condition?: Maybe<BookingCondition>;
    filter?: Maybe<BookingFilter>;
};

export type ParticipantEmailCodesArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['Cursor']>;
    after?: Maybe<Scalars['Cursor']>;
    orderBy?: Maybe<Array<EmailCodesOrderBy>>;
    condition?: Maybe<EmailCodeCondition>;
    filter?: Maybe<EmailCodeFilter>;
};

export type ParticipantAddressLetterArgs = {
    delimiter?: Maybe<Scalars['String']>;
};

export type ParticipantFullNameWithSalutationLetterArgs = {
    delimiter?: Maybe<Scalars['String']>;
};

export type ParticipantAggregates = {
    __typename?: 'ParticipantAggregates';
    keys?: Maybe<Array<Scalars['String']>>;
    /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    sum?: Maybe<ParticipantSumAggregates>;
    /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
    distinctCount?: Maybe<ParticipantDistinctCountAggregates>;
    /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    min?: Maybe<ParticipantMinAggregates>;
    /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    max?: Maybe<ParticipantMaxAggregates>;
    /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
    average?: Maybe<ParticipantAverageAggregates>;
    /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
    stddevSample?: Maybe<ParticipantStddevSampleAggregates>;
    /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
    stddevPopulation?: Maybe<ParticipantStddevPopulationAggregates>;
    /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
    varianceSample?: Maybe<ParticipantVarianceSampleAggregates>;
    /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
    variancePopulation?: Maybe<ParticipantVariancePopulationAggregates>;
};

export type ParticipantAverageAggregates = {
    __typename?: 'ParticipantAverageAggregates';
    /** Mean average of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Mean average of invoiceCredit across the matching connection */
    invoiceCredit?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `Participant` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ParticipantCondition = {
    /** Checks for equality with the object’s `id` field. */
    id?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `title` field. */
    title?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `familyName` field. */
    familyName?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `givenName` field. */
    givenName?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `jobTitle` field. */
    jobTitle?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `street` field. */
    street?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `city` field. */
    city?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `postcode` field. */
    postcode?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `country` field. */
    country?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `phone` field. */
    phone?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `mobile` field. */
    mobile?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `fax` field. */
    fax?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `email` field. */
    email?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `birthday` field. */
    birthday?: Maybe<Scalars['Date']>;
    /** Checks for equality with the object’s `note` field. */
    note?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `invoiceFamilyName` field. */
    invoiceFamilyName?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `invoiceSalutation` field. */
    invoiceSalutation?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `invoiceTitle` field. */
    invoiceTitle?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `invoiceGivenName` field. */
    invoiceGivenName?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `invoiceStreet` field. */
    invoiceStreet?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `invoicePostcode` field. */
    invoicePostcode?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `invoiceCity` field. */
    invoiceCity?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `invoiceCountry` field. */
    invoiceCountry?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `invoiceBankAccountNumber` field. */
    invoiceBankAccountNumber?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `invoiceBankOwner` field. */
    invoiceBankOwner?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `invoiceBankOldCode` field. */
    invoiceBankOldCode?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `invoiceBankName` field. */
    invoiceBankName?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `invoiceBankSwift` field. */
    invoiceBankSwift?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `invoiceBankIban` field. */
    invoiceBankIban?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `invoiceCredit` field. */
    invoiceCredit?: Maybe<Scalars['BigFloat']>;
    /** Checks for equality with the object’s `highlighted` field. */
    highlighted?: Maybe<Scalars['Boolean']>;
    /** Checks for equality with the object’s `createdAt` field. */
    createdAt?: Maybe<Scalars['Datetime']>;
    /** Checks for equality with the object’s `updatedAt` field. */
    updatedAt?: Maybe<Scalars['Datetime']>;
    /** Checks for equality with the object’s `gender` field. */
    gender?: Maybe<ParticipantGender>;
    /** Checks for equality with the object’s `newsletter` field. */
    newsletter?: Maybe<Scalars['Boolean']>;
    /** Checks for equality with the object’s `files` field. */
    files?: Maybe<Scalars['String']>;
};

/** All input for the `participantDetailsForCertificateCourseList` mutation. */
export type ParticipantDetailsForCertificateCourseListInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    p: ParticipantInput;
};

/** The output of our `participantDetailsForCertificateCourseList` mutation. */
export type ParticipantDetailsForCertificateCourseListPayload = {
    __typename?: 'ParticipantDetailsForCertificateCourseListPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    json?: Maybe<Scalars['JSON']>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
};

export type ParticipantDistinctCountAggregates = {
    __typename?: 'ParticipantDistinctCountAggregates';
    /** Distinct count of id across the matching connection */
    id?: Maybe<Scalars['BigInt']>;
    /** Distinct count of title across the matching connection */
    title?: Maybe<Scalars['BigInt']>;
    /** Distinct count of familyName across the matching connection */
    familyName?: Maybe<Scalars['BigInt']>;
    /** Distinct count of givenName across the matching connection */
    givenName?: Maybe<Scalars['BigInt']>;
    /** Distinct count of jobTitle across the matching connection */
    jobTitle?: Maybe<Scalars['BigInt']>;
    /** Distinct count of street across the matching connection */
    street?: Maybe<Scalars['BigInt']>;
    /** Distinct count of city across the matching connection */
    city?: Maybe<Scalars['BigInt']>;
    /** Distinct count of postcode across the matching connection */
    postcode?: Maybe<Scalars['BigInt']>;
    /** Distinct count of country across the matching connection */
    country?: Maybe<Scalars['BigInt']>;
    /** Distinct count of phone across the matching connection */
    phone?: Maybe<Scalars['BigInt']>;
    /** Distinct count of mobile across the matching connection */
    mobile?: Maybe<Scalars['BigInt']>;
    /** Distinct count of fax across the matching connection */
    fax?: Maybe<Scalars['BigInt']>;
    /** Distinct count of email across the matching connection */
    email?: Maybe<Scalars['BigInt']>;
    /** Distinct count of birthday across the matching connection */
    birthday?: Maybe<Scalars['BigInt']>;
    /** Distinct count of note across the matching connection */
    note?: Maybe<Scalars['BigInt']>;
    /** Distinct count of invoiceFamilyName across the matching connection */
    invoiceFamilyName?: Maybe<Scalars['BigInt']>;
    /** Distinct count of invoiceSalutation across the matching connection */
    invoiceSalutation?: Maybe<Scalars['BigInt']>;
    /** Distinct count of invoiceTitle across the matching connection */
    invoiceTitle?: Maybe<Scalars['BigInt']>;
    /** Distinct count of invoiceGivenName across the matching connection */
    invoiceGivenName?: Maybe<Scalars['BigInt']>;
    /** Distinct count of invoiceStreet across the matching connection */
    invoiceStreet?: Maybe<Scalars['BigInt']>;
    /** Distinct count of invoicePostcode across the matching connection */
    invoicePostcode?: Maybe<Scalars['BigInt']>;
    /** Distinct count of invoiceCity across the matching connection */
    invoiceCity?: Maybe<Scalars['BigInt']>;
    /** Distinct count of invoiceCountry across the matching connection */
    invoiceCountry?: Maybe<Scalars['BigInt']>;
    /** Distinct count of invoiceBankAccountNumber across the matching connection */
    invoiceBankAccountNumber?: Maybe<Scalars['BigInt']>;
    /** Distinct count of invoiceBankOwner across the matching connection */
    invoiceBankOwner?: Maybe<Scalars['BigInt']>;
    /** Distinct count of invoiceBankOldCode across the matching connection */
    invoiceBankOldCode?: Maybe<Scalars['BigInt']>;
    /** Distinct count of invoiceBankName across the matching connection */
    invoiceBankName?: Maybe<Scalars['BigInt']>;
    /** Distinct count of invoiceBankSwift across the matching connection */
    invoiceBankSwift?: Maybe<Scalars['BigInt']>;
    /** Distinct count of invoiceBankIban across the matching connection */
    invoiceBankIban?: Maybe<Scalars['BigInt']>;
    /** Distinct count of invoiceCredit across the matching connection */
    invoiceCredit?: Maybe<Scalars['BigInt']>;
    /** Distinct count of highlighted across the matching connection */
    highlighted?: Maybe<Scalars['BigInt']>;
    /** Distinct count of createdAt across the matching connection */
    createdAt?: Maybe<Scalars['BigInt']>;
    /** Distinct count of updatedAt across the matching connection */
    updatedAt?: Maybe<Scalars['BigInt']>;
    /** Distinct count of gender across the matching connection */
    gender?: Maybe<Scalars['BigInt']>;
    /** Distinct count of newsletter across the matching connection */
    newsletter?: Maybe<Scalars['BigInt']>;
    /** Distinct count of files across the matching connection */
    files?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    address?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    addressLetter?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    birthdayFormatted?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    cleanMobileNumber?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    cleanPhoneNumber?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    fullName?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    fullNameWithSalutation?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    fullNameWithSalutationLetter?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    fullnameAndAdress?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    mobileOrPhone?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    salutation?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    salutationEmail?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    salutationLetter?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    sepaMandateName?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    sepaName?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    sortableName?: Maybe<Scalars['BigInt']>;
};

export type ParticipantDistinctCountAggregatesAddressLetterArgs = {
    delimiter?: Maybe<Scalars['String']>;
};

export type ParticipantDistinctCountAggregatesFullNameWithSalutationLetterArgs = {
    delimiter?: Maybe<Scalars['String']>;
};

/** A filter to be used against `Participant` object types. All fields are combined with a logical ‘and.’ */
export type ParticipantFilter = {
    /** Filter by the object’s `id` field. */
    id?: Maybe<IntFilter>;
    /** Filter by the object’s `title` field. */
    title?: Maybe<StringFilter>;
    /** Filter by the object’s `familyName` field. */
    familyName?: Maybe<StringFilter>;
    /** Filter by the object’s `givenName` field. */
    givenName?: Maybe<StringFilter>;
    /** Filter by the object’s `jobTitle` field. */
    jobTitle?: Maybe<StringFilter>;
    /** Filter by the object’s `street` field. */
    street?: Maybe<StringFilter>;
    /** Filter by the object’s `city` field. */
    city?: Maybe<StringFilter>;
    /** Filter by the object’s `postcode` field. */
    postcode?: Maybe<StringFilter>;
    /** Filter by the object’s `country` field. */
    country?: Maybe<StringFilter>;
    /** Filter by the object’s `phone` field. */
    phone?: Maybe<StringFilter>;
    /** Filter by the object’s `mobile` field. */
    mobile?: Maybe<StringFilter>;
    /** Filter by the object’s `fax` field. */
    fax?: Maybe<StringFilter>;
    /** Filter by the object’s `email` field. */
    email?: Maybe<StringFilter>;
    /** Filter by the object’s `birthday` field. */
    birthday?: Maybe<DateFilter>;
    /** Filter by the object’s `note` field. */
    note?: Maybe<StringFilter>;
    /** Filter by the object’s `invoiceFamilyName` field. */
    invoiceFamilyName?: Maybe<StringFilter>;
    /** Filter by the object’s `invoiceSalutation` field. */
    invoiceSalutation?: Maybe<StringFilter>;
    /** Filter by the object’s `invoiceTitle` field. */
    invoiceTitle?: Maybe<StringFilter>;
    /** Filter by the object’s `invoiceGivenName` field. */
    invoiceGivenName?: Maybe<StringFilter>;
    /** Filter by the object’s `invoiceStreet` field. */
    invoiceStreet?: Maybe<StringFilter>;
    /** Filter by the object’s `invoicePostcode` field. */
    invoicePostcode?: Maybe<StringFilter>;
    /** Filter by the object’s `invoiceCity` field. */
    invoiceCity?: Maybe<StringFilter>;
    /** Filter by the object’s `invoiceCountry` field. */
    invoiceCountry?: Maybe<StringFilter>;
    /** Filter by the object’s `invoiceBankAccountNumber` field. */
    invoiceBankAccountNumber?: Maybe<StringFilter>;
    /** Filter by the object’s `invoiceBankOwner` field. */
    invoiceBankOwner?: Maybe<StringFilter>;
    /** Filter by the object’s `invoiceBankOldCode` field. */
    invoiceBankOldCode?: Maybe<StringFilter>;
    /** Filter by the object’s `invoiceBankName` field. */
    invoiceBankName?: Maybe<StringFilter>;
    /** Filter by the object’s `invoiceBankSwift` field. */
    invoiceBankSwift?: Maybe<StringFilter>;
    /** Filter by the object’s `invoiceBankIban` field. */
    invoiceBankIban?: Maybe<StringFilter>;
    /** Filter by the object’s `invoiceCredit` field. */
    invoiceCredit?: Maybe<BigFloatFilter>;
    /** Filter by the object’s `highlighted` field. */
    highlighted?: Maybe<BooleanFilter>;
    /** Filter by the object’s `createdAt` field. */
    createdAt?: Maybe<DatetimeFilter>;
    /** Filter by the object’s `updatedAt` field. */
    updatedAt?: Maybe<DatetimeFilter>;
    /** Filter by the object’s `gender` field. */
    gender?: Maybe<ParticipantGenderFilter>;
    /** Filter by the object’s `newsletter` field. */
    newsletter?: Maybe<BooleanFilter>;
    /** Filter by the object’s `files` field. */
    files?: Maybe<StringFilter>;
    /** Filter by the object’s `address` field. */
    address?: Maybe<StringFilter>;
    /** Filter by the object’s `addressLetter` field. */
    addressLetter?: Maybe<StringFilter>;
    /** Filter by the object’s `birthdayFormatted` field. */
    birthdayFormatted?: Maybe<StringFilter>;
    /** Filter by the object’s `cleanMobileNumber` field. */
    cleanMobileNumber?: Maybe<StringFilter>;
    /** Filter by the object’s `cleanPhoneNumber` field. */
    cleanPhoneNumber?: Maybe<StringFilter>;
    /** Filter by the object’s `fullName` field. */
    fullName?: Maybe<StringFilter>;
    /** Filter by the object’s `fullNameWithSalutation` field. */
    fullNameWithSalutation?: Maybe<StringFilter>;
    /** Filter by the object’s `fullNameWithSalutationLetter` field. */
    fullNameWithSalutationLetter?: Maybe<StringFilter>;
    /** Filter by the object’s `fullnameAndAdress` field. */
    fullnameAndAdress?: Maybe<StringFilter>;
    /** Filter by the object’s `mobileOrPhone` field. */
    mobileOrPhone?: Maybe<StringFilter>;
    /** Filter by the object’s `salutation` field. */
    salutation?: Maybe<StringFilter>;
    /** Filter by the object’s `salutationEmail` field. */
    salutationEmail?: Maybe<StringFilter>;
    /** Filter by the object’s `salutationLetter` field. */
    salutationLetter?: Maybe<StringFilter>;
    /** Filter by the object’s `sepaMandateName` field. */
    sepaMandateName?: Maybe<StringFilter>;
    /** Filter by the object’s `sepaName` field. */
    sepaName?: Maybe<StringFilter>;
    /** Filter by the object’s `sortableName` field. */
    sortableName?: Maybe<StringFilter>;
    /** Checks for all expressions in this list. */
    and?: Maybe<Array<ParticipantFilter>>;
    /** Checks for any expressions in this list. */
    or?: Maybe<Array<ParticipantFilter>>;
    /** Negates the expression. */
    not?: Maybe<ParticipantFilter>;
};

export enum ParticipantGender {
    Male = 'MALE',
    Female = 'FEMALE',
    XGender = 'X_GENDER',
    Unknown = 'UNKNOWN',
}

/** A filter to be used against ParticipantGender fields. All fields are combined with a logical ‘and.’ */
export type ParticipantGenderFilter = {
    /** Is null (if `true` is specified) or is not null (if `false` is specified). */
    isNull?: Maybe<Scalars['Boolean']>;
    /** Equal to the specified value. */
    equalTo?: Maybe<ParticipantGender>;
    /** Not equal to the specified value. */
    notEqualTo?: Maybe<ParticipantGender>;
    /** Not equal to the specified value, treating null like an ordinary value. */
    distinctFrom?: Maybe<ParticipantGender>;
    /** Equal to the specified value, treating null like an ordinary value. */
    notDistinctFrom?: Maybe<ParticipantGender>;
    /** Included in the specified list. */
    in?: Maybe<Array<ParticipantGender>>;
    /** Not included in the specified list. */
    notIn?: Maybe<Array<ParticipantGender>>;
    /** Less than the specified value. */
    lessThan?: Maybe<ParticipantGender>;
    /** Less than or equal to the specified value. */
    lessThanOrEqualTo?: Maybe<ParticipantGender>;
    /** Greater than the specified value. */
    greaterThan?: Maybe<ParticipantGender>;
    /** Greater than or equal to the specified value. */
    greaterThanOrEqualTo?: Maybe<ParticipantGender>;
};

/** Grouping methods for `Participant` for usage during aggregation. */
export enum ParticipantGroupBy {
    Title = 'TITLE',
    FamilyName = 'FAMILY_NAME',
    GivenName = 'GIVEN_NAME',
    JobTitle = 'JOB_TITLE',
    Street = 'STREET',
    City = 'CITY',
    Postcode = 'POSTCODE',
    Country = 'COUNTRY',
    Phone = 'PHONE',
    Mobile = 'MOBILE',
    Fax = 'FAX',
    Birthday = 'BIRTHDAY',
    Note = 'NOTE',
    InvoiceFamilyName = 'INVOICE_FAMILY_NAME',
    InvoiceSalutation = 'INVOICE_SALUTATION',
    InvoiceTitle = 'INVOICE_TITLE',
    InvoiceGivenName = 'INVOICE_GIVEN_NAME',
    InvoiceStreet = 'INVOICE_STREET',
    InvoicePostcode = 'INVOICE_POSTCODE',
    InvoiceCity = 'INVOICE_CITY',
    InvoiceCountry = 'INVOICE_COUNTRY',
    InvoiceBankAccountNumber = 'INVOICE_BANK_ACCOUNT_NUMBER',
    InvoiceBankOwner = 'INVOICE_BANK_OWNER',
    InvoiceBankOldCode = 'INVOICE_BANK_OLD_CODE',
    InvoiceBankName = 'INVOICE_BANK_NAME',
    InvoiceBankSwift = 'INVOICE_BANK_SWIFT',
    InvoiceBankIban = 'INVOICE_BANK_IBAN',
    InvoiceCredit = 'INVOICE_CREDIT',
    Highlighted = 'HIGHLIGHTED',
    CreatedAt = 'CREATED_AT',
    CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
    CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
    UpdatedAt = 'UPDATED_AT',
    UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
    UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
    Gender = 'GENDER',
    Newsletter = 'NEWSLETTER',
    Files = 'FILES',
}

export type ParticipantHavingAverageInput = {
    id?: Maybe<HavingIntFilter>;
    birthday?: Maybe<HavingDatetimeFilter>;
    invoiceCredit?: Maybe<HavingBigfloatFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ParticipantHavingDistinctCountInput = {
    id?: Maybe<HavingIntFilter>;
    birthday?: Maybe<HavingDatetimeFilter>;
    invoiceCredit?: Maybe<HavingBigfloatFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `Participant` aggregates. */
export type ParticipantHavingInput = {
    AND?: Maybe<Array<ParticipantHavingInput>>;
    OR?: Maybe<Array<ParticipantHavingInput>>;
    sum?: Maybe<ParticipantHavingSumInput>;
    distinctCount?: Maybe<ParticipantHavingDistinctCountInput>;
    min?: Maybe<ParticipantHavingMinInput>;
    max?: Maybe<ParticipantHavingMaxInput>;
    average?: Maybe<ParticipantHavingAverageInput>;
    stddevSample?: Maybe<ParticipantHavingStddevSampleInput>;
    stddevPopulation?: Maybe<ParticipantHavingStddevPopulationInput>;
    varianceSample?: Maybe<ParticipantHavingVarianceSampleInput>;
    variancePopulation?: Maybe<ParticipantHavingVariancePopulationInput>;
};

export type ParticipantHavingMaxInput = {
    id?: Maybe<HavingIntFilter>;
    birthday?: Maybe<HavingDatetimeFilter>;
    invoiceCredit?: Maybe<HavingBigfloatFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ParticipantHavingMinInput = {
    id?: Maybe<HavingIntFilter>;
    birthday?: Maybe<HavingDatetimeFilter>;
    invoiceCredit?: Maybe<HavingBigfloatFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ParticipantHavingStddevPopulationInput = {
    id?: Maybe<HavingIntFilter>;
    birthday?: Maybe<HavingDatetimeFilter>;
    invoiceCredit?: Maybe<HavingBigfloatFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ParticipantHavingStddevSampleInput = {
    id?: Maybe<HavingIntFilter>;
    birthday?: Maybe<HavingDatetimeFilter>;
    invoiceCredit?: Maybe<HavingBigfloatFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ParticipantHavingSumInput = {
    id?: Maybe<HavingIntFilter>;
    birthday?: Maybe<HavingDatetimeFilter>;
    invoiceCredit?: Maybe<HavingBigfloatFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ParticipantHavingVariancePopulationInput = {
    id?: Maybe<HavingIntFilter>;
    birthday?: Maybe<HavingDatetimeFilter>;
    invoiceCredit?: Maybe<HavingBigfloatFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type ParticipantHavingVarianceSampleInput = {
    id?: Maybe<HavingIntFilter>;
    birthday?: Maybe<HavingDatetimeFilter>;
    invoiceCredit?: Maybe<HavingBigfloatFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `Participant` */
export type ParticipantInput = {
    id?: Maybe<Scalars['Int']>;
    title?: Maybe<Scalars['String']>;
    familyName: Scalars['String'];
    givenName: Scalars['String'];
    jobTitle?: Maybe<Scalars['String']>;
    street?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    postcode?: Maybe<Scalars['String']>;
    country?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    mobile?: Maybe<Scalars['String']>;
    fax?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    birthday?: Maybe<Scalars['Date']>;
    note?: Maybe<Scalars['String']>;
    invoiceFamilyName?: Maybe<Scalars['String']>;
    invoiceSalutation?: Maybe<Scalars['String']>;
    invoiceTitle?: Maybe<Scalars['String']>;
    invoiceGivenName?: Maybe<Scalars['String']>;
    invoiceStreet?: Maybe<Scalars['String']>;
    invoicePostcode?: Maybe<Scalars['String']>;
    invoiceCity?: Maybe<Scalars['String']>;
    invoiceCountry?: Maybe<Scalars['String']>;
    invoiceBankAccountNumber?: Maybe<Scalars['String']>;
    invoiceBankOwner?: Maybe<Scalars['String']>;
    invoiceBankOldCode?: Maybe<Scalars['String']>;
    invoiceBankName?: Maybe<Scalars['String']>;
    invoiceBankSwift?: Maybe<Scalars['String']>;
    invoiceBankIban?: Maybe<Scalars['String']>;
    invoiceCredit?: Maybe<Scalars['BigFloat']>;
    highlighted?: Maybe<Scalars['Boolean']>;
    createdAt?: Maybe<Scalars['Datetime']>;
    updatedAt?: Maybe<Scalars['Datetime']>;
    gender?: Maybe<ParticipantGender>;
    newsletter?: Maybe<Scalars['Boolean']>;
    files?: Maybe<Scalars['String']>;
};

export type ParticipantMaxAggregates = {
    __typename?: 'ParticipantMaxAggregates';
    /** Maximum of id across the matching connection */
    id?: Maybe<Scalars['Int']>;
    /** Maximum of invoiceCredit across the matching connection */
    invoiceCredit?: Maybe<Scalars['BigFloat']>;
};

export type ParticipantMinAggregates = {
    __typename?: 'ParticipantMinAggregates';
    /** Minimum of id across the matching connection */
    id?: Maybe<Scalars['Int']>;
    /** Minimum of invoiceCredit across the matching connection */
    invoiceCredit?: Maybe<Scalars['BigFloat']>;
};

/** Represents an update to a `Participant`. Fields that are set will be updated. */
export type ParticipantPatch = {
    id?: Maybe<Scalars['Int']>;
    title?: Maybe<Scalars['String']>;
    familyName?: Maybe<Scalars['String']>;
    givenName?: Maybe<Scalars['String']>;
    jobTitle?: Maybe<Scalars['String']>;
    street?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    postcode?: Maybe<Scalars['String']>;
    country?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    mobile?: Maybe<Scalars['String']>;
    fax?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    birthday?: Maybe<Scalars['Date']>;
    note?: Maybe<Scalars['String']>;
    invoiceFamilyName?: Maybe<Scalars['String']>;
    invoiceSalutation?: Maybe<Scalars['String']>;
    invoiceTitle?: Maybe<Scalars['String']>;
    invoiceGivenName?: Maybe<Scalars['String']>;
    invoiceStreet?: Maybe<Scalars['String']>;
    invoicePostcode?: Maybe<Scalars['String']>;
    invoiceCity?: Maybe<Scalars['String']>;
    invoiceCountry?: Maybe<Scalars['String']>;
    invoiceBankAccountNumber?: Maybe<Scalars['String']>;
    invoiceBankOwner?: Maybe<Scalars['String']>;
    invoiceBankOldCode?: Maybe<Scalars['String']>;
    invoiceBankName?: Maybe<Scalars['String']>;
    invoiceBankSwift?: Maybe<Scalars['String']>;
    invoiceBankIban?: Maybe<Scalars['String']>;
    invoiceCredit?: Maybe<Scalars['BigFloat']>;
    highlighted?: Maybe<Scalars['Boolean']>;
    createdAt?: Maybe<Scalars['Datetime']>;
    updatedAt?: Maybe<Scalars['Datetime']>;
    gender?: Maybe<ParticipantGender>;
    newsletter?: Maybe<Scalars['Boolean']>;
    files?: Maybe<Scalars['String']>;
};

export type ParticipantStddevPopulationAggregates = {
    __typename?: 'ParticipantStddevPopulationAggregates';
    /** Population standard deviation of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Population standard deviation of invoiceCredit across the matching connection */
    invoiceCredit?: Maybe<Scalars['BigFloat']>;
};

export type ParticipantStddevSampleAggregates = {
    __typename?: 'ParticipantStddevSampleAggregates';
    /** Sample standard deviation of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Sample standard deviation of invoiceCredit across the matching connection */
    invoiceCredit?: Maybe<Scalars['BigFloat']>;
};

export type ParticipantSumAggregates = {
    __typename?: 'ParticipantSumAggregates';
    /** Sum of id across the matching connection */
    id: Scalars['BigInt'];
    /** Sum of invoiceCredit across the matching connection */
    invoiceCredit: Scalars['BigFloat'];
};

export type ParticipantVariancePopulationAggregates = {
    __typename?: 'ParticipantVariancePopulationAggregates';
    /** Population variance of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Population variance of invoiceCredit across the matching connection */
    invoiceCredit?: Maybe<Scalars['BigFloat']>;
};

export type ParticipantVarianceSampleAggregates = {
    __typename?: 'ParticipantVarianceSampleAggregates';
    /** Sample variance of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
    /** Sample variance of invoiceCredit across the matching connection */
    invoiceCredit?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Participant` values. */
export type ParticipantsConnection = {
    __typename?: 'ParticipantsConnection';
    /** A list of `Participant` objects. */
    nodes: Array<Participant>;
    /** A list of edges which contains the `Participant` and cursor to aid in pagination. */
    edges: Array<ParticipantsEdge>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `Participant` you could get from the connection. */
    totalCount: Scalars['Int'];
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<ParticipantAggregates>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<ParticipantAggregates>>;
};

/** A connection to a list of `Participant` values. */
export type ParticipantsConnectionGroupedAggregatesArgs = {
    groupBy: Array<ParticipantGroupBy>;
    having?: Maybe<ParticipantHavingInput>;
};

/** A `Participant` edge in the connection. */
export type ParticipantsEdge = {
    __typename?: 'ParticipantsEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `Participant` at the end of the edge. */
    node: Participant;
};

/** Methods to use when ordering `Participant`. */
export enum ParticipantsOrderBy {
    Natural = 'NATURAL',
    IdAsc = 'ID_ASC',
    IdDesc = 'ID_DESC',
    TitleAsc = 'TITLE_ASC',
    TitleDesc = 'TITLE_DESC',
    FamilyNameAsc = 'FAMILY_NAME_ASC',
    FamilyNameDesc = 'FAMILY_NAME_DESC',
    GivenNameAsc = 'GIVEN_NAME_ASC',
    GivenNameDesc = 'GIVEN_NAME_DESC',
    JobTitleAsc = 'JOB_TITLE_ASC',
    JobTitleDesc = 'JOB_TITLE_DESC',
    StreetAsc = 'STREET_ASC',
    StreetDesc = 'STREET_DESC',
    CityAsc = 'CITY_ASC',
    CityDesc = 'CITY_DESC',
    PostcodeAsc = 'POSTCODE_ASC',
    PostcodeDesc = 'POSTCODE_DESC',
    CountryAsc = 'COUNTRY_ASC',
    CountryDesc = 'COUNTRY_DESC',
    PhoneAsc = 'PHONE_ASC',
    PhoneDesc = 'PHONE_DESC',
    MobileAsc = 'MOBILE_ASC',
    MobileDesc = 'MOBILE_DESC',
    FaxAsc = 'FAX_ASC',
    FaxDesc = 'FAX_DESC',
    EmailAsc = 'EMAIL_ASC',
    EmailDesc = 'EMAIL_DESC',
    BirthdayAsc = 'BIRTHDAY_ASC',
    BirthdayDesc = 'BIRTHDAY_DESC',
    NoteAsc = 'NOTE_ASC',
    NoteDesc = 'NOTE_DESC',
    InvoiceFamilyNameAsc = 'INVOICE_FAMILY_NAME_ASC',
    InvoiceFamilyNameDesc = 'INVOICE_FAMILY_NAME_DESC',
    InvoiceSalutationAsc = 'INVOICE_SALUTATION_ASC',
    InvoiceSalutationDesc = 'INVOICE_SALUTATION_DESC',
    InvoiceTitleAsc = 'INVOICE_TITLE_ASC',
    InvoiceTitleDesc = 'INVOICE_TITLE_DESC',
    InvoiceGivenNameAsc = 'INVOICE_GIVEN_NAME_ASC',
    InvoiceGivenNameDesc = 'INVOICE_GIVEN_NAME_DESC',
    InvoiceStreetAsc = 'INVOICE_STREET_ASC',
    InvoiceStreetDesc = 'INVOICE_STREET_DESC',
    InvoicePostcodeAsc = 'INVOICE_POSTCODE_ASC',
    InvoicePostcodeDesc = 'INVOICE_POSTCODE_DESC',
    InvoiceCityAsc = 'INVOICE_CITY_ASC',
    InvoiceCityDesc = 'INVOICE_CITY_DESC',
    InvoiceCountryAsc = 'INVOICE_COUNTRY_ASC',
    InvoiceCountryDesc = 'INVOICE_COUNTRY_DESC',
    InvoiceBankAccountNumberAsc = 'INVOICE_BANK_ACCOUNT_NUMBER_ASC',
    InvoiceBankAccountNumberDesc = 'INVOICE_BANK_ACCOUNT_NUMBER_DESC',
    InvoiceBankOwnerAsc = 'INVOICE_BANK_OWNER_ASC',
    InvoiceBankOwnerDesc = 'INVOICE_BANK_OWNER_DESC',
    InvoiceBankOldCodeAsc = 'INVOICE_BANK_OLD_CODE_ASC',
    InvoiceBankOldCodeDesc = 'INVOICE_BANK_OLD_CODE_DESC',
    InvoiceBankNameAsc = 'INVOICE_BANK_NAME_ASC',
    InvoiceBankNameDesc = 'INVOICE_BANK_NAME_DESC',
    InvoiceBankSwiftAsc = 'INVOICE_BANK_SWIFT_ASC',
    InvoiceBankSwiftDesc = 'INVOICE_BANK_SWIFT_DESC',
    InvoiceBankIbanAsc = 'INVOICE_BANK_IBAN_ASC',
    InvoiceBankIbanDesc = 'INVOICE_BANK_IBAN_DESC',
    InvoiceCreditAsc = 'INVOICE_CREDIT_ASC',
    InvoiceCreditDesc = 'INVOICE_CREDIT_DESC',
    HighlightedAsc = 'HIGHLIGHTED_ASC',
    HighlightedDesc = 'HIGHLIGHTED_DESC',
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
    UpdatedAtAsc = 'UPDATED_AT_ASC',
    UpdatedAtDesc = 'UPDATED_AT_DESC',
    GenderAsc = 'GENDER_ASC',
    GenderDesc = 'GENDER_DESC',
    NewsletterAsc = 'NEWSLETTER_ASC',
    NewsletterDesc = 'NEWSLETTER_DESC',
    FilesAsc = 'FILES_ASC',
    FilesDesc = 'FILES_DESC',
    PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
    PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
    BookingsCountAsc = 'BOOKINGS_COUNT_ASC',
    BookingsCountDesc = 'BOOKINGS_COUNT_DESC',
    BookingsSumIdAsc = 'BOOKINGS_SUM_ID_ASC',
    BookingsSumIdDesc = 'BOOKINGS_SUM_ID_DESC',
    BookingsSumCourseScheduleIdAsc = 'BOOKINGS_SUM_COURSE_SCHEDULE_ID_ASC',
    BookingsSumCourseScheduleIdDesc = 'BOOKINGS_SUM_COURSE_SCHEDULE_ID_DESC',
    BookingsSumParticipantIdAsc = 'BOOKINGS_SUM_PARTICIPANT_ID_ASC',
    BookingsSumParticipantIdDesc = 'BOOKINGS_SUM_PARTICIPANT_ID_DESC',
    BookingsSumRegistrationRegisteredAsc = 'BOOKINGS_SUM_REGISTRATION_REGISTERED_ASC',
    BookingsSumRegistrationRegisteredDesc = 'BOOKINGS_SUM_REGISTRATION_REGISTERED_DESC',
    BookingsSumRegistrationDateAsc = 'BOOKINGS_SUM_REGISTRATION_DATE_ASC',
    BookingsSumRegistrationDateDesc = 'BOOKINGS_SUM_REGISTRATION_DATE_DESC',
    BookingsSumRegistrationNoteAsc = 'BOOKINGS_SUM_REGISTRATION_NOTE_ASC',
    BookingsSumRegistrationNoteDesc = 'BOOKINGS_SUM_REGISTRATION_NOTE_DESC',
    BookingsSumPassedAsc = 'BOOKINGS_SUM_PASSED_ASC',
    BookingsSumPassedDesc = 'BOOKINGS_SUM_PASSED_DESC',
    BookingsSumMissingHoursAsc = 'BOOKINGS_SUM_MISSING_HOURS_ASC',
    BookingsSumMissingHoursDesc = 'BOOKINGS_SUM_MISSING_HOURS_DESC',
    BookingsSumPaymentReceivedAsc = 'BOOKINGS_SUM_PAYMENT_RECEIVED_ASC',
    BookingsSumPaymentReceivedDesc = 'BOOKINGS_SUM_PAYMENT_RECEIVED_DESC',
    BookingsSumPaymentDateAsc = 'BOOKINGS_SUM_PAYMENT_DATE_ASC',
    BookingsSumPaymentDateDesc = 'BOOKINGS_SUM_PAYMENT_DATE_DESC',
    BookingsSumPaymentMethodAsc = 'BOOKINGS_SUM_PAYMENT_METHOD_ASC',
    BookingsSumPaymentMethodDesc = 'BOOKINGS_SUM_PAYMENT_METHOD_DESC',
    BookingsSumInvoiceNumberAsc = 'BOOKINGS_SUM_INVOICE_NUMBER_ASC',
    BookingsSumInvoiceNumberDesc = 'BOOKINGS_SUM_INVOICE_NUMBER_DESC',
    BookingsSumWaitingPositionAsc = 'BOOKINGS_SUM_WAITING_POSITION_ASC',
    BookingsSumWaitingPositionDesc = 'BOOKINGS_SUM_WAITING_POSITION_DESC',
    BookingsSumDocumentsSendAsc = 'BOOKINGS_SUM_DOCUMENTS_SEND_ASC',
    BookingsSumDocumentsSendDesc = 'BOOKINGS_SUM_DOCUMENTS_SEND_DESC',
    BookingsSumNoteAsc = 'BOOKINGS_SUM_NOTE_ASC',
    BookingsSumNoteDesc = 'BOOKINGS_SUM_NOTE_DESC',
    BookingsSumAmountAsc = 'BOOKINGS_SUM_AMOUNT_ASC',
    BookingsSumAmountDesc = 'BOOKINGS_SUM_AMOUNT_DESC',
    BookingsSumCreatedAtAsc = 'BOOKINGS_SUM_CREATED_AT_ASC',
    BookingsSumCreatedAtDesc = 'BOOKINGS_SUM_CREATED_AT_DESC',
    BookingsSumUpdatedAtAsc = 'BOOKINGS_SUM_UPDATED_AT_ASC',
    BookingsSumUpdatedAtDesc = 'BOOKINGS_SUM_UPDATED_AT_DESC',
    BookingsSumRegistrationConfirmationAsc = 'BOOKINGS_SUM_REGISTRATION_CONFIRMATION_ASC',
    BookingsSumRegistrationConfirmationDesc = 'BOOKINGS_SUM_REGISTRATION_CONFIRMATION_DESC',
    BookingsSumInvoiceCompanyNameAsc = 'BOOKINGS_SUM_INVOICE_COMPANY_NAME_ASC',
    BookingsSumInvoiceCompanyNameDesc = 'BOOKINGS_SUM_INVOICE_COMPANY_NAME_DESC',
    BookingsSumInvoiceFamilyNameAsc = 'BOOKINGS_SUM_INVOICE_FAMILY_NAME_ASC',
    BookingsSumInvoiceFamilyNameDesc = 'BOOKINGS_SUM_INVOICE_FAMILY_NAME_DESC',
    BookingsSumInvoiceSalutationAsc = 'BOOKINGS_SUM_INVOICE_SALUTATION_ASC',
    BookingsSumInvoiceSalutationDesc = 'BOOKINGS_SUM_INVOICE_SALUTATION_DESC',
    BookingsSumInvoiceTitleAsc = 'BOOKINGS_SUM_INVOICE_TITLE_ASC',
    BookingsSumInvoiceTitleDesc = 'BOOKINGS_SUM_INVOICE_TITLE_DESC',
    BookingsSumInvoiceGivenNameAsc = 'BOOKINGS_SUM_INVOICE_GIVEN_NAME_ASC',
    BookingsSumInvoiceGivenNameDesc = 'BOOKINGS_SUM_INVOICE_GIVEN_NAME_DESC',
    BookingsSumInvoiceStreetAsc = 'BOOKINGS_SUM_INVOICE_STREET_ASC',
    BookingsSumInvoiceStreetDesc = 'BOOKINGS_SUM_INVOICE_STREET_DESC',
    BookingsSumInvoicePostcodeAsc = 'BOOKINGS_SUM_INVOICE_POSTCODE_ASC',
    BookingsSumInvoicePostcodeDesc = 'BOOKINGS_SUM_INVOICE_POSTCODE_DESC',
    BookingsSumInvoiceCityAsc = 'BOOKINGS_SUM_INVOICE_CITY_ASC',
    BookingsSumInvoiceCityDesc = 'BOOKINGS_SUM_INVOICE_CITY_DESC',
    BookingsSumInvoiceCountryAsc = 'BOOKINGS_SUM_INVOICE_COUNTRY_ASC',
    BookingsSumInvoiceCountryDesc = 'BOOKINGS_SUM_INVOICE_COUNTRY_DESC',
    BookingsSumInvoiceBankOwnerAsc = 'BOOKINGS_SUM_INVOICE_BANK_OWNER_ASC',
    BookingsSumInvoiceBankOwnerDesc = 'BOOKINGS_SUM_INVOICE_BANK_OWNER_DESC',
    BookingsSumInvoiceBankNameAsc = 'BOOKINGS_SUM_INVOICE_BANK_NAME_ASC',
    BookingsSumInvoiceBankNameDesc = 'BOOKINGS_SUM_INVOICE_BANK_NAME_DESC',
    BookingsSumInvoiceBankSwiftAsc = 'BOOKINGS_SUM_INVOICE_BANK_SWIFT_ASC',
    BookingsSumInvoiceBankSwiftDesc = 'BOOKINGS_SUM_INVOICE_BANK_SWIFT_DESC',
    BookingsSumInvoiceBankIbanAsc = 'BOOKINGS_SUM_INVOICE_BANK_IBAN_ASC',
    BookingsSumInvoiceBankIbanDesc = 'BOOKINGS_SUM_INVOICE_BANK_IBAN_DESC',
    BookingsSumInvoiceToEmployerAsc = 'BOOKINGS_SUM_INVOICE_TO_EMPLOYER_ASC',
    BookingsSumInvoiceToEmployerDesc = 'BOOKINGS_SUM_INVOICE_TO_EMPLOYER_DESC',
    BookingsSumStateAsc = 'BOOKINGS_SUM_STATE_ASC',
    BookingsSumStateDesc = 'BOOKINGS_SUM_STATE_DESC',
    BookingsSumInvoiceContactPhoneAsc = 'BOOKINGS_SUM_INVOICE_CONTACT_PHONE_ASC',
    BookingsSumInvoiceContactPhoneDesc = 'BOOKINGS_SUM_INVOICE_CONTACT_PHONE_DESC',
    BookingsSumInvoiceContactEmailAsc = 'BOOKINGS_SUM_INVOICE_CONTACT_EMAIL_ASC',
    BookingsSumInvoiceContactEmailDesc = 'BOOKINGS_SUM_INVOICE_CONTACT_EMAIL_DESC',
    BookingsSumInvoiceContactUrlAsc = 'BOOKINGS_SUM_INVOICE_CONTACT_URL_ASC',
    BookingsSumInvoiceContactUrlDesc = 'BOOKINGS_SUM_INVOICE_CONTACT_URL_DESC',
    BookingsSumAcceptAsc = 'BOOKINGS_SUM_ACCEPT_ASC',
    BookingsSumAcceptDesc = 'BOOKINGS_SUM_ACCEPT_DESC',
    BookingsSumAcceptPaymentAsc = 'BOOKINGS_SUM_ACCEPT_PAYMENT_ASC',
    BookingsSumAcceptPaymentDesc = 'BOOKINGS_SUM_ACCEPT_PAYMENT_DESC',
    BookingsSumInvoiceDateAsc = 'BOOKINGS_SUM_INVOICE_DATE_ASC',
    BookingsSumInvoiceDateDesc = 'BOOKINGS_SUM_INVOICE_DATE_DESC',
    BookingsSumCodeAsc = 'BOOKINGS_SUM_CODE_ASC',
    BookingsSumCodeDesc = 'BOOKINGS_SUM_CODE_DESC',
    BookingsSumUseForCertificationAsc = 'BOOKINGS_SUM_USE_FOR_CERTIFICATION_ASC',
    BookingsSumUseForCertificationDesc = 'BOOKINGS_SUM_USE_FOR_CERTIFICATION_DESC',
    BookingsDistinctCountIdAsc = 'BOOKINGS_DISTINCT_COUNT_ID_ASC',
    BookingsDistinctCountIdDesc = 'BOOKINGS_DISTINCT_COUNT_ID_DESC',
    BookingsDistinctCountCourseScheduleIdAsc = 'BOOKINGS_DISTINCT_COUNT_COURSE_SCHEDULE_ID_ASC',
    BookingsDistinctCountCourseScheduleIdDesc = 'BOOKINGS_DISTINCT_COUNT_COURSE_SCHEDULE_ID_DESC',
    BookingsDistinctCountParticipantIdAsc = 'BOOKINGS_DISTINCT_COUNT_PARTICIPANT_ID_ASC',
    BookingsDistinctCountParticipantIdDesc = 'BOOKINGS_DISTINCT_COUNT_PARTICIPANT_ID_DESC',
    BookingsDistinctCountRegistrationRegisteredAsc = 'BOOKINGS_DISTINCT_COUNT_REGISTRATION_REGISTERED_ASC',
    BookingsDistinctCountRegistrationRegisteredDesc = 'BOOKINGS_DISTINCT_COUNT_REGISTRATION_REGISTERED_DESC',
    BookingsDistinctCountRegistrationDateAsc = 'BOOKINGS_DISTINCT_COUNT_REGISTRATION_DATE_ASC',
    BookingsDistinctCountRegistrationDateDesc = 'BOOKINGS_DISTINCT_COUNT_REGISTRATION_DATE_DESC',
    BookingsDistinctCountRegistrationNoteAsc = 'BOOKINGS_DISTINCT_COUNT_REGISTRATION_NOTE_ASC',
    BookingsDistinctCountRegistrationNoteDesc = 'BOOKINGS_DISTINCT_COUNT_REGISTRATION_NOTE_DESC',
    BookingsDistinctCountPassedAsc = 'BOOKINGS_DISTINCT_COUNT_PASSED_ASC',
    BookingsDistinctCountPassedDesc = 'BOOKINGS_DISTINCT_COUNT_PASSED_DESC',
    BookingsDistinctCountMissingHoursAsc = 'BOOKINGS_DISTINCT_COUNT_MISSING_HOURS_ASC',
    BookingsDistinctCountMissingHoursDesc = 'BOOKINGS_DISTINCT_COUNT_MISSING_HOURS_DESC',
    BookingsDistinctCountPaymentReceivedAsc = 'BOOKINGS_DISTINCT_COUNT_PAYMENT_RECEIVED_ASC',
    BookingsDistinctCountPaymentReceivedDesc = 'BOOKINGS_DISTINCT_COUNT_PAYMENT_RECEIVED_DESC',
    BookingsDistinctCountPaymentDateAsc = 'BOOKINGS_DISTINCT_COUNT_PAYMENT_DATE_ASC',
    BookingsDistinctCountPaymentDateDesc = 'BOOKINGS_DISTINCT_COUNT_PAYMENT_DATE_DESC',
    BookingsDistinctCountPaymentMethodAsc = 'BOOKINGS_DISTINCT_COUNT_PAYMENT_METHOD_ASC',
    BookingsDistinctCountPaymentMethodDesc = 'BOOKINGS_DISTINCT_COUNT_PAYMENT_METHOD_DESC',
    BookingsDistinctCountInvoiceNumberAsc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_NUMBER_ASC',
    BookingsDistinctCountInvoiceNumberDesc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_NUMBER_DESC',
    BookingsDistinctCountWaitingPositionAsc = 'BOOKINGS_DISTINCT_COUNT_WAITING_POSITION_ASC',
    BookingsDistinctCountWaitingPositionDesc = 'BOOKINGS_DISTINCT_COUNT_WAITING_POSITION_DESC',
    BookingsDistinctCountDocumentsSendAsc = 'BOOKINGS_DISTINCT_COUNT_DOCUMENTS_SEND_ASC',
    BookingsDistinctCountDocumentsSendDesc = 'BOOKINGS_DISTINCT_COUNT_DOCUMENTS_SEND_DESC',
    BookingsDistinctCountNoteAsc = 'BOOKINGS_DISTINCT_COUNT_NOTE_ASC',
    BookingsDistinctCountNoteDesc = 'BOOKINGS_DISTINCT_COUNT_NOTE_DESC',
    BookingsDistinctCountAmountAsc = 'BOOKINGS_DISTINCT_COUNT_AMOUNT_ASC',
    BookingsDistinctCountAmountDesc = 'BOOKINGS_DISTINCT_COUNT_AMOUNT_DESC',
    BookingsDistinctCountCreatedAtAsc = 'BOOKINGS_DISTINCT_COUNT_CREATED_AT_ASC',
    BookingsDistinctCountCreatedAtDesc = 'BOOKINGS_DISTINCT_COUNT_CREATED_AT_DESC',
    BookingsDistinctCountUpdatedAtAsc = 'BOOKINGS_DISTINCT_COUNT_UPDATED_AT_ASC',
    BookingsDistinctCountUpdatedAtDesc = 'BOOKINGS_DISTINCT_COUNT_UPDATED_AT_DESC',
    BookingsDistinctCountRegistrationConfirmationAsc = 'BOOKINGS_DISTINCT_COUNT_REGISTRATION_CONFIRMATION_ASC',
    BookingsDistinctCountRegistrationConfirmationDesc = 'BOOKINGS_DISTINCT_COUNT_REGISTRATION_CONFIRMATION_DESC',
    BookingsDistinctCountInvoiceCompanyNameAsc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_COMPANY_NAME_ASC',
    BookingsDistinctCountInvoiceCompanyNameDesc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_COMPANY_NAME_DESC',
    BookingsDistinctCountInvoiceFamilyNameAsc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_FAMILY_NAME_ASC',
    BookingsDistinctCountInvoiceFamilyNameDesc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_FAMILY_NAME_DESC',
    BookingsDistinctCountInvoiceSalutationAsc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_SALUTATION_ASC',
    BookingsDistinctCountInvoiceSalutationDesc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_SALUTATION_DESC',
    BookingsDistinctCountInvoiceTitleAsc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_TITLE_ASC',
    BookingsDistinctCountInvoiceTitleDesc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_TITLE_DESC',
    BookingsDistinctCountInvoiceGivenNameAsc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_GIVEN_NAME_ASC',
    BookingsDistinctCountInvoiceGivenNameDesc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_GIVEN_NAME_DESC',
    BookingsDistinctCountInvoiceStreetAsc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_STREET_ASC',
    BookingsDistinctCountInvoiceStreetDesc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_STREET_DESC',
    BookingsDistinctCountInvoicePostcodeAsc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_POSTCODE_ASC',
    BookingsDistinctCountInvoicePostcodeDesc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_POSTCODE_DESC',
    BookingsDistinctCountInvoiceCityAsc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_CITY_ASC',
    BookingsDistinctCountInvoiceCityDesc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_CITY_DESC',
    BookingsDistinctCountInvoiceCountryAsc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_COUNTRY_ASC',
    BookingsDistinctCountInvoiceCountryDesc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_COUNTRY_DESC',
    BookingsDistinctCountInvoiceBankOwnerAsc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_BANK_OWNER_ASC',
    BookingsDistinctCountInvoiceBankOwnerDesc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_BANK_OWNER_DESC',
    BookingsDistinctCountInvoiceBankNameAsc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_BANK_NAME_ASC',
    BookingsDistinctCountInvoiceBankNameDesc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_BANK_NAME_DESC',
    BookingsDistinctCountInvoiceBankSwiftAsc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_BANK_SWIFT_ASC',
    BookingsDistinctCountInvoiceBankSwiftDesc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_BANK_SWIFT_DESC',
    BookingsDistinctCountInvoiceBankIbanAsc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_BANK_IBAN_ASC',
    BookingsDistinctCountInvoiceBankIbanDesc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_BANK_IBAN_DESC',
    BookingsDistinctCountInvoiceToEmployerAsc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_TO_EMPLOYER_ASC',
    BookingsDistinctCountInvoiceToEmployerDesc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_TO_EMPLOYER_DESC',
    BookingsDistinctCountStateAsc = 'BOOKINGS_DISTINCT_COUNT_STATE_ASC',
    BookingsDistinctCountStateDesc = 'BOOKINGS_DISTINCT_COUNT_STATE_DESC',
    BookingsDistinctCountInvoiceContactPhoneAsc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_CONTACT_PHONE_ASC',
    BookingsDistinctCountInvoiceContactPhoneDesc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_CONTACT_PHONE_DESC',
    BookingsDistinctCountInvoiceContactEmailAsc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_CONTACT_EMAIL_ASC',
    BookingsDistinctCountInvoiceContactEmailDesc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_CONTACT_EMAIL_DESC',
    BookingsDistinctCountInvoiceContactUrlAsc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_CONTACT_URL_ASC',
    BookingsDistinctCountInvoiceContactUrlDesc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_CONTACT_URL_DESC',
    BookingsDistinctCountAcceptAsc = 'BOOKINGS_DISTINCT_COUNT_ACCEPT_ASC',
    BookingsDistinctCountAcceptDesc = 'BOOKINGS_DISTINCT_COUNT_ACCEPT_DESC',
    BookingsDistinctCountAcceptPaymentAsc = 'BOOKINGS_DISTINCT_COUNT_ACCEPT_PAYMENT_ASC',
    BookingsDistinctCountAcceptPaymentDesc = 'BOOKINGS_DISTINCT_COUNT_ACCEPT_PAYMENT_DESC',
    BookingsDistinctCountInvoiceDateAsc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_DATE_ASC',
    BookingsDistinctCountInvoiceDateDesc = 'BOOKINGS_DISTINCT_COUNT_INVOICE_DATE_DESC',
    BookingsDistinctCountCodeAsc = 'BOOKINGS_DISTINCT_COUNT_CODE_ASC',
    BookingsDistinctCountCodeDesc = 'BOOKINGS_DISTINCT_COUNT_CODE_DESC',
    BookingsDistinctCountUseForCertificationAsc = 'BOOKINGS_DISTINCT_COUNT_USE_FOR_CERTIFICATION_ASC',
    BookingsDistinctCountUseForCertificationDesc = 'BOOKINGS_DISTINCT_COUNT_USE_FOR_CERTIFICATION_DESC',
    BookingsMinIdAsc = 'BOOKINGS_MIN_ID_ASC',
    BookingsMinIdDesc = 'BOOKINGS_MIN_ID_DESC',
    BookingsMinCourseScheduleIdAsc = 'BOOKINGS_MIN_COURSE_SCHEDULE_ID_ASC',
    BookingsMinCourseScheduleIdDesc = 'BOOKINGS_MIN_COURSE_SCHEDULE_ID_DESC',
    BookingsMinParticipantIdAsc = 'BOOKINGS_MIN_PARTICIPANT_ID_ASC',
    BookingsMinParticipantIdDesc = 'BOOKINGS_MIN_PARTICIPANT_ID_DESC',
    BookingsMinRegistrationRegisteredAsc = 'BOOKINGS_MIN_REGISTRATION_REGISTERED_ASC',
    BookingsMinRegistrationRegisteredDesc = 'BOOKINGS_MIN_REGISTRATION_REGISTERED_DESC',
    BookingsMinRegistrationDateAsc = 'BOOKINGS_MIN_REGISTRATION_DATE_ASC',
    BookingsMinRegistrationDateDesc = 'BOOKINGS_MIN_REGISTRATION_DATE_DESC',
    BookingsMinRegistrationNoteAsc = 'BOOKINGS_MIN_REGISTRATION_NOTE_ASC',
    BookingsMinRegistrationNoteDesc = 'BOOKINGS_MIN_REGISTRATION_NOTE_DESC',
    BookingsMinPassedAsc = 'BOOKINGS_MIN_PASSED_ASC',
    BookingsMinPassedDesc = 'BOOKINGS_MIN_PASSED_DESC',
    BookingsMinMissingHoursAsc = 'BOOKINGS_MIN_MISSING_HOURS_ASC',
    BookingsMinMissingHoursDesc = 'BOOKINGS_MIN_MISSING_HOURS_DESC',
    BookingsMinPaymentReceivedAsc = 'BOOKINGS_MIN_PAYMENT_RECEIVED_ASC',
    BookingsMinPaymentReceivedDesc = 'BOOKINGS_MIN_PAYMENT_RECEIVED_DESC',
    BookingsMinPaymentDateAsc = 'BOOKINGS_MIN_PAYMENT_DATE_ASC',
    BookingsMinPaymentDateDesc = 'BOOKINGS_MIN_PAYMENT_DATE_DESC',
    BookingsMinPaymentMethodAsc = 'BOOKINGS_MIN_PAYMENT_METHOD_ASC',
    BookingsMinPaymentMethodDesc = 'BOOKINGS_MIN_PAYMENT_METHOD_DESC',
    BookingsMinInvoiceNumberAsc = 'BOOKINGS_MIN_INVOICE_NUMBER_ASC',
    BookingsMinInvoiceNumberDesc = 'BOOKINGS_MIN_INVOICE_NUMBER_DESC',
    BookingsMinWaitingPositionAsc = 'BOOKINGS_MIN_WAITING_POSITION_ASC',
    BookingsMinWaitingPositionDesc = 'BOOKINGS_MIN_WAITING_POSITION_DESC',
    BookingsMinDocumentsSendAsc = 'BOOKINGS_MIN_DOCUMENTS_SEND_ASC',
    BookingsMinDocumentsSendDesc = 'BOOKINGS_MIN_DOCUMENTS_SEND_DESC',
    BookingsMinNoteAsc = 'BOOKINGS_MIN_NOTE_ASC',
    BookingsMinNoteDesc = 'BOOKINGS_MIN_NOTE_DESC',
    BookingsMinAmountAsc = 'BOOKINGS_MIN_AMOUNT_ASC',
    BookingsMinAmountDesc = 'BOOKINGS_MIN_AMOUNT_DESC',
    BookingsMinCreatedAtAsc = 'BOOKINGS_MIN_CREATED_AT_ASC',
    BookingsMinCreatedAtDesc = 'BOOKINGS_MIN_CREATED_AT_DESC',
    BookingsMinUpdatedAtAsc = 'BOOKINGS_MIN_UPDATED_AT_ASC',
    BookingsMinUpdatedAtDesc = 'BOOKINGS_MIN_UPDATED_AT_DESC',
    BookingsMinRegistrationConfirmationAsc = 'BOOKINGS_MIN_REGISTRATION_CONFIRMATION_ASC',
    BookingsMinRegistrationConfirmationDesc = 'BOOKINGS_MIN_REGISTRATION_CONFIRMATION_DESC',
    BookingsMinInvoiceCompanyNameAsc = 'BOOKINGS_MIN_INVOICE_COMPANY_NAME_ASC',
    BookingsMinInvoiceCompanyNameDesc = 'BOOKINGS_MIN_INVOICE_COMPANY_NAME_DESC',
    BookingsMinInvoiceFamilyNameAsc = 'BOOKINGS_MIN_INVOICE_FAMILY_NAME_ASC',
    BookingsMinInvoiceFamilyNameDesc = 'BOOKINGS_MIN_INVOICE_FAMILY_NAME_DESC',
    BookingsMinInvoiceSalutationAsc = 'BOOKINGS_MIN_INVOICE_SALUTATION_ASC',
    BookingsMinInvoiceSalutationDesc = 'BOOKINGS_MIN_INVOICE_SALUTATION_DESC',
    BookingsMinInvoiceTitleAsc = 'BOOKINGS_MIN_INVOICE_TITLE_ASC',
    BookingsMinInvoiceTitleDesc = 'BOOKINGS_MIN_INVOICE_TITLE_DESC',
    BookingsMinInvoiceGivenNameAsc = 'BOOKINGS_MIN_INVOICE_GIVEN_NAME_ASC',
    BookingsMinInvoiceGivenNameDesc = 'BOOKINGS_MIN_INVOICE_GIVEN_NAME_DESC',
    BookingsMinInvoiceStreetAsc = 'BOOKINGS_MIN_INVOICE_STREET_ASC',
    BookingsMinInvoiceStreetDesc = 'BOOKINGS_MIN_INVOICE_STREET_DESC',
    BookingsMinInvoicePostcodeAsc = 'BOOKINGS_MIN_INVOICE_POSTCODE_ASC',
    BookingsMinInvoicePostcodeDesc = 'BOOKINGS_MIN_INVOICE_POSTCODE_DESC',
    BookingsMinInvoiceCityAsc = 'BOOKINGS_MIN_INVOICE_CITY_ASC',
    BookingsMinInvoiceCityDesc = 'BOOKINGS_MIN_INVOICE_CITY_DESC',
    BookingsMinInvoiceCountryAsc = 'BOOKINGS_MIN_INVOICE_COUNTRY_ASC',
    BookingsMinInvoiceCountryDesc = 'BOOKINGS_MIN_INVOICE_COUNTRY_DESC',
    BookingsMinInvoiceBankOwnerAsc = 'BOOKINGS_MIN_INVOICE_BANK_OWNER_ASC',
    BookingsMinInvoiceBankOwnerDesc = 'BOOKINGS_MIN_INVOICE_BANK_OWNER_DESC',
    BookingsMinInvoiceBankNameAsc = 'BOOKINGS_MIN_INVOICE_BANK_NAME_ASC',
    BookingsMinInvoiceBankNameDesc = 'BOOKINGS_MIN_INVOICE_BANK_NAME_DESC',
    BookingsMinInvoiceBankSwiftAsc = 'BOOKINGS_MIN_INVOICE_BANK_SWIFT_ASC',
    BookingsMinInvoiceBankSwiftDesc = 'BOOKINGS_MIN_INVOICE_BANK_SWIFT_DESC',
    BookingsMinInvoiceBankIbanAsc = 'BOOKINGS_MIN_INVOICE_BANK_IBAN_ASC',
    BookingsMinInvoiceBankIbanDesc = 'BOOKINGS_MIN_INVOICE_BANK_IBAN_DESC',
    BookingsMinInvoiceToEmployerAsc = 'BOOKINGS_MIN_INVOICE_TO_EMPLOYER_ASC',
    BookingsMinInvoiceToEmployerDesc = 'BOOKINGS_MIN_INVOICE_TO_EMPLOYER_DESC',
    BookingsMinStateAsc = 'BOOKINGS_MIN_STATE_ASC',
    BookingsMinStateDesc = 'BOOKINGS_MIN_STATE_DESC',
    BookingsMinInvoiceContactPhoneAsc = 'BOOKINGS_MIN_INVOICE_CONTACT_PHONE_ASC',
    BookingsMinInvoiceContactPhoneDesc = 'BOOKINGS_MIN_INVOICE_CONTACT_PHONE_DESC',
    BookingsMinInvoiceContactEmailAsc = 'BOOKINGS_MIN_INVOICE_CONTACT_EMAIL_ASC',
    BookingsMinInvoiceContactEmailDesc = 'BOOKINGS_MIN_INVOICE_CONTACT_EMAIL_DESC',
    BookingsMinInvoiceContactUrlAsc = 'BOOKINGS_MIN_INVOICE_CONTACT_URL_ASC',
    BookingsMinInvoiceContactUrlDesc = 'BOOKINGS_MIN_INVOICE_CONTACT_URL_DESC',
    BookingsMinAcceptAsc = 'BOOKINGS_MIN_ACCEPT_ASC',
    BookingsMinAcceptDesc = 'BOOKINGS_MIN_ACCEPT_DESC',
    BookingsMinAcceptPaymentAsc = 'BOOKINGS_MIN_ACCEPT_PAYMENT_ASC',
    BookingsMinAcceptPaymentDesc = 'BOOKINGS_MIN_ACCEPT_PAYMENT_DESC',
    BookingsMinInvoiceDateAsc = 'BOOKINGS_MIN_INVOICE_DATE_ASC',
    BookingsMinInvoiceDateDesc = 'BOOKINGS_MIN_INVOICE_DATE_DESC',
    BookingsMinCodeAsc = 'BOOKINGS_MIN_CODE_ASC',
    BookingsMinCodeDesc = 'BOOKINGS_MIN_CODE_DESC',
    BookingsMinUseForCertificationAsc = 'BOOKINGS_MIN_USE_FOR_CERTIFICATION_ASC',
    BookingsMinUseForCertificationDesc = 'BOOKINGS_MIN_USE_FOR_CERTIFICATION_DESC',
    BookingsMaxIdAsc = 'BOOKINGS_MAX_ID_ASC',
    BookingsMaxIdDesc = 'BOOKINGS_MAX_ID_DESC',
    BookingsMaxCourseScheduleIdAsc = 'BOOKINGS_MAX_COURSE_SCHEDULE_ID_ASC',
    BookingsMaxCourseScheduleIdDesc = 'BOOKINGS_MAX_COURSE_SCHEDULE_ID_DESC',
    BookingsMaxParticipantIdAsc = 'BOOKINGS_MAX_PARTICIPANT_ID_ASC',
    BookingsMaxParticipantIdDesc = 'BOOKINGS_MAX_PARTICIPANT_ID_DESC',
    BookingsMaxRegistrationRegisteredAsc = 'BOOKINGS_MAX_REGISTRATION_REGISTERED_ASC',
    BookingsMaxRegistrationRegisteredDesc = 'BOOKINGS_MAX_REGISTRATION_REGISTERED_DESC',
    BookingsMaxRegistrationDateAsc = 'BOOKINGS_MAX_REGISTRATION_DATE_ASC',
    BookingsMaxRegistrationDateDesc = 'BOOKINGS_MAX_REGISTRATION_DATE_DESC',
    BookingsMaxRegistrationNoteAsc = 'BOOKINGS_MAX_REGISTRATION_NOTE_ASC',
    BookingsMaxRegistrationNoteDesc = 'BOOKINGS_MAX_REGISTRATION_NOTE_DESC',
    BookingsMaxPassedAsc = 'BOOKINGS_MAX_PASSED_ASC',
    BookingsMaxPassedDesc = 'BOOKINGS_MAX_PASSED_DESC',
    BookingsMaxMissingHoursAsc = 'BOOKINGS_MAX_MISSING_HOURS_ASC',
    BookingsMaxMissingHoursDesc = 'BOOKINGS_MAX_MISSING_HOURS_DESC',
    BookingsMaxPaymentReceivedAsc = 'BOOKINGS_MAX_PAYMENT_RECEIVED_ASC',
    BookingsMaxPaymentReceivedDesc = 'BOOKINGS_MAX_PAYMENT_RECEIVED_DESC',
    BookingsMaxPaymentDateAsc = 'BOOKINGS_MAX_PAYMENT_DATE_ASC',
    BookingsMaxPaymentDateDesc = 'BOOKINGS_MAX_PAYMENT_DATE_DESC',
    BookingsMaxPaymentMethodAsc = 'BOOKINGS_MAX_PAYMENT_METHOD_ASC',
    BookingsMaxPaymentMethodDesc = 'BOOKINGS_MAX_PAYMENT_METHOD_DESC',
    BookingsMaxInvoiceNumberAsc = 'BOOKINGS_MAX_INVOICE_NUMBER_ASC',
    BookingsMaxInvoiceNumberDesc = 'BOOKINGS_MAX_INVOICE_NUMBER_DESC',
    BookingsMaxWaitingPositionAsc = 'BOOKINGS_MAX_WAITING_POSITION_ASC',
    BookingsMaxWaitingPositionDesc = 'BOOKINGS_MAX_WAITING_POSITION_DESC',
    BookingsMaxDocumentsSendAsc = 'BOOKINGS_MAX_DOCUMENTS_SEND_ASC',
    BookingsMaxDocumentsSendDesc = 'BOOKINGS_MAX_DOCUMENTS_SEND_DESC',
    BookingsMaxNoteAsc = 'BOOKINGS_MAX_NOTE_ASC',
    BookingsMaxNoteDesc = 'BOOKINGS_MAX_NOTE_DESC',
    BookingsMaxAmountAsc = 'BOOKINGS_MAX_AMOUNT_ASC',
    BookingsMaxAmountDesc = 'BOOKINGS_MAX_AMOUNT_DESC',
    BookingsMaxCreatedAtAsc = 'BOOKINGS_MAX_CREATED_AT_ASC',
    BookingsMaxCreatedAtDesc = 'BOOKINGS_MAX_CREATED_AT_DESC',
    BookingsMaxUpdatedAtAsc = 'BOOKINGS_MAX_UPDATED_AT_ASC',
    BookingsMaxUpdatedAtDesc = 'BOOKINGS_MAX_UPDATED_AT_DESC',
    BookingsMaxRegistrationConfirmationAsc = 'BOOKINGS_MAX_REGISTRATION_CONFIRMATION_ASC',
    BookingsMaxRegistrationConfirmationDesc = 'BOOKINGS_MAX_REGISTRATION_CONFIRMATION_DESC',
    BookingsMaxInvoiceCompanyNameAsc = 'BOOKINGS_MAX_INVOICE_COMPANY_NAME_ASC',
    BookingsMaxInvoiceCompanyNameDesc = 'BOOKINGS_MAX_INVOICE_COMPANY_NAME_DESC',
    BookingsMaxInvoiceFamilyNameAsc = 'BOOKINGS_MAX_INVOICE_FAMILY_NAME_ASC',
    BookingsMaxInvoiceFamilyNameDesc = 'BOOKINGS_MAX_INVOICE_FAMILY_NAME_DESC',
    BookingsMaxInvoiceSalutationAsc = 'BOOKINGS_MAX_INVOICE_SALUTATION_ASC',
    BookingsMaxInvoiceSalutationDesc = 'BOOKINGS_MAX_INVOICE_SALUTATION_DESC',
    BookingsMaxInvoiceTitleAsc = 'BOOKINGS_MAX_INVOICE_TITLE_ASC',
    BookingsMaxInvoiceTitleDesc = 'BOOKINGS_MAX_INVOICE_TITLE_DESC',
    BookingsMaxInvoiceGivenNameAsc = 'BOOKINGS_MAX_INVOICE_GIVEN_NAME_ASC',
    BookingsMaxInvoiceGivenNameDesc = 'BOOKINGS_MAX_INVOICE_GIVEN_NAME_DESC',
    BookingsMaxInvoiceStreetAsc = 'BOOKINGS_MAX_INVOICE_STREET_ASC',
    BookingsMaxInvoiceStreetDesc = 'BOOKINGS_MAX_INVOICE_STREET_DESC',
    BookingsMaxInvoicePostcodeAsc = 'BOOKINGS_MAX_INVOICE_POSTCODE_ASC',
    BookingsMaxInvoicePostcodeDesc = 'BOOKINGS_MAX_INVOICE_POSTCODE_DESC',
    BookingsMaxInvoiceCityAsc = 'BOOKINGS_MAX_INVOICE_CITY_ASC',
    BookingsMaxInvoiceCityDesc = 'BOOKINGS_MAX_INVOICE_CITY_DESC',
    BookingsMaxInvoiceCountryAsc = 'BOOKINGS_MAX_INVOICE_COUNTRY_ASC',
    BookingsMaxInvoiceCountryDesc = 'BOOKINGS_MAX_INVOICE_COUNTRY_DESC',
    BookingsMaxInvoiceBankOwnerAsc = 'BOOKINGS_MAX_INVOICE_BANK_OWNER_ASC',
    BookingsMaxInvoiceBankOwnerDesc = 'BOOKINGS_MAX_INVOICE_BANK_OWNER_DESC',
    BookingsMaxInvoiceBankNameAsc = 'BOOKINGS_MAX_INVOICE_BANK_NAME_ASC',
    BookingsMaxInvoiceBankNameDesc = 'BOOKINGS_MAX_INVOICE_BANK_NAME_DESC',
    BookingsMaxInvoiceBankSwiftAsc = 'BOOKINGS_MAX_INVOICE_BANK_SWIFT_ASC',
    BookingsMaxInvoiceBankSwiftDesc = 'BOOKINGS_MAX_INVOICE_BANK_SWIFT_DESC',
    BookingsMaxInvoiceBankIbanAsc = 'BOOKINGS_MAX_INVOICE_BANK_IBAN_ASC',
    BookingsMaxInvoiceBankIbanDesc = 'BOOKINGS_MAX_INVOICE_BANK_IBAN_DESC',
    BookingsMaxInvoiceToEmployerAsc = 'BOOKINGS_MAX_INVOICE_TO_EMPLOYER_ASC',
    BookingsMaxInvoiceToEmployerDesc = 'BOOKINGS_MAX_INVOICE_TO_EMPLOYER_DESC',
    BookingsMaxStateAsc = 'BOOKINGS_MAX_STATE_ASC',
    BookingsMaxStateDesc = 'BOOKINGS_MAX_STATE_DESC',
    BookingsMaxInvoiceContactPhoneAsc = 'BOOKINGS_MAX_INVOICE_CONTACT_PHONE_ASC',
    BookingsMaxInvoiceContactPhoneDesc = 'BOOKINGS_MAX_INVOICE_CONTACT_PHONE_DESC',
    BookingsMaxInvoiceContactEmailAsc = 'BOOKINGS_MAX_INVOICE_CONTACT_EMAIL_ASC',
    BookingsMaxInvoiceContactEmailDesc = 'BOOKINGS_MAX_INVOICE_CONTACT_EMAIL_DESC',
    BookingsMaxInvoiceContactUrlAsc = 'BOOKINGS_MAX_INVOICE_CONTACT_URL_ASC',
    BookingsMaxInvoiceContactUrlDesc = 'BOOKINGS_MAX_INVOICE_CONTACT_URL_DESC',
    BookingsMaxAcceptAsc = 'BOOKINGS_MAX_ACCEPT_ASC',
    BookingsMaxAcceptDesc = 'BOOKINGS_MAX_ACCEPT_DESC',
    BookingsMaxAcceptPaymentAsc = 'BOOKINGS_MAX_ACCEPT_PAYMENT_ASC',
    BookingsMaxAcceptPaymentDesc = 'BOOKINGS_MAX_ACCEPT_PAYMENT_DESC',
    BookingsMaxInvoiceDateAsc = 'BOOKINGS_MAX_INVOICE_DATE_ASC',
    BookingsMaxInvoiceDateDesc = 'BOOKINGS_MAX_INVOICE_DATE_DESC',
    BookingsMaxCodeAsc = 'BOOKINGS_MAX_CODE_ASC',
    BookingsMaxCodeDesc = 'BOOKINGS_MAX_CODE_DESC',
    BookingsMaxUseForCertificationAsc = 'BOOKINGS_MAX_USE_FOR_CERTIFICATION_ASC',
    BookingsMaxUseForCertificationDesc = 'BOOKINGS_MAX_USE_FOR_CERTIFICATION_DESC',
    BookingsAverageIdAsc = 'BOOKINGS_AVERAGE_ID_ASC',
    BookingsAverageIdDesc = 'BOOKINGS_AVERAGE_ID_DESC',
    BookingsAverageCourseScheduleIdAsc = 'BOOKINGS_AVERAGE_COURSE_SCHEDULE_ID_ASC',
    BookingsAverageCourseScheduleIdDesc = 'BOOKINGS_AVERAGE_COURSE_SCHEDULE_ID_DESC',
    BookingsAverageParticipantIdAsc = 'BOOKINGS_AVERAGE_PARTICIPANT_ID_ASC',
    BookingsAverageParticipantIdDesc = 'BOOKINGS_AVERAGE_PARTICIPANT_ID_DESC',
    BookingsAverageRegistrationRegisteredAsc = 'BOOKINGS_AVERAGE_REGISTRATION_REGISTERED_ASC',
    BookingsAverageRegistrationRegisteredDesc = 'BOOKINGS_AVERAGE_REGISTRATION_REGISTERED_DESC',
    BookingsAverageRegistrationDateAsc = 'BOOKINGS_AVERAGE_REGISTRATION_DATE_ASC',
    BookingsAverageRegistrationDateDesc = 'BOOKINGS_AVERAGE_REGISTRATION_DATE_DESC',
    BookingsAverageRegistrationNoteAsc = 'BOOKINGS_AVERAGE_REGISTRATION_NOTE_ASC',
    BookingsAverageRegistrationNoteDesc = 'BOOKINGS_AVERAGE_REGISTRATION_NOTE_DESC',
    BookingsAveragePassedAsc = 'BOOKINGS_AVERAGE_PASSED_ASC',
    BookingsAveragePassedDesc = 'BOOKINGS_AVERAGE_PASSED_DESC',
    BookingsAverageMissingHoursAsc = 'BOOKINGS_AVERAGE_MISSING_HOURS_ASC',
    BookingsAverageMissingHoursDesc = 'BOOKINGS_AVERAGE_MISSING_HOURS_DESC',
    BookingsAveragePaymentReceivedAsc = 'BOOKINGS_AVERAGE_PAYMENT_RECEIVED_ASC',
    BookingsAveragePaymentReceivedDesc = 'BOOKINGS_AVERAGE_PAYMENT_RECEIVED_DESC',
    BookingsAveragePaymentDateAsc = 'BOOKINGS_AVERAGE_PAYMENT_DATE_ASC',
    BookingsAveragePaymentDateDesc = 'BOOKINGS_AVERAGE_PAYMENT_DATE_DESC',
    BookingsAveragePaymentMethodAsc = 'BOOKINGS_AVERAGE_PAYMENT_METHOD_ASC',
    BookingsAveragePaymentMethodDesc = 'BOOKINGS_AVERAGE_PAYMENT_METHOD_DESC',
    BookingsAverageInvoiceNumberAsc = 'BOOKINGS_AVERAGE_INVOICE_NUMBER_ASC',
    BookingsAverageInvoiceNumberDesc = 'BOOKINGS_AVERAGE_INVOICE_NUMBER_DESC',
    BookingsAverageWaitingPositionAsc = 'BOOKINGS_AVERAGE_WAITING_POSITION_ASC',
    BookingsAverageWaitingPositionDesc = 'BOOKINGS_AVERAGE_WAITING_POSITION_DESC',
    BookingsAverageDocumentsSendAsc = 'BOOKINGS_AVERAGE_DOCUMENTS_SEND_ASC',
    BookingsAverageDocumentsSendDesc = 'BOOKINGS_AVERAGE_DOCUMENTS_SEND_DESC',
    BookingsAverageNoteAsc = 'BOOKINGS_AVERAGE_NOTE_ASC',
    BookingsAverageNoteDesc = 'BOOKINGS_AVERAGE_NOTE_DESC',
    BookingsAverageAmountAsc = 'BOOKINGS_AVERAGE_AMOUNT_ASC',
    BookingsAverageAmountDesc = 'BOOKINGS_AVERAGE_AMOUNT_DESC',
    BookingsAverageCreatedAtAsc = 'BOOKINGS_AVERAGE_CREATED_AT_ASC',
    BookingsAverageCreatedAtDesc = 'BOOKINGS_AVERAGE_CREATED_AT_DESC',
    BookingsAverageUpdatedAtAsc = 'BOOKINGS_AVERAGE_UPDATED_AT_ASC',
    BookingsAverageUpdatedAtDesc = 'BOOKINGS_AVERAGE_UPDATED_AT_DESC',
    BookingsAverageRegistrationConfirmationAsc = 'BOOKINGS_AVERAGE_REGISTRATION_CONFIRMATION_ASC',
    BookingsAverageRegistrationConfirmationDesc = 'BOOKINGS_AVERAGE_REGISTRATION_CONFIRMATION_DESC',
    BookingsAverageInvoiceCompanyNameAsc = 'BOOKINGS_AVERAGE_INVOICE_COMPANY_NAME_ASC',
    BookingsAverageInvoiceCompanyNameDesc = 'BOOKINGS_AVERAGE_INVOICE_COMPANY_NAME_DESC',
    BookingsAverageInvoiceFamilyNameAsc = 'BOOKINGS_AVERAGE_INVOICE_FAMILY_NAME_ASC',
    BookingsAverageInvoiceFamilyNameDesc = 'BOOKINGS_AVERAGE_INVOICE_FAMILY_NAME_DESC',
    BookingsAverageInvoiceSalutationAsc = 'BOOKINGS_AVERAGE_INVOICE_SALUTATION_ASC',
    BookingsAverageInvoiceSalutationDesc = 'BOOKINGS_AVERAGE_INVOICE_SALUTATION_DESC',
    BookingsAverageInvoiceTitleAsc = 'BOOKINGS_AVERAGE_INVOICE_TITLE_ASC',
    BookingsAverageInvoiceTitleDesc = 'BOOKINGS_AVERAGE_INVOICE_TITLE_DESC',
    BookingsAverageInvoiceGivenNameAsc = 'BOOKINGS_AVERAGE_INVOICE_GIVEN_NAME_ASC',
    BookingsAverageInvoiceGivenNameDesc = 'BOOKINGS_AVERAGE_INVOICE_GIVEN_NAME_DESC',
    BookingsAverageInvoiceStreetAsc = 'BOOKINGS_AVERAGE_INVOICE_STREET_ASC',
    BookingsAverageInvoiceStreetDesc = 'BOOKINGS_AVERAGE_INVOICE_STREET_DESC',
    BookingsAverageInvoicePostcodeAsc = 'BOOKINGS_AVERAGE_INVOICE_POSTCODE_ASC',
    BookingsAverageInvoicePostcodeDesc = 'BOOKINGS_AVERAGE_INVOICE_POSTCODE_DESC',
    BookingsAverageInvoiceCityAsc = 'BOOKINGS_AVERAGE_INVOICE_CITY_ASC',
    BookingsAverageInvoiceCityDesc = 'BOOKINGS_AVERAGE_INVOICE_CITY_DESC',
    BookingsAverageInvoiceCountryAsc = 'BOOKINGS_AVERAGE_INVOICE_COUNTRY_ASC',
    BookingsAverageInvoiceCountryDesc = 'BOOKINGS_AVERAGE_INVOICE_COUNTRY_DESC',
    BookingsAverageInvoiceBankOwnerAsc = 'BOOKINGS_AVERAGE_INVOICE_BANK_OWNER_ASC',
    BookingsAverageInvoiceBankOwnerDesc = 'BOOKINGS_AVERAGE_INVOICE_BANK_OWNER_DESC',
    BookingsAverageInvoiceBankNameAsc = 'BOOKINGS_AVERAGE_INVOICE_BANK_NAME_ASC',
    BookingsAverageInvoiceBankNameDesc = 'BOOKINGS_AVERAGE_INVOICE_BANK_NAME_DESC',
    BookingsAverageInvoiceBankSwiftAsc = 'BOOKINGS_AVERAGE_INVOICE_BANK_SWIFT_ASC',
    BookingsAverageInvoiceBankSwiftDesc = 'BOOKINGS_AVERAGE_INVOICE_BANK_SWIFT_DESC',
    BookingsAverageInvoiceBankIbanAsc = 'BOOKINGS_AVERAGE_INVOICE_BANK_IBAN_ASC',
    BookingsAverageInvoiceBankIbanDesc = 'BOOKINGS_AVERAGE_INVOICE_BANK_IBAN_DESC',
    BookingsAverageInvoiceToEmployerAsc = 'BOOKINGS_AVERAGE_INVOICE_TO_EMPLOYER_ASC',
    BookingsAverageInvoiceToEmployerDesc = 'BOOKINGS_AVERAGE_INVOICE_TO_EMPLOYER_DESC',
    BookingsAverageStateAsc = 'BOOKINGS_AVERAGE_STATE_ASC',
    BookingsAverageStateDesc = 'BOOKINGS_AVERAGE_STATE_DESC',
    BookingsAverageInvoiceContactPhoneAsc = 'BOOKINGS_AVERAGE_INVOICE_CONTACT_PHONE_ASC',
    BookingsAverageInvoiceContactPhoneDesc = 'BOOKINGS_AVERAGE_INVOICE_CONTACT_PHONE_DESC',
    BookingsAverageInvoiceContactEmailAsc = 'BOOKINGS_AVERAGE_INVOICE_CONTACT_EMAIL_ASC',
    BookingsAverageInvoiceContactEmailDesc = 'BOOKINGS_AVERAGE_INVOICE_CONTACT_EMAIL_DESC',
    BookingsAverageInvoiceContactUrlAsc = 'BOOKINGS_AVERAGE_INVOICE_CONTACT_URL_ASC',
    BookingsAverageInvoiceContactUrlDesc = 'BOOKINGS_AVERAGE_INVOICE_CONTACT_URL_DESC',
    BookingsAverageAcceptAsc = 'BOOKINGS_AVERAGE_ACCEPT_ASC',
    BookingsAverageAcceptDesc = 'BOOKINGS_AVERAGE_ACCEPT_DESC',
    BookingsAverageAcceptPaymentAsc = 'BOOKINGS_AVERAGE_ACCEPT_PAYMENT_ASC',
    BookingsAverageAcceptPaymentDesc = 'BOOKINGS_AVERAGE_ACCEPT_PAYMENT_DESC',
    BookingsAverageInvoiceDateAsc = 'BOOKINGS_AVERAGE_INVOICE_DATE_ASC',
    BookingsAverageInvoiceDateDesc = 'BOOKINGS_AVERAGE_INVOICE_DATE_DESC',
    BookingsAverageCodeAsc = 'BOOKINGS_AVERAGE_CODE_ASC',
    BookingsAverageCodeDesc = 'BOOKINGS_AVERAGE_CODE_DESC',
    BookingsAverageUseForCertificationAsc = 'BOOKINGS_AVERAGE_USE_FOR_CERTIFICATION_ASC',
    BookingsAverageUseForCertificationDesc = 'BOOKINGS_AVERAGE_USE_FOR_CERTIFICATION_DESC',
    BookingsStddevSampleIdAsc = 'BOOKINGS_STDDEV_SAMPLE_ID_ASC',
    BookingsStddevSampleIdDesc = 'BOOKINGS_STDDEV_SAMPLE_ID_DESC',
    BookingsStddevSampleCourseScheduleIdAsc = 'BOOKINGS_STDDEV_SAMPLE_COURSE_SCHEDULE_ID_ASC',
    BookingsStddevSampleCourseScheduleIdDesc = 'BOOKINGS_STDDEV_SAMPLE_COURSE_SCHEDULE_ID_DESC',
    BookingsStddevSampleParticipantIdAsc = 'BOOKINGS_STDDEV_SAMPLE_PARTICIPANT_ID_ASC',
    BookingsStddevSampleParticipantIdDesc = 'BOOKINGS_STDDEV_SAMPLE_PARTICIPANT_ID_DESC',
    BookingsStddevSampleRegistrationRegisteredAsc = 'BOOKINGS_STDDEV_SAMPLE_REGISTRATION_REGISTERED_ASC',
    BookingsStddevSampleRegistrationRegisteredDesc = 'BOOKINGS_STDDEV_SAMPLE_REGISTRATION_REGISTERED_DESC',
    BookingsStddevSampleRegistrationDateAsc = 'BOOKINGS_STDDEV_SAMPLE_REGISTRATION_DATE_ASC',
    BookingsStddevSampleRegistrationDateDesc = 'BOOKINGS_STDDEV_SAMPLE_REGISTRATION_DATE_DESC',
    BookingsStddevSampleRegistrationNoteAsc = 'BOOKINGS_STDDEV_SAMPLE_REGISTRATION_NOTE_ASC',
    BookingsStddevSampleRegistrationNoteDesc = 'BOOKINGS_STDDEV_SAMPLE_REGISTRATION_NOTE_DESC',
    BookingsStddevSamplePassedAsc = 'BOOKINGS_STDDEV_SAMPLE_PASSED_ASC',
    BookingsStddevSamplePassedDesc = 'BOOKINGS_STDDEV_SAMPLE_PASSED_DESC',
    BookingsStddevSampleMissingHoursAsc = 'BOOKINGS_STDDEV_SAMPLE_MISSING_HOURS_ASC',
    BookingsStddevSampleMissingHoursDesc = 'BOOKINGS_STDDEV_SAMPLE_MISSING_HOURS_DESC',
    BookingsStddevSamplePaymentReceivedAsc = 'BOOKINGS_STDDEV_SAMPLE_PAYMENT_RECEIVED_ASC',
    BookingsStddevSamplePaymentReceivedDesc = 'BOOKINGS_STDDEV_SAMPLE_PAYMENT_RECEIVED_DESC',
    BookingsStddevSamplePaymentDateAsc = 'BOOKINGS_STDDEV_SAMPLE_PAYMENT_DATE_ASC',
    BookingsStddevSamplePaymentDateDesc = 'BOOKINGS_STDDEV_SAMPLE_PAYMENT_DATE_DESC',
    BookingsStddevSamplePaymentMethodAsc = 'BOOKINGS_STDDEV_SAMPLE_PAYMENT_METHOD_ASC',
    BookingsStddevSamplePaymentMethodDesc = 'BOOKINGS_STDDEV_SAMPLE_PAYMENT_METHOD_DESC',
    BookingsStddevSampleInvoiceNumberAsc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_NUMBER_ASC',
    BookingsStddevSampleInvoiceNumberDesc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_NUMBER_DESC',
    BookingsStddevSampleWaitingPositionAsc = 'BOOKINGS_STDDEV_SAMPLE_WAITING_POSITION_ASC',
    BookingsStddevSampleWaitingPositionDesc = 'BOOKINGS_STDDEV_SAMPLE_WAITING_POSITION_DESC',
    BookingsStddevSampleDocumentsSendAsc = 'BOOKINGS_STDDEV_SAMPLE_DOCUMENTS_SEND_ASC',
    BookingsStddevSampleDocumentsSendDesc = 'BOOKINGS_STDDEV_SAMPLE_DOCUMENTS_SEND_DESC',
    BookingsStddevSampleNoteAsc = 'BOOKINGS_STDDEV_SAMPLE_NOTE_ASC',
    BookingsStddevSampleNoteDesc = 'BOOKINGS_STDDEV_SAMPLE_NOTE_DESC',
    BookingsStddevSampleAmountAsc = 'BOOKINGS_STDDEV_SAMPLE_AMOUNT_ASC',
    BookingsStddevSampleAmountDesc = 'BOOKINGS_STDDEV_SAMPLE_AMOUNT_DESC',
    BookingsStddevSampleCreatedAtAsc = 'BOOKINGS_STDDEV_SAMPLE_CREATED_AT_ASC',
    BookingsStddevSampleCreatedAtDesc = 'BOOKINGS_STDDEV_SAMPLE_CREATED_AT_DESC',
    BookingsStddevSampleUpdatedAtAsc = 'BOOKINGS_STDDEV_SAMPLE_UPDATED_AT_ASC',
    BookingsStddevSampleUpdatedAtDesc = 'BOOKINGS_STDDEV_SAMPLE_UPDATED_AT_DESC',
    BookingsStddevSampleRegistrationConfirmationAsc = 'BOOKINGS_STDDEV_SAMPLE_REGISTRATION_CONFIRMATION_ASC',
    BookingsStddevSampleRegistrationConfirmationDesc = 'BOOKINGS_STDDEV_SAMPLE_REGISTRATION_CONFIRMATION_DESC',
    BookingsStddevSampleInvoiceCompanyNameAsc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_COMPANY_NAME_ASC',
    BookingsStddevSampleInvoiceCompanyNameDesc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_COMPANY_NAME_DESC',
    BookingsStddevSampleInvoiceFamilyNameAsc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_FAMILY_NAME_ASC',
    BookingsStddevSampleInvoiceFamilyNameDesc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_FAMILY_NAME_DESC',
    BookingsStddevSampleInvoiceSalutationAsc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_SALUTATION_ASC',
    BookingsStddevSampleInvoiceSalutationDesc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_SALUTATION_DESC',
    BookingsStddevSampleInvoiceTitleAsc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_TITLE_ASC',
    BookingsStddevSampleInvoiceTitleDesc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_TITLE_DESC',
    BookingsStddevSampleInvoiceGivenNameAsc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_GIVEN_NAME_ASC',
    BookingsStddevSampleInvoiceGivenNameDesc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_GIVEN_NAME_DESC',
    BookingsStddevSampleInvoiceStreetAsc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_STREET_ASC',
    BookingsStddevSampleInvoiceStreetDesc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_STREET_DESC',
    BookingsStddevSampleInvoicePostcodeAsc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_POSTCODE_ASC',
    BookingsStddevSampleInvoicePostcodeDesc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_POSTCODE_DESC',
    BookingsStddevSampleInvoiceCityAsc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_CITY_ASC',
    BookingsStddevSampleInvoiceCityDesc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_CITY_DESC',
    BookingsStddevSampleInvoiceCountryAsc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_COUNTRY_ASC',
    BookingsStddevSampleInvoiceCountryDesc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_COUNTRY_DESC',
    BookingsStddevSampleInvoiceBankOwnerAsc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_BANK_OWNER_ASC',
    BookingsStddevSampleInvoiceBankOwnerDesc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_BANK_OWNER_DESC',
    BookingsStddevSampleInvoiceBankNameAsc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_BANK_NAME_ASC',
    BookingsStddevSampleInvoiceBankNameDesc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_BANK_NAME_DESC',
    BookingsStddevSampleInvoiceBankSwiftAsc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_BANK_SWIFT_ASC',
    BookingsStddevSampleInvoiceBankSwiftDesc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_BANK_SWIFT_DESC',
    BookingsStddevSampleInvoiceBankIbanAsc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_BANK_IBAN_ASC',
    BookingsStddevSampleInvoiceBankIbanDesc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_BANK_IBAN_DESC',
    BookingsStddevSampleInvoiceToEmployerAsc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_TO_EMPLOYER_ASC',
    BookingsStddevSampleInvoiceToEmployerDesc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_TO_EMPLOYER_DESC',
    BookingsStddevSampleStateAsc = 'BOOKINGS_STDDEV_SAMPLE_STATE_ASC',
    BookingsStddevSampleStateDesc = 'BOOKINGS_STDDEV_SAMPLE_STATE_DESC',
    BookingsStddevSampleInvoiceContactPhoneAsc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_CONTACT_PHONE_ASC',
    BookingsStddevSampleInvoiceContactPhoneDesc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_CONTACT_PHONE_DESC',
    BookingsStddevSampleInvoiceContactEmailAsc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_CONTACT_EMAIL_ASC',
    BookingsStddevSampleInvoiceContactEmailDesc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_CONTACT_EMAIL_DESC',
    BookingsStddevSampleInvoiceContactUrlAsc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_CONTACT_URL_ASC',
    BookingsStddevSampleInvoiceContactUrlDesc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_CONTACT_URL_DESC',
    BookingsStddevSampleAcceptAsc = 'BOOKINGS_STDDEV_SAMPLE_ACCEPT_ASC',
    BookingsStddevSampleAcceptDesc = 'BOOKINGS_STDDEV_SAMPLE_ACCEPT_DESC',
    BookingsStddevSampleAcceptPaymentAsc = 'BOOKINGS_STDDEV_SAMPLE_ACCEPT_PAYMENT_ASC',
    BookingsStddevSampleAcceptPaymentDesc = 'BOOKINGS_STDDEV_SAMPLE_ACCEPT_PAYMENT_DESC',
    BookingsStddevSampleInvoiceDateAsc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_DATE_ASC',
    BookingsStddevSampleInvoiceDateDesc = 'BOOKINGS_STDDEV_SAMPLE_INVOICE_DATE_DESC',
    BookingsStddevSampleCodeAsc = 'BOOKINGS_STDDEV_SAMPLE_CODE_ASC',
    BookingsStddevSampleCodeDesc = 'BOOKINGS_STDDEV_SAMPLE_CODE_DESC',
    BookingsStddevSampleUseForCertificationAsc = 'BOOKINGS_STDDEV_SAMPLE_USE_FOR_CERTIFICATION_ASC',
    BookingsStddevSampleUseForCertificationDesc = 'BOOKINGS_STDDEV_SAMPLE_USE_FOR_CERTIFICATION_DESC',
    BookingsStddevPopulationIdAsc = 'BOOKINGS_STDDEV_POPULATION_ID_ASC',
    BookingsStddevPopulationIdDesc = 'BOOKINGS_STDDEV_POPULATION_ID_DESC',
    BookingsStddevPopulationCourseScheduleIdAsc = 'BOOKINGS_STDDEV_POPULATION_COURSE_SCHEDULE_ID_ASC',
    BookingsStddevPopulationCourseScheduleIdDesc = 'BOOKINGS_STDDEV_POPULATION_COURSE_SCHEDULE_ID_DESC',
    BookingsStddevPopulationParticipantIdAsc = 'BOOKINGS_STDDEV_POPULATION_PARTICIPANT_ID_ASC',
    BookingsStddevPopulationParticipantIdDesc = 'BOOKINGS_STDDEV_POPULATION_PARTICIPANT_ID_DESC',
    BookingsStddevPopulationRegistrationRegisteredAsc = 'BOOKINGS_STDDEV_POPULATION_REGISTRATION_REGISTERED_ASC',
    BookingsStddevPopulationRegistrationRegisteredDesc = 'BOOKINGS_STDDEV_POPULATION_REGISTRATION_REGISTERED_DESC',
    BookingsStddevPopulationRegistrationDateAsc = 'BOOKINGS_STDDEV_POPULATION_REGISTRATION_DATE_ASC',
    BookingsStddevPopulationRegistrationDateDesc = 'BOOKINGS_STDDEV_POPULATION_REGISTRATION_DATE_DESC',
    BookingsStddevPopulationRegistrationNoteAsc = 'BOOKINGS_STDDEV_POPULATION_REGISTRATION_NOTE_ASC',
    BookingsStddevPopulationRegistrationNoteDesc = 'BOOKINGS_STDDEV_POPULATION_REGISTRATION_NOTE_DESC',
    BookingsStddevPopulationPassedAsc = 'BOOKINGS_STDDEV_POPULATION_PASSED_ASC',
    BookingsStddevPopulationPassedDesc = 'BOOKINGS_STDDEV_POPULATION_PASSED_DESC',
    BookingsStddevPopulationMissingHoursAsc = 'BOOKINGS_STDDEV_POPULATION_MISSING_HOURS_ASC',
    BookingsStddevPopulationMissingHoursDesc = 'BOOKINGS_STDDEV_POPULATION_MISSING_HOURS_DESC',
    BookingsStddevPopulationPaymentReceivedAsc = 'BOOKINGS_STDDEV_POPULATION_PAYMENT_RECEIVED_ASC',
    BookingsStddevPopulationPaymentReceivedDesc = 'BOOKINGS_STDDEV_POPULATION_PAYMENT_RECEIVED_DESC',
    BookingsStddevPopulationPaymentDateAsc = 'BOOKINGS_STDDEV_POPULATION_PAYMENT_DATE_ASC',
    BookingsStddevPopulationPaymentDateDesc = 'BOOKINGS_STDDEV_POPULATION_PAYMENT_DATE_DESC',
    BookingsStddevPopulationPaymentMethodAsc = 'BOOKINGS_STDDEV_POPULATION_PAYMENT_METHOD_ASC',
    BookingsStddevPopulationPaymentMethodDesc = 'BOOKINGS_STDDEV_POPULATION_PAYMENT_METHOD_DESC',
    BookingsStddevPopulationInvoiceNumberAsc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_NUMBER_ASC',
    BookingsStddevPopulationInvoiceNumberDesc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_NUMBER_DESC',
    BookingsStddevPopulationWaitingPositionAsc = 'BOOKINGS_STDDEV_POPULATION_WAITING_POSITION_ASC',
    BookingsStddevPopulationWaitingPositionDesc = 'BOOKINGS_STDDEV_POPULATION_WAITING_POSITION_DESC',
    BookingsStddevPopulationDocumentsSendAsc = 'BOOKINGS_STDDEV_POPULATION_DOCUMENTS_SEND_ASC',
    BookingsStddevPopulationDocumentsSendDesc = 'BOOKINGS_STDDEV_POPULATION_DOCUMENTS_SEND_DESC',
    BookingsStddevPopulationNoteAsc = 'BOOKINGS_STDDEV_POPULATION_NOTE_ASC',
    BookingsStddevPopulationNoteDesc = 'BOOKINGS_STDDEV_POPULATION_NOTE_DESC',
    BookingsStddevPopulationAmountAsc = 'BOOKINGS_STDDEV_POPULATION_AMOUNT_ASC',
    BookingsStddevPopulationAmountDesc = 'BOOKINGS_STDDEV_POPULATION_AMOUNT_DESC',
    BookingsStddevPopulationCreatedAtAsc = 'BOOKINGS_STDDEV_POPULATION_CREATED_AT_ASC',
    BookingsStddevPopulationCreatedAtDesc = 'BOOKINGS_STDDEV_POPULATION_CREATED_AT_DESC',
    BookingsStddevPopulationUpdatedAtAsc = 'BOOKINGS_STDDEV_POPULATION_UPDATED_AT_ASC',
    BookingsStddevPopulationUpdatedAtDesc = 'BOOKINGS_STDDEV_POPULATION_UPDATED_AT_DESC',
    BookingsStddevPopulationRegistrationConfirmationAsc = 'BOOKINGS_STDDEV_POPULATION_REGISTRATION_CONFIRMATION_ASC',
    BookingsStddevPopulationRegistrationConfirmationDesc = 'BOOKINGS_STDDEV_POPULATION_REGISTRATION_CONFIRMATION_DESC',
    BookingsStddevPopulationInvoiceCompanyNameAsc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_COMPANY_NAME_ASC',
    BookingsStddevPopulationInvoiceCompanyNameDesc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_COMPANY_NAME_DESC',
    BookingsStddevPopulationInvoiceFamilyNameAsc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_FAMILY_NAME_ASC',
    BookingsStddevPopulationInvoiceFamilyNameDesc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_FAMILY_NAME_DESC',
    BookingsStddevPopulationInvoiceSalutationAsc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_SALUTATION_ASC',
    BookingsStddevPopulationInvoiceSalutationDesc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_SALUTATION_DESC',
    BookingsStddevPopulationInvoiceTitleAsc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_TITLE_ASC',
    BookingsStddevPopulationInvoiceTitleDesc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_TITLE_DESC',
    BookingsStddevPopulationInvoiceGivenNameAsc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_GIVEN_NAME_ASC',
    BookingsStddevPopulationInvoiceGivenNameDesc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_GIVEN_NAME_DESC',
    BookingsStddevPopulationInvoiceStreetAsc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_STREET_ASC',
    BookingsStddevPopulationInvoiceStreetDesc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_STREET_DESC',
    BookingsStddevPopulationInvoicePostcodeAsc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_POSTCODE_ASC',
    BookingsStddevPopulationInvoicePostcodeDesc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_POSTCODE_DESC',
    BookingsStddevPopulationInvoiceCityAsc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_CITY_ASC',
    BookingsStddevPopulationInvoiceCityDesc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_CITY_DESC',
    BookingsStddevPopulationInvoiceCountryAsc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_COUNTRY_ASC',
    BookingsStddevPopulationInvoiceCountryDesc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_COUNTRY_DESC',
    BookingsStddevPopulationInvoiceBankOwnerAsc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_BANK_OWNER_ASC',
    BookingsStddevPopulationInvoiceBankOwnerDesc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_BANK_OWNER_DESC',
    BookingsStddevPopulationInvoiceBankNameAsc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_BANK_NAME_ASC',
    BookingsStddevPopulationInvoiceBankNameDesc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_BANK_NAME_DESC',
    BookingsStddevPopulationInvoiceBankSwiftAsc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_BANK_SWIFT_ASC',
    BookingsStddevPopulationInvoiceBankSwiftDesc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_BANK_SWIFT_DESC',
    BookingsStddevPopulationInvoiceBankIbanAsc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_BANK_IBAN_ASC',
    BookingsStddevPopulationInvoiceBankIbanDesc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_BANK_IBAN_DESC',
    BookingsStddevPopulationInvoiceToEmployerAsc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_TO_EMPLOYER_ASC',
    BookingsStddevPopulationInvoiceToEmployerDesc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_TO_EMPLOYER_DESC',
    BookingsStddevPopulationStateAsc = 'BOOKINGS_STDDEV_POPULATION_STATE_ASC',
    BookingsStddevPopulationStateDesc = 'BOOKINGS_STDDEV_POPULATION_STATE_DESC',
    BookingsStddevPopulationInvoiceContactPhoneAsc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_CONTACT_PHONE_ASC',
    BookingsStddevPopulationInvoiceContactPhoneDesc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_CONTACT_PHONE_DESC',
    BookingsStddevPopulationInvoiceContactEmailAsc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_CONTACT_EMAIL_ASC',
    BookingsStddevPopulationInvoiceContactEmailDesc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_CONTACT_EMAIL_DESC',
    BookingsStddevPopulationInvoiceContactUrlAsc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_CONTACT_URL_ASC',
    BookingsStddevPopulationInvoiceContactUrlDesc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_CONTACT_URL_DESC',
    BookingsStddevPopulationAcceptAsc = 'BOOKINGS_STDDEV_POPULATION_ACCEPT_ASC',
    BookingsStddevPopulationAcceptDesc = 'BOOKINGS_STDDEV_POPULATION_ACCEPT_DESC',
    BookingsStddevPopulationAcceptPaymentAsc = 'BOOKINGS_STDDEV_POPULATION_ACCEPT_PAYMENT_ASC',
    BookingsStddevPopulationAcceptPaymentDesc = 'BOOKINGS_STDDEV_POPULATION_ACCEPT_PAYMENT_DESC',
    BookingsStddevPopulationInvoiceDateAsc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_DATE_ASC',
    BookingsStddevPopulationInvoiceDateDesc = 'BOOKINGS_STDDEV_POPULATION_INVOICE_DATE_DESC',
    BookingsStddevPopulationCodeAsc = 'BOOKINGS_STDDEV_POPULATION_CODE_ASC',
    BookingsStddevPopulationCodeDesc = 'BOOKINGS_STDDEV_POPULATION_CODE_DESC',
    BookingsStddevPopulationUseForCertificationAsc = 'BOOKINGS_STDDEV_POPULATION_USE_FOR_CERTIFICATION_ASC',
    BookingsStddevPopulationUseForCertificationDesc = 'BOOKINGS_STDDEV_POPULATION_USE_FOR_CERTIFICATION_DESC',
    BookingsVarianceSampleIdAsc = 'BOOKINGS_VARIANCE_SAMPLE_ID_ASC',
    BookingsVarianceSampleIdDesc = 'BOOKINGS_VARIANCE_SAMPLE_ID_DESC',
    BookingsVarianceSampleCourseScheduleIdAsc = 'BOOKINGS_VARIANCE_SAMPLE_COURSE_SCHEDULE_ID_ASC',
    BookingsVarianceSampleCourseScheduleIdDesc = 'BOOKINGS_VARIANCE_SAMPLE_COURSE_SCHEDULE_ID_DESC',
    BookingsVarianceSampleParticipantIdAsc = 'BOOKINGS_VARIANCE_SAMPLE_PARTICIPANT_ID_ASC',
    BookingsVarianceSampleParticipantIdDesc = 'BOOKINGS_VARIANCE_SAMPLE_PARTICIPANT_ID_DESC',
    BookingsVarianceSampleRegistrationRegisteredAsc = 'BOOKINGS_VARIANCE_SAMPLE_REGISTRATION_REGISTERED_ASC',
    BookingsVarianceSampleRegistrationRegisteredDesc = 'BOOKINGS_VARIANCE_SAMPLE_REGISTRATION_REGISTERED_DESC',
    BookingsVarianceSampleRegistrationDateAsc = 'BOOKINGS_VARIANCE_SAMPLE_REGISTRATION_DATE_ASC',
    BookingsVarianceSampleRegistrationDateDesc = 'BOOKINGS_VARIANCE_SAMPLE_REGISTRATION_DATE_DESC',
    BookingsVarianceSampleRegistrationNoteAsc = 'BOOKINGS_VARIANCE_SAMPLE_REGISTRATION_NOTE_ASC',
    BookingsVarianceSampleRegistrationNoteDesc = 'BOOKINGS_VARIANCE_SAMPLE_REGISTRATION_NOTE_DESC',
    BookingsVarianceSamplePassedAsc = 'BOOKINGS_VARIANCE_SAMPLE_PASSED_ASC',
    BookingsVarianceSamplePassedDesc = 'BOOKINGS_VARIANCE_SAMPLE_PASSED_DESC',
    BookingsVarianceSampleMissingHoursAsc = 'BOOKINGS_VARIANCE_SAMPLE_MISSING_HOURS_ASC',
    BookingsVarianceSampleMissingHoursDesc = 'BOOKINGS_VARIANCE_SAMPLE_MISSING_HOURS_DESC',
    BookingsVarianceSamplePaymentReceivedAsc = 'BOOKINGS_VARIANCE_SAMPLE_PAYMENT_RECEIVED_ASC',
    BookingsVarianceSamplePaymentReceivedDesc = 'BOOKINGS_VARIANCE_SAMPLE_PAYMENT_RECEIVED_DESC',
    BookingsVarianceSamplePaymentDateAsc = 'BOOKINGS_VARIANCE_SAMPLE_PAYMENT_DATE_ASC',
    BookingsVarianceSamplePaymentDateDesc = 'BOOKINGS_VARIANCE_SAMPLE_PAYMENT_DATE_DESC',
    BookingsVarianceSamplePaymentMethodAsc = 'BOOKINGS_VARIANCE_SAMPLE_PAYMENT_METHOD_ASC',
    BookingsVarianceSamplePaymentMethodDesc = 'BOOKINGS_VARIANCE_SAMPLE_PAYMENT_METHOD_DESC',
    BookingsVarianceSampleInvoiceNumberAsc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_NUMBER_ASC',
    BookingsVarianceSampleInvoiceNumberDesc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_NUMBER_DESC',
    BookingsVarianceSampleWaitingPositionAsc = 'BOOKINGS_VARIANCE_SAMPLE_WAITING_POSITION_ASC',
    BookingsVarianceSampleWaitingPositionDesc = 'BOOKINGS_VARIANCE_SAMPLE_WAITING_POSITION_DESC',
    BookingsVarianceSampleDocumentsSendAsc = 'BOOKINGS_VARIANCE_SAMPLE_DOCUMENTS_SEND_ASC',
    BookingsVarianceSampleDocumentsSendDesc = 'BOOKINGS_VARIANCE_SAMPLE_DOCUMENTS_SEND_DESC',
    BookingsVarianceSampleNoteAsc = 'BOOKINGS_VARIANCE_SAMPLE_NOTE_ASC',
    BookingsVarianceSampleNoteDesc = 'BOOKINGS_VARIANCE_SAMPLE_NOTE_DESC',
    BookingsVarianceSampleAmountAsc = 'BOOKINGS_VARIANCE_SAMPLE_AMOUNT_ASC',
    BookingsVarianceSampleAmountDesc = 'BOOKINGS_VARIANCE_SAMPLE_AMOUNT_DESC',
    BookingsVarianceSampleCreatedAtAsc = 'BOOKINGS_VARIANCE_SAMPLE_CREATED_AT_ASC',
    BookingsVarianceSampleCreatedAtDesc = 'BOOKINGS_VARIANCE_SAMPLE_CREATED_AT_DESC',
    BookingsVarianceSampleUpdatedAtAsc = 'BOOKINGS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
    BookingsVarianceSampleUpdatedAtDesc = 'BOOKINGS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
    BookingsVarianceSampleRegistrationConfirmationAsc = 'BOOKINGS_VARIANCE_SAMPLE_REGISTRATION_CONFIRMATION_ASC',
    BookingsVarianceSampleRegistrationConfirmationDesc = 'BOOKINGS_VARIANCE_SAMPLE_REGISTRATION_CONFIRMATION_DESC',
    BookingsVarianceSampleInvoiceCompanyNameAsc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_COMPANY_NAME_ASC',
    BookingsVarianceSampleInvoiceCompanyNameDesc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_COMPANY_NAME_DESC',
    BookingsVarianceSampleInvoiceFamilyNameAsc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_FAMILY_NAME_ASC',
    BookingsVarianceSampleInvoiceFamilyNameDesc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_FAMILY_NAME_DESC',
    BookingsVarianceSampleInvoiceSalutationAsc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_SALUTATION_ASC',
    BookingsVarianceSampleInvoiceSalutationDesc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_SALUTATION_DESC',
    BookingsVarianceSampleInvoiceTitleAsc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_TITLE_ASC',
    BookingsVarianceSampleInvoiceTitleDesc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_TITLE_DESC',
    BookingsVarianceSampleInvoiceGivenNameAsc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_GIVEN_NAME_ASC',
    BookingsVarianceSampleInvoiceGivenNameDesc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_GIVEN_NAME_DESC',
    BookingsVarianceSampleInvoiceStreetAsc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_STREET_ASC',
    BookingsVarianceSampleInvoiceStreetDesc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_STREET_DESC',
    BookingsVarianceSampleInvoicePostcodeAsc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_POSTCODE_ASC',
    BookingsVarianceSampleInvoicePostcodeDesc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_POSTCODE_DESC',
    BookingsVarianceSampleInvoiceCityAsc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_CITY_ASC',
    BookingsVarianceSampleInvoiceCityDesc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_CITY_DESC',
    BookingsVarianceSampleInvoiceCountryAsc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_COUNTRY_ASC',
    BookingsVarianceSampleInvoiceCountryDesc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_COUNTRY_DESC',
    BookingsVarianceSampleInvoiceBankOwnerAsc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_BANK_OWNER_ASC',
    BookingsVarianceSampleInvoiceBankOwnerDesc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_BANK_OWNER_DESC',
    BookingsVarianceSampleInvoiceBankNameAsc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_BANK_NAME_ASC',
    BookingsVarianceSampleInvoiceBankNameDesc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_BANK_NAME_DESC',
    BookingsVarianceSampleInvoiceBankSwiftAsc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_BANK_SWIFT_ASC',
    BookingsVarianceSampleInvoiceBankSwiftDesc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_BANK_SWIFT_DESC',
    BookingsVarianceSampleInvoiceBankIbanAsc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_BANK_IBAN_ASC',
    BookingsVarianceSampleInvoiceBankIbanDesc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_BANK_IBAN_DESC',
    BookingsVarianceSampleInvoiceToEmployerAsc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_TO_EMPLOYER_ASC',
    BookingsVarianceSampleInvoiceToEmployerDesc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_TO_EMPLOYER_DESC',
    BookingsVarianceSampleStateAsc = 'BOOKINGS_VARIANCE_SAMPLE_STATE_ASC',
    BookingsVarianceSampleStateDesc = 'BOOKINGS_VARIANCE_SAMPLE_STATE_DESC',
    BookingsVarianceSampleInvoiceContactPhoneAsc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_CONTACT_PHONE_ASC',
    BookingsVarianceSampleInvoiceContactPhoneDesc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_CONTACT_PHONE_DESC',
    BookingsVarianceSampleInvoiceContactEmailAsc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_CONTACT_EMAIL_ASC',
    BookingsVarianceSampleInvoiceContactEmailDesc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_CONTACT_EMAIL_DESC',
    BookingsVarianceSampleInvoiceContactUrlAsc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_CONTACT_URL_ASC',
    BookingsVarianceSampleInvoiceContactUrlDesc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_CONTACT_URL_DESC',
    BookingsVarianceSampleAcceptAsc = 'BOOKINGS_VARIANCE_SAMPLE_ACCEPT_ASC',
    BookingsVarianceSampleAcceptDesc = 'BOOKINGS_VARIANCE_SAMPLE_ACCEPT_DESC',
    BookingsVarianceSampleAcceptPaymentAsc = 'BOOKINGS_VARIANCE_SAMPLE_ACCEPT_PAYMENT_ASC',
    BookingsVarianceSampleAcceptPaymentDesc = 'BOOKINGS_VARIANCE_SAMPLE_ACCEPT_PAYMENT_DESC',
    BookingsVarianceSampleInvoiceDateAsc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_DATE_ASC',
    BookingsVarianceSampleInvoiceDateDesc = 'BOOKINGS_VARIANCE_SAMPLE_INVOICE_DATE_DESC',
    BookingsVarianceSampleCodeAsc = 'BOOKINGS_VARIANCE_SAMPLE_CODE_ASC',
    BookingsVarianceSampleCodeDesc = 'BOOKINGS_VARIANCE_SAMPLE_CODE_DESC',
    BookingsVarianceSampleUseForCertificationAsc = 'BOOKINGS_VARIANCE_SAMPLE_USE_FOR_CERTIFICATION_ASC',
    BookingsVarianceSampleUseForCertificationDesc = 'BOOKINGS_VARIANCE_SAMPLE_USE_FOR_CERTIFICATION_DESC',
    BookingsVariancePopulationIdAsc = 'BOOKINGS_VARIANCE_POPULATION_ID_ASC',
    BookingsVariancePopulationIdDesc = 'BOOKINGS_VARIANCE_POPULATION_ID_DESC',
    BookingsVariancePopulationCourseScheduleIdAsc = 'BOOKINGS_VARIANCE_POPULATION_COURSE_SCHEDULE_ID_ASC',
    BookingsVariancePopulationCourseScheduleIdDesc = 'BOOKINGS_VARIANCE_POPULATION_COURSE_SCHEDULE_ID_DESC',
    BookingsVariancePopulationParticipantIdAsc = 'BOOKINGS_VARIANCE_POPULATION_PARTICIPANT_ID_ASC',
    BookingsVariancePopulationParticipantIdDesc = 'BOOKINGS_VARIANCE_POPULATION_PARTICIPANT_ID_DESC',
    BookingsVariancePopulationRegistrationRegisteredAsc = 'BOOKINGS_VARIANCE_POPULATION_REGISTRATION_REGISTERED_ASC',
    BookingsVariancePopulationRegistrationRegisteredDesc = 'BOOKINGS_VARIANCE_POPULATION_REGISTRATION_REGISTERED_DESC',
    BookingsVariancePopulationRegistrationDateAsc = 'BOOKINGS_VARIANCE_POPULATION_REGISTRATION_DATE_ASC',
    BookingsVariancePopulationRegistrationDateDesc = 'BOOKINGS_VARIANCE_POPULATION_REGISTRATION_DATE_DESC',
    BookingsVariancePopulationRegistrationNoteAsc = 'BOOKINGS_VARIANCE_POPULATION_REGISTRATION_NOTE_ASC',
    BookingsVariancePopulationRegistrationNoteDesc = 'BOOKINGS_VARIANCE_POPULATION_REGISTRATION_NOTE_DESC',
    BookingsVariancePopulationPassedAsc = 'BOOKINGS_VARIANCE_POPULATION_PASSED_ASC',
    BookingsVariancePopulationPassedDesc = 'BOOKINGS_VARIANCE_POPULATION_PASSED_DESC',
    BookingsVariancePopulationMissingHoursAsc = 'BOOKINGS_VARIANCE_POPULATION_MISSING_HOURS_ASC',
    BookingsVariancePopulationMissingHoursDesc = 'BOOKINGS_VARIANCE_POPULATION_MISSING_HOURS_DESC',
    BookingsVariancePopulationPaymentReceivedAsc = 'BOOKINGS_VARIANCE_POPULATION_PAYMENT_RECEIVED_ASC',
    BookingsVariancePopulationPaymentReceivedDesc = 'BOOKINGS_VARIANCE_POPULATION_PAYMENT_RECEIVED_DESC',
    BookingsVariancePopulationPaymentDateAsc = 'BOOKINGS_VARIANCE_POPULATION_PAYMENT_DATE_ASC',
    BookingsVariancePopulationPaymentDateDesc = 'BOOKINGS_VARIANCE_POPULATION_PAYMENT_DATE_DESC',
    BookingsVariancePopulationPaymentMethodAsc = 'BOOKINGS_VARIANCE_POPULATION_PAYMENT_METHOD_ASC',
    BookingsVariancePopulationPaymentMethodDesc = 'BOOKINGS_VARIANCE_POPULATION_PAYMENT_METHOD_DESC',
    BookingsVariancePopulationInvoiceNumberAsc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_NUMBER_ASC',
    BookingsVariancePopulationInvoiceNumberDesc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_NUMBER_DESC',
    BookingsVariancePopulationWaitingPositionAsc = 'BOOKINGS_VARIANCE_POPULATION_WAITING_POSITION_ASC',
    BookingsVariancePopulationWaitingPositionDesc = 'BOOKINGS_VARIANCE_POPULATION_WAITING_POSITION_DESC',
    BookingsVariancePopulationDocumentsSendAsc = 'BOOKINGS_VARIANCE_POPULATION_DOCUMENTS_SEND_ASC',
    BookingsVariancePopulationDocumentsSendDesc = 'BOOKINGS_VARIANCE_POPULATION_DOCUMENTS_SEND_DESC',
    BookingsVariancePopulationNoteAsc = 'BOOKINGS_VARIANCE_POPULATION_NOTE_ASC',
    BookingsVariancePopulationNoteDesc = 'BOOKINGS_VARIANCE_POPULATION_NOTE_DESC',
    BookingsVariancePopulationAmountAsc = 'BOOKINGS_VARIANCE_POPULATION_AMOUNT_ASC',
    BookingsVariancePopulationAmountDesc = 'BOOKINGS_VARIANCE_POPULATION_AMOUNT_DESC',
    BookingsVariancePopulationCreatedAtAsc = 'BOOKINGS_VARIANCE_POPULATION_CREATED_AT_ASC',
    BookingsVariancePopulationCreatedAtDesc = 'BOOKINGS_VARIANCE_POPULATION_CREATED_AT_DESC',
    BookingsVariancePopulationUpdatedAtAsc = 'BOOKINGS_VARIANCE_POPULATION_UPDATED_AT_ASC',
    BookingsVariancePopulationUpdatedAtDesc = 'BOOKINGS_VARIANCE_POPULATION_UPDATED_AT_DESC',
    BookingsVariancePopulationRegistrationConfirmationAsc = 'BOOKINGS_VARIANCE_POPULATION_REGISTRATION_CONFIRMATION_ASC',
    BookingsVariancePopulationRegistrationConfirmationDesc = 'BOOKINGS_VARIANCE_POPULATION_REGISTRATION_CONFIRMATION_DESC',
    BookingsVariancePopulationInvoiceCompanyNameAsc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_COMPANY_NAME_ASC',
    BookingsVariancePopulationInvoiceCompanyNameDesc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_COMPANY_NAME_DESC',
    BookingsVariancePopulationInvoiceFamilyNameAsc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_FAMILY_NAME_ASC',
    BookingsVariancePopulationInvoiceFamilyNameDesc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_FAMILY_NAME_DESC',
    BookingsVariancePopulationInvoiceSalutationAsc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_SALUTATION_ASC',
    BookingsVariancePopulationInvoiceSalutationDesc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_SALUTATION_DESC',
    BookingsVariancePopulationInvoiceTitleAsc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_TITLE_ASC',
    BookingsVariancePopulationInvoiceTitleDesc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_TITLE_DESC',
    BookingsVariancePopulationInvoiceGivenNameAsc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_GIVEN_NAME_ASC',
    BookingsVariancePopulationInvoiceGivenNameDesc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_GIVEN_NAME_DESC',
    BookingsVariancePopulationInvoiceStreetAsc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_STREET_ASC',
    BookingsVariancePopulationInvoiceStreetDesc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_STREET_DESC',
    BookingsVariancePopulationInvoicePostcodeAsc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_POSTCODE_ASC',
    BookingsVariancePopulationInvoicePostcodeDesc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_POSTCODE_DESC',
    BookingsVariancePopulationInvoiceCityAsc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_CITY_ASC',
    BookingsVariancePopulationInvoiceCityDesc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_CITY_DESC',
    BookingsVariancePopulationInvoiceCountryAsc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_COUNTRY_ASC',
    BookingsVariancePopulationInvoiceCountryDesc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_COUNTRY_DESC',
    BookingsVariancePopulationInvoiceBankOwnerAsc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_BANK_OWNER_ASC',
    BookingsVariancePopulationInvoiceBankOwnerDesc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_BANK_OWNER_DESC',
    BookingsVariancePopulationInvoiceBankNameAsc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_BANK_NAME_ASC',
    BookingsVariancePopulationInvoiceBankNameDesc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_BANK_NAME_DESC',
    BookingsVariancePopulationInvoiceBankSwiftAsc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_BANK_SWIFT_ASC',
    BookingsVariancePopulationInvoiceBankSwiftDesc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_BANK_SWIFT_DESC',
    BookingsVariancePopulationInvoiceBankIbanAsc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_BANK_IBAN_ASC',
    BookingsVariancePopulationInvoiceBankIbanDesc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_BANK_IBAN_DESC',
    BookingsVariancePopulationInvoiceToEmployerAsc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_TO_EMPLOYER_ASC',
    BookingsVariancePopulationInvoiceToEmployerDesc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_TO_EMPLOYER_DESC',
    BookingsVariancePopulationStateAsc = 'BOOKINGS_VARIANCE_POPULATION_STATE_ASC',
    BookingsVariancePopulationStateDesc = 'BOOKINGS_VARIANCE_POPULATION_STATE_DESC',
    BookingsVariancePopulationInvoiceContactPhoneAsc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_CONTACT_PHONE_ASC',
    BookingsVariancePopulationInvoiceContactPhoneDesc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_CONTACT_PHONE_DESC',
    BookingsVariancePopulationInvoiceContactEmailAsc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_CONTACT_EMAIL_ASC',
    BookingsVariancePopulationInvoiceContactEmailDesc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_CONTACT_EMAIL_DESC',
    BookingsVariancePopulationInvoiceContactUrlAsc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_CONTACT_URL_ASC',
    BookingsVariancePopulationInvoiceContactUrlDesc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_CONTACT_URL_DESC',
    BookingsVariancePopulationAcceptAsc = 'BOOKINGS_VARIANCE_POPULATION_ACCEPT_ASC',
    BookingsVariancePopulationAcceptDesc = 'BOOKINGS_VARIANCE_POPULATION_ACCEPT_DESC',
    BookingsVariancePopulationAcceptPaymentAsc = 'BOOKINGS_VARIANCE_POPULATION_ACCEPT_PAYMENT_ASC',
    BookingsVariancePopulationAcceptPaymentDesc = 'BOOKINGS_VARIANCE_POPULATION_ACCEPT_PAYMENT_DESC',
    BookingsVariancePopulationInvoiceDateAsc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_DATE_ASC',
    BookingsVariancePopulationInvoiceDateDesc = 'BOOKINGS_VARIANCE_POPULATION_INVOICE_DATE_DESC',
    BookingsVariancePopulationCodeAsc = 'BOOKINGS_VARIANCE_POPULATION_CODE_ASC',
    BookingsVariancePopulationCodeDesc = 'BOOKINGS_VARIANCE_POPULATION_CODE_DESC',
    BookingsVariancePopulationUseForCertificationAsc = 'BOOKINGS_VARIANCE_POPULATION_USE_FOR_CERTIFICATION_ASC',
    BookingsVariancePopulationUseForCertificationDesc = 'BOOKINGS_VARIANCE_POPULATION_USE_FOR_CERTIFICATION_DESC',
    EmailCodesCountAsc = 'EMAIL_CODES_COUNT_ASC',
    EmailCodesCountDesc = 'EMAIL_CODES_COUNT_DESC',
    EmailCodesSumIdAsc = 'EMAIL_CODES_SUM_ID_ASC',
    EmailCodesSumIdDesc = 'EMAIL_CODES_SUM_ID_DESC',
    EmailCodesSumEmailAsc = 'EMAIL_CODES_SUM_EMAIL_ASC',
    EmailCodesSumEmailDesc = 'EMAIL_CODES_SUM_EMAIL_DESC',
    EmailCodesSumCodeAsc = 'EMAIL_CODES_SUM_CODE_ASC',
    EmailCodesSumCodeDesc = 'EMAIL_CODES_SUM_CODE_DESC',
    EmailCodesSumCourseScheduleNodeIdAsc = 'EMAIL_CODES_SUM_COURSE_SCHEDULE_NODE_ID_ASC',
    EmailCodesSumCourseScheduleNodeIdDesc = 'EMAIL_CODES_SUM_COURSE_SCHEDULE_NODE_ID_DESC',
    EmailCodesSumParticipantIdAsc = 'EMAIL_CODES_SUM_PARTICIPANT_ID_ASC',
    EmailCodesSumParticipantIdDesc = 'EMAIL_CODES_SUM_PARTICIPANT_ID_DESC',
    EmailCodesSumCreatedAtAsc = 'EMAIL_CODES_SUM_CREATED_AT_ASC',
    EmailCodesSumCreatedAtDesc = 'EMAIL_CODES_SUM_CREATED_AT_DESC',
    EmailCodesSumUpdatedAtAsc = 'EMAIL_CODES_SUM_UPDATED_AT_ASC',
    EmailCodesSumUpdatedAtDesc = 'EMAIL_CODES_SUM_UPDATED_AT_DESC',
    EmailCodesDistinctCountIdAsc = 'EMAIL_CODES_DISTINCT_COUNT_ID_ASC',
    EmailCodesDistinctCountIdDesc = 'EMAIL_CODES_DISTINCT_COUNT_ID_DESC',
    EmailCodesDistinctCountEmailAsc = 'EMAIL_CODES_DISTINCT_COUNT_EMAIL_ASC',
    EmailCodesDistinctCountEmailDesc = 'EMAIL_CODES_DISTINCT_COUNT_EMAIL_DESC',
    EmailCodesDistinctCountCodeAsc = 'EMAIL_CODES_DISTINCT_COUNT_CODE_ASC',
    EmailCodesDistinctCountCodeDesc = 'EMAIL_CODES_DISTINCT_COUNT_CODE_DESC',
    EmailCodesDistinctCountCourseScheduleNodeIdAsc = 'EMAIL_CODES_DISTINCT_COUNT_COURSE_SCHEDULE_NODE_ID_ASC',
    EmailCodesDistinctCountCourseScheduleNodeIdDesc = 'EMAIL_CODES_DISTINCT_COUNT_COURSE_SCHEDULE_NODE_ID_DESC',
    EmailCodesDistinctCountParticipantIdAsc = 'EMAIL_CODES_DISTINCT_COUNT_PARTICIPANT_ID_ASC',
    EmailCodesDistinctCountParticipantIdDesc = 'EMAIL_CODES_DISTINCT_COUNT_PARTICIPANT_ID_DESC',
    EmailCodesDistinctCountCreatedAtAsc = 'EMAIL_CODES_DISTINCT_COUNT_CREATED_AT_ASC',
    EmailCodesDistinctCountCreatedAtDesc = 'EMAIL_CODES_DISTINCT_COUNT_CREATED_AT_DESC',
    EmailCodesDistinctCountUpdatedAtAsc = 'EMAIL_CODES_DISTINCT_COUNT_UPDATED_AT_ASC',
    EmailCodesDistinctCountUpdatedAtDesc = 'EMAIL_CODES_DISTINCT_COUNT_UPDATED_AT_DESC',
    EmailCodesMinIdAsc = 'EMAIL_CODES_MIN_ID_ASC',
    EmailCodesMinIdDesc = 'EMAIL_CODES_MIN_ID_DESC',
    EmailCodesMinEmailAsc = 'EMAIL_CODES_MIN_EMAIL_ASC',
    EmailCodesMinEmailDesc = 'EMAIL_CODES_MIN_EMAIL_DESC',
    EmailCodesMinCodeAsc = 'EMAIL_CODES_MIN_CODE_ASC',
    EmailCodesMinCodeDesc = 'EMAIL_CODES_MIN_CODE_DESC',
    EmailCodesMinCourseScheduleNodeIdAsc = 'EMAIL_CODES_MIN_COURSE_SCHEDULE_NODE_ID_ASC',
    EmailCodesMinCourseScheduleNodeIdDesc = 'EMAIL_CODES_MIN_COURSE_SCHEDULE_NODE_ID_DESC',
    EmailCodesMinParticipantIdAsc = 'EMAIL_CODES_MIN_PARTICIPANT_ID_ASC',
    EmailCodesMinParticipantIdDesc = 'EMAIL_CODES_MIN_PARTICIPANT_ID_DESC',
    EmailCodesMinCreatedAtAsc = 'EMAIL_CODES_MIN_CREATED_AT_ASC',
    EmailCodesMinCreatedAtDesc = 'EMAIL_CODES_MIN_CREATED_AT_DESC',
    EmailCodesMinUpdatedAtAsc = 'EMAIL_CODES_MIN_UPDATED_AT_ASC',
    EmailCodesMinUpdatedAtDesc = 'EMAIL_CODES_MIN_UPDATED_AT_DESC',
    EmailCodesMaxIdAsc = 'EMAIL_CODES_MAX_ID_ASC',
    EmailCodesMaxIdDesc = 'EMAIL_CODES_MAX_ID_DESC',
    EmailCodesMaxEmailAsc = 'EMAIL_CODES_MAX_EMAIL_ASC',
    EmailCodesMaxEmailDesc = 'EMAIL_CODES_MAX_EMAIL_DESC',
    EmailCodesMaxCodeAsc = 'EMAIL_CODES_MAX_CODE_ASC',
    EmailCodesMaxCodeDesc = 'EMAIL_CODES_MAX_CODE_DESC',
    EmailCodesMaxCourseScheduleNodeIdAsc = 'EMAIL_CODES_MAX_COURSE_SCHEDULE_NODE_ID_ASC',
    EmailCodesMaxCourseScheduleNodeIdDesc = 'EMAIL_CODES_MAX_COURSE_SCHEDULE_NODE_ID_DESC',
    EmailCodesMaxParticipantIdAsc = 'EMAIL_CODES_MAX_PARTICIPANT_ID_ASC',
    EmailCodesMaxParticipantIdDesc = 'EMAIL_CODES_MAX_PARTICIPANT_ID_DESC',
    EmailCodesMaxCreatedAtAsc = 'EMAIL_CODES_MAX_CREATED_AT_ASC',
    EmailCodesMaxCreatedAtDesc = 'EMAIL_CODES_MAX_CREATED_AT_DESC',
    EmailCodesMaxUpdatedAtAsc = 'EMAIL_CODES_MAX_UPDATED_AT_ASC',
    EmailCodesMaxUpdatedAtDesc = 'EMAIL_CODES_MAX_UPDATED_AT_DESC',
    EmailCodesAverageIdAsc = 'EMAIL_CODES_AVERAGE_ID_ASC',
    EmailCodesAverageIdDesc = 'EMAIL_CODES_AVERAGE_ID_DESC',
    EmailCodesAverageEmailAsc = 'EMAIL_CODES_AVERAGE_EMAIL_ASC',
    EmailCodesAverageEmailDesc = 'EMAIL_CODES_AVERAGE_EMAIL_DESC',
    EmailCodesAverageCodeAsc = 'EMAIL_CODES_AVERAGE_CODE_ASC',
    EmailCodesAverageCodeDesc = 'EMAIL_CODES_AVERAGE_CODE_DESC',
    EmailCodesAverageCourseScheduleNodeIdAsc = 'EMAIL_CODES_AVERAGE_COURSE_SCHEDULE_NODE_ID_ASC',
    EmailCodesAverageCourseScheduleNodeIdDesc = 'EMAIL_CODES_AVERAGE_COURSE_SCHEDULE_NODE_ID_DESC',
    EmailCodesAverageParticipantIdAsc = 'EMAIL_CODES_AVERAGE_PARTICIPANT_ID_ASC',
    EmailCodesAverageParticipantIdDesc = 'EMAIL_CODES_AVERAGE_PARTICIPANT_ID_DESC',
    EmailCodesAverageCreatedAtAsc = 'EMAIL_CODES_AVERAGE_CREATED_AT_ASC',
    EmailCodesAverageCreatedAtDesc = 'EMAIL_CODES_AVERAGE_CREATED_AT_DESC',
    EmailCodesAverageUpdatedAtAsc = 'EMAIL_CODES_AVERAGE_UPDATED_AT_ASC',
    EmailCodesAverageUpdatedAtDesc = 'EMAIL_CODES_AVERAGE_UPDATED_AT_DESC',
    EmailCodesStddevSampleIdAsc = 'EMAIL_CODES_STDDEV_SAMPLE_ID_ASC',
    EmailCodesStddevSampleIdDesc = 'EMAIL_CODES_STDDEV_SAMPLE_ID_DESC',
    EmailCodesStddevSampleEmailAsc = 'EMAIL_CODES_STDDEV_SAMPLE_EMAIL_ASC',
    EmailCodesStddevSampleEmailDesc = 'EMAIL_CODES_STDDEV_SAMPLE_EMAIL_DESC',
    EmailCodesStddevSampleCodeAsc = 'EMAIL_CODES_STDDEV_SAMPLE_CODE_ASC',
    EmailCodesStddevSampleCodeDesc = 'EMAIL_CODES_STDDEV_SAMPLE_CODE_DESC',
    EmailCodesStddevSampleCourseScheduleNodeIdAsc = 'EMAIL_CODES_STDDEV_SAMPLE_COURSE_SCHEDULE_NODE_ID_ASC',
    EmailCodesStddevSampleCourseScheduleNodeIdDesc = 'EMAIL_CODES_STDDEV_SAMPLE_COURSE_SCHEDULE_NODE_ID_DESC',
    EmailCodesStddevSampleParticipantIdAsc = 'EMAIL_CODES_STDDEV_SAMPLE_PARTICIPANT_ID_ASC',
    EmailCodesStddevSampleParticipantIdDesc = 'EMAIL_CODES_STDDEV_SAMPLE_PARTICIPANT_ID_DESC',
    EmailCodesStddevSampleCreatedAtAsc = 'EMAIL_CODES_STDDEV_SAMPLE_CREATED_AT_ASC',
    EmailCodesStddevSampleCreatedAtDesc = 'EMAIL_CODES_STDDEV_SAMPLE_CREATED_AT_DESC',
    EmailCodesStddevSampleUpdatedAtAsc = 'EMAIL_CODES_STDDEV_SAMPLE_UPDATED_AT_ASC',
    EmailCodesStddevSampleUpdatedAtDesc = 'EMAIL_CODES_STDDEV_SAMPLE_UPDATED_AT_DESC',
    EmailCodesStddevPopulationIdAsc = 'EMAIL_CODES_STDDEV_POPULATION_ID_ASC',
    EmailCodesStddevPopulationIdDesc = 'EMAIL_CODES_STDDEV_POPULATION_ID_DESC',
    EmailCodesStddevPopulationEmailAsc = 'EMAIL_CODES_STDDEV_POPULATION_EMAIL_ASC',
    EmailCodesStddevPopulationEmailDesc = 'EMAIL_CODES_STDDEV_POPULATION_EMAIL_DESC',
    EmailCodesStddevPopulationCodeAsc = 'EMAIL_CODES_STDDEV_POPULATION_CODE_ASC',
    EmailCodesStddevPopulationCodeDesc = 'EMAIL_CODES_STDDEV_POPULATION_CODE_DESC',
    EmailCodesStddevPopulationCourseScheduleNodeIdAsc = 'EMAIL_CODES_STDDEV_POPULATION_COURSE_SCHEDULE_NODE_ID_ASC',
    EmailCodesStddevPopulationCourseScheduleNodeIdDesc = 'EMAIL_CODES_STDDEV_POPULATION_COURSE_SCHEDULE_NODE_ID_DESC',
    EmailCodesStddevPopulationParticipantIdAsc = 'EMAIL_CODES_STDDEV_POPULATION_PARTICIPANT_ID_ASC',
    EmailCodesStddevPopulationParticipantIdDesc = 'EMAIL_CODES_STDDEV_POPULATION_PARTICIPANT_ID_DESC',
    EmailCodesStddevPopulationCreatedAtAsc = 'EMAIL_CODES_STDDEV_POPULATION_CREATED_AT_ASC',
    EmailCodesStddevPopulationCreatedAtDesc = 'EMAIL_CODES_STDDEV_POPULATION_CREATED_AT_DESC',
    EmailCodesStddevPopulationUpdatedAtAsc = 'EMAIL_CODES_STDDEV_POPULATION_UPDATED_AT_ASC',
    EmailCodesStddevPopulationUpdatedAtDesc = 'EMAIL_CODES_STDDEV_POPULATION_UPDATED_AT_DESC',
    EmailCodesVarianceSampleIdAsc = 'EMAIL_CODES_VARIANCE_SAMPLE_ID_ASC',
    EmailCodesVarianceSampleIdDesc = 'EMAIL_CODES_VARIANCE_SAMPLE_ID_DESC',
    EmailCodesVarianceSampleEmailAsc = 'EMAIL_CODES_VARIANCE_SAMPLE_EMAIL_ASC',
    EmailCodesVarianceSampleEmailDesc = 'EMAIL_CODES_VARIANCE_SAMPLE_EMAIL_DESC',
    EmailCodesVarianceSampleCodeAsc = 'EMAIL_CODES_VARIANCE_SAMPLE_CODE_ASC',
    EmailCodesVarianceSampleCodeDesc = 'EMAIL_CODES_VARIANCE_SAMPLE_CODE_DESC',
    EmailCodesVarianceSampleCourseScheduleNodeIdAsc = 'EMAIL_CODES_VARIANCE_SAMPLE_COURSE_SCHEDULE_NODE_ID_ASC',
    EmailCodesVarianceSampleCourseScheduleNodeIdDesc = 'EMAIL_CODES_VARIANCE_SAMPLE_COURSE_SCHEDULE_NODE_ID_DESC',
    EmailCodesVarianceSampleParticipantIdAsc = 'EMAIL_CODES_VARIANCE_SAMPLE_PARTICIPANT_ID_ASC',
    EmailCodesVarianceSampleParticipantIdDesc = 'EMAIL_CODES_VARIANCE_SAMPLE_PARTICIPANT_ID_DESC',
    EmailCodesVarianceSampleCreatedAtAsc = 'EMAIL_CODES_VARIANCE_SAMPLE_CREATED_AT_ASC',
    EmailCodesVarianceSampleCreatedAtDesc = 'EMAIL_CODES_VARIANCE_SAMPLE_CREATED_AT_DESC',
    EmailCodesVarianceSampleUpdatedAtAsc = 'EMAIL_CODES_VARIANCE_SAMPLE_UPDATED_AT_ASC',
    EmailCodesVarianceSampleUpdatedAtDesc = 'EMAIL_CODES_VARIANCE_SAMPLE_UPDATED_AT_DESC',
    EmailCodesVariancePopulationIdAsc = 'EMAIL_CODES_VARIANCE_POPULATION_ID_ASC',
    EmailCodesVariancePopulationIdDesc = 'EMAIL_CODES_VARIANCE_POPULATION_ID_DESC',
    EmailCodesVariancePopulationEmailAsc = 'EMAIL_CODES_VARIANCE_POPULATION_EMAIL_ASC',
    EmailCodesVariancePopulationEmailDesc = 'EMAIL_CODES_VARIANCE_POPULATION_EMAIL_DESC',
    EmailCodesVariancePopulationCodeAsc = 'EMAIL_CODES_VARIANCE_POPULATION_CODE_ASC',
    EmailCodesVariancePopulationCodeDesc = 'EMAIL_CODES_VARIANCE_POPULATION_CODE_DESC',
    EmailCodesVariancePopulationCourseScheduleNodeIdAsc = 'EMAIL_CODES_VARIANCE_POPULATION_COURSE_SCHEDULE_NODE_ID_ASC',
    EmailCodesVariancePopulationCourseScheduleNodeIdDesc = 'EMAIL_CODES_VARIANCE_POPULATION_COURSE_SCHEDULE_NODE_ID_DESC',
    EmailCodesVariancePopulationParticipantIdAsc = 'EMAIL_CODES_VARIANCE_POPULATION_PARTICIPANT_ID_ASC',
    EmailCodesVariancePopulationParticipantIdDesc = 'EMAIL_CODES_VARIANCE_POPULATION_PARTICIPANT_ID_DESC',
    EmailCodesVariancePopulationCreatedAtAsc = 'EMAIL_CODES_VARIANCE_POPULATION_CREATED_AT_ASC',
    EmailCodesVariancePopulationCreatedAtDesc = 'EMAIL_CODES_VARIANCE_POPULATION_CREATED_AT_DESC',
    EmailCodesVariancePopulationUpdatedAtAsc = 'EMAIL_CODES_VARIANCE_POPULATION_UPDATED_AT_ASC',
    EmailCodesVariancePopulationUpdatedAtDesc = 'EMAIL_CODES_VARIANCE_POPULATION_UPDATED_AT_DESC',
}

/** All input for the `pySendEmail` mutation. */
export type PySendEmailInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    recipient: Scalars['String'];
    sub: Scalars['String'];
    htmlcontent: Scalars['String'];
    textcontent: Scalars['String'];
};

/** The output of our `pySendEmail` mutation. */
export type PySendEmailPayload = {
    __typename?: 'PySendEmailPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    string?: Maybe<Scalars['String']>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
};

/** All input for the `pySendEmailWelcome` mutation. */
export type PySendEmailWelcomeInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    recipient: Scalars['String'];
    sub: Scalars['String'];
    htmltemplate: Scalars['String'];
    texttemplate: Scalars['String'];
    coursefullname: Scalars['String'];
    code: Scalars['String'];
};

/** The output of our `pySendEmailWelcome` mutation. */
export type PySendEmailWelcomePayload = {
    __typename?: 'PySendEmailWelcomePayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    string?: Maybe<Scalars['String']>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
};

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
    __typename?: 'Query';
    /**
     * Exposes the root query type nested one level down. This is helpful for Relay 1
     * which can only query top level fields if they are in a particular form.
     */
    query: Query;
    /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
    nodeId: Scalars['ID'];
    /** Fetches an object given its globally unique `ID`. */
    node?: Maybe<Node>;
    /** Reads and enables pagination through a set of `Booking`. */
    bookings?: Maybe<BookingsConnection>;
    /** Reads and enables pagination through a set of `Course`. */
    courses?: Maybe<CoursesConnection>;
    /** Reads and enables pagination through a set of `CourseDefaultSpeaker`. */
    courseDefaultSpeakers?: Maybe<CourseDefaultSpeakersConnection>;
    /** Reads and enables pagination through a set of `CourseGroup`. */
    courseGroups?: Maybe<CourseGroupsConnection>;
    /** Reads and enables pagination through a set of `CourseGroupMenu`. */
    courseGroupMenus?: Maybe<CourseGroupMenusConnection>;
    /** Reads and enables pagination through a set of `CourseSchedule`. */
    courseSchedules?: Maybe<CourseSchedulesConnection>;
    /** Reads and enables pagination through a set of `CourseScheduleSpeaker`. */
    courseScheduleSpeakers?: Maybe<CourseScheduleSpeakersConnection>;
    /** Reads and enables pagination through a set of `Education`. */
    educations?: Maybe<EducationsConnection>;
    /** Reads and enables pagination through a set of `EducationClass`. */
    educationClasses?: Maybe<EducationClassesConnection>;
    /** Reads and enables pagination through a set of `EducationClassSchedule`. */
    educationClassSchedules?: Maybe<EducationClassSchedulesConnection>;
    /** Reads and enables pagination through a set of `EducationClassScheduleEntry`. */
    educationClassScheduleEntries?: Maybe<EducationClassScheduleEntriesConnection>;
    /** Reads and enables pagination through a set of `EmailCode`. */
    emailCodes?: Maybe<EmailCodesConnection>;
    /** Reads and enables pagination through a set of `EmailTemplate`. */
    emailTemplates?: Maybe<EmailTemplatesConnection>;
    /** Reads and enables pagination through a set of `Location`. */
    locations?: Maybe<LocationsConnection>;
    /** Reads and enables pagination through a set of `Participant`. */
    participants?: Maybe<ParticipantsConnection>;
    /** Reads and enables pagination through a set of `Setting`. */
    settings?: Maybe<SettingsConnection>;
    /** Reads and enables pagination through a set of `Speaker`. */
    speakers?: Maybe<SpeakersConnection>;
    booking?: Maybe<Booking>;
    bookingByCode?: Maybe<Booking>;
    course?: Maybe<Course>;
    courseDefaultSpeaker?: Maybe<CourseDefaultSpeaker>;
    courseGroup?: Maybe<CourseGroup>;
    courseGroupMenu?: Maybe<CourseGroupMenu>;
    courseSchedule?: Maybe<CourseSchedule>;
    courseScheduleSpeaker?: Maybe<CourseScheduleSpeaker>;
    education?: Maybe<Education>;
    educationClass?: Maybe<EducationClass>;
    educationClassSchedule?: Maybe<EducationClassSchedule>;
    educationClassScheduleEntry?: Maybe<EducationClassScheduleEntry>;
    emailCode?: Maybe<EmailCode>;
    emailCodeByCode?: Maybe<EmailCode>;
    emailTemplate?: Maybe<EmailTemplate>;
    location?: Maybe<Location>;
    participant?: Maybe<Participant>;
    participantByEmail?: Maybe<Participant>;
    setting?: Maybe<Setting>;
    speaker?: Maybe<Speaker>;
    courseScheduleResolvedSpeakersList?: Maybe<Scalars['String']>;
    /** Reads and enables pagination through a set of `Speaker`. */
    courseScheduleResolvedSpeakersWithId?: Maybe<SpeakersConnection>;
    pyCheckBicValid?: Maybe<Scalars['Boolean']>;
    pyCheckIbanValid?: Maybe<Scalars['Boolean']>;
    pyCreateParticipantCertificateDocument?: Maybe<Scalars['String']>;
    pyCreateParticipantPassedDocuments?: Maybe<Scalars['String']>;
    pyCreateParticipantsList?: Maybe<Scalars['String']>;
    pyCreateParticipantsSepaXml?: Maybe<Scalars['String']>;
    pyFormattedIban?: Maybe<Scalars['String']>;
    pyGenerateBic?: Maybe<Scalars['String']>;
    pyGenerateBicFromIban?: Maybe<Scalars['String']>;
    pyGenerateIban?: Maybe<Scalars['String']>;
    pySendEmailSignupConfirmation?: Maybe<Scalars['String']>;
    /** Reads a single `Booking` using its globally unique `ID`. */
    bookingByNodeId?: Maybe<Booking>;
    /** Reads a single `Course` using its globally unique `ID`. */
    courseByNodeId?: Maybe<Course>;
    /** Reads a single `CourseDefaultSpeaker` using its globally unique `ID`. */
    courseDefaultSpeakerByNodeId?: Maybe<CourseDefaultSpeaker>;
    /** Reads a single `CourseGroup` using its globally unique `ID`. */
    courseGroupByNodeId?: Maybe<CourseGroup>;
    /** Reads a single `CourseGroupMenu` using its globally unique `ID`. */
    courseGroupMenuByNodeId?: Maybe<CourseGroupMenu>;
    /** Reads a single `CourseSchedule` using its globally unique `ID`. */
    courseScheduleByNodeId?: Maybe<CourseSchedule>;
    /** Reads a single `CourseScheduleSpeaker` using its globally unique `ID`. */
    courseScheduleSpeakerByNodeId?: Maybe<CourseScheduleSpeaker>;
    /** Reads a single `Education` using its globally unique `ID`. */
    educationByNodeId?: Maybe<Education>;
    /** Reads a single `EducationClass` using its globally unique `ID`. */
    educationClassByNodeId?: Maybe<EducationClass>;
    /** Reads a single `EducationClassSchedule` using its globally unique `ID`. */
    educationClassScheduleByNodeId?: Maybe<EducationClassSchedule>;
    /** Reads a single `EducationClassScheduleEntry` using its globally unique `ID`. */
    educationClassScheduleEntryByNodeId?: Maybe<EducationClassScheduleEntry>;
    /** Reads a single `EmailCode` using its globally unique `ID`. */
    emailCodeByNodeId?: Maybe<EmailCode>;
    /** Reads a single `EmailTemplate` using its globally unique `ID`. */
    emailTemplateByNodeId?: Maybe<EmailTemplate>;
    /** Reads a single `Location` using its globally unique `ID`. */
    locationByNodeId?: Maybe<Location>;
    /** Reads a single `Participant` using its globally unique `ID`. */
    participantByNodeId?: Maybe<Participant>;
    /** Reads a single `Setting` using its globally unique `ID`. */
    settingByNodeId?: Maybe<Setting>;
    /** Reads a single `Speaker` using its globally unique `ID`. */
    speakerByNodeId?: Maybe<Speaker>;
};

/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
    nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryBookingsArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['Cursor']>;
    after?: Maybe<Scalars['Cursor']>;
    orderBy?: Maybe<Array<BookingsOrderBy>>;
    condition?: Maybe<BookingCondition>;
    filter?: Maybe<BookingFilter>;
};

/** The root query type which gives access points into the data universe. */
export type QueryCoursesArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['Cursor']>;
    after?: Maybe<Scalars['Cursor']>;
    orderBy?: Maybe<Array<CoursesOrderBy>>;
    condition?: Maybe<CourseCondition>;
    filter?: Maybe<CourseFilter>;
};

/** The root query type which gives access points into the data universe. */
export type QueryCourseDefaultSpeakersArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['Cursor']>;
    after?: Maybe<Scalars['Cursor']>;
    orderBy?: Maybe<Array<CourseDefaultSpeakersOrderBy>>;
    condition?: Maybe<CourseDefaultSpeakerCondition>;
    filter?: Maybe<CourseDefaultSpeakerFilter>;
};

/** The root query type which gives access points into the data universe. */
export type QueryCourseGroupsArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['Cursor']>;
    after?: Maybe<Scalars['Cursor']>;
    orderBy?: Maybe<Array<CourseGroupsOrderBy>>;
    condition?: Maybe<CourseGroupCondition>;
    filter?: Maybe<CourseGroupFilter>;
};

/** The root query type which gives access points into the data universe. */
export type QueryCourseGroupMenusArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['Cursor']>;
    after?: Maybe<Scalars['Cursor']>;
    orderBy?: Maybe<Array<CourseGroupMenusOrderBy>>;
    condition?: Maybe<CourseGroupMenuCondition>;
    filter?: Maybe<CourseGroupMenuFilter>;
};

/** The root query type which gives access points into the data universe. */
export type QueryCourseSchedulesArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['Cursor']>;
    after?: Maybe<Scalars['Cursor']>;
    orderBy?: Maybe<Array<CourseSchedulesOrderBy>>;
    condition?: Maybe<CourseScheduleCondition>;
    filter?: Maybe<CourseScheduleFilter>;
};

/** The root query type which gives access points into the data universe. */
export type QueryCourseScheduleSpeakersArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['Cursor']>;
    after?: Maybe<Scalars['Cursor']>;
    orderBy?: Maybe<Array<CourseScheduleSpeakersOrderBy>>;
    condition?: Maybe<CourseScheduleSpeakerCondition>;
    filter?: Maybe<CourseScheduleSpeakerFilter>;
};

/** The root query type which gives access points into the data universe. */
export type QueryEducationsArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['Cursor']>;
    after?: Maybe<Scalars['Cursor']>;
    orderBy?: Maybe<Array<EducationsOrderBy>>;
    condition?: Maybe<EducationCondition>;
    filter?: Maybe<EducationFilter>;
};

/** The root query type which gives access points into the data universe. */
export type QueryEducationClassesArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['Cursor']>;
    after?: Maybe<Scalars['Cursor']>;
    orderBy?: Maybe<Array<EducationClassesOrderBy>>;
    condition?: Maybe<EducationClassCondition>;
    filter?: Maybe<EducationClassFilter>;
};

/** The root query type which gives access points into the data universe. */
export type QueryEducationClassSchedulesArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['Cursor']>;
    after?: Maybe<Scalars['Cursor']>;
    orderBy?: Maybe<Array<EducationClassSchedulesOrderBy>>;
    condition?: Maybe<EducationClassScheduleCondition>;
    filter?: Maybe<EducationClassScheduleFilter>;
};

/** The root query type which gives access points into the data universe. */
export type QueryEducationClassScheduleEntriesArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['Cursor']>;
    after?: Maybe<Scalars['Cursor']>;
    orderBy?: Maybe<Array<EducationClassScheduleEntriesOrderBy>>;
    condition?: Maybe<EducationClassScheduleEntryCondition>;
    filter?: Maybe<EducationClassScheduleEntryFilter>;
};

/** The root query type which gives access points into the data universe. */
export type QueryEmailCodesArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['Cursor']>;
    after?: Maybe<Scalars['Cursor']>;
    orderBy?: Maybe<Array<EmailCodesOrderBy>>;
    condition?: Maybe<EmailCodeCondition>;
    filter?: Maybe<EmailCodeFilter>;
};

/** The root query type which gives access points into the data universe. */
export type QueryEmailTemplatesArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['Cursor']>;
    after?: Maybe<Scalars['Cursor']>;
    orderBy?: Maybe<Array<EmailTemplatesOrderBy>>;
    condition?: Maybe<EmailTemplateCondition>;
    filter?: Maybe<EmailTemplateFilter>;
};

/** The root query type which gives access points into the data universe. */
export type QueryLocationsArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['Cursor']>;
    after?: Maybe<Scalars['Cursor']>;
    orderBy?: Maybe<Array<LocationsOrderBy>>;
    condition?: Maybe<LocationCondition>;
    filter?: Maybe<LocationFilter>;
};

/** The root query type which gives access points into the data universe. */
export type QueryParticipantsArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['Cursor']>;
    after?: Maybe<Scalars['Cursor']>;
    orderBy?: Maybe<Array<ParticipantsOrderBy>>;
    condition?: Maybe<ParticipantCondition>;
    filter?: Maybe<ParticipantFilter>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySettingsArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['Cursor']>;
    after?: Maybe<Scalars['Cursor']>;
    orderBy?: Maybe<Array<SettingsOrderBy>>;
    condition?: Maybe<SettingCondition>;
    filter?: Maybe<SettingFilter>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySpeakersArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['Cursor']>;
    after?: Maybe<Scalars['Cursor']>;
    orderBy?: Maybe<Array<SpeakersOrderBy>>;
    condition?: Maybe<SpeakerCondition>;
    filter?: Maybe<SpeakerFilter>;
};

/** The root query type which gives access points into the data universe. */
export type QueryBookingArgs = {
    id: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryBookingByCodeArgs = {
    code: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCourseArgs = {
    id: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCourseDefaultSpeakerArgs = {
    speakerId: Scalars['Int'];
    courseId: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCourseGroupArgs = {
    id: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCourseGroupMenuArgs = {
    id: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCourseScheduleArgs = {
    id: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCourseScheduleSpeakerArgs = {
    speakerId: Scalars['Int'];
    courseScheduleId: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryEducationArgs = {
    id: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryEducationClassArgs = {
    id: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryEducationClassScheduleArgs = {
    id: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryEducationClassScheduleEntryArgs = {
    id: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryEmailCodeArgs = {
    id: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryEmailCodeByCodeArgs = {
    code: Scalars['UUID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryEmailTemplateArgs = {
    id: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryLocationArgs = {
    id: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryParticipantArgs = {
    id: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryParticipantByEmailArgs = {
    email: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QuerySettingArgs = {
    id: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QuerySpeakerArgs = {
    id: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCourseScheduleResolvedSpeakersListArgs = {
    sid?: Maybe<Scalars['Int']>;
    delimiter?: Maybe<Scalars['String']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryCourseScheduleResolvedSpeakersWithIdArgs = {
    sid: Scalars['Int'];
    limitCount?: Maybe<Scalars['Int']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['Cursor']>;
    after?: Maybe<Scalars['Cursor']>;
    filter?: Maybe<SpeakerFilter>;
};

/** The root query type which gives access points into the data universe. */
export type QueryPyCheckBicValidArgs = {
    pBic: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPyCheckIbanValidArgs = {
    pIban: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPyCreateParticipantCertificateDocumentArgs = {
    documents: Scalars['JSON'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPyCreateParticipantPassedDocumentsArgs = {
    documents: Scalars['JSON'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPyCreateParticipantsListArgs = {
    participants: Scalars['JSON'];
    course: Scalars['JSON'];
    emptyLines: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPyCreateParticipantsSepaXmlArgs = {
    configJson: Scalars['JSON'];
    paymentsJson: Scalars['JSON'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPyFormattedIbanArgs = {
    pIban: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPyGenerateBicArgs = {
    pCountry: Scalars['String'];
    pBankCode: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPyGenerateBicFromIbanArgs = {
    pIban: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPyGenerateIbanArgs = {
    pCountry: Scalars['String'];
    pBankCode: Scalars['String'];
    pAccountCode: Scalars['String'];
};

/** The root query type which gives access points into the data universe. */
export type QueryPySendEmailSignupConfirmationArgs = {
    recipient: Scalars['String'];
    sub: Scalars['String'];
    htmltemplate: Scalars['String'];
    texttemplate: Scalars['String'];
    coursefullname: Scalars['String'];
    allData: Scalars['JSON'];
    bcc: Scalars['Boolean'];
};

/** The root query type which gives access points into the data universe. */
export type QueryBookingByNodeIdArgs = {
    nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCourseByNodeIdArgs = {
    nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCourseDefaultSpeakerByNodeIdArgs = {
    nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCourseGroupByNodeIdArgs = {
    nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCourseGroupMenuByNodeIdArgs = {
    nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCourseScheduleByNodeIdArgs = {
    nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryCourseScheduleSpeakerByNodeIdArgs = {
    nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryEducationByNodeIdArgs = {
    nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryEducationClassByNodeIdArgs = {
    nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryEducationClassScheduleByNodeIdArgs = {
    nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryEducationClassScheduleEntryByNodeIdArgs = {
    nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryEmailCodeByNodeIdArgs = {
    nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryEmailTemplateByNodeIdArgs = {
    nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryLocationByNodeIdArgs = {
    nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QueryParticipantByNodeIdArgs = {
    nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QuerySettingByNodeIdArgs = {
    nodeId: Scalars['ID'];
};

/** The root query type which gives access points into the data universe. */
export type QuerySpeakerByNodeIdArgs = {
    nodeId: Scalars['ID'];
};

/** All input for the `sendEmailSignupConfirmation` mutation. */
export type SendEmailSignupConfirmationInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    bookingid: Scalars['Int'];
    templateName: Scalars['String'];
};

/** The output of our `sendEmailSignupConfirmation` mutation. */
export type SendEmailSignupConfirmationPayload = {
    __typename?: 'SendEmailSignupConfirmationPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    string?: Maybe<Scalars['String']>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
};

/** All input for the `sendEmailWelcome` mutation. */
export type SendEmailWelcomeInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    recipient: Scalars['String'];
    courseId: Scalars['Int'];
    courseScheduleNodeId: Scalars['String'];
    alreadyRegistered: Scalars['Boolean'];
};

/** The output of our `sendEmailWelcome` mutation. */
export type SendEmailWelcomePayload = {
    __typename?: 'SendEmailWelcomePayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    string?: Maybe<Scalars['String']>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
};

export type Setting = Node & {
    __typename?: 'Setting';
    /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
    nodeId: Scalars['ID'];
    id: Scalars['Int'];
    key: Scalars['String'];
    value: Scalars['JSON'];
};

export type SettingAggregates = {
    __typename?: 'SettingAggregates';
    keys?: Maybe<Array<Scalars['String']>>;
    /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    sum?: Maybe<SettingSumAggregates>;
    /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
    distinctCount?: Maybe<SettingDistinctCountAggregates>;
    /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    min?: Maybe<SettingMinAggregates>;
    /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    max?: Maybe<SettingMaxAggregates>;
    /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
    average?: Maybe<SettingAverageAggregates>;
    /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
    stddevSample?: Maybe<SettingStddevSampleAggregates>;
    /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
    stddevPopulation?: Maybe<SettingStddevPopulationAggregates>;
    /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
    varianceSample?: Maybe<SettingVarianceSampleAggregates>;
    /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
    variancePopulation?: Maybe<SettingVariancePopulationAggregates>;
};

export type SettingAverageAggregates = {
    __typename?: 'SettingAverageAggregates';
    /** Mean average of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
};

/** A condition to be used against `Setting` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type SettingCondition = {
    /** Checks for equality with the object’s `id` field. */
    id?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `key` field. */
    key?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `value` field. */
    value?: Maybe<Scalars['JSON']>;
};

export type SettingDistinctCountAggregates = {
    __typename?: 'SettingDistinctCountAggregates';
    /** Distinct count of id across the matching connection */
    id?: Maybe<Scalars['BigInt']>;
    /** Distinct count of key across the matching connection */
    key?: Maybe<Scalars['BigInt']>;
    /** Distinct count of value across the matching connection */
    value?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `Setting` object types. All fields are combined with a logical ‘and.’ */
export type SettingFilter = {
    /** Filter by the object’s `id` field. */
    id?: Maybe<IntFilter>;
    /** Filter by the object’s `key` field. */
    key?: Maybe<StringFilter>;
    /** Filter by the object’s `value` field. */
    value?: Maybe<JsonFilter>;
    /** Checks for all expressions in this list. */
    and?: Maybe<Array<SettingFilter>>;
    /** Checks for any expressions in this list. */
    or?: Maybe<Array<SettingFilter>>;
    /** Negates the expression. */
    not?: Maybe<SettingFilter>;
};

/** An input for mutations affecting `Setting` */
export type SettingInput = {
    id?: Maybe<Scalars['Int']>;
    key: Scalars['String'];
    value: Scalars['JSON'];
};

export type SettingMaxAggregates = {
    __typename?: 'SettingMaxAggregates';
    /** Maximum of id across the matching connection */
    id?: Maybe<Scalars['Int']>;
};

export type SettingMinAggregates = {
    __typename?: 'SettingMinAggregates';
    /** Minimum of id across the matching connection */
    id?: Maybe<Scalars['Int']>;
};

/** Represents an update to a `Setting`. Fields that are set will be updated. */
export type SettingPatch = {
    id?: Maybe<Scalars['Int']>;
    key?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['JSON']>;
};

export type SettingStddevPopulationAggregates = {
    __typename?: 'SettingStddevPopulationAggregates';
    /** Population standard deviation of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
};

export type SettingStddevSampleAggregates = {
    __typename?: 'SettingStddevSampleAggregates';
    /** Sample standard deviation of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
};

export type SettingSumAggregates = {
    __typename?: 'SettingSumAggregates';
    /** Sum of id across the matching connection */
    id: Scalars['BigInt'];
};

export type SettingVariancePopulationAggregates = {
    __typename?: 'SettingVariancePopulationAggregates';
    /** Population variance of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
};

export type SettingVarianceSampleAggregates = {
    __typename?: 'SettingVarianceSampleAggregates';
    /** Sample variance of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Setting` values. */
export type SettingsConnection = {
    __typename?: 'SettingsConnection';
    /** A list of `Setting` objects. */
    nodes: Array<Setting>;
    /** A list of edges which contains the `Setting` and cursor to aid in pagination. */
    edges: Array<SettingsEdge>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `Setting` you could get from the connection. */
    totalCount: Scalars['Int'];
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<SettingAggregates>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<SettingAggregates>>;
};

/** A connection to a list of `Setting` values. */
export type SettingsConnectionGroupedAggregatesArgs = {
    groupBy: Array<SettingsGroupBy>;
    having?: Maybe<SettingsHavingInput>;
};

/** A `Setting` edge in the connection. */
export type SettingsEdge = {
    __typename?: 'SettingsEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `Setting` at the end of the edge. */
    node: Setting;
};

/** Grouping methods for `Setting` for usage during aggregation. */
export enum SettingsGroupBy {
    Value = 'VALUE',
}

export type SettingsHavingAverageInput = {
    id?: Maybe<HavingIntFilter>;
};

export type SettingsHavingDistinctCountInput = {
    id?: Maybe<HavingIntFilter>;
};

/** Conditions for `Setting` aggregates. */
export type SettingsHavingInput = {
    AND?: Maybe<Array<SettingsHavingInput>>;
    OR?: Maybe<Array<SettingsHavingInput>>;
    sum?: Maybe<SettingsHavingSumInput>;
    distinctCount?: Maybe<SettingsHavingDistinctCountInput>;
    min?: Maybe<SettingsHavingMinInput>;
    max?: Maybe<SettingsHavingMaxInput>;
    average?: Maybe<SettingsHavingAverageInput>;
    stddevSample?: Maybe<SettingsHavingStddevSampleInput>;
    stddevPopulation?: Maybe<SettingsHavingStddevPopulationInput>;
    varianceSample?: Maybe<SettingsHavingVarianceSampleInput>;
    variancePopulation?: Maybe<SettingsHavingVariancePopulationInput>;
};

export type SettingsHavingMaxInput = {
    id?: Maybe<HavingIntFilter>;
};

export type SettingsHavingMinInput = {
    id?: Maybe<HavingIntFilter>;
};

export type SettingsHavingStddevPopulationInput = {
    id?: Maybe<HavingIntFilter>;
};

export type SettingsHavingStddevSampleInput = {
    id?: Maybe<HavingIntFilter>;
};

export type SettingsHavingSumInput = {
    id?: Maybe<HavingIntFilter>;
};

export type SettingsHavingVariancePopulationInput = {
    id?: Maybe<HavingIntFilter>;
};

export type SettingsHavingVarianceSampleInput = {
    id?: Maybe<HavingIntFilter>;
};

/** Methods to use when ordering `Setting`. */
export enum SettingsOrderBy {
    Natural = 'NATURAL',
    IdAsc = 'ID_ASC',
    IdDesc = 'ID_DESC',
    KeyAsc = 'KEY_ASC',
    KeyDesc = 'KEY_DESC',
    ValueAsc = 'VALUE_ASC',
    ValueDesc = 'VALUE_DESC',
    PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
    PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
}

export type Speaker = Node & {
    __typename?: 'Speaker';
    /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
    nodeId: Scalars['ID'];
    id: Scalars['Int'];
    salutation?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    familyName: Scalars['String'];
    givenName: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    archived?: Maybe<Scalars['Boolean']>;
    createdAt: Scalars['Datetime'];
    updatedAt: Scalars['Datetime'];
    /** Reads and enables pagination through a set of `CourseDefaultSpeaker`. */
    courseDefaultSpeakers: CourseDefaultSpeakersConnection;
    /** Reads and enables pagination through a set of `CourseScheduleSpeaker`. */
    courseScheduleSpeakers: CourseScheduleSpeakersConnection;
    /** Reads and enables pagination through a set of `EducationClassScheduleEntry`. */
    educationClassScheduleEntries: EducationClassScheduleEntriesConnection;
    fullName?: Maybe<Scalars['String']>;
    fullNameWithSalutation?: Maybe<Scalars['String']>;
    fullNameWithSalutationAndDescription?: Maybe<Scalars['String']>;
    sortableName?: Maybe<Scalars['String']>;
};

export type SpeakerCourseDefaultSpeakersArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['Cursor']>;
    after?: Maybe<Scalars['Cursor']>;
    orderBy?: Maybe<Array<CourseDefaultSpeakersOrderBy>>;
    condition?: Maybe<CourseDefaultSpeakerCondition>;
    filter?: Maybe<CourseDefaultSpeakerFilter>;
};

export type SpeakerCourseScheduleSpeakersArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['Cursor']>;
    after?: Maybe<Scalars['Cursor']>;
    orderBy?: Maybe<Array<CourseScheduleSpeakersOrderBy>>;
    condition?: Maybe<CourseScheduleSpeakerCondition>;
    filter?: Maybe<CourseScheduleSpeakerFilter>;
};

export type SpeakerEducationClassScheduleEntriesArgs = {
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    before?: Maybe<Scalars['Cursor']>;
    after?: Maybe<Scalars['Cursor']>;
    orderBy?: Maybe<Array<EducationClassScheduleEntriesOrderBy>>;
    condition?: Maybe<EducationClassScheduleEntryCondition>;
    filter?: Maybe<EducationClassScheduleEntryFilter>;
};

export type SpeakerAggregates = {
    __typename?: 'SpeakerAggregates';
    keys?: Maybe<Array<Scalars['String']>>;
    /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    sum?: Maybe<SpeakerSumAggregates>;
    /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
    distinctCount?: Maybe<SpeakerDistinctCountAggregates>;
    /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    min?: Maybe<SpeakerMinAggregates>;
    /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
    max?: Maybe<SpeakerMaxAggregates>;
    /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
    average?: Maybe<SpeakerAverageAggregates>;
    /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
    stddevSample?: Maybe<SpeakerStddevSampleAggregates>;
    /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
    stddevPopulation?: Maybe<SpeakerStddevPopulationAggregates>;
    /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
    varianceSample?: Maybe<SpeakerVarianceSampleAggregates>;
    /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
    variancePopulation?: Maybe<SpeakerVariancePopulationAggregates>;
};

export type SpeakerAverageAggregates = {
    __typename?: 'SpeakerAverageAggregates';
    /** Mean average of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
};

/** A condition to be used against `Speaker` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type SpeakerCondition = {
    /** Checks for equality with the object’s `id` field. */
    id?: Maybe<Scalars['Int']>;
    /** Checks for equality with the object’s `salutation` field. */
    salutation?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `title` field. */
    title?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `familyName` field. */
    familyName?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `givenName` field. */
    givenName?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `description` field. */
    description?: Maybe<Scalars['String']>;
    /** Checks for equality with the object’s `archived` field. */
    archived?: Maybe<Scalars['Boolean']>;
    /** Checks for equality with the object’s `createdAt` field. */
    createdAt?: Maybe<Scalars['Datetime']>;
    /** Checks for equality with the object’s `updatedAt` field. */
    updatedAt?: Maybe<Scalars['Datetime']>;
};

export type SpeakerDistinctCountAggregates = {
    __typename?: 'SpeakerDistinctCountAggregates';
    /** Distinct count of id across the matching connection */
    id?: Maybe<Scalars['BigInt']>;
    /** Distinct count of salutation across the matching connection */
    salutation?: Maybe<Scalars['BigInt']>;
    /** Distinct count of title across the matching connection */
    title?: Maybe<Scalars['BigInt']>;
    /** Distinct count of familyName across the matching connection */
    familyName?: Maybe<Scalars['BigInt']>;
    /** Distinct count of givenName across the matching connection */
    givenName?: Maybe<Scalars['BigInt']>;
    /** Distinct count of description across the matching connection */
    description?: Maybe<Scalars['BigInt']>;
    /** Distinct count of archived across the matching connection */
    archived?: Maybe<Scalars['BigInt']>;
    /** Distinct count of createdAt across the matching connection */
    createdAt?: Maybe<Scalars['BigInt']>;
    /** Distinct count of updatedAt across the matching connection */
    updatedAt?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    fullName?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    fullNameWithSalutation?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    fullNameWithSalutationAndDescription?: Maybe<Scalars['BigInt']>;
    /** Distinct count of this field across the matching connection. */
    sortableName?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `Speaker` object types. All fields are combined with a logical ‘and.’ */
export type SpeakerFilter = {
    /** Filter by the object’s `id` field. */
    id?: Maybe<IntFilter>;
    /** Filter by the object’s `salutation` field. */
    salutation?: Maybe<StringFilter>;
    /** Filter by the object’s `title` field. */
    title?: Maybe<StringFilter>;
    /** Filter by the object’s `familyName` field. */
    familyName?: Maybe<StringFilter>;
    /** Filter by the object’s `givenName` field. */
    givenName?: Maybe<StringFilter>;
    /** Filter by the object’s `description` field. */
    description?: Maybe<StringFilter>;
    /** Filter by the object’s `archived` field. */
    archived?: Maybe<BooleanFilter>;
    /** Filter by the object’s `createdAt` field. */
    createdAt?: Maybe<DatetimeFilter>;
    /** Filter by the object’s `updatedAt` field. */
    updatedAt?: Maybe<DatetimeFilter>;
    /** Filter by the object’s `fullName` field. */
    fullName?: Maybe<StringFilter>;
    /** Filter by the object’s `fullNameWithSalutation` field. */
    fullNameWithSalutation?: Maybe<StringFilter>;
    /** Filter by the object’s `fullNameWithSalutationAndDescription` field. */
    fullNameWithSalutationAndDescription?: Maybe<StringFilter>;
    /** Filter by the object’s `sortableName` field. */
    sortableName?: Maybe<StringFilter>;
    /** Checks for all expressions in this list. */
    and?: Maybe<Array<SpeakerFilter>>;
    /** Checks for any expressions in this list. */
    or?: Maybe<Array<SpeakerFilter>>;
    /** Negates the expression. */
    not?: Maybe<SpeakerFilter>;
};

/** Grouping methods for `Speaker` for usage during aggregation. */
export enum SpeakerGroupBy {
    Salutation = 'SALUTATION',
    Title = 'TITLE',
    FamilyName = 'FAMILY_NAME',
    GivenName = 'GIVEN_NAME',
    Description = 'DESCRIPTION',
    Archived = 'ARCHIVED',
    CreatedAt = 'CREATED_AT',
    CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
    CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
    UpdatedAt = 'UPDATED_AT',
    UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
    UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
}

export type SpeakerHavingAverageInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type SpeakerHavingDistinctCountInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** Conditions for `Speaker` aggregates. */
export type SpeakerHavingInput = {
    AND?: Maybe<Array<SpeakerHavingInput>>;
    OR?: Maybe<Array<SpeakerHavingInput>>;
    sum?: Maybe<SpeakerHavingSumInput>;
    distinctCount?: Maybe<SpeakerHavingDistinctCountInput>;
    min?: Maybe<SpeakerHavingMinInput>;
    max?: Maybe<SpeakerHavingMaxInput>;
    average?: Maybe<SpeakerHavingAverageInput>;
    stddevSample?: Maybe<SpeakerHavingStddevSampleInput>;
    stddevPopulation?: Maybe<SpeakerHavingStddevPopulationInput>;
    varianceSample?: Maybe<SpeakerHavingVarianceSampleInput>;
    variancePopulation?: Maybe<SpeakerHavingVariancePopulationInput>;
};

export type SpeakerHavingMaxInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type SpeakerHavingMinInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type SpeakerHavingStddevPopulationInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type SpeakerHavingStddevSampleInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type SpeakerHavingSumInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type SpeakerHavingVariancePopulationInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

export type SpeakerHavingVarianceSampleInput = {
    id?: Maybe<HavingIntFilter>;
    createdAt?: Maybe<HavingDatetimeFilter>;
    updatedAt?: Maybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `Speaker` */
export type SpeakerInput = {
    id?: Maybe<Scalars['Int']>;
    salutation?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    familyName: Scalars['String'];
    givenName: Scalars['String'];
    description?: Maybe<Scalars['String']>;
    archived?: Maybe<Scalars['Boolean']>;
    createdAt?: Maybe<Scalars['Datetime']>;
    updatedAt?: Maybe<Scalars['Datetime']>;
};

export type SpeakerMaxAggregates = {
    __typename?: 'SpeakerMaxAggregates';
    /** Maximum of id across the matching connection */
    id?: Maybe<Scalars['Int']>;
};

export type SpeakerMinAggregates = {
    __typename?: 'SpeakerMinAggregates';
    /** Minimum of id across the matching connection */
    id?: Maybe<Scalars['Int']>;
};

/** Represents an update to a `Speaker`. Fields that are set will be updated. */
export type SpeakerPatch = {
    id?: Maybe<Scalars['Int']>;
    salutation?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    familyName?: Maybe<Scalars['String']>;
    givenName?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    archived?: Maybe<Scalars['Boolean']>;
    createdAt?: Maybe<Scalars['Datetime']>;
    updatedAt?: Maybe<Scalars['Datetime']>;
};

export type SpeakerStddevPopulationAggregates = {
    __typename?: 'SpeakerStddevPopulationAggregates';
    /** Population standard deviation of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
};

export type SpeakerStddevSampleAggregates = {
    __typename?: 'SpeakerStddevSampleAggregates';
    /** Sample standard deviation of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
};

export type SpeakerSumAggregates = {
    __typename?: 'SpeakerSumAggregates';
    /** Sum of id across the matching connection */
    id: Scalars['BigInt'];
};

export type SpeakerVariancePopulationAggregates = {
    __typename?: 'SpeakerVariancePopulationAggregates';
    /** Population variance of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
};

export type SpeakerVarianceSampleAggregates = {
    __typename?: 'SpeakerVarianceSampleAggregates';
    /** Sample variance of id across the matching connection */
    id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Speaker` values. */
export type SpeakersConnection = {
    __typename?: 'SpeakersConnection';
    /** A list of `Speaker` objects. */
    nodes: Array<Speaker>;
    /** A list of edges which contains the `Speaker` and cursor to aid in pagination. */
    edges: Array<SpeakersEdge>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** The count of *all* `Speaker` you could get from the connection. */
    totalCount: Scalars['Int'];
    /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
    aggregates?: Maybe<SpeakerAggregates>;
    /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
    groupedAggregates?: Maybe<Array<SpeakerAggregates>>;
};

/** A connection to a list of `Speaker` values. */
export type SpeakersConnectionGroupedAggregatesArgs = {
    groupBy: Array<SpeakerGroupBy>;
    having?: Maybe<SpeakerHavingInput>;
};

/** A `Speaker` edge in the connection. */
export type SpeakersEdge = {
    __typename?: 'SpeakersEdge';
    /** A cursor for use in pagination. */
    cursor?: Maybe<Scalars['Cursor']>;
    /** The `Speaker` at the end of the edge. */
    node: Speaker;
};

/** Methods to use when ordering `Speaker`. */
export enum SpeakersOrderBy {
    Natural = 'NATURAL',
    IdAsc = 'ID_ASC',
    IdDesc = 'ID_DESC',
    SalutationAsc = 'SALUTATION_ASC',
    SalutationDesc = 'SALUTATION_DESC',
    TitleAsc = 'TITLE_ASC',
    TitleDesc = 'TITLE_DESC',
    FamilyNameAsc = 'FAMILY_NAME_ASC',
    FamilyNameDesc = 'FAMILY_NAME_DESC',
    GivenNameAsc = 'GIVEN_NAME_ASC',
    GivenNameDesc = 'GIVEN_NAME_DESC',
    DescriptionAsc = 'DESCRIPTION_ASC',
    DescriptionDesc = 'DESCRIPTION_DESC',
    ArchivedAsc = 'ARCHIVED_ASC',
    ArchivedDesc = 'ARCHIVED_DESC',
    CreatedAtAsc = 'CREATED_AT_ASC',
    CreatedAtDesc = 'CREATED_AT_DESC',
    UpdatedAtAsc = 'UPDATED_AT_ASC',
    UpdatedAtDesc = 'UPDATED_AT_DESC',
    PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
    PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
    CourseDefaultSpeakersCountAsc = 'COURSE_DEFAULT_SPEAKERS_COUNT_ASC',
    CourseDefaultSpeakersCountDesc = 'COURSE_DEFAULT_SPEAKERS_COUNT_DESC',
    CourseDefaultSpeakersSumSpeakerIdAsc = 'COURSE_DEFAULT_SPEAKERS_SUM_SPEAKER_ID_ASC',
    CourseDefaultSpeakersSumSpeakerIdDesc = 'COURSE_DEFAULT_SPEAKERS_SUM_SPEAKER_ID_DESC',
    CourseDefaultSpeakersSumCourseIdAsc = 'COURSE_DEFAULT_SPEAKERS_SUM_COURSE_ID_ASC',
    CourseDefaultSpeakersSumCourseIdDesc = 'COURSE_DEFAULT_SPEAKERS_SUM_COURSE_ID_DESC',
    CourseDefaultSpeakersSumCreatedAtAsc = 'COURSE_DEFAULT_SPEAKERS_SUM_CREATED_AT_ASC',
    CourseDefaultSpeakersSumCreatedAtDesc = 'COURSE_DEFAULT_SPEAKERS_SUM_CREATED_AT_DESC',
    CourseDefaultSpeakersSumUpdatedAtAsc = 'COURSE_DEFAULT_SPEAKERS_SUM_UPDATED_AT_ASC',
    CourseDefaultSpeakersSumUpdatedAtDesc = 'COURSE_DEFAULT_SPEAKERS_SUM_UPDATED_AT_DESC',
    CourseDefaultSpeakersDistinctCountSpeakerIdAsc = 'COURSE_DEFAULT_SPEAKERS_DISTINCT_COUNT_SPEAKER_ID_ASC',
    CourseDefaultSpeakersDistinctCountSpeakerIdDesc = 'COURSE_DEFAULT_SPEAKERS_DISTINCT_COUNT_SPEAKER_ID_DESC',
    CourseDefaultSpeakersDistinctCountCourseIdAsc = 'COURSE_DEFAULT_SPEAKERS_DISTINCT_COUNT_COURSE_ID_ASC',
    CourseDefaultSpeakersDistinctCountCourseIdDesc = 'COURSE_DEFAULT_SPEAKERS_DISTINCT_COUNT_COURSE_ID_DESC',
    CourseDefaultSpeakersDistinctCountCreatedAtAsc = 'COURSE_DEFAULT_SPEAKERS_DISTINCT_COUNT_CREATED_AT_ASC',
    CourseDefaultSpeakersDistinctCountCreatedAtDesc = 'COURSE_DEFAULT_SPEAKERS_DISTINCT_COUNT_CREATED_AT_DESC',
    CourseDefaultSpeakersDistinctCountUpdatedAtAsc = 'COURSE_DEFAULT_SPEAKERS_DISTINCT_COUNT_UPDATED_AT_ASC',
    CourseDefaultSpeakersDistinctCountUpdatedAtDesc = 'COURSE_DEFAULT_SPEAKERS_DISTINCT_COUNT_UPDATED_AT_DESC',
    CourseDefaultSpeakersMinSpeakerIdAsc = 'COURSE_DEFAULT_SPEAKERS_MIN_SPEAKER_ID_ASC',
    CourseDefaultSpeakersMinSpeakerIdDesc = 'COURSE_DEFAULT_SPEAKERS_MIN_SPEAKER_ID_DESC',
    CourseDefaultSpeakersMinCourseIdAsc = 'COURSE_DEFAULT_SPEAKERS_MIN_COURSE_ID_ASC',
    CourseDefaultSpeakersMinCourseIdDesc = 'COURSE_DEFAULT_SPEAKERS_MIN_COURSE_ID_DESC',
    CourseDefaultSpeakersMinCreatedAtAsc = 'COURSE_DEFAULT_SPEAKERS_MIN_CREATED_AT_ASC',
    CourseDefaultSpeakersMinCreatedAtDesc = 'COURSE_DEFAULT_SPEAKERS_MIN_CREATED_AT_DESC',
    CourseDefaultSpeakersMinUpdatedAtAsc = 'COURSE_DEFAULT_SPEAKERS_MIN_UPDATED_AT_ASC',
    CourseDefaultSpeakersMinUpdatedAtDesc = 'COURSE_DEFAULT_SPEAKERS_MIN_UPDATED_AT_DESC',
    CourseDefaultSpeakersMaxSpeakerIdAsc = 'COURSE_DEFAULT_SPEAKERS_MAX_SPEAKER_ID_ASC',
    CourseDefaultSpeakersMaxSpeakerIdDesc = 'COURSE_DEFAULT_SPEAKERS_MAX_SPEAKER_ID_DESC',
    CourseDefaultSpeakersMaxCourseIdAsc = 'COURSE_DEFAULT_SPEAKERS_MAX_COURSE_ID_ASC',
    CourseDefaultSpeakersMaxCourseIdDesc = 'COURSE_DEFAULT_SPEAKERS_MAX_COURSE_ID_DESC',
    CourseDefaultSpeakersMaxCreatedAtAsc = 'COURSE_DEFAULT_SPEAKERS_MAX_CREATED_AT_ASC',
    CourseDefaultSpeakersMaxCreatedAtDesc = 'COURSE_DEFAULT_SPEAKERS_MAX_CREATED_AT_DESC',
    CourseDefaultSpeakersMaxUpdatedAtAsc = 'COURSE_DEFAULT_SPEAKERS_MAX_UPDATED_AT_ASC',
    CourseDefaultSpeakersMaxUpdatedAtDesc = 'COURSE_DEFAULT_SPEAKERS_MAX_UPDATED_AT_DESC',
    CourseDefaultSpeakersAverageSpeakerIdAsc = 'COURSE_DEFAULT_SPEAKERS_AVERAGE_SPEAKER_ID_ASC',
    CourseDefaultSpeakersAverageSpeakerIdDesc = 'COURSE_DEFAULT_SPEAKERS_AVERAGE_SPEAKER_ID_DESC',
    CourseDefaultSpeakersAverageCourseIdAsc = 'COURSE_DEFAULT_SPEAKERS_AVERAGE_COURSE_ID_ASC',
    CourseDefaultSpeakersAverageCourseIdDesc = 'COURSE_DEFAULT_SPEAKERS_AVERAGE_COURSE_ID_DESC',
    CourseDefaultSpeakersAverageCreatedAtAsc = 'COURSE_DEFAULT_SPEAKERS_AVERAGE_CREATED_AT_ASC',
    CourseDefaultSpeakersAverageCreatedAtDesc = 'COURSE_DEFAULT_SPEAKERS_AVERAGE_CREATED_AT_DESC',
    CourseDefaultSpeakersAverageUpdatedAtAsc = 'COURSE_DEFAULT_SPEAKERS_AVERAGE_UPDATED_AT_ASC',
    CourseDefaultSpeakersAverageUpdatedAtDesc = 'COURSE_DEFAULT_SPEAKERS_AVERAGE_UPDATED_AT_DESC',
    CourseDefaultSpeakersStddevSampleSpeakerIdAsc = 'COURSE_DEFAULT_SPEAKERS_STDDEV_SAMPLE_SPEAKER_ID_ASC',
    CourseDefaultSpeakersStddevSampleSpeakerIdDesc = 'COURSE_DEFAULT_SPEAKERS_STDDEV_SAMPLE_SPEAKER_ID_DESC',
    CourseDefaultSpeakersStddevSampleCourseIdAsc = 'COURSE_DEFAULT_SPEAKERS_STDDEV_SAMPLE_COURSE_ID_ASC',
    CourseDefaultSpeakersStddevSampleCourseIdDesc = 'COURSE_DEFAULT_SPEAKERS_STDDEV_SAMPLE_COURSE_ID_DESC',
    CourseDefaultSpeakersStddevSampleCreatedAtAsc = 'COURSE_DEFAULT_SPEAKERS_STDDEV_SAMPLE_CREATED_AT_ASC',
    CourseDefaultSpeakersStddevSampleCreatedAtDesc = 'COURSE_DEFAULT_SPEAKERS_STDDEV_SAMPLE_CREATED_AT_DESC',
    CourseDefaultSpeakersStddevSampleUpdatedAtAsc = 'COURSE_DEFAULT_SPEAKERS_STDDEV_SAMPLE_UPDATED_AT_ASC',
    CourseDefaultSpeakersStddevSampleUpdatedAtDesc = 'COURSE_DEFAULT_SPEAKERS_STDDEV_SAMPLE_UPDATED_AT_DESC',
    CourseDefaultSpeakersStddevPopulationSpeakerIdAsc = 'COURSE_DEFAULT_SPEAKERS_STDDEV_POPULATION_SPEAKER_ID_ASC',
    CourseDefaultSpeakersStddevPopulationSpeakerIdDesc = 'COURSE_DEFAULT_SPEAKERS_STDDEV_POPULATION_SPEAKER_ID_DESC',
    CourseDefaultSpeakersStddevPopulationCourseIdAsc = 'COURSE_DEFAULT_SPEAKERS_STDDEV_POPULATION_COURSE_ID_ASC',
    CourseDefaultSpeakersStddevPopulationCourseIdDesc = 'COURSE_DEFAULT_SPEAKERS_STDDEV_POPULATION_COURSE_ID_DESC',
    CourseDefaultSpeakersStddevPopulationCreatedAtAsc = 'COURSE_DEFAULT_SPEAKERS_STDDEV_POPULATION_CREATED_AT_ASC',
    CourseDefaultSpeakersStddevPopulationCreatedAtDesc = 'COURSE_DEFAULT_SPEAKERS_STDDEV_POPULATION_CREATED_AT_DESC',
    CourseDefaultSpeakersStddevPopulationUpdatedAtAsc = 'COURSE_DEFAULT_SPEAKERS_STDDEV_POPULATION_UPDATED_AT_ASC',
    CourseDefaultSpeakersStddevPopulationUpdatedAtDesc = 'COURSE_DEFAULT_SPEAKERS_STDDEV_POPULATION_UPDATED_AT_DESC',
    CourseDefaultSpeakersVarianceSampleSpeakerIdAsc = 'COURSE_DEFAULT_SPEAKERS_VARIANCE_SAMPLE_SPEAKER_ID_ASC',
    CourseDefaultSpeakersVarianceSampleSpeakerIdDesc = 'COURSE_DEFAULT_SPEAKERS_VARIANCE_SAMPLE_SPEAKER_ID_DESC',
    CourseDefaultSpeakersVarianceSampleCourseIdAsc = 'COURSE_DEFAULT_SPEAKERS_VARIANCE_SAMPLE_COURSE_ID_ASC',
    CourseDefaultSpeakersVarianceSampleCourseIdDesc = 'COURSE_DEFAULT_SPEAKERS_VARIANCE_SAMPLE_COURSE_ID_DESC',
    CourseDefaultSpeakersVarianceSampleCreatedAtAsc = 'COURSE_DEFAULT_SPEAKERS_VARIANCE_SAMPLE_CREATED_AT_ASC',
    CourseDefaultSpeakersVarianceSampleCreatedAtDesc = 'COURSE_DEFAULT_SPEAKERS_VARIANCE_SAMPLE_CREATED_AT_DESC',
    CourseDefaultSpeakersVarianceSampleUpdatedAtAsc = 'COURSE_DEFAULT_SPEAKERS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
    CourseDefaultSpeakersVarianceSampleUpdatedAtDesc = 'COURSE_DEFAULT_SPEAKERS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
    CourseDefaultSpeakersVariancePopulationSpeakerIdAsc = 'COURSE_DEFAULT_SPEAKERS_VARIANCE_POPULATION_SPEAKER_ID_ASC',
    CourseDefaultSpeakersVariancePopulationSpeakerIdDesc = 'COURSE_DEFAULT_SPEAKERS_VARIANCE_POPULATION_SPEAKER_ID_DESC',
    CourseDefaultSpeakersVariancePopulationCourseIdAsc = 'COURSE_DEFAULT_SPEAKERS_VARIANCE_POPULATION_COURSE_ID_ASC',
    CourseDefaultSpeakersVariancePopulationCourseIdDesc = 'COURSE_DEFAULT_SPEAKERS_VARIANCE_POPULATION_COURSE_ID_DESC',
    CourseDefaultSpeakersVariancePopulationCreatedAtAsc = 'COURSE_DEFAULT_SPEAKERS_VARIANCE_POPULATION_CREATED_AT_ASC',
    CourseDefaultSpeakersVariancePopulationCreatedAtDesc = 'COURSE_DEFAULT_SPEAKERS_VARIANCE_POPULATION_CREATED_AT_DESC',
    CourseDefaultSpeakersVariancePopulationUpdatedAtAsc = 'COURSE_DEFAULT_SPEAKERS_VARIANCE_POPULATION_UPDATED_AT_ASC',
    CourseDefaultSpeakersVariancePopulationUpdatedAtDesc = 'COURSE_DEFAULT_SPEAKERS_VARIANCE_POPULATION_UPDATED_AT_DESC',
    CourseScheduleSpeakersCountAsc = 'COURSE_SCHEDULE_SPEAKERS_COUNT_ASC',
    CourseScheduleSpeakersCountDesc = 'COURSE_SCHEDULE_SPEAKERS_COUNT_DESC',
    CourseScheduleSpeakersSumSpeakerIdAsc = 'COURSE_SCHEDULE_SPEAKERS_SUM_SPEAKER_ID_ASC',
    CourseScheduleSpeakersSumSpeakerIdDesc = 'COURSE_SCHEDULE_SPEAKERS_SUM_SPEAKER_ID_DESC',
    CourseScheduleSpeakersSumCourseScheduleIdAsc = 'COURSE_SCHEDULE_SPEAKERS_SUM_COURSE_SCHEDULE_ID_ASC',
    CourseScheduleSpeakersSumCourseScheduleIdDesc = 'COURSE_SCHEDULE_SPEAKERS_SUM_COURSE_SCHEDULE_ID_DESC',
    CourseScheduleSpeakersSumCreatedAtAsc = 'COURSE_SCHEDULE_SPEAKERS_SUM_CREATED_AT_ASC',
    CourseScheduleSpeakersSumCreatedAtDesc = 'COURSE_SCHEDULE_SPEAKERS_SUM_CREATED_AT_DESC',
    CourseScheduleSpeakersSumUpdatedAtAsc = 'COURSE_SCHEDULE_SPEAKERS_SUM_UPDATED_AT_ASC',
    CourseScheduleSpeakersSumUpdatedAtDesc = 'COURSE_SCHEDULE_SPEAKERS_SUM_UPDATED_AT_DESC',
    CourseScheduleSpeakersDistinctCountSpeakerIdAsc = 'COURSE_SCHEDULE_SPEAKERS_DISTINCT_COUNT_SPEAKER_ID_ASC',
    CourseScheduleSpeakersDistinctCountSpeakerIdDesc = 'COURSE_SCHEDULE_SPEAKERS_DISTINCT_COUNT_SPEAKER_ID_DESC',
    CourseScheduleSpeakersDistinctCountCourseScheduleIdAsc = 'COURSE_SCHEDULE_SPEAKERS_DISTINCT_COUNT_COURSE_SCHEDULE_ID_ASC',
    CourseScheduleSpeakersDistinctCountCourseScheduleIdDesc = 'COURSE_SCHEDULE_SPEAKERS_DISTINCT_COUNT_COURSE_SCHEDULE_ID_DESC',
    CourseScheduleSpeakersDistinctCountCreatedAtAsc = 'COURSE_SCHEDULE_SPEAKERS_DISTINCT_COUNT_CREATED_AT_ASC',
    CourseScheduleSpeakersDistinctCountCreatedAtDesc = 'COURSE_SCHEDULE_SPEAKERS_DISTINCT_COUNT_CREATED_AT_DESC',
    CourseScheduleSpeakersDistinctCountUpdatedAtAsc = 'COURSE_SCHEDULE_SPEAKERS_DISTINCT_COUNT_UPDATED_AT_ASC',
    CourseScheduleSpeakersDistinctCountUpdatedAtDesc = 'COURSE_SCHEDULE_SPEAKERS_DISTINCT_COUNT_UPDATED_AT_DESC',
    CourseScheduleSpeakersMinSpeakerIdAsc = 'COURSE_SCHEDULE_SPEAKERS_MIN_SPEAKER_ID_ASC',
    CourseScheduleSpeakersMinSpeakerIdDesc = 'COURSE_SCHEDULE_SPEAKERS_MIN_SPEAKER_ID_DESC',
    CourseScheduleSpeakersMinCourseScheduleIdAsc = 'COURSE_SCHEDULE_SPEAKERS_MIN_COURSE_SCHEDULE_ID_ASC',
    CourseScheduleSpeakersMinCourseScheduleIdDesc = 'COURSE_SCHEDULE_SPEAKERS_MIN_COURSE_SCHEDULE_ID_DESC',
    CourseScheduleSpeakersMinCreatedAtAsc = 'COURSE_SCHEDULE_SPEAKERS_MIN_CREATED_AT_ASC',
    CourseScheduleSpeakersMinCreatedAtDesc = 'COURSE_SCHEDULE_SPEAKERS_MIN_CREATED_AT_DESC',
    CourseScheduleSpeakersMinUpdatedAtAsc = 'COURSE_SCHEDULE_SPEAKERS_MIN_UPDATED_AT_ASC',
    CourseScheduleSpeakersMinUpdatedAtDesc = 'COURSE_SCHEDULE_SPEAKERS_MIN_UPDATED_AT_DESC',
    CourseScheduleSpeakersMaxSpeakerIdAsc = 'COURSE_SCHEDULE_SPEAKERS_MAX_SPEAKER_ID_ASC',
    CourseScheduleSpeakersMaxSpeakerIdDesc = 'COURSE_SCHEDULE_SPEAKERS_MAX_SPEAKER_ID_DESC',
    CourseScheduleSpeakersMaxCourseScheduleIdAsc = 'COURSE_SCHEDULE_SPEAKERS_MAX_COURSE_SCHEDULE_ID_ASC',
    CourseScheduleSpeakersMaxCourseScheduleIdDesc = 'COURSE_SCHEDULE_SPEAKERS_MAX_COURSE_SCHEDULE_ID_DESC',
    CourseScheduleSpeakersMaxCreatedAtAsc = 'COURSE_SCHEDULE_SPEAKERS_MAX_CREATED_AT_ASC',
    CourseScheduleSpeakersMaxCreatedAtDesc = 'COURSE_SCHEDULE_SPEAKERS_MAX_CREATED_AT_DESC',
    CourseScheduleSpeakersMaxUpdatedAtAsc = 'COURSE_SCHEDULE_SPEAKERS_MAX_UPDATED_AT_ASC',
    CourseScheduleSpeakersMaxUpdatedAtDesc = 'COURSE_SCHEDULE_SPEAKERS_MAX_UPDATED_AT_DESC',
    CourseScheduleSpeakersAverageSpeakerIdAsc = 'COURSE_SCHEDULE_SPEAKERS_AVERAGE_SPEAKER_ID_ASC',
    CourseScheduleSpeakersAverageSpeakerIdDesc = 'COURSE_SCHEDULE_SPEAKERS_AVERAGE_SPEAKER_ID_DESC',
    CourseScheduleSpeakersAverageCourseScheduleIdAsc = 'COURSE_SCHEDULE_SPEAKERS_AVERAGE_COURSE_SCHEDULE_ID_ASC',
    CourseScheduleSpeakersAverageCourseScheduleIdDesc = 'COURSE_SCHEDULE_SPEAKERS_AVERAGE_COURSE_SCHEDULE_ID_DESC',
    CourseScheduleSpeakersAverageCreatedAtAsc = 'COURSE_SCHEDULE_SPEAKERS_AVERAGE_CREATED_AT_ASC',
    CourseScheduleSpeakersAverageCreatedAtDesc = 'COURSE_SCHEDULE_SPEAKERS_AVERAGE_CREATED_AT_DESC',
    CourseScheduleSpeakersAverageUpdatedAtAsc = 'COURSE_SCHEDULE_SPEAKERS_AVERAGE_UPDATED_AT_ASC',
    CourseScheduleSpeakersAverageUpdatedAtDesc = 'COURSE_SCHEDULE_SPEAKERS_AVERAGE_UPDATED_AT_DESC',
    CourseScheduleSpeakersStddevSampleSpeakerIdAsc = 'COURSE_SCHEDULE_SPEAKERS_STDDEV_SAMPLE_SPEAKER_ID_ASC',
    CourseScheduleSpeakersStddevSampleSpeakerIdDesc = 'COURSE_SCHEDULE_SPEAKERS_STDDEV_SAMPLE_SPEAKER_ID_DESC',
    CourseScheduleSpeakersStddevSampleCourseScheduleIdAsc = 'COURSE_SCHEDULE_SPEAKERS_STDDEV_SAMPLE_COURSE_SCHEDULE_ID_ASC',
    CourseScheduleSpeakersStddevSampleCourseScheduleIdDesc = 'COURSE_SCHEDULE_SPEAKERS_STDDEV_SAMPLE_COURSE_SCHEDULE_ID_DESC',
    CourseScheduleSpeakersStddevSampleCreatedAtAsc = 'COURSE_SCHEDULE_SPEAKERS_STDDEV_SAMPLE_CREATED_AT_ASC',
    CourseScheduleSpeakersStddevSampleCreatedAtDesc = 'COURSE_SCHEDULE_SPEAKERS_STDDEV_SAMPLE_CREATED_AT_DESC',
    CourseScheduleSpeakersStddevSampleUpdatedAtAsc = 'COURSE_SCHEDULE_SPEAKERS_STDDEV_SAMPLE_UPDATED_AT_ASC',
    CourseScheduleSpeakersStddevSampleUpdatedAtDesc = 'COURSE_SCHEDULE_SPEAKERS_STDDEV_SAMPLE_UPDATED_AT_DESC',
    CourseScheduleSpeakersStddevPopulationSpeakerIdAsc = 'COURSE_SCHEDULE_SPEAKERS_STDDEV_POPULATION_SPEAKER_ID_ASC',
    CourseScheduleSpeakersStddevPopulationSpeakerIdDesc = 'COURSE_SCHEDULE_SPEAKERS_STDDEV_POPULATION_SPEAKER_ID_DESC',
    CourseScheduleSpeakersStddevPopulationCourseScheduleIdAsc = 'COURSE_SCHEDULE_SPEAKERS_STDDEV_POPULATION_COURSE_SCHEDULE_ID_ASC',
    CourseScheduleSpeakersStddevPopulationCourseScheduleIdDesc = 'COURSE_SCHEDULE_SPEAKERS_STDDEV_POPULATION_COURSE_SCHEDULE_ID_DESC',
    CourseScheduleSpeakersStddevPopulationCreatedAtAsc = 'COURSE_SCHEDULE_SPEAKERS_STDDEV_POPULATION_CREATED_AT_ASC',
    CourseScheduleSpeakersStddevPopulationCreatedAtDesc = 'COURSE_SCHEDULE_SPEAKERS_STDDEV_POPULATION_CREATED_AT_DESC',
    CourseScheduleSpeakersStddevPopulationUpdatedAtAsc = 'COURSE_SCHEDULE_SPEAKERS_STDDEV_POPULATION_UPDATED_AT_ASC',
    CourseScheduleSpeakersStddevPopulationUpdatedAtDesc = 'COURSE_SCHEDULE_SPEAKERS_STDDEV_POPULATION_UPDATED_AT_DESC',
    CourseScheduleSpeakersVarianceSampleSpeakerIdAsc = 'COURSE_SCHEDULE_SPEAKERS_VARIANCE_SAMPLE_SPEAKER_ID_ASC',
    CourseScheduleSpeakersVarianceSampleSpeakerIdDesc = 'COURSE_SCHEDULE_SPEAKERS_VARIANCE_SAMPLE_SPEAKER_ID_DESC',
    CourseScheduleSpeakersVarianceSampleCourseScheduleIdAsc = 'COURSE_SCHEDULE_SPEAKERS_VARIANCE_SAMPLE_COURSE_SCHEDULE_ID_ASC',
    CourseScheduleSpeakersVarianceSampleCourseScheduleIdDesc = 'COURSE_SCHEDULE_SPEAKERS_VARIANCE_SAMPLE_COURSE_SCHEDULE_ID_DESC',
    CourseScheduleSpeakersVarianceSampleCreatedAtAsc = 'COURSE_SCHEDULE_SPEAKERS_VARIANCE_SAMPLE_CREATED_AT_ASC',
    CourseScheduleSpeakersVarianceSampleCreatedAtDesc = 'COURSE_SCHEDULE_SPEAKERS_VARIANCE_SAMPLE_CREATED_AT_DESC',
    CourseScheduleSpeakersVarianceSampleUpdatedAtAsc = 'COURSE_SCHEDULE_SPEAKERS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
    CourseScheduleSpeakersVarianceSampleUpdatedAtDesc = 'COURSE_SCHEDULE_SPEAKERS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
    CourseScheduleSpeakersVariancePopulationSpeakerIdAsc = 'COURSE_SCHEDULE_SPEAKERS_VARIANCE_POPULATION_SPEAKER_ID_ASC',
    CourseScheduleSpeakersVariancePopulationSpeakerIdDesc = 'COURSE_SCHEDULE_SPEAKERS_VARIANCE_POPULATION_SPEAKER_ID_DESC',
    CourseScheduleSpeakersVariancePopulationCourseScheduleIdAsc = 'COURSE_SCHEDULE_SPEAKERS_VARIANCE_POPULATION_COURSE_SCHEDULE_ID_ASC',
    CourseScheduleSpeakersVariancePopulationCourseScheduleIdDesc = 'COURSE_SCHEDULE_SPEAKERS_VARIANCE_POPULATION_COURSE_SCHEDULE_ID_DESC',
    CourseScheduleSpeakersVariancePopulationCreatedAtAsc = 'COURSE_SCHEDULE_SPEAKERS_VARIANCE_POPULATION_CREATED_AT_ASC',
    CourseScheduleSpeakersVariancePopulationCreatedAtDesc = 'COURSE_SCHEDULE_SPEAKERS_VARIANCE_POPULATION_CREATED_AT_DESC',
    CourseScheduleSpeakersVariancePopulationUpdatedAtAsc = 'COURSE_SCHEDULE_SPEAKERS_VARIANCE_POPULATION_UPDATED_AT_ASC',
    CourseScheduleSpeakersVariancePopulationUpdatedAtDesc = 'COURSE_SCHEDULE_SPEAKERS_VARIANCE_POPULATION_UPDATED_AT_DESC',
    EducationClassScheduleEntriesCountAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_COUNT_ASC',
    EducationClassScheduleEntriesCountDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_COUNT_DESC',
    EducationClassScheduleEntriesSumIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_SUM_ID_ASC',
    EducationClassScheduleEntriesSumIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_SUM_ID_DESC',
    EducationClassScheduleEntriesSumEducationClassScheduleIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_SUM_EDUCATION_CLASS_SCHEDULE_ID_ASC',
    EducationClassScheduleEntriesSumEducationClassScheduleIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_SUM_EDUCATION_CLASS_SCHEDULE_ID_DESC',
    EducationClassScheduleEntriesSumDateAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_SUM_DATE_ASC',
    EducationClassScheduleEntriesSumDateDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_SUM_DATE_DESC',
    EducationClassScheduleEntriesSumSpeakerIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_SUM_SPEAKER_ID_ASC',
    EducationClassScheduleEntriesSumSpeakerIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_SUM_SPEAKER_ID_DESC',
    EducationClassScheduleEntriesSumTopicAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_SUM_TOPIC_ASC',
    EducationClassScheduleEntriesSumTopicDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_SUM_TOPIC_DESC',
    EducationClassScheduleEntriesSumCreatedAtAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_SUM_CREATED_AT_ASC',
    EducationClassScheduleEntriesSumCreatedAtDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_SUM_CREATED_AT_DESC',
    EducationClassScheduleEntriesSumUpdatedAtAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_SUM_UPDATED_AT_ASC',
    EducationClassScheduleEntriesSumUpdatedAtDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_SUM_UPDATED_AT_DESC',
    EducationClassScheduleEntriesDistinctCountIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_DISTINCT_COUNT_ID_ASC',
    EducationClassScheduleEntriesDistinctCountIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_DISTINCT_COUNT_ID_DESC',
    EducationClassScheduleEntriesDistinctCountEducationClassScheduleIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_DISTINCT_COUNT_EDUCATION_CLASS_SCHEDULE_ID_ASC',
    EducationClassScheduleEntriesDistinctCountEducationClassScheduleIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_DISTINCT_COUNT_EDUCATION_CLASS_SCHEDULE_ID_DESC',
    EducationClassScheduleEntriesDistinctCountDateAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_DISTINCT_COUNT_DATE_ASC',
    EducationClassScheduleEntriesDistinctCountDateDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_DISTINCT_COUNT_DATE_DESC',
    EducationClassScheduleEntriesDistinctCountSpeakerIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_DISTINCT_COUNT_SPEAKER_ID_ASC',
    EducationClassScheduleEntriesDistinctCountSpeakerIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_DISTINCT_COUNT_SPEAKER_ID_DESC',
    EducationClassScheduleEntriesDistinctCountTopicAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_DISTINCT_COUNT_TOPIC_ASC',
    EducationClassScheduleEntriesDistinctCountTopicDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_DISTINCT_COUNT_TOPIC_DESC',
    EducationClassScheduleEntriesDistinctCountCreatedAtAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_DISTINCT_COUNT_CREATED_AT_ASC',
    EducationClassScheduleEntriesDistinctCountCreatedAtDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_DISTINCT_COUNT_CREATED_AT_DESC',
    EducationClassScheduleEntriesDistinctCountUpdatedAtAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_DISTINCT_COUNT_UPDATED_AT_ASC',
    EducationClassScheduleEntriesDistinctCountUpdatedAtDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_DISTINCT_COUNT_UPDATED_AT_DESC',
    EducationClassScheduleEntriesMinIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MIN_ID_ASC',
    EducationClassScheduleEntriesMinIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MIN_ID_DESC',
    EducationClassScheduleEntriesMinEducationClassScheduleIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MIN_EDUCATION_CLASS_SCHEDULE_ID_ASC',
    EducationClassScheduleEntriesMinEducationClassScheduleIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MIN_EDUCATION_CLASS_SCHEDULE_ID_DESC',
    EducationClassScheduleEntriesMinDateAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MIN_DATE_ASC',
    EducationClassScheduleEntriesMinDateDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MIN_DATE_DESC',
    EducationClassScheduleEntriesMinSpeakerIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MIN_SPEAKER_ID_ASC',
    EducationClassScheduleEntriesMinSpeakerIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MIN_SPEAKER_ID_DESC',
    EducationClassScheduleEntriesMinTopicAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MIN_TOPIC_ASC',
    EducationClassScheduleEntriesMinTopicDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MIN_TOPIC_DESC',
    EducationClassScheduleEntriesMinCreatedAtAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MIN_CREATED_AT_ASC',
    EducationClassScheduleEntriesMinCreatedAtDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MIN_CREATED_AT_DESC',
    EducationClassScheduleEntriesMinUpdatedAtAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MIN_UPDATED_AT_ASC',
    EducationClassScheduleEntriesMinUpdatedAtDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MIN_UPDATED_AT_DESC',
    EducationClassScheduleEntriesMaxIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MAX_ID_ASC',
    EducationClassScheduleEntriesMaxIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MAX_ID_DESC',
    EducationClassScheduleEntriesMaxEducationClassScheduleIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MAX_EDUCATION_CLASS_SCHEDULE_ID_ASC',
    EducationClassScheduleEntriesMaxEducationClassScheduleIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MAX_EDUCATION_CLASS_SCHEDULE_ID_DESC',
    EducationClassScheduleEntriesMaxDateAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MAX_DATE_ASC',
    EducationClassScheduleEntriesMaxDateDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MAX_DATE_DESC',
    EducationClassScheduleEntriesMaxSpeakerIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MAX_SPEAKER_ID_ASC',
    EducationClassScheduleEntriesMaxSpeakerIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MAX_SPEAKER_ID_DESC',
    EducationClassScheduleEntriesMaxTopicAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MAX_TOPIC_ASC',
    EducationClassScheduleEntriesMaxTopicDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MAX_TOPIC_DESC',
    EducationClassScheduleEntriesMaxCreatedAtAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MAX_CREATED_AT_ASC',
    EducationClassScheduleEntriesMaxCreatedAtDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MAX_CREATED_AT_DESC',
    EducationClassScheduleEntriesMaxUpdatedAtAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MAX_UPDATED_AT_ASC',
    EducationClassScheduleEntriesMaxUpdatedAtDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_MAX_UPDATED_AT_DESC',
    EducationClassScheduleEntriesAverageIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_AVERAGE_ID_ASC',
    EducationClassScheduleEntriesAverageIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_AVERAGE_ID_DESC',
    EducationClassScheduleEntriesAverageEducationClassScheduleIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_AVERAGE_EDUCATION_CLASS_SCHEDULE_ID_ASC',
    EducationClassScheduleEntriesAverageEducationClassScheduleIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_AVERAGE_EDUCATION_CLASS_SCHEDULE_ID_DESC',
    EducationClassScheduleEntriesAverageDateAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_AVERAGE_DATE_ASC',
    EducationClassScheduleEntriesAverageDateDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_AVERAGE_DATE_DESC',
    EducationClassScheduleEntriesAverageSpeakerIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_AVERAGE_SPEAKER_ID_ASC',
    EducationClassScheduleEntriesAverageSpeakerIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_AVERAGE_SPEAKER_ID_DESC',
    EducationClassScheduleEntriesAverageTopicAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_AVERAGE_TOPIC_ASC',
    EducationClassScheduleEntriesAverageTopicDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_AVERAGE_TOPIC_DESC',
    EducationClassScheduleEntriesAverageCreatedAtAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_AVERAGE_CREATED_AT_ASC',
    EducationClassScheduleEntriesAverageCreatedAtDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_AVERAGE_CREATED_AT_DESC',
    EducationClassScheduleEntriesAverageUpdatedAtAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_AVERAGE_UPDATED_AT_ASC',
    EducationClassScheduleEntriesAverageUpdatedAtDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_AVERAGE_UPDATED_AT_DESC',
    EducationClassScheduleEntriesStddevSampleIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_SAMPLE_ID_ASC',
    EducationClassScheduleEntriesStddevSampleIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_SAMPLE_ID_DESC',
    EducationClassScheduleEntriesStddevSampleEducationClassScheduleIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_SAMPLE_EDUCATION_CLASS_SCHEDULE_ID_ASC',
    EducationClassScheduleEntriesStddevSampleEducationClassScheduleIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_SAMPLE_EDUCATION_CLASS_SCHEDULE_ID_DESC',
    EducationClassScheduleEntriesStddevSampleDateAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_SAMPLE_DATE_ASC',
    EducationClassScheduleEntriesStddevSampleDateDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_SAMPLE_DATE_DESC',
    EducationClassScheduleEntriesStddevSampleSpeakerIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_SAMPLE_SPEAKER_ID_ASC',
    EducationClassScheduleEntriesStddevSampleSpeakerIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_SAMPLE_SPEAKER_ID_DESC',
    EducationClassScheduleEntriesStddevSampleTopicAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_SAMPLE_TOPIC_ASC',
    EducationClassScheduleEntriesStddevSampleTopicDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_SAMPLE_TOPIC_DESC',
    EducationClassScheduleEntriesStddevSampleCreatedAtAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_SAMPLE_CREATED_AT_ASC',
    EducationClassScheduleEntriesStddevSampleCreatedAtDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_SAMPLE_CREATED_AT_DESC',
    EducationClassScheduleEntriesStddevSampleUpdatedAtAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_SAMPLE_UPDATED_AT_ASC',
    EducationClassScheduleEntriesStddevSampleUpdatedAtDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_SAMPLE_UPDATED_AT_DESC',
    EducationClassScheduleEntriesStddevPopulationIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_POPULATION_ID_ASC',
    EducationClassScheduleEntriesStddevPopulationIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_POPULATION_ID_DESC',
    EducationClassScheduleEntriesStddevPopulationEducationClassScheduleIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_POPULATION_EDUCATION_CLASS_SCHEDULE_ID_ASC',
    EducationClassScheduleEntriesStddevPopulationEducationClassScheduleIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_POPULATION_EDUCATION_CLASS_SCHEDULE_ID_DESC',
    EducationClassScheduleEntriesStddevPopulationDateAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_POPULATION_DATE_ASC',
    EducationClassScheduleEntriesStddevPopulationDateDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_POPULATION_DATE_DESC',
    EducationClassScheduleEntriesStddevPopulationSpeakerIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_POPULATION_SPEAKER_ID_ASC',
    EducationClassScheduleEntriesStddevPopulationSpeakerIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_POPULATION_SPEAKER_ID_DESC',
    EducationClassScheduleEntriesStddevPopulationTopicAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_POPULATION_TOPIC_ASC',
    EducationClassScheduleEntriesStddevPopulationTopicDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_POPULATION_TOPIC_DESC',
    EducationClassScheduleEntriesStddevPopulationCreatedAtAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_POPULATION_CREATED_AT_ASC',
    EducationClassScheduleEntriesStddevPopulationCreatedAtDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_POPULATION_CREATED_AT_DESC',
    EducationClassScheduleEntriesStddevPopulationUpdatedAtAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_POPULATION_UPDATED_AT_ASC',
    EducationClassScheduleEntriesStddevPopulationUpdatedAtDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_STDDEV_POPULATION_UPDATED_AT_DESC',
    EducationClassScheduleEntriesVarianceSampleIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_SAMPLE_ID_ASC',
    EducationClassScheduleEntriesVarianceSampleIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_SAMPLE_ID_DESC',
    EducationClassScheduleEntriesVarianceSampleEducationClassScheduleIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_SAMPLE_EDUCATION_CLASS_SCHEDULE_ID_ASC',
    EducationClassScheduleEntriesVarianceSampleEducationClassScheduleIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_SAMPLE_EDUCATION_CLASS_SCHEDULE_ID_DESC',
    EducationClassScheduleEntriesVarianceSampleDateAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_SAMPLE_DATE_ASC',
    EducationClassScheduleEntriesVarianceSampleDateDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_SAMPLE_DATE_DESC',
    EducationClassScheduleEntriesVarianceSampleSpeakerIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_SAMPLE_SPEAKER_ID_ASC',
    EducationClassScheduleEntriesVarianceSampleSpeakerIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_SAMPLE_SPEAKER_ID_DESC',
    EducationClassScheduleEntriesVarianceSampleTopicAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_SAMPLE_TOPIC_ASC',
    EducationClassScheduleEntriesVarianceSampleTopicDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_SAMPLE_TOPIC_DESC',
    EducationClassScheduleEntriesVarianceSampleCreatedAtAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_SAMPLE_CREATED_AT_ASC',
    EducationClassScheduleEntriesVarianceSampleCreatedAtDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_SAMPLE_CREATED_AT_DESC',
    EducationClassScheduleEntriesVarianceSampleUpdatedAtAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_SAMPLE_UPDATED_AT_ASC',
    EducationClassScheduleEntriesVarianceSampleUpdatedAtDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_SAMPLE_UPDATED_AT_DESC',
    EducationClassScheduleEntriesVariancePopulationIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_POPULATION_ID_ASC',
    EducationClassScheduleEntriesVariancePopulationIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_POPULATION_ID_DESC',
    EducationClassScheduleEntriesVariancePopulationEducationClassScheduleIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_POPULATION_EDUCATION_CLASS_SCHEDULE_ID_ASC',
    EducationClassScheduleEntriesVariancePopulationEducationClassScheduleIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_POPULATION_EDUCATION_CLASS_SCHEDULE_ID_DESC',
    EducationClassScheduleEntriesVariancePopulationDateAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_POPULATION_DATE_ASC',
    EducationClassScheduleEntriesVariancePopulationDateDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_POPULATION_DATE_DESC',
    EducationClassScheduleEntriesVariancePopulationSpeakerIdAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_POPULATION_SPEAKER_ID_ASC',
    EducationClassScheduleEntriesVariancePopulationSpeakerIdDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_POPULATION_SPEAKER_ID_DESC',
    EducationClassScheduleEntriesVariancePopulationTopicAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_POPULATION_TOPIC_ASC',
    EducationClassScheduleEntriesVariancePopulationTopicDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_POPULATION_TOPIC_DESC',
    EducationClassScheduleEntriesVariancePopulationCreatedAtAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_POPULATION_CREATED_AT_ASC',
    EducationClassScheduleEntriesVariancePopulationCreatedAtDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_POPULATION_CREATED_AT_DESC',
    EducationClassScheduleEntriesVariancePopulationUpdatedAtAsc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_POPULATION_UPDATED_AT_ASC',
    EducationClassScheduleEntriesVariancePopulationUpdatedAtDesc = 'EDUCATION_CLASS_SCHEDULE_ENTRIES_VARIANCE_POPULATION_UPDATED_AT_DESC',
}

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
    /** Is null (if `true` is specified) or is not null (if `false` is specified). */
    isNull?: Maybe<Scalars['Boolean']>;
    /** Equal to the specified value. */
    equalTo?: Maybe<Scalars['String']>;
    /** Not equal to the specified value. */
    notEqualTo?: Maybe<Scalars['String']>;
    /** Not equal to the specified value, treating null like an ordinary value. */
    distinctFrom?: Maybe<Scalars['String']>;
    /** Equal to the specified value, treating null like an ordinary value. */
    notDistinctFrom?: Maybe<Scalars['String']>;
    /** Included in the specified list. */
    in?: Maybe<Array<Scalars['String']>>;
    /** Not included in the specified list. */
    notIn?: Maybe<Array<Scalars['String']>>;
    /** Less than the specified value. */
    lessThan?: Maybe<Scalars['String']>;
    /** Less than or equal to the specified value. */
    lessThanOrEqualTo?: Maybe<Scalars['String']>;
    /** Greater than the specified value. */
    greaterThan?: Maybe<Scalars['String']>;
    /** Greater than or equal to the specified value. */
    greaterThanOrEqualTo?: Maybe<Scalars['String']>;
    /** Contains the specified string (case-sensitive). */
    includes?: Maybe<Scalars['String']>;
    /** Does not contain the specified string (case-sensitive). */
    notIncludes?: Maybe<Scalars['String']>;
    /** Contains the specified string (case-insensitive). */
    includesInsensitive?: Maybe<Scalars['String']>;
    /** Does not contain the specified string (case-insensitive). */
    notIncludesInsensitive?: Maybe<Scalars['String']>;
    /** Starts with the specified string (case-sensitive). */
    startsWith?: Maybe<Scalars['String']>;
    /** Does not start with the specified string (case-sensitive). */
    notStartsWith?: Maybe<Scalars['String']>;
    /** Starts with the specified string (case-insensitive). */
    startsWithInsensitive?: Maybe<Scalars['String']>;
    /** Does not start with the specified string (case-insensitive). */
    notStartsWithInsensitive?: Maybe<Scalars['String']>;
    /** Ends with the specified string (case-sensitive). */
    endsWith?: Maybe<Scalars['String']>;
    /** Does not end with the specified string (case-sensitive). */
    notEndsWith?: Maybe<Scalars['String']>;
    /** Ends with the specified string (case-insensitive). */
    endsWithInsensitive?: Maybe<Scalars['String']>;
    /** Does not end with the specified string (case-insensitive). */
    notEndsWithInsensitive?: Maybe<Scalars['String']>;
    /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
    like?: Maybe<Scalars['String']>;
    /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
    notLike?: Maybe<Scalars['String']>;
    /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
    likeInsensitive?: Maybe<Scalars['String']>;
    /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
    notLikeInsensitive?: Maybe<Scalars['String']>;
    /** Equal to the specified value (case-insensitive). */
    equalToInsensitive?: Maybe<Scalars['String']>;
    /** Not equal to the specified value (case-insensitive). */
    notEqualToInsensitive?: Maybe<Scalars['String']>;
    /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
    distinctFromInsensitive?: Maybe<Scalars['String']>;
    /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
    notDistinctFromInsensitive?: Maybe<Scalars['String']>;
    /** Included in the specified list (case-insensitive). */
    inInsensitive?: Maybe<Array<Scalars['String']>>;
    /** Not included in the specified list (case-insensitive). */
    notInInsensitive?: Maybe<Array<Scalars['String']>>;
    /** Less than the specified value (case-insensitive). */
    lessThanInsensitive?: Maybe<Scalars['String']>;
    /** Less than or equal to the specified value (case-insensitive). */
    lessThanOrEqualToInsensitive?: Maybe<Scalars['String']>;
    /** Greater than the specified value (case-insensitive). */
    greaterThanInsensitive?: Maybe<Scalars['String']>;
    /** Greater than or equal to the specified value (case-insensitive). */
    greaterThanOrEqualToInsensitive?: Maybe<Scalars['String']>;
};

/** A filter to be used against UUID fields. All fields are combined with a logical ‘and.’ */
export type UuidFilter = {
    /** Is null (if `true` is specified) or is not null (if `false` is specified). */
    isNull?: Maybe<Scalars['Boolean']>;
    /** Equal to the specified value. */
    equalTo?: Maybe<Scalars['UUID']>;
    /** Not equal to the specified value. */
    notEqualTo?: Maybe<Scalars['UUID']>;
    /** Not equal to the specified value, treating null like an ordinary value. */
    distinctFrom?: Maybe<Scalars['UUID']>;
    /** Equal to the specified value, treating null like an ordinary value. */
    notDistinctFrom?: Maybe<Scalars['UUID']>;
    /** Included in the specified list. */
    in?: Maybe<Array<Scalars['UUID']>>;
    /** Not included in the specified list. */
    notIn?: Maybe<Array<Scalars['UUID']>>;
    /** Less than the specified value. */
    lessThan?: Maybe<Scalars['UUID']>;
    /** Less than or equal to the specified value. */
    lessThanOrEqualTo?: Maybe<Scalars['UUID']>;
    /** Greater than the specified value. */
    greaterThan?: Maybe<Scalars['UUID']>;
    /** Greater than or equal to the specified value. */
    greaterThanOrEqualTo?: Maybe<Scalars['UUID']>;
};

/** All input for the `updateAddAllPassedParticipantsToCertificate` mutation. */
export type UpdateAddAllPassedParticipantsToCertificateInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    selectedCourseScheduleId: Scalars['Int'];
};

/** The output of our `updateAddAllPassedParticipantsToCertificate` mutation. */
export type UpdateAddAllPassedParticipantsToCertificatePayload = {
    __typename?: 'UpdateAddAllPassedParticipantsToCertificatePayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    integer?: Maybe<Scalars['Int']>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
};

/** All input for the `updateBookingByCode` mutation. */
export type UpdateBookingByCodeInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** An object where the defined keys will be set on the `Booking` being updated. */
    patch: BookingPatch;
    code: Scalars['UUID'];
};

/** All input for the `updateBookingByNodeId` mutation. */
export type UpdateBookingByNodeIdInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The globally unique `ID` which will identify a single `Booking` to be updated. */
    nodeId: Scalars['ID'];
    /** An object where the defined keys will be set on the `Booking` being updated. */
    patch: BookingPatch;
};

/** All input for the `updateBooking` mutation. */
export type UpdateBookingInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** An object where the defined keys will be set on the `Booking` being updated. */
    patch: BookingPatch;
    id: Scalars['Int'];
};

/** The output of our update `Booking` mutation. */
export type UpdateBookingPayload = {
    __typename?: 'UpdateBookingPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `Booking` that was updated by this mutation. */
    booking?: Maybe<Booking>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** Reads a single `CourseSchedule` that is related to this `Booking`. */
    courseSchedule?: Maybe<CourseSchedule>;
    /** Reads a single `Participant` that is related to this `Booking`. */
    participant?: Maybe<Participant>;
    /** An edge for our `Booking`. May be used by Relay 1. */
    bookingEdge?: Maybe<BookingsEdge>;
};

/** The output of our update `Booking` mutation. */
export type UpdateBookingPayloadBookingEdgeArgs = {
    orderBy?: Maybe<Array<BookingsOrderBy>>;
};

/** All input for the `updateBookingsBankData` mutation. */
export type UpdateBookingsBankDataInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    selectedParticipantId: Scalars['Int'];
};

/** The output of our `updateBookingsBankData` mutation. */
export type UpdateBookingsBankDataPayload = {
    __typename?: 'UpdateBookingsBankDataPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    boolean?: Maybe<Scalars['Boolean']>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
};

/** All input for the `updateCourseByNodeId` mutation. */
export type UpdateCourseByNodeIdInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The globally unique `ID` which will identify a single `Course` to be updated. */
    nodeId: Scalars['ID'];
    /** An object where the defined keys will be set on the `Course` being updated. */
    patch: CoursePatch;
};

/** All input for the `updateCourseDefaultSpeakerByNodeId` mutation. */
export type UpdateCourseDefaultSpeakerByNodeIdInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The globally unique `ID` which will identify a single `CourseDefaultSpeaker` to be updated. */
    nodeId: Scalars['ID'];
    /** An object where the defined keys will be set on the `CourseDefaultSpeaker` being updated. */
    patch: CourseDefaultSpeakerPatch;
};

/** All input for the `updateCourseDefaultSpeaker` mutation. */
export type UpdateCourseDefaultSpeakerInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** An object where the defined keys will be set on the `CourseDefaultSpeaker` being updated. */
    patch: CourseDefaultSpeakerPatch;
    speakerId: Scalars['Int'];
    courseId: Scalars['Int'];
};

/** The output of our update `CourseDefaultSpeaker` mutation. */
export type UpdateCourseDefaultSpeakerPayload = {
    __typename?: 'UpdateCourseDefaultSpeakerPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `CourseDefaultSpeaker` that was updated by this mutation. */
    courseDefaultSpeaker?: Maybe<CourseDefaultSpeaker>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** Reads a single `Speaker` that is related to this `CourseDefaultSpeaker`. */
    speaker?: Maybe<Speaker>;
    /** Reads a single `Course` that is related to this `CourseDefaultSpeaker`. */
    course?: Maybe<Course>;
    /** An edge for our `CourseDefaultSpeaker`. May be used by Relay 1. */
    courseDefaultSpeakerEdge?: Maybe<CourseDefaultSpeakersEdge>;
};

/** The output of our update `CourseDefaultSpeaker` mutation. */
export type UpdateCourseDefaultSpeakerPayloadCourseDefaultSpeakerEdgeArgs = {
    orderBy?: Maybe<Array<CourseDefaultSpeakersOrderBy>>;
};

/** All input for the `updateCourseGroupByNodeId` mutation. */
export type UpdateCourseGroupByNodeIdInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The globally unique `ID` which will identify a single `CourseGroup` to be updated. */
    nodeId: Scalars['ID'];
    /** An object where the defined keys will be set on the `CourseGroup` being updated. */
    patch: CourseGroupPatch;
};

/** All input for the `updateCourseGroup` mutation. */
export type UpdateCourseGroupInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** An object where the defined keys will be set on the `CourseGroup` being updated. */
    patch: CourseGroupPatch;
    id: Scalars['Int'];
};

/** All input for the `updateCourseGroupMenuByNodeId` mutation. */
export type UpdateCourseGroupMenuByNodeIdInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The globally unique `ID` which will identify a single `CourseGroupMenu` to be updated. */
    nodeId: Scalars['ID'];
    /** An object where the defined keys will be set on the `CourseGroupMenu` being updated. */
    patch: CourseGroupMenuPatch;
};

/** All input for the `updateCourseGroupMenu` mutation. */
export type UpdateCourseGroupMenuInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** An object where the defined keys will be set on the `CourseGroupMenu` being updated. */
    patch: CourseGroupMenuPatch;
    id: Scalars['Int'];
};

/** The output of our update `CourseGroupMenu` mutation. */
export type UpdateCourseGroupMenuPayload = {
    __typename?: 'UpdateCourseGroupMenuPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `CourseGroupMenu` that was updated by this mutation. */
    courseGroupMenu?: Maybe<CourseGroupMenu>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** An edge for our `CourseGroupMenu`. May be used by Relay 1. */
    courseGroupMenuEdge?: Maybe<CourseGroupMenusEdge>;
};

/** The output of our update `CourseGroupMenu` mutation. */
export type UpdateCourseGroupMenuPayloadCourseGroupMenuEdgeArgs = {
    orderBy?: Maybe<Array<CourseGroupMenusOrderBy>>;
};

/** The output of our update `CourseGroup` mutation. */
export type UpdateCourseGroupPayload = {
    __typename?: 'UpdateCourseGroupPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `CourseGroup` that was updated by this mutation. */
    courseGroup?: Maybe<CourseGroup>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** Reads a single `CourseGroupMenu` that is related to this `CourseGroup`. */
    courseGroupMenu?: Maybe<CourseGroupMenu>;
    /** An edge for our `CourseGroup`. May be used by Relay 1. */
    courseGroupEdge?: Maybe<CourseGroupsEdge>;
};

/** The output of our update `CourseGroup` mutation. */
export type UpdateCourseGroupPayloadCourseGroupEdgeArgs = {
    orderBy?: Maybe<Array<CourseGroupsOrderBy>>;
};

/** All input for the `updateCourse` mutation. */
export type UpdateCourseInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** An object where the defined keys will be set on the `Course` being updated. */
    patch: CoursePatch;
    id: Scalars['Int'];
};

/** The output of our update `Course` mutation. */
export type UpdateCoursePayload = {
    __typename?: 'UpdateCoursePayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `Course` that was updated by this mutation. */
    course?: Maybe<Course>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** Reads a single `Location` that is related to this `Course`. */
    defaultLocation?: Maybe<Location>;
    /** Reads a single `CourseGroup` that is related to this `Course`. */
    courseGroup?: Maybe<CourseGroup>;
    /** An edge for our `Course`. May be used by Relay 1. */
    courseEdge?: Maybe<CoursesEdge>;
};

/** The output of our update `Course` mutation. */
export type UpdateCoursePayloadCourseEdgeArgs = {
    orderBy?: Maybe<Array<CoursesOrderBy>>;
};

/** All input for the `updateCourseScheduleByNodeId` mutation. */
export type UpdateCourseScheduleByNodeIdInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The globally unique `ID` which will identify a single `CourseSchedule` to be updated. */
    nodeId: Scalars['ID'];
    /** An object where the defined keys will be set on the `CourseSchedule` being updated. */
    patch: CourseSchedulePatch;
};

/** All input for the `updateCourseSchedule` mutation. */
export type UpdateCourseScheduleInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** An object where the defined keys will be set on the `CourseSchedule` being updated. */
    patch: CourseSchedulePatch;
    id: Scalars['Int'];
};

/** The output of our update `CourseSchedule` mutation. */
export type UpdateCourseSchedulePayload = {
    __typename?: 'UpdateCourseSchedulePayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `CourseSchedule` that was updated by this mutation. */
    courseSchedule?: Maybe<CourseSchedule>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** Reads a single `Course` that is related to this `CourseSchedule`. */
    course?: Maybe<Course>;
    /** Reads a single `Location` that is related to this `CourseSchedule`. */
    location?: Maybe<Location>;
    /** An edge for our `CourseSchedule`. May be used by Relay 1. */
    courseScheduleEdge?: Maybe<CourseSchedulesEdge>;
};

/** The output of our update `CourseSchedule` mutation. */
export type UpdateCourseSchedulePayloadCourseScheduleEdgeArgs = {
    orderBy?: Maybe<Array<CourseSchedulesOrderBy>>;
};

/** All input for the `updateCourseScheduleSpeakerByNodeId` mutation. */
export type UpdateCourseScheduleSpeakerByNodeIdInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The globally unique `ID` which will identify a single `CourseScheduleSpeaker` to be updated. */
    nodeId: Scalars['ID'];
    /** An object where the defined keys will be set on the `CourseScheduleSpeaker` being updated. */
    patch: CourseScheduleSpeakerPatch;
};

/** All input for the `updateCourseScheduleSpeaker` mutation. */
export type UpdateCourseScheduleSpeakerInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** An object where the defined keys will be set on the `CourseScheduleSpeaker` being updated. */
    patch: CourseScheduleSpeakerPatch;
    speakerId: Scalars['Int'];
    courseScheduleId: Scalars['Int'];
};

/** The output of our update `CourseScheduleSpeaker` mutation. */
export type UpdateCourseScheduleSpeakerPayload = {
    __typename?: 'UpdateCourseScheduleSpeakerPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `CourseScheduleSpeaker` that was updated by this mutation. */
    courseScheduleSpeaker?: Maybe<CourseScheduleSpeaker>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** Reads a single `Speaker` that is related to this `CourseScheduleSpeaker`. */
    speaker?: Maybe<Speaker>;
    /** Reads a single `CourseSchedule` that is related to this `CourseScheduleSpeaker`. */
    courseSchedule?: Maybe<CourseSchedule>;
    /** An edge for our `CourseScheduleSpeaker`. May be used by Relay 1. */
    courseScheduleSpeakerEdge?: Maybe<CourseScheduleSpeakersEdge>;
};

/** The output of our update `CourseScheduleSpeaker` mutation. */
export type UpdateCourseScheduleSpeakerPayloadCourseScheduleSpeakerEdgeArgs = {
    orderBy?: Maybe<Array<CourseScheduleSpeakersOrderBy>>;
};

/** All input for the `updateCourseScheduleSpeakers` mutation. */
export type UpdateCourseScheduleSpeakersInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    selectedCourseScheduleId: Scalars['Int'];
    selectedSpeakerIds: Array<Maybe<Scalars['Int']>>;
};

/** The output of our `updateCourseScheduleSpeakers` mutation. */
export type UpdateCourseScheduleSpeakersPayload = {
    __typename?: 'UpdateCourseScheduleSpeakersPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    boolean?: Maybe<Scalars['Boolean']>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
};

/** All input for the `updateEducationByNodeId` mutation. */
export type UpdateEducationByNodeIdInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The globally unique `ID` which will identify a single `Education` to be updated. */
    nodeId: Scalars['ID'];
    /** An object where the defined keys will be set on the `Education` being updated. */
    patch: EducationPatch;
};

/** All input for the `updateEducationClassByNodeId` mutation. */
export type UpdateEducationClassByNodeIdInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The globally unique `ID` which will identify a single `EducationClass` to be updated. */
    nodeId: Scalars['ID'];
    /** An object where the defined keys will be set on the `EducationClass` being updated. */
    patch: EducationClassPatch;
};

/** All input for the `updateEducationClass` mutation. */
export type UpdateEducationClassInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** An object where the defined keys will be set on the `EducationClass` being updated. */
    patch: EducationClassPatch;
    id: Scalars['Int'];
};

/** The output of our update `EducationClass` mutation. */
export type UpdateEducationClassPayload = {
    __typename?: 'UpdateEducationClassPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `EducationClass` that was updated by this mutation. */
    educationClass?: Maybe<EducationClass>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** Reads a single `Education` that is related to this `EducationClass`. */
    education?: Maybe<Education>;
    /** An edge for our `EducationClass`. May be used by Relay 1. */
    educationClassEdge?: Maybe<EducationClassesEdge>;
};

/** The output of our update `EducationClass` mutation. */
export type UpdateEducationClassPayloadEducationClassEdgeArgs = {
    orderBy?: Maybe<Array<EducationClassesOrderBy>>;
};

/** All input for the `updateEducationClassScheduleByNodeId` mutation. */
export type UpdateEducationClassScheduleByNodeIdInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The globally unique `ID` which will identify a single `EducationClassSchedule` to be updated. */
    nodeId: Scalars['ID'];
    /** An object where the defined keys will be set on the `EducationClassSchedule` being updated. */
    patch: EducationClassSchedulePatch;
};

/** All input for the `updateEducationClassScheduleEntryByNodeId` mutation. */
export type UpdateEducationClassScheduleEntryByNodeIdInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The globally unique `ID` which will identify a single `EducationClassScheduleEntry` to be updated. */
    nodeId: Scalars['ID'];
    /** An object where the defined keys will be set on the `EducationClassScheduleEntry` being updated. */
    patch: EducationClassScheduleEntryPatch;
};

/** All input for the `updateEducationClassScheduleEntry` mutation. */
export type UpdateEducationClassScheduleEntryInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** An object where the defined keys will be set on the `EducationClassScheduleEntry` being updated. */
    patch: EducationClassScheduleEntryPatch;
    id: Scalars['Int'];
};

/** The output of our update `EducationClassScheduleEntry` mutation. */
export type UpdateEducationClassScheduleEntryPayload = {
    __typename?: 'UpdateEducationClassScheduleEntryPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `EducationClassScheduleEntry` that was updated by this mutation. */
    educationClassScheduleEntry?: Maybe<EducationClassScheduleEntry>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** Reads a single `EducationClassSchedule` that is related to this `EducationClassScheduleEntry`. */
    educationClassSchedule?: Maybe<EducationClassSchedule>;
    /** Reads a single `Speaker` that is related to this `EducationClassScheduleEntry`. */
    speaker?: Maybe<Speaker>;
    /** An edge for our `EducationClassScheduleEntry`. May be used by Relay 1. */
    educationClassScheduleEntryEdge?: Maybe<EducationClassScheduleEntriesEdge>;
};

/** The output of our update `EducationClassScheduleEntry` mutation. */
export type UpdateEducationClassScheduleEntryPayloadEducationClassScheduleEntryEdgeArgs = {
    orderBy?: Maybe<Array<EducationClassScheduleEntriesOrderBy>>;
};

/** All input for the `updateEducationClassSchedule` mutation. */
export type UpdateEducationClassScheduleInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** An object where the defined keys will be set on the `EducationClassSchedule` being updated. */
    patch: EducationClassSchedulePatch;
    id: Scalars['Int'];
};

/** The output of our update `EducationClassSchedule` mutation. */
export type UpdateEducationClassSchedulePayload = {
    __typename?: 'UpdateEducationClassSchedulePayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `EducationClassSchedule` that was updated by this mutation. */
    educationClassSchedule?: Maybe<EducationClassSchedule>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** Reads a single `EducationClass` that is related to this `EducationClassSchedule`. */
    educationClass?: Maybe<EducationClass>;
    /** Reads a single `Location` that is related to this `EducationClassSchedule`. */
    location?: Maybe<Location>;
    /** An edge for our `EducationClassSchedule`. May be used by Relay 1. */
    educationClassScheduleEdge?: Maybe<EducationClassSchedulesEdge>;
};

/** The output of our update `EducationClassSchedule` mutation. */
export type UpdateEducationClassSchedulePayloadEducationClassScheduleEdgeArgs = {
    orderBy?: Maybe<Array<EducationClassSchedulesOrderBy>>;
};

/** All input for the `updateEducation` mutation. */
export type UpdateEducationInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** An object where the defined keys will be set on the `Education` being updated. */
    patch: EducationPatch;
    id: Scalars['Int'];
};

/** The output of our update `Education` mutation. */
export type UpdateEducationPayload = {
    __typename?: 'UpdateEducationPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `Education` that was updated by this mutation. */
    education?: Maybe<Education>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** An edge for our `Education`. May be used by Relay 1. */
    educationEdge?: Maybe<EducationsEdge>;
};

/** The output of our update `Education` mutation. */
export type UpdateEducationPayloadEducationEdgeArgs = {
    orderBy?: Maybe<Array<EducationsOrderBy>>;
};

/** All input for the `updateEmailCodeByCode` mutation. */
export type UpdateEmailCodeByCodeInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** An object where the defined keys will be set on the `EmailCode` being updated. */
    patch: EmailCodePatch;
    code: Scalars['UUID'];
};

/** All input for the `updateEmailCodeByNodeId` mutation. */
export type UpdateEmailCodeByNodeIdInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The globally unique `ID` which will identify a single `EmailCode` to be updated. */
    nodeId: Scalars['ID'];
    /** An object where the defined keys will be set on the `EmailCode` being updated. */
    patch: EmailCodePatch;
};

/** All input for the `updateEmailCode` mutation. */
export type UpdateEmailCodeInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** An object where the defined keys will be set on the `EmailCode` being updated. */
    patch: EmailCodePatch;
    id: Scalars['Int'];
};

/** The output of our update `EmailCode` mutation. */
export type UpdateEmailCodePayload = {
    __typename?: 'UpdateEmailCodePayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `EmailCode` that was updated by this mutation. */
    emailCode?: Maybe<EmailCode>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** Reads a single `Participant` that is related to this `EmailCode`. */
    participant?: Maybe<Participant>;
    /** An edge for our `EmailCode`. May be used by Relay 1. */
    emailCodeEdge?: Maybe<EmailCodesEdge>;
};

/** The output of our update `EmailCode` mutation. */
export type UpdateEmailCodePayloadEmailCodeEdgeArgs = {
    orderBy?: Maybe<Array<EmailCodesOrderBy>>;
};

/** All input for the `updateEmailTemplateByNodeId` mutation. */
export type UpdateEmailTemplateByNodeIdInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The globally unique `ID` which will identify a single `EmailTemplate` to be updated. */
    nodeId: Scalars['ID'];
    /** An object where the defined keys will be set on the `EmailTemplate` being updated. */
    patch: EmailTemplatePatch;
};

/** All input for the `updateEmailTemplate` mutation. */
export type UpdateEmailTemplateInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** An object where the defined keys will be set on the `EmailTemplate` being updated. */
    patch: EmailTemplatePatch;
    id: Scalars['Int'];
};

/** The output of our update `EmailTemplate` mutation. */
export type UpdateEmailTemplatePayload = {
    __typename?: 'UpdateEmailTemplatePayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `EmailTemplate` that was updated by this mutation. */
    emailTemplate?: Maybe<EmailTemplate>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** An edge for our `EmailTemplate`. May be used by Relay 1. */
    emailTemplateEdge?: Maybe<EmailTemplatesEdge>;
};

/** The output of our update `EmailTemplate` mutation. */
export type UpdateEmailTemplatePayloadEmailTemplateEdgeArgs = {
    orderBy?: Maybe<Array<EmailTemplatesOrderBy>>;
};

/** All input for the `updateLocationByNodeId` mutation. */
export type UpdateLocationByNodeIdInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The globally unique `ID` which will identify a single `Location` to be updated. */
    nodeId: Scalars['ID'];
    /** An object where the defined keys will be set on the `Location` being updated. */
    patch: LocationPatch;
};

/** All input for the `updateLocation` mutation. */
export type UpdateLocationInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** An object where the defined keys will be set on the `Location` being updated. */
    patch: LocationPatch;
    id: Scalars['Int'];
};

/** The output of our update `Location` mutation. */
export type UpdateLocationPayload = {
    __typename?: 'UpdateLocationPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `Location` that was updated by this mutation. */
    location?: Maybe<Location>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** An edge for our `Location`. May be used by Relay 1. */
    locationEdge?: Maybe<LocationsEdge>;
};

/** The output of our update `Location` mutation. */
export type UpdateLocationPayloadLocationEdgeArgs = {
    orderBy?: Maybe<Array<LocationsOrderBy>>;
};

/** All input for the `updateParticipantByEmail` mutation. */
export type UpdateParticipantByEmailInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** An object where the defined keys will be set on the `Participant` being updated. */
    patch: ParticipantPatch;
    email: Scalars['String'];
};

/** All input for the `updateParticipantByNodeId` mutation. */
export type UpdateParticipantByNodeIdInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The globally unique `ID` which will identify a single `Participant` to be updated. */
    nodeId: Scalars['ID'];
    /** An object where the defined keys will be set on the `Participant` being updated. */
    patch: ParticipantPatch;
};

/** All input for the `updateParticipant` mutation. */
export type UpdateParticipantInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** An object where the defined keys will be set on the `Participant` being updated. */
    patch: ParticipantPatch;
    id: Scalars['Int'];
};

/** The output of our update `Participant` mutation. */
export type UpdateParticipantPayload = {
    __typename?: 'UpdateParticipantPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `Participant` that was updated by this mutation. */
    participant?: Maybe<Participant>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** An edge for our `Participant`. May be used by Relay 1. */
    participantEdge?: Maybe<ParticipantsEdge>;
};

/** The output of our update `Participant` mutation. */
export type UpdateParticipantPayloadParticipantEdgeArgs = {
    orderBy?: Maybe<Array<ParticipantsOrderBy>>;
};

/** All input for the `updatePassedAllParticipants` mutation. */
export type UpdatePassedAllParticipantsInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    selectedCourseScheduleId: Scalars['Int'];
};

/** The output of our `updatePassedAllParticipants` mutation. */
export type UpdatePassedAllParticipantsPayload = {
    __typename?: 'UpdatePassedAllParticipantsPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    integer?: Maybe<Scalars['Int']>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
};

/** All input for the `updateSettingByNodeId` mutation. */
export type UpdateSettingByNodeIdInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The globally unique `ID` which will identify a single `Setting` to be updated. */
    nodeId: Scalars['ID'];
    /** An object where the defined keys will be set on the `Setting` being updated. */
    patch: SettingPatch;
};

/** All input for the `updateSetting` mutation. */
export type UpdateSettingInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** An object where the defined keys will be set on the `Setting` being updated. */
    patch: SettingPatch;
    id: Scalars['Int'];
};

/** The output of our update `Setting` mutation. */
export type UpdateSettingPayload = {
    __typename?: 'UpdateSettingPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `Setting` that was updated by this mutation. */
    setting?: Maybe<Setting>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** An edge for our `Setting`. May be used by Relay 1. */
    settingEdge?: Maybe<SettingsEdge>;
};

/** The output of our update `Setting` mutation. */
export type UpdateSettingPayloadSettingEdgeArgs = {
    orderBy?: Maybe<Array<SettingsOrderBy>>;
};

/** All input for the `updateSpeakerByNodeId` mutation. */
export type UpdateSpeakerByNodeIdInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The globally unique `ID` which will identify a single `Speaker` to be updated. */
    nodeId: Scalars['ID'];
    /** An object where the defined keys will be set on the `Speaker` being updated. */
    patch: SpeakerPatch;
};

/** All input for the `updateSpeaker` mutation. */
export type UpdateSpeakerInput = {
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** An object where the defined keys will be set on the `Speaker` being updated. */
    patch: SpeakerPatch;
    id: Scalars['Int'];
};

/** The output of our update `Speaker` mutation. */
export type UpdateSpeakerPayload = {
    __typename?: 'UpdateSpeakerPayload';
    /**
     * The exact same `clientMutationId` that was provided in the mutation input,
     * unchanged and unused. May be used by a client to track mutations.
     */
    clientMutationId?: Maybe<Scalars['String']>;
    /** The `Speaker` that was updated by this mutation. */
    speaker?: Maybe<Speaker>;
    /** Our root query field type. Allows us to run any query from our mutation payload. */
    query?: Maybe<Query>;
    /** An edge for our `Speaker`. May be used by Relay 1. */
    speakerEdge?: Maybe<SpeakersEdge>;
};

/** The output of our update `Speaker` mutation. */
export type UpdateSpeakerPayloadSpeakerEdgeArgs = {
    orderBy?: Maybe<Array<SpeakersOrderBy>>;
};

export type GetAllBookingsQueryVariables = Exact<{
    bookingFilter?: Maybe<BookingFilter>;
}>;

export type GetAllBookingsQuery = {
    __typename?: 'Query';
    bookings?: Maybe<{
        __typename?: 'BookingsConnection';
        nodes: Array<{ __typename?: 'Booking' } & BookingDetailsFragment>;
    }>;
};

export type GetParticipantsForTableQueryVariables = Exact<{ [key: string]: never }>;

export type GetParticipantsForTableQuery = {
    __typename?: 'Query';
    participants?: Maybe<{
        __typename?: 'ParticipantsConnection';
        nodes: Array<{
            __typename?: 'Participant';
            nodeId: string;
            salutation?: Maybe<string>;
            sortableName?: Maybe<string>;
            address?: Maybe<string>;
            highlighted?: Maybe<boolean>;
            mobileOrPhone?: Maybe<string>;
            email?: Maybe<string>;
            createdAt: any;
            id: number;
            birthday?: Maybe<any>;
        }>;
    }>;
};

export type GetParticipantDetailsQueryVariables = Exact<{
    nodeId: Scalars['ID'];
}>;

export type GetParticipantDetailsQuery = {
    __typename?: 'Query';
    participantByNodeId?: Maybe<{ __typename?: 'Participant' } & ParticipantDetailsFieldsFragment>;
};

export type ParticipantDetailsFieldsFragment = {
    __typename?: 'Participant';
    birthday?: Maybe<any>;
    city?: Maybe<string>;
    country: string;
    email?: Maybe<string>;
    familyName: string;
    fax?: Maybe<string>;
    givenName: string;
    highlighted?: Maybe<boolean>;
    id: number;
    invoiceBankIban?: Maybe<string>;
    invoiceBankName?: Maybe<string>;
    invoiceBankOwner?: Maybe<string>;
    invoiceBankSwift?: Maybe<string>;
    invoiceCity?: Maybe<string>;
    invoiceCountry?: Maybe<string>;
    invoiceCredit: any;
    invoiceFamilyName?: Maybe<string>;
    invoiceGivenName?: Maybe<string>;
    invoicePostcode?: Maybe<string>;
    invoiceStreet?: Maybe<string>;
    invoiceSalutation?: Maybe<string>;
    invoiceTitle?: Maybe<string>;
    jobTitle?: Maybe<string>;
    mobile?: Maybe<string>;
    note?: Maybe<string>;
    phone?: Maybe<string>;
    postcode?: Maybe<string>;
    gender?: Maybe<ParticipantGender>;
    street?: Maybe<string>;
    title?: Maybe<string>;
    sortableName?: Maybe<string>;
    nodeId: string;
    salutationEmail?: Maybe<string>;
    files?: Maybe<string>;
};

export type UpdateParticipantDetailsMutationVariables = Exact<{
    nodeId: Scalars['ID'];
    participantPatch: ParticipantPatch;
}>;

export type UpdateParticipantDetailsMutation = {
    __typename?: 'Mutation';
    updateParticipantByNodeId?: Maybe<{
        __typename?: 'UpdateParticipantPayload';
        clientMutationId?: Maybe<string>;
        participant?: Maybe<{ __typename?: 'Participant' } & ParticipantDetailsFieldsFragment>;
    }>;
};

export type GetSpeakersForTableQueryVariables = Exact<{ [key: string]: never }>;

export type GetSpeakersForTableQuery = {
    __typename?: 'Query';
    speakers?: Maybe<{
        __typename?: 'SpeakersConnection';
        nodes: Array<{
            __typename?: 'Speaker';
            nodeId: string;
            description?: Maybe<string>;
            sortableName?: Maybe<string>;
            title?: Maybe<string>;
            salutation?: Maybe<string>;
            archived?: Maybe<boolean>;
            fullNameWithSalutation?: Maybe<string>;
            fullName?: Maybe<string>;
            id: number;
        }>;
    }>;
};

export type GetSpeakerDetailsQueryVariables = Exact<{
    nodeId: Scalars['ID'];
}>;

export type GetSpeakerDetailsQuery = {
    __typename?: 'Query';
    speakerByNodeId?: Maybe<
        {
            __typename?: 'Speaker';
            courseScheduleSpeakers: { __typename?: 'CourseScheduleSpeakersConnection'; totalCount: number };
        } & SpeakerDetailsFieldsFragment
    >;
};

export type SpeakerDetailsFieldsFragment = {
    __typename?: 'Speaker';
    familyName: string;
    givenName: string;
    salutation?: Maybe<string>;
    title?: Maybe<string>;
    description?: Maybe<string>;
    archived?: Maybe<boolean>;
    fullNameWithSalutation?: Maybe<string>;
    fullName?: Maybe<string>;
    sortableName?: Maybe<string>;
    id: number;
};

export type UpdateSpeakerDetailsMutationVariables = Exact<{
    nodeId: Scalars['ID'];
    speakerPatch: SpeakerPatch;
}>;

export type UpdateSpeakerDetailsMutation = {
    __typename?: 'Mutation';
    updateSpeakerByNodeId?: Maybe<{ __typename?: 'UpdateSpeakerPayload'; clientMutationId?: Maybe<string> }>;
};

export type CreateSpeakerMutationVariables = Exact<{
    speakerInput: CreateSpeakerInput;
}>;

export type CreateSpeakerMutation = {
    __typename?: 'Mutation';
    createSpeaker?: Maybe<{
        __typename?: 'CreateSpeakerPayload';
        speaker?: Maybe<{ __typename?: 'Speaker'; nodeId: string }>;
    }>;
};

export type DeleteSpeakerMutationVariables = Exact<{
    nodeId: Scalars['ID'];
}>;

export type DeleteSpeakerMutation = {
    __typename?: 'Mutation';
    deleteSpeakerByNodeId?: Maybe<{ __typename?: 'DeleteSpeakerPayload'; deletedSpeakerNodeId?: Maybe<string> }>;
};

export type GetCourseGroupsForTableQueryVariables = Exact<{ [key: string]: never }>;

export type GetCourseGroupsForTableQuery = {
    __typename?: 'Query';
    courseGroups?: Maybe<{
        __typename?: 'CourseGroupsConnection';
        nodes: Array<{
            __typename?: 'CourseGroup';
            title?: Maybe<string>;
            archived?: Maybe<boolean>;
            nodeId: string;
            courses: { __typename?: 'CoursesConnection'; totalCount: number };
        }>;
    }>;
};

export type GetCourseGroupDetailsQueryVariables = Exact<{
    nodeId: Scalars['ID'];
}>;

export type GetCourseGroupDetailsQuery = {
    __typename?: 'Query';
    courseGroupByNodeId?: Maybe<
        {
            __typename?: 'CourseGroup';
            nodeId: string;
            courses: { __typename?: 'CoursesConnection'; totalCount: number };
            courseGroupMenu?: Maybe<{ __typename?: 'CourseGroupMenu'; shortcut?: Maybe<string> }>;
        } & CourseGroupDetailsFieldsFragment
    >;
};

export type CourseGroupDetailsFieldsFragment = {
    __typename?: 'CourseGroup';
    title?: Maybe<string>;
    description?: Maybe<string>;
    topic?: Maybe<string>;
    content?: Maybe<string>;
    externalUrl?: Maybe<string>;
    archived?: Maybe<boolean>;
    notes?: Maybe<string>;
};

export type CourseGroupDetailsFieldsWithNodeIdFragment = {
    __typename?: 'CourseGroup';
    nodeId: string;
} & CourseGroupDetailsFieldsFragment;

export type CourseGroupDetailsFieldsCoursesSchedulesFragment = {
    __typename?: 'CourseGroup';
    courses: {
        __typename?: 'CoursesConnection';
        aggregates?: Maybe<{
            __typename?: 'CourseAggregates';
            sum?: Maybe<{ __typename?: 'CourseSumAggregates'; plannedScheduleCount?: Maybe<any> }>;
        }>;
    };
};

export type UpdateCourseGroupDetailsMutationVariables = Exact<{
    nodeId: Scalars['ID'];
    courseGroupPatch: CourseGroupPatch;
}>;

export type UpdateCourseGroupDetailsMutation = {
    __typename?: 'Mutation';
    updateCourseGroupByNodeId?: Maybe<{
        __typename?: 'UpdateCourseGroupPayload';
        courseGroup?: Maybe<{ __typename?: 'CourseGroup'; nodeId: string } & CourseGroupDetailsFieldsFragment>;
    }>;
};

export type CreateCourseGroupMutationVariables = Exact<{
    courseGroupInput: CreateCourseGroupInput;
}>;

export type CreateCourseGroupMutation = {
    __typename?: 'Mutation';
    createCourseGroup?: Maybe<{
        __typename?: 'CreateCourseGroupPayload';
        courseGroup?: Maybe<{ __typename?: 'CourseGroup'; nodeId: string }>;
    }>;
};

export type DeleteCourseGroupMutationVariables = Exact<{
    nodeId: Scalars['ID'];
}>;

export type DeleteCourseGroupMutation = {
    __typename?: 'Mutation';
    deleteCourseGroupByNodeId?: Maybe<{
        __typename?: 'DeleteCourseGroupPayload';
        deletedCourseGroupNodeId?: Maybe<string>;
    }>;
};

export type SpeakerNameFragment = { __typename?: 'Speaker'; fullName?: Maybe<string> };

export type EventCalendarFragment = {
    __typename?: 'CourseSchedule';
    id: number;
    startDate: any;
    endDate: any;
    seats?: Maybe<number>;
    freeSeats?: Maybe<any>;
    status?: Maybe<CourseScheduleStatus>;
    courseName?: Maybe<string>;
    resolvedSpeakers: {
        __typename?: 'SpeakersConnection';
        nodes: Array<{ __typename?: 'Speaker' } & SpeakerNameFragment>;
    };
    course?: Maybe<{ __typename?: 'Course'; nodeId: string }>;
};

export type GetDashboardStatisticsQueryVariables = Exact<{ [key: string]: never }>;

export type GetDashboardStatisticsQuery = {
    __typename?: 'Query';
    participants?: Maybe<{ __typename?: 'ParticipantsConnection'; totalCount: number }>;
    courses?: Maybe<{ __typename?: 'CoursesConnection'; totalCount: number }>;
    allbookings?: Maybe<{ __typename?: 'BookingsConnection'; totalCount: number }>;
    speakers?: Maybe<{ __typename?: 'SpeakersConnection'; totalCount: number }>;
    locations?: Maybe<{ __typename?: 'LocationsConnection'; totalCount: number }>;
    unreadbookings?: Maybe<{ __typename?: 'BookingsConnection'; totalCount: number }>;
    courseSchedules?: Maybe<{
        __typename?: 'CourseSchedulesConnection';
        nodes: Array<{ __typename?: 'CourseSchedule' } & EventCalendarFragment>;
    }>;
    educationClassSchedules?: Maybe<{
        __typename?: 'EducationClassSchedulesConnection';
        nodes: Array<{ __typename?: 'EducationClassSchedule' } & EducationClassEventFragment>;
    }>;
};

export type GetCoursesForTableQueryVariables = Exact<{ [key: string]: never }>;

export type GetCoursesForTableQuery = {
    __typename?: 'Query';
    newCourses?: Maybe<{
        __typename?: 'CoursesConnection';
        nodes: Array<{ __typename?: 'Course' } & CourseTableFieldsFragment>;
    }>;
    oldCourses?: Maybe<{
        __typename?: 'CoursesConnection';
        nodes: Array<{ __typename?: 'Course' } & CourseTableFieldsFragment>;
    }>;
};

export type CourseTableFieldsFragment = {
    __typename?: 'Course';
    nodeId: string;
    name?: Maybe<string>;
    nextDate?: Maybe<any>;
    lastDate?: Maybe<any>;
    archived?: Maybe<boolean>;
    fullName?: Maybe<string>;
    fullNameWithMenu?: Maybe<string>;
    nextSchedule?: Maybe<{ __typename?: 'CourseSchedule' } & CourseScheduleFieldsFragment>;
    lastSchedule?: Maybe<{ __typename?: 'CourseSchedule' } & CourseScheduleFieldsFragment>;
    defaultLocation?: Maybe<{ __typename?: 'Location' } & PublicCourseLocationFragment>;
    courseGroup?: Maybe<{
        __typename?: 'CourseGroup';
        title?: Maybe<string>;
        courseGroupMenu?: Maybe<{ __typename?: 'CourseGroupMenu'; title?: Maybe<string> }>;
    }>;
};

export type CourseScheduleFieldsFragment = {
    __typename?: 'CourseSchedule';
    id: number;
    nodeId: string;
    freeSeats?: Maybe<any>;
    status?: Maybe<CourseScheduleStatus>;
    dateSchedule?: Maybe<string>;
    seats?: Maybe<number>;
    price?: Maybe<number>;
    daysAndUnits?: Maybe<string>;
    timetable?: Maybe<string>;
    courseName?: Maybe<string>;
    emptyLines?: Maybe<number>;
    emailList?: Maybe<string>;
    comment?: Maybe<string>;
    units?: Maybe<number>;
    duration?: Maybe<number>;
    startDate: any;
    dateScheduleSortable?: Maybe<string>;
    cancelled?: Maybe<boolean>;
    resolvedTimetable?: Maybe<string>;
    reminderSentAt?: Maybe<any>;
    location?: Maybe<{ __typename?: 'Location' } & PublicCourseLocationFragment>;
    speakersByCourseScheduleId: {
        __typename?: 'SpeakersConnection';
        nodes: Array<{ __typename?: 'Speaker' } & SpeakerDetailsFieldsFragment>;
    };
    resolvedSpeakers: {
        __typename?: 'SpeakersConnection';
        nodes: Array<{ __typename?: 'Speaker' } & SpeakerDetailsFieldsFragment>;
    };
    resolvedLocation?: Maybe<{ __typename?: 'Location' } & PublicCourseLocationFragment>;
};

export type CourseParticipantOverviewFieldsFragment = {
    __typename?: 'Participant';
    fullNameWithSalutation?: Maybe<string>;
    address?: Maybe<string>;
    mobileOrPhone?: Maybe<string>;
    salutationEmail?: Maybe<string>;
};

export type CourseBookingOverviewFieldsFragment = {
    __typename?: 'Booking';
    nodeId: string;
    paymentReceived?: Maybe<boolean>;
    passed?: Maybe<boolean>;
    registrationRegistered?: Maybe<boolean>;
    missingHours: number;
    useForCertification: boolean;
    participant?: Maybe<{ __typename?: 'Participant' } & CourseParticipantOverviewFieldsFragment>;
};

export type CourseDetailFieldsFragment = {
    __typename?: 'Course';
    comment?: Maybe<string>;
    defaultLocationId?: Maybe<number>;
    courseGroupId?: Maybe<number>;
    description?: Maybe<string>;
    details?: Maybe<string>;
    nextDate?: Maybe<any>;
    lastDate?: Maybe<any>;
    defaultTimetable?: Maybe<string>;
    defaultSeats?: Maybe<number>;
    defaultUnits?: Maybe<number>;
    defaultDuration?: Maybe<number>;
    defaultPrice?: Maybe<number>;
    name?: Maybe<string>;
    nodeId: string;
    id: number;
    confirmationContent?: Maybe<string>;
    courseSchedules: {
        __typename?: 'CourseSchedulesConnection';
        nodes: Array<
            {
                __typename?: 'CourseSchedule';
                speakersByCourseScheduleId: {
                    __typename?: 'SpeakersConnection';
                    nodes: Array<{ __typename?: 'Speaker'; fullName?: Maybe<string> }>;
                };
                location?: Maybe<{ __typename?: 'Location'; title?: Maybe<string> }>;
                bookings: {
                    __typename?: 'BookingsConnection';
                    nodes: Array<{ __typename?: 'Booking' } & CourseBookingOverviewFieldsFragment>;
                };
            } & CourseScheduleFieldsFragment
        >;
    };
    courseGroup?: Maybe<{
        __typename?: 'CourseGroup';
        title?: Maybe<string>;
        courseGroupMenu?: Maybe<{ __typename?: 'CourseGroupMenu'; title?: Maybe<string> }>;
    }>;
};

export type GetCourseDetailsQueryVariables = Exact<{
    nodeId: Scalars['ID'];
}>;

export type GetCourseDetailsQuery = {
    __typename?: 'Query';
    courseByNodeId?: Maybe<{ __typename?: 'Course' } & CourseDetailFieldsFragment>;
};

export type GetActiveSpeakersQueryVariables = Exact<{ [key: string]: never }>;

export type GetActiveSpeakersQuery = {
    __typename?: 'Query';
    speakers?: Maybe<{
        __typename?: 'SpeakersConnection';
        nodes: Array<{ __typename?: 'Speaker'; nodeId: string; fullName?: Maybe<string> }>;
    }>;
};

export type GetActiveLocationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetActiveLocationsQuery = {
    __typename?: 'Query';
    locations?: Maybe<{
        __typename?: 'LocationsConnection';
        nodes: Array<{ __typename?: 'Location'; nodeId: string; title?: Maybe<string> }>;
    }>;
};

export type GetActiveCourseGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type GetActiveCourseGroupsQuery = {
    __typename?: 'Query';
    courseGroups?: Maybe<{
        __typename?: 'CourseGroupsConnection';
        nodes: Array<{ __typename?: 'CourseGroup'; nodeId: string; title?: Maybe<string> }>;
    }>;
};

export type GetLocationsForTableQueryVariables = Exact<{ [key: string]: never }>;

export type GetLocationsForTableQuery = {
    __typename?: 'Query';
    locations?: Maybe<{
        __typename?: 'LocationsConnection';
        nodes: Array<{
            __typename?: 'Location';
            title?: Maybe<string>;
            address?: Maybe<string>;
            archived?: Maybe<boolean>;
            nodeId: string;
        }>;
    }>;
};

export type GetLocationDetailsQueryVariables = Exact<{
    nodeId: Scalars['ID'];
}>;

export type GetLocationDetailsQuery = {
    __typename?: 'Query';
    locationByNodeId?: Maybe<
        {
            __typename?: 'Location';
            courseSchedules: { __typename?: 'CourseSchedulesConnection'; totalCount: number };
            coursesByDefaultLocationId: { __typename?: 'CoursesConnection'; totalCount: number };
        } & LocationDetailsFieldsFragment
    >;
};

export type LocationDetailsFieldsFragment = {
    __typename?: 'Location';
    street?: Maybe<string>;
    city?: Maybe<string>;
    postcode?: Maybe<string>;
    country: string;
    remarks?: Maybe<string>;
    title?: Maybe<string>;
    archived?: Maybe<boolean>;
};

export type UpdateLocationDetailsMutationVariables = Exact<{
    nodeId: Scalars['ID'];
    locationPatch: LocationPatch;
}>;

export type UpdateLocationDetailsMutation = {
    __typename?: 'Mutation';
    updateLocationByNodeId?: Maybe<{ __typename?: 'UpdateLocationPayload'; clientMutationId?: Maybe<string> }>;
};

export type CreateLocationMutationVariables = Exact<{
    locationInput: CreateLocationInput;
}>;

export type CreateLocationMutation = {
    __typename?: 'Mutation';
    createLocation?: Maybe<{
        __typename?: 'CreateLocationPayload';
        location?: Maybe<{ __typename?: 'Location'; nodeId: string }>;
    }>;
};

export type DeleteLocationMutationVariables = Exact<{
    nodeId: Scalars['ID'];
}>;

export type DeleteLocationMutation = {
    __typename?: 'Mutation';
    deleteLocationByNodeId?: Maybe<{ __typename?: 'DeleteLocationPayload'; deletedLocationNodeId?: Maybe<string> }>;
};

export type GetPublicCoursesForShortcutQueryVariables = Exact<{
    shortcut?: Maybe<Scalars['String']>;
}>;

export type GetPublicCoursesForShortcutQuery = {
    __typename?: 'Query';
    courseGroupMenus?: Maybe<{
        __typename?: 'CourseGroupMenusConnection';
        nodes: Array<
            {
                __typename?: 'CourseGroupMenu';
                courseGroups: {
                    __typename?: 'CourseGroupsConnection';
                    nodes: Array<
                        { __typename?: 'CourseGroup' } & CourseGroupDetailsFieldsWithNodeIdFragment &
                            CourseGroupDetailsFieldsCoursesSchedulesFragment
                    >;
                };
            } & CourseGroupMenuDetailsFieldsFragment
        >;
    }>;
};

export type GetPublicCourseMenuQueryVariables = Exact<{ [key: string]: never }>;

export type GetPublicCourseMenuQuery = {
    __typename?: 'Query';
    courseGroupMenus?: Maybe<{
        __typename?: 'CourseGroupMenusConnection';
        nodes: Array<{ __typename?: 'CourseGroupMenu' } & CourseGroupMenuDetailsFieldsFragment>;
    }>;
};

export type GetPublicCourseDetailsForCourseQueryVariables = Exact<{
    courseNodeId: Scalars['ID'];
}>;

export type GetPublicCourseDetailsForCourseQuery = {
    __typename?: 'Query';
    courseGroupByNodeId?: Maybe<
        {
            __typename?: 'CourseGroup';
            nodeId: string;
            courses: {
                __typename?: 'CoursesConnection';
                totalCount: number;
                nodes: Array<{ __typename?: 'Course' } & PublicCoursesDetailFieldsFragment>;
            };
            courseGroupMenu?: Maybe<{ __typename?: 'CourseGroupMenu'; shortcut?: Maybe<string> }>;
        } & CourseGroupDetailsFieldsFragment
    >;
};

export type GetPublicCourseSignupQueryVariables = Exact<{
    courseScheduleId: Scalars['ID'];
}>;

export type GetPublicCourseSignupQuery = {
    __typename?: 'Query';
    courseScheduleByNodeId?: Maybe<{ __typename?: 'CourseSchedule'; nodeId: string } & PublicCourseSignupFragment>;
};

export type PublicCourseSignupFragment = {
    __typename?: 'CourseSchedule';
    course?: Maybe<
        {
            __typename?: 'Course';
            name?: Maybe<string>;
            courseGroup?: Maybe<{ __typename?: 'CourseGroup' } & CourseGroupDetailsFieldsFragment>;
        } & PublicCourseTableFieldsFragment
    >;
} & CourseScheduleFieldsFragment;

export type PublicCoursesDetailFieldsFragment = {
    __typename?: 'Course';
    name?: Maybe<string>;
    archived?: Maybe<boolean>;
    description?: Maybe<string>;
    defaultLocation?: Maybe<{ __typename?: 'Location' } & PublicCourseLocationFragment>;
    courseSchedules: {
        __typename?: 'CourseSchedulesConnection';
        nodes: Array<{ __typename?: 'CourseSchedule' } & PublicCourseScheduleFieldsFragment>;
    };
};

export type PublicCourseScheduleFieldsFragment = {
    __typename?: 'CourseSchedule';
    nodeId: string;
    id: number;
    dateSchedule?: Maybe<string>;
    daysAndUnits?: Maybe<string>;
    duration?: Maybe<number>;
    endDate: any;
    freeSeats?: Maybe<any>;
    price?: Maybe<number>;
    startDate: any;
    status?: Maybe<CourseScheduleStatus>;
    timetable?: Maybe<string>;
    units?: Maybe<number>;
    comment?: Maybe<string>;
};

export type PublicCourseLocationFragment = {
    __typename?: 'Location';
    nodeId: string;
    title?: Maybe<string>;
    address?: Maybe<string>;
    country: string;
};

export type PublicCourseTableFieldsFragment = {
    __typename?: 'Course';
    id: number;
    nodeId: string;
    name?: Maybe<string>;
    defaultTimetable?: Maybe<string>;
    comment?: Maybe<string>;
    description?: Maybe<string>;
    nextDate?: Maybe<any>;
    lastDate?: Maybe<any>;
    archived?: Maybe<boolean>;
    courseSchedules: {
        __typename?: 'CourseSchedulesConnection';
        nodes: Array<{ __typename?: 'CourseSchedule' } & CourseScheduleFieldsFragment>;
    };
    defaultLocation?: Maybe<{ __typename?: 'Location' } & PublicCourseLocationFragment>;
    courseDefaultSpeakers: {
        __typename?: 'CourseDefaultSpeakersConnection';
        nodes: Array<{
            __typename?: 'CourseDefaultSpeaker';
            speaker?: Maybe<{ __typename?: 'Speaker' } & SpeakerDetailsFieldsFragment>;
        }>;
    };
};

export type GetCourseGroupMenusForTableQueryVariables = Exact<{ [key: string]: never }>;

export type GetCourseGroupMenusForTableQuery = {
    __typename?: 'Query';
    courseGroupMenus?: Maybe<{
        __typename?: 'CourseGroupMenusConnection';
        nodes: Array<{
            __typename?: 'CourseGroupMenu';
            title?: Maybe<string>;
            titleOverview?: Maybe<string>;
            archived?: Maybe<boolean>;
            nodeId: string;
            courseGroups: { __typename?: 'CourseGroupsConnection'; totalCount: number };
        }>;
    }>;
};

export type GetCourseGroupMenuDetailsQueryVariables = Exact<{
    nodeId: Scalars['ID'];
}>;

export type GetCourseGroupMenuDetailsQuery = {
    __typename?: 'Query';
    courseGroupMenuByNodeId?: Maybe<
        {
            __typename?: 'CourseGroupMenu';
            courseGroups: { __typename?: 'CourseGroupsConnection'; totalCount: number };
        } & CourseGroupMenuDetailsFieldsFragment
    >;
};

export type CourseGroupMenuDetailsFieldsFragment = {
    __typename?: 'CourseGroupMenu';
    shortcut?: Maybe<string>;
    titleOverview?: Maybe<string>;
    descriptionOverview?: Maybe<string>;
    imageurlOverview?: Maybe<string>;
    title?: Maybe<string>;
    description?: Maybe<string>;
    imageurl?: Maybe<string>;
    archived?: Maybe<boolean>;
};

export type UpdateCourseGroupMenuDetailsMutationVariables = Exact<{
    nodeId: Scalars['ID'];
    courseGroupMenuPatch: CourseGroupMenuPatch;
}>;

export type UpdateCourseGroupMenuDetailsMutation = {
    __typename?: 'Mutation';
    updateCourseGroupMenuByNodeId?: Maybe<{
        __typename?: 'UpdateCourseGroupMenuPayload';
        clientMutationId?: Maybe<string>;
    }>;
};

export type CreateCourseGroupMenuMutationVariables = Exact<{
    courseGroupMenuInput: CreateCourseGroupMenuInput;
}>;

export type CreateCourseGroupMenuMutation = {
    __typename?: 'Mutation';
    createCourseGroupMenu?: Maybe<{
        __typename?: 'CreateCourseGroupMenuPayload';
        courseGroupMenu?: Maybe<{ __typename?: 'CourseGroupMenu'; nodeId: string }>;
    }>;
};

export type DeleteCourseGroupMenuMutationVariables = Exact<{
    nodeId: Scalars['ID'];
}>;

export type DeleteCourseGroupMenuMutation = {
    __typename?: 'Mutation';
    deleteCourseGroupMenuByNodeId?: Maybe<{
        __typename?: 'DeleteCourseGroupMenuPayload';
        deletedCourseGroupMenuNodeId?: Maybe<string>;
    }>;
};

export type SendEmailWelcomeMutationVariables = Exact<{
    recipient: Scalars['String'];
    course_id: Scalars['Int'];
    course_schedule_node_id: Scalars['String'];
    already_registered: Scalars['Boolean'];
}>;

export type SendEmailWelcomeMutation = {
    __typename?: 'Mutation';
    sendEmailWelcome?: Maybe<{ __typename?: 'SendEmailWelcomePayload'; string?: Maybe<string> }>;
};

export type SendEmailSignupConfirmationMutationVariables = Exact<{
    booking_id: Scalars['Int'];
    template_name: Scalars['String'];
}>;

export type SendEmailSignupConfirmationMutation = {
    __typename?: 'Mutation';
    sendEmailSignupConfirmation?: Maybe<{ __typename?: 'SendEmailSignupConfirmationPayload'; string?: Maybe<string> }>;
};

export type CreateParticipantMutationVariables = Exact<{
    participantInput: CreateParticipantInput;
}>;

export type CreateParticipantMutation = {
    __typename?: 'Mutation';
    createParticipant?: Maybe<{
        __typename?: 'CreateParticipantPayload';
        participant?: Maybe<{ __typename?: 'Participant'; nodeId: string; id: number }>;
    }>;
};

export type CreateBookingMutationVariables = Exact<{
    bookingInput: CreateBookingInput;
}>;

export type CreateBookingMutation = {
    __typename?: 'Mutation';
    createBooking?: Maybe<{
        __typename?: 'CreateBookingPayload';
        booking?: Maybe<{ __typename?: 'Booking'; nodeId: string; id: number }>;
    }>;
};

export type DeleteCodeMutationVariables = Exact<{
    code: Scalars['UUID'];
}>;

export type DeleteCodeMutation = {
    __typename?: 'Mutation';
    deleteEmailCodeByCode?: Maybe<{ __typename?: 'DeleteEmailCodePayload'; clientMutationId?: Maybe<string> }>;
};

export type GetEmailCodeInfoQueryVariables = Exact<{
    code: Scalars['UUID'];
}>;

export type GetEmailCodeInfoQuery = {
    __typename?: 'Query';
    emailCodes?: Maybe<{
        __typename?: 'EmailCodesConnection';
        nodes: Array<{
            __typename?: 'EmailCode';
            courseScheduleNodeId: string;
            email: string;
            participant?: Maybe<{ __typename?: 'Participant' } & ParticipantSignUpFieldsFragment>;
        }>;
    }>;
};

export type ParticipantSignUpFieldsFragment = {
    __typename?: 'Participant';
    birthday?: Maybe<any>;
    city?: Maybe<string>;
    country: string;
    email?: Maybe<string>;
    familyName: string;
    givenName: string;
    id: number;
    nodeId: string;
    invoiceBankIban?: Maybe<string>;
    invoiceBankSwift?: Maybe<string>;
    jobTitle?: Maybe<string>;
    postcode?: Maybe<string>;
    street?: Maybe<string>;
    mobileOrPhone?: Maybe<string>;
    gender?: Maybe<ParticipantGender>;
    newsletter?: Maybe<boolean>;
};

export type CourseScheduleBookableMutationVariables = Exact<{
    recipient: Scalars['String'];
    courseScheduleDbId: Scalars['Int'];
}>;

export type CourseScheduleBookableMutation = {
    __typename?: 'Mutation';
    courseScheduleBookable?: Maybe<{ __typename?: 'CourseScheduleBookablePayload'; boolean?: Maybe<boolean> }>;
};

export type BookingCourseFieldsFragment = {
    __typename?: 'Course';
    nodeId: string;
    id: number;
    name?: Maybe<string>;
    fullName?: Maybe<string>;
    courseGroup?: Maybe<{ __typename?: 'CourseGroup'; title?: Maybe<string> }>;
};

export type BookingLocationFragment = { __typename?: 'Location'; title?: Maybe<string> };

export type BookingSpeakersFragment = { __typename?: 'Speaker'; fullNameWithSalutation?: Maybe<string> };

export type BookingCourseScheduleFieldsFragment = {
    __typename?: 'CourseSchedule';
    nodeId: string;
    id: number;
    priceFormatted?: Maybe<string>;
    dateSchedule?: Maybe<string>;
    cancelled?: Maybe<boolean>;
    status?: Maybe<CourseScheduleStatus>;
    startDate: any;
    dateScheduleSortable?: Maybe<string>;
    reminderSentAt?: Maybe<any>;
    course?: Maybe<{ __typename?: 'Course' } & BookingCourseFieldsFragment>;
};

export type BookingParticipantFragment = {
    __typename?: 'Participant';
    fullName?: Maybe<string>;
    sortableName?: Maybe<string>;
    nodeId: string;
    salutationEmail?: Maybe<string>;
    id: number;
};

export type BookingDetailsFragment = {
    __typename?: 'Booking';
    nodeId: string;
    id: number;
    documentsSend?: Maybe<boolean>;
    state: BookingState;
    passed?: Maybe<boolean>;
    paymentMethod: BookingPaymentMethod;
    invoiceToEmployer?: Maybe<boolean>;
    invoiceAddress?: Maybe<string>;
    invoiceNumber?: Maybe<any>;
    invoiceDate?: Maybe<any>;
    invoiceCompanyName?: Maybe<string>;
    invoiceFamilyName?: Maybe<string>;
    invoiceSalutation?: Maybe<string>;
    invoiceTitle?: Maybe<string>;
    invoiceGivenName?: Maybe<string>;
    invoiceStreet?: Maybe<string>;
    invoicePostcode?: Maybe<string>;
    invoiceCity?: Maybe<string>;
    invoiceCountry?: Maybe<string>;
    invoiceContactPhone?: Maybe<string>;
    invoiceContactEmail?: Maybe<string>;
    invoiceContactUrl?: Maybe<string>;
    invoiceContactInformation?: Maybe<string>;
    registrationRegistered?: Maybe<boolean>;
    registrationDateFormatted?: Maybe<string>;
    paymentReceived?: Maybe<boolean>;
    infos?: Maybe<any>;
    infosSearchable?: Maybe<string>;
    note?: Maybe<string>;
    useForCertification: boolean;
    courseSchedule?: Maybe<{ __typename?: 'CourseSchedule' } & BookingCourseScheduleFieldsFragment>;
    participant?: Maybe<{ __typename?: 'Participant' } & BookingParticipantFragment>;
};

export type UpdateBookingDetailsMutationVariables = Exact<{
    nodeId: Scalars['ID'];
    bookingPatch: BookingPatch;
}>;

export type UpdateBookingDetailsMutation = {
    __typename?: 'Mutation';
    updateBookingByNodeId?: Maybe<{
        __typename?: 'UpdateBookingPayload';
        clientMutationId?: Maybe<string>;
        booking?: Maybe<{ __typename?: 'Booking' } & BookingDetailsFragment>;
    }>;
};

export type CreateParticipantsListMutationVariables = Exact<{
    courseScheduleDbId: Scalars['Int'];
    overrideDescription?: Maybe<Scalars['String']>;
    emptyLines?: Maybe<Scalars['Int']>;
}>;

export type CreateParticipantsListMutation = {
    __typename?: 'Mutation';
    createParticipantsList?: Maybe<{
        __typename?: 'CreateParticipantsListPayload';
        clientMutationId?: Maybe<string>;
        string?: Maybe<string>;
    }>;
};

export type CreateParticipantsSepaXmlMutationVariables = Exact<{
    courseScheduleDbId: Scalars['Int'];
}>;

export type CreateParticipantsSepaXmlMutation = {
    __typename?: 'Mutation';
    createParticipantsSepaXml?: Maybe<{
        __typename?: 'CreateParticipantsSepaXmlPayload';
        clientMutationId?: Maybe<string>;
        string?: Maybe<string>;
    }>;
};

export type UpdatePassedAllParticipantsMutationVariables = Exact<{
    courseScheduleDbId: Scalars['Int'];
}>;

export type UpdatePassedAllParticipantsMutation = {
    __typename?: 'Mutation';
    updatePassedAllParticipants?: Maybe<{
        __typename?: 'UpdatePassedAllParticipantsPayload';
        clientMutationId?: Maybe<string>;
        integer?: Maybe<number>;
    }>;
};

export type UpdateAddAllPassedParticipantsToCertificateMutationVariables = Exact<{
    courseScheduleDbId: Scalars['Int'];
}>;

export type UpdateAddAllPassedParticipantsToCertificateMutation = {
    __typename?: 'Mutation';
    updateAddAllPassedParticipantsToCertificate?: Maybe<{
        __typename?: 'UpdateAddAllPassedParticipantsToCertificatePayload';
        clientMutationId?: Maybe<string>;
        integer?: Maybe<number>;
    }>;
};

export type UpdateCourseDetailsMutationVariables = Exact<{
    nodeId: Scalars['ID'];
    coursePatch: CoursePatch;
}>;

export type UpdateCourseDetailsMutation = {
    __typename?: 'Mutation';
    updateCourseByNodeId?: Maybe<{
        __typename?: 'UpdateCoursePayload';
        clientMutationId?: Maybe<string>;
        course?: Maybe<{ __typename?: 'Course' } & CourseDetailFieldsFragment>;
    }>;
};

export type UpdateCourseScheduleDetailsMutationVariables = Exact<{
    nodeId: Scalars['ID'];
    courseSchedulePatch: CourseSchedulePatch;
}>;

export type UpdateCourseScheduleDetailsMutation = {
    __typename?: 'Mutation';
    updateCourseScheduleByNodeId?: Maybe<{
        __typename?: 'UpdateCourseSchedulePayload';
        clientMutationId?: Maybe<string>;
        courseSchedule?: Maybe<{ __typename?: 'CourseSchedule' } & CourseScheduleFieldsFragment>;
    }>;
};

export type CreateCourseScheduleMutationVariables = Exact<{
    courseScheduleInput: CreateCourseScheduleInput;
}>;

export type CreateCourseScheduleMutation = {
    __typename?: 'Mutation';
    createCourseSchedule?: Maybe<{
        __typename?: 'CreateCourseSchedulePayload';
        courseSchedule?: Maybe<{
            __typename?: 'CourseSchedule';
            nodeId: string;
            dateSchedule?: Maybe<string>;
            id: number;
        }>;
    }>;
};

export type GetPossibleRelocationSchedulesQueryVariables = Exact<{
    relocationCourseId: Scalars['Int'];
    relocationCourseScheduleId: Scalars['Int'];
}>;

export type GetPossibleRelocationSchedulesQuery = {
    __typename?: 'Query';
    courseSchedules?: Maybe<{
        __typename?: 'CourseSchedulesConnection';
        totalCount: number;
        nodes: Array<{ __typename?: 'CourseSchedule' } & RelocationCourseScheduleFieldsFragment>;
    }>;
};

export type RelocationCourseScheduleFieldsFragment = {
    __typename?: 'CourseSchedule';
    status?: Maybe<CourseScheduleStatus>;
    id: number;
    dateSchedule?: Maybe<string>;
};

export type CreateParticipantPassedDocumentsMutationVariables = Exact<{
    courseScheduleDbId: Scalars['Int'];
}>;

export type CreateParticipantPassedDocumentsMutation = {
    __typename?: 'Mutation';
    createParticipantPassedDocuments?: Maybe<{
        __typename?: 'CreateParticipantPassedDocumentsPayload';
        clientMutationId?: Maybe<string>;
        string?: Maybe<string>;
    }>;
};

export type CreateSingleParticipantPassedDocumentMutationVariables = Exact<{
    bookingId: Scalars['Int'];
}>;

export type CreateSingleParticipantPassedDocumentMutation = {
    __typename?: 'Mutation';
    createSingleParticipantPassedDocument?: Maybe<{
        __typename?: 'CreateSingleParticipantPassedDocumentPayload';
        clientMutationId?: Maybe<string>;
        string?: Maybe<string>;
    }>;
};

export type CreateSingleParticipantCertificateDocumentMutationVariables = Exact<{
    participantId: Scalars['Int'];
}>;

export type CreateSingleParticipantCertificateDocumentMutation = {
    __typename?: 'Mutation';
    createSingleParticipantCertificateDocument?: Maybe<{
        __typename?: 'CreateSingleParticipantCertificateDocumentPayload';
        clientMutationId?: Maybe<string>;
        string?: Maybe<string>;
    }>;
};

export type GetPassedCountQueryVariables = Exact<{
    courseScheduleDbId: Scalars['Int'];
}>;

export type GetPassedCountQuery = {
    __typename?: 'Query';
    courseSchedule?: Maybe<{
        __typename?: 'CourseSchedule';
        id: number;
        bookings: { __typename?: 'BookingsConnection'; totalCount: number };
    }>;
};

export type BookingsForCourseScheduleQueryVariables = Exact<{
    courseScheduleId: Scalars['Int'];
}>;

export type BookingsForCourseScheduleQuery = {
    __typename?: 'Query';
    bookings?: Maybe<{
        __typename?: 'BookingsConnection';
        totalCount: number;
        nodes: Array<{ __typename?: 'Booking'; id: number }>;
    }>;
};

export type CreateBookingForParticipantMutationVariables = Exact<{
    selectedCourseScheduleId: Scalars['Int'];
    selectParticipantId: Scalars['Int'];
}>;

export type CreateBookingForParticipantMutation = {
    __typename?: 'Mutation';
    createBookingForParticipant?: Maybe<{
        __typename?: 'CreateBookingForParticipantPayload';
        clientMutationId?: Maybe<string>;
        integer?: Maybe<number>;
    }>;
};

export type GetAllCoursesForSelectionQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllCoursesForSelectionQuery = {
    __typename?: 'Query';
    courses?: Maybe<{
        __typename?: 'CoursesConnection';
        totalCount: number;
        nodes: Array<{
            __typename?: 'Course';
            id: number;
            name?: Maybe<string>;
            fullName?: Maybe<string>;
            fullNameNewline?: Maybe<string>;
            courseGroup?: Maybe<{ __typename?: 'CourseGroup'; title?: Maybe<string> }>;
            courseSchedules: {
                __typename?: 'CourseSchedulesConnection';
                nodes: Array<{
                    __typename?: 'CourseSchedule';
                    id: number;
                    dateSchedule?: Maybe<string>;
                    dateScheduleSortable?: Maybe<string>;
                    startDate: any;
                    comment?: Maybe<string>;
                    seats?: Maybe<number>;
                    freeSeats?: Maybe<any>;
                    status?: Maybe<CourseScheduleStatus>;
                }>;
            };
        }>;
    }>;
};

export type UpdateCourseScheduleSpeakersMutationVariables = Exact<{
    selectedCourseScheduleId: Scalars['Int'];
    selectedSpeakerIds: Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>;
}>;

export type UpdateCourseScheduleSpeakersMutation = {
    __typename?: 'Mutation';
    updateCourseScheduleSpeakers?: Maybe<{
        __typename?: 'UpdateCourseScheduleSpeakersPayload';
        clientMutationId?: Maybe<string>;
        boolean?: Maybe<boolean>;
    }>;
};

export type UpdateBookingsBankDataMutationVariables = Exact<{
    selectedParticipantId: Scalars['Int'];
}>;

export type UpdateBookingsBankDataMutation = {
    __typename?: 'Mutation';
    updateBookingsBankData?: Maybe<{
        __typename?: 'UpdateBookingsBankDataPayload';
        clientMutationId?: Maybe<string>;
        boolean?: Maybe<boolean>;
    }>;
};

export type SettingsQueryVariables = Exact<{ [key: string]: never }>;

export type SettingsQuery = {
    __typename?: 'Query';
    settings?: Maybe<{
        __typename?: 'SettingsConnection';
        nodes: Array<{ __typename?: 'Setting'; nodeId: string; id: number; key: string; value: any }>;
    }>;
};

export type CombineParticipantsMutationVariables = Exact<{
    oldParticipantId: Scalars['Int'];
    newParticipantId: Scalars['Int'];
}>;

export type CombineParticipantsMutation = {
    __typename?: 'Mutation';
    combineParticipants?: Maybe<{
        __typename?: 'CombineParticipantsPayload';
        clientMutationId?: Maybe<string>;
        integer?: Maybe<number>;
    }>;
};

export type GetEducationsForTableQueryVariables = Exact<{ [key: string]: never }>;

export type GetEducationsForTableQuery = {
    __typename?: 'Query';
    educations?: Maybe<{
        __typename?: 'EducationsConnection';
        nodes: Array<{
            __typename?: 'Education';
            name?: Maybe<string>;
            nodeId: string;
            id: number;
            educationClasses: { __typename?: 'EducationClassesConnection'; totalCount: number };
        }>;
    }>;
};

export type EducationDetailsFieldsFragment = { __typename?: 'Education'; name?: Maybe<string> };

export type UpdateEducationDetailsMutationVariables = Exact<{
    nodeId: Scalars['ID'];
    educationPatch: EducationPatch;
}>;

export type UpdateEducationDetailsMutation = {
    __typename?: 'Mutation';
    updateEducationByNodeId?: Maybe<{
        __typename?: 'UpdateEducationPayload';
        education?: Maybe<{ __typename?: 'Education'; nodeId: string } & EducationDetailsFieldsFragment>;
    }>;
};

export type CreateEducationMutationVariables = Exact<{
    educationInput: CreateEducationInput;
}>;

export type CreateEducationMutation = {
    __typename?: 'Mutation';
    createEducation?: Maybe<{
        __typename?: 'CreateEducationPayload';
        education?: Maybe<{ __typename?: 'Education'; nodeId: string }>;
    }>;
};

export type GetEducationDetailsQueryVariables = Exact<{
    nodeId: Scalars['ID'];
}>;

export type GetEducationDetailsQuery = {
    __typename?: 'Query';
    educationByNodeId?: Maybe<
        {
            __typename?: 'Education';
            nodeId: string;
            id: number;
            educationClasses: { __typename?: 'EducationClassesConnection'; totalCount: number };
        } & EducationDetailsFieldsFragment
    >;
};

export type DeleteEducationMutationVariables = Exact<{
    nodeId: Scalars['ID'];
}>;

export type DeleteEducationMutation = {
    __typename?: 'Mutation';
    deleteEducationByNodeId?: Maybe<{ __typename?: 'DeleteEducationPayload'; deletedEducationNodeId?: Maybe<string> }>;
};

export type EducationClassEventFragment = {
    __typename?: 'EducationClassSchedule';
    nodeId: string;
    startDate: any;
    endDate: any;
    courseYear: EducationClassYear;
    educationClassScheduleEntries: {
        __typename?: 'EducationClassScheduleEntriesConnection';
        nodes: Array<{ __typename?: 'EducationClassScheduleEntry' } & EducationClassScheduleEntryFieldsFragment>;
    };
    educationClass?: Maybe<{
        __typename?: 'EducationClass';
        nodeId: string;
        name: string;
        education?: Maybe<{ __typename?: 'Education'; name?: Maybe<string> }>;
    }>;
};

export type GetEducationClassesForTableQueryVariables = Exact<{
    educationId: Scalars['Int'];
}>;

export type GetEducationClassesForTableQuery = {
    __typename?: 'Query';
    educations?: Maybe<{
        __typename?: 'EducationsConnection';
        nodes: Array<{
            __typename?: 'Education';
            name?: Maybe<string>;
            educationClasses: {
                __typename?: 'EducationClassesConnection';
                nodes: Array<{
                    __typename?: 'EducationClass';
                    name: string;
                    nodeId: string;
                    educationClassSchedules: { __typename?: 'EducationClassSchedulesConnection'; totalCount: number };
                }>;
            };
        }>;
    }>;
};

export type EducationClassDetailsFieldsFragment = { __typename?: 'EducationClass'; name: string };

export type UpdateEducationClassDetailsMutationVariables = Exact<{
    nodeId: Scalars['ID'];
    educationClassPatch: EducationClassPatch;
}>;

export type UpdateEducationClassDetailsMutation = {
    __typename?: 'Mutation';
    updateEducationClassByNodeId?: Maybe<{
        __typename?: 'UpdateEducationClassPayload';
        educationClass?: Maybe<{ __typename?: 'EducationClass'; nodeId: string } & EducationClassDetailsFieldsFragment>;
    }>;
};

export type CreateEducationClassMutationVariables = Exact<{
    educationClassInput: CreateEducationClassInput;
}>;

export type CreateEducationClassMutation = {
    __typename?: 'Mutation';
    createEducationClass?: Maybe<{
        __typename?: 'CreateEducationClassPayload';
        education?: Maybe<{ __typename?: 'Education'; nodeId: string }>;
    }>;
};

export type GetEducationClassDetailsQueryVariables = Exact<{
    nodeId: Scalars['ID'];
}>;

export type GetEducationClassDetailsQuery = {
    __typename?: 'Query';
    educationClassByNodeId?: Maybe<
        {
            __typename?: 'EducationClass';
            nodeId: string;
            educationClassSchedules: { __typename?: 'EducationClassSchedulesConnection'; totalCount: number };
        } & EducationClassDetailsFieldsFragment
    >;
};

export type DeleteEducationClassMutationVariables = Exact<{
    nodeId: Scalars['ID'];
}>;

export type DeleteEducationClassMutation = {
    __typename?: 'Mutation';
    deleteEducationClassByNodeId?: Maybe<{
        __typename?: 'DeleteEducationClassPayload';
        deletedEducationClassNodeId?: Maybe<string>;
    }>;
};

export type EducationClassScheduleEntryFieldsFragment = {
    __typename?: 'EducationClassScheduleEntry';
    nodeId: string;
    id: number;
    date: any;
    topic?: Maybe<string>;
    speaker?: Maybe<{ __typename?: 'Speaker' } & SpeakerDetailsFieldsFragment>;
};

export type EducationClassScheduleFieldsFragment = {
    __typename?: 'EducationClassSchedule';
    nodeId: string;
    id: number;
    startDate: any;
    endDate: any;
    dateSchedule?: Maybe<string>;
    timetable?: Maybe<string>;
    locationId?: Maybe<number>;
    courseYear: EducationClassYear;
    educationClassId?: Maybe<number>;
    location?: Maybe<{ __typename?: 'Location' } & LocationDetailsFieldsFragment>;
    educationClass?: Maybe<{
        __typename?: 'EducationClass';
        id: number;
        nodeId: string;
        name: string;
        education?: Maybe<{ __typename?: 'Education'; name?: Maybe<string> }>;
    }>;
    educationClassScheduleEntries: {
        __typename?: 'EducationClassScheduleEntriesConnection';
        nodes: Array<{ __typename?: 'EducationClassScheduleEntry' } & EducationClassScheduleEntryFieldsFragment>;
    };
};

export type GetEducationClassScheduleQueryVariables = Exact<{
    educationClassNodeId: Scalars['ID'];
}>;

export type GetEducationClassScheduleQuery = {
    __typename?: 'Query';
    educationClassByNodeId?: Maybe<{
        __typename?: 'EducationClass';
        name: string;
        nodeId: string;
        id: number;
        education?: Maybe<{ __typename?: 'Education'; name?: Maybe<string> }>;
        educationClassSchedules: {
            __typename?: 'EducationClassSchedulesConnection';
            nodes: Array<{ __typename?: 'EducationClassSchedule' } & EducationClassScheduleFieldsFragment>;
        };
    }>;
};

export type UpdateEducationClassScheduleMutationVariables = Exact<{
    nodeId: Scalars['ID'];
    educationClassSchedulePatch: EducationClassSchedulePatch;
}>;

export type UpdateEducationClassScheduleMutation = {
    __typename?: 'Mutation';
    updateEducationClassScheduleByNodeId?: Maybe<{
        __typename?: 'UpdateEducationClassSchedulePayload';
        clientMutationId?: Maybe<string>;
        educationClassSchedule?: Maybe<
            { __typename?: 'EducationClassSchedule' } & EducationClassScheduleFieldsFragment
        >;
    }>;
};

export type CreateEducationClassScheduleMutationVariables = Exact<{
    educationClassScheduleInput: CreateEducationClassScheduleInput;
}>;

export type CreateEducationClassScheduleMutation = {
    __typename?: 'Mutation';
    createEducationClassSchedule?: Maybe<{
        __typename?: 'CreateEducationClassSchedulePayload';
        educationClassSchedule?: Maybe<
            { __typename?: 'EducationClassSchedule' } & EducationClassScheduleFieldsFragment
        >;
    }>;
};

export type DeleteEducationClassScheduleMutationVariables = Exact<{
    nodeId: Scalars['ID'];
}>;

export type DeleteEducationClassScheduleMutation = {
    __typename?: 'Mutation';
    deleteEducationClassScheduleByNodeId?: Maybe<{
        __typename?: 'DeleteEducationClassSchedulePayload';
        deletedEducationClassScheduleNodeId?: Maybe<string>;
    }>;
};

export type UpdateEducationClassScheduleEntryMutationVariables = Exact<{
    nodeId: Scalars['ID'];
    educationClassScheduleEntryPatch: EducationClassScheduleEntryPatch;
}>;

export type UpdateEducationClassScheduleEntryMutation = {
    __typename?: 'Mutation';
    updateEducationClassScheduleEntryByNodeId?: Maybe<{
        __typename?: 'UpdateEducationClassScheduleEntryPayload';
        clientMutationId?: Maybe<string>;
        educationClassScheduleEntry?: Maybe<
            { __typename?: 'EducationClassScheduleEntry' } & EducationClassScheduleEntryFieldsFragment
        >;
    }>;
};

export type CreateEducationClassScheduleEntryMutationVariables = Exact<{
    educationClassScheduleEntryInput: CreateEducationClassScheduleEntryInput;
}>;

export type CreateEducationClassScheduleEntryMutation = {
    __typename?: 'Mutation';
    createEducationClassScheduleEntry?: Maybe<{
        __typename?: 'CreateEducationClassScheduleEntryPayload';
        educationClassScheduleEntry?: Maybe<
            { __typename?: 'EducationClassScheduleEntry' } & EducationClassScheduleEntryFieldsFragment
        >;
    }>;
};

export type DeleteEducationClassScheduleEntryMutationVariables = Exact<{
    nodeId: Scalars['ID'];
}>;

export type DeleteEducationClassScheduleEntryMutation = {
    __typename?: 'Mutation';
    deleteEducationClassScheduleEntryByNodeId?: Maybe<{
        __typename?: 'DeleteEducationClassScheduleEntryPayload';
        deletedEducationClassScheduleEntryNodeId?: Maybe<string>;
    }>;
};

export const ParticipantDetailsFieldsFragmentDoc = gql`
    fragment ParticipantDetailsFields on Participant {
        birthday
        city
        country
        email
        familyName
        fax
        givenName
        highlighted
        id
        invoiceBankIban
        invoiceBankName
        invoiceBankOwner
        invoiceBankSwift
        invoiceCity
        invoiceCountry
        invoiceCredit
        invoiceFamilyName
        invoiceGivenName
        invoicePostcode
        invoiceStreet
        invoiceSalutation
        invoiceTitle
        jobTitle
        mobile
        note
        phone
        postcode
        gender
        street
        title
        sortableName
        nodeId
        salutationEmail
        files
    }
`;
export const CourseGroupDetailsFieldsFragmentDoc = gql`
    fragment CourseGroupDetailsFields on CourseGroup {
        title
        description
        topic
        content
        externalUrl
        archived
        notes
    }
`;
export const CourseGroupDetailsFieldsWithNodeIdFragmentDoc = gql`
    fragment CourseGroupDetailsFieldsWithNodeId on CourseGroup {
        nodeId
        ...CourseGroupDetailsFields
    }
    ${CourseGroupDetailsFieldsFragmentDoc}
`;
export const CourseGroupDetailsFieldsCoursesSchedulesFragmentDoc = gql`
    fragment CourseGroupDetailsFieldsCoursesSchedules on CourseGroup {
        courses {
            aggregates {
                sum {
                    plannedScheduleCount
                }
            }
        }
    }
`;
export const SpeakerNameFragmentDoc = gql`
    fragment SpeakerName on Speaker {
        fullName
    }
`;
export const EventCalendarFragmentDoc = gql`
    fragment EventCalendar on CourseSchedule {
        id
        startDate
        endDate
        seats
        freeSeats
        status
        courseName
        resolvedSpeakers {
            nodes {
                ...SpeakerName
            }
        }
        course {
            nodeId
        }
    }
    ${SpeakerNameFragmentDoc}
`;
export const PublicCourseLocationFragmentDoc = gql`
    fragment PublicCourseLocation on Location {
        nodeId
        title
        address
        country
    }
`;
export const SpeakerDetailsFieldsFragmentDoc = gql`
    fragment SpeakerDetailsFields on Speaker {
        familyName
        givenName
        salutation
        title
        description
        archived
        fullNameWithSalutation
        fullName
        sortableName
        id
    }
`;
export const CourseScheduleFieldsFragmentDoc = gql`
    fragment CourseScheduleFields on CourseSchedule {
        id
        nodeId
        freeSeats
        status
        dateSchedule
        seats
        price
        daysAndUnits
        timetable
        courseName
        emptyLines
        emailList
        comment
        units
        duration
        startDate
        dateScheduleSortable
        cancelled
        location {
            ...PublicCourseLocation
        }
        speakersByCourseScheduleId {
            nodes {
                ...SpeakerDetailsFields
            }
        }
        resolvedSpeakers {
            nodes {
                ...SpeakerDetailsFields
            }
        }
        resolvedTimetable
        resolvedLocation {
            ...PublicCourseLocation
        }
        reminderSentAt
    }
    ${PublicCourseLocationFragmentDoc}
    ${SpeakerDetailsFieldsFragmentDoc}
`;
export const CourseTableFieldsFragmentDoc = gql`
    fragment CourseTableFields on Course {
        nodeId
        nextSchedule {
            ...CourseScheduleFields
        }
        lastSchedule {
            ...CourseScheduleFields
        }
        name
        nextDate
        lastDate
        archived
        defaultLocation {
            ...PublicCourseLocation
        }
        courseGroup {
            title
            courseGroupMenu {
                title
            }
        }
        fullName
        fullNameWithMenu
    }
    ${CourseScheduleFieldsFragmentDoc}
    ${PublicCourseLocationFragmentDoc}
`;
export const CourseParticipantOverviewFieldsFragmentDoc = gql`
    fragment CourseParticipantOverviewFields on Participant {
        fullNameWithSalutation
        address
        mobileOrPhone
        salutationEmail
    }
`;
export const CourseBookingOverviewFieldsFragmentDoc = gql`
    fragment CourseBookingOverviewFields on Booking {
        nodeId
        participant {
            ...CourseParticipantOverviewFields
        }
        paymentReceived
        passed
        registrationRegistered
        missingHours
        useForCertification
    }
    ${CourseParticipantOverviewFieldsFragmentDoc}
`;
export const CourseDetailFieldsFragmentDoc = gql`
    fragment CourseDetailFields on Course {
        comment
        defaultLocationId
        courseGroupId
        description
        details
        nextDate
        lastDate
        defaultTimetable
        defaultSeats
        defaultUnits
        defaultDuration
        defaultPrice
        name
        nodeId
        id
        confirmationContent
        courseSchedules(orderBy: START_DATE_DESC) {
            nodes {
                ...CourseScheduleFields
                speakersByCourseScheduleId {
                    nodes {
                        fullName
                    }
                }
                location {
                    title
                }
                bookings {
                    nodes {
                        ...CourseBookingOverviewFields
                    }
                }
            }
        }
        courseGroup {
            title
            courseGroupMenu {
                title
            }
        }
    }
    ${CourseScheduleFieldsFragmentDoc}
    ${CourseBookingOverviewFieldsFragmentDoc}
`;
export const PublicCourseTableFieldsFragmentDoc = gql`
    fragment PublicCourseTableFields on Course {
        id
        nodeId
        courseSchedules(
            condition: { cancelled: false }
            orderBy: START_DATE_ASC
            filter: { status: { in: [PLANNED, ACTIVE] } }
        ) {
            nodes {
                ...CourseScheduleFields
            }
        }
        name
        defaultTimetable
        comment
        description
        nextDate
        lastDate
        archived
        defaultLocation {
            ...PublicCourseLocation
        }
        courseDefaultSpeakers {
            nodes {
                speaker {
                    ...SpeakerDetailsFields
                }
            }
        }
    }
    ${CourseScheduleFieldsFragmentDoc}
    ${PublicCourseLocationFragmentDoc}
    ${SpeakerDetailsFieldsFragmentDoc}
`;
export const PublicCourseSignupFragmentDoc = gql`
    fragment PublicCourseSignup on CourseSchedule {
        course {
            name
            courseGroup {
                ...CourseGroupDetailsFields
            }
            ...PublicCourseTableFields
        }
        ...CourseScheduleFields
    }
    ${CourseGroupDetailsFieldsFragmentDoc}
    ${PublicCourseTableFieldsFragmentDoc}
    ${CourseScheduleFieldsFragmentDoc}
`;
export const PublicCourseScheduleFieldsFragmentDoc = gql`
    fragment PublicCourseScheduleFields on CourseSchedule {
        nodeId
        id
        dateSchedule
        daysAndUnits
        duration
        endDate
        freeSeats
        price
        startDate
        status
        timetable
        units
        comment
    }
`;
export const PublicCoursesDetailFieldsFragmentDoc = gql`
    fragment PublicCoursesDetailFields on Course {
        name
        defaultLocation {
            ...PublicCourseLocation
        }
        courseSchedules(
            filter: { status: { in: [PLANNED, ACTIVE] }, freeSeats: { greaterThan: "0" } }
            orderBy: START_DATE_ASC
        ) {
            nodes {
                ...PublicCourseScheduleFields
            }
        }
        archived
        description
    }
    ${PublicCourseLocationFragmentDoc}
    ${PublicCourseScheduleFieldsFragmentDoc}
`;
export const CourseGroupMenuDetailsFieldsFragmentDoc = gql`
    fragment CourseGroupMenuDetailsFields on CourseGroupMenu {
        shortcut
        titleOverview
        descriptionOverview
        imageurlOverview
        title
        description
        imageurl
        archived
    }
`;
export const ParticipantSignUpFieldsFragmentDoc = gql`
    fragment ParticipantSignUpFields on Participant {
        birthday
        city
        country
        email
        familyName
        givenName
        id
        nodeId
        invoiceBankIban
        invoiceBankSwift
        jobTitle
        postcode
        street
        mobileOrPhone
        gender
        newsletter
    }
`;
export const BookingLocationFragmentDoc = gql`
    fragment BookingLocation on Location {
        title
    }
`;
export const BookingSpeakersFragmentDoc = gql`
    fragment BookingSpeakers on Speaker {
        fullNameWithSalutation
    }
`;
export const BookingCourseFieldsFragmentDoc = gql`
    fragment BookingCourseFields on Course {
        nodeId
        id
        name
        fullName
        courseGroup {
            title
        }
    }
`;
export const BookingCourseScheduleFieldsFragmentDoc = gql`
    fragment BookingCourseScheduleFields on CourseSchedule {
        nodeId
        id
        priceFormatted
        dateSchedule
        cancelled
        status
        startDate
        dateScheduleSortable
        reminderSentAt
        course {
            ...BookingCourseFields
        }
    }
    ${BookingCourseFieldsFragmentDoc}
`;
export const BookingParticipantFragmentDoc = gql`
    fragment BookingParticipant on Participant {
        fullName
        sortableName
        nodeId
        salutationEmail
        id
    }
`;
export const BookingDetailsFragmentDoc = gql`
    fragment BookingDetails on Booking {
        nodeId
        id
        courseSchedule {
            ...BookingCourseScheduleFields
        }
        participant {
            ...BookingParticipant
        }
        documentsSend
        state
        passed
        paymentMethod
        invoiceToEmployer
        invoiceAddress
        invoiceNumber
        invoiceDate
        invoiceCompanyName
        invoiceFamilyName
        invoiceSalutation
        invoiceTitle
        invoiceGivenName
        invoiceStreet
        invoicePostcode
        invoiceCity
        invoiceCountry
        invoiceContactPhone
        invoiceContactEmail
        invoiceContactUrl
        invoiceContactInformation
        registrationRegistered
        registrationDateFormatted
        paymentReceived
        infos
        infosSearchable
        note
        useForCertification
    }
    ${BookingCourseScheduleFieldsFragmentDoc}
    ${BookingParticipantFragmentDoc}
`;
export const RelocationCourseScheduleFieldsFragmentDoc = gql`
    fragment RelocationCourseScheduleFields on CourseSchedule {
        status
        id
        dateSchedule
    }
`;
export const EducationDetailsFieldsFragmentDoc = gql`
    fragment EducationDetailsFields on Education {
        name
    }
`;
export const EducationClassScheduleEntryFieldsFragmentDoc = gql`
    fragment EducationClassScheduleEntryFields on EducationClassScheduleEntry {
        nodeId
        id
        date
        speaker {
            ...SpeakerDetailsFields
        }
        topic
    }
    ${SpeakerDetailsFieldsFragmentDoc}
`;
export const EducationClassEventFragmentDoc = gql`
    fragment EducationClassEvent on EducationClassSchedule {
        nodeId
        startDate
        endDate
        courseYear
        educationClassScheduleEntries {
            nodes {
                ...EducationClassScheduleEntryFields
            }
        }
        educationClass {
            nodeId
            name
            education {
                name
            }
        }
    }
    ${EducationClassScheduleEntryFieldsFragmentDoc}
`;
export const EducationClassDetailsFieldsFragmentDoc = gql`
    fragment EducationClassDetailsFields on EducationClass {
        name
    }
`;
export const LocationDetailsFieldsFragmentDoc = gql`
    fragment LocationDetailsFields on Location {
        street
        city
        postcode
        country
        remarks
        title
        archived
    }
`;
export const EducationClassScheduleFieldsFragmentDoc = gql`
    fragment EducationClassScheduleFields on EducationClassSchedule {
        nodeId
        id
        startDate
        endDate
        dateSchedule
        timetable
        locationId
        location {
            ...LocationDetailsFields
        }
        courseYear
        educationClassId
        educationClass {
            id
            nodeId
            name
            education {
                name
            }
        }
        educationClassScheduleEntries {
            nodes {
                ...EducationClassScheduleEntryFields
            }
        }
    }
    ${LocationDetailsFieldsFragmentDoc}
    ${EducationClassScheduleEntryFieldsFragmentDoc}
`;
export const GetAllBookingsDocument = gql`
    query getAllBookings($bookingFilter: BookingFilter) {
        bookings(orderBy: [REGISTRATION_DATE_DESC], filter: $bookingFilter) {
            nodes {
                ...BookingDetails
            }
        }
    }
    ${BookingDetailsFragmentDoc}
`;

/**
 * __useGetAllBookingsQuery__
 *
 * To run a query within a React component, call `useGetAllBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllBookingsQuery({
 *   variables: {
 *      bookingFilter: // value for 'bookingFilter'
 *   },
 * });
 */
export function useGetAllBookingsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllBookingsQuery, GetAllBookingsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetAllBookingsQuery, GetAllBookingsQueryVariables>(
        GetAllBookingsDocument,
        options,
    );
}
export function useGetAllBookingsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllBookingsQuery, GetAllBookingsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<GetAllBookingsQuery, GetAllBookingsQueryVariables>(
        GetAllBookingsDocument,
        options,
    );
}
export type GetAllBookingsQueryHookResult = ReturnType<typeof useGetAllBookingsQuery>;
export type GetAllBookingsLazyQueryHookResult = ReturnType<typeof useGetAllBookingsLazyQuery>;
export type GetAllBookingsQueryResult = Apollo.QueryResult<GetAllBookingsQuery, GetAllBookingsQueryVariables>;
export const GetParticipantsForTableDocument = gql`
    query getParticipantsForTable {
        participants(orderBy: [FAMILY_NAME_ASC, GIVEN_NAME_ASC, CREATED_AT_ASC]) {
            nodes {
                nodeId
                salutation
                sortableName
                address
                highlighted
                mobileOrPhone
                email
                createdAt
                id
                birthday
            }
        }
    }
`;

/**
 * __useGetParticipantsForTableQuery__
 *
 * To run a query within a React component, call `useGetParticipantsForTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParticipantsForTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParticipantsForTableQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetParticipantsForTableQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GetParticipantsForTableQuery,
        GetParticipantsForTableQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetParticipantsForTableQuery, GetParticipantsForTableQueryVariables>(
        GetParticipantsForTableDocument,
        options,
    );
}
export function useGetParticipantsForTableLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetParticipantsForTableQuery,
        GetParticipantsForTableQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<GetParticipantsForTableQuery, GetParticipantsForTableQueryVariables>(
        GetParticipantsForTableDocument,
        options,
    );
}
export type GetParticipantsForTableQueryHookResult = ReturnType<typeof useGetParticipantsForTableQuery>;
export type GetParticipantsForTableLazyQueryHookResult = ReturnType<typeof useGetParticipantsForTableLazyQuery>;
export type GetParticipantsForTableQueryResult = Apollo.QueryResult<
    GetParticipantsForTableQuery,
    GetParticipantsForTableQueryVariables
>;
export const GetParticipantDetailsDocument = gql`
    query getParticipantDetails($nodeId: ID!) {
        participantByNodeId(nodeId: $nodeId) {
            ...ParticipantDetailsFields
        }
    }
    ${ParticipantDetailsFieldsFragmentDoc}
`;

/**
 * __useGetParticipantDetailsQuery__
 *
 * To run a query within a React component, call `useGetParticipantDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParticipantDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParticipantDetailsQuery({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useGetParticipantDetailsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<GetParticipantDetailsQuery, GetParticipantDetailsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetParticipantDetailsQuery, GetParticipantDetailsQueryVariables>(
        GetParticipantDetailsDocument,
        options,
    );
}
export function useGetParticipantDetailsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetParticipantDetailsQuery,
        GetParticipantDetailsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<GetParticipantDetailsQuery, GetParticipantDetailsQueryVariables>(
        GetParticipantDetailsDocument,
        options,
    );
}
export type GetParticipantDetailsQueryHookResult = ReturnType<typeof useGetParticipantDetailsQuery>;
export type GetParticipantDetailsLazyQueryHookResult = ReturnType<typeof useGetParticipantDetailsLazyQuery>;
export type GetParticipantDetailsQueryResult = Apollo.QueryResult<
    GetParticipantDetailsQuery,
    GetParticipantDetailsQueryVariables
>;
export const UpdateParticipantDetailsDocument = gql`
    mutation updateParticipantDetails($nodeId: ID!, $participantPatch: ParticipantPatch!) {
        updateParticipantByNodeId(input: { nodeId: $nodeId, patch: $participantPatch }) {
            clientMutationId
            participant {
                ...ParticipantDetailsFields
            }
        }
    }
    ${ParticipantDetailsFieldsFragmentDoc}
`;
export type UpdateParticipantDetailsMutationFn = Apollo.MutationFunction<
    UpdateParticipantDetailsMutation,
    UpdateParticipantDetailsMutationVariables
>;

/**
 * __useUpdateParticipantDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateParticipantDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateParticipantDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateParticipantDetailsMutation, { data, loading, error }] = useUpdateParticipantDetailsMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *      participantPatch: // value for 'participantPatch'
 *   },
 * });
 */
export function useUpdateParticipantDetailsMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateParticipantDetailsMutation,
        UpdateParticipantDetailsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<UpdateParticipantDetailsMutation, UpdateParticipantDetailsMutationVariables>(
        UpdateParticipantDetailsDocument,
        options,
    );
}
export type UpdateParticipantDetailsMutationHookResult = ReturnType<typeof useUpdateParticipantDetailsMutation>;
export type UpdateParticipantDetailsMutationResult = Apollo.MutationResult<UpdateParticipantDetailsMutation>;
export type UpdateParticipantDetailsMutationOptions = Apollo.BaseMutationOptions<
    UpdateParticipantDetailsMutation,
    UpdateParticipantDetailsMutationVariables
>;
export const GetSpeakersForTableDocument = gql`
    query getSpeakersForTable {
        speakers(orderBy: [ARCHIVED_ASC, FAMILY_NAME_ASC, GIVEN_NAME_ASC]) {
            nodes {
                nodeId
                description
                sortableName
                title
                salutation
                archived
                fullNameWithSalutation
                fullName
                sortableName
                id
            }
        }
    }
`;

/**
 * __useGetSpeakersForTableQuery__
 *
 * To run a query within a React component, call `useGetSpeakersForTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpeakersForTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpeakersForTableQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSpeakersForTableQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<GetSpeakersForTableQuery, GetSpeakersForTableQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetSpeakersForTableQuery, GetSpeakersForTableQueryVariables>(
        GetSpeakersForTableDocument,
        options,
    );
}
export function useGetSpeakersForTableLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSpeakersForTableQuery, GetSpeakersForTableQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<GetSpeakersForTableQuery, GetSpeakersForTableQueryVariables>(
        GetSpeakersForTableDocument,
        options,
    );
}
export type GetSpeakersForTableQueryHookResult = ReturnType<typeof useGetSpeakersForTableQuery>;
export type GetSpeakersForTableLazyQueryHookResult = ReturnType<typeof useGetSpeakersForTableLazyQuery>;
export type GetSpeakersForTableQueryResult = Apollo.QueryResult<
    GetSpeakersForTableQuery,
    GetSpeakersForTableQueryVariables
>;
export const GetSpeakerDetailsDocument = gql`
    query getSpeakerDetails($nodeId: ID!) {
        speakerByNodeId(nodeId: $nodeId) {
            ...SpeakerDetailsFields
            courseScheduleSpeakers {
                totalCount
            }
        }
    }
    ${SpeakerDetailsFieldsFragmentDoc}
`;

/**
 * __useGetSpeakerDetailsQuery__
 *
 * To run a query within a React component, call `useGetSpeakerDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpeakerDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpeakerDetailsQuery({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useGetSpeakerDetailsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<GetSpeakerDetailsQuery, GetSpeakerDetailsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetSpeakerDetailsQuery, GetSpeakerDetailsQueryVariables>(
        GetSpeakerDetailsDocument,
        options,
    );
}
export function useGetSpeakerDetailsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSpeakerDetailsQuery, GetSpeakerDetailsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<GetSpeakerDetailsQuery, GetSpeakerDetailsQueryVariables>(
        GetSpeakerDetailsDocument,
        options,
    );
}
export type GetSpeakerDetailsQueryHookResult = ReturnType<typeof useGetSpeakerDetailsQuery>;
export type GetSpeakerDetailsLazyQueryHookResult = ReturnType<typeof useGetSpeakerDetailsLazyQuery>;
export type GetSpeakerDetailsQueryResult = Apollo.QueryResult<GetSpeakerDetailsQuery, GetSpeakerDetailsQueryVariables>;
export const UpdateSpeakerDetailsDocument = gql`
    mutation updateSpeakerDetails($nodeId: ID!, $speakerPatch: SpeakerPatch!) {
        updateSpeakerByNodeId(input: { nodeId: $nodeId, patch: $speakerPatch }) {
            clientMutationId
        }
    }
`;
export type UpdateSpeakerDetailsMutationFn = Apollo.MutationFunction<
    UpdateSpeakerDetailsMutation,
    UpdateSpeakerDetailsMutationVariables
>;

/**
 * __useUpdateSpeakerDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateSpeakerDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSpeakerDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSpeakerDetailsMutation, { data, loading, error }] = useUpdateSpeakerDetailsMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *      speakerPatch: // value for 'speakerPatch'
 *   },
 * });
 */
export function useUpdateSpeakerDetailsMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateSpeakerDetailsMutation,
        UpdateSpeakerDetailsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<UpdateSpeakerDetailsMutation, UpdateSpeakerDetailsMutationVariables>(
        UpdateSpeakerDetailsDocument,
        options,
    );
}
export type UpdateSpeakerDetailsMutationHookResult = ReturnType<typeof useUpdateSpeakerDetailsMutation>;
export type UpdateSpeakerDetailsMutationResult = Apollo.MutationResult<UpdateSpeakerDetailsMutation>;
export type UpdateSpeakerDetailsMutationOptions = Apollo.BaseMutationOptions<
    UpdateSpeakerDetailsMutation,
    UpdateSpeakerDetailsMutationVariables
>;
export const CreateSpeakerDocument = gql`
    mutation createSpeaker($speakerInput: CreateSpeakerInput!) {
        createSpeaker(input: $speakerInput) {
            speaker {
                nodeId
            }
        }
    }
`;
export type CreateSpeakerMutationFn = Apollo.MutationFunction<CreateSpeakerMutation, CreateSpeakerMutationVariables>;

/**
 * __useCreateSpeakerMutation__
 *
 * To run a mutation, you first call `useCreateSpeakerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSpeakerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSpeakerMutation, { data, loading, error }] = useCreateSpeakerMutation({
 *   variables: {
 *      speakerInput: // value for 'speakerInput'
 *   },
 * });
 */
export function useCreateSpeakerMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSpeakerMutation, CreateSpeakerMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<CreateSpeakerMutation, CreateSpeakerMutationVariables>(
        CreateSpeakerDocument,
        options,
    );
}
export type CreateSpeakerMutationHookResult = ReturnType<typeof useCreateSpeakerMutation>;
export type CreateSpeakerMutationResult = Apollo.MutationResult<CreateSpeakerMutation>;
export type CreateSpeakerMutationOptions = Apollo.BaseMutationOptions<
    CreateSpeakerMutation,
    CreateSpeakerMutationVariables
>;
export const DeleteSpeakerDocument = gql`
    mutation deleteSpeaker($nodeId: ID!) {
        deleteSpeakerByNodeId(input: { nodeId: $nodeId }) {
            deletedSpeakerNodeId
        }
    }
`;
export type DeleteSpeakerMutationFn = Apollo.MutationFunction<DeleteSpeakerMutation, DeleteSpeakerMutationVariables>;

/**
 * __useDeleteSpeakerMutation__
 *
 * To run a mutation, you first call `useDeleteSpeakerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSpeakerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSpeakerMutation, { data, loading, error }] = useDeleteSpeakerMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useDeleteSpeakerMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSpeakerMutation, DeleteSpeakerMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<DeleteSpeakerMutation, DeleteSpeakerMutationVariables>(
        DeleteSpeakerDocument,
        options,
    );
}
export type DeleteSpeakerMutationHookResult = ReturnType<typeof useDeleteSpeakerMutation>;
export type DeleteSpeakerMutationResult = Apollo.MutationResult<DeleteSpeakerMutation>;
export type DeleteSpeakerMutationOptions = Apollo.BaseMutationOptions<
    DeleteSpeakerMutation,
    DeleteSpeakerMutationVariables
>;
export const GetCourseGroupsForTableDocument = gql`
    query getCourseGroupsForTable {
        courseGroups(orderBy: TITLE_ASC) {
            nodes {
                title
                archived
                nodeId
                courses {
                    totalCount
                }
            }
        }
    }
`;

/**
 * __useGetCourseGroupsForTableQuery__
 *
 * To run a query within a React component, call `useGetCourseGroupsForTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseGroupsForTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseGroupsForTableQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCourseGroupsForTableQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GetCourseGroupsForTableQuery,
        GetCourseGroupsForTableQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetCourseGroupsForTableQuery, GetCourseGroupsForTableQueryVariables>(
        GetCourseGroupsForTableDocument,
        options,
    );
}
export function useGetCourseGroupsForTableLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetCourseGroupsForTableQuery,
        GetCourseGroupsForTableQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<GetCourseGroupsForTableQuery, GetCourseGroupsForTableQueryVariables>(
        GetCourseGroupsForTableDocument,
        options,
    );
}
export type GetCourseGroupsForTableQueryHookResult = ReturnType<typeof useGetCourseGroupsForTableQuery>;
export type GetCourseGroupsForTableLazyQueryHookResult = ReturnType<typeof useGetCourseGroupsForTableLazyQuery>;
export type GetCourseGroupsForTableQueryResult = Apollo.QueryResult<
    GetCourseGroupsForTableQuery,
    GetCourseGroupsForTableQueryVariables
>;
export const GetCourseGroupDetailsDocument = gql`
    query getCourseGroupDetails($nodeId: ID!) {
        courseGroupByNodeId(nodeId: $nodeId) {
            nodeId
            ...CourseGroupDetailsFields
            courses {
                totalCount
            }
            courseGroupMenu {
                shortcut
            }
        }
    }
    ${CourseGroupDetailsFieldsFragmentDoc}
`;

/**
 * __useGetCourseGroupDetailsQuery__
 *
 * To run a query within a React component, call `useGetCourseGroupDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseGroupDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseGroupDetailsQuery({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useGetCourseGroupDetailsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<GetCourseGroupDetailsQuery, GetCourseGroupDetailsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetCourseGroupDetailsQuery, GetCourseGroupDetailsQueryVariables>(
        GetCourseGroupDetailsDocument,
        options,
    );
}
export function useGetCourseGroupDetailsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetCourseGroupDetailsQuery,
        GetCourseGroupDetailsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<GetCourseGroupDetailsQuery, GetCourseGroupDetailsQueryVariables>(
        GetCourseGroupDetailsDocument,
        options,
    );
}
export type GetCourseGroupDetailsQueryHookResult = ReturnType<typeof useGetCourseGroupDetailsQuery>;
export type GetCourseGroupDetailsLazyQueryHookResult = ReturnType<typeof useGetCourseGroupDetailsLazyQuery>;
export type GetCourseGroupDetailsQueryResult = Apollo.QueryResult<
    GetCourseGroupDetailsQuery,
    GetCourseGroupDetailsQueryVariables
>;
export const UpdateCourseGroupDetailsDocument = gql`
    mutation updateCourseGroupDetails($nodeId: ID!, $courseGroupPatch: CourseGroupPatch!) {
        updateCourseGroupByNodeId(input: { nodeId: $nodeId, patch: $courseGroupPatch }) {
            courseGroup {
                nodeId
                ...CourseGroupDetailsFields
            }
        }
    }
    ${CourseGroupDetailsFieldsFragmentDoc}
`;
export type UpdateCourseGroupDetailsMutationFn = Apollo.MutationFunction<
    UpdateCourseGroupDetailsMutation,
    UpdateCourseGroupDetailsMutationVariables
>;

/**
 * __useUpdateCourseGroupDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateCourseGroupDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseGroupDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseGroupDetailsMutation, { data, loading, error }] = useUpdateCourseGroupDetailsMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *      courseGroupPatch: // value for 'courseGroupPatch'
 *   },
 * });
 */
export function useUpdateCourseGroupDetailsMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateCourseGroupDetailsMutation,
        UpdateCourseGroupDetailsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<UpdateCourseGroupDetailsMutation, UpdateCourseGroupDetailsMutationVariables>(
        UpdateCourseGroupDetailsDocument,
        options,
    );
}
export type UpdateCourseGroupDetailsMutationHookResult = ReturnType<typeof useUpdateCourseGroupDetailsMutation>;
export type UpdateCourseGroupDetailsMutationResult = Apollo.MutationResult<UpdateCourseGroupDetailsMutation>;
export type UpdateCourseGroupDetailsMutationOptions = Apollo.BaseMutationOptions<
    UpdateCourseGroupDetailsMutation,
    UpdateCourseGroupDetailsMutationVariables
>;
export const CreateCourseGroupDocument = gql`
    mutation createCourseGroup($courseGroupInput: CreateCourseGroupInput!) {
        createCourseGroup(input: $courseGroupInput) {
            courseGroup {
                nodeId
            }
        }
    }
`;
export type CreateCourseGroupMutationFn = Apollo.MutationFunction<
    CreateCourseGroupMutation,
    CreateCourseGroupMutationVariables
>;

/**
 * __useCreateCourseGroupMutation__
 *
 * To run a mutation, you first call `useCreateCourseGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseGroupMutation, { data, loading, error }] = useCreateCourseGroupMutation({
 *   variables: {
 *      courseGroupInput: // value for 'courseGroupInput'
 *   },
 * });
 */
export function useCreateCourseGroupMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCourseGroupMutation, CreateCourseGroupMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<CreateCourseGroupMutation, CreateCourseGroupMutationVariables>(
        CreateCourseGroupDocument,
        options,
    );
}
export type CreateCourseGroupMutationHookResult = ReturnType<typeof useCreateCourseGroupMutation>;
export type CreateCourseGroupMutationResult = Apollo.MutationResult<CreateCourseGroupMutation>;
export type CreateCourseGroupMutationOptions = Apollo.BaseMutationOptions<
    CreateCourseGroupMutation,
    CreateCourseGroupMutationVariables
>;
export const DeleteCourseGroupDocument = gql`
    mutation deleteCourseGroup($nodeId: ID!) {
        deleteCourseGroupByNodeId(input: { nodeId: $nodeId }) {
            deletedCourseGroupNodeId
        }
    }
`;
export type DeleteCourseGroupMutationFn = Apollo.MutationFunction<
    DeleteCourseGroupMutation,
    DeleteCourseGroupMutationVariables
>;

/**
 * __useDeleteCourseGroupMutation__
 *
 * To run a mutation, you first call `useDeleteCourseGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCourseGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCourseGroupMutation, { data, loading, error }] = useDeleteCourseGroupMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useDeleteCourseGroupMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCourseGroupMutation, DeleteCourseGroupMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<DeleteCourseGroupMutation, DeleteCourseGroupMutationVariables>(
        DeleteCourseGroupDocument,
        options,
    );
}
export type DeleteCourseGroupMutationHookResult = ReturnType<typeof useDeleteCourseGroupMutation>;
export type DeleteCourseGroupMutationResult = Apollo.MutationResult<DeleteCourseGroupMutation>;
export type DeleteCourseGroupMutationOptions = Apollo.BaseMutationOptions<
    DeleteCourseGroupMutation,
    DeleteCourseGroupMutationVariables
>;
export const GetDashboardStatisticsDocument = gql`
    query getDashboardStatistics {
        participants {
            totalCount
        }
        courses {
            totalCount
        }
        allbookings: bookings {
            totalCount
        }
        speakers {
            totalCount
        }
        locations {
            totalCount
        }
        unreadbookings: bookings(condition: { state: UNREAD }) {
            totalCount
        }
        courseSchedules(filter: { status: { in: [PLANNED, DONE, ACTIVE] } }, orderBy: START_DATE_ASC) {
            nodes {
                ...EventCalendar
            }
        }
        educationClassSchedules {
            nodes {
                ...EducationClassEvent
            }
        }
    }
    ${EventCalendarFragmentDoc}
    ${EducationClassEventFragmentDoc}
`;

/**
 * __useGetDashboardStatisticsQuery__
 *
 * To run a query within a React component, call `useGetDashboardStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardStatisticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDashboardStatisticsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<GetDashboardStatisticsQuery, GetDashboardStatisticsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetDashboardStatisticsQuery, GetDashboardStatisticsQueryVariables>(
        GetDashboardStatisticsDocument,
        options,
    );
}
export function useGetDashboardStatisticsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetDashboardStatisticsQuery,
        GetDashboardStatisticsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<GetDashboardStatisticsQuery, GetDashboardStatisticsQueryVariables>(
        GetDashboardStatisticsDocument,
        options,
    );
}
export type GetDashboardStatisticsQueryHookResult = ReturnType<typeof useGetDashboardStatisticsQuery>;
export type GetDashboardStatisticsLazyQueryHookResult = ReturnType<typeof useGetDashboardStatisticsLazyQuery>;
export type GetDashboardStatisticsQueryResult = Apollo.QueryResult<
    GetDashboardStatisticsQuery,
    GetDashboardStatisticsQueryVariables
>;
export const GetCoursesForTableDocument = gql`
    query getCoursesForTable {
        newCourses: courses(orderBy: NEXT_DATE_ASC, filter: { nextDate: { isNull: false } }) {
            nodes {
                ...CourseTableFields
            }
        }
        oldCourses: courses(orderBy: LAST_DATE_DESC, filter: { nextDate: { isNull: true } }) {
            nodes {
                ...CourseTableFields
            }
        }
    }
    ${CourseTableFieldsFragmentDoc}
`;

/**
 * __useGetCoursesForTableQuery__
 *
 * To run a query within a React component, call `useGetCoursesForTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoursesForTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoursesForTableQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCoursesForTableQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<GetCoursesForTableQuery, GetCoursesForTableQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetCoursesForTableQuery, GetCoursesForTableQueryVariables>(
        GetCoursesForTableDocument,
        options,
    );
}
export function useGetCoursesForTableLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCoursesForTableQuery, GetCoursesForTableQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<GetCoursesForTableQuery, GetCoursesForTableQueryVariables>(
        GetCoursesForTableDocument,
        options,
    );
}
export type GetCoursesForTableQueryHookResult = ReturnType<typeof useGetCoursesForTableQuery>;
export type GetCoursesForTableLazyQueryHookResult = ReturnType<typeof useGetCoursesForTableLazyQuery>;
export type GetCoursesForTableQueryResult = Apollo.QueryResult<
    GetCoursesForTableQuery,
    GetCoursesForTableQueryVariables
>;
export const GetCourseDetailsDocument = gql`
    query getCourseDetails($nodeId: ID!) {
        courseByNodeId(nodeId: $nodeId) {
            ...CourseDetailFields
        }
    }
    ${CourseDetailFieldsFragmentDoc}
`;

/**
 * __useGetCourseDetailsQuery__
 *
 * To run a query within a React component, call `useGetCourseDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseDetailsQuery({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useGetCourseDetailsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<GetCourseDetailsQuery, GetCourseDetailsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetCourseDetailsQuery, GetCourseDetailsQueryVariables>(
        GetCourseDetailsDocument,
        options,
    );
}
export function useGetCourseDetailsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCourseDetailsQuery, GetCourseDetailsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<GetCourseDetailsQuery, GetCourseDetailsQueryVariables>(
        GetCourseDetailsDocument,
        options,
    );
}
export type GetCourseDetailsQueryHookResult = ReturnType<typeof useGetCourseDetailsQuery>;
export type GetCourseDetailsLazyQueryHookResult = ReturnType<typeof useGetCourseDetailsLazyQuery>;
export type GetCourseDetailsQueryResult = Apollo.QueryResult<GetCourseDetailsQuery, GetCourseDetailsQueryVariables>;
export const GetActiveSpeakersDocument = gql`
    query getActiveSpeakers {
        speakers(condition: { archived: false }) {
            nodes {
                nodeId
                fullName
            }
        }
    }
`;

/**
 * __useGetActiveSpeakersQuery__
 *
 * To run a query within a React component, call `useGetActiveSpeakersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveSpeakersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveSpeakersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveSpeakersQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<GetActiveSpeakersQuery, GetActiveSpeakersQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetActiveSpeakersQuery, GetActiveSpeakersQueryVariables>(
        GetActiveSpeakersDocument,
        options,
    );
}
export function useGetActiveSpeakersLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetActiveSpeakersQuery, GetActiveSpeakersQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<GetActiveSpeakersQuery, GetActiveSpeakersQueryVariables>(
        GetActiveSpeakersDocument,
        options,
    );
}
export type GetActiveSpeakersQueryHookResult = ReturnType<typeof useGetActiveSpeakersQuery>;
export type GetActiveSpeakersLazyQueryHookResult = ReturnType<typeof useGetActiveSpeakersLazyQuery>;
export type GetActiveSpeakersQueryResult = Apollo.QueryResult<GetActiveSpeakersQuery, GetActiveSpeakersQueryVariables>;
export const GetActiveLocationsDocument = gql`
    query getActiveLocations {
        locations(condition: { archived: false }) {
            nodes {
                nodeId
                title
            }
        }
    }
`;

/**
 * __useGetActiveLocationsQuery__
 *
 * To run a query within a React component, call `useGetActiveLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveLocationsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<GetActiveLocationsQuery, GetActiveLocationsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetActiveLocationsQuery, GetActiveLocationsQueryVariables>(
        GetActiveLocationsDocument,
        options,
    );
}
export function useGetActiveLocationsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetActiveLocationsQuery, GetActiveLocationsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<GetActiveLocationsQuery, GetActiveLocationsQueryVariables>(
        GetActiveLocationsDocument,
        options,
    );
}
export type GetActiveLocationsQueryHookResult = ReturnType<typeof useGetActiveLocationsQuery>;
export type GetActiveLocationsLazyQueryHookResult = ReturnType<typeof useGetActiveLocationsLazyQuery>;
export type GetActiveLocationsQueryResult = Apollo.QueryResult<
    GetActiveLocationsQuery,
    GetActiveLocationsQueryVariables
>;
export const GetActiveCourseGroupsDocument = gql`
    query getActiveCourseGroups {
        courseGroups(condition: { archived: false }) {
            nodes {
                nodeId
                title
            }
        }
    }
`;

/**
 * __useGetActiveCourseGroupsQuery__
 *
 * To run a query within a React component, call `useGetActiveCourseGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveCourseGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveCourseGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveCourseGroupsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<GetActiveCourseGroupsQuery, GetActiveCourseGroupsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetActiveCourseGroupsQuery, GetActiveCourseGroupsQueryVariables>(
        GetActiveCourseGroupsDocument,
        options,
    );
}
export function useGetActiveCourseGroupsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetActiveCourseGroupsQuery,
        GetActiveCourseGroupsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<GetActiveCourseGroupsQuery, GetActiveCourseGroupsQueryVariables>(
        GetActiveCourseGroupsDocument,
        options,
    );
}
export type GetActiveCourseGroupsQueryHookResult = ReturnType<typeof useGetActiveCourseGroupsQuery>;
export type GetActiveCourseGroupsLazyQueryHookResult = ReturnType<typeof useGetActiveCourseGroupsLazyQuery>;
export type GetActiveCourseGroupsQueryResult = Apollo.QueryResult<
    GetActiveCourseGroupsQuery,
    GetActiveCourseGroupsQueryVariables
>;
export const GetLocationsForTableDocument = gql`
    query getLocationsForTable {
        locations(orderBy: TITLE_ASC) {
            nodes {
                title
                address
                archived
                nodeId
            }
        }
    }
`;

/**
 * __useGetLocationsForTableQuery__
 *
 * To run a query within a React component, call `useGetLocationsForTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsForTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsForTableQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocationsForTableQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<GetLocationsForTableQuery, GetLocationsForTableQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetLocationsForTableQuery, GetLocationsForTableQueryVariables>(
        GetLocationsForTableDocument,
        options,
    );
}
export function useGetLocationsForTableLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLocationsForTableQuery, GetLocationsForTableQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<GetLocationsForTableQuery, GetLocationsForTableQueryVariables>(
        GetLocationsForTableDocument,
        options,
    );
}
export type GetLocationsForTableQueryHookResult = ReturnType<typeof useGetLocationsForTableQuery>;
export type GetLocationsForTableLazyQueryHookResult = ReturnType<typeof useGetLocationsForTableLazyQuery>;
export type GetLocationsForTableQueryResult = Apollo.QueryResult<
    GetLocationsForTableQuery,
    GetLocationsForTableQueryVariables
>;
export const GetLocationDetailsDocument = gql`
    query getLocationDetails($nodeId: ID!) {
        locationByNodeId(nodeId: $nodeId) {
            ...LocationDetailsFields
            courseSchedules {
                totalCount
            }
            coursesByDefaultLocationId {
                totalCount
            }
        }
    }
    ${LocationDetailsFieldsFragmentDoc}
`;

/**
 * __useGetLocationDetailsQuery__
 *
 * To run a query within a React component, call `useGetLocationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationDetailsQuery({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useGetLocationDetailsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<GetLocationDetailsQuery, GetLocationDetailsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetLocationDetailsQuery, GetLocationDetailsQueryVariables>(
        GetLocationDetailsDocument,
        options,
    );
}
export function useGetLocationDetailsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLocationDetailsQuery, GetLocationDetailsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<GetLocationDetailsQuery, GetLocationDetailsQueryVariables>(
        GetLocationDetailsDocument,
        options,
    );
}
export type GetLocationDetailsQueryHookResult = ReturnType<typeof useGetLocationDetailsQuery>;
export type GetLocationDetailsLazyQueryHookResult = ReturnType<typeof useGetLocationDetailsLazyQuery>;
export type GetLocationDetailsQueryResult = Apollo.QueryResult<
    GetLocationDetailsQuery,
    GetLocationDetailsQueryVariables
>;
export const UpdateLocationDetailsDocument = gql`
    mutation updateLocationDetails($nodeId: ID!, $locationPatch: LocationPatch!) {
        updateLocationByNodeId(input: { nodeId: $nodeId, patch: $locationPatch }) {
            clientMutationId
        }
    }
`;
export type UpdateLocationDetailsMutationFn = Apollo.MutationFunction<
    UpdateLocationDetailsMutation,
    UpdateLocationDetailsMutationVariables
>;

/**
 * __useUpdateLocationDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateLocationDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationDetailsMutation, { data, loading, error }] = useUpdateLocationDetailsMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *      locationPatch: // value for 'locationPatch'
 *   },
 * });
 */
export function useUpdateLocationDetailsMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateLocationDetailsMutation,
        UpdateLocationDetailsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<UpdateLocationDetailsMutation, UpdateLocationDetailsMutationVariables>(
        UpdateLocationDetailsDocument,
        options,
    );
}
export type UpdateLocationDetailsMutationHookResult = ReturnType<typeof useUpdateLocationDetailsMutation>;
export type UpdateLocationDetailsMutationResult = Apollo.MutationResult<UpdateLocationDetailsMutation>;
export type UpdateLocationDetailsMutationOptions = Apollo.BaseMutationOptions<
    UpdateLocationDetailsMutation,
    UpdateLocationDetailsMutationVariables
>;
export const CreateLocationDocument = gql`
    mutation createLocation($locationInput: CreateLocationInput!) {
        createLocation(input: $locationInput) {
            location {
                nodeId
            }
        }
    }
`;
export type CreateLocationMutationFn = Apollo.MutationFunction<CreateLocationMutation, CreateLocationMutationVariables>;

/**
 * __useCreateLocationMutation__
 *
 * To run a mutation, you first call `useCreateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationMutation, { data, loading, error }] = useCreateLocationMutation({
 *   variables: {
 *      locationInput: // value for 'locationInput'
 *   },
 * });
 */
export function useCreateLocationMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<CreateLocationMutation, CreateLocationMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<CreateLocationMutation, CreateLocationMutationVariables>(
        CreateLocationDocument,
        options,
    );
}
export type CreateLocationMutationHookResult = ReturnType<typeof useCreateLocationMutation>;
export type CreateLocationMutationResult = Apollo.MutationResult<CreateLocationMutation>;
export type CreateLocationMutationOptions = Apollo.BaseMutationOptions<
    CreateLocationMutation,
    CreateLocationMutationVariables
>;
export const DeleteLocationDocument = gql`
    mutation deleteLocation($nodeId: ID!) {
        deleteLocationByNodeId(input: { nodeId: $nodeId }) {
            deletedLocationNodeId
        }
    }
`;
export type DeleteLocationMutationFn = Apollo.MutationFunction<DeleteLocationMutation, DeleteLocationMutationVariables>;

/**
 * __useDeleteLocationMutation__
 *
 * To run a mutation, you first call `useDeleteLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLocationMutation, { data, loading, error }] = useDeleteLocationMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useDeleteLocationMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteLocationMutation, DeleteLocationMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<DeleteLocationMutation, DeleteLocationMutationVariables>(
        DeleteLocationDocument,
        options,
    );
}
export type DeleteLocationMutationHookResult = ReturnType<typeof useDeleteLocationMutation>;
export type DeleteLocationMutationResult = Apollo.MutationResult<DeleteLocationMutation>;
export type DeleteLocationMutationOptions = Apollo.BaseMutationOptions<
    DeleteLocationMutation,
    DeleteLocationMutationVariables
>;
export const GetPublicCoursesForShortcutDocument = gql`
    query getPublicCoursesForShortcut($shortcut: String) {
        courseGroupMenus(
            filter: { shortcut: { equalToInsensitive: $shortcut }, archived: { equalTo: false } }
            orderBy: TITLE_ASC
        ) {
            nodes {
                ...CourseGroupMenuDetailsFields
                courseGroups(filter: { archived: { equalTo: false } }, orderBy: TITLE_ASC) {
                    nodes {
                        ...CourseGroupDetailsFieldsWithNodeId
                        ...CourseGroupDetailsFieldsCoursesSchedules
                    }
                }
            }
        }
    }
    ${CourseGroupMenuDetailsFieldsFragmentDoc}
    ${CourseGroupDetailsFieldsWithNodeIdFragmentDoc}
    ${CourseGroupDetailsFieldsCoursesSchedulesFragmentDoc}
`;

/**
 * __useGetPublicCoursesForShortcutQuery__
 *
 * To run a query within a React component, call `useGetPublicCoursesForShortcutQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicCoursesForShortcutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicCoursesForShortcutQuery({
 *   variables: {
 *      shortcut: // value for 'shortcut'
 *   },
 * });
 */
export function useGetPublicCoursesForShortcutQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GetPublicCoursesForShortcutQuery,
        GetPublicCoursesForShortcutQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetPublicCoursesForShortcutQuery, GetPublicCoursesForShortcutQueryVariables>(
        GetPublicCoursesForShortcutDocument,
        options,
    );
}
export function useGetPublicCoursesForShortcutLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetPublicCoursesForShortcutQuery,
        GetPublicCoursesForShortcutQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<GetPublicCoursesForShortcutQuery, GetPublicCoursesForShortcutQueryVariables>(
        GetPublicCoursesForShortcutDocument,
        options,
    );
}
export type GetPublicCoursesForShortcutQueryHookResult = ReturnType<typeof useGetPublicCoursesForShortcutQuery>;
export type GetPublicCoursesForShortcutLazyQueryHookResult = ReturnType<typeof useGetPublicCoursesForShortcutLazyQuery>;
export type GetPublicCoursesForShortcutQueryResult = Apollo.QueryResult<
    GetPublicCoursesForShortcutQuery,
    GetPublicCoursesForShortcutQueryVariables
>;
export const GetPublicCourseMenuDocument = gql`
    query getPublicCourseMenu {
        courseGroupMenus(filter: { archived: { equalTo: false } }) {
            nodes {
                ...CourseGroupMenuDetailsFields
            }
        }
    }
    ${CourseGroupMenuDetailsFieldsFragmentDoc}
`;

/**
 * __useGetPublicCourseMenuQuery__
 *
 * To run a query within a React component, call `useGetPublicCourseMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicCourseMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicCourseMenuQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPublicCourseMenuQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<GetPublicCourseMenuQuery, GetPublicCourseMenuQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetPublicCourseMenuQuery, GetPublicCourseMenuQueryVariables>(
        GetPublicCourseMenuDocument,
        options,
    );
}
export function useGetPublicCourseMenuLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPublicCourseMenuQuery, GetPublicCourseMenuQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<GetPublicCourseMenuQuery, GetPublicCourseMenuQueryVariables>(
        GetPublicCourseMenuDocument,
        options,
    );
}
export type GetPublicCourseMenuQueryHookResult = ReturnType<typeof useGetPublicCourseMenuQuery>;
export type GetPublicCourseMenuLazyQueryHookResult = ReturnType<typeof useGetPublicCourseMenuLazyQuery>;
export type GetPublicCourseMenuQueryResult = Apollo.QueryResult<
    GetPublicCourseMenuQuery,
    GetPublicCourseMenuQueryVariables
>;
export const GetPublicCourseDetailsForCourseDocument = gql`
    query getPublicCourseDetailsForCourse($courseNodeId: ID!) {
        courseGroupByNodeId(nodeId: $courseNodeId) {
            nodeId
            ...CourseGroupDetailsFields
            courses(
                orderBy: NAME_ASC
                filter: { plannedScheduleCount: { greaterThan: 0 }, archived: { equalTo: false } }
            ) {
                totalCount
                nodes {
                    ...PublicCoursesDetailFields
                }
            }
            courseGroupMenu {
                shortcut
            }
        }
    }
    ${CourseGroupDetailsFieldsFragmentDoc}
    ${PublicCoursesDetailFieldsFragmentDoc}
`;

/**
 * __useGetPublicCourseDetailsForCourseQuery__
 *
 * To run a query within a React component, call `useGetPublicCourseDetailsForCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicCourseDetailsForCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicCourseDetailsForCourseQuery({
 *   variables: {
 *      courseNodeId: // value for 'courseNodeId'
 *   },
 * });
 */
export function useGetPublicCourseDetailsForCourseQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetPublicCourseDetailsForCourseQuery,
        GetPublicCourseDetailsForCourseQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<
        GetPublicCourseDetailsForCourseQuery,
        GetPublicCourseDetailsForCourseQueryVariables
    >(GetPublicCourseDetailsForCourseDocument, options);
}
export function useGetPublicCourseDetailsForCourseLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetPublicCourseDetailsForCourseQuery,
        GetPublicCourseDetailsForCourseQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetPublicCourseDetailsForCourseQuery,
        GetPublicCourseDetailsForCourseQueryVariables
    >(GetPublicCourseDetailsForCourseDocument, options);
}
export type GetPublicCourseDetailsForCourseQueryHookResult = ReturnType<typeof useGetPublicCourseDetailsForCourseQuery>;
export type GetPublicCourseDetailsForCourseLazyQueryHookResult = ReturnType<
    typeof useGetPublicCourseDetailsForCourseLazyQuery
>;
export type GetPublicCourseDetailsForCourseQueryResult = Apollo.QueryResult<
    GetPublicCourseDetailsForCourseQuery,
    GetPublicCourseDetailsForCourseQueryVariables
>;
export const GetPublicCourseSignupDocument = gql`
    query getPublicCourseSignup($courseScheduleId: ID!) {
        courseScheduleByNodeId(nodeId: $courseScheduleId) {
            nodeId
            ...PublicCourseSignup
        }
    }
    ${PublicCourseSignupFragmentDoc}
`;

/**
 * __useGetPublicCourseSignupQuery__
 *
 * To run a query within a React component, call `useGetPublicCourseSignupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicCourseSignupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicCourseSignupQuery({
 *   variables: {
 *      courseScheduleId: // value for 'courseScheduleId'
 *   },
 * });
 */
export function useGetPublicCourseSignupQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<GetPublicCourseSignupQuery, GetPublicCourseSignupQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetPublicCourseSignupQuery, GetPublicCourseSignupQueryVariables>(
        GetPublicCourseSignupDocument,
        options,
    );
}
export function useGetPublicCourseSignupLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetPublicCourseSignupQuery,
        GetPublicCourseSignupQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<GetPublicCourseSignupQuery, GetPublicCourseSignupQueryVariables>(
        GetPublicCourseSignupDocument,
        options,
    );
}
export type GetPublicCourseSignupQueryHookResult = ReturnType<typeof useGetPublicCourseSignupQuery>;
export type GetPublicCourseSignupLazyQueryHookResult = ReturnType<typeof useGetPublicCourseSignupLazyQuery>;
export type GetPublicCourseSignupQueryResult = Apollo.QueryResult<
    GetPublicCourseSignupQuery,
    GetPublicCourseSignupQueryVariables
>;
export const GetCourseGroupMenusForTableDocument = gql`
    query getCourseGroupMenusForTable {
        courseGroupMenus(orderBy: TITLE_ASC) {
            nodes {
                title
                titleOverview
                archived
                nodeId
                courseGroups {
                    totalCount
                }
            }
        }
    }
`;

/**
 * __useGetCourseGroupMenusForTableQuery__
 *
 * To run a query within a React component, call `useGetCourseGroupMenusForTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseGroupMenusForTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseGroupMenusForTableQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCourseGroupMenusForTableQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GetCourseGroupMenusForTableQuery,
        GetCourseGroupMenusForTableQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetCourseGroupMenusForTableQuery, GetCourseGroupMenusForTableQueryVariables>(
        GetCourseGroupMenusForTableDocument,
        options,
    );
}
export function useGetCourseGroupMenusForTableLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetCourseGroupMenusForTableQuery,
        GetCourseGroupMenusForTableQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<GetCourseGroupMenusForTableQuery, GetCourseGroupMenusForTableQueryVariables>(
        GetCourseGroupMenusForTableDocument,
        options,
    );
}
export type GetCourseGroupMenusForTableQueryHookResult = ReturnType<typeof useGetCourseGroupMenusForTableQuery>;
export type GetCourseGroupMenusForTableLazyQueryHookResult = ReturnType<typeof useGetCourseGroupMenusForTableLazyQuery>;
export type GetCourseGroupMenusForTableQueryResult = Apollo.QueryResult<
    GetCourseGroupMenusForTableQuery,
    GetCourseGroupMenusForTableQueryVariables
>;
export const GetCourseGroupMenuDetailsDocument = gql`
    query getCourseGroupMenuDetails($nodeId: ID!) {
        courseGroupMenuByNodeId(nodeId: $nodeId) {
            ...CourseGroupMenuDetailsFields
            courseGroups {
                totalCount
            }
        }
    }
    ${CourseGroupMenuDetailsFieldsFragmentDoc}
`;

/**
 * __useGetCourseGroupMenuDetailsQuery__
 *
 * To run a query within a React component, call `useGetCourseGroupMenuDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseGroupMenuDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseGroupMenuDetailsQuery({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useGetCourseGroupMenuDetailsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetCourseGroupMenuDetailsQuery,
        GetCourseGroupMenuDetailsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetCourseGroupMenuDetailsQuery, GetCourseGroupMenuDetailsQueryVariables>(
        GetCourseGroupMenuDetailsDocument,
        options,
    );
}
export function useGetCourseGroupMenuDetailsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetCourseGroupMenuDetailsQuery,
        GetCourseGroupMenuDetailsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<GetCourseGroupMenuDetailsQuery, GetCourseGroupMenuDetailsQueryVariables>(
        GetCourseGroupMenuDetailsDocument,
        options,
    );
}
export type GetCourseGroupMenuDetailsQueryHookResult = ReturnType<typeof useGetCourseGroupMenuDetailsQuery>;
export type GetCourseGroupMenuDetailsLazyQueryHookResult = ReturnType<typeof useGetCourseGroupMenuDetailsLazyQuery>;
export type GetCourseGroupMenuDetailsQueryResult = Apollo.QueryResult<
    GetCourseGroupMenuDetailsQuery,
    GetCourseGroupMenuDetailsQueryVariables
>;
export const UpdateCourseGroupMenuDetailsDocument = gql`
    mutation updateCourseGroupMenuDetails($nodeId: ID!, $courseGroupMenuPatch: CourseGroupMenuPatch!) {
        updateCourseGroupMenuByNodeId(input: { nodeId: $nodeId, patch: $courseGroupMenuPatch }) {
            clientMutationId
        }
    }
`;
export type UpdateCourseGroupMenuDetailsMutationFn = Apollo.MutationFunction<
    UpdateCourseGroupMenuDetailsMutation,
    UpdateCourseGroupMenuDetailsMutationVariables
>;

/**
 * __useUpdateCourseGroupMenuDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateCourseGroupMenuDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseGroupMenuDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseGroupMenuDetailsMutation, { data, loading, error }] = useUpdateCourseGroupMenuDetailsMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *      courseGroupMenuPatch: // value for 'courseGroupMenuPatch'
 *   },
 * });
 */
export function useUpdateCourseGroupMenuDetailsMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateCourseGroupMenuDetailsMutation,
        UpdateCourseGroupMenuDetailsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateCourseGroupMenuDetailsMutation,
        UpdateCourseGroupMenuDetailsMutationVariables
    >(UpdateCourseGroupMenuDetailsDocument, options);
}
export type UpdateCourseGroupMenuDetailsMutationHookResult = ReturnType<typeof useUpdateCourseGroupMenuDetailsMutation>;
export type UpdateCourseGroupMenuDetailsMutationResult = Apollo.MutationResult<UpdateCourseGroupMenuDetailsMutation>;
export type UpdateCourseGroupMenuDetailsMutationOptions = Apollo.BaseMutationOptions<
    UpdateCourseGroupMenuDetailsMutation,
    UpdateCourseGroupMenuDetailsMutationVariables
>;
export const CreateCourseGroupMenuDocument = gql`
    mutation createCourseGroupMenu($courseGroupMenuInput: CreateCourseGroupMenuInput!) {
        createCourseGroupMenu(input: $courseGroupMenuInput) {
            courseGroupMenu {
                nodeId
            }
        }
    }
`;
export type CreateCourseGroupMenuMutationFn = Apollo.MutationFunction<
    CreateCourseGroupMenuMutation,
    CreateCourseGroupMenuMutationVariables
>;

/**
 * __useCreateCourseGroupMenuMutation__
 *
 * To run a mutation, you first call `useCreateCourseGroupMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseGroupMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseGroupMenuMutation, { data, loading, error }] = useCreateCourseGroupMenuMutation({
 *   variables: {
 *      courseGroupMenuInput: // value for 'courseGroupMenuInput'
 *   },
 * });
 */
export function useCreateCourseGroupMenuMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateCourseGroupMenuMutation,
        CreateCourseGroupMenuMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<CreateCourseGroupMenuMutation, CreateCourseGroupMenuMutationVariables>(
        CreateCourseGroupMenuDocument,
        options,
    );
}
export type CreateCourseGroupMenuMutationHookResult = ReturnType<typeof useCreateCourseGroupMenuMutation>;
export type CreateCourseGroupMenuMutationResult = Apollo.MutationResult<CreateCourseGroupMenuMutation>;
export type CreateCourseGroupMenuMutationOptions = Apollo.BaseMutationOptions<
    CreateCourseGroupMenuMutation,
    CreateCourseGroupMenuMutationVariables
>;
export const DeleteCourseGroupMenuDocument = gql`
    mutation deleteCourseGroupMenu($nodeId: ID!) {
        deleteCourseGroupMenuByNodeId(input: { nodeId: $nodeId }) {
            deletedCourseGroupMenuNodeId
        }
    }
`;
export type DeleteCourseGroupMenuMutationFn = Apollo.MutationFunction<
    DeleteCourseGroupMenuMutation,
    DeleteCourseGroupMenuMutationVariables
>;

/**
 * __useDeleteCourseGroupMenuMutation__
 *
 * To run a mutation, you first call `useDeleteCourseGroupMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCourseGroupMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCourseGroupMenuMutation, { data, loading, error }] = useDeleteCourseGroupMenuMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useDeleteCourseGroupMenuMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DeleteCourseGroupMenuMutation,
        DeleteCourseGroupMenuMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<DeleteCourseGroupMenuMutation, DeleteCourseGroupMenuMutationVariables>(
        DeleteCourseGroupMenuDocument,
        options,
    );
}
export type DeleteCourseGroupMenuMutationHookResult = ReturnType<typeof useDeleteCourseGroupMenuMutation>;
export type DeleteCourseGroupMenuMutationResult = Apollo.MutationResult<DeleteCourseGroupMenuMutation>;
export type DeleteCourseGroupMenuMutationOptions = Apollo.BaseMutationOptions<
    DeleteCourseGroupMenuMutation,
    DeleteCourseGroupMenuMutationVariables
>;
export const SendEmailWelcomeDocument = gql`
    mutation sendEmailWelcome(
        $recipient: String!
        $course_id: Int!
        $course_schedule_node_id: String!
        $already_registered: Boolean!
    ) {
        sendEmailWelcome(
            input: {
                recipient: $recipient
                courseId: $course_id
                courseScheduleNodeId: $course_schedule_node_id
                alreadyRegistered: $already_registered
            }
        ) {
            string
        }
    }
`;
export type SendEmailWelcomeMutationFn = Apollo.MutationFunction<
    SendEmailWelcomeMutation,
    SendEmailWelcomeMutationVariables
>;

/**
 * __useSendEmailWelcomeMutation__
 *
 * To run a mutation, you first call `useSendEmailWelcomeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailWelcomeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailWelcomeMutation, { data, loading, error }] = useSendEmailWelcomeMutation({
 *   variables: {
 *      recipient: // value for 'recipient'
 *      course_id: // value for 'course_id'
 *      course_schedule_node_id: // value for 'course_schedule_node_id'
 *      already_registered: // value for 'already_registered'
 *   },
 * });
 */
export function useSendEmailWelcomeMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<SendEmailWelcomeMutation, SendEmailWelcomeMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<SendEmailWelcomeMutation, SendEmailWelcomeMutationVariables>(
        SendEmailWelcomeDocument,
        options,
    );
}
export type SendEmailWelcomeMutationHookResult = ReturnType<typeof useSendEmailWelcomeMutation>;
export type SendEmailWelcomeMutationResult = Apollo.MutationResult<SendEmailWelcomeMutation>;
export type SendEmailWelcomeMutationOptions = Apollo.BaseMutationOptions<
    SendEmailWelcomeMutation,
    SendEmailWelcomeMutationVariables
>;
export const SendEmailSignupConfirmationDocument = gql`
    mutation sendEmailSignupConfirmation($booking_id: Int!, $template_name: String!) {
        sendEmailSignupConfirmation(input: { bookingid: $booking_id, templateName: $template_name }) {
            string
        }
    }
`;
export type SendEmailSignupConfirmationMutationFn = Apollo.MutationFunction<
    SendEmailSignupConfirmationMutation,
    SendEmailSignupConfirmationMutationVariables
>;

/**
 * __useSendEmailSignupConfirmationMutation__
 *
 * To run a mutation, you first call `useSendEmailSignupConfirmationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailSignupConfirmationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailSignupConfirmationMutation, { data, loading, error }] = useSendEmailSignupConfirmationMutation({
 *   variables: {
 *      booking_id: // value for 'booking_id'
 *      template_name: // value for 'template_name'
 *   },
 * });
 */
export function useSendEmailSignupConfirmationMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        SendEmailSignupConfirmationMutation,
        SendEmailSignupConfirmationMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        SendEmailSignupConfirmationMutation,
        SendEmailSignupConfirmationMutationVariables
    >(SendEmailSignupConfirmationDocument, options);
}
export type SendEmailSignupConfirmationMutationHookResult = ReturnType<typeof useSendEmailSignupConfirmationMutation>;
export type SendEmailSignupConfirmationMutationResult = Apollo.MutationResult<SendEmailSignupConfirmationMutation>;
export type SendEmailSignupConfirmationMutationOptions = Apollo.BaseMutationOptions<
    SendEmailSignupConfirmationMutation,
    SendEmailSignupConfirmationMutationVariables
>;
export const CreateParticipantDocument = gql`
    mutation createParticipant($participantInput: CreateParticipantInput!) {
        createParticipant(input: $participantInput) {
            participant {
                nodeId
                id
            }
        }
    }
`;
export type CreateParticipantMutationFn = Apollo.MutationFunction<
    CreateParticipantMutation,
    CreateParticipantMutationVariables
>;

/**
 * __useCreateParticipantMutation__
 *
 * To run a mutation, you first call `useCreateParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createParticipantMutation, { data, loading, error }] = useCreateParticipantMutation({
 *   variables: {
 *      participantInput: // value for 'participantInput'
 *   },
 * });
 */
export function useCreateParticipantMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<CreateParticipantMutation, CreateParticipantMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<CreateParticipantMutation, CreateParticipantMutationVariables>(
        CreateParticipantDocument,
        options,
    );
}
export type CreateParticipantMutationHookResult = ReturnType<typeof useCreateParticipantMutation>;
export type CreateParticipantMutationResult = Apollo.MutationResult<CreateParticipantMutation>;
export type CreateParticipantMutationOptions = Apollo.BaseMutationOptions<
    CreateParticipantMutation,
    CreateParticipantMutationVariables
>;
export const CreateBookingDocument = gql`
    mutation createBooking($bookingInput: CreateBookingInput!) {
        createBooking(input: $bookingInput) {
            booking {
                nodeId
                id
            }
        }
    }
`;
export type CreateBookingMutationFn = Apollo.MutationFunction<CreateBookingMutation, CreateBookingMutationVariables>;

/**
 * __useCreateBookingMutation__
 *
 * To run a mutation, you first call `useCreateBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookingMutation, { data, loading, error }] = useCreateBookingMutation({
 *   variables: {
 *      bookingInput: // value for 'bookingInput'
 *   },
 * });
 */
export function useCreateBookingMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBookingMutation, CreateBookingMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<CreateBookingMutation, CreateBookingMutationVariables>(
        CreateBookingDocument,
        options,
    );
}
export type CreateBookingMutationHookResult = ReturnType<typeof useCreateBookingMutation>;
export type CreateBookingMutationResult = Apollo.MutationResult<CreateBookingMutation>;
export type CreateBookingMutationOptions = Apollo.BaseMutationOptions<
    CreateBookingMutation,
    CreateBookingMutationVariables
>;
export const DeleteCodeDocument = gql`
    mutation deleteCode($code: UUID!) {
        deleteEmailCodeByCode(input: { code: $code }) {
            clientMutationId
        }
    }
`;
export type DeleteCodeMutationFn = Apollo.MutationFunction<DeleteCodeMutation, DeleteCodeMutationVariables>;

/**
 * __useDeleteCodeMutation__
 *
 * To run a mutation, you first call `useDeleteCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCodeMutation, { data, loading, error }] = useDeleteCodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useDeleteCodeMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCodeMutation, DeleteCodeMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<DeleteCodeMutation, DeleteCodeMutationVariables>(DeleteCodeDocument, options);
}
export type DeleteCodeMutationHookResult = ReturnType<typeof useDeleteCodeMutation>;
export type DeleteCodeMutationResult = Apollo.MutationResult<DeleteCodeMutation>;
export type DeleteCodeMutationOptions = Apollo.BaseMutationOptions<DeleteCodeMutation, DeleteCodeMutationVariables>;
export const GetEmailCodeInfoDocument = gql`
    query getEmailCodeInfo($code: UUID!) {
        emailCodes(condition: { code: $code }) {
            nodes {
                courseScheduleNodeId
                email
                participant {
                    ...ParticipantSignUpFields
                }
            }
        }
    }
    ${ParticipantSignUpFieldsFragmentDoc}
`;

/**
 * __useGetEmailCodeInfoQuery__
 *
 * To run a query within a React component, call `useGetEmailCodeInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailCodeInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailCodeInfoQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetEmailCodeInfoQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<GetEmailCodeInfoQuery, GetEmailCodeInfoQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetEmailCodeInfoQuery, GetEmailCodeInfoQueryVariables>(
        GetEmailCodeInfoDocument,
        options,
    );
}
export function useGetEmailCodeInfoLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmailCodeInfoQuery, GetEmailCodeInfoQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<GetEmailCodeInfoQuery, GetEmailCodeInfoQueryVariables>(
        GetEmailCodeInfoDocument,
        options,
    );
}
export type GetEmailCodeInfoQueryHookResult = ReturnType<typeof useGetEmailCodeInfoQuery>;
export type GetEmailCodeInfoLazyQueryHookResult = ReturnType<typeof useGetEmailCodeInfoLazyQuery>;
export type GetEmailCodeInfoQueryResult = Apollo.QueryResult<GetEmailCodeInfoQuery, GetEmailCodeInfoQueryVariables>;
export const CourseScheduleBookableDocument = gql`
    mutation courseScheduleBookable($recipient: String!, $courseScheduleDbId: Int!) {
        courseScheduleBookable(input: { recipient: $recipient, pCourseScheduleId: $courseScheduleDbId }) {
            boolean
        }
    }
`;
export type CourseScheduleBookableMutationFn = Apollo.MutationFunction<
    CourseScheduleBookableMutation,
    CourseScheduleBookableMutationVariables
>;

/**
 * __useCourseScheduleBookableMutation__
 *
 * To run a mutation, you first call `useCourseScheduleBookableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseScheduleBookableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseScheduleBookableMutation, { data, loading, error }] = useCourseScheduleBookableMutation({
 *   variables: {
 *      recipient: // value for 'recipient'
 *      courseScheduleDbId: // value for 'courseScheduleDbId'
 *   },
 * });
 */
export function useCourseScheduleBookableMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CourseScheduleBookableMutation,
        CourseScheduleBookableMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<CourseScheduleBookableMutation, CourseScheduleBookableMutationVariables>(
        CourseScheduleBookableDocument,
        options,
    );
}
export type CourseScheduleBookableMutationHookResult = ReturnType<typeof useCourseScheduleBookableMutation>;
export type CourseScheduleBookableMutationResult = Apollo.MutationResult<CourseScheduleBookableMutation>;
export type CourseScheduleBookableMutationOptions = Apollo.BaseMutationOptions<
    CourseScheduleBookableMutation,
    CourseScheduleBookableMutationVariables
>;
export const UpdateBookingDetailsDocument = gql`
    mutation updateBookingDetails($nodeId: ID!, $bookingPatch: BookingPatch!) {
        updateBookingByNodeId(input: { nodeId: $nodeId, patch: $bookingPatch }) {
            clientMutationId
            booking {
                ...BookingDetails
            }
        }
    }
    ${BookingDetailsFragmentDoc}
`;
export type UpdateBookingDetailsMutationFn = Apollo.MutationFunction<
    UpdateBookingDetailsMutation,
    UpdateBookingDetailsMutationVariables
>;

/**
 * __useUpdateBookingDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateBookingDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookingDetailsMutation, { data, loading, error }] = useUpdateBookingDetailsMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *      bookingPatch: // value for 'bookingPatch'
 *   },
 * });
 */
export function useUpdateBookingDetailsMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateBookingDetailsMutation,
        UpdateBookingDetailsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<UpdateBookingDetailsMutation, UpdateBookingDetailsMutationVariables>(
        UpdateBookingDetailsDocument,
        options,
    );
}
export type UpdateBookingDetailsMutationHookResult = ReturnType<typeof useUpdateBookingDetailsMutation>;
export type UpdateBookingDetailsMutationResult = Apollo.MutationResult<UpdateBookingDetailsMutation>;
export type UpdateBookingDetailsMutationOptions = Apollo.BaseMutationOptions<
    UpdateBookingDetailsMutation,
    UpdateBookingDetailsMutationVariables
>;
export const CreateParticipantsListDocument = gql`
    mutation createParticipantsList($courseScheduleDbId: Int!, $overrideDescription: String, $emptyLines: Int) {
        createParticipantsList(
            input: {
                selectedCourseScheduleId: $courseScheduleDbId
                overrideDescription: $overrideDescription
                emptyLines: $emptyLines
            }
        ) {
            clientMutationId
            string
        }
    }
`;
export type CreateParticipantsListMutationFn = Apollo.MutationFunction<
    CreateParticipantsListMutation,
    CreateParticipantsListMutationVariables
>;

/**
 * __useCreateParticipantsListMutation__
 *
 * To run a mutation, you first call `useCreateParticipantsListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateParticipantsListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createParticipantsListMutation, { data, loading, error }] = useCreateParticipantsListMutation({
 *   variables: {
 *      courseScheduleDbId: // value for 'courseScheduleDbId'
 *      overrideDescription: // value for 'overrideDescription'
 *      emptyLines: // value for 'emptyLines'
 *   },
 * });
 */
export function useCreateParticipantsListMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateParticipantsListMutation,
        CreateParticipantsListMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<CreateParticipantsListMutation, CreateParticipantsListMutationVariables>(
        CreateParticipantsListDocument,
        options,
    );
}
export type CreateParticipantsListMutationHookResult = ReturnType<typeof useCreateParticipantsListMutation>;
export type CreateParticipantsListMutationResult = Apollo.MutationResult<CreateParticipantsListMutation>;
export type CreateParticipantsListMutationOptions = Apollo.BaseMutationOptions<
    CreateParticipantsListMutation,
    CreateParticipantsListMutationVariables
>;
export const CreateParticipantsSepaXmlDocument = gql`
    mutation createParticipantsSepaXml($courseScheduleDbId: Int!) {
        createParticipantsSepaXml(input: { selectedCourseScheduleId: $courseScheduleDbId }) {
            clientMutationId
            string
        }
    }
`;
export type CreateParticipantsSepaXmlMutationFn = Apollo.MutationFunction<
    CreateParticipantsSepaXmlMutation,
    CreateParticipantsSepaXmlMutationVariables
>;

/**
 * __useCreateParticipantsSepaXmlMutation__
 *
 * To run a mutation, you first call `useCreateParticipantsSepaXmlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateParticipantsSepaXmlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createParticipantsSepaXmlMutation, { data, loading, error }] = useCreateParticipantsSepaXmlMutation({
 *   variables: {
 *      courseScheduleDbId: // value for 'courseScheduleDbId'
 *   },
 * });
 */
export function useCreateParticipantsSepaXmlMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateParticipantsSepaXmlMutation,
        CreateParticipantsSepaXmlMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<CreateParticipantsSepaXmlMutation, CreateParticipantsSepaXmlMutationVariables>(
        CreateParticipantsSepaXmlDocument,
        options,
    );
}
export type CreateParticipantsSepaXmlMutationHookResult = ReturnType<typeof useCreateParticipantsSepaXmlMutation>;
export type CreateParticipantsSepaXmlMutationResult = Apollo.MutationResult<CreateParticipantsSepaXmlMutation>;
export type CreateParticipantsSepaXmlMutationOptions = Apollo.BaseMutationOptions<
    CreateParticipantsSepaXmlMutation,
    CreateParticipantsSepaXmlMutationVariables
>;
export const UpdatePassedAllParticipantsDocument = gql`
    mutation updatePassedAllParticipants($courseScheduleDbId: Int!) {
        updatePassedAllParticipants(input: { selectedCourseScheduleId: $courseScheduleDbId }) {
            clientMutationId
            integer
        }
    }
`;
export type UpdatePassedAllParticipantsMutationFn = Apollo.MutationFunction<
    UpdatePassedAllParticipantsMutation,
    UpdatePassedAllParticipantsMutationVariables
>;

/**
 * __useUpdatePassedAllParticipantsMutation__
 *
 * To run a mutation, you first call `useUpdatePassedAllParticipantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePassedAllParticipantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePassedAllParticipantsMutation, { data, loading, error }] = useUpdatePassedAllParticipantsMutation({
 *   variables: {
 *      courseScheduleDbId: // value for 'courseScheduleDbId'
 *   },
 * });
 */
export function useUpdatePassedAllParticipantsMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdatePassedAllParticipantsMutation,
        UpdatePassedAllParticipantsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdatePassedAllParticipantsMutation,
        UpdatePassedAllParticipantsMutationVariables
    >(UpdatePassedAllParticipantsDocument, options);
}
export type UpdatePassedAllParticipantsMutationHookResult = ReturnType<typeof useUpdatePassedAllParticipantsMutation>;
export type UpdatePassedAllParticipantsMutationResult = Apollo.MutationResult<UpdatePassedAllParticipantsMutation>;
export type UpdatePassedAllParticipantsMutationOptions = Apollo.BaseMutationOptions<
    UpdatePassedAllParticipantsMutation,
    UpdatePassedAllParticipantsMutationVariables
>;
export const UpdateAddAllPassedParticipantsToCertificateDocument = gql`
    mutation updateAddAllPassedParticipantsToCertificate($courseScheduleDbId: Int!) {
        updateAddAllPassedParticipantsToCertificate(input: { selectedCourseScheduleId: $courseScheduleDbId }) {
            clientMutationId
            integer
        }
    }
`;
export type UpdateAddAllPassedParticipantsToCertificateMutationFn = Apollo.MutationFunction<
    UpdateAddAllPassedParticipantsToCertificateMutation,
    UpdateAddAllPassedParticipantsToCertificateMutationVariables
>;

/**
 * __useUpdateAddAllPassedParticipantsToCertificateMutation__
 *
 * To run a mutation, you first call `useUpdateAddAllPassedParticipantsToCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAddAllPassedParticipantsToCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAddAllPassedParticipantsToCertificateMutation, { data, loading, error }] = useUpdateAddAllPassedParticipantsToCertificateMutation({
 *   variables: {
 *      courseScheduleDbId: // value for 'courseScheduleDbId'
 *   },
 * });
 */
export function useUpdateAddAllPassedParticipantsToCertificateMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateAddAllPassedParticipantsToCertificateMutation,
        UpdateAddAllPassedParticipantsToCertificateMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateAddAllPassedParticipantsToCertificateMutation,
        UpdateAddAllPassedParticipantsToCertificateMutationVariables
    >(UpdateAddAllPassedParticipantsToCertificateDocument, options);
}
export type UpdateAddAllPassedParticipantsToCertificateMutationHookResult = ReturnType<
    typeof useUpdateAddAllPassedParticipantsToCertificateMutation
>;
export type UpdateAddAllPassedParticipantsToCertificateMutationResult =
    Apollo.MutationResult<UpdateAddAllPassedParticipantsToCertificateMutation>;
export type UpdateAddAllPassedParticipantsToCertificateMutationOptions = Apollo.BaseMutationOptions<
    UpdateAddAllPassedParticipantsToCertificateMutation,
    UpdateAddAllPassedParticipantsToCertificateMutationVariables
>;
export const UpdateCourseDetailsDocument = gql`
    mutation updateCourseDetails($nodeId: ID!, $coursePatch: CoursePatch!) {
        updateCourseByNodeId(input: { nodeId: $nodeId, patch: $coursePatch }) {
            clientMutationId
            course {
                ...CourseDetailFields
            }
        }
    }
    ${CourseDetailFieldsFragmentDoc}
`;
export type UpdateCourseDetailsMutationFn = Apollo.MutationFunction<
    UpdateCourseDetailsMutation,
    UpdateCourseDetailsMutationVariables
>;

/**
 * __useUpdateCourseDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateCourseDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseDetailsMutation, { data, loading, error }] = useUpdateCourseDetailsMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *      coursePatch: // value for 'coursePatch'
 *   },
 * });
 */
export function useUpdateCourseDetailsMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateCourseDetailsMutation,
        UpdateCourseDetailsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<UpdateCourseDetailsMutation, UpdateCourseDetailsMutationVariables>(
        UpdateCourseDetailsDocument,
        options,
    );
}
export type UpdateCourseDetailsMutationHookResult = ReturnType<typeof useUpdateCourseDetailsMutation>;
export type UpdateCourseDetailsMutationResult = Apollo.MutationResult<UpdateCourseDetailsMutation>;
export type UpdateCourseDetailsMutationOptions = Apollo.BaseMutationOptions<
    UpdateCourseDetailsMutation,
    UpdateCourseDetailsMutationVariables
>;
export const UpdateCourseScheduleDetailsDocument = gql`
    mutation updateCourseScheduleDetails($nodeId: ID!, $courseSchedulePatch: CourseSchedulePatch!) {
        updateCourseScheduleByNodeId(input: { nodeId: $nodeId, patch: $courseSchedulePatch }) {
            clientMutationId
            courseSchedule {
                ...CourseScheduleFields
            }
        }
    }
    ${CourseScheduleFieldsFragmentDoc}
`;
export type UpdateCourseScheduleDetailsMutationFn = Apollo.MutationFunction<
    UpdateCourseScheduleDetailsMutation,
    UpdateCourseScheduleDetailsMutationVariables
>;

/**
 * __useUpdateCourseScheduleDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateCourseScheduleDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseScheduleDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseScheduleDetailsMutation, { data, loading, error }] = useUpdateCourseScheduleDetailsMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *      courseSchedulePatch: // value for 'courseSchedulePatch'
 *   },
 * });
 */
export function useUpdateCourseScheduleDetailsMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateCourseScheduleDetailsMutation,
        UpdateCourseScheduleDetailsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateCourseScheduleDetailsMutation,
        UpdateCourseScheduleDetailsMutationVariables
    >(UpdateCourseScheduleDetailsDocument, options);
}
export type UpdateCourseScheduleDetailsMutationHookResult = ReturnType<typeof useUpdateCourseScheduleDetailsMutation>;
export type UpdateCourseScheduleDetailsMutationResult = Apollo.MutationResult<UpdateCourseScheduleDetailsMutation>;
export type UpdateCourseScheduleDetailsMutationOptions = Apollo.BaseMutationOptions<
    UpdateCourseScheduleDetailsMutation,
    UpdateCourseScheduleDetailsMutationVariables
>;
export const CreateCourseScheduleDocument = gql`
    mutation createCourseSchedule($courseScheduleInput: CreateCourseScheduleInput!) {
        createCourseSchedule(input: $courseScheduleInput) {
            courseSchedule {
                nodeId
                dateSchedule
                id
            }
        }
    }
`;
export type CreateCourseScheduleMutationFn = Apollo.MutationFunction<
    CreateCourseScheduleMutation,
    CreateCourseScheduleMutationVariables
>;

/**
 * __useCreateCourseScheduleMutation__
 *
 * To run a mutation, you first call `useCreateCourseScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseScheduleMutation, { data, loading, error }] = useCreateCourseScheduleMutation({
 *   variables: {
 *      courseScheduleInput: // value for 'courseScheduleInput'
 *   },
 * });
 */
export function useCreateCourseScheduleMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateCourseScheduleMutation,
        CreateCourseScheduleMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<CreateCourseScheduleMutation, CreateCourseScheduleMutationVariables>(
        CreateCourseScheduleDocument,
        options,
    );
}
export type CreateCourseScheduleMutationHookResult = ReturnType<typeof useCreateCourseScheduleMutation>;
export type CreateCourseScheduleMutationResult = Apollo.MutationResult<CreateCourseScheduleMutation>;
export type CreateCourseScheduleMutationOptions = Apollo.BaseMutationOptions<
    CreateCourseScheduleMutation,
    CreateCourseScheduleMutationVariables
>;
export const GetPossibleRelocationSchedulesDocument = gql`
    query getPossibleRelocationSchedules($relocationCourseId: Int!, $relocationCourseScheduleId: Int!) {
        courseSchedules(
            filter: {
                courseId: { equalTo: $relocationCourseId }
                id: { notEqualTo: $relocationCourseScheduleId }
                status: { equalTo: PLANNED }
            }
        ) {
            nodes {
                ...RelocationCourseScheduleFields
            }
            totalCount
        }
    }
    ${RelocationCourseScheduleFieldsFragmentDoc}
`;

/**
 * __useGetPossibleRelocationSchedulesQuery__
 *
 * To run a query within a React component, call `useGetPossibleRelocationSchedulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPossibleRelocationSchedulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPossibleRelocationSchedulesQuery({
 *   variables: {
 *      relocationCourseId: // value for 'relocationCourseId'
 *      relocationCourseScheduleId: // value for 'relocationCourseScheduleId'
 *   },
 * });
 */
export function useGetPossibleRelocationSchedulesQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetPossibleRelocationSchedulesQuery,
        GetPossibleRelocationSchedulesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetPossibleRelocationSchedulesQuery, GetPossibleRelocationSchedulesQueryVariables>(
        GetPossibleRelocationSchedulesDocument,
        options,
    );
}
export function useGetPossibleRelocationSchedulesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetPossibleRelocationSchedulesQuery,
        GetPossibleRelocationSchedulesQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<
        GetPossibleRelocationSchedulesQuery,
        GetPossibleRelocationSchedulesQueryVariables
    >(GetPossibleRelocationSchedulesDocument, options);
}
export type GetPossibleRelocationSchedulesQueryHookResult = ReturnType<typeof useGetPossibleRelocationSchedulesQuery>;
export type GetPossibleRelocationSchedulesLazyQueryHookResult = ReturnType<
    typeof useGetPossibleRelocationSchedulesLazyQuery
>;
export type GetPossibleRelocationSchedulesQueryResult = Apollo.QueryResult<
    GetPossibleRelocationSchedulesQuery,
    GetPossibleRelocationSchedulesQueryVariables
>;
export const CreateParticipantPassedDocumentsDocument = gql`
    mutation createParticipantPassedDocuments($courseScheduleDbId: Int!) {
        createParticipantPassedDocuments(input: { selectedCourseScheduleId: $courseScheduleDbId }) {
            clientMutationId
            string
        }
    }
`;
export type CreateParticipantPassedDocumentsMutationFn = Apollo.MutationFunction<
    CreateParticipantPassedDocumentsMutation,
    CreateParticipantPassedDocumentsMutationVariables
>;

/**
 * __useCreateParticipantPassedDocumentsMutation__
 *
 * To run a mutation, you first call `useCreateParticipantPassedDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateParticipantPassedDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createParticipantPassedDocumentsMutation, { data, loading, error }] = useCreateParticipantPassedDocumentsMutation({
 *   variables: {
 *      courseScheduleDbId: // value for 'courseScheduleDbId'
 *   },
 * });
 */
export function useCreateParticipantPassedDocumentsMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateParticipantPassedDocumentsMutation,
        CreateParticipantPassedDocumentsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        CreateParticipantPassedDocumentsMutation,
        CreateParticipantPassedDocumentsMutationVariables
    >(CreateParticipantPassedDocumentsDocument, options);
}
export type CreateParticipantPassedDocumentsMutationHookResult = ReturnType<
    typeof useCreateParticipantPassedDocumentsMutation
>;
export type CreateParticipantPassedDocumentsMutationResult =
    Apollo.MutationResult<CreateParticipantPassedDocumentsMutation>;
export type CreateParticipantPassedDocumentsMutationOptions = Apollo.BaseMutationOptions<
    CreateParticipantPassedDocumentsMutation,
    CreateParticipantPassedDocumentsMutationVariables
>;
export const CreateSingleParticipantPassedDocumentDocument = gql`
    mutation createSingleParticipantPassedDocument($bookingId: Int!) {
        createSingleParticipantPassedDocument(input: { bookingId: $bookingId }) {
            clientMutationId
            string
        }
    }
`;
export type CreateSingleParticipantPassedDocumentMutationFn = Apollo.MutationFunction<
    CreateSingleParticipantPassedDocumentMutation,
    CreateSingleParticipantPassedDocumentMutationVariables
>;

/**
 * __useCreateSingleParticipantPassedDocumentMutation__
 *
 * To run a mutation, you first call `useCreateSingleParticipantPassedDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSingleParticipantPassedDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSingleParticipantPassedDocumentMutation, { data, loading, error }] = useCreateSingleParticipantPassedDocumentMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useCreateSingleParticipantPassedDocumentMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateSingleParticipantPassedDocumentMutation,
        CreateSingleParticipantPassedDocumentMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        CreateSingleParticipantPassedDocumentMutation,
        CreateSingleParticipantPassedDocumentMutationVariables
    >(CreateSingleParticipantPassedDocumentDocument, options);
}
export type CreateSingleParticipantPassedDocumentMutationHookResult = ReturnType<
    typeof useCreateSingleParticipantPassedDocumentMutation
>;
export type CreateSingleParticipantPassedDocumentMutationResult =
    Apollo.MutationResult<CreateSingleParticipantPassedDocumentMutation>;
export type CreateSingleParticipantPassedDocumentMutationOptions = Apollo.BaseMutationOptions<
    CreateSingleParticipantPassedDocumentMutation,
    CreateSingleParticipantPassedDocumentMutationVariables
>;
export const CreateSingleParticipantCertificateDocumentDocument = gql`
    mutation createSingleParticipantCertificateDocument($participantId: Int!) {
        createSingleParticipantCertificateDocument(input: { singleParticipantId: $participantId }) {
            clientMutationId
            string
        }
    }
`;
export type CreateSingleParticipantCertificateDocumentMutationFn = Apollo.MutationFunction<
    CreateSingleParticipantCertificateDocumentMutation,
    CreateSingleParticipantCertificateDocumentMutationVariables
>;

/**
 * __useCreateSingleParticipantCertificateDocumentMutation__
 *
 * To run a mutation, you first call `useCreateSingleParticipantCertificateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSingleParticipantCertificateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSingleParticipantCertificateDocumentMutation, { data, loading, error }] = useCreateSingleParticipantCertificateDocumentMutation({
 *   variables: {
 *      participantId: // value for 'participantId'
 *   },
 * });
 */
export function useCreateSingleParticipantCertificateDocumentMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateSingleParticipantCertificateDocumentMutation,
        CreateSingleParticipantCertificateDocumentMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        CreateSingleParticipantCertificateDocumentMutation,
        CreateSingleParticipantCertificateDocumentMutationVariables
    >(CreateSingleParticipantCertificateDocumentDocument, options);
}
export type CreateSingleParticipantCertificateDocumentMutationHookResult = ReturnType<
    typeof useCreateSingleParticipantCertificateDocumentMutation
>;
export type CreateSingleParticipantCertificateDocumentMutationResult =
    Apollo.MutationResult<CreateSingleParticipantCertificateDocumentMutation>;
export type CreateSingleParticipantCertificateDocumentMutationOptions = Apollo.BaseMutationOptions<
    CreateSingleParticipantCertificateDocumentMutation,
    CreateSingleParticipantCertificateDocumentMutationVariables
>;
export const GetPassedCountDocument = gql`
    query getPassedCount($courseScheduleDbId: Int!) {
        courseSchedule(id: $courseScheduleDbId) {
            bookings(condition: { passed: true }) {
                totalCount
            }
            id
        }
    }
`;

/**
 * __useGetPassedCountQuery__
 *
 * To run a query within a React component, call `useGetPassedCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPassedCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPassedCountQuery({
 *   variables: {
 *      courseScheduleDbId: // value for 'courseScheduleDbId'
 *   },
 * });
 */
export function useGetPassedCountQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<GetPassedCountQuery, GetPassedCountQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetPassedCountQuery, GetPassedCountQueryVariables>(
        GetPassedCountDocument,
        options,
    );
}
export function useGetPassedCountLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPassedCountQuery, GetPassedCountQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<GetPassedCountQuery, GetPassedCountQueryVariables>(
        GetPassedCountDocument,
        options,
    );
}
export type GetPassedCountQueryHookResult = ReturnType<typeof useGetPassedCountQuery>;
export type GetPassedCountLazyQueryHookResult = ReturnType<typeof useGetPassedCountLazyQuery>;
export type GetPassedCountQueryResult = Apollo.QueryResult<GetPassedCountQuery, GetPassedCountQueryVariables>;
export const BookingsForCourseScheduleDocument = gql`
    query bookingsForCourseSchedule($courseScheduleId: Int!) {
        bookings(
            filter: {
                courseScheduleId: { equalTo: $courseScheduleId }
                registrationRegistered: { equalTo: true }
                state: { notEqualTo: CANCELLED }
            }
        ) {
            nodes {
                id
            }
            totalCount
        }
    }
`;

/**
 * __useBookingsForCourseScheduleQuery__
 *
 * To run a query within a React component, call `useBookingsForCourseScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingsForCourseScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingsForCourseScheduleQuery({
 *   variables: {
 *      courseScheduleId: // value for 'courseScheduleId'
 *   },
 * });
 */
export function useBookingsForCourseScheduleQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        BookingsForCourseScheduleQuery,
        BookingsForCourseScheduleQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<BookingsForCourseScheduleQuery, BookingsForCourseScheduleQueryVariables>(
        BookingsForCourseScheduleDocument,
        options,
    );
}
export function useBookingsForCourseScheduleLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        BookingsForCourseScheduleQuery,
        BookingsForCourseScheduleQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<BookingsForCourseScheduleQuery, BookingsForCourseScheduleQueryVariables>(
        BookingsForCourseScheduleDocument,
        options,
    );
}
export type BookingsForCourseScheduleQueryHookResult = ReturnType<typeof useBookingsForCourseScheduleQuery>;
export type BookingsForCourseScheduleLazyQueryHookResult = ReturnType<typeof useBookingsForCourseScheduleLazyQuery>;
export type BookingsForCourseScheduleQueryResult = Apollo.QueryResult<
    BookingsForCourseScheduleQuery,
    BookingsForCourseScheduleQueryVariables
>;
export const CreateBookingForParticipantDocument = gql`
    mutation createBookingForParticipant($selectedCourseScheduleId: Int!, $selectParticipantId: Int!) {
        createBookingForParticipant(
            input: { selectedCourseScheduleId: $selectedCourseScheduleId, selectParticipantId: $selectParticipantId }
        ) {
            clientMutationId
            integer
        }
    }
`;
export type CreateBookingForParticipantMutationFn = Apollo.MutationFunction<
    CreateBookingForParticipantMutation,
    CreateBookingForParticipantMutationVariables
>;

/**
 * __useCreateBookingForParticipantMutation__
 *
 * To run a mutation, you first call `useCreateBookingForParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookingForParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookingForParticipantMutation, { data, loading, error }] = useCreateBookingForParticipantMutation({
 *   variables: {
 *      selectedCourseScheduleId: // value for 'selectedCourseScheduleId'
 *      selectParticipantId: // value for 'selectParticipantId'
 *   },
 * });
 */
export function useCreateBookingForParticipantMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateBookingForParticipantMutation,
        CreateBookingForParticipantMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        CreateBookingForParticipantMutation,
        CreateBookingForParticipantMutationVariables
    >(CreateBookingForParticipantDocument, options);
}
export type CreateBookingForParticipantMutationHookResult = ReturnType<typeof useCreateBookingForParticipantMutation>;
export type CreateBookingForParticipantMutationResult = Apollo.MutationResult<CreateBookingForParticipantMutation>;
export type CreateBookingForParticipantMutationOptions = Apollo.BaseMutationOptions<
    CreateBookingForParticipantMutation,
    CreateBookingForParticipantMutationVariables
>;
export const GetAllCoursesForSelectionDocument = gql`
    query getAllCoursesForSelection {
        courses(orderBy: FULL_NAME_ASC, filter: { archived: { equalTo: false } }) {
            nodes {
                id
                name
                fullName
                fullNameNewline
                courseGroup {
                    title
                }
                courseSchedules(filter: { status: { in: [PLANNED, DONE, ACTIVE] } }, orderBy: START_DATE_DESC) {
                    nodes {
                        id
                        dateSchedule
                        dateScheduleSortable
                        startDate
                        comment
                        seats
                        freeSeats
                        status
                    }
                }
            }
            totalCount
        }
    }
`;

/**
 * __useGetAllCoursesForSelectionQuery__
 *
 * To run a query within a React component, call `useGetAllCoursesForSelectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCoursesForSelectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCoursesForSelectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCoursesForSelectionQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<
        GetAllCoursesForSelectionQuery,
        GetAllCoursesForSelectionQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetAllCoursesForSelectionQuery, GetAllCoursesForSelectionQueryVariables>(
        GetAllCoursesForSelectionDocument,
        options,
    );
}
export function useGetAllCoursesForSelectionLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetAllCoursesForSelectionQuery,
        GetAllCoursesForSelectionQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<GetAllCoursesForSelectionQuery, GetAllCoursesForSelectionQueryVariables>(
        GetAllCoursesForSelectionDocument,
        options,
    );
}
export type GetAllCoursesForSelectionQueryHookResult = ReturnType<typeof useGetAllCoursesForSelectionQuery>;
export type GetAllCoursesForSelectionLazyQueryHookResult = ReturnType<typeof useGetAllCoursesForSelectionLazyQuery>;
export type GetAllCoursesForSelectionQueryResult = Apollo.QueryResult<
    GetAllCoursesForSelectionQuery,
    GetAllCoursesForSelectionQueryVariables
>;
export const UpdateCourseScheduleSpeakersDocument = gql`
    mutation updateCourseScheduleSpeakers($selectedCourseScheduleId: Int!, $selectedSpeakerIds: [Int]!) {
        updateCourseScheduleSpeakers(
            input: { selectedCourseScheduleId: $selectedCourseScheduleId, selectedSpeakerIds: $selectedSpeakerIds }
        ) {
            clientMutationId
            boolean
        }
    }
`;
export type UpdateCourseScheduleSpeakersMutationFn = Apollo.MutationFunction<
    UpdateCourseScheduleSpeakersMutation,
    UpdateCourseScheduleSpeakersMutationVariables
>;

/**
 * __useUpdateCourseScheduleSpeakersMutation__
 *
 * To run a mutation, you first call `useUpdateCourseScheduleSpeakersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseScheduleSpeakersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseScheduleSpeakersMutation, { data, loading, error }] = useUpdateCourseScheduleSpeakersMutation({
 *   variables: {
 *      selectedCourseScheduleId: // value for 'selectedCourseScheduleId'
 *      selectedSpeakerIds: // value for 'selectedSpeakerIds'
 *   },
 * });
 */
export function useUpdateCourseScheduleSpeakersMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateCourseScheduleSpeakersMutation,
        UpdateCourseScheduleSpeakersMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateCourseScheduleSpeakersMutation,
        UpdateCourseScheduleSpeakersMutationVariables
    >(UpdateCourseScheduleSpeakersDocument, options);
}
export type UpdateCourseScheduleSpeakersMutationHookResult = ReturnType<typeof useUpdateCourseScheduleSpeakersMutation>;
export type UpdateCourseScheduleSpeakersMutationResult = Apollo.MutationResult<UpdateCourseScheduleSpeakersMutation>;
export type UpdateCourseScheduleSpeakersMutationOptions = Apollo.BaseMutationOptions<
    UpdateCourseScheduleSpeakersMutation,
    UpdateCourseScheduleSpeakersMutationVariables
>;
export const UpdateBookingsBankDataDocument = gql`
    mutation updateBookingsBankData($selectedParticipantId: Int!) {
        updateBookingsBankData(input: { selectedParticipantId: $selectedParticipantId }) {
            clientMutationId
            boolean
        }
    }
`;
export type UpdateBookingsBankDataMutationFn = Apollo.MutationFunction<
    UpdateBookingsBankDataMutation,
    UpdateBookingsBankDataMutationVariables
>;

/**
 * __useUpdateBookingsBankDataMutation__
 *
 * To run a mutation, you first call `useUpdateBookingsBankDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingsBankDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookingsBankDataMutation, { data, loading, error }] = useUpdateBookingsBankDataMutation({
 *   variables: {
 *      selectedParticipantId: // value for 'selectedParticipantId'
 *   },
 * });
 */
export function useUpdateBookingsBankDataMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateBookingsBankDataMutation,
        UpdateBookingsBankDataMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<UpdateBookingsBankDataMutation, UpdateBookingsBankDataMutationVariables>(
        UpdateBookingsBankDataDocument,
        options,
    );
}
export type UpdateBookingsBankDataMutationHookResult = ReturnType<typeof useUpdateBookingsBankDataMutation>;
export type UpdateBookingsBankDataMutationResult = Apollo.MutationResult<UpdateBookingsBankDataMutation>;
export type UpdateBookingsBankDataMutationOptions = Apollo.BaseMutationOptions<
    UpdateBookingsBankDataMutation,
    UpdateBookingsBankDataMutationVariables
>;
export const SettingsDocument = gql`
    query settings {
        settings {
            nodes {
                nodeId
                id
                key
                value
            }
        }
    }
`;

/**
 * __useSettingsQuery__
 *
 * To run a query within a React component, call `useSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingsQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<SettingsQuery, SettingsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<SettingsQuery, SettingsQueryVariables>(SettingsDocument, options);
}
export function useSettingsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SettingsQuery, SettingsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<SettingsQuery, SettingsQueryVariables>(SettingsDocument, options);
}
export type SettingsQueryHookResult = ReturnType<typeof useSettingsQuery>;
export type SettingsLazyQueryHookResult = ReturnType<typeof useSettingsLazyQuery>;
export type SettingsQueryResult = Apollo.QueryResult<SettingsQuery, SettingsQueryVariables>;
export const CombineParticipantsDocument = gql`
    mutation combineParticipants($oldParticipantId: Int!, $newParticipantId: Int!) {
        combineParticipants(input: { oldParticipantId: $oldParticipantId, newParticipantId: $newParticipantId }) {
            clientMutationId
            integer
        }
    }
`;
export type CombineParticipantsMutationFn = Apollo.MutationFunction<
    CombineParticipantsMutation,
    CombineParticipantsMutationVariables
>;

/**
 * __useCombineParticipantsMutation__
 *
 * To run a mutation, you first call `useCombineParticipantsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCombineParticipantsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [combineParticipantsMutation, { data, loading, error }] = useCombineParticipantsMutation({
 *   variables: {
 *      oldParticipantId: // value for 'oldParticipantId'
 *      newParticipantId: // value for 'newParticipantId'
 *   },
 * });
 */
export function useCombineParticipantsMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CombineParticipantsMutation,
        CombineParticipantsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<CombineParticipantsMutation, CombineParticipantsMutationVariables>(
        CombineParticipantsDocument,
        options,
    );
}
export type CombineParticipantsMutationHookResult = ReturnType<typeof useCombineParticipantsMutation>;
export type CombineParticipantsMutationResult = Apollo.MutationResult<CombineParticipantsMutation>;
export type CombineParticipantsMutationOptions = Apollo.BaseMutationOptions<
    CombineParticipantsMutation,
    CombineParticipantsMutationVariables
>;
export const GetEducationsForTableDocument = gql`
    query getEducationsForTable {
        educations(orderBy: NAME_ASC) {
            nodes {
                name
                nodeId
                id
                educationClasses {
                    totalCount
                }
            }
        }
    }
`;

/**
 * __useGetEducationsForTableQuery__
 *
 * To run a query within a React component, call `useGetEducationsForTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEducationsForTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEducationsForTableQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEducationsForTableQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<GetEducationsForTableQuery, GetEducationsForTableQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetEducationsForTableQuery, GetEducationsForTableQueryVariables>(
        GetEducationsForTableDocument,
        options,
    );
}
export function useGetEducationsForTableLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetEducationsForTableQuery,
        GetEducationsForTableQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<GetEducationsForTableQuery, GetEducationsForTableQueryVariables>(
        GetEducationsForTableDocument,
        options,
    );
}
export type GetEducationsForTableQueryHookResult = ReturnType<typeof useGetEducationsForTableQuery>;
export type GetEducationsForTableLazyQueryHookResult = ReturnType<typeof useGetEducationsForTableLazyQuery>;
export type GetEducationsForTableQueryResult = Apollo.QueryResult<
    GetEducationsForTableQuery,
    GetEducationsForTableQueryVariables
>;
export const UpdateEducationDetailsDocument = gql`
    mutation updateEducationDetails($nodeId: ID!, $educationPatch: EducationPatch!) {
        updateEducationByNodeId(input: { nodeId: $nodeId, patch: $educationPatch }) {
            education {
                nodeId
                ...EducationDetailsFields
            }
        }
    }
    ${EducationDetailsFieldsFragmentDoc}
`;
export type UpdateEducationDetailsMutationFn = Apollo.MutationFunction<
    UpdateEducationDetailsMutation,
    UpdateEducationDetailsMutationVariables
>;

/**
 * __useUpdateEducationDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateEducationDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEducationDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEducationDetailsMutation, { data, loading, error }] = useUpdateEducationDetailsMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *      educationPatch: // value for 'educationPatch'
 *   },
 * });
 */
export function useUpdateEducationDetailsMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateEducationDetailsMutation,
        UpdateEducationDetailsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<UpdateEducationDetailsMutation, UpdateEducationDetailsMutationVariables>(
        UpdateEducationDetailsDocument,
        options,
    );
}
export type UpdateEducationDetailsMutationHookResult = ReturnType<typeof useUpdateEducationDetailsMutation>;
export type UpdateEducationDetailsMutationResult = Apollo.MutationResult<UpdateEducationDetailsMutation>;
export type UpdateEducationDetailsMutationOptions = Apollo.BaseMutationOptions<
    UpdateEducationDetailsMutation,
    UpdateEducationDetailsMutationVariables
>;
export const CreateEducationDocument = gql`
    mutation createEducation($educationInput: CreateEducationInput!) {
        createEducation(input: $educationInput) {
            education {
                nodeId
            }
        }
    }
`;
export type CreateEducationMutationFn = Apollo.MutationFunction<
    CreateEducationMutation,
    CreateEducationMutationVariables
>;

/**
 * __useCreateEducationMutation__
 *
 * To run a mutation, you first call `useCreateEducationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEducationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEducationMutation, { data, loading, error }] = useCreateEducationMutation({
 *   variables: {
 *      educationInput: // value for 'educationInput'
 *   },
 * });
 */
export function useCreateEducationMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEducationMutation, CreateEducationMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<CreateEducationMutation, CreateEducationMutationVariables>(
        CreateEducationDocument,
        options,
    );
}
export type CreateEducationMutationHookResult = ReturnType<typeof useCreateEducationMutation>;
export type CreateEducationMutationResult = Apollo.MutationResult<CreateEducationMutation>;
export type CreateEducationMutationOptions = Apollo.BaseMutationOptions<
    CreateEducationMutation,
    CreateEducationMutationVariables
>;
export const GetEducationDetailsDocument = gql`
    query getEducationDetails($nodeId: ID!) {
        educationByNodeId(nodeId: $nodeId) {
            nodeId
            id
            ...EducationDetailsFields
            educationClasses {
                totalCount
            }
        }
    }
    ${EducationDetailsFieldsFragmentDoc}
`;

/**
 * __useGetEducationDetailsQuery__
 *
 * To run a query within a React component, call `useGetEducationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEducationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEducationDetailsQuery({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useGetEducationDetailsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<GetEducationDetailsQuery, GetEducationDetailsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetEducationDetailsQuery, GetEducationDetailsQueryVariables>(
        GetEducationDetailsDocument,
        options,
    );
}
export function useGetEducationDetailsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEducationDetailsQuery, GetEducationDetailsQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<GetEducationDetailsQuery, GetEducationDetailsQueryVariables>(
        GetEducationDetailsDocument,
        options,
    );
}
export type GetEducationDetailsQueryHookResult = ReturnType<typeof useGetEducationDetailsQuery>;
export type GetEducationDetailsLazyQueryHookResult = ReturnType<typeof useGetEducationDetailsLazyQuery>;
export type GetEducationDetailsQueryResult = Apollo.QueryResult<
    GetEducationDetailsQuery,
    GetEducationDetailsQueryVariables
>;
export const DeleteEducationDocument = gql`
    mutation deleteEducation($nodeId: ID!) {
        deleteEducationByNodeId(input: { nodeId: $nodeId }) {
            deletedEducationNodeId
        }
    }
`;
export type DeleteEducationMutationFn = Apollo.MutationFunction<
    DeleteEducationMutation,
    DeleteEducationMutationVariables
>;

/**
 * __useDeleteEducationMutation__
 *
 * To run a mutation, you first call `useDeleteEducationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEducationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEducationMutation, { data, loading, error }] = useDeleteEducationMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useDeleteEducationMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteEducationMutation, DeleteEducationMutationVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<DeleteEducationMutation, DeleteEducationMutationVariables>(
        DeleteEducationDocument,
        options,
    );
}
export type DeleteEducationMutationHookResult = ReturnType<typeof useDeleteEducationMutation>;
export type DeleteEducationMutationResult = Apollo.MutationResult<DeleteEducationMutation>;
export type DeleteEducationMutationOptions = Apollo.BaseMutationOptions<
    DeleteEducationMutation,
    DeleteEducationMutationVariables
>;
export const GetEducationClassesForTableDocument = gql`
    query getEducationClassesForTable($educationId: Int!) {
        educations(filter: { id: { equalTo: $educationId } }) {
            nodes {
                name
                educationClasses {
                    nodes {
                        name
                        nodeId
                        educationClassSchedules {
                            totalCount
                        }
                    }
                }
            }
        }
    }
`;

/**
 * __useGetEducationClassesForTableQuery__
 *
 * To run a query within a React component, call `useGetEducationClassesForTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEducationClassesForTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEducationClassesForTableQuery({
 *   variables: {
 *      educationId: // value for 'educationId'
 *   },
 * });
 */
export function useGetEducationClassesForTableQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetEducationClassesForTableQuery,
        GetEducationClassesForTableQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetEducationClassesForTableQuery, GetEducationClassesForTableQueryVariables>(
        GetEducationClassesForTableDocument,
        options,
    );
}
export function useGetEducationClassesForTableLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetEducationClassesForTableQuery,
        GetEducationClassesForTableQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<GetEducationClassesForTableQuery, GetEducationClassesForTableQueryVariables>(
        GetEducationClassesForTableDocument,
        options,
    );
}
export type GetEducationClassesForTableQueryHookResult = ReturnType<typeof useGetEducationClassesForTableQuery>;
export type GetEducationClassesForTableLazyQueryHookResult = ReturnType<typeof useGetEducationClassesForTableLazyQuery>;
export type GetEducationClassesForTableQueryResult = Apollo.QueryResult<
    GetEducationClassesForTableQuery,
    GetEducationClassesForTableQueryVariables
>;
export const UpdateEducationClassDetailsDocument = gql`
    mutation updateEducationClassDetails($nodeId: ID!, $educationClassPatch: EducationClassPatch!) {
        updateEducationClassByNodeId(input: { nodeId: $nodeId, patch: $educationClassPatch }) {
            educationClass {
                nodeId
                ...EducationClassDetailsFields
            }
        }
    }
    ${EducationClassDetailsFieldsFragmentDoc}
`;
export type UpdateEducationClassDetailsMutationFn = Apollo.MutationFunction<
    UpdateEducationClassDetailsMutation,
    UpdateEducationClassDetailsMutationVariables
>;

/**
 * __useUpdateEducationClassDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateEducationClassDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEducationClassDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEducationClassDetailsMutation, { data, loading, error }] = useUpdateEducationClassDetailsMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *      educationClassPatch: // value for 'educationClassPatch'
 *   },
 * });
 */
export function useUpdateEducationClassDetailsMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateEducationClassDetailsMutation,
        UpdateEducationClassDetailsMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateEducationClassDetailsMutation,
        UpdateEducationClassDetailsMutationVariables
    >(UpdateEducationClassDetailsDocument, options);
}
export type UpdateEducationClassDetailsMutationHookResult = ReturnType<typeof useUpdateEducationClassDetailsMutation>;
export type UpdateEducationClassDetailsMutationResult = Apollo.MutationResult<UpdateEducationClassDetailsMutation>;
export type UpdateEducationClassDetailsMutationOptions = Apollo.BaseMutationOptions<
    UpdateEducationClassDetailsMutation,
    UpdateEducationClassDetailsMutationVariables
>;
export const CreateEducationClassDocument = gql`
    mutation createEducationClass($educationClassInput: CreateEducationClassInput!) {
        createEducationClass(input: $educationClassInput) {
            education {
                nodeId
            }
        }
    }
`;
export type CreateEducationClassMutationFn = Apollo.MutationFunction<
    CreateEducationClassMutation,
    CreateEducationClassMutationVariables
>;

/**
 * __useCreateEducationClassMutation__
 *
 * To run a mutation, you first call `useCreateEducationClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEducationClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEducationClassMutation, { data, loading, error }] = useCreateEducationClassMutation({
 *   variables: {
 *      educationClassInput: // value for 'educationClassInput'
 *   },
 * });
 */
export function useCreateEducationClassMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateEducationClassMutation,
        CreateEducationClassMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<CreateEducationClassMutation, CreateEducationClassMutationVariables>(
        CreateEducationClassDocument,
        options,
    );
}
export type CreateEducationClassMutationHookResult = ReturnType<typeof useCreateEducationClassMutation>;
export type CreateEducationClassMutationResult = Apollo.MutationResult<CreateEducationClassMutation>;
export type CreateEducationClassMutationOptions = Apollo.BaseMutationOptions<
    CreateEducationClassMutation,
    CreateEducationClassMutationVariables
>;
export const GetEducationClassDetailsDocument = gql`
    query getEducationClassDetails($nodeId: ID!) {
        educationClassByNodeId(nodeId: $nodeId) {
            nodeId
            ...EducationClassDetailsFields
            educationClassSchedules {
                totalCount
            }
        }
    }
    ${EducationClassDetailsFieldsFragmentDoc}
`;

/**
 * __useGetEducationClassDetailsQuery__
 *
 * To run a query within a React component, call `useGetEducationClassDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEducationClassDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEducationClassDetailsQuery({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useGetEducationClassDetailsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetEducationClassDetailsQuery,
        GetEducationClassDetailsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetEducationClassDetailsQuery, GetEducationClassDetailsQueryVariables>(
        GetEducationClassDetailsDocument,
        options,
    );
}
export function useGetEducationClassDetailsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetEducationClassDetailsQuery,
        GetEducationClassDetailsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<GetEducationClassDetailsQuery, GetEducationClassDetailsQueryVariables>(
        GetEducationClassDetailsDocument,
        options,
    );
}
export type GetEducationClassDetailsQueryHookResult = ReturnType<typeof useGetEducationClassDetailsQuery>;
export type GetEducationClassDetailsLazyQueryHookResult = ReturnType<typeof useGetEducationClassDetailsLazyQuery>;
export type GetEducationClassDetailsQueryResult = Apollo.QueryResult<
    GetEducationClassDetailsQuery,
    GetEducationClassDetailsQueryVariables
>;
export const DeleteEducationClassDocument = gql`
    mutation deleteEducationClass($nodeId: ID!) {
        deleteEducationClassByNodeId(input: { nodeId: $nodeId }) {
            deletedEducationClassNodeId
        }
    }
`;
export type DeleteEducationClassMutationFn = Apollo.MutationFunction<
    DeleteEducationClassMutation,
    DeleteEducationClassMutationVariables
>;

/**
 * __useDeleteEducationClassMutation__
 *
 * To run a mutation, you first call `useDeleteEducationClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEducationClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEducationClassMutation, { data, loading, error }] = useDeleteEducationClassMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useDeleteEducationClassMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DeleteEducationClassMutation,
        DeleteEducationClassMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<DeleteEducationClassMutation, DeleteEducationClassMutationVariables>(
        DeleteEducationClassDocument,
        options,
    );
}
export type DeleteEducationClassMutationHookResult = ReturnType<typeof useDeleteEducationClassMutation>;
export type DeleteEducationClassMutationResult = Apollo.MutationResult<DeleteEducationClassMutation>;
export type DeleteEducationClassMutationOptions = Apollo.BaseMutationOptions<
    DeleteEducationClassMutation,
    DeleteEducationClassMutationVariables
>;
export const GetEducationClassScheduleDocument = gql`
    query getEducationClassSchedule($educationClassNodeId: ID!) {
        educationClassByNodeId(nodeId: $educationClassNodeId) {
            name
            education {
                name
            }
            nodeId
            id
            educationClassSchedules(orderBy: [COURSE_YEAR_SORTABLE_ASC, START_DATE_ASC]) {
                nodes {
                    ...EducationClassScheduleFields
                }
            }
        }
    }
    ${EducationClassScheduleFieldsFragmentDoc}
`;

/**
 * __useGetEducationClassScheduleQuery__
 *
 * To run a query within a React component, call `useGetEducationClassScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEducationClassScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEducationClassScheduleQuery({
 *   variables: {
 *      educationClassNodeId: // value for 'educationClassNodeId'
 *   },
 * });
 */
export function useGetEducationClassScheduleQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        GetEducationClassScheduleQuery,
        GetEducationClassScheduleQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<GetEducationClassScheduleQuery, GetEducationClassScheduleQueryVariables>(
        GetEducationClassScheduleDocument,
        options,
    );
}
export function useGetEducationClassScheduleLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        GetEducationClassScheduleQuery,
        GetEducationClassScheduleQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<GetEducationClassScheduleQuery, GetEducationClassScheduleQueryVariables>(
        GetEducationClassScheduleDocument,
        options,
    );
}
export type GetEducationClassScheduleQueryHookResult = ReturnType<typeof useGetEducationClassScheduleQuery>;
export type GetEducationClassScheduleLazyQueryHookResult = ReturnType<typeof useGetEducationClassScheduleLazyQuery>;
export type GetEducationClassScheduleQueryResult = Apollo.QueryResult<
    GetEducationClassScheduleQuery,
    GetEducationClassScheduleQueryVariables
>;
export const UpdateEducationClassScheduleDocument = gql`
    mutation updateEducationClassSchedule($nodeId: ID!, $educationClassSchedulePatch: EducationClassSchedulePatch!) {
        updateEducationClassScheduleByNodeId(input: { nodeId: $nodeId, patch: $educationClassSchedulePatch }) {
            clientMutationId
            educationClassSchedule {
                ...EducationClassScheduleFields
            }
        }
    }
    ${EducationClassScheduleFieldsFragmentDoc}
`;
export type UpdateEducationClassScheduleMutationFn = Apollo.MutationFunction<
    UpdateEducationClassScheduleMutation,
    UpdateEducationClassScheduleMutationVariables
>;

/**
 * __useUpdateEducationClassScheduleMutation__
 *
 * To run a mutation, you first call `useUpdateEducationClassScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEducationClassScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEducationClassScheduleMutation, { data, loading, error }] = useUpdateEducationClassScheduleMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *      educationClassSchedulePatch: // value for 'educationClassSchedulePatch'
 *   },
 * });
 */
export function useUpdateEducationClassScheduleMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateEducationClassScheduleMutation,
        UpdateEducationClassScheduleMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateEducationClassScheduleMutation,
        UpdateEducationClassScheduleMutationVariables
    >(UpdateEducationClassScheduleDocument, options);
}
export type UpdateEducationClassScheduleMutationHookResult = ReturnType<typeof useUpdateEducationClassScheduleMutation>;
export type UpdateEducationClassScheduleMutationResult = Apollo.MutationResult<UpdateEducationClassScheduleMutation>;
export type UpdateEducationClassScheduleMutationOptions = Apollo.BaseMutationOptions<
    UpdateEducationClassScheduleMutation,
    UpdateEducationClassScheduleMutationVariables
>;
export const CreateEducationClassScheduleDocument = gql`
    mutation createEducationClassSchedule($educationClassScheduleInput: CreateEducationClassScheduleInput!) {
        createEducationClassSchedule(input: $educationClassScheduleInput) {
            educationClassSchedule {
                ...EducationClassScheduleFields
            }
        }
    }
    ${EducationClassScheduleFieldsFragmentDoc}
`;
export type CreateEducationClassScheduleMutationFn = Apollo.MutationFunction<
    CreateEducationClassScheduleMutation,
    CreateEducationClassScheduleMutationVariables
>;

/**
 * __useCreateEducationClassScheduleMutation__
 *
 * To run a mutation, you first call `useCreateEducationClassScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEducationClassScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEducationClassScheduleMutation, { data, loading, error }] = useCreateEducationClassScheduleMutation({
 *   variables: {
 *      educationClassScheduleInput: // value for 'educationClassScheduleInput'
 *   },
 * });
 */
export function useCreateEducationClassScheduleMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateEducationClassScheduleMutation,
        CreateEducationClassScheduleMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        CreateEducationClassScheduleMutation,
        CreateEducationClassScheduleMutationVariables
    >(CreateEducationClassScheduleDocument, options);
}
export type CreateEducationClassScheduleMutationHookResult = ReturnType<typeof useCreateEducationClassScheduleMutation>;
export type CreateEducationClassScheduleMutationResult = Apollo.MutationResult<CreateEducationClassScheduleMutation>;
export type CreateEducationClassScheduleMutationOptions = Apollo.BaseMutationOptions<
    CreateEducationClassScheduleMutation,
    CreateEducationClassScheduleMutationVariables
>;
export const DeleteEducationClassScheduleDocument = gql`
    mutation deleteEducationClassSchedule($nodeId: ID!) {
        deleteEducationClassScheduleByNodeId(input: { nodeId: $nodeId }) {
            deletedEducationClassScheduleNodeId
        }
    }
`;
export type DeleteEducationClassScheduleMutationFn = Apollo.MutationFunction<
    DeleteEducationClassScheduleMutation,
    DeleteEducationClassScheduleMutationVariables
>;

/**
 * __useDeleteEducationClassScheduleMutation__
 *
 * To run a mutation, you first call `useDeleteEducationClassScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEducationClassScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEducationClassScheduleMutation, { data, loading, error }] = useDeleteEducationClassScheduleMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useDeleteEducationClassScheduleMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DeleteEducationClassScheduleMutation,
        DeleteEducationClassScheduleMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        DeleteEducationClassScheduleMutation,
        DeleteEducationClassScheduleMutationVariables
    >(DeleteEducationClassScheduleDocument, options);
}
export type DeleteEducationClassScheduleMutationHookResult = ReturnType<typeof useDeleteEducationClassScheduleMutation>;
export type DeleteEducationClassScheduleMutationResult = Apollo.MutationResult<DeleteEducationClassScheduleMutation>;
export type DeleteEducationClassScheduleMutationOptions = Apollo.BaseMutationOptions<
    DeleteEducationClassScheduleMutation,
    DeleteEducationClassScheduleMutationVariables
>;
export const UpdateEducationClassScheduleEntryDocument = gql`
    mutation updateEducationClassScheduleEntry(
        $nodeId: ID!
        $educationClassScheduleEntryPatch: EducationClassScheduleEntryPatch!
    ) {
        updateEducationClassScheduleEntryByNodeId(
            input: { nodeId: $nodeId, patch: $educationClassScheduleEntryPatch }
        ) {
            clientMutationId
            educationClassScheduleEntry {
                ...EducationClassScheduleEntryFields
            }
        }
    }
    ${EducationClassScheduleEntryFieldsFragmentDoc}
`;
export type UpdateEducationClassScheduleEntryMutationFn = Apollo.MutationFunction<
    UpdateEducationClassScheduleEntryMutation,
    UpdateEducationClassScheduleEntryMutationVariables
>;

/**
 * __useUpdateEducationClassScheduleEntryMutation__
 *
 * To run a mutation, you first call `useUpdateEducationClassScheduleEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEducationClassScheduleEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEducationClassScheduleEntryMutation, { data, loading, error }] = useUpdateEducationClassScheduleEntryMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *      educationClassScheduleEntryPatch: // value for 'educationClassScheduleEntryPatch'
 *   },
 * });
 */
export function useUpdateEducationClassScheduleEntryMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpdateEducationClassScheduleEntryMutation,
        UpdateEducationClassScheduleEntryMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        UpdateEducationClassScheduleEntryMutation,
        UpdateEducationClassScheduleEntryMutationVariables
    >(UpdateEducationClassScheduleEntryDocument, options);
}
export type UpdateEducationClassScheduleEntryMutationHookResult = ReturnType<
    typeof useUpdateEducationClassScheduleEntryMutation
>;
export type UpdateEducationClassScheduleEntryMutationResult =
    Apollo.MutationResult<UpdateEducationClassScheduleEntryMutation>;
export type UpdateEducationClassScheduleEntryMutationOptions = Apollo.BaseMutationOptions<
    UpdateEducationClassScheduleEntryMutation,
    UpdateEducationClassScheduleEntryMutationVariables
>;
export const CreateEducationClassScheduleEntryDocument = gql`
    mutation createEducationClassScheduleEntry(
        $educationClassScheduleEntryInput: CreateEducationClassScheduleEntryInput!
    ) {
        createEducationClassScheduleEntry(input: $educationClassScheduleEntryInput) {
            educationClassScheduleEntry {
                ...EducationClassScheduleEntryFields
            }
        }
    }
    ${EducationClassScheduleEntryFieldsFragmentDoc}
`;
export type CreateEducationClassScheduleEntryMutationFn = Apollo.MutationFunction<
    CreateEducationClassScheduleEntryMutation,
    CreateEducationClassScheduleEntryMutationVariables
>;

/**
 * __useCreateEducationClassScheduleEntryMutation__
 *
 * To run a mutation, you first call `useCreateEducationClassScheduleEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEducationClassScheduleEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEducationClassScheduleEntryMutation, { data, loading, error }] = useCreateEducationClassScheduleEntryMutation({
 *   variables: {
 *      educationClassScheduleEntryInput: // value for 'educationClassScheduleEntryInput'
 *   },
 * });
 */
export function useCreateEducationClassScheduleEntryMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateEducationClassScheduleEntryMutation,
        CreateEducationClassScheduleEntryMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        CreateEducationClassScheduleEntryMutation,
        CreateEducationClassScheduleEntryMutationVariables
    >(CreateEducationClassScheduleEntryDocument, options);
}
export type CreateEducationClassScheduleEntryMutationHookResult = ReturnType<
    typeof useCreateEducationClassScheduleEntryMutation
>;
export type CreateEducationClassScheduleEntryMutationResult =
    Apollo.MutationResult<CreateEducationClassScheduleEntryMutation>;
export type CreateEducationClassScheduleEntryMutationOptions = Apollo.BaseMutationOptions<
    CreateEducationClassScheduleEntryMutation,
    CreateEducationClassScheduleEntryMutationVariables
>;
export const DeleteEducationClassScheduleEntryDocument = gql`
    mutation deleteEducationClassScheduleEntry($nodeId: ID!) {
        deleteEducationClassScheduleEntryByNodeId(input: { nodeId: $nodeId }) {
            deletedEducationClassScheduleEntryNodeId
        }
    }
`;
export type DeleteEducationClassScheduleEntryMutationFn = Apollo.MutationFunction<
    DeleteEducationClassScheduleEntryMutation,
    DeleteEducationClassScheduleEntryMutationVariables
>;

/**
 * __useDeleteEducationClassScheduleEntryMutation__
 *
 * To run a mutation, you first call `useDeleteEducationClassScheduleEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEducationClassScheduleEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEducationClassScheduleEntryMutation, { data, loading, error }] = useDeleteEducationClassScheduleEntryMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useDeleteEducationClassScheduleEntryMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DeleteEducationClassScheduleEntryMutation,
        DeleteEducationClassScheduleEntryMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        DeleteEducationClassScheduleEntryMutation,
        DeleteEducationClassScheduleEntryMutationVariables
    >(DeleteEducationClassScheduleEntryDocument, options);
}
export type DeleteEducationClassScheduleEntryMutationHookResult = ReturnType<
    typeof useDeleteEducationClassScheduleEntryMutation
>;
export type DeleteEducationClassScheduleEntryMutationResult =
    Apollo.MutationResult<DeleteEducationClassScheduleEntryMutation>;
export type DeleteEducationClassScheduleEntryMutationOptions = Apollo.BaseMutationOptions<
    DeleteEducationClassScheduleEntryMutation,
    DeleteEducationClassScheduleEntryMutationVariables
>;
